import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionReassignPayload } from 'api/actions/revision-reassign/revision-reassign-payload';
import { RevisionReassignPathParameters } from 'api/actions/revision-reassign/revision-reassign-path-parameters';
import { RevisionReassignResponse } from 'api/actions/revision-reassign/revision-reassign-response';

type RevisionReassignQueryParameters = Record<string, any>;

/**
 * The path of the RevisionReassign action.
 */
export const revisionReassignActionPath = new SweetPath<keyof RevisionReassignPathParameters>(
  '/api/revision/reassign/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionReassign actions.
 */
export default function createRevisionReassignAction(axios: AxiosInstance) {
  return function revisionReassignAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionReassignPathParameters;
    query?: RevisionReassignQueryParameters;
    payload: RevisionReassignPayload;
  }) {
    const path = revisionReassignActionPath.insert(parameters);

    return axios.put<RevisionReassignResponse, RevisionReassignResponse, RevisionReassignPayload>(path, payload);
  };
}
