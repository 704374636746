import { FloatingPosition } from '@mantine/core/lib/Floating';
import { DateInput as MantineDateInput, DateInputProps as MantineDateInputProps } from '@mantine/dates';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { useMemo } from 'react';

interface DateInputProps extends Omit<MantineDateInputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  popoverPosition?: FloatingPosition;
}

/**
 * Converts a Date object to a string.
 */
export const stringifyDate = (date: Date | null): string => {
  if (!date) {
    return '';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString();

  return `${year}-${month}-${day}`;
};

/**
 * Normalized mantine DateInput.
 */
export function DateInput({ value, onChange = noop, popoverPosition = 'left', ...rest }: DateInputProps) {
  const dateValue = useMemo(() => {
    if (value === undefined || value === '') {
      return null;
    }

    try {
      return new Date(value);
    } catch (error: any) {
      panic(new Error(`Invalid date: ${value}`));
      return null;
    }
  }, [value]);

  return (
    <MantineDateInput
      value={dateValue}
      onChange={(date) => onChange(stringifyDate(date))}
      valueFormat="DD.MM.YYYY"
      dateParser={(input) => {
        const [day, month, year] = input.split('.');
        if (!day || !month || !year) {
          return null;
        }

        return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
      }}
      popoverProps={{ position: popoverPosition, withinPortal: true, zIndex: 10000 }}
      {...rest}
    />
  );
}
