import { IconRowRemove } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * Removes the selected row.
 */
export default function RemoveRow({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      label="Odstrániť riadok"
      icon={<IconRowRemove />}
      onClick={() => editor.chain().focus().deleteRow().run()}
    />
  );
}
