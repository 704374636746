import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceSetStatusPayload } from 'api/actions/device-set-status/device-set-status-payload';
import { DeviceSetStatusPathParameters } from 'api/actions/device-set-status/device-set-status-path-parameters';
import { DeviceSetStatusResponse } from 'api/actions/device-set-status/device-set-status-response';

type DeviceSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the DeviceSetStatus action.
 */
export const deviceSetStatusActionPath = new SweetPath<keyof DeviceSetStatusPathParameters>(
  '/api/device/status/{deviceId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceSetStatus actions.
 */
export default function createDeviceSetStatusAction(axios: AxiosInstance) {
  return function deviceSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceSetStatusPathParameters;
    query?: DeviceSetStatusQueryParameters;
    payload: DeviceSetStatusPayload;
  }) {
    const path = deviceSetStatusActionPath.insert(parameters);

    return axios.put<DeviceSetStatusResponse, DeviceSetStatusResponse, DeviceSetStatusPayload>(path, payload);
  };
}
