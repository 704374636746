import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import OrganizationForm, { OrganizationFormData } from 'components/forms/organization/OrganizationForm';
import { ORGANIZATION_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { getDeviceType } from 'model/DeviceType';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { nanoid } from 'nanoid';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ORGANIZATIONS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit the organization.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A7408 Figma Design}
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A7813 Figma Design}
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A8120 Figma Design}
 * - {@link https://www.notion.so/Administrative-Edit-Organization-0845cb37114943d6999b5613e80f1fe6?pvs=4 Notion Page}
 */
export default function EditOrganizationPage() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const { getAction } = useApi();
  const { getPermissionTemplate, getDefaultPermissionTemplate } = usePermissionDataProvider();

  const { data, loading, error } = useImmediateAction(() => {
    const organizationGetAction = getAction('OrganizationGet');
    return organizationGetAction({ parameters: { organizationId: organizationId! } });
  });

  if (error) {
    panic(error);
    return <></>;
  }

  if (!organizationId) {
    return <Navigate to={ORGANIZATIONS_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({
    organizationName,
    streetAddress,
    city,
    zip,
    country,
    companyQualifications,
    selfAdministration,
    people,
    includedInStatistics,
    inKepHashArchive,
  }: OrganizationFormData) {
    const organizationUpdateAction = getAction('OrganizationUpdate');

    const mappedPeople = Object.entries(people)
      .map(([personId, value]) => ({
        userId: Number(personId),
        checked: value.checked,
        deleted: value.deleted,
        permissions: value.permissions,
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        isNew: value.isNew,
      }))
      .map(({ userId, permissions, templateId, deleted, isNew }) => ({
        deleted,
        userId,
        permissions: permissions ?? [],
        templateId,
        isNew,
      }));

    return organizationUpdateAction({
      payload: {
        organizationName,
        streetAddress,
        city,
        zip,
        country,
        companyQualifications: (companyQualifications || []).map(({ qualificationNumber, type }) => ({
          deviceTypeId: getDeviceType(type)!.id,
          qualificationNumber,
        })),
        selfAdministration: selfAdministration ? selfAdministration : false,
        companyCertificates: [],
        people: mappedPeople,
        includedInStatistics,
        inKepHashArchive,
      },
      parameters: {
        organizationId: organizationId!,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Organizácia bola upravená',
          message: `Organizácia ${organizationName} bola úspešne upravená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (ORGANIZATION_REDIRECT_AFTER_SAVE === 'list') {
          navigate(ORGANIZATIONS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title={`Úprava organizácie: ${data?.organizationName ?? ''}`}
      breadcrumbs={[
        { title: 'Organizácie', link: ORGANIZATIONS_PAGE_PATH.original },
        { title: data?.organizationName ?? 'Načítavanie ...' },
      ]}
    >
      {!loading && (
        <OrganizationForm
          initialValues={{
            organizationName: data.organizationName,
            streetAddress: data.streetAddress,
            city: data.city,
            zip: data.zip,
            country: data.country,
            selfAdministration: data.selfAdministration,
            includedInStatistics: data.includedInStatistics,
            inKepHashArchive: data.inKepHashArchive,
            companyQualifications: (data.companyDetail?.qualifications || []).map((qualification) => ({
              ...qualification,
              type: getDeviceType(qualification.deviceTypeId)!.slug,
              uuid: nanoid(),
            })),
            people: (data.people || []).reduce((acc, curr) => {
              acc[String(curr.userId)] = {
                checked: true,
                deleted: curr.deleted,
                permissions: curr.permissions,
                templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
                isNew: curr.isNew,
              };

              return acc;
            }, {} as { [userId: string]: { checked: boolean; deleted: boolean; permissions: number[]; templateName: string; isNew: boolean } }),
            peopleInDepartments: (data.peopleInDepartments || []).reduce((acc, curr) => {
              if (!Object.keys(acc).includes(String(curr.departmentId))) {
                acc[String(curr.departmentId)] = [];
              }
              acc[String(curr.departmentId)] = [
                ...acc[String(curr.departmentId)],
                {
                  userId: curr.userId,
                  permissions: curr.permissions,
                  templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
                  departmentName: curr.departmentName,
                },
              ];

              return acc;
            }, {} as { [departmentId: string]: { userId: number; permissions: number[]; templateName: string; departmentName: string }[] }),
          }}
          onSubmit={onSubmit}
        />
      )}
    </DashboardLayout>
  );
}
