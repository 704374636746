import { SimpleGrid, Stack } from '@mantine/core';
import ReadonlyText from 'components/typography/ReadonlyText';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useMemo } from 'react';

/**
 * Revision metadata - basic information about the revision.
 */
export default function RevisionMetadata() {
  const { form } = useFillOutRevisionDataProvider();

  const devices = useMemo(
    () =>
      Object.entries(form.values.devices).map(([deviceId, device]) => ({
        key: deviceId,
        value: device.fields._deviceName ?? '',
      })),
    [form.values.devices]
  );

  return (
    <Stack px={40} py={24} bg="gray.0" style={{ borderRadius: '4px' }}>
      <SimpleGrid cols={2} spacing={40}>
        <ReadonlyText title="Názov" value={form.values.fields._revisionName ?? ''} />
        <ReadonlyText title="Zariadenie" value={devices} />
      </SimpleGrid>
      <SimpleGrid cols={2} spacing={40}>
        <ReadonlyText title="Organizácia" value={form.values.fields._organization ?? ''} />
        <ReadonlyText title="Stredisko" value={form.values.fields._department ?? ''} />
      </SimpleGrid>
    </Stack>
  );
}
