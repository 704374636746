import { ActionIcon, Box, Button, createStyles, Group, Menu, Popover, rem, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCaretRightFilled, IconChevronDown } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';
import { noop } from 'lodash';
import { useMemo } from 'react';

export type DropdownControlKind = 'action-icon' | 'button' | 'menu-item';

export interface DropdownControlProps {
  kind: DropdownControlKind;
  icon?: React.ReactNode;
  label: string;
  text?: string;
  children: React.ReactNode;
  active?: boolean;
  closeOnClick?: boolean;
}

const useStyles = createStyles((theme) => ({
  icon: {
    display: 'flex',
    gap: rem(2),
    '& svg': {
      strokeWidth: '2.5 !important',
    },
  },
  item: {
    height: rem(36),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: rem(8),
    padding: `${rem(8)} ${rem(16)}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  itemIconWrapper: {
    '& svg': {
      width: rem(16),
      height: rem(16),
    },
  },
  chevronIcon: {
    width: rem(12),
    height: rem(12),
    '& path': {
      fill: theme.colors.gray[6],
    },
  },
  dropdown: {
    padding: 0,
    transform: 'translateX(298px)',
    transitionDelay: '300ms !important',
  },
}));

/**
 * A toolbar control action button for operations with a dropdown.
 */
export default function DropdownControl({
  kind,
  icon,
  label,
  text,
  children,
  active,
  closeOnClick = false,
}: DropdownControlProps) {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure();
  const iconVariant = useMemo(() => (active ? 'subtle-blue' : 'subtle-gray'), [active]);
  const popoverClassNames = useMemo(() => ({ dropdown: classes.dropdown }), [classes]);

  if (kind === 'action-icon') {
    return (
      <Menu withinPortal onOpen={open} onClose={close} opened={opened} loop={false}>
        <Menu.Target>
          <Tooltip label={label} withArrow={false} openDelay={500} withinPortal position="bottom" disabled={opened}>
            <ActionIcon variant={iconVariant} size="xs" className={classes.icon} w={40}>
              {icon}
              <IconChevronDown size={16} />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown onClick={closeOnClick ? close : noop}>{children}</Menu.Dropdown>
      </Menu>
    );
  }

  if (kind === 'button') {
    return (
      <Menu withinPortal onOpen={open} onClose={close}>
        <Menu.Target>
          <Tooltip label={label} withArrow={false} openDelay={500} withinPortal position="bottom" disabled={opened}>
            <Button variant="subtle-gray" size="xs" rightIcon={<IconChevronDown />} leftIcon={icon}>
              {text || label}
            </Button>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Menu>
    );
  }

  return (
    <Group className={classes.item} onMouseEnter={open} onMouseLeave={close} pos="relative">
      <Box className={classes.itemIconWrapper} w={16} h={16}>
        {icon}
      </Box>
      <P2Regular c="gray.8">{label}</P2Regular>
      <IconCaretRightFilled size={12} className={classes.chevronIcon} />
      <Popover opened={opened} position="top-end" classNames={popoverClassNames}>
        <Menu.Dropdown>{children}</Menu.Dropdown>
      </Popover>
    </Group>
  );
}
