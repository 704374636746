import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthResetPasswordPayload } from 'api/actions/auth-reset-password/auth-reset-password-payload';
import { AuthResetPasswordPathParameters } from 'api/actions/auth-reset-password/auth-reset-password-path-parameters';
import { AuthResetPasswordResponse } from 'api/actions/auth-reset-password/auth-reset-password-response';

type AuthResetPasswordQueryParameters = Record<string, any>;

/**
 * The path of the AuthResetPassword action.
 */
export const authResetPasswordActionPath = new SweetPath<keyof AuthResetPasswordPathParameters>(
  '/api/auth/reset-password',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthResetPassword actions.
 */
export default function createAuthResetPasswordAction(axios: AxiosInstance) {
  return function authResetPasswordAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthResetPasswordQueryParameters;
    payload: AuthResetPasswordPayload;
  }) {
    const path = authResetPasswordActionPath.original;

    return axios.post<AuthResetPasswordResponse, AuthResetPasswordResponse, AuthResetPasswordPayload>(path, payload);
  };
}
