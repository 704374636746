import { Text } from '@mantine/core';
import { ActionIcon, Button, Group, Menu, Portal } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useConfirm } from 'components/modals/message/MessageProvider';
import panic from 'errors/panic';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { EDIT_MEASURING_DEVICE_PAGE_PATH } from 'routes/paths';

/**
 * Interface for MeasuringDeviceActions component props
 */
export interface MeasuringDeviceActionsProps {
  measuringDeviceId: number;
  measuringDeviceName: string;
  onDelete: () => void;
}

/**
 * Displays actions for a measuring device in a table.
 */
export default function MeasuringDeviceActions({
  measuringDeviceId,
  measuringDeviceName,
  onDelete,
}: MeasuringDeviceActionsProps) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();

  /**
   * Makes a call to the API to delete the measuring device.
   */
  const deleteMeasuringDevice = useCallback(() => {
    const measuringDeviceDelete = getAction('MeasuringDeviceDelete');

    measuringDeviceDelete({ parameters: { measuringDeviceId: String(measuringDeviceId) } })
      .then(() => {
        onDelete();
      })
      .catch(panic);
  }, [getAction, measuringDeviceId]);

  /**
   * Confirms the deletion of the measuring device.
   */
  const confirmDeleteMeasuringDevice = useCallback(() => {
    confirm({
      title: 'Vymazať merací prístroj',
      content: `Naozaj chcete vymazať merací prístroj ${measuringDeviceName}?`,
      onConfirm: deleteMeasuringDevice,
    });
  }, [confirm, deleteMeasuringDevice]);

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        component={Link}
        to={EDIT_MEASURING_DEVICE_PAGE_PATH.insert({ measuringDeviceId })}
        variant="secondary"
        leftIcon={<IconPencil />}
      >
        Editovať
      </Button>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {measuringDeviceName}
              </Text>
            </Menu.Label>
            <Menu.Item
              color="red.8"
              onClick={confirmDeleteMeasuringDevice}
              icon={<IconTrashX stroke={1.5} size={20} />}
            >
              Vymazať
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
