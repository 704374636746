import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Step {
  key: string;
  title: string;
  hidden?: boolean;
}

/**
 * Hook to manage the steps of a tab.
 */
export default function useTabSteps(steps: Step[], initialStep?: string) {
  const [searchParams, setSearchParams] = useSearchParams();

  const firstVisibleStep = useMemo(() => steps.find(({ hidden }) => !hidden), [steps]);
  const lastVisibleStep = useMemo(() => [...steps].reverse().find(({ hidden }) => !hidden), [steps]);

  const [currStepKey, setCurrStepKey] = useState<string>(() => {
    const initialKey = initialStep || searchParams.get('tab');
    const step = steps.find(({ key }) => key === initialKey);
    return step?.key ?? firstVisibleStep?.key ?? '';
  });

  const currStep = useMemo(() => steps.find(({ key }) => key === currStepKey)!, [currStepKey, steps]);
  const currStepIndex = useMemo(() => steps.findIndex(({ key }) => key === currStepKey), [currStepKey, steps]);

  const nextStepIndex = useMemo(() => {
    let index = currStepIndex + 1;

    while (index < steps.length && steps[index].hidden) {
      index++;
    }

    return Math.min(steps.length - 1, index);
  }, [currStepIndex, steps]);

  const prevStepIndex = useMemo(() => {
    let index = currStepIndex - 1;

    while (index >= 0 && steps[index].hidden) {
      index--;
    }

    return Math.max(0, index);
  }, [currStepIndex, steps]);

  const isFirstStep = useMemo(() => currStepKey === firstVisibleStep?.key, [currStepKey, firstVisibleStep]);
  const isLastStep = useMemo(() => currStepKey === lastVisibleStep?.key, [currStepKey, lastVisibleStep]);

  const nextStep = useCallback(() => setCurrStepKey(steps[nextStepIndex]?.key ?? ''), [nextStepIndex]);
  const prevStep = useCallback(() => setCurrStepKey(steps[prevStepIndex]?.key ?? ''), [prevStepIndex]);

  useEffect(() => {
    setSearchParams({ tab: currStepKey }, { replace: true });
  }, [currStepKey]);

  const value = useMemo(
    () => ({ steps, currStep, isFirstStep, isLastStep, setCurrStep: setCurrStepKey, nextStep, prevStep }),
    [steps, currStep, isFirstStep, isLastStep, setCurrStepKey, nextStep, prevStep]
  );

  return value;
}
