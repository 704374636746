import { Box } from '@mantine/core';
import FormInputGroup from 'components/forms/FormInputGroup';
import RevisionRow from 'components/forms/revision/fill-out/RevisionRow';
import { IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';

/**
 * Single section in the revision tab.
 */
export default function RevisionSection({ section: { id, name, rows } }: { section: IFormSection }) {
  const allInputsHidden = useMemo(
    () => rows.every(({ inputs }) => inputs.every(({ spec: { hidden } }) => hidden)),
    [rows]
  );

  return (
    <Box display={allInputsHidden ? 'none' : 'block'}>
      <FormInputGroup key={id} groupTitle={name} stackProps={{ pl: 0, pr: 0, pt: 0, pb: 0 }}>
        {rows.map((row) => (
          <RevisionRow key={row.id} row={row} />
        ))}
      </FormInputGroup>
    </Box>
  );
}
