import { Select, SelectItem } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IFormInputSpec,
  IFormInputSpecDeviceProperty,
} from 'pages/revisions-module/template-editor/editors/form/types';

type DeviceProperty = IFormInputSpecDeviceProperty['property'];

interface DevicePropertyOption {
  label: string;
  group: string;
}

const PROPERTY_NAMES: Record<DeviceProperty, DevicePropertyOption> = {
  deviceName: { label: 'Názov zariadenia', group: 'Základné info' },
  manufacturer: { label: 'Výrobca', group: 'Základné info' },
  manufactured: { label: 'Rok výroby', group: 'Základné info' },
  serialNumber: { label: 'Výrobné číslo', group: 'Základné info' },
  deviceSubTypeName: { label: 'Typ zariadenia', group: 'Základné info' },

  building: { label: 'Budova', group: 'Umiestnenie' },
  floor: { label: 'Podlažie', group: 'Umiestnenie' },
  room: { label: 'Miestnosť', group: 'Umiestnenie' },
  latitude: { label: 'Zemepisná šírka (GPS)', group: 'Umiestnenie' },
  longitude: { label: 'Zemepisná dĺžka (GPS)', group: 'Umiestnenie' },

  internalPossessionsNumber: { label: 'Interné číslo majetku', group: 'Doplňujúce info' },
  internalNote: { label: 'Interná poznámka', group: 'Doplňujúce info' },
};

const OPTIONS: SelectItem[] = Object.entries(PROPERTY_NAMES).map(([value, { label, group }]) => ({
  value,
  label,
  group,
}));

/**
 * Sub-form for deviceProperty input properties.
 */
export default function DevicePropertyInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return (
    <Select size="sm" label="Výber vlasnosti" data={OPTIONS} searchable required {...form.getInputProps('property')} />
  );
}
