import { Box, createStyles, Group, Stack } from '@mantine/core';
import { times } from 'lodash';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useMemo } from 'react';

const useStyles = createStyles(() => ({
  cornerBox: {
    cursor: 'ew-resize',
  },
  cornerBoxTriangle: {
    clipPath: 'polygon(50% 100%, 0 0, 100% 0)',
  },
}));

/**
 * Displays a ruler for the template editor.
 */
export default function Ruler({ ticks = 85 }: { ticks?: number }) {
  const { visual } = useTemplateEditorData();
  const { classes } = useStyles();

  const tickBoxes = useMemo(
    () => times(ticks, (i) => <Box key={i} w={1} h={i % 4 === 0 ? '100%' : '50%'} bg="gray.3" />),
    [ticks]
  );

  return (
    <Box h={8} mt={10}>
      <Box w="210mm" h="100%" mx="auto" pos="relative">
        <Group position="apart" align="end" spacing={0} w="calc(210mm + 1px)" h="100%">
          {tickBoxes}
        </Group>
        <Group
          position="apart"
          pos="absolute"
          bottom={-1}
          left={`${visual.marginLeft ?? 4}mm`}
          right={`${visual.marginRight ?? 4}mm`}
          h={1}
          bg="gray.5"
        >
          <Stack spacing={1} pos="relative" top={-11} left={-5} w={11} h={10} className={classes.cornerBox}>
            <Box w={11} h={3} mx="auto" bg="blue.5" />
            <Box w={11} h={6} bg="blue.5" className={classes.cornerBoxTriangle} />
          </Stack>

          <Stack spacing={1} pos="relative" top={-11} right={-5} w={11} h={10} className={classes.cornerBox}>
            <Box w={11} h={3} mx="auto" bg="blue.5" />
            <Box w={11} h={6} bg="blue.5" className={classes.cornerBoxTriangle} />
          </Stack>
        </Group>
      </Box>
    </Box>
  );
}
