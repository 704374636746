import { useApi } from 'api/api-context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from 'routes/paths';

/**
 * Logs out the user and redirects to login.
 */
export default function LogoutPage() {
  const navigate = useNavigate();
  const { logout } = useApi();

  useEffect(() => {
    logout();
    navigate(LOGIN_PAGE_PATH.original);
  }, [logout, navigate]);

  return <></>;
}
