import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionSignDocPayload } from 'api/actions/revision-sign-doc/revision-sign-doc-payload';
import { RevisionSignDocPathParameters } from 'api/actions/revision-sign-doc/revision-sign-doc-path-parameters';
import { RevisionSignDocResponse } from 'api/actions/revision-sign-doc/revision-sign-doc-response';

type RevisionSignDocQueryParameters = Record<string, any>;

/**
 * The path of the RevisionSignDoc action.
 */
export const revisionSignDocActionPath = new SweetPath<keyof RevisionSignDocPathParameters>(
  '/api/revision/sign-doc/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionSignDoc actions.
 */
export default function createRevisionSignDocAction(axios: AxiosInstance) {
  return function revisionSignDocAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionSignDocPathParameters;
    query?: RevisionSignDocQueryParameters;
    payload: RevisionSignDocPayload;
  }) {
    const path = revisionSignDocActionPath.insert(parameters);

    return axios.post<RevisionSignDocResponse, RevisionSignDocResponse, RevisionSignDocPayload>(path, payload);
  };
}
