import { Select } from '@mantine/core';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { IFormInputSpecSelect } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Select input for the revision form.
 */
export default function RevisionFieldSelect({ spec, name }: RevisionFieldProps<IFormInputSpecSelect>) {
  const { form } = useFillOutRevisionDataProvider();

  return (
    <Select
      required={spec.required}
      readOnly={spec.readOnly}
      size="lg"
      label={spec.label}
      searchable
      data={spec.options}
      name={name}
      {...form.getInputProps(name)}
    />
  );
}
