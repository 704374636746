import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceResponsiblePersonGetOptionsPathParameters } from 'api/actions/device-responsible-person-get-options/device-responsible-person-get-options-path-parameters';
import { DeviceResponsiblePersonGetOptionsResponse } from 'api/actions/device-responsible-person-get-options/device-responsible-person-get-options-response';

type DeviceResponsiblePersonGetOptionsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceResponsiblePersonGetOptions action.
 */
export const deviceResponsiblePersonGetOptionsActionPath = new SweetPath<
  keyof DeviceResponsiblePersonGetOptionsPathParameters
>('/api/device/responsible-person-options/{departmentId}', { path: '{param}' });

/**
 * Creates a contextualized DeviceResponsiblePersonGetOptions actions.
 */
export default function createDeviceResponsiblePersonGetOptionsAction(axios: AxiosInstance) {
  return function deviceResponsiblePersonGetOptionsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceResponsiblePersonGetOptionsPathParameters;
    query?: DeviceResponsiblePersonGetOptionsQueryParameters;
  }) {
    const path = deviceResponsiblePersonGetOptionsActionPath.insert(parameters);

    return axios.get<DeviceResponsiblePersonGetOptionsResponse, DeviceResponsiblePersonGetOptionsResponse>(path, {
      params: query,
      headers,
    });
  };
}
