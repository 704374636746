import { IconBorderOuter } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { Menu } from '@mantine/core';

const OPTIONS = [0, 1, 2, 3, 4, 5];

/**
 * Sets the border width of the cell.
 */
export default function CellBorderWidth({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <DropdownControl kind={kind} label="Šírka orámovania bunky" icon={<IconBorderOuter />}>
      {OPTIONS.map((option) => (
        <Menu.Item
          key={option}
          onClick={() => editor.chain().focus().setCellAttribute('data-border-width', option).run()}
        >
          {option} px
        </Menu.Item>
      ))}
      <Menu.Divider />
    </DropdownControl>
  );
}
