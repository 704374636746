import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignJustified } from '@tabler/icons-react';

/**
 * A button that allows the user to align the selected text to the justify.
 */
export default function AlignJustify({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconAlignJustified />}
      label="Zarovnať podľa okrajov"
      shortcut="Ctrl + Shift + J"
      active={editor?.isActive({ textAlign: 'justify' })}
      onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
    />
  );
}
