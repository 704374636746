import { useApi } from 'api/api-context';
import NumberStatCard from 'components/stats/NumberStatCard';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import { FAULTS_PAGE_PATH } from 'routes/paths';

/**
 * Displays the number of devices with faults.
 */
export default function DevicesWithFaultsCard() {
  const { getAction } = useApi();
  const [faults, setFaults] = useState<number | null>(null);
  const [devices, setDevices] = useState<number | null>(null);

  useEffect(() => {
    const deviceListAction = getAction('DeviceList');
    const fetchFaults = getAction('DeviceListAllFaults');

    Promise.all([
      deviceListAction({
        query: { filters: { 'withFault.eq': 1, 'showDiscarded.eq': 0, 'includedInStatistics.eq': 1 } },
      }),
      fetchFaults({ query: { filters: { 'fixed.eq': 0, 'includedInStatistics.eq': 1 } } }),
    ])
      .then(([{ length: devices }, { length: faults }]) => {
        setDevices(devices);
        setFaults(faults);
      })
      .catch(panic);
  }, []);

  return (
    <NumberStatCard
      loading={faults === null || devices === null}
      title="Nevyriešené závady / Zariadenia so závadami"
      link={`${FAULTS_PAGE_PATH.original}?isFixedText=not-fixed`}
      value={faults && devices ? [faults, devices] : [0, 0]}
      variant="red"
    />
  );
}
