import { Button, createStyles, Group, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconSettings } from '@tabler/icons-react';
import { BubbleMenu } from '@tiptap/react';
import P4Medium from 'components/typography/P4Medium';
import { useMemo } from 'react';
import { Props } from 'tippy.js';
import IfBlockConfigurator from 'pages/revisions-module/template-editor/editors/template/extensions/if-block/IfBlockConfigurator';

const useStyles = createStyles((theme) => ({
  button: {
    boxShadow: '0px 4px 12px 0px rgba(197, 198, 211, 0.40) !important',
    border: `1px solid ${theme.colors.blue[3]}`,
  },
}));

/**
 * Bubble menu used to control the IfBlock.
 */
export default function IfBlockBubbleMenu() {
  const { classes } = useStyles();
  const { editor } = useRichTextEditorContext();
  const [configuratorOpened, { close: closeConfigurator, open: openConfigurator }] = useDisclosure(false);

  const tippyOptions: Partial<Props> = useMemo(
    () => ({
      duration: 100,
      placement: 'top-start',
      zIndex: 3,
      getReferenceClientRect: () => {
        let node: Node | null = editor.view.domAtPos(editor.state.selection.anchor).node;

        while (node) {
          if (node instanceof HTMLElement && node.classList.contains('if-block')) {
            node = node.querySelector('.if-block-start');
            break;
          }

          node = node.parentNode;
        }

        if (node instanceof HTMLElement) {
          return node.getBoundingClientRect();
        }

        return editor.view.dom.getBoundingClientRect();
      },
      offset: [-62, 5],
    }),
    [editor]
  );

  if (!editor) {
    return null;
  }

  return (
    <>
      <BubbleMenu editor={editor} tippyOptions={tippyOptions} shouldShow={({ editor }) => editor.isActive('ifBlock')}>
        <Button size="sm" variant="secondary" onClick={openConfigurator} className={classes.button}>
          <Group spacing={2}>
            <IconSettings size={12} />
            <P4Medium fw={700} pr={2}>
              Konfigurovať
            </P4Medium>
          </Group>
        </Button>
      </BubbleMenu>
      <Modal
        title="Konfigurátor podmienky"
        size="auto"
        centered
        returnFocus={false}
        opened={configuratorOpened}
        onClose={closeConfigurator}
      >
        <IfBlockConfigurator onClose={closeConfigurator} />
      </Modal>
    </>
  );
}
