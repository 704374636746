import { Box, Center, Checkbox, Group, Menu, Stack, Tooltip, rem, useMantineTheme } from '@mantine/core';
import RevisionStatusIcon from 'components/RevisionStatusIcon';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import { FEATURE_TOGGLE_KEP } from 'env';
import { noop } from 'lodash';
import { useMemo } from 'react';

/**
 * Parameters of the RevisionStatusMultiSelect component.
 */
export interface RevisionStatusMultiSelectProps {
  value?: number[];
  onChange?: (value: number[]) => void;
  placeholder?: string;
  activeIconLimit?: number;
}

/**
 * Renders a multi-select input for statuses.
 */
export default function RevisionStatusMultiSelect({
  value = [],
  onChange = noop,
  placeholder = 'Vyberte stavy revízie',
  activeIconLimit = 4,
}: RevisionStatusMultiSelectProps = {}) {
  const theme = useMantineTheme();
  const statuses = useMemo(
    () => [1, 2, 3, 4, 5, 6, 7, 8, FEATURE_TOGGLE_KEP ? 9 : undefined, FEATURE_TOGGLE_KEP ? 10 : undefined, 999],
    [FEATURE_TOGGLE_KEP]
  );

  /**
   * Toggles the status in the value.
   */
  const toggleStatus = (statusId: number) => {
    if (value.includes(statusId)) {
      onChange(value.filter((status) => status !== statusId));
    } else {
      onChange([...value, statusId]);
    }
  };

  return (
    <Menu withinPortal>
      <Menu.Target>
        <Box
          w="100%"
          h={32}
          py={4}
          px={8}
          bg="white"
          sx={{
            borderRadius: rem(4),
            border: `1px solid ${theme.colors.gray[4]}`,
            '&:hover': {
              borderColor: theme.colors.blue[8],
              cursor: 'text',
            },
          }}
        >
          {value.length === 0 ? (
            <P2Regular color="gray.5">{placeholder}</P2Regular>
          ) : (
            <Group noWrap spacing={4}>
              {value.slice(0, activeIconLimit).map((status) => (
                <Box key={status} sx={{ transform: 'translateY(-1px)' }}>
                  <RevisionStatusIcon iconOnly statusId={status} />
                </Box>
              ))}
              {value.length > activeIconLimit && (
                <Tooltip
                  withinPortal
                  label={
                    <Stack spacing={8} py={4}>
                      {value.slice(activeIconLimit).map((status) => (
                        <RevisionStatusIcon textColorOverride="white" key={status} statusId={status} />
                      ))}
                    </Stack>
                  }
                >
                  <Center w={24} h={24} bg="gray.1" sx={{ transform: 'translateY(-1px)', borderRadius: '50%' }}>
                    <P2Medium color="gray.7">+{value.length - activeIconLimit}</P2Medium>
                  </Center>
                </Tooltip>
              )}
            </Group>
          )}
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>
          <P2Regular color="gray.7">Vyberte stavy revízie</P2Regular>
        </Menu.Label>
        {statuses
          .filter((status) => status !== undefined)
          .map((status) => (
            <Menu.Item key={status} onClick={() => toggleStatus(status!)} closeMenuOnClick={false}>
              <Group spacing={16}>
                <Checkbox
                  styles={{ input: { cursor: 'pointer !important' } }}
                  checked={value.includes(status!)}
                  onChange={() => toggleStatus(status!)}
                />
                <RevisionStatusIcon statusId={status!} />
              </Group>
            </Menu.Item>
          ))}
      </Menu.Dropdown>
    </Menu>
  );
}
