import { mergeAttributes, Node } from '@tiptap/core';
import { nanoid } from 'nanoid';

export interface DeviceLoopOptions {}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    deviceLoop: {
      setDeviceLoop: () => ReturnType;
    };
  }
}

export const DeviceLoop = Node.create<DeviceLoopOptions>({
  name: 'deviceLoop',
  content: 'block+',
  group: 'block',
  defining: true,

  addAttributes() {
    return {
      'data-id': { default: nanoid() },
    };
  },

  parseHTML() {
    return [{ tag: 'div.device-loop' }];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      mergeAttributes({ class: 'device-loop' }, HTMLAttributes),
      ['div', { class: 'loop-block-start', 'data-iterator': 'devices', 'data-id': node.attrs['data-id'] }],
      ['div', { class: 'device-loop-body', 'data-iterator': 'devices', 'data-id': node.attrs['data-id'] }, 0],
      ['div', { class: 'loop-block-end', 'data-iterator': 'devices', 'data-id': node.attrs['data-id'] }],
    ];
  },

  addCommands() {
    return {
      setDeviceLoop:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
    };
  },
});
