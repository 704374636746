import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthSetUserDepartmentPermissionsPayload } from 'api/actions/auth-set-user-department-permissions/auth-set-user-department-permissions-payload';
import { AuthSetUserDepartmentPermissionsPathParameters } from 'api/actions/auth-set-user-department-permissions/auth-set-user-department-permissions-path-parameters';
import { AuthSetUserDepartmentPermissionsResponse } from 'api/actions/auth-set-user-department-permissions/auth-set-user-department-permissions-response';

type AuthSetUserDepartmentPermissionsQueryParameters = Record<string, any>;

/**
 * The path of the AuthSetUserDepartmentPermissions action.
 */
export const authSetUserDepartmentPermissionsActionPath = new SweetPath<
  keyof AuthSetUserDepartmentPermissionsPathParameters
>('/api/auth/user-department/set-permissions', { path: '{param}' });

/**
 * Creates a contextualized AuthSetUserDepartmentPermissions actions.
 */
export default function createAuthSetUserDepartmentPermissionsAction(axios: AxiosInstance) {
  return function authSetUserDepartmentPermissionsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthSetUserDepartmentPermissionsQueryParameters;
    payload: AuthSetUserDepartmentPermissionsPayload;
  }) {
    const path = authSetUserDepartmentPermissionsActionPath.original;

    return axios.put<
      AuthSetUserDepartmentPermissionsResponse,
      AuthSetUserDepartmentPermissionsResponse,
      AuthSetUserDepartmentPermissionsPayload
    >(path, payload);
  };
}
