import { Select } from '@mantine/core';
import CompanyQualificationItem from 'components/inputs/company-qualification-select/CompanyQualificationItem';
import { IFormInputSpecQualification } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useMemo } from 'react';

/**
 * Preview for a certificate input.
 */
export default function QualificationInputPreview({ spec }: { spec: IFormInputSpecQualification }) {
  const { deviceType } = useTemplateEditorData();

  const data = useMemo(
    () => [
      { label: 'Oprávnenie organizácie', value: '1', image: deviceType.id },
      { label: 'Oprávnenie externého RT', value: '2', image: deviceType.id },
    ],
    [deviceType.id]
  );

  return (
    <Select
      data={data}
      itemComponent={CompanyQualificationItem}
      placeholder="Vyberte oprávnenie firmy"
      searchable
      readOnly={spec.readOnly}
      required={spec.required}
      size="sm"
    />
  );
}
