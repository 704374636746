import { UseFormReturnType } from '@mantine/form';
import { IFormInputSpec, IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useCallback, useMemo } from 'react';
import { Box, Group, Select } from '@mantine/core';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';

/**
 * Sub-form for number input properties
 */
export default function DatePlusPeriodInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  const { allInputs } = useFormInputs();

  const getOptions = useCallback(
    (type: IFormInputType) =>
      allInputs
        .filter(({ context, spec }) => context === 'revision' && spec.type === type)
        .map(({ spec: { name, label } }) => ({ value: name, label })),
    [allInputs]
  );

  const dateOptions = useMemo(() => getOptions('date'), [getOptions]);
  const periodOptions = useMemo(() => getOptions('period'), [getOptions]);
  const checkboxOptions = useMemo(() => getOptions('checkbox'), [getOptions]);

  return (
    <>
      <Select required size="sm" label="Pole - dátum" data={dateOptions} {...form.getInputProps('dateInput')} />
      <Select required size="sm" label="Pole - perióda" data={periodOptions} {...form.getInputProps('periodInput')} />
      <Select
        size="sm"
        label={
          <Group spacing={4}>
            <Box>Pole - podmienka</Box>
            <QuestionMarkTooltip
              size={14}
              multiline
              width={220}
              label="Ak pole nie je zaškrtnuté, hodnotou bude prázdny reťazec"
            />
          </Group>
        }
        data={checkboxOptions}
        {...form.getInputProps('checkboxInput')}
      />
    </>
  );
}
