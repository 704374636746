import { Checkbox, Stack } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { PredefinedDataTypeSelect } from 'components/selects/PredefinedDataTypeSelect';
import P3Regular from 'components/typography/P3Regular';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';

/**
 * Sub-form for number input properties
 */
export default function PredefinedSelectInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  const { deviceType } = useTemplateEditorData();

  return (
    <>
      <PredefinedDataTypeSelect
        required
        size="sm"
        deviceTypeId={deviceType.id}
        label="Typ predvoleného údaju"
        {...form.getInputProps('dataTypeId')}
      />
      <Stack spacing={12}>
        <P3Regular mb={-6} c="gray.6">
          Prepínače
        </P3Regular>
        <Checkbox
          size="md"
          label="Použiť popis namiesto názvu"
          {...form.getInputProps('useDescription', { type: 'checkbox' })}
        />
      </Stack>
    </>
  );
}
