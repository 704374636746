import { Box, Button, CopyButton, Group, rem, Tooltip } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';

/**
 * Displays the input name.
 */
export default function InputNameDisplay({ name }: { name: string }) {
  return (
    <Group noWrap position="apart" spacing={12} py={12} px={16} bg="gray.0" style={{ borderRadius: rem(4) }}>
      <Group noWrap spacing={12}>
        <P1Regular color="gray.6">ID</P1Regular>
        <Box w={1} h={32} bg="gray.4" />
        <Group noWrap spacing={4}>
          <P2Regular color="gray.8">{name}</P2Regular>
        </Group>
      </Group>
      <CopyButton value={name}>
        {({ copy, copied }) => (
          <Tooltip label="Identifikátor skopírovaný" disabled={!copied} withArrow={false}>
            <Button variant="subtle-gray" size="sm" leftIcon={<IconCopy />} onClick={copy}>
              Kopírovať
            </Button>
          </Tooltip>
        )}
      </CopyButton>
    </Group>
  );
}
