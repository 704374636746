import { Box, SimpleGrid } from '@mantine/core';
import RevisionField from 'components/forms/revision/fill-out/RevisionField';
import { IFormRow } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';

/**
 * Single row in a section of the revision form.
 */
export default function RevisionRow({ row: { inputs, id } }: { row: IFormRow }) {
  const allInputsHidden = useMemo(() => inputs.every(({ spec: { hidden } }) => hidden), [inputs]);

  return (
    <Box display={allInputsHidden ? 'none' : 'block'}>
      <SimpleGrid cols={inputs.length} spacing={40} key={id}>
        {inputs.map((input) => (
          <RevisionField key={input.id} field={input} namePrefix="fields." />
        ))}
      </SimpleGrid>
    </Box>
  );
}
