import { Box, Button, Group, SimpleGrid, Stack, TextInput, Textarea } from '@mantine/core';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { isEmpty, noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import PickDepartments, { PickDepartmentItem } from 'components/inputs/multi-checkbox/PickDepartments';
import FormWrapper from 'components/forms/FormWrapper';
import FormInputGroup from 'components/forms/FormInputGroup';
import { DEVICE_TYPE_MAP } from 'model/DeviceType';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import H3Medium from 'components/typography/H3Medium';
import P1Regular from 'components/typography/P1Regular';
import PickOrganizations, { PickOrganizationItem } from 'components/inputs/multi-checkbox/PickOrganizations';
import P1Medium from 'components/typography/P1Medium';
import ThreeWaySelect, { SelectedEntityRow } from 'components/forms/device-subtype/ThreeWaySelect';
import P2Regular from 'components/typography/P2Regular';
import { useConfirm } from 'components/modals/message/MessageProvider';
import EvidenceFieldRow, { EvidenceField } from 'components/forms/device-subtype/EvidenceFieldRow';
import { useDefineEvidenceField } from 'components/forms/device-subtype/DefineEvidenceFieldProvider';
import { FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS } from 'env';
import { nanoid } from 'nanoid';

export interface DeviceSubtypeFormData {
  parentDeviceTypeId: number;
  deviceSubtypeName: string;
  deviceSubtypeShortName?: string;
  description?: string;
  restriction: 'global' | 'department' | 'organization';
  departments: PickDepartmentItem[] | { departmentId: number; departmentName: string }[];
  organizations:
    | PickOrganizationItem[]
    | {
        organizationId: number;
        organizationName: string;
      }[];
  evidenceFields: (EvidenceField & { key: string })[];
}

export const deviceSubtypeFormInitialValues = {
  parentDeviceTypeId: 0,
  deviceSubtypeName: '',
  deviceSubtypeShortName: '',
  restriction: 'department' as const,
  departments: [],
  organizations: [],
  evidenceFields: [],
};

/**
 * Parameters of the OrganizationForm.
 */
export interface DeviceSubtypeFormProps extends FormProps<DeviceSubtypeFormData> {
  readOnly?: Partial<Record<keyof DeviceSubtypeFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for creating and editing device subtypes.
 */
export default function DeviceSubtypeForm({
  initialValues = deviceSubtypeFormInitialValues,
  onSubmit = noop,
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" size={24} />,
}: DeviceSubtypeFormProps) {
  const { confirm } = useConfirm();
  const { defineEvidenceField } = useDefineEvidenceField();
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const [openedDepartments, { close: closeDepartments, open: openDepartments }] = useDisclosure(false);
  const [openedOrganizations, { close: closeOrganizations, open: openOrganizations }] = useDisclosure(false);

  const form = useForm({
    initialValues,
    validate: {
      deviceSubtypeName: createValidator([required]),
      organizations: (value) => {
        if (form.values.restriction === 'organization' && isEmpty(value)) {
          return 'Vyberte aspoň jednu organizáciu';
        }

        return null;
      },
      departments: (value) => {
        if (form.values.restriction === 'department' && isEmpty(value)) {
          return 'Vyberte aspoň jedno stredisko';
        }

        return null;
      },
    },
  });

  const sectionTitle = useMemo(
    () => (
      <Group noWrap spacing={8}>
        <DeviceTypeIcon typeId={form.values.parentDeviceTypeId} size="md" />
        <H3Medium color="gray.7">{DEVICE_TYPE_MAP.get(form.values.parentDeviceTypeId)?.name}</H3Medium>
      </Group>
    ),
    [form.values.parentDeviceTypeId, DEVICE_TYPE_MAP]
  );

  /**
   * Confirms the removal of the organization from the list of selected organizations.
   */
  const confirmRemoveOrganization = useCallback(
    (organizationName: string, index: number) => {
      confirm({
        title: 'Odstrániť organizáciu',
        content: `Naozaj chcete odstrániť organizáciu ${organizationName} zo zoznamu?`,
        onConfirm: () => form.removeListItem('organizations', index),
      });
    },
    [confirm]
  );

  /**
   * Confirms the removal of the department from the list of selected departments.
   */
  const confirmRemoveDepartment = useCallback(
    (departmentName: string, index: number) => {
      confirm({
        title: 'Odstrániť stredisko',
        content: `Naozaj chcete odstrániť stredisko ${departmentName} zo zoznamu?`,
        onConfirm: () => form.removeListItem('departments', index),
      });
    },
    [confirm]
  );

  const confirmRemoveEvidenceField = useCallback(
    ({ field, index }: { field: EvidenceField; index: number }) => {
      confirm({
        title: 'Odstrániť evidenčné pole',
        content: `Naozaj chcete odstrániť evidenčné pole ${field.fieldName} zo zoznamu?`,
        onConfirm: () => form.setFieldValue(`evidenceFields.${index}`, { ...field, deleted: true }),
      });
    },
    [confirm]
  );

  /**
   * Allows to create or edit evidence field.
   */
  const openEvidenceFieldModal = useCallback(
    ({
      type,
      index,
      field = { key: nanoid(), fieldName: '', fieldType: 'number', isRequired: false, deleted: false },
    }: {
      type: 'create' | 'edit';
      index?: number;
      field?: EvidenceField;
    }) => {
      const title = type === 'create' ? 'Pridať nové evidenčné pole' : 'Upraviť evidenčné pole';

      defineEvidenceField({
        type,
        title,
        onDefine: (field) => {
          if (type === 'create') {
            form.insertListItem('evidenceFields', field);
          } else {
            form.setFieldValue(`evidenceFields.${index}`, field);
          }
        },
        initialValues: field,
      });
    },
    [defineEvidenceField]
  );

  const addOrganizationButton = useMemo(
    () => (
      <Box>
        <Button onClick={openOrganizations} variant="subtle" leftIcon={<IconPlus />}>
          Pridať organizácie
        </Button>
      </Box>
    ),
    [openOrganizations]
  );

  const addDepartmentButton = useMemo(
    () => (
      <Box>
        <Button onClick={openDepartments} variant="subtle" leftIcon={<IconPlus />}>
          Pridať strediská
        </Button>
      </Box>
    ),
    [openDepartments]
  );

  const addEvidenceFieldButton = useMemo(
    () => (
      <Box>
        <Button onClick={() => openEvidenceFieldModal({ type: 'create' })} variant="subtle" leftIcon={<IconPlus />}>
          Pridať evidenčné pole
        </Button>
      </Box>
    ),
    [openEvidenceFieldModal]
  );

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle={sectionTitle}>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov typu zariadenia"
            placeholder="Názov typu zariadenia"
            size="lg"
            name="amperia-deviceSubtypeName"
            {...form.getInputProps('deviceSubtypeName')}
          />
          <TextInput
            label="Krátky názov typu zariadenia"
            placeholder="Krátky názov typu zariadenia"
            size="lg"
            name="amperia-deviceSubtypeShortName"
            {...form.getInputProps('deviceSubtypeShortName')}
          />
        </SimpleGrid>

        <SimpleGrid cols={2} spacing={40}>
          <Textarea
            label="Popis typu zariadenia"
            placeholder="Popis typu zariadenia"
            size="lg"
            name="amperia-description"
            {...form.getInputProps('description')}
          />
        </SimpleGrid>
      </FormInputGroup>
      <FormInputGroup groupTitle="Vymedzenie pre:">
        <SimpleGrid cols={2} spacing={40}>
          <ThreeWaySelect {...form.getInputProps('restriction')} />
        </SimpleGrid>
        {form.values.restriction === 'global' && (
          <P1Regular color="gray.6">Typ zariadenia bude dostupný na všetkých strediskách.</P1Regular>
        )}
        {form.values.restriction === 'organization' &&
          (isEmpty(form.values.organizations) ? (
            <Stack align="flex-start">
              <P1Regular color="gray.6">
                Zatiaľ nie sú pridané žiadne organizácie. Tento typ zariadenia bude dostupný na všetkých strediskách
                vybraných organizácií.
              </P1Regular>
              <P2Regular color="red.8">{form.errors.organizations}</P2Regular>
              {addOrganizationButton}
            </Stack>
          ) : (
            <Stack pt={10} spacing={24}>
              <Box>
                <Box bg="gray.0" style={{ borderRadius: '4px' }} p={16}>
                  <P1Medium color="gray.8">Organizácia</P1Medium>
                </Box>

                {form.values.organizations.map((organization, index) => (
                  <SelectedEntityRow
                    key={organization.organizationId}
                    name={organization.organizationName}
                    deleteText="Zmazať organizáciu"
                    onDelete={() => {
                      confirmRemoveOrganization(organization.organizationName, index);
                    }}
                  />
                ))}
              </Box>

              {addOrganizationButton}
            </Stack>
          ))}
        {form.values.restriction === 'department' &&
          (isEmpty(form.values.departments) ? (
            <Stack align="flex-start">
              <P1Regular color="gray.6">
                Zatiaľ nie sú pridané žiadne strediská. Tento typ zariadenia bude dostupný len pre vybrané strediská.
              </P1Regular>
              <P2Regular color="red.8">{form.errors.departments}</P2Regular>
              {addDepartmentButton}
            </Stack>
          ) : (
            <Stack pt={10} spacing={24}>
              <Box>
                <Box bg="gray.0" style={{ borderRadius: '4px' }} p={16}>
                  <P1Medium color="gray.8">Stredisko</P1Medium>
                </Box>

                {form.values.departments.map((department, index) => (
                  <SelectedEntityRow
                    key={department.departmentId}
                    name={department.departmentName}
                    deleteText="Zmazať stredisko"
                    onDelete={() => {
                      confirmRemoveDepartment(department.departmentName, index);
                    }}
                  />
                ))}
              </Box>

              {addDepartmentButton}
            </Stack>
          ))}
      </FormInputGroup>

      {FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS && (
        <FormInputGroup groupTitle="Evidenčné polia">
          {isEmpty(form.values.evidenceFields) || form.values.evidenceFields.every((field) => field.deleted) ? (
            <Stack>
              <P1Regular color="gray.6">
                Zatiaľ nie sú pridané evidenčné polia. Evidenčné polia budú dostupné iba daný typ zariadenia.
              </P1Regular>
              <P2Regular color="red.8">{form.errors.evidenceFields}</P2Regular>
              {addEvidenceFieldButton}
            </Stack>
          ) : (
            <Stack pt={10} spacing={24}>
              <Box>
                <SimpleGrid cols={4} bg="gray.0" style={{ borderRadius: '4px' }} p={16}>
                  <P1Medium color="gray.8">Názov</P1Medium>
                  <P1Medium color="gray.8">Typ</P1Medium>
                  <P1Medium color="gray.8">Povinné</P1Medium>
                  <P1Medium color="gray.8" />
                </SimpleGrid>

                {form.values.evidenceFields
                  .filter((field) => !field.deleted)
                  .map((field, index) => (
                    <EvidenceFieldRow
                      {...field}
                      onDelete={() => {
                        confirmRemoveEvidenceField({ field, index });
                      }}
                      onEdit={() => {
                        openEvidenceFieldModal({ type: 'edit', index, field });
                      }}
                    />
                  ))}
              </Box>

              {addEvidenceFieldButton}
            </Stack>
          )}
        </FormInputGroup>
      )}

      <PickOrganizations
        opened={openedOrganizations}
        onClose={closeOrganizations}
        permissionSlug="define-device-subtypes"
        onPick={(organizations) => {
          for (const item of organizations) {
            form.insertListItem('organizations', item);
          }
        }}
        excludeIds={form.values.organizations.map(({ organizationId }) => organizationId)}
      />

      <PickDepartments
        opened={openedDepartments}
        onClose={closeDepartments}
        permissionSlug="define-device-subtypes"
        onPick={(departments) => {
          for (const item of departments) {
            form.insertListItem('departments', item);
          }
        }}
        excludeIds={form.values.departments.map(({ departmentId }) => departmentId)}
      />
    </FormWrapper>
  );
}
