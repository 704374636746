import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import HtmlBlockControl from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/html-block/HtmlBlockControl';
import { Box, Center, Group, Menu, Stack } from '@mantine/core';
import NamedControlGroup from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/NamedControlGroup';
import Ruler from 'pages/revisions-module/template-editor/editors/template/toolbar/Ruler';
import { IconSpace } from '@tabler/icons-react';
import DropdownControl from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';

// Edit
import Undo from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/edit/Undo';
import Redo from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/edit/Redo';
// View
import Fullscreen from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/view/Fullscreen';
import Zoom from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/view/Zoom';
// Insert
import InsertTable from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertTable';
import InsertSpecialSymbol from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertSpecialSymbol';
import InsertPlaceholder from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertPlaceholder';
import InsertHorizontalLine from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertHorizontalLine';
import InsertPageBreak from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertPageBreak';
import InsertSectionEnd from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertSectionEnd';
import InsertNonBreakingSpace from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertNonBreakingSpace';
import InsertTabulator from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertTabulator';
import InsertDeviceLoop from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertDeviceLoop';
import InsertIfBlock from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertIfBlock';
import InsertDeviceFaultsPlaceholder from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertDeviceFaultsPlaceholder';
import InsertDeviceMeasurementsPlaceholder from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/insert/InsertDeviceMeasurementsPlaceholder';
// Format
import Bold from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Bold';
import Italic from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Italic';
import Underline from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Underline';
import Strike from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Strike';
import Subscript from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Subscript';
import Superscript from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Superscript';
import TextStyle from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/TextStyle';
import TextMarks from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/TextMarks';
import BulletList from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/list/BulletList';
import OrderedList from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/list/OrderedList';
import List from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/list/List';
import Align from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/align/Align';
// Appearance
import PageMargin from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/appearance/PageMargin';
import PageBorder from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/appearance/PageBorder';
import PageTypography from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/appearance/PageTypography';
import PageAdvanced from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/appearance/PageAdvanced';
// Table
import MergeCells from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/MergeCells';
import CellBorderWidth from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/CellBorderWidth';
import AddRowAfter from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/AddRowAfter';
import AddRowBefore from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/AddRowBefore';
import AddColumnAfter from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/AddColumnAfter';
import AddColumnBefore from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/AddColumnBefore';
import RemoveRow from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/RemoveRow';
import RemoveColumn from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/RemoveColumn';
import RemoveTable from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/table/RemoveTable';

/**
 * The toolbar of the wysiwyg editor.
 */
export default function WysiwygEditorToolbar() {
  const { editor } = useRichTextEditorContext();
  const isTableActive = editor?.isActive('table') ?? false;

  return (
    <RichTextEditor.Toolbar sticky stickyOffset={0}>
      <Stack spacing={4} w="100%">
        <Group spacing={4}>
          <NamedControlGroup label="Upraviť">
            <Undo kind="menu-item" />
            <Redo kind="menu-item" />
          </NamedControlGroup>

          <NamedControlGroup label="Zobraziť">
            <Fullscreen kind="menu-item" />
          </NamedControlGroup>

          <NamedControlGroup label="Vložiť">
            <InsertTable kind="menu-item" />
            <Menu.Divider my={4} />
            <InsertPlaceholder kind="menu-item" />
            <InsertDeviceLoop kind="menu-item" />
            <InsertIfBlock kind="menu-item" />
            <InsertDeviceFaultsPlaceholder kind="menu-item" />
            <InsertDeviceMeasurementsPlaceholder kind="menu-item" />
            <Menu.Divider my={4} />
            <InsertSpecialSymbol kind="menu-item" />
            <DropdownControl kind="menu-item" label="Medzery" icon={<IconSpace />}>
              <InsertNonBreakingSpace kind="menu-item" hideIcon />
              <InsertTabulator kind="menu-item" hideIcon />
            </DropdownControl>
            <Menu.Divider my={4} />
            <InsertHorizontalLine kind="menu-item" />
            <InsertPageBreak kind="menu-item" />
            <InsertSectionEnd kind="menu-item" />
          </NamedControlGroup>

          <NamedControlGroup label="Formát">
            <TextMarks kind="menu-item" />
            <TextStyle kind="menu-item" />
            <Align kind="menu-item" />
            <List kind="menu-item" />
          </NamedControlGroup>

          <NamedControlGroup label="Vzhľad">
            <PageMargin kind="menu-item" />
            <PageBorder kind="menu-item" />
            <PageTypography kind="menu-item" />
            <Menu.Divider my={4} />
            <PageAdvanced kind="menu-item" />
          </NamedControlGroup>

          {isTableActive && (
            <NamedControlGroup label="Tabuľka">
              <CellBorderWidth kind="menu-item" />
              <MergeCells kind="menu-item" />
              <Menu.Divider my={4} />
              <AddRowAfter kind="menu-item" />
              <AddRowBefore kind="menu-item" />
              <AddColumnAfter kind="menu-item" />
              <AddColumnBefore kind="menu-item" />
              <Menu.Divider my={4} />
              <RemoveRow kind="menu-item" />
              <RemoveColumn kind="menu-item" />
              <RemoveTable kind="menu-item" />
            </NamedControlGroup>
          )}
        </Group>

        <Group py={4} px={16} bg="blue.0" style={{ borderRadius: '18px' }} spacing={0}>
          <Group spacing={0}>
            <RichTextEditor.ControlsGroup>
              <Undo kind="action-icon" />
              <Redo kind="action-icon" />
              <Zoom kind="button" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <TextStyle kind="button" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <Bold kind="action-icon" />
              <Italic kind="action-icon" />
              <Underline kind="action-icon" />
              <Strike kind="action-icon" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <Superscript kind="action-icon" />
              <Subscript kind="action-icon" />
              <InsertSpecialSymbol kind="action-icon" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <BulletList kind="action-icon" />
              <OrderedList kind="action-icon" />
              <Align kind="action-icon" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <InsertIfBlock kind="action-icon" />
              <InsertDeviceLoop kind="action-icon" />
              <InsertDeviceFaultsPlaceholder kind="action-icon" />
              <InsertDeviceMeasurementsPlaceholder kind="action-icon" />
              <InsertPlaceholder kind="action-icon" />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              {isTableActive ? (
                <>
                  <CellBorderWidth kind="action-icon" />
                  <MergeCells kind="action-icon" />
                  <Box w={8}></Box>
                  <AddRowAfter kind="action-icon" />
                  <AddRowBefore kind="action-icon" />
                  <AddColumnAfter kind="action-icon" />
                  <AddColumnBefore kind="action-icon" />
                  <Box w={8}></Box>
                  <RemoveRow kind="action-icon" />
                  <RemoveColumn kind="action-icon" />
                  <RemoveTable kind="action-icon" />
                </>
              ) : (
                <InsertTable kind="action-icon" />
              )}
            </RichTextEditor.ControlsGroup>
          </Group>

          <Center ml="auto">
            <HtmlBlockControl />
          </Center>
        </Group>

        <Ruler />
      </Stack>
    </RichTextEditor.Toolbar>
  );
}
