import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import InputConfigurator, {
  InputConfiguratorProps,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfigurator';
import { useDisclosure } from '@mantine/hooks';
import { noop } from 'lodash';

/**
 * Parameters of the ConfigureInput function.
 */
export type IConfigureInputParams = Omit<InputConfiguratorProps, 'opened' | 'onClose'>;

/**
 * The ConfigureInput function.
 */
type ConfigureInput = (params: IConfigureInputParams) => void;

/**
 * Context for the input configurator.
 */
const InputConfiguratorContext = createContext<{ configureInput: ConfigureInput }>(undefined!);

/**
 * Provides the input configurator to the form editor.
 */
export function InputConfiguratorProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false);

  const [params, setParams] = useState<IConfigureInputParams>({
    onSubmit: noop,
  });

  const configureInput: ConfigureInput = useCallback(
    (params: IConfigureInputParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const value = useMemo(() => ({ configureInput }), [configureInput]);

  return (
    <InputConfiguratorContext.Provider value={value}>
      {children}
      <InputConfigurator opened={opened} onClose={close} {...params} />
    </InputConfiguratorContext.Provider>
  );
}

/**
 * Uses the input configurator.
 */
export function useInputConfigurator() {
  return useContext(InputConfiguratorContext);
}
