import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import UserForm, { UserFormData, userFormInitialValues } from 'components/forms/user/UserForm';
import { USER_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EDIT_USER_PAGE_PATH, USERS_PAGE_PATH } from 'routes/paths';
import { DUPLICATE_USER_ERROR_CODE, ERROR_NOTIFICATION_COLOR, SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add a new user.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=240%3A5833 Figma Design}
 * - {@link https://www.notion.so/Administrative-Create-New-User-c6e26e99643f4fbd94a2fb5c565f72c1?pvs=4 Notion Page}
 */
export default function AddUserPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();
  const { getPermissionTemplate, getDefaultPermissionTemplate } = usePermissionDataProvider();

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    preNominals,
    postNominals,
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    roleId,
    organizationId,
    inOrganizations,
    inDepartments,
  }: UserFormData) {
    const userCreateAction = getAction('UserCreate');

    const mappedInOrganizations = Object.entries(inOrganizations)
      .map(([organizationId, value]) => ({
        organizationId: Number(organizationId),
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        permissions: value.permissions,
        checked: value.checked,
      }))
      .filter(({ checked }) => checked)
      .map(({ organizationId, permissions, templateId }) => ({
        organizationId,
        permissions: permissions ?? [],
        templateId,
      }));

    const mappedInDepartments = Object.entries(inDepartments)
      .map(([departmentId, value]) => ({
        departmentId: Number(departmentId),
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        permissions: value.permissions,
        checked: value.checked,
      }))
      .filter(({ checked }) => checked)
      .map(({ departmentId, permissions, templateId }) => ({
        departmentId,
        permissions: permissions ?? [],
        templateId,
      }));

    return userCreateAction({
      payload: {
        preNominals,
        postNominals,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        roleId: Number(roleId),
        organizationId: Number(organizationId),
        inOrganizations: mappedInOrganizations,
        inDepartments: mappedInDepartments,
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Používateľ bol vytvorený',
          message: `Používateľ ${firstName} ${lastName} bol úspešne vytvorený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (USER_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_USER_PAGE_PATH.insert({ userId: response.userId }));
        } else if (USER_REDIRECT_AFTER_SAVE === 'list') {
          navigate(USERS_PAGE_PATH.original);
        }
      })
      .catch((error) => {
        if (error.response.data.error.code === DUPLICATE_USER_ERROR_CODE) {
          notifications.show({
            title: 'Chyba!',
            message: 'Používateľ s danou emailovou adresou už existuje.',
            color: ERROR_NOTIFICATION_COLOR,
          });
        } else {
          panic(error);
        }
      });
  }

  return (
    <DashboardLayout
      title="Pridanie nového používateľa"
      breadcrumbs={[
        { title: 'Používatelia', link: USERS_PAGE_PATH.original },
        { title: 'Pridanie nového používateľa' },
      ]}
    >
      <UserForm
        onSubmit={onSubmit}
        initialValues={{
          ...userFormInitialValues,
          organizationId: searchParams.get('organizationId') ?? '',
        }}
        hideSections={{
          password: false,
          inOrganizations: true,
          inDepartments: true,
        }}
        primaryButtonText="Vytvoriť"
      />
    </DashboardLayout>
  );
}
