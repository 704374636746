import { IFormInputSpecPredefinedList } from 'pages/revisions-module/template-editor/editors/form/types';
import PredefinedDataCustomInput from 'components/inputs/predefined-data-custom-input/PredefinedDataCustomInput';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';

/**
 * Custom predefined data input for the revision form.
 */
export default function RevisionFieldPredefinedList({ spec, name }: RevisionFieldProps<IFormInputSpecPredefinedList>) {
  const { form, revision } = useFillOutRevisionDataProvider();

  return (
    <PredefinedDataCustomInput
      deviceTypeId={revision.deviceType.deviceTypeId}
      predefinedDataType={Number(spec.dataTypeId)}
      withMoreInfo={spec.withMoreInfo}
      allowDuplicates={spec.allowDuplicates}
      {...form.getInputProps(name)}
    />
  );
}
