import { MantineThemeOverride } from '@mantine/core';
import { components } from 'theme/components';

const theme: MantineThemeOverride = {
  fontFamily: 'Roboto, sans-serif', // todo: define
  black: '#353E45', // todo: define
  white: '#FFFFFF', // todo: define
  primaryColor: 'blue',
  primaryShade: {
    light: 8,
  },
  fontSizes: {
    md: '14px',
  },
  // See https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=73-293&mode=dev
  colors: {
    gray: [
      '#F6F6F8', // Gray 0
      '#EBEBF0', // Gray 1
      '#DCDCE5', // Gray 2
      '#D1D1DC', // Gray 3
      '#C5C6D3', // Gray 4
      '#A2A5B9', // Gray 5
      '#6E7291', // Gray 6
      '#585B74', // Gray 7
      '#353746', // Gray 8
      '#282934', // Gray 9
    ],
    blue: [
      '#F5F8FF', // Blue 0
      '#DBE4FF', // Blue 1
      '#BAC8FF', // Blue 2
      '#91A7FF', // Blue 3
      '#748FFC', // Blue 4
      '#5C7CFA', // Blue 5
      '#4C6EF5', // Blue 6
      '#4263EB', // Blue 7
      '#3B5BDB', // Blue 8
      '#364FC7', // Blue 9
    ],
    red: [
      '#FFF5F7', // Red 0
      '#FFE3E8', // Red 1
      '#FFC9D2', // Red 2
      '#FFA8B7', // Red 3
      '#FF879B', // Red 4
      '#FF6B84', // Red 5
      '#FA526E', // Red 6
      '#F03E5C', // Red 7
      '#E0314E', // Red 8
      '#C92A45', // Red 9
    ],
    green: [
      '#EFFAEF', // Green 0
      '#DBF5DB', // Green 1
      '#C8EFC8', // Green 2
      '#B0E8B0', // Green 3
      '#A4E5A4', // Green 4
      '#90DF90', // Green 5
      '#68D468', // Green 6
      '#40C940', // Green 7
      '#2FA72F', // Green 8
      '#1E6B1E', // Green 9
    ],
    white: [
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
      '#FFFFFF', // White
    ],
  },
  components,
};

export default theme;
