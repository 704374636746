import { Box, Flex } from '@mantine/core';
import { DeviceRow } from 'components/tables/device/types';
import { IconBuildingSkyscraper } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for organization name.
 */
export default function OrganizationNameColumn({ data: { organization } }: { data: DeviceRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <IconBuildingSkyscraper stroke="1.5" />
      </Box>
      <P2Regular color="gray.8">{organization.organizationName}</P2Regular>
    </Flex>
  );
}
