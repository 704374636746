import { Flex } from '@mantine/core';
import { FaultRow } from 'components/tables/fault/types';
import { BadgeFixed } from 'components/tables/Badge';
import P2Medium from 'components/typography/P2Medium';
import Excerpt from 'components/Excerpt';

/**
 * Column for the fault name.
 */
export default function FaultNameColumn({ data: { faultName, fixed } }: { data: FaultRow }) {
  const opacity = fixed && fixed.fixedAt !== undefined ? 0.6 : 1;

  return (
    <Flex gap={10} align="center">
      {fixed && fixed.fixedAt !== undefined && <BadgeFixed text="Vyriešená" />}
      <P2Medium color="gray.8" style={{ opacity }}>
        <Excerpt text={faultName} length={100} />
      </P2Medium>
    </Flex>
  );
}
