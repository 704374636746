import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import { IconHash } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';
import PlaceholderMenu from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/PlaceholderMenu';

/**
 * Control used to insert a placeholder.
 */
export default function InsertPlaceholder({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <DropdownControl icon={<IconHash />} kind={kind} label="Hodnota z formulára">
      <PlaceholderMenu onClick={(placeholder) => editor.chain().focus().insertPlaceholder(placeholder).run()} />
    </DropdownControl>
  );
}
