import { MantineThemeOverride } from '@mantine/core';

export type NumberInputTheme = NonNullable<MantineThemeOverride['components']>['NumberInput'];

export const NumberInput: NumberInputTheme = {
  defaultProps: {
    decimalSeparator: ',',
    hideControls: true,
  },
};
