import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthSetUserOrganizationPermissionsPayload } from 'api/actions/auth-set-user-organization-permissions/auth-set-user-organization-permissions-payload';
import { AuthSetUserOrganizationPermissionsPathParameters } from 'api/actions/auth-set-user-organization-permissions/auth-set-user-organization-permissions-path-parameters';
import { AuthSetUserOrganizationPermissionsResponse } from 'api/actions/auth-set-user-organization-permissions/auth-set-user-organization-permissions-response';

type AuthSetUserOrganizationPermissionsQueryParameters = Record<string, any>;

/**
 * The path of the AuthSetUserOrganizationPermissions action.
 */
export const authSetUserOrganizationPermissionsActionPath = new SweetPath<
  keyof AuthSetUserOrganizationPermissionsPathParameters
>('/api/auth/user-organization/set-permissions', { path: '{param}' });

/**
 * Creates a contextualized AuthSetUserOrganizationPermissions actions.
 */
export default function createAuthSetUserOrganizationPermissionsAction(axios: AxiosInstance) {
  return function authSetUserOrganizationPermissionsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthSetUserOrganizationPermissionsQueryParameters;
    payload: AuthSetUserOrganizationPermissionsPayload;
  }) {
    const path = authSetUserOrganizationPermissionsActionPath.original;

    return axios.put<
      AuthSetUserOrganizationPermissionsResponse,
      AuthSetUserOrganizationPermissionsResponse,
      AuthSetUserOrganizationPermissionsPayload
    >(path, payload);
  };
}
