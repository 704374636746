import { createContext, useCallback, useContext, useMemo } from 'react';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';

interface IPipelineDataContext {
  automations: IAutomation[];
  mutateAutomations: (mutate: (automations: IAutomation[]) => IAutomation[]) => void;
  addAutomation: (automation: IAutomation) => void;
  removeAutomation: (automationId: string) => void;
  updateAutomation: (automation: IAutomation) => void;
}

const PipelineDataContext = createContext<IPipelineDataContext>(undefined!);

/**
 * Provides the pipeline data to the pipeline editor.
 */
export function PipelineDataProvider({ children }: { children: React.ReactNode }) {
  const {
    pipeline: { automations },
    setPipeline,
  } = useTemplateEditorData();

  const mutateAutomations = useCallback((mutate: (automations: IAutomation[]) => IAutomation[]) => {
    setPipeline((pipeline) => ({ ...pipeline, automations: mutate(pipeline.automations) }));
  }, []);

  const addAutomation = useCallback((automation: IAutomation) => {
    mutateAutomations((automations) => [...automations, automation]);
  }, []);

  const removeAutomation = useCallback((automationId: string) => {
    mutateAutomations((automations) => automations.filter((automation) => automation.id !== automationId));
  }, []);

  const updateAutomation = useCallback((automation: IAutomation) => {
    mutateAutomations((automations) =>
      automations.map((existingAutomation) =>
        existingAutomation.id === automation.id ? automation : existingAutomation
      )
    );
  }, []);

  const value = useMemo(
    () => ({ automations, mutateAutomations, addAutomation, removeAutomation, updateAutomation }),
    [automations, mutateAutomations, addAutomation, removeAutomation, updateAutomation]
  );

  return <PipelineDataContext.Provider value={value}>{children}</PipelineDataContext.Provider>;
}

/**
 * Use the pipeline data from the pipeline editor.
 */
export function usePipelineData() {
  return useContext(PipelineDataContext);
}
