import ConfirmWithMessageModal from 'components/modals/confirm-with-message/ConfirmWithMessageModal';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useState } from 'react';

/**
 * Parameters of the confirmWithMessage function.
 */
export interface IConfirmWithMessageParams {
  message: string;
  title?: string;
  requiredReason?: boolean;
  onConfirmWithMessage: (reason?: string) => void;
}

/**
 * ConfirmWithMessage function.
 */
export type IConfirmWithMessage = (params: IConfirmWithMessageParams) => void;

/**
 * Typedef for the ConfirmWithMessageProvider context.
 */
export interface IConfirmWithMessageContext {
  confirmWithMessage: IConfirmWithMessage;
}

/**
 * The confirmWithMessage context.
 */
const ConfirmWithMessageContext = createContext<IConfirmWithMessageContext>(undefined!);

/**
 * Provides the ConfirmWithMessageContext.
 */
export default function ConfirmWithMessageProvider({ children }: { children: React.ReactNode }) {
  const [opened, setOpened] = useState(false);
  const [params, setParams] = useState<IConfirmWithMessageParams>({
    message: '',
    onConfirmWithMessage: noop,
  });

  /**
   * ConfirmWithMessage function.
   */
  const confirmWithMessage: IConfirmWithMessage = useCallback(
    (params) => {
      setParams(params);
      setOpened(true);
    },
    [setParams, setOpened]
  );

  return (
    <ConfirmWithMessageContext.Provider value={{ confirmWithMessage }}>
      {children}
      <ConfirmWithMessageModal {...params} opened={opened} onClose={() => setOpened(false)} />
    </ConfirmWithMessageContext.Provider>
  );
}

/**
 * Uses the ConfirmWithMessageContext.
 */
export function useConfirmWithMessage() {
  return useContext(ConfirmWithMessageContext);
}
