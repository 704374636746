import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'deviceType.deviceTypeId' as const;

/**
 * Parameters of the DeviceTypeSelectFilter component.
 */
export interface DeviceTypeSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the deviceType column.
 *
 * This filter assumes:
 *   - column field is `deviceType.deviceTypeId`
 *   - context contains `initialFilters` with `deviceType.deviceTypeId` filter
 */
export default function DeviceTypeSelectFilter({ api, context }: DeviceTypeSelectFilterProps) {
  const [value, setValue] = useState<string | null>(String(context?.initialFilters?.[FILTER_KEY]?.filter));

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <DeviceTypeSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky zariadenia"
      value={value}
      onChange={onChange}
      styles={{ dropdown: { minWidth: rem(220) } }}
    />
  );
}
