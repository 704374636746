import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionListTemplatesAndVisualsPathParameters } from 'api/actions/revision-list-templates-and-visuals/revision-list-templates-and-visuals-path-parameters';
import { RevisionListTemplatesAndVisualsResponse } from 'api/actions/revision-list-templates-and-visuals/revision-list-templates-and-visuals-response';

type RevisionListTemplatesAndVisualsQueryParameters = Record<string, any>;

/**
 * The path of the RevisionListTemplatesAndVisuals action.
 */
export const revisionListTemplatesAndVisualsActionPath = new SweetPath<
  keyof RevisionListTemplatesAndVisualsPathParameters
>('/api/revision/list-templates-and-visuals', { path: '{param}' });

/**
 * Creates a contextualized RevisionListTemplatesAndVisuals actions.
 */
export default function createRevisionListTemplatesAndVisualsAction(axios: AxiosInstance) {
  return function revisionListTemplatesAndVisualsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: RevisionListTemplatesAndVisualsQueryParameters } = {}) {
    const path = revisionListTemplatesAndVisualsActionPath.original;

    return axios.get<RevisionListTemplatesAndVisualsResponse, RevisionListTemplatesAndVisualsResponse>(path, {
      params: query,
      headers,
    });
  };
}
