import { Button } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import { RevisionDevice } from 'components/forms/revision/fill-out/types';
import { useDeviceFault } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultProvider';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useEffect, useMemo } from 'react';

/**
 * The button which opens a dialog for adding device faults.
 */
export default function DeviceFaultsButton({ device: { faults, deviceId, fields } }: { device: RevisionDevice }) {
  const { specifyFaults } = useDeviceFault();
  const { revision, form } = useFillOutRevisionDataProvider();
  const nFaults = useMemo(() => fields._deviceFaultCount ?? 0, [fields._deviceFaultCount]);

  /** Opens the device fault modal. */
  const openFaultsModal = () =>
    specifyFaults({
      deviceTypeId: revision.deviceType.deviceTypeId,
      initialValues: { faults },
      onSubmit: ({ faults }) => {
        form.setFieldValue(`devices.${deviceId}.faults`, faults);
        form.setFieldValue(`devices.${deviceId}.fields._deviceFaultCount`, faults.length);
      },
    });

  useEffect(() => {
    form.setFieldValue(`devices.${deviceId}.fields._deviceFaultCount`, faults.length);
  }, []);

  return (
    <Button size="md" variant="subtle" leftIcon={<IconAlertTriangle stroke="1.5" />} onClick={openFaultsModal}>
      Závady zariadenia {nFaults > 0 && `(${nFaults})`}
    </Button>
  );
}
