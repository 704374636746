import { Select } from '@mantine/core';
import { IFormInputSpecSafetyPoint } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';

/**
 * Preview for a number input.
 */
export default function SafetyPointInputPreview({ spec }: { spec: IFormInputSpecSafetyPoint }) {
  const options = useMemo(
    () => [
      { value: 'suitable', label: spec.suitableText },
      { value: 'not-suitable', label: spec.notSuitableText },
      { value: 'with-reservations', label: spec.withReservationsText },
    ],
    [spec.suitableText, spec.withReservationsText, spec.notSuitableText]
  );

  return <Select data={options} required={spec.required} readOnly={spec.readOnly} searchable size="sm" />;
}
