import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { UserSelect } from 'components/selects/UserSelect';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'technician.userId' as const;

/**
 * Params passed to the TechnicianSelectFilter component
 */
export interface TechnicianSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the technician column.
 *
 * This filter assumes:
 *   - column field is `technician.userId`
 *   - context contains `initialFilters` with `technician.userId` filter
 */
export default function TechnicianSelectFilter({ api, context }: TechnicianSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  // TODO list here only technicians
  return (
    <UserSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetci technici"
      value={value}
      onChange={onChange}
      styles={{ dropdown: { minWidth: rem(220) } }}
    />
  );
}
