import { useApi } from 'api/api-context';
import {
  DataSelectInheritedProps,
  DataSelect,
  DataMultiSelectInheritedProps,
  DataMultiSelect,
} from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

/**
 * Fetches users from API.
 */
function useUserSelect({ showDiscarded, organizationId }: { showDiscarded: boolean; organizationId?: number }) {
  const { getAction } = useApi();

  const fetchUsers = useCallback(async () => {
    const action = getAction('UserList');

    const users = await action({
      query: {
        filters: {
          'organizationId.eq': organizationId === -1 ? undefined : organizationId,
          'showDiscarded.eq': showDiscarded ? 1 : 0,
        },
      },
    });

    return users.map((user) => ({
      label: (user.status ? '' : '[VYRADENÝ] ') + user.fullName,
      value: user.userId,
    }));
  }, [getAction, organizationId]);

  return { fetchUsers };
}

interface UserSelectProp {
  showDiscarded?: boolean;
  organizationId?: number;
}

type UserSelectProps = DataSelectInheritedProps & UserSelectProp;
type UserMultiSelectProps = DataMultiSelectInheritedProps & UserSelectProp;

/**
 * Select input for users.
 */
export function UserSelect({ showDiscarded = true, organizationId, ...props }: UserSelectProps) {
  const key = useMemo(() => nanoid(), [organizationId]);
  const { fetchUsers } = useUserSelect({ showDiscarded, organizationId });

  return <DataSelect key={key} action={fetchUsers} labelProp="label" valueProp="value" {...props} />;
}

/**
 * Select input for users (multiple).
 */
export function UserMultiSelect({ showDiscarded = true, organizationId, ...props }: UserMultiSelectProps) {
  const { fetchUsers } = useUserSelect({ showDiscarded, organizationId });
  return <DataMultiSelect action={fetchUsers} labelProp="label" valueProp="value" {...props} />;
}
