import { Box, Center, Group, Overlay, Skeleton, rem, useMantineTheme } from '@mantine/core';
import { IconEye } from '@tabler/icons-react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import { FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER } from 'env';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';
import { DefaultExtensionType, FileIcon, defaultStyles } from 'react-file-icon';

/**
 * Parameters of the FileThumbnail component.
 */
export interface FileThumbnailProps {
  fileId: string;
  showSlider?: boolean;
  onOpenSlider?: () => void;
}

/**
 * Displays a thumbnail for the given file extension.
 */
export default function FileThumbnail({ fileId, showSlider = true, onOpenSlider = noop }: FileThumbnailProps) {
  const theme = useMantineTheme();

  const { getFileMetadata, getFileThumbnail } = useFileManager();
  const [loading, setLoading] = useState(true);
  const [extension, setExtension] = useState('');
  const [contents, setContents] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const metadata = await getFileMetadata({ fileId });

        const extension = metadata.extension.replace(/^\./, '');

        const isImage =
          metadata.mimeType === 'image/jpeg' ||
          metadata.mimeType === 'image/png' ||
          metadata.mimeType === 'image/gif' ||
          metadata.mimeType === 'image/webp';

        setExtension(extension);

        if (isImage) {
          const contents = await getFileThumbnail({ fileId });
          setContents(URL.createObjectURL(contents));
        }
      } catch (error: any) {
        panic(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return <Skeleton w="100%" h="100%" radius={8} />;
  }

  if (contents) {
    return FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER && showSlider ? (
      <Box className="relative flex items-center justify-center" sx={{ borderRadius: '8px' }}>
        <Group
          h={44}
          w="100%"
          className="group/file-thumbnail"
          sx={{
            borderRadius: '8px',
            border: `2px solid ${theme.colors.gray[1]}`,
            '&:hover': {
              border: '2px solid #FFF',
              outline: '4px solid rgba(53, 55, 70, 0.20)',
              outlineOffset: '2px',
            },
          }}
        >
          <Box
            component="img"
            p={4}
            className="h-full w-full object-cover"
            w="100%"
            h={44}
            src={contents}
            alt={extension}
            sx={{
              borderRadius: '8px',
            }}
          />
          <Overlay
            color="#000"
            zIndex={5}
            opacity={0.4}
            className="hidden cursor-pointer items-center justify-center rounded-[8px] group-hover/file-thumbnail:flex"
            onClick={onOpenSlider}
          >
            <IconEye width={20} height={20} color="#FFF" stroke={1.5} />
          </Overlay>
        </Group>
      </Box>
    ) : (
      <Box component="img" h={80} p={4} w="100%" src={contents} alt={extension} sx={{ borderRadius: '8px' }} />
    );
  }

  const fileThumbnail = (
    <Box h={48} w={48} sx={{ svg: { width: rem(48), height: rem(48) } }}>
      <FileIcon extension={extension} {...(defaultStyles[extension as DefaultExtensionType] || {})} />
    </Box>
  );

  return extension === 'pdf' && FEATURE_TOGGLE_DEVICE_IMAGES_SLIDER && showSlider ? (
    <Box
      className="group/file-thumbnail relative flex items-center justify-center"
      bg="#FFF"
      m={4}
      sx={{ borderRadius: '4px' }}
    >
      {fileThumbnail}
      <Overlay
        color="#000"
        zIndex={5}
        opacity={0.4}
        className="hidden cursor-pointer items-center justify-center rounded-[8px] group-hover/file-thumbnail:flex"
        onClick={onOpenSlider}
      >
        <IconEye width={20} height={20} color="#FFF" stroke={1.5} />
      </Overlay>
    </Box>
  ) : (
    <Box p={4} sx={{ borderRadius: '4px' }}>
      <Center h="100%" bg="#fff" sx={{ borderRadius: '4px' }}>
        {fileThumbnail}
      </Center>
    </Box>
  );
}
