import { useApi } from 'api/api-context';
import { RevisionBulkActionProps } from 'components/tables/revision/types';
import { useMemo } from 'react';
import { Button } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import useAcceptAction from 'components/tables/revision/actions/hooks/use-accept-action';
import { noop } from 'lodash';
import BulkConfirmMessage from 'components/tables/revision/actions/bulk/BulkConfirmMessage';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Performs a bulk accept on all selected rows.
 */
export default function BulkAcceptAction({
  revisions,
  onStatusChange = noop,
  onBulkActionComplete = noop,
}: RevisionBulkActionProps) {
  const { userId } = useApi();
  const { confirm } = useConfirm();
  const { acceptRevision } = useAcceptAction();

  const availableRevisions = useMemo(
    () =>
      revisions.filter(
        ({ revisionStatus: { slug }, technician }) => slug === 'created' && technician.userId === userId
      ),
    [revisions, userId]
  );

  /**
   * Accepts all selected revisions.
   */
  const acceptAll = () =>
    confirm({
      title: `Prijať revízne správy (${availableRevisions.length})`,
      content: (
        <BulkConfirmMessage message="Naozaj si prajete prijať vybrané revízne správy?" revisions={availableRevisions} />
      ),
      onConfirm: async () => {
        for (const revision of availableRevisions) {
          await acceptRevision({ revision, onStatusChange });
        }
        onBulkActionComplete();
      },
    });

  if (availableRevisions.length === 0) {
    return <></>;
  }

  return (
    <Button variant="primary" size="sm" leftIcon={<IconCheck />} onClick={acceptAll}>
      Prijať ({availableRevisions.length})
    </Button>
  );
}
