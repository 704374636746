import { ActionIcon, Button, Group, LoadingOverlay, Menu, Portal, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBackspace, IconDotsVertical, IconPencil, IconPlus, IconTrashX, IconServer } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useAcknowledge, useConfirm } from 'components/modals/message/MessageProvider';
import panic from 'errors/panic';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ADD_DEVICE_PAGE_PATH, DEVICES_PAGE_PATH, EDIT_DEPARTMENT_PAGE_PATH } from 'routes/paths';
import { CANNOT_SET_ENTITY_STATUS, ERROR_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Parameters of the DepartmentActions component.
 */
export interface DepartmentActionsProps {
  departmentId: number;
  departmentName: string;
  canDiscard: boolean;
  status: boolean;
  onStatusChange: (status: boolean) => void;
}

/**
 * Displays actions for a department in a table.
 */
export default function DepartmentActions({
  departmentId,
  departmentName,
  canDiscard,
  status,
  onStatusChange,
}: DepartmentActionsProps) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();
  const { acknowledge } = useAcknowledge();
  const [loading, setLoading] = useState<boolean>(true);
  const [opened, setOpened] = useState<boolean>(false);
  const [canManageDevices, setCanManageDevices] = useState<boolean>(false);

  /**
   * Makes a call to the API to set the status of the department.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const departmentSetStatus = getAction('DepartmentSetStatus');

      departmentSetStatus({ parameters: { departmentId: String(departmentId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch((e) => {
          if (e.response.data.error.code === CANNOT_SET_ENTITY_STATUS) {
            notifications.show({
              title: 'Chyba!',
              message: 'Toto stredisko nemôžete deaktivovať.',
              color: ERROR_NOTIFICATION_COLOR,
            });
          } else {
            panic(e);
          }
        });
    },
    [getAction, departmentId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      if (!status && !canDiscard) {
        acknowledge({
          title: 'Nemožno vyradiť stredisko',
          content: 'Stredisko má priradené zariadenia. Vyraďte najskôr zariadenia a potom stredisko.',
        });
      } else {
        const title = status ? 'Zaradiť stredisko' : 'Vyradiť stredisko';
        const message = status ? 'Naozaj chcete zaradiť stredisko?' : 'Naozaj chcete vyradiť stredisko?';

        confirm({
          title,
          content: message,
          onConfirm: () => setStatusRemote(status),
        });
      }
    },
    [confirm, setStatusRemote]
  );

  // Check permissions on open.
  useEffect(() => {
    if (opened) {
      const action = getAction('AuthUserHasPermissionInDepartment');

      action({ parameters: { departmentId: String(departmentId), permissionSlug: 'manage-devices' } })
        .then(({ hasPermission }) => setCanManageDevices(hasPermission))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [opened]);

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        component={Link}
        to={EDIT_DEPARTMENT_PAGE_PATH.insert({ departmentId })}
        variant="secondary"
        leftIcon={<IconPencil />}
        disabled={!status}
      >
        Editovať
      </Button>
      <Menu position="bottom-end" opened={opened} onChange={setOpened}>
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <LoadingOverlay visible={loading} loaderProps={{ size: 12 }} />
            <Menu.Label>
              <Text maw={160} truncate>
                {departmentName}
              </Text>
            </Menu.Label>
            {status && canManageDevices && (
              <>
                <Menu.Item
                  component={Link}
                  to={`${ADD_DEVICE_PAGE_PATH.original}?departmentId=${departmentId}`}
                  icon={<IconPlus stroke={1.5} size={20} />}
                  color="gray.8"
                >
                  Pridať zariadenie
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  to={`${DEVICES_PAGE_PATH.original}?departmentId=${departmentId}`}
                  icon={<IconServer stroke="1.5" size={20} />}
                  color="gray.8"
                >
                  Zoraziť zariadenia
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Item
              color={status ? 'red.8' : 'blue.8'}
              onClick={() => confirmSetStatus(!status)}
              icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
            >
              {status ? 'Vyradiť' : 'Zaradiť'}
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
