/** @typedef {import('./actions').GetActionMap} GetActionMap */

import { createContext, useContext } from 'react';

/**
 * The file manager context interface.
 */
interface IFileManagerContext {
  uploadFile: ({ fileName, contents }: { fileName: string; contents: string | ArrayBuffer | null }) => Promise<string>;
  readFile: ({ fileId }: { fileId: string }) => Promise<Blob>;
  getFileMetadata: ({ fileId }: { fileId: string }) => Promise<any>;
  getFileThumbnail: ({ fileId }: { fileId: string }) => Promise<Blob>;
  createAccessToken: ({ fileId }: { fileId: string }) => Promise<string>;
}

/**
 * Creates empty context.
 */
export const FileManagerContext = createContext<IFileManagerContext>(undefined!);

/**
 * Requires the API context.
 */
export const useFileManager = () => useContext(FileManagerContext);
