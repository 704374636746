import { Select, SelectItem, SelectProps } from '@mantine/core';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useCallback, useEffect, useState } from 'react';
import CompanyQualificationItem from 'components/inputs/company-qualification-select/CompanyQualificationItem';

/**
 * The company qualification used in select.
 */
export interface CompanyQualificationSelectValue {
  qualificationNumber: string;
  companyName: string;
  streetAddress?: string;
  city?: string;
  zip?: string;
  country?: string;
}

/**
 * Parameters of the CompanyQualificationSelect component.
 */
export type CompanyQualificationSelectProps = Omit<SelectProps, 'value' | 'onChange' | 'data'> & {
  deviceTypeId: number;
  revisionId: number;
  value?: CompanyQualificationSelectValue;
  onChange?: (value: CompanyQualificationSelectValue) => void;
  autoSelectSingleResult?: boolean;
  loadingPlaceholder?: string;
};

/**
 * Allows the user to select the company qualifications.
 */
export default function CompanyQualificationSelect({
  deviceTypeId,
  revisionId,
  autoSelectSingleResult = false,
  value,
  onChange,
  loadingPlaceholder = 'Načítavanie...',
  placeholder = 'Vyberte oprávnenie',
  ...props
}: CompanyQualificationSelectProps) {
  const { getAction } = useApi();
  const [qualifications, setQualifications] = useState<CompanyQualificationSelectValue[]>([]);
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState(false);

  /**
   * Handles the change of the select.
   */
  const onChangeImpl = useCallback(
    (qualificationNumber: string) => {
      const qualification = qualifications.find(
        (qualification) => qualification.qualificationNumber === qualificationNumber
      );

      if (qualification) {
        onChange?.(qualification);
      }
    },
    [onChange, qualifications]
  );

  /**
   * Used to fetch the qualifications.
   */
  const fetchQualifications = useCallback(
    async ({ deviceTypeId, revisionId }: { deviceTypeId: number; revisionId: number }) => {
      setLoading(true);
      setQualifications([]);
      setOptions([]);

      const action = getAction('RevisionListExecutingCompaniesOptions');

      try {
        const qualifications = await action({
          parameters: { deviceTypeId: String(deviceTypeId), revisionId: String(revisionId) },
        });

        setQualifications(qualifications);

        setOptions(
          qualifications.map(({ qualificationNumber, companyName }) => ({
            label: `${qualificationNumber} - ${companyName}`,
            value: qualificationNumber,
            image: String(deviceTypeId),
          }))
        );

        const selectedQualification = qualifications.find(
          (qualification) => qualification.qualificationNumber === value?.qualificationNumber
        );

        if (selectedQualification) {
          onChange?.(selectedQualification);
        }
      } catch (error: any) {
        panic(error);
      } finally {
        setLoading(false);
      }
    },
    [getAction]
  );

  useEffect(() => {
    fetchQualifications({ deviceTypeId, revisionId });
  }, [deviceTypeId, revisionId]);

  useEffect(() => {
    if (autoSelectSingleResult && qualifications.length === 1) {
      onChange?.(qualifications[0]);
    }
  }, [autoSelectSingleResult, qualifications]);

  const actualPlaceholder =
    loading || (autoSelectSingleResult && qualifications.length === 1) ? loadingPlaceholder : placeholder;

  return (
    <Select
      {...props}
      data={options}
      value={value?.qualificationNumber}
      onChange={onChangeImpl}
      placeholder={actualPlaceholder}
      itemComponent={CompanyQualificationItem}
    />
  );
}
