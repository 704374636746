import { useMemo } from 'react';
import P2Regular from 'components/typography/P2Regular';
import { Box, Group, Stack, Tooltip } from '@mantine/core';

/**
 * A single device subtype.
 */
interface DeviceSubtype {
  deviceTypeId: number;
  deviceTypeName: string;
}

/**
 * Parameters of the DeviceSubtypeList component.
 */
export interface DeviceSubtypeListProps {
  deviceSubtypes: DeviceSubtype[];
  limit?: number;
}

/**
 * Displays a list of device subtypes.
 */
export default function DeviceSubtypeList({ deviceSubtypes, limit = 2 }: DeviceSubtypeListProps) {
  const some = useMemo(() => deviceSubtypes.slice(0, limit), [deviceSubtypes, limit]);
  const more = useMemo(() => deviceSubtypes.slice(limit), [deviceSubtypes, limit]);

  const someNames = useMemo(() => some.map(({ deviceTypeName }) => deviceTypeName).join(', '), [some]);

  const hasMore = more.length > 0;

  if (deviceSubtypes.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group spacing={4}>
      <P2Regular color="gray.8">{someNames}</P2Regular>
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {more.map(({ deviceTypeId, deviceTypeName }) => (
                <P2Regular key={deviceTypeId} color="gray.8">
                  {deviceTypeName}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Regular color="gray.6">(+{more.length})</P2Regular>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}
