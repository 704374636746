import { Select } from '@mantine/core';
import { useMemo } from 'react';

const OPTIONS = [
  { value: 'serif', label: 'Serif' },
  { value: 'roboto', label: 'Sans-serif' },
  { value: 'monospace', label: 'Monospace' },
];

const ITEM_STYLES = OPTIONS.reduce(
  (acc, { value: fontFamily }, i) => ({ ...acc, [`&:nth-of-type(${i + 1})`]: { fontFamily } }),
  {}
);

/**
 * Select component for font family.
 */
export function FontFamilySelect({ value, onChange }: { value: string; onChange: (value: string) => void }) {
  const styles = useMemo(
    () => ({
      item: ITEM_STYLES,
      input: { fontFamily: value },
    }),
    [value]
  );

  return (
    <Select
      size="md"
      searchable
      label="Písmo"
      styles={styles}
      value={value}
      onChange={(value) => onChange(value as string)}
      data={OPTIONS}
    />
  );
}
