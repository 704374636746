import {
  ActionIcon,
  Box,
  Button,
  createStyles,
  Group,
  Menu,
  rem,
  Select,
  SelectItem,
  Stack,
  Tooltip,
} from '@mantine/core';
import { IconDotsVertical, IconMenu, IconPlus, IconSwitch, IconTrashX } from '@tabler/icons-react';
import FormInput from 'pages/revisions-module/template-editor/editors/form/input/FormInput';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useConfirm } from 'components/modals/message/MessageProvider';
import {
  IConfigureInputParams,
  useInputConfigurator,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { IFormInputContext, IFormRow } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFormRows } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { noop, times } from 'lodash';
import { closeModal, openModal } from '@mantine/modals';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import findElementParent from 'utils/find-element-parent';

/**
 * Texts for the FormRow component.
 */
export interface FormRowTexts {
  removeTooltip: string;
  removeConfirm: string;
  moveTooltip: string;
  newInput: string;
  moveToSection: string;
}

/**
 * Parameters of the FormRow component.
 */
interface FormRowParams {
  sectionId: string;
  index: number;
  data: IFormRow;
  context: IFormInputContext;
  maxInputsInRow?: number;
  texts?: FormRowTexts;
  configureInputParams?: Partial<IConfigureInputParams>;
  dropMode?: boolean;
  dragMode?: boolean;
  disableMoveInput?: boolean;
  disableMoveToSection?: boolean;
  onDragStart?: (e: React.DragEvent) => void;
  onDragEnd?: (e: React.DragEvent) => void;
  startMovingInputToRow?: (inputId: string) => void;
}

/**
 * Styles for the FormRow component.
 */
const useStyles = createStyles((theme) => ({
  wrapper: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gap: rem(16),
    alignItems: 'center',
  },
}));

interface MoveSectionModalProps {
  options: SelectItem[];
  initialSection: string;
  onSubmit: (sectionId: string) => void;
}

/**
 * Modal for picking a section and moving a row to it.
 */
function MoveSectionModal({ options, initialSection, onSubmit }: MoveSectionModalProps) {
  const [selected, setSelected] = useState<string | null>(initialSection);
  const close = useCallback(() => closeModal('pick-section'), []);

  const submitAndClose = useCallback(() => {
    if (selected) {
      onSubmit(selected);
      close();
    }
  }, [onSubmit, selected, close]);

  return (
    <Stack spacing={16} pt={16}>
      <Select label="Výber sekcie" data={options} value={selected} onChange={setSelected} />
      <Group position="apart">
        <Button type="button" variant="subtle-gray" size="md" onClick={close}>
          Zrušiť
        </Button>
        <Button type="submit" variant="primary" size="md" onClick={submitAndClose}>
          Presunúť
        </Button>
      </Group>
    </Stack>
  );
}

/**
 * A single row in a form section.
 */
export default function FormRow({
  sectionId,
  index,
  data,
  context,
  maxInputsInRow = 999,
  texts = {
    removeTooltip: 'Odstrániť riadok',
    removeConfirm: 'Naozaj chcete odstrániť tento riadok? Táto akcia je nevratná.',
    moveTooltip: 'Presunúť riadok (drag & drop)',
    newInput: 'Pole v riadku',
    moveToSection: 'Presunúť do inej sekcie',
  },
  configureInputParams = {},
  dropMode = false,
  dragMode = false,
  disableMoveInput = false,
  disableMoveToSection = false,
  onDragStart = noop,
  onDragEnd = noop,
  startMovingInputToRow = noop,
}: FormRowParams) {
  const { classes } = useStyles();
  const { confirm } = useConfirm();
  const { sections, getTabName, moveRowToSection } = useFormSections();
  const { removeRow } = useFormRows();
  const { addInput, moveInput } = useFormInputs();
  const { configureInput } = useInputConfigurator();

  const [draggingInput, setDraggingInput] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const wrapperClassName = useMemo(() => `${classes.wrapper} form-row`, [classes.wrapper]);
  const canHaveMoreInputs = useMemo(() => data.inputs.length < maxInputsInRow, [data.inputs.length, maxInputsInRow]);
  const canBeDeleted = useMemo(() => data.inputs.every(({ deletable }) => deletable), [data.inputs]);

  const inputsWrapperStyle = useMemo(
    () => ({ gridTemplateColumns: times(data.inputs.length, () => '2px 1fr').join(' ') + ' 2px' }),
    [data.inputs.length]
  );

  /** Confirm and remove the row. */
  const confirmRemoveRow = () =>
    confirm({
      title: texts.removeTooltip,
      content: texts.removeConfirm,
      onConfirm: () => removeRow(sectionId, data.id),
    });

  /** Configure and add a new input. */
  const configureAndAddInput = () =>
    configureInput({
      ...configureInputParams,
      primaryButtonLabel: texts.newInput,
      onSubmit: (spec) => addInput(sectionId, data.id, context, spec),
    });

  /** Pick a section and move the row to it. */
  const pickSectionAndMove = () => {
    const options = sections
      .filter((section) => section.tab !== 'device' && section.tab !== 'measurements')
      .map((section) => ({ value: section.id, label: section.name || '(Bez názvu)', group: getTabName(section.tab) }));

    openModal({
      modalId: 'pick-section',
      title: texts.moveToSection,
      children: (
        <MoveSectionModal
          options={options}
          initialSection={sectionId}
          onSubmit={(newSectionId) => moveRowToSection(sectionId, data.id, newSectionId)}
        />
      ),
    });
  };

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      const hoverInput = findElementParent(e.target as Element, (el) => el.classList.contains('form-input'));

      if (draggingInput !== null && hoverInput !== null) {
        e.preventDefault();
        const isAboveHalf = e.clientX - hoverInput.getBoundingClientRect().left < hoverInput.clientWidth / 2;
        const index = Number(hoverInput.getAttribute('data-index')) + (isAboveHalf ? 0 : 1);
        setHoveredIndex(index);
      }
    },
    [draggingInput]
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();

      if (hoveredIndex !== null && draggingInput !== null && hoveredIndex !== draggingInput) {
        const shifted = Math.max(0, hoveredIndex > draggingInput ? hoveredIndex - 1 : hoveredIndex);
        moveInput(sectionId, data.id, draggingInput, shifted);
      }

      setDraggingInput(null);
      setHoveredIndex(null);
    },
    [data.id, draggingInput, hoveredIndex, moveInput]
  );

  return (
    <Box
      py={8}
      pos="relative"
      className={wrapperClassName}
      data-row-id={data.id}
      data-index={index}
      opacity={dragMode ? 0.5 : 1}
      onDrop={onDrop}
    >
      <Tooltip label={texts.moveTooltip} openDelay={300} withArrow={false} disabled={dropMode}>
        <ActionIcon
          variant="subtle-gray"
          size="sm"
          className="cursor-grab"
          draggable
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        >
          <IconMenu />
        </ActionIcon>
      </Tooltip>

      <Box className="grid items-start gap-1" style={inputsWrapperStyle} onDragOver={onDragOver}>
        {data.inputs.map((input, index) => (
          <Fragment key={input.id}>
            <Box w={2} h="100%" bg="blue.5" opacity={hoveredIndex === index ? 1 : 0} className="transition-opacity" />
            <Box w="calc(100% - 2px)">
              <FormInput
                index={index}
                sectionId={sectionId}
                rowId={data.id}
                data={input}
                configureInputParams={configureInputParams}
                disableMove={disableMoveInput}
                onDragStart={() => setDraggingInput(index)}
                onDragEnd={() => {
                  setDraggingInput(null);
                  setHoveredIndex(null);
                }}
                startMovingInputToRow={startMovingInputToRow}
              />
            </Box>
          </Fragment>
        ))}
        <Box
          w={2}
          h="100%"
          bg="blue.5"
          opacity={hoveredIndex === data.inputs.length ? 1 : 0}
          className="transition-opacity"
        />
      </Box>

      <Group spacing={12} noWrap>
        {canHaveMoreInputs && (
          <Button variant="secondary" size="md" leftIcon={<IconPlus />} onClick={configureAndAddInput}>
            {texts.newInput}
          </Button>
        )}
        <Menu>
          <Menu.Target>
            <ActionIcon variant="subtle-gray" size="md">
              <IconDotsVertical />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {!disableMoveToSection && (
              <Menu.Item color="gray.7" onClick={pickSectionAndMove} icon={<IconSwitch />}>
                {texts.moveToSection}
              </Menu.Item>
            )}
            <Menu.Item color="red.8" onClick={confirmRemoveRow} disabled={!canBeDeleted} icon={<IconTrashX />}>
              {texts.removeTooltip}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Box>
  );
}
