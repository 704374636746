import { IFormInputSpecMeasuringDevice } from 'pages/revisions-module/template-editor/editors/form/types';
import { MeasuringDevicesMultiCheckbox } from 'components/inputs/measuring-devices-multi-checkbox/MeasuringDevicesMultiCheckbox';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';

/**
 * Measuring device multi checkbox input for the revision form.
 */
export default function RevisionFieldMeasuringDevice({ name }: RevisionFieldProps<IFormInputSpecMeasuringDevice>) {
  const { form } = useFillOutRevisionDataProvider();

  return <MeasuringDevicesMultiCheckbox {...form.getInputProps(name)} />;
}
