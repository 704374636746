import { Button, Checkbox, Divider, Group, Modal, ScrollArea, Stack, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import P2Regular from 'components/typography/P2Regular';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import panic from 'errors/panic';
import PermissionTemplateInput from 'components/inputs/permission-template-input/PermissionTemplateInput';
import { OrganizationListResponse } from 'api/actions/organization-list/organization-list-response';

type Step = 'organizations' | 'permission';

interface PickOrganizationsModalParams {
  title: string;
  permissionsTitle?: string;
  hiddenOrganizations: number[];
  opened: boolean;
  pickOrganizations?: boolean;
  pickPermissions?: boolean;
  autoSelectSingleOrganization?: boolean;
  initialStep?: Step;
  initialTemplateName: string;
  initialPermissions: number[];
  onClose: () => void;
  onPick: (value: {
    pickedOrganizations: {
      [organizationId: number]: { checked: boolean; templateName: string; organizationName: string };
    };
    permissions: number[];
    templateName: string;
  }) => void;
}

type FormValues = {
  pickedOrganizations: {
    [organizationId: number]: { checked: boolean; templateName: string; organizationName: string };
  };
  permissions: { templateName: string; permissions: number[] };
};

type Organization = OrganizationListResponse[number] & { search: string };

/**
 * Modal for selecting permissions of a user in an organization.
 */
export default function PickOrganizationsModal({
  title,
  permissionsTitle,
  hiddenOrganizations,
  opened,
  pickOrganizations = true,
  pickPermissions = true,
  autoSelectSingleOrganization = true,
  initialTemplateName,
  initialPermissions,
  onClose,
  onPick,
}: PickOrganizationsModalParams) {
  const [search, setSearch] = useState<string>('');
  const [step, setStep] = useState<'organizations' | 'permission'>(pickOrganizations ? 'organizations' : 'permission');
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const { getAction } = useApi();
  const theme = useMantineTheme();

  const filteredOrganizations = useMemo(() => {
    const normalizedSearch = search.toLowerCase(); // TODO: Remove diacritics

    return organizations.filter((organization) =>
      organization.organizationName.toLowerCase().includes(normalizedSearch)
    );
  }, [search, organizations]);

  const form = useForm<FormValues>({
    initialValues: {
      pickedOrganizations: {},
      permissions: { permissions: initialPermissions, templateName: initialTemplateName },
    },
  });

  /**
   * Submits the form.
   */
  function submit({ pickedOrganizations, permissions }: FormValues) {
    onPick({ pickedOrganizations, permissions: permissions.permissions, templateName: permissions.templateName });
    onClose();
  }

  useEffect(() => {
    const organizationGetList = getAction('OrganizationList');
    // retrieve all people from the app
    // the backend will take care of filtering out the ones the current user doesn't have access to
    organizationGetList({ query: { filters: { 'showDiscarded.eq': 0, 'permissionSlug.eq': 'manage-users' } } })
      .then((organizations) => {
        const filtered = organizations
          .filter(({ organizationId }) => !hiddenOrganizations.includes(organizationId))
          .map((organization) => ({
            ...organization,
            search: organization.organizationName.toLowerCase(),
          }));

        if (filtered.length === 1 && autoSelectSingleOrganization) {
          const organization = filtered[0];
          form.setFieldValue(`pickedOrganizations.${organization.organizationId}`, {
            checked: true,
            templateName: 'no-template',
            organizationName: organization.organizationName,
          });

          setStep('permission');
        } else {
          const reduced = filtered.reduce((acc, curr) => {
            acc[curr.organizationId] = {
              checked: false,
              templateName: 'no-template',
              organizationName: curr.organizationName,
            };
            return acc;
          }, {} as { [organizationId: number]: { checked: boolean; templateName: string; organizationName: string } });

          form.setFieldValue('pickedOrganizations', reduced);
        }

        setOrganizations(filtered);
      })
      .catch(panic);
  }, [getAction]);

  const multiStep = pickOrganizations && pickPermissions;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        step === 'organizations'
          ? title
          : multiStep && step === 'permission'
          ? 'Nastaviť oprávnenia v organizácii'
          : permissionsTitle
      }
      centered
    >
      <Divider color="gray.1" mb={24} />
      <form onSubmit={form.onSubmit(submit)}>
        {step === 'organizations' ? (
          <Stack spacing={24}>
            <TextInput
              rightSection={<IconSearch stroke="1.5" color={theme.colors.gray[6]} />}
              value={search}
              placeholder="Hľadať organizácie"
              size="md"
              onChange={(event) => setSearch(event.currentTarget.value)}
              // TODO name
              name="amperia-search-organizations"
            />
            <ScrollArea h={410}>
              <Stack spacing={24} pb={24}>
                {filteredOrganizations.map((organization) => (
                  <Checkbox
                    key={organization.organizationId}
                    label={organization.organizationName}
                    size="lg"
                    form="pick-organizations-modal"
                    name={`amperia-pickedOrganizations.${organization.organizationId}.checked`}
                    {...form.getInputProps(`pickedOrganizations.${organization.organizationId}.checked`, {
                      type: 'checkbox',
                    })}
                  />
                ))}
                {isEmpty(organizations) && <P2Regular>Neboli nájdené žiadne organizácie.</P2Regular>}
              </Stack>
            </ScrollArea>
          </Stack>
        ) : (
          <PermissionTemplateInput {...form.getInputProps('permissions')} permissionType="organization" />
        )}

        {/* TODO find a proper place to display this error */}
        <P2Regular color="red.9">{form.errors.pickedOrganizations}</P2Regular>
        <P2Regular color="red.9">{form.errors.permissions}</P2Regular>

        <Group spacing={40} position="apart" mt={24}>
          <Button
            variant="subtle-gray"
            type="button"
            leftIcon={
              multiStep && step === 'permission' ? (
                <IconChevronLeft stroke="1.5" color={theme.colors.gray[7]} />
              ) : undefined
            }
            onClick={() => {
              if (multiStep && step === 'permission') {
                setStep('organizations');
              } else {
                onClose();
              }
            }}
          >
            {multiStep && step === 'permission' ? 'Späť' : 'Zrušiť'}
          </Button>
          {step === 'organizations' && (
            <Button
              variant="primary"
              size="md"
              type="button"
              rightIcon={<IconChevronRight stroke="1.5" color={theme.colors.gray[0]} />}
              onClick={() => setStep('permission')}
              disabled={Object.values(form.values.pickedOrganizations).every((value) => !value.checked)}
            >
              Pokračovať
            </Button>
          )}
          {step === 'permission' && (
            <Button
              variant="primary"
              size="md"
              type="submit"
              disabled={form.values.permissions.permissions.length === 0}
            >
              Uložiť {multiStep && ' a pridať organizácie'}
            </Button>
          )}
        </Group>
      </form>
    </Modal>
  );
}
