import { Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'deadlineStatus' as const;

/**
 * The options of the filter.
 */
export const OPTIONS = [
  { value: 'after-deadline', label: 'Po termíne' },
  { value: 'before-deadline', label: 'Pred termínom' },
] as const;

/**
 * Parameters of the RevisionDeadlineSelectFilter component.
 */
interface RevisionDeadlineSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the revision deadlineStatus column.
 *
 * This filter assumes:
 *  - column field is `deadlineStatus`
 * - context contains `initialFilters` with `deadlineStatus` filter
 */
export default function RevisionDeadlineSelectFilter({ api, context }: RevisionDeadlineSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <Select
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky"
      data={OPTIONS}
      value={value}
      onChange={onChange}
    />
  );
}
