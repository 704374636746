import { Box, Button, Group } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import ButtonWithConfirm from 'components/modals/confirm/ButtonWithConfirm';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';

export interface FormFooterProps {
  loading?: boolean;
  primaryIcon?: React.ReactNode;
  primaryButtonText?: string;
  hidePrimaryButton?: boolean;
  secondaryIcon?: React.ReactNode;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
  skipSecondaryButtonConfirm?: boolean;
  closeConfirmTitle?: string;
  closeConfirmMessage?: string;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
}

/**
 * The form footer. Allows to submit or cancel the form.
 */
export default function FormFooter({
  loading = false,
  primaryButtonText = 'Uložiť',
  hidePrimaryButton = false,
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
  secondaryIcon = <IconX stroke="1.5" height={24} width={24} />,
  secondaryButtonText = 'Zrušiť',
  hideSecondaryButton = false,
  skipSecondaryButtonConfirm = false,
  closeConfirmTitle = 'Zrušenie formulára',
  closeConfirmMessage = 'Naozaj chcete odísť? Všetky neuložené zmeny budú stratené.',
  onPrimaryClick = noop,
  onSecondaryClick,
}: FormFooterProps = {}) {
  const navigate = useNavigate();

  return (
    <Box
      w="100%"
      pos="sticky"
      bottom={0}
      pb={24}
      mb={-24}
      bg="gray.1"
      sx={(theme) => ({
        borderTop: `1px solid ${theme.colors.gray[1]}`,
        borderBottomLeftRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
        // boxShadow: '0px -4px 12px 0px rgba(197, 198, 211, 0.40)',
      })}
    >
      <Group
        spacing={16}
        py={16}
        px={40}
        bg="white"
        sx={(theme) => ({
          borderBottomLeftRadius: theme.radius.sm,
          borderBottomRightRadius: theme.radius.sm,
        })}
      >
        {!hidePrimaryButton && (
          <Button
            type="submit"
            variant="primary"
            leftIcon={primaryIcon}
            size="md"
            loading={loading}
            onClick={onPrimaryClick}
          >
            {primaryButtonText}
          </Button>
        )}
        {!hideSecondaryButton && (
          <ButtonWithConfirm
            variant="tertiary"
            confirmTitle={closeConfirmTitle}
            confirmMessage={closeConfirmMessage}
            leftIcon={secondaryIcon}
            size="md"
            loading={loading}
            loaderPosition="center"
            loaderProps={{ size: 0 }}
            skipConfirm={skipSecondaryButtonConfirm}
            onConfirm={() => (onSecondaryClick ? onSecondaryClick() : navigate(-1))}
          >
            {secondaryButtonText}
          </ButtonWithConfirm>
        )}
      </Group>
    </Box>
  );
}
