import { Button, Menu } from '@mantine/core';
import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { Link } from 'react-router-dom';
import { REVISION_PAGE_PATH } from 'routes/paths';
import { IconEye } from '@tabler/icons-react';

/**
 * Action performed by the users who have the permission to view the revision.
 */
export default function ViewAction({ revision, kind }: RevisionSingleActionProps) {
  if (kind === 'button') {
    return (
      <Button
        size="md"
        component={Link}
        to={REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
        leftIcon={<IconEye />}
        variant="secondary"
        w={180}
      >
        Pozrieť
      </Button>
    );
  }

  return (
    <Menu.Item
      component={Link}
      to={REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
      icon={<IconEye stroke="1.5" size={24} />}
      color="gray.8"
    >
      Pozrieť
    </Menu.Item>
  );
}
