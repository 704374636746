import { Stack } from '@mantine/core';
import { RevisionRow } from 'components/tables/revision/types';
import P1Regular from 'components/typography/P1Regular';

/**
 * Parameters of the BulkConfirmMessage component.
 */
export interface BulkConfirmMessageProps {
  message: string;
  revisions: RevisionRow[];
}

/**
 * Bulk confirm message for all selected revisions.
 */
export default function BulkConfirmMessage({ message, revisions }: BulkConfirmMessageProps) {
  return (
    <Stack spacing={4}>
      <P1Regular mb={4}>{message}</P1Regular>
      {revisions.map((revision) => (
        <P1Regular pl={8} key={revision.revisionId}>
          - {revision.revisionName}
        </P1Regular>
      ))}
    </Stack>
  );
}
