import { IFloatingFilterParams } from 'ag-grid-community';
import RevisionStatusMultiSelect from 'components/inputs/revision-status-multi-select/RevisionStatusMultiSelect';
import { noop } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

export default forwardRef(({ parentFilterInstance, context }: IFloatingFilterParams, ref) => {
  const [value, setValue] = useState<number[]>(context?.initialFilters?.['revisionStatus.revisionStatusId'] ?? []);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => ({ onParentModelChanged: noop }));

  // Propagate changes to parent filter.
  useEffect(() => {
    parentFilterInstance((instance: any) => {
      instance.onFloatingFilterChanged(value);
    });
  }, [value]);

  return <RevisionStatusMultiSelect value={value} onChange={setValue} />;
});
