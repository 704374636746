import { Text } from '@mantine/core';
import { ActionIcon, Button, Group, Menu, Portal } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useConfirm } from 'components/modals/message/MessageProvider';
import panic from 'errors/panic';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { SweetPath } from 'sweet-path';

/**
 * Interface for PredefinedDataActions component props
 */
export interface PredefinedDataActionsProps {
  predefinedDataId: number;
  predefinedDataName: string;
  editPath: SweetPath<string>;
  tableType: 'user' | 'global';
  onDelete: () => void;
}

/**
 * Displays actions for a predefined data in a table.
 */
export default function PredefinedDataActions({
  predefinedDataId,
  predefinedDataName,
  editPath,
  tableType,
  onDelete,
}: PredefinedDataActionsProps) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();

  const deletePredefinedData = useCallback(() => {
    const predefinedDataDeleteAction =
      tableType === 'user' ? getAction('PredefinedDataDeleteUsers') : getAction('PredefinedDataDeleteGlobal');

    predefinedDataDeleteAction({ parameters: { predefinedDataId: String(predefinedDataId) } })
      .then(() => {
        onDelete();
      })
      .catch(panic);
  }, [getAction, predefinedDataId]);

  const confirmDeletePredefinedData = useCallback(() => {
    confirm({
      title: 'Vymazať predvolený údaj',
      content: 'Naozaj chcete vymazať predvolený údaj?',
      onConfirm: deletePredefinedData,
    });
  }, [confirm, deletePredefinedData]);

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        component={Link}
        to={editPath.insert({ predefinedDataId: predefinedDataId })}
        variant="secondary"
        leftIcon={<IconPencil />}
      >
        Editovať
      </Button>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {predefinedDataName}
              </Text>
            </Menu.Label>
            <Menu.Item color="red.8" onClick={confirmDeletePredefinedData} icon={<IconTrashX stroke={1.5} size={20} />}>
              Vymazať
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
