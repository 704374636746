import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionRevisionTemplateConfigurePayload } from 'api/actions/revision-revision-template-configure/revision-revision-template-configure-payload';
import { RevisionRevisionTemplateConfigurePathParameters } from 'api/actions/revision-revision-template-configure/revision-revision-template-configure-path-parameters';
import { RevisionRevisionTemplateConfigureResponse } from 'api/actions/revision-revision-template-configure/revision-revision-template-configure-response';

type RevisionRevisionTemplateConfigureQueryParameters = Record<string, any>;

/**
 * The path of the RevisionRevisionTemplateConfigure action.
 */
export const revisionRevisionTemplateConfigureActionPath = new SweetPath<
  keyof RevisionRevisionTemplateConfigurePathParameters
>('/api/revision/revision-template/configure/{revisionTemplateId}', { path: '{param}' });

/**
 * Creates a contextualized RevisionRevisionTemplateConfigure actions.
 */
export default function createRevisionRevisionTemplateConfigureAction(axios: AxiosInstance) {
  return function revisionRevisionTemplateConfigureAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionRevisionTemplateConfigurePathParameters;
    query?: RevisionRevisionTemplateConfigureQueryParameters;
    payload: RevisionRevisionTemplateConfigurePayload;
  }) {
    const path = revisionRevisionTemplateConfigureActionPath.insert(parameters);

    return axios.post<
      RevisionRevisionTemplateConfigureResponse,
      RevisionRevisionTemplateConfigureResponse,
      RevisionRevisionTemplateConfigurePayload
    >(path, payload);
  };
}
