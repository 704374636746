import { Stack } from '@mantine/core';
import DeviceMeasurements from 'pages/revisions-module/template-editor/editors/form/device/DeviceMeasurements';
import DeviceProperties from 'pages/revisions-module/template-editor/editors/form/device/DeviceProperties';

/**
 * Special case for the devices tab.
 */
export default function DevicesTab() {
  return (
    <Stack spacing={40}>
      <DeviceProperties />
      <DeviceMeasurements />
    </Stack>
  );
}
