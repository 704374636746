import { Box } from '@mantine/core';
import P1Medium from 'components/typography/P1Medium';
import { createMonogram } from 'utils/create-monogram';
import P4Medium from 'components/typography/P4Medium';

interface AvatarProps {
  fullName: string;
  size: 'm' | 'l';
  bgColor: string;
}

/**
 * Avatar component with monogram.
 */
export default function Avatar({ fullName, size, bgColor }: AvatarProps) {
  const monogram = createMonogram(fullName);

  return (
    <Box
      w={size === 'l' ? 40 : 24}
      h={size === 'l' ? 40 : 24}
      sx={{
        borderRadius: '50%',
        backgroundColor: bgColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {size === 'l' ? <P1Medium color="gray.0">{monogram}</P1Medium> : <P4Medium color="gray.0">{monogram}</P4Medium>}
    </Box>
  );
}
