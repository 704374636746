import { Button, Divider, Group, Modal, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';
import { useMemo, useState } from 'react';
import PermissionTemplateInput from 'components/inputs/permission-template-input/PermissionTemplateInput';
import { PickUsersParams } from 'components/modals/pick-users-modal/PickUsersProvider';
import UserMultiCheckbox from 'components/inputs/multi-checkbox/UserMultiCheckbox';

/**
 * Parameters of the PickUsersModal component.
 */
interface PickUsersModalParams extends PickUsersParams {
  opened: boolean;
  onClose: () => void;
}

/**
 * Values of the form.
 */
interface FormValues {
  pickedUsers: number[];
  permissions: {
    templateName: string;
    permissions: number[];
  };
}

/**
 * Modal for selecting users to a department/organization.
 */
export default function PickUsersModal({
  title,
  permissionsTitle,
  hiddenUsers,
  opened,
  inForm,
  pickUsers = true,
  pickPermissions = true,
  initialTemplateName,
  initialPermissions,
  onClose,
  onPick,
}: PickUsersModalParams) {
  const theme = useMantineTheme();
  const [step, setStep] = useState<'users' | 'permission'>(pickUsers ? 'users' : 'permission');

  const isMultiStep = useMemo(() => pickUsers && pickPermissions, [pickUsers, pickPermissions]);
  const isSecondStep = useMemo(() => isMultiStep && step === 'permission', [isMultiStep, step]);

  const computedTitle = useMemo(() => {
    if (step === 'users') {
      return title;
    }

    if (isSecondStep) {
      return 'Nastaviť oprávnenia pre ľudí';
    }

    return permissionsTitle;
  }, [step, title, isSecondStep, permissionsTitle]);

  const form = useForm<FormValues>({
    initialValues: {
      pickedUsers: [],
      permissions: {
        permissions: initialPermissions,
        templateName: initialTemplateName,
      },
    },
  });

  /**
   * Submits the form.
   */
  function submit({ pickedUsers, permissions }: FormValues) {
    onPick({ pickedUsers, ...permissions });
    onClose();
  }

  return (
    <Modal opened={opened} onClose={onClose} title={computedTitle} centered>
      <Divider color="gray.1" mb={24} />
      <form onSubmit={form.onSubmit(submit)}>
        {step === 'users' ? (
          <UserMultiCheckbox withSearch excludeIds={hiddenUsers} {...form.getInputProps('pickedUsers')} />
        ) : (
          <PermissionTemplateInput permissionType={inForm} {...form.getInputProps('permissions')} />
        )}

        {/* TODO find a proper place to display this error */}
        <P2Regular color="red.9">{form.errors.pickedUsers}</P2Regular>
        <P2Regular color="red.9">{form.errors.permissions}</P2Regular>

        <Group spacing={40} position="apart" mt={24}>
          <Button
            variant="subtle-gray"
            type="button"
            leftIcon={isSecondStep ? <IconChevronLeft stroke="1.5" color={theme.colors.gray[7]} /> : undefined}
            onClick={() => (isSecondStep ? setStep('users') : onClose())}
          >
            {isSecondStep ? 'Späť' : 'Zrušiť'}
          </Button>
          {step === 'users' && (
            <Button
              variant="primary"
              size="md"
              type="button"
              rightIcon={<IconChevronRight stroke="1.5" color={theme.colors.gray[0]} />}
              onClick={() => setStep('permission')}
              disabled={form.values.pickedUsers.length === 0}
            >
              Pokračovať
            </Button>
          )}
          {step === 'permission' && (
            <Button
              variant="primary"
              size="md"
              type="submit"
              disabled={form.values.permissions.permissions.length === 0}
            >
              Uložiť {isMultiStep && ' a pridať ľudí'}
            </Button>
          )}
        </Group>
      </form>
    </Modal>
  );
}
