import { ValidationRule } from 'components/forms/validators/validation-rule';
import { Validator } from 'components/forms/validators/validator';

/**
 * Creates a validator from the given validation rules.
 */
export default function createValidator(rules: ValidationRule[]): Validator {
  /**
   * Validates the input.
   */
  function validate(value: string): string | null {
    for (const rule of rules) {
      const error = rule(value);

      if (error) {
        return error.message;
      }
    }

    return null; // No errors.
  }

  return validate;
}
