import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthUserHasPermissionInDepartmentPathParameters } from 'api/actions/auth-user-has-permission-in-department/auth-user-has-permission-in-department-path-parameters';
import { AuthUserHasPermissionInDepartmentResponse } from 'api/actions/auth-user-has-permission-in-department/auth-user-has-permission-in-department-response';

type AuthUserHasPermissionInDepartmentQueryParameters = Record<string, any>;

/**
 * The path of the AuthUserHasPermissionInDepartment action.
 */
export const authUserHasPermissionInDepartmentActionPath = new SweetPath<
  keyof AuthUserHasPermissionInDepartmentPathParameters
>('/api/auth/check/permission/{permissionSlug}/department/{departmentId}/', { path: '{param}' });

/**
 * Creates a contextualized AuthUserHasPermissionInDepartment actions.
 */
export default function createAuthUserHasPermissionInDepartmentAction(axios: AxiosInstance) {
  return function authUserHasPermissionInDepartmentAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: AuthUserHasPermissionInDepartmentPathParameters;
    query?: AuthUserHasPermissionInDepartmentQueryParameters;
  }) {
    const path = authUserHasPermissionInDepartmentActionPath.insert(parameters);

    return axios.get<AuthUserHasPermissionInDepartmentResponse, AuthUserHasPermissionInDepartmentResponse>(path, {
      params: query,
      headers,
    });
  };
}
