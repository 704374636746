import { MantineThemeOverride } from '@mantine/core';

export type AccordionTheme = NonNullable<MantineThemeOverride['components']>['Accordion'];

export const Accordion: AccordionTheme = {
  styles: (theme, params, { variant }) => ({
    item: {
      backgroundColor: theme.white,
      border: variant === 'separated' ? `1px solid ${theme.colors.gray[2]}` : 0,
    },
  }),
};
