import { Box, CSSObject } from '@mantine/core';

/**
 * Parameters of the TableWrapper component.
 */
export interface TableWrapperProps {
  children: React.ReactNode;
}

/**
 * Styles used by the TableWrapper component.
 */
const styles: CSSObject = {
  height: '100%',
  border: '2px solid #fff',
  borderRadius: '4px',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '1fr 72px',
  backgroundColor: 'white',
  transition: 'opacity 30ms ease-in-out 100ms',
};

/**
 * Element that wraps a table and adds some styling.
 */
export default function TableWrapper({ children }: TableWrapperProps) {
  return <Box sx={styles}>{children}</Box>;
}
