import { Group, Select, Tooltip, useMantineTheme } from '@mantine/core';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { forwardRef, useMemo } from 'react';
import { IAutomationType } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { IconLock } from '@tabler/icons-react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  group: string;
  help?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, help, ...others }: ItemProps, ref) => {
  return (
    <Group ref={ref} {...others} spacing={8}>
      <span>{label}</span>
      {help && <QuestionMarkTooltip label={help} />}
    </Group>
  );
});

/**
 * Map from every automation type to its name.
 */
export const AUTOMATION_TYPE_NAMES: Record<IAutomationType, ItemProps> = {
  'set-revision-safety-point': { label: 'Nastavenie záveru revíznej správy', group: 'Revízna správa' },
  'plan-next-revision': { label: 'Preplánovanie termínov zariadení', group: 'Termíny' },
};

/**
 * Options for automation type select.
 */
const OPTIONS = Object.entries(AUTOMATION_TYPE_NAMES).map(([value, props]) => ({
  value: value as IAutomationType,
  ...props,
}));

/**
 * Map from every automation type to boolean value indicating whether the automation type is enabled.
 */
export const ALL_TYPES_ON: Record<IAutomationType, boolean> = {
  'set-revision-safety-point': true,
  'plan-next-revision': true,
};

/**
 * Parameters of the AutomationTypeSelect component.
 */
export interface AutomationTypeSelectProps {
  value: IAutomationType;
  onChange: (value: IAutomationType) => void;
  readOnly?: boolean;
  automationTypes?: Partial<Record<IAutomationType, boolean>>;
}

/**
 * Select for automation type.
 */
export default function AutomationTypeSelect({
  value,
  onChange,
  readOnly = false,
  automationTypes = ALL_TYPES_ON,
}: AutomationTypeSelectProps) {
  const theme = useMantineTheme();

  const filteredOptions = useMemo(() => OPTIONS.filter((option) => automationTypes[option.value]), [automationTypes]);

  return (
    <Select
      data={filteredOptions}
      size="lg"
      value={value}
      onChange={onChange}
      searchable
      readOnly={readOnly}
      disabled={readOnly}
      itemComponent={SelectItem}
      label={
        <Group spacing={4}>
          <span>Typ automatizácie</span>
          {readOnly && (
            <Tooltip label="Typ automatizácie už nie je možné upraviť" withArrow={false} openDelay={200}>
              <IconLock size={16} color={theme.colors.gray[6]} />
            </Tooltip>
          )}
        </Group>
      }
    />
  );
}
