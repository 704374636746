import { DeviceSelect } from 'components/selects/DeviceSelect';
import { useCallback, useState } from 'react';
import { GridApi } from 'ag-grid-community';

/**
 * Parameters of the RevisionDeviceSelectFilter component.
 */
export interface RevisionDeviceSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the `deviceId` column.
 *
 * This filter assumes:
 *  - column field is `deviceId`
 * - context contains `initialFilters` with `deviceId` filter
 */
export default function RevisionDeviceSelectFilter({ api, context }: RevisionDeviceSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.['deviceId']?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        '_search.deviceId': { filterType: 'text', type: 'contains', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <DeviceSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky názvy zariadení"
      value={value}
      onChange={onChange}
      showDiscarded={false}
    />
  );
}
