import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserUpdateMyCertificatesPayload } from 'api/actions/user-update-my-certificates/user-update-my-certificates-payload';
import { UserUpdateMyCertificatesPathParameters } from 'api/actions/user-update-my-certificates/user-update-my-certificates-path-parameters';
import { UserUpdateMyCertificatesResponse } from 'api/actions/user-update-my-certificates/user-update-my-certificates-response';

type UserUpdateMyCertificatesQueryParameters = Record<string, any>;

/**
 * The path of the UserUpdateMyCertificates action.
 */
export const userUpdateMyCertificatesActionPath = new SweetPath<keyof UserUpdateMyCertificatesPathParameters>(
  '/api/user/update/certificates',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserUpdateMyCertificates actions.
 */
export default function createUserUpdateMyCertificatesAction(axios: AxiosInstance) {
  return function userUpdateMyCertificatesAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UserUpdateMyCertificatesQueryParameters;
    payload: UserUpdateMyCertificatesPayload;
  }) {
    const path = userUpdateMyCertificatesActionPath.original;

    return axios.put<
      UserUpdateMyCertificatesResponse,
      UserUpdateMyCertificatesResponse,
      UserUpdateMyCertificatesPayload
    >(path, payload);
  };
}
