import { useMantineTheme } from '@mantine/core';
import {
  IconBrandSpeedtest,
  IconDatabase,
  IconPropeller,
  IconAirConditioning,
  IconX,
  IconPlug,
  IconScaleOutline,
  IconDeviceCctv,
  IconTemperaturePlus,
  IconElevator,
  IconFireExtinguisher,
} from '@tabler/icons-react';
import {
  COOLING_DEVICE_TYPE,
  ELECTRIC_DEVICE_TYPE,
  FIRE_SAFETY_DEVICE_TYPE,
  GAS_DEVICE_TYPE,
  HEATING_DEVICE_TYPE,
  LIFTING_DEVICE_TYPE,
  OTHER_DEVICE_TYPE,
  PRESSURE_DEVICE_TYPE,
  VENTILATION_DEVICE_TYPE,
  WEAK_CURRENT_DEVICE_TYPE,
} from 'model/DeviceType';

/**
 * Parameters of the TypeIcon component.
 */
interface TypeIconProps {
  /**
   * The type ID to determine the appropriate icon.
   */
  typeId: number;

  /**
   * Size of the type icon.
   */
  size?: 'sm' | 'md';
}

/**
 * Renders an icon based on the provided device type
 */
export default function DeviceTypeIcon({ typeId, size = 'sm' }: TypeIconProps) {
  const theme = useMantineTheme();
  const iconSize = size === 'sm' ? 24 : 28;

  switch (typeId) {
    case PRESSURE_DEVICE_TYPE.id:
      return <IconBrandSpeedtest size={iconSize} color={theme.colors.red[5]} />;

    case ELECTRIC_DEVICE_TYPE.id:
      return <IconPlug size={iconSize} color="#F4AB56" />;

    case OTHER_DEVICE_TYPE.id:
      return <IconDatabase size={iconSize} color={theme.colors.gray[6]} />;

    case GAS_DEVICE_TYPE.id:
      return <IconScaleOutline size={iconSize} color={theme.colors.blue[9]} />;

    case WEAK_CURRENT_DEVICE_TYPE.id:
      return <IconDeviceCctv size={iconSize} color="#EF8361" />;

    case HEATING_DEVICE_TYPE.id:
      return <IconTemperaturePlus size={iconSize} color="#CC481E" />;

    case LIFTING_DEVICE_TYPE.id:
      return <IconElevator size={iconSize} color="#B0B167" />;

    case FIRE_SAFETY_DEVICE_TYPE.id:
      return <IconFireExtinguisher size={iconSize} color={theme.colors.red[9]} />;

    case VENTILATION_DEVICE_TYPE.id:
      return <IconPropeller size={iconSize} color={theme.colors.green[7]} />;

    case COOLING_DEVICE_TYPE.id:
      return <IconAirConditioning size={iconSize} color={theme.colors.blue[5]} />;

    default:
      return <IconX size={iconSize} color={theme.colors.gray[9]} />;
  }
}
