import { ScrollArea, SimpleGrid, Stack } from '@mantine/core';
import H3Medium from 'components/typography/H3Medium';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { DEVICE_TYPES } from 'model/DeviceType';
import DeviceTypeBox from 'pages/devices-module/device-types/DeviceTypeBox';

/**
 * The page for listing all device types.
 *
 * @see {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=3543-32614&mode=design&t=kMB1fulaMZpgJfQA-0 Figma}
 */
export default function DeviceTypesPage() {
  return (
    <DashboardLayout height="screen" title="Typy zariadení" breadcrumbs={[{ title: 'Typy zariadení' }]}>
      <Stack bg="white" mih="100%" style={{ borderRadius: '4px' }} py={24} px={40} spacing={24}>
        <H3Medium color="gray.7">Vyberte zariadenie</H3Medium>
        <ScrollArea h={350}>
          <SimpleGrid
            cols={4}
            spacing={24}
            breakpoints={[
              { maxWidth: 1200, cols: 3 },
              { maxWidth: 900, cols: 2 },
              { maxWidth: 600, cols: 1 },
            ]}
          >
            {DEVICE_TYPES.map((type) => (
              <DeviceTypeBox key={type.id} deviceTypeId={type.id} />
            ))}
          </SimpleGrid>
        </ScrollArea>
      </Stack>
    </DashboardLayout>
  );
}
