import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserUpdateMyCompanySettingsPayload } from 'api/actions/user-update-my-company-settings/user-update-my-company-settings-payload';
import { UserUpdateMyCompanySettingsPathParameters } from 'api/actions/user-update-my-company-settings/user-update-my-company-settings-path-parameters';
import { UserUpdateMyCompanySettingsResponse } from 'api/actions/user-update-my-company-settings/user-update-my-company-settings-response';

type UserUpdateMyCompanySettingsQueryParameters = Record<string, any>;

/**
 * The path of the UserUpdateMyCompanySettings action.
 */
export const userUpdateMyCompanySettingsActionPath = new SweetPath<keyof UserUpdateMyCompanySettingsPathParameters>(
  '/api/user/update/company-settings',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserUpdateMyCompanySettings actions.
 */
export default function createUserUpdateMyCompanySettingsAction(axios: AxiosInstance) {
  return function userUpdateMyCompanySettingsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UserUpdateMyCompanySettingsQueryParameters;
    payload: UserUpdateMyCompanySettingsPayload;
  }) {
    const path = userUpdateMyCompanySettingsActionPath.original;

    return axios.put<
      UserUpdateMyCompanySettingsResponse,
      UserUpdateMyCompanySettingsResponse,
      UserUpdateMyCompanySettingsPayload
    >(path, payload);
  };
}
