import { RevisionTemplateRow } from 'components/tables/revision-template/types';
import RevisionTemplateActions from 'components/tables/revision-template/RevisionTemplateActions';
import { IRowNode } from 'ag-grid-community';

/**
 * Column for the actions.
 */
export default function ActionsColumn({
  node,
  data,
}: {
  node: IRowNode<RevisionTemplateRow>;
  data: RevisionTemplateRow;
}) {
  return <RevisionTemplateActions {...data} onStatusChange={(status) => node.setData({ ...data, status })} />;
}
