import { MantineThemeOverride } from '@mantine/core';

export type TooltipTheme = NonNullable<MantineThemeOverride['components']>['Tooltip'];

export const Tooltip: TooltipTheme = {
  defaultProps: {
    withArrow: true,
    arrowSize: 10,
  },
};
