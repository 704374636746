import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignLeft } from '@tabler/icons-react';

/**
 * Used to align text to the left.
 */
export default function ControlAlignLeft() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Align left" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('left').run()}
        active={editor?.isActive({ textAlign: 'left' })}
      >
        <IconAlignLeft size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
