import RestrictionSelectBaseFilter, {
  RestrictionSelectFilterBaseProps,
} from 'components/tables/filters/restriction/RestrictionSelectBaseFilter';

/**
 * Filter component for the restriction column with three options - global, organization and department.
 */
export default function ThreeRestrictionsSelectFilter({ api, context }: RestrictionSelectFilterBaseProps) {
  return (
    <RestrictionSelectBaseFilter
      api={api}
      context={context}
      data={[
        { value: 'global', label: 'Globálne' },
        { value: 'organization', label: 'Organizácia' },
        { value: 'department', label: 'Stredisko' },
      ]}
    />
  );
}
