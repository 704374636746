import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconArrowBackUp } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to undo the last action.
 */
export default function Undo({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconArrowBackUp />}
      label="Späť"
      shortcut="Ctrl + Z"
      onClick={() => editor?.chain().undo().focus().run()}
    />
  );
}
