import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetPathParameters } from 'api/actions/user-get/user-get-path-parameters';
import { UserGetResponse } from 'api/actions/user-get/user-get-response';

type UserGetQueryParameters = Record<string, any>;

/**
 * The path of the UserGet action.
 */
export const userGetActionPath = new SweetPath<keyof UserGetPathParameters>('/api/user/get/{userId}', {
  path: '{param}',
});

/**
 * Creates a contextualized UserGet actions.
 */
export default function createUserGetAction(axios: AxiosInstance) {
  return function userGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserGetPathParameters;
    query?: UserGetQueryParameters;
  }) {
    const path = userGetActionPath.insert(parameters);

    return axios.get<UserGetResponse, UserGetResponse>(path, { params: query, headers });
  };
}
