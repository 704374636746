import TableCellTiptap from '@tiptap/extension-table-cell';

export const TableCell = TableCellTiptap.extend({
  addAttributes() {
    return {
      colspan: { default: 1 },
      rowspan: { default: 1 },
      'data-border-width': { default: '1' },
      'data-border-color': { default: '#000' },
      'data-border-style': { default: 'solid' },
      style: {
        renderHTML(attributes) {
          const width = Number(attributes['data-border-width']);
          const color = attributes['data-border-color'];
          const style = attributes['data-border-style'];

          const border = width === 0 ? 'none' : `${width}px ${style} ${color}`;

          return {
            style: `border: ${border};`,
          };
        },
      },
    };
  },
});
