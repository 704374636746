import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { useMemo } from 'react';

/**
 * Sub-form for plan-next-revision automation.
 */
export default function PlanNextRevisionSubForm({ form }: { form: UseFormReturnType<IAutomation> }) {
  const { allInputs } = useFormInputs();

  const dateInputs = useMemo(
    () =>
      allInputs
        .filter((input) => input.context === 'revision' && input.spec.type === 'date')
        .map((input) => ({ value: input.spec.name, label: input.spec.label })),
    [allInputs]
  );

  const checkboxInputs = useMemo(
    () =>
      allInputs
        .filter((input) => input.context === 'revision' && input.spec.type === 'checkbox')
        .map((input) => ({ value: input.spec.name, label: input.spec.label })),
    [allInputs]
  );

  const periodInputs = useMemo(
    () =>
      allInputs
        .filter((input) => input.context === 'revision' && input.spec.type === 'period')
        .map((input) => ({ value: input.spec.name, label: input.spec.label })),
    [allInputs]
  );

  return (
    <>
      <Select
        data={dateInputs}
        label="Zobrať dátum ukončenia podľa poľa"
        required
        searchable
        size="sm"
        {...form.getInputProps('endDate')}
      />
      <Select
        data={checkboxInputs}
        label="Preplánovať iba ak je zaškrtnuté pole"
        placeholder="Preplánovať vždy"
        size="sm"
        clearable
        {...form.getInputProps('planIfChecked')}
      />
      <Select
        data={periodInputs}
        size="sm"
        placeholder="Ponechať termíny bez zmeny"
        label="Upraviť periódu termínov podľa poľa"
        clearable
        {...form.getInputProps('overridePeriod')}
      />
    </>
  );
}
