import { GridApi } from 'ag-grid-community';
import { DepartmentSelect } from 'components/selects/DepartmentSelect';
import { useCallback, useEffect, useState } from 'react';
import { FILTER_KEY as ORGANIZATION_SELECT_FILTER_KEY } from 'components/tables/filters/OrganizationSelectFilter';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'department.departmentId' as const;

/**
 * Parameters of the DepartmentSelectFilter component.
 */
export interface DepartmentSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the department column.
 *
 * This filter assumes:
 *   - column field is `department.departmentId`
 *   - context contains `initialFilters` with `department.departmentId` filter
 */
export default function DepartmentSelectFilter({ api, context }: DepartmentSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);
  const [permissionSlug] = useState<string | undefined>(context?.initialFilters?.[FILTER_KEY]?.permissionSlug);
  const [organizationId, setOrganizationId] = useState<number | null>(-1);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  useEffect(() => {
    /**
     * Handles the change of the organization id.
     */
    const onFilterChanged = () => {
      const organizationId = api.getFilterModel()[ORGANIZATION_SELECT_FILTER_KEY]?.filter ?? -1;
      setOrganizationId(organizationId);
    };

    api.addEventListener('filterChanged', onFilterChanged);
    return () => api.removeEventListener('filterChanged', onFilterChanged);
  }, []);

  return (
    <DepartmentSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky strediská"
      value={value}
      onChange={onChange}
      groupByOrganization
      organizationId={organizationId ?? -1}
      permissionSlug={permissionSlug}
    />
  );
}
