import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';
import { useApi } from 'api/api-context';

interface RevisionTechnicianSelectFilters {
  departmentId: number;
  deviceTypeId: number;
}

/**
 * Fetches devices from API.
 */
function useRevisionTechnicianSelect({ departmentId, deviceTypeId }: RevisionTechnicianSelectFilters) {
  const { getAction } = useApi();

  const fetchRevisionTechnicians = useCallback(async () => {
    const action = getAction('RevisionListTechnicians');

    const technicians = await action({
      parameters: { departmentId: String(departmentId), deviceTypeId: String(deviceTypeId) },
    });

    return technicians.map(({ fullName, userId }) => ({
      label: fullName,
      value: String(userId),
    }));
  }, [getAction, departmentId, deviceTypeId]);

  return { fetchRevisionTechnicians };
}

/**
 * Parameters of the RevisionTechnicianSelect component.
 */
type RevisionTechnicianSelectProps = DataSelectInheritedProps & RevisionTechnicianSelectFilters;

/**
 * Select for devices.
 */
export function RevisionTechnicianSelect({ departmentId, deviceTypeId, ...props }: RevisionTechnicianSelectProps) {
  const key = useMemo(nanoid, [departmentId, deviceTypeId]);
  const { fetchRevisionTechnicians } = useRevisionTechnicianSelect({ departmentId, deviceTypeId });

  return <DataSelect key={key} valueProp="value" labelProp="label" action={fetchRevisionTechnicians} {...props} />;
}
