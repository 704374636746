import { Group, Text } from '@mantine/core';
import { useApi } from 'api/api-context';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import { forwardRef, useCallback } from 'react';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';

/**
 * Fetches device types from API.
 */
function useQualificationsSelect() {
  const { getAction } = useApi();

  const fetchDeviceTypes = useCallback(async () => {
    const action = getAction('DeviceListTypes');

    const deviceTypes = await action();

    return deviceTypes.map((deviceType) => ({
      label: deviceType.deviceTypeName,
      value: String(deviceType.slug),
      image: String(deviceType.deviceTypeId),
    }));
  }, [getAction]);

  return { fetchDeviceTypes };
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
}

const DeviceTypeItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* NOTE: image is a workaround to get the ID of the device type */}
        <DeviceTypeIcon typeId={Number(image)} />
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  );
});

/**
 * Select for device types.
 */
export function QualificationsSelect({ ...props }: DataSelectInheritedProps) {
  const { fetchDeviceTypes } = useQualificationsSelect();

  return (
    <DataSelect
      valueProp="value"
      labelProp="label"
      imageProp="image"
      action={fetchDeviceTypes}
      itemComponent={DeviceTypeItem}
      {...props}
    />
  );
}
