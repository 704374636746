import { Box, Group, rem } from '@mantine/core';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useMemo } from 'react';

interface InputMentionProps {
  name: string;
  prefix?: string;
}

/**
 * Used to mention an input in the pipeline preview.
 */
export default function InputMention({ name, prefix }: InputMentionProps) {
  const { getInputByName } = useFormInputs();

  const input = useMemo(() => getInputByName(name), [name, getInputByName]);
  const label = useMemo(() => input?.spec.label ?? `Neznáme pole: ${name}`, [input, name]);
  const color = useMemo(() => (input ? 'blue' : 'red'), [input]);

  const style = useMemo(
    () => ({
      backgroundColor: `var(--mantine-color-${color}-1)`,
      borderRadius: rem(4),
    }),
    [color]
  );

  return (
    <Group spacing={2} noWrap>
      {prefix && <Box>{prefix}</Box>}
      <Box py={2} px={4} style={style} lh={1.2}>
        {label}
      </Box>
    </Group>
  );
}
