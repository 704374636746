import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OrganizationGetPathParameters } from 'api/actions/organization-get/organization-get-path-parameters';
import { OrganizationGetResponse } from 'api/actions/organization-get/organization-get-response';

type OrganizationGetQueryParameters = Record<string, any>;

/**
 * The path of the OrganizationGet action.
 */
export const organizationGetActionPath = new SweetPath<keyof OrganizationGetPathParameters>(
  '/api/organization/get/{organizationId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized OrganizationGet actions.
 */
export default function createOrganizationGetAction(axios: AxiosInstance) {
  return function organizationGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: OrganizationGetPathParameters;
    query?: OrganizationGetQueryParameters;
  }) {
    const path = organizationGetActionPath.insert(parameters);

    return axios.get<OrganizationGetResponse, OrganizationGetResponse>(path, { params: query, headers });
  };
}
