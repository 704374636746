import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListFaultsPathParameters } from 'api/actions/device-list-faults/device-list-faults-path-parameters';
import { DeviceListFaultsResponse } from 'api/actions/device-list-faults/device-list-faults-response';

type DeviceListFaultsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListFaults action.
 */
export const deviceListFaultsActionPath = new SweetPath<keyof DeviceListFaultsPathParameters>(
  '/api/device/faults/{deviceId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListFaults actions.
 */
export default function createDeviceListFaultsAction(axios: AxiosInstance) {
  return function deviceListFaultsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceListFaultsPathParameters;
    query?: DeviceListFaultsQueryParameters;
  }) {
    const path = deviceListFaultsActionPath.insert(parameters);

    return axios.get<DeviceListFaultsResponse, DeviceListFaultsResponse>(path, { params: query, headers });
  };
}
