import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentGetPathParameters } from 'api/actions/department-get/department-get-path-parameters';
import { DepartmentGetResponse } from 'api/actions/department-get/department-get-response';

type DepartmentGetQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentGet action.
 */
export const departmentGetActionPath = new SweetPath<keyof DepartmentGetPathParameters>(
  '/api/department/get/{departmentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DepartmentGet actions.
 */
export default function createDepartmentGetAction(axios: AxiosInstance) {
  return function departmentGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DepartmentGetPathParameters;
    query?: DepartmentGetQueryParameters;
  }) {
    const path = departmentGetActionPath.insert(parameters);

    return axios.get<DepartmentGetResponse, DepartmentGetResponse>(path, { params: query, headers });
  };
}
