import { Box, LoadingOverlay, Tabs } from '@mantine/core';
import RevisionTab from 'components/forms/revision/fill-out/RevisionTab';
import RevisionTabContent from 'components/forms/revision/fill-out/RevisionTabContent';
import RevisionFormFooter from 'components/forms/RevisionFormFooter';
import RevisionPreview from 'components/revision/RevisionPreview';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import RevisionMetadata from 'components/forms/revision/fill-out/RevisionMetadata';
import DevicesTabContent from 'components/forms/revision/fill-out/devices/DevicesTabContent';
import {
  FillOutRevisionDataProvider,
  useFillOutRevisionDataProvider,
} from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import {
  FillOutRevisionSaveDraftProvider,
  useFillOutRevisionSaveDraftProvider,
} from 'components/forms/revision/fill-out/data/FillOutRevisionSaveDraftProvider';
import {
  FillOutRevisionSubmitProvider,
  useFillOutRevisionSubmitProvider,
} from 'components/forms/revision/fill-out/data/FillOutRevisionSubmitProvider';
import { useMemo } from 'react';
import useTabSteps from 'hooks/use-tab-steps';

/**
 * The form for filling out a revision.
 */
function FillOutRevisionFormImpl() {
  const { saveDraft, loading: loadingSave } = useFillOutRevisionSaveDraftProvider();
  const { submit, loading: loadingSubmit } = useFillOutRevisionSubmitProvider();
  const { revision, form, sectionsByTab, tabHasVisibleInputs } = useFillOutRevisionDataProvider();

  const tabs = useMemo(
    () => [
      { key: 'basic-information', title: 'Základné informácie' },
      { key: 'description', title: 'Popis', hidden: !tabHasVisibleInputs('description') },
      { key: 'specification', title: 'Špecifikácia', hidden: !tabHasVisibleInputs('specification') },
      { key: 'devices', title: 'Zariadenia' },
      { key: 'conclusion', title: 'Záver', hidden: !tabHasVisibleInputs('conclusion') },
      { key: 'preview', title: 'Náhľad' },
    ],
    [tabHasVisibleInputs]
  );

  const { steps, currStep, setCurrStep, isFirstStep, isLastStep, nextStep, prevStep } = useTabSteps(tabs);

  return (
    <Box w="100%" className="rounded-[4px]" bg="white">
      <LoadingOverlay visible={loadingSubmit} />
      <Tabs value={currStep.key} onTabChange={(value) => setCurrStep(value ?? steps[0].key)}>
        <Tabs.List>
          {tabs
            .filter(({ hidden }) => !hidden)
            .map(({ key, title }) => (
              <Tabs.Tab key={key} value={key}>
                {title}
              </Tabs.Tab>
            ))}
        </Tabs.List>

        <RevisionTab value={steps[0].key}>
          <RevisionMetadata />
          <RevisionTabContent sections={sectionsByTab.info} />
        </RevisionTab>
        <RevisionTab value={steps[1].key}>
          <RevisionTabContent sections={sectionsByTab.description} />
        </RevisionTab>
        <RevisionTab value={steps[2].key}>
          <RevisionTabContent sections={sectionsByTab.specification} />
        </RevisionTab>
        <RevisionTab value={steps[3].key}>
          <DevicesTabContent />
        </RevisionTab>
        <RevisionTab value={steps[4].key}>
          <RevisionTabContent sections={sectionsByTab.conclusion} />
        </RevisionTab>
        {currStep === steps[5] && (
          <RevisionTab skinny value={steps[5].key}>
            <RevisionPreview key={currStep.key} revisionData={form.values} revision={revision} />
          </RevisionTab>
        )}
      </Tabs>

      <RevisionFormFooter
        loading={loadingSave}
        isFirstStep={isFirstStep}
        isLastStep={isLastStep}
        onPrevStep={prevStep}
        onNextStep={nextStep}
        onSave={saveDraft}
        onSubmit={submit}
      />
    </Box>
  );
}

/**
 * The form for filling out a revision.
 */
export default function FillOutRevisionForm({ revision }: { revision: RevisionGetResponse }) {
  return (
    <FillOutRevisionDataProvider revision={revision}>
      <FillOutRevisionSaveDraftProvider>
        <FillOutRevisionSubmitProvider>
          <FillOutRevisionFormImpl />
        </FillOutRevisionSubmitProvider>
      </FillOutRevisionSaveDraftProvider>
    </FillOutRevisionDataProvider>
  );
}
