import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionRejectPayload } from 'api/actions/revision-reject/revision-reject-payload';
import { RevisionRejectPathParameters } from 'api/actions/revision-reject/revision-reject-path-parameters';
import { RevisionRejectResponse } from 'api/actions/revision-reject/revision-reject-response';

type RevisionRejectQueryParameters = Record<string, any>;

/**
 * The path of the RevisionReject action.
 */
export const revisionRejectActionPath = new SweetPath<keyof RevisionRejectPathParameters>(
  '/api/revision/reject/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionReject actions.
 */
export default function createRevisionRejectAction(axios: AxiosInstance) {
  return function revisionRejectAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionRejectPathParameters;
    query?: RevisionRejectQueryParameters;
    payload: RevisionRejectPayload;
  }) {
    const path = revisionRejectActionPath.insert(parameters);

    return axios.post<RevisionRejectResponse, RevisionRejectResponse, RevisionRejectPayload>(path, payload);
  };
}
