import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import PickUsersModal from 'components/modals/pick-users-modal/PickUsersModal';

/**
 * Parameters of the onPick callback.
 */
export interface OnPickParams {
  pickedUsers: number[];
  permissions: number[];
  templateName: string;
}

/**
 * Callback for when user selects the list of users and their permissions.
 */
export type OnPickCallback = (value: OnPickParams) => void;

/**
 * Parameters of the pickUsers function.
 */
export interface PickUsersParams {
  title: string;
  permissionsTitle?: string;
  hiddenUsers: number[];
  inForm: 'department' | 'organization';
  pickUsers?: boolean;
  pickPermissions?: boolean;
  initialTemplateName: string;
  initialPermissions: number[];
  onPick: OnPickCallback;
}

/**
 * Function for selecting the list of users and their permissions.
 */
type PickUsers = (params: PickUsersParams) => void;

/**
 * Interface of the PickUsersContext.
 */
interface IPickUsersContext {
  pickUsers: PickUsers;
}

/**
 * Context for selecting the list of users and their permissions.
 */
const PickUsersContext = createContext<IPickUsersContext>(undefined!);

/**
 * Provider for selecting the list of users and their permissions.
 */
export function PickUsersProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<PickUsersParams | undefined>(undefined);

  /**
   * Opens the modal for selecting the list of users and their permissions.
   */
  const pickUsers: PickUsers = (params) => {
    setParams(params);
    open();
  };

  return (
    <PickUsersContext.Provider value={{ pickUsers }}>
      {children}
      {opened && <PickUsersModal opened onClose={close} {...params!} />}
    </PickUsersContext.Provider>
  );
}

/**
 * Hook for selecting the list of users and their permissions.
 */
export function usePickUsers() {
  return useContext(PickUsersContext);
}
