import { useMemo } from 'react';
import { IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Creates styles for placeholders based on the form inputs.
 */
export function useInjectSectionNameStyles(sections: IFormSection[]) {
  return useMemo(
    () =>
      sections.reduce(
        (acc, { id, name }) => ({
          ...acc,
          [`& .ProseMirror [data-inject-section-name="${id}"]::before`]: {
            content: `"${name.replace(/"/g, '\\"')}"`,
            outline: 'none',
          },
        }),
        {}
      ),
    [sections]
  );
}
