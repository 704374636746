import { ADD_DEPARTMENT_PAGE_PATH } from 'routes/paths';
import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import DataTable from 'components/tables/DataTable';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import { DepartmentRow } from 'components/tables/department/types';
import DepartmentNameColumn from 'components/tables/department/columns/DepartmentNameColumn';
import OrganizationNameColumn from 'components/tables/department/columns/OrganizationNameColumn';
import AddressColumn from 'components/tables/department/columns/AddressColumn';
import ContactPersonColumn from 'components/tables/department/columns/ContactPersonColumn';
import ActionsColumn from 'components/tables/department/columns/ActionsColumn';

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'checkbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressMovable: true,
    width: 44,
    minWidth: 44,
    maxWidth: 44,
    cellStyle: ({ data: { status } }: { data: DepartmentRow }) => ({ opacity: status ? 1 : 0.6 }),
  },
  { field: 'departmentId', headerName: 'ID strediska', hide: true },
  {
    field: 'departmentName',
    headerName: 'Názov',
    minWidth: 200,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  { field: 'departmentNumber', headerName: 'Číslo strediska', hide: true },
  {
    field: 'organization.organizationId',
    headerName: 'ID organizácie',
    hide: true,
    filter: true,
  },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  { field: 'streetAddress', headerName: 'Ulica', hide: true },
  { field: 'city', headerName: 'Mesto', hide: true },
  { field: 'zip', headerName: 'PSČ', hide: true },
  { field: 'country', headerName: 'Krajina', hide: true },
  {
    field: 'address',
    headerName: 'Adresa',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    cellRenderer: AddressColumn,
  },
  { field: 'contactPerson.userId', headerName: 'ID kontaktnej osoby', hide: true },
  { field: 'contactPerson.fullName', headerName: 'Meno kontaktnej osoby', hide: true },
  {
    field: 'contactPerson.firstName',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson.lastName',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson.email',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'contactPerson',
    headerName: 'Kontaktná osoba',
    minWidth: 200,
    resizable: true,
    wrapText: true,
    cellRenderer: ContactPersonColumn,
  },
  {
    valueGetter: ({ data: { status } }: { data: DepartmentRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívne',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Parameters of the DepartmentTable component.
 */
export interface DepartmentTableProps {
  search?: string;
  showDiscarded?: boolean;
}

/**
 * Table which displays departments.
 */
export default function DepartmentTable({ search = '', showDiscarded = false }: DepartmentTableProps = {}) {
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();

  const [initialFilters] = useState(() => ({
    'organization.organizationId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('organizationId') ?? '',
      permissionSlug: 'manage-departments',
    },
  }));

  const action = useCallback(() => {
    const action = getAction('DepartmentList');
    return action({ query: { filters: { 'permissionSlug.eq': 'manage-departments' } } });
  }, [getAction]);

  const additionalFilters = useMemo(() => {
    if (!showDiscarded) {
      return {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      };
    }

    return { status: null };
  }, [showDiscarded]);

  return (
    <DataTable
      columns={columns}
      action={action}
      search={search}
      addButtonText="Pridať nové"
      addButtonTarget={ADD_DEPARTMENT_PAGE_PATH.original}
      initialFilters={initialFilters}
      additionalFilters={additionalFilters}
      dataExport={{
        modalTitle: 'Exportovať strediská',
        fileName: 'strediska.xlsx',
        columnKeys: [
          'departmentId',
          'departmentName',
          'departmentNumber',
          'streetAddress',
          'city',
          'zip',
          'country',
          'status',
          'contactPerson.userId',
          'contactPerson.fullName',
          'organization.organizationId',
          'organization.organizationName',
        ],
      }}
    />
  );
}
