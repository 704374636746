import { mergeAttributes, Node } from '@tiptap/core';
import { IFormInputContext, IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import { INPUT_TYPE_NAMES } from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';

export type IPlaceholderType =
  | IFormInputType
  | 'technicianStamp'
  | 'technicianCertificateRenewalDate'
  | 'technicianProperty'
  | 'revisionProperty'
  | 'documentProperty'
  | 'deviceFaultsOrderedList';

export type IPlaceholderContext = IFormInputContext | 'global';

export interface IPlaceholder {
  context: IPlaceholderContext;
  name: string;
  label: string;
  type: IPlaceholderType;
}

interface ISpecialPlaceholder extends IPlaceholder {
  name: `_${string}`;
}

export interface PlaceholderOptions {
  inline: boolean;
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    placeholder: {
      insertPlaceholder: (placeholder: IPlaceholder) => ReturnType;
    };
  }
}

/** Returns the label of the placeholder type. */
export function getPlaceholderTypeLabel(type: IPlaceholderType) {
  switch (type) {
    case 'technicianProperty':
    case 'technicianStamp':
    case 'technicianCertificateRenewalDate':
      return 'Vlastnosť revízneho technika';

    case 'revisionProperty':
      return 'Vlastnosť revíznej správy';

    case 'documentProperty':
      return 'Vlastnosť dokumentu';

    case 'deviceFaultsOrderedList':
      return 'Závady zariadenia';

    default:
      return INPUT_TYPE_NAMES[type]?.label ?? '';
  }
}

/** Returns the kind of the placeholder. */
export function getPlaceholderKind(type: IPlaceholderType): 'inline' | 'block' {
  switch (type) {
    case 'measuringDevice':
    case 'predefinedList':
    case 'wysiwyg':
    case 'deviceFaultsOrderedList':
      return 'block';

    default:
      return 'inline';
  }
}

/** Returns the path suffix based on the placeholder type. */
export function getPlaceholderPathSuffix(type: IPlaceholderType) {
  switch (type) {
    case 'safetyPoint':
      return 'label';

    case 'predefinedSelect':
      return 'primary';

    case 'qualification':
      return 'qualificationNumber';

    default:
      return '';
  }
}

export const DEVICE_PROPERTY_PLACEHOLDERS: ISpecialPlaceholder[] = [
  { context: 'device', name: '_deviceName', label: 'Názov zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceLocation', label: 'Umiestnenie zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceManufacturer', label: 'Výrobca zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceManufactured', label: 'Rok výroby zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceSerialNumber', label: 'Výrobné číslo zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceSubTypeName', label: 'Typ zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceBuilding', label: 'Budova zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceFloor', label: 'Podlažie zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceRoom', label: 'Miestnosť zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceLatitude', label: 'Zemepisná šírka zariadenia (GPS)', type: 'deviceProperty' },
  { context: 'device', name: '_deviceLongitude', label: 'Zemepisná dĺžka zariadenia (GPS)', type: 'deviceProperty' },
  {
    context: 'device',
    name: '_deviceInternalPossessionsNumber',
    label: 'Interné číslo zariadenia',
    type: 'deviceProperty',
  },
  { context: 'device', name: '_deviceInternalNote', label: 'Interná poznámka zariadenia', type: 'deviceProperty' },
  { context: 'device', name: '_deviceFaultCount', label: 'Počet závad zariadenia', type: 'deviceProperty' },
];

export const TECHNICIAN_PROPERTY_PLACEHOLDERS: ISpecialPlaceholder[] = [
  { context: 'revision', name: '_technicianName', label: 'Meno revízneho technika', type: 'technicianProperty' },
  {
    context: 'revision',
    name: '_technicianOrganization',
    label: 'Organizácia revízneho technika',
    type: 'technicianProperty',
  },
  {
    context: 'revision',
    name: '_technicianPhone',
    label: 'Telefónne číslo revízneho technika',
    type: 'technicianProperty',
  },
  { context: 'revision', name: '_technicianEmail', label: 'Email revízneho technika', type: 'technicianProperty' },
  {
    context: 'revision',
    name: '_technicianCertificateNumber',
    label: 'Číslo osvedčenia revízneho technika',
    type: 'technicianProperty',
  },
  {
    context: 'revision',
    name: '_technicianCertificateStamp',
    label: 'Pečiatka revízneho technika',
    type: 'technicianStamp',
  },
  {
    context: 'revision',
    name: '_technicianCertificateRenewalDate',
    label: 'Aktualizačná príprava revízneho technika',
    type: 'technicianCertificateRenewalDate',
  },
];

export const REVISION_PROPERTY_PLACEHOLDERS: ISpecialPlaceholder[] = [
  { context: 'revision', name: '_revisionName', label: 'Názov revíznej správy', type: 'revisionProperty' },
  { context: 'revision', name: '_organization', label: 'Organizácia', type: 'revisionProperty' },
  { context: 'revision', name: '_department', label: 'Stredisko', type: 'revisionProperty' },
  { context: 'revision', name: '_totalFaultCount', label: 'Počet závad celkovo', type: 'revisionProperty' },
];

export const DOCUMENT_PROPERTY_PLACEHOLDERS: ISpecialPlaceholder[] = [
  { context: 'global', name: '_page', label: 'Číslo strany', type: 'documentProperty' },
  { context: 'global', name: '_pages', label: 'Počet strán', type: 'documentProperty' },
];

export const SPECIAL_PLACEHOLDERS: ISpecialPlaceholder[] = [
  ...DEVICE_PROPERTY_PLACEHOLDERS,
  ...TECHNICIAN_PROPERTY_PLACEHOLDERS,
  ...REVISION_PROPERTY_PLACEHOLDERS,
  ...DOCUMENT_PROPERTY_PLACEHOLDERS,
];

const Placeholder = Node.create<PlaceholderOptions>({
  name: 'placeholder',
  draggable: true,

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },

  addOptions() {
    return {
      inline: true,
      HTMLAttributes: {
        class: 'placeholder',
      },
    };
  },

  addAttributes() {
    return {
      'data-name': { default: '' },
      'data-type': { default: '' },
      'data-format': { default: '' },
      'data-context': {
        default: '',
        parseHTML: (el) => {
          const context = el.getAttribute('data-context');

          if (context === 'fields') {
            return 'revision'; // Backward compatibility
          }

          return context;
        },
      },
      'data-path': {
        default: '',
        renderHTML: (attributes) => {
          const name = attributes['data-name'];
          const context = attributes['data-context'];
          const type = attributes['data-type'];

          const prefix = context === 'global' ? 'global' : 'fields';
          const suffix = getPlaceholderPathSuffix(type);

          const path = [prefix, name, suffix].filter(Boolean).join('.');

          return {
            'data-path': path,
          };
        },
      },
      'data-inject-field-name': {
        default: '',
        renderHTML: (attributes) => ({ 'data-inject-field-name': attributes['data-name'] }),
      },
    };
  },

  parseHTML() {
    const tag = this.options.inline ? 'span' : 'div';
    return [{ tag: `${tag}.placeholder`, priority: 50 }];
  },

  renderHTML({ HTMLAttributes }) {
    const tag = this.options.inline ? 'span' : 'div';
    return [tag, mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ''];
  },

  addCommands() {
    return {
      insertPlaceholder:
        ({ name, context, type }) =>
        ({ commands }) => {
          const kind = getPlaceholderKind(type);

          return commands.insertContent({
            type: `${kind}Placeholder`,
            attrs: {
              'data-name': name,
              'data-type': type,
              'data-context': context,
            },
          });
        },
    };
  },
});

export const InlinePlaceholder = Placeholder.extend({ name: 'inlinePlaceholder' }).configure({ inline: true });
export const BlockPlaceholder = Placeholder.extend({ name: 'blockPlaceholder' }).configure({ inline: false });
