import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthRemoveUserOrganizationPairsPayload } from 'api/actions/auth-remove-user-organization-pairs/auth-remove-user-organization-pairs-payload';
import { AuthRemoveUserOrganizationPairsPathParameters } from 'api/actions/auth-remove-user-organization-pairs/auth-remove-user-organization-pairs-path-parameters';
import { AuthRemoveUserOrganizationPairsResponse } from 'api/actions/auth-remove-user-organization-pairs/auth-remove-user-organization-pairs-response';

type AuthRemoveUserOrganizationPairsQueryParameters = Record<string, any>;

/**
 * The path of the AuthRemoveUserOrganizationPairs action.
 */
export const authRemoveUserOrganizationPairsActionPath = new SweetPath<
  keyof AuthRemoveUserOrganizationPairsPathParameters
>('/api/auth/user-organization/remove', { path: '{param}' });

/**
 * Creates a contextualized AuthRemoveUserOrganizationPairs actions.
 */
export default function createAuthRemoveUserOrganizationPairsAction(axios: AxiosInstance) {
  return function authRemoveUserOrganizationPairsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthRemoveUserOrganizationPairsQueryParameters;
    payload: AuthRemoveUserOrganizationPairsPayload;
  }) {
    const path = authRemoveUserOrganizationPairsActionPath.original;

    return axios.put<
      AuthRemoveUserOrganizationPairsResponse,
      AuthRemoveUserOrganizationPairsResponse,
      AuthRemoveUserOrganizationPairsPayload
    >(path, payload);
  };
}
