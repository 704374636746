import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceSubtypeSetStatusPayload } from 'api/actions/device-subtype-set-status/device-subtype-set-status-payload';
import { DeviceSubtypeSetStatusPathParameters } from 'api/actions/device-subtype-set-status/device-subtype-set-status-path-parameters';
import { DeviceSubtypeSetStatusResponse } from 'api/actions/device-subtype-set-status/device-subtype-set-status-response';

type DeviceSubtypeSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the DeviceSubtypeSetStatus action.
 */
export const deviceSubtypeSetStatusActionPath = new SweetPath<keyof DeviceSubtypeSetStatusPathParameters>(
  '/api/device/subtypes/status/{deviceSubtypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceSubtypeSetStatus actions.
 */
export default function createDeviceSubtypeSetStatusAction(axios: AxiosInstance) {
  return function deviceSubtypeSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceSubtypeSetStatusPathParameters;
    query?: DeviceSubtypeSetStatusQueryParameters;
    payload: DeviceSubtypeSetStatusPayload;
  }) {
    const path = deviceSubtypeSetStatusActionPath.insert(parameters);

    return axios.put<DeviceSubtypeSetStatusResponse, DeviceSubtypeSetStatusResponse, DeviceSubtypeSetStatusPayload>(
      path,
      payload
    );
  };
}
