import { Flex } from '@mantine/core';
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import P2Medium from 'components/typography/P2Medium';
import P3Medium from 'components/typography/P3Medium';

/**
 * Parameters of the Badge component.
 */
export interface BadgeProps {
  text: string;
  size?: 'sm' | 'md';
}

/**
 * Badge marking deleted rows in a table.
 */
export default function BadgeDeleted({ text, size = 'md' }: BadgeProps) {
  const iconSize = size === 'sm' ? 16 : 24;
  const miw = size === 'sm' ? 82 : 112;
  const py = size === 'sm' ? 0 : 4;
  const px = size === 'sm' ? 6 : 8;

  const style = {
    borderRadius: '40px',
    border: '1px solid white',
  };

  const Text = size === 'sm' ? P3Medium : P2Medium;

  return (
    <Flex bg="red.0" miw={miw} gap={4} py={py} px={px} align="center" style={style}>
      <IconExclamationCircle size={iconSize} color="#C92A45" />
      <Text color="red.9">{text}</Text>
    </Flex>
  );
}

/**
 * Badge marking fixed rows in a table.
 */
export function BadgeFixed({ text }: BadgeProps) {
  return (
    <Flex bg="gray.1" miw={112} gap={4} py={4} px={8} style={{ borderRadius: '40px', border: '1px solid white' }}>
      <IconCheck color="#353746" />
      <P2Medium color="gray.8">{text}</P2Medium>
    </Flex>
  );
}
