import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import NotificationSettingsForm, {
  NotificationSettingsFormData,
} from 'components/forms/notification-settings/NotificationSettingsForm';
import { NOTIFICATION_SETTINGS_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page where the user can set their notifications.
 */
export default function NotificationSettingsPage() {
  const navigate = useNavigate();
  const { fullName, getAction, userId } = useApi();
  const { userId: paramsUserId } = useParams();
  const [displayedName, setDisplayedName] = useState('');

  const { data, loading, error } = useImmediateAction(() => {
    const userGetNotificationSettings = getAction('UserGetNotificationSettings');

    return userGetNotificationSettings({ parameters: { userId: paramsUserId! } });
  });

  /**
   * Handles the form submission.
   */
  function submit({ revisions, revisionPlans, departmentIds, deviceTypeIds }: NotificationSettingsFormData) {
    const action = getAction('UserUpdateNotificationSettings');

    return action({
      parameters: {
        userId: paramsUserId!,
      },
      payload: {
        revisions,
        revisionPlans,
        departmentIds: departmentIds ? departmentIds.map((id) => Number(id)) : [],
        deviceTypeIds: deviceTypeIds ? deviceTypeIds.map((id) => Number(id)) : [],
      },
    })
      .then(() => {
        const message =
          userId === Number(paramsUserId!)
            ? 'Vaše upozornenia boli úspešne nastavené'
            : `Upozornenia pre ${displayedName} boli úspešne nastavené.`;

        showNotification({
          title: 'Upozornenia boli nastavené',
          message,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (NOTIFICATION_SETTINGS_REDIRECT_AFTER_SAVE === 'list') {
          return navigate(DASHBOARD_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  useEffect(() => {
    if (paramsUserId && Number(paramsUserId) === userId) {
      setDisplayedName(fullName!);
    } else {
      const userGetAction = getAction('UserGet');

      userGetAction({ parameters: { userId: paramsUserId! } }).then((response) => {
        setDisplayedName(`${response.firstName} ${response.lastName}`);
      });
    }
  }, [userId, paramsUserId]);

  // Display the error if there is one.
  useEffect(() => {
    if (error) {
      panic(error);
    }
  }, [error]);

  if (!userId) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} />;
  }

  return (
    <DashboardLayout
      title="Nastavenia upozornení"
      breadcrumbs={[{ title: displayedName }, { title: 'Nastavenia upozornení' }]}
    >
      {!loading && !error && (
        <NotificationSettingsForm
          onSubmit={submit}
          initialValues={{
            revisions: data.revisions,
            revisionPlans: data.revisionPlans,
            departmentIds: data.departmentIds.map((id) => String(id)),
            deviceTypeIds: data.deviceTypeIds.map((id) => String(id)),
          }}
        />
      )}
    </DashboardLayout>
  );
}
