import SimpleControl, {
  SimpleControlKind as Kind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconLetterT } from '@tabler/icons-react';

/**
 * Used to insert a non-breaking space into the editor.
 */
export default function InsertTabulator({ kind, hideIcon = false }: { kind: Kind; hideIcon?: boolean }) {
  const { editor } = useRichTextEditorContext();
  const tabulator = '\u0009';

  return (
    <SimpleControl
      kind={kind}
      icon={hideIcon ? undefined : <IconLetterT />}
      label="Tabulátor"
      shortcut="Tab"
      onClick={() => editor?.chain().focus().insertContent(tabulator).run()}
    />
  );
}
