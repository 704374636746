import { Button, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import FormSection from 'pages/revisions-module/template-editor/editors/form/section/FormSection';
import { useMemo } from 'react';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { IConfigureInputParams } from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { omit } from 'lodash';
import { ALL_TYPES_ON } from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';
import { IFormTabSlug } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Parameters for the FormTab component.
 */
export interface FormTabProps {
  slug: IFormTabSlug;
}

/**
 * A single tab in the form editor.
 */
export default function FormTab({ slug }: FormTabProps) {
  const { sections, addSection } = useFormSections();

  const sectionsInTab = useMemo(() => sections.filter((section) => section.tab === slug), [sections, slug]);

  const configureInputParams: Partial<IConfigureInputParams> = useMemo(
    () => ({
      inputTypes: omit(ALL_TYPES_ON, ['deviceProperty']),
    }),
    []
  );

  return (
    <Stack spacing={40}>
      {sectionsInTab.map((section, index) => (
        <FormSection
          key={section.id}
          data={section}
          context="revision"
          configureInputParams={configureInputParams}
          isFirst={index === 0}
          isLast={index === sectionsInTab.length - 1}
        />
      ))}
      <Button variant="tertiary" size="lg" leftIcon={<IconPlus />} ml={40} onClick={() => addSection(slug)}>
        Pridať sekciu
      </Button>
    </Stack>
  );
}
