import { rem, Select } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RevisionRow } from 'components/tables/revision/types';

type RevisionTemplate = NonNullable<RevisionRow['revisionTemplate']>;

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'revisionTemplate.slug' as const;

/**
 * Parameters of the RevisionTemplateSelectFilter component.
 */
export interface RevisionTemplateSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the revisionTemplate column.
 *
 * This filter assumes:
 *   - column field is `revisionTemplate.slug`
 *   - context contains `initialFilters` with `revisionTemplate.slug` filter
 */
export default function RevisionTemplateSelectFilter({ api, context }: RevisionTemplateSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);
  const [templates, setTemplates] = useState<RevisionTemplate[]>([]);

  const options = useMemo(
    () =>
      templates.map(({ slug, name }) => ({ value: slug, label: name })).sort((a, b) => a.label.localeCompare(b.label)),
    [templates]
  );

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  useEffect(() => {
    /** Finds unique templates and sets them as options. */
    const findUniqueTemplates = () => {
      const uniqueTemplates = new Map<string, RevisionTemplate>();

      api.forEachNode(({ displayed, data: { revisionTemplate } }) => {
        if (displayed && revisionTemplate && !uniqueTemplates.has(revisionTemplate.slug)) {
          uniqueTemplates.set(revisionTemplate.slug, revisionTemplate);
        }
      });

      setTemplates(Array.from(uniqueTemplates.values()));
    };

    api.addEventListener('modelUpdated', findUniqueTemplates);
    return () => api.removeEventListener('modelUpdated', findUniqueTemplates);
  }, []);

  return (
    <Select
      data={options}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky šablóny"
      value={value}
      onChange={onChange}
      styles={{ dropdown: { minWidth: rem(300) } }}
    />
  );
}
