import { useForm } from '@mantine/form';
import { noop } from 'lodash';
import useLoadingAction from 'hooks/use-loading-action';
import { Group, TextInput } from '@mantine/core';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import FormInputGroup from 'components/forms/FormInputGroup';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import ChangePasswordForm, { ChangePasswordFormData } from 'components/forms/my-profile/ChangePasswordForm';
import { FormMessage } from 'components/forms/FormProps';
import FormWrapper from 'components/forms/FormWrapper';
import { IconCheck } from '@tabler/icons-react';

/**
 * The data collected from the form.
 */
export interface MyProfileFormData {
  preNominals?: string;
  postNominals?: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

/**
 * Parameters of the MyProfileForm component.
 */
export interface MyProfileFormProps {
  initialValues: MyProfileFormData;
  passwordChangeMessage?: FormMessage;
  onSubmit?: (data: MyProfileFormData) => void;
  onPasswordChange?: (data: ChangePasswordFormData) => void;
}

/**
 * Form for editing user's own profile.
 */
export default function MyProfileForm({
  initialValues,
  passwordChangeMessage,
  onPasswordChange = noop,
  onSubmit = noop,
}: MyProfileFormProps) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<MyProfileFormData>({
    initialValues,
    validate: {
      firstName: createValidator([required]),
      lastName: createValidator([required]),
      email: createValidator([required, emailFormat]),
    },
  });

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText="Uložiť"
      primaryIcon={<IconCheck stroke="1.5" height={24} width={24} />}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Osobné údaje">
        <Group grow spacing={40}>
          <TextInput
            label="Titul pred menom"
            size="lg"
            name="amperia-preNominals"
            {...form.getInputProps('preNominals')}
          />
          <TextInput
            label="Titul za menom"
            size="lg"
            name="amperia-postNominals"
            {...form.getInputProps('postNominals')}
          />
        </Group>
        <Group grow spacing={40}>
          <TextInput label="Meno" size="lg" name="amperia-firstName" {...form.getInputProps('firstName')} />
          <TextInput label="Priezvisko" size="lg" name="amperia-lastName" {...form.getInputProps('lastName')} />
        </Group>
        <Group grow spacing={40}>
          <TextInput
            size="lg"
            type="email"
            placeholder="email@email.com"
            label="E-mail"
            name="amperia-email"
            {...form.getInputProps('email')}
          />
          <TextInput
            label="Telefón"
            placeholder="+421 123 456 789"
            size="lg"
            type="tel"
            name="amperia-phoneNumber"
            {...form.getInputProps('phoneNumber')}
          />
        </Group>
      </FormInputGroup>

      <ChangePasswordForm onSubmit={onPasswordChange} message={passwordChangeMessage} />
    </FormWrapper>
  );
}
