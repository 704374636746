import { Select, rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'safetyPoint' as const;

/**
 * The options of the filter.
 */
export const OPTIONS = [
  { value: 'suitable', label: 'Vyhovuje' },
  { value: 'not-suitable', label: 'Nevyhovuje' },
  { value: 'with-reservations', label: 'Vyhovuje s výhradami' },
];

/**
 * Parameters of the RevisionSafetyPointSelectFilter component.
 */
interface RevisionSafetyPointSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the revision safetyPoint column.
 *
 * This filter assumes:
 *  - column field is `safetyPoint`
 * - context contains `initialFilters` with `safetyPoint` filter
 */
export default function RevisionSafetyPointSelectFilter({ api, context }: RevisionSafetyPointSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <Select
      clearable
      searchable
      w="100%"
      size="sm"
      placeholder="Všetky typy záveru"
      data={OPTIONS}
      value={value}
      onChange={onChange}
      styles={{ dropdown: { minWidth: rem(180) } }}
    />
  );
}
