import { useMemo } from 'react';
import { IFormInput } from 'pages/revisions-module/template-editor/editors/form/types';
import { SPECIAL_PLACEHOLDERS } from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/Placeholder';

/**
 * Creates styles for placeholders based on the form inputs.
 */
export function useInjectFieldNameStyles(inputs: IFormInput[]) {
  return useMemo(
    () =>
      [...inputs.map(({ spec }) => spec), ...SPECIAL_PLACEHOLDERS].reduce(
        (acc, { name, label }) => ({
          ...acc,
          [`& .ProseMirror [data-inject-field-name="${name}"]::before`]: {
            content: `"${label.replace(/"/g, '\\"')}"`,
            outline: 'none',
          },
        }),
        {}
      ),
    [inputs]
  );
}
