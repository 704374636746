import WysiwygEditor from 'components/inputs/wysiwyg/WysiwygEditor';
import { IFormInputSpecWysiwyg } from 'pages/revisions-module/template-editor/editors/form/types';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

/**
 * WYSIWYG input for the revision form.
 */
export default function RevisionFieldWysiwyg({ spec, name }: RevisionFieldProps<IFormInputSpecWysiwyg>) {
  const { form } = useFillOutRevisionDataProvider();

  return <WysiwygEditor label={spec.label} {...form.getInputProps(name)} />;
}
