import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import CompanySettingsForm, { CompanySettingsFormData } from 'components/forms/company-settings/CompanySettingsForm';
import { COMPANY_SETTINGS_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { getDeviceType } from 'model/DeviceType';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, SETTINGS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit the currently logged in user's company settings.
 */
export default function MyCompanySettingsPage() {
  const { getAction } = useApi();
  const navigate = useNavigate();

  const { data, loading, error } = useImmediateAction(() => {
    const userGetMeAction = getAction('AuthLoggedUserInfo');
    return userGetMeAction();
  });

  /**
   * Handles the form submission.
   */
  function submit({ companyName, streetAddress, city, zip, country, companyQualifications }: CompanySettingsFormData) {
    const userUpdateMyCompanySettingsAction = getAction('UserUpdateMyCompanySettings');

    return userUpdateMyCompanySettingsAction({
      payload: {
        companyName,
        streetAddress,
        city,
        zip,
        country,
        qualifications: companyQualifications.map(({ qualificationNumber, type }) => ({
          qualificationNumber,
          deviceTypeId: getDeviceType(type)!.id,
        })),
      },
    })
      .then(() => {
        notifications.show({
          title: 'Informácie o spoločnosti boli aktualizované',
          message: 'Informácie o Vašej spoločnosti boli úspešne aktualizované.',
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (COMPANY_SETTINGS_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DASHBOARD_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  // Display the error if there is one.
  useEffect(() => {
    if (error) {
      panic(error);
    }
  }, [error]);

  return (
    <DashboardLayout
      title="Nastavenia spoločnosti"
      breadcrumbs={[{ title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original }, { title: 'Nastavenia spoločnosti' }]}
    >
      {!loading && !error && (
        <CompanySettingsForm
          onSubmit={submit}
          initialValues={{
            companyName: data.companyDetail?.companyName ?? '',
            streetAddress: data.companyDetail?.streetAddress ?? '',
            city: data.companyDetail?.city ?? '',
            zip: data.companyDetail?.zip ?? '',
            country: data.companyDetail?.country ?? '',
            companyQualifications:
              (data.companyDetail?.qualifications || []).map(({ qualificationNumber, deviceTypeId }) => ({
                qualificationNumber,
                type: getDeviceType(deviceTypeId)!.slug,
              })) ?? [],
          }}
        />
      )}
    </DashboardLayout>
  );
}
