import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthLoginPayload } from 'api/actions/auth-login/auth-login-payload';
import { AuthLoginPathParameters } from 'api/actions/auth-login/auth-login-path-parameters';
import { AuthLoginResponse } from 'api/actions/auth-login/auth-login-response';

type AuthLoginQueryParameters = Record<string, any>;

/**
 * The path of the AuthLogin action.
 */
export const authLoginActionPath = new SweetPath<keyof AuthLoginPathParameters>('/api/auth/login', { path: '{param}' });

/**
 * Creates a contextualized AuthLogin actions.
 */
export default function createAuthLoginAction(axios: AxiosInstance) {
  return function authLoginAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthLoginQueryParameters;
    payload: AuthLoginPayload;
  }) {
    const path = authLoginActionPath.original;

    return axios.post<AuthLoginResponse, AuthLoginResponse, AuthLoginPayload>(path, payload);
  };
}
