import { NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Sub-form for number input properties
 */
export default function NumberInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return (
    <>
      <NumberInput label="Minimálna hodnota" {...form.getInputProps('min')} />
      <NumberInput label="Maximálna hodnota" {...form.getInputProps('max')} />
      <NumberInput
        label="Počet desatinných miest"
        min={0}
        max={8}
        defaultValue={0}
        {...form.getInputProps('precision')}
      />
      <TextInput label="Suffix" {...form.getInputProps('suffix')} />
    </>
  );
}
