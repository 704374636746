import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconH1, IconH2, IconH3, IconH4, IconH5, IconH6, IconTextSize } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import SimpleControl from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';

interface Style {
  Icon: React.FC<{ size?: number }>;
  label: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
}

const STYLES: Style[] = [
  { Icon: IconTextSize, label: 'Obyčajný text' },
  { Icon: IconH1, label: 'Nadpis 1', level: 1 },
  { Icon: IconH2, label: 'Nadpis 2', level: 2 },
  { Icon: IconH3, label: 'Nadpis 3', level: 3 },
  { Icon: IconH4, label: 'Nadpis 4', level: 4 },
  { Icon: IconH5, label: 'Nadpis 5', level: 5 },
  { Icon: IconH6, label: 'Nadpis 6', level: 6 },
];

/**
 * Control which allows the user to select a text style.
 */
export default function TextStyle({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();

  let activeStyle = STYLES[0];

  for (let level = 1; level <= 6; level++) {
    if (editor?.isActive('heading', { level })) {
      activeStyle = STYLES[level];
      break;
    }
  }

  /** Handles a style being clicked. */
  const styleClicked = (oldStyle: Style, newStyle: Style) => {
    window.requestAnimationFrame(() => {
      if (newStyle.level) {
        editor?.chain().focus().setHeading({ level: newStyle.level }).run();
      } else if (oldStyle.level) {
        editor?.chain().focus().toggleHeading({ level: oldStyle.level }).run();
      } else {
        // Do nothing if the style is already active.
      }
    });
  };

  return (
    <DropdownControl kind={kind} label="Štýl textu" text={activeStyle.label} icon={<activeStyle.Icon />}>
      {STYLES.map((style, index) => (
        <SimpleControl
          key={`TextStyle.${index}`}
          kind="menu-item"
          icon={<style.Icon size={16} />}
          label={style.label}
          onClick={() => styleClicked(activeStyle, style)}
          shortcut={`Ctrl + Alt + ${index}`}
        />
      ))}
    </DropdownControl>
  );
}
