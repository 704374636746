import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthLogout_2PathParameters } from 'api/actions/auth-logout-2/auth-logout-2-path-parameters';
import { AuthLogout_2Response } from 'api/actions/auth-logout-2/auth-logout-2-response';

type AuthLogout_2QueryParameters = Record<string, any>;

/**
 * The path of the AuthLogout_2 action.
 */
export const authLogout_2ActionPath = new SweetPath<keyof AuthLogout_2PathParameters>('/api/auth/logout', {
  path: '{param}',
});

/**
 * Creates a contextualized AuthLogout_2 actions.
 */
export default function createAuthLogout_2Action(axios: AxiosInstance) {
  return function authLogout_2Action({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthLogout_2QueryParameters } = {}) {
    const path = authLogout_2ActionPath.original;

    return axios.get<AuthLogout_2Response, AuthLogout_2Response>(path, { params: query, headers });
  };
}
