import { useForm } from '@mantine/form';
import FormInputGroup from 'components/forms/FormInputGroup';
import { Box, Button, Group, PasswordInput, SimpleGrid, Stack } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { IconPencil } from '@tabler/icons-react';
import { noop } from 'lodash';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import useLoadingAction from 'hooks/use-loading-action';
import { useDisclosure } from '@mantine/hooks';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';
import Toast from 'components/Toast';
import { FormProps } from 'components/forms/FormProps';

/**
 * The data collected from the form.
 */
export interface ChangePasswordFormData {
  password: string;
  newPassword: string;
  newPasswordRepeat: string;
}

/**
 * The form for changing the password.
 */
export default function ChangePasswordForm({ onSubmit = noop, message }: FormProps<ChangePasswordFormData> = {}) {
  const [opened, { open, close }] = useDisclosure(false);
  const [{ loading }, submitWithLoading] = useLoadingAction(onSubmit);

  const form = useForm<ChangePasswordFormData>({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    validate: {
      password: createValidator([required]),
      newPassword: createValidator([required, passwordFormat]),
      newPasswordRepeat: (newPasswordRepeat, { newPassword }) => {
        if (!newPasswordRepeat) {
          return 'Pole je povinné';
        }

        if (newPasswordRepeat !== newPassword) {
          return 'Heslá sa nezhodujú';
        }
      },
    },
  });

  const submit = useMemo(() => form.onSubmit(submitWithLoading), [form, submitWithLoading]);

  // Clear errors when the form is closed.
  useEffect(() => {
    if (!opened) {
      form.clearErrors();
    }
  }, [opened]);

  const success = message?.type === 'success';

  // This is a hack to prevent the form from being submitted twice.
  useEffect(() => {
    if (success) {
      form.reset();
      close();
    }
  }, [success]);

  return (
    <FormInputGroup groupTitle="Heslo">
      <SimpleGrid cols={2} spacing={40}>
        <PasswordInput
          label="Zadajte vaše staré heslo"
          placeholder="Staré heslo"
          size="lg"
          disabled={!opened}
          form="change-password-form"
          name="amperia-password"
          {...form.getInputProps('password')}
          value={!opened && form.values.password ? '*'.repeat(form.values.password.length) : form.values.password}
        />
      </SimpleGrid>
      <SimpleGrid cols={2} spacing={40}>
        <Stack spacing={24}>
          <Box hidden={!opened}>
            <Stack spacing={24}>
              <PasswordInput
                label="Zadajte vaše nové heslo"
                placeholder="Nové heslo"
                size="lg"
                form="change-password-form"
                name="amperia-newPassword"
                {...form.getInputProps('newPassword')}
              />
              <PasswordInput
                label="Zopakujte vaše nové heslo"
                placeholder="Nové heslo znova"
                size="lg"
                form="change-password-form"
                name="amperia-newPasswordRepeat"
                {...form.getInputProps('newPasswordRepeat')}
              />
            </Stack>
          </Box>

          {message && <Toast {...message} opened withCloseButton={false} fullWidth />}

          {!success && (
            <Group spacing={24} position="left">
              <Button
                type="button"
                variant={opened ? 'primary' : 'subtle-compact'}
                leftIcon={<IconPencil stroke="1.5" width={24} height={24} />}
                onClick={() => (opened ? submit() : open())}
                loading={loading}
              >
                Zmeniť heslo
              </Button>
              {opened && (
                <Button onClick={close} variant="subtle-compact" disabled={loading}>
                  Zrušiť
                </Button>
              )}
            </Group>
          )}
        </Stack>
      </SimpleGrid>
    </FormInputGroup>
  );
}
