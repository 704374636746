import { Group, Text } from '@mantine/core';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import { forwardRef } from 'react';

/**
 * The custom properties of the company qualification item.
 */
interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
}

/**
 * Represents a single company qualification item in the select.
 */
const CompanyQualificationItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, ...others }: ItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          <DeviceTypeIcon typeId={Number(image)} />
          <Text size="sm">{label}</Text>
        </Group>
      </div>
    );
  }
);

export default CompanyQualificationItem;
