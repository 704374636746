import { Box } from '@mantine/core';
import { nanoid } from 'nanoid';
import { IFormInput } from 'pages/revisions-module/template-editor/editors/form/types';
import Toast from 'components/Toast';
import RevisionFieldText from 'components/forms/revision/fill-out/fields/RevisionFieldText';
import RevisionFieldNumber from 'components/forms/revision/fill-out/fields/RevisionFieldNumber';
import RevisionFieldDate from 'components/forms/revision/fill-out/fields/RevisionFieldDate';
import RevisionFieldCheckbox from 'components/forms/revision/fill-out/fields/RevisionFieldCheckbox';
import RevisionFieldSelect from 'components/forms/revision/fill-out/fields/RevisionFieldSelect';
import RevisionFieldCertificate from 'components/forms/revision/fill-out/fields/RevisionFieldCertificate';
import RevisionFieldQualification from 'components/forms/revision/fill-out/fields/RevisionFieldQualification';
import RevisionFieldWysiwyg from 'components/forms/revision/fill-out/fields/RevisionFieldWysiwyg';
import RevisionFieldPredefinedSelect from 'components/forms/revision/fill-out/fields/RevisionFieldPredefinedSelect';
import RevisionFieldPredefinedList from 'components/forms/revision/fill-out/fields/RevisionFieldPredefinedList';
import RevisionFieldPeriod from 'components/forms/revision/fill-out/fields/RevisionFieldPeriod';
import RevisionFieldSafetyPoint from 'components/forms/revision/fill-out/fields/RevisionFieldSafetyPoint';
import RevisionFieldMeasuringDevice from 'components/forms/revision/fill-out/fields/RevisionFieldMeasuringDevice';
import RevisionFieldDeviceProperty from 'components/forms/revision/fill-out/fields/RevisionFieldDeviceProperty';
import RevisionFieldSum from 'components/forms/revision/fill-out/fields/RevisionFieldSum';
import RevisionFieldDatePlusPeriod from 'components/forms/revision/fill-out/fields/RevisionFieldDatePlusPeriod';
import { useMemo } from 'react';

type TypeCond<T, U> = [T] extends [never] ? { message: U } : {};

interface RevisionFieldProps {
  field: IFormInput;
  namePrefix?: string;
}

/**
 * Selects the correct field component based on the field type.
 */
function RevisionFieldDiscriminator({ field: { spec }, namePrefix = '' }: RevisionFieldProps) {
  const name = useMemo(() => `${namePrefix}${spec.name}`, [namePrefix, spec.name]);

  switch (spec.type) {
    case 'text':
      return <RevisionFieldText spec={spec} name={name} />;

    case 'number':
      return <RevisionFieldNumber spec={spec} name={name} />;

    case 'date':
      return <RevisionFieldDate spec={spec} name={name} />;

    case 'checkbox':
      return <RevisionFieldCheckbox spec={spec} name={name} />;

    case 'select':
      return <RevisionFieldSelect spec={spec} name={name} />;

    case 'certificate':
      return <RevisionFieldCertificate spec={spec} name={name} />;

    case 'qualification':
      return <RevisionFieldQualification spec={spec} name={name} />;

    case 'wysiwyg':
      return <RevisionFieldWysiwyg spec={spec} name={name} />;

    case 'predefinedSelect':
      return <RevisionFieldPredefinedSelect spec={spec} name={name} />;

    case 'predefinedList':
      return <RevisionFieldPredefinedList spec={spec} name={name} />;

    case 'measuringDevice':
      return <RevisionFieldMeasuringDevice spec={spec} name={name} />;

    case 'period':
      return <RevisionFieldPeriod spec={spec} name={name} />;

    case 'safetyPoint':
      return <RevisionFieldSafetyPoint spec={spec} name={name} />;

    case 'deviceProperty':
      return <RevisionFieldDeviceProperty spec={spec} name={name} />;

    case 'sum':
      return <RevisionFieldSum spec={spec} name={name} />;

    case 'datePlusPeriod':
      return <RevisionFieldDatePlusPeriod spec={spec} name={name} />;

    default:
      // This is a TS trick to make sure all types are handled in the switch.
      // If there is a new type, the compiler will complain here. To fix it, add a new case to the switch.
      const { message }: TypeCond<typeof spec, string> = { message: 'Neznámy typ poľa' };

      return (
        <Box mr={32} key={nanoid()}>
          <Toast fullWidth type="fail" withCloseButton={false} message={message} />
        </Box>
      );
  }
}

/**
 * Field in the row of the revision form.
 */
export default function RevisionField({ field, namePrefix }: RevisionFieldProps) {
  return (
    <Box display={field.spec.hidden ? 'none' : 'block'}>
      <RevisionFieldDiscriminator field={field} namePrefix={namePrefix} />
    </Box>
  );
}
