import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionListPathParameters } from 'api/actions/revision-list/revision-list-path-parameters';
import { RevisionListResponse } from 'api/actions/revision-list/revision-list-response';

type RevisionListQueryParameters = Record<string, any>;

/**
 * The path of the RevisionList action.
 */
export const revisionListActionPath = new SweetPath<keyof RevisionListPathParameters>('/api/revision/list', {
  path: '{param}',
});

/**
 * Creates a contextualized RevisionList actions.
 */
export default function createRevisionListAction(axios: AxiosInstance) {
  return function revisionListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: RevisionListQueryParameters } = {}) {
    const path = revisionListActionPath.original;

    return axios.get<RevisionListResponse, RevisionListResponse>(path, { params: query, headers });
  };
}
