import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import PreDefinedDataForm, {
  PreDefinedDataFormData,
  preDefinedDataFormInitialValues,
} from 'components/forms/pre-defined-data/PreDefinedDataForm';
import { PREDEFINED_DATA_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EDIT_USERS_PREDEFINED_DATA_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  USERS_PREDEFINED_DATA_PAGE_PATH,
} from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add user's pre-defined data.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A11558 Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-Create-User-s-Pre-defined-Data-21006a9b8117409ea11d1d5374fca59b?pvs=4 Notion Page}
 */
export default function AddUsersPredefinedDataPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const [initialValues] = useState(() => ({
    ...preDefinedDataFormInitialValues,
    isGlobal: false,
    isPersonal: true,
  }));

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({ predefinedDataName, predefinedDataDescription, predefinedDataTypeId }: PreDefinedDataFormData) {
    const preDefinedDataCreateAction = getAction('PredefinedDataCreateUsers');

    return preDefinedDataCreateAction({
      payload: {
        predefinedDataName,
        predefinedDataDescription: predefinedDataDescription ?? undefined,
        predefinedDataTypeId: Number(predefinedDataTypeId),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Predvolený údaj bol vytvorený',
          message: `Predvolený údaj ${predefinedDataName} bol úspešne vytvorený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (PREDEFINED_DATA_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_USERS_PREDEFINED_DATA_PAGE_PATH.insert({ predefinedDataId: response.userPredefinedDataId }));
        } else if (PREDEFINED_DATA_REDIRECT_AFTER_SAVE === 'list') {
          navigate(USERS_PREDEFINED_DATA_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového predvoleného údaja"
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        { title: 'Predvolené údaje', link: USERS_PREDEFINED_DATA_PAGE_PATH.original },
        { title: 'Pridanie nového predvoleného údaja' },
      ]}
    >
      <PreDefinedDataForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
