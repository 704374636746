import { Center, MantineColor, Tooltip, TooltipProps } from '@mantine/core';
import QuestionMark from 'components/QuestionMark';

/**
 * Parameters of the QuestionMarkTooltip component.
 */
export interface QuestionMarkTooltipProps extends Omit<TooltipProps, 'children'> {
  size?: number;
  color?: MantineColor;
}

/**
 * Displays a question mark icon with a tooltip.
 */
export default function QuestionMarkTooltip({ size = 16, color = 'blue.7', ...props }: QuestionMarkTooltipProps) {
  return (
    <Tooltip {...props}>
      <Center inline w={size} h={size}>
        <QuestionMark size={size} color={color} />
      </Center>
    </Tooltip>
  );
}
