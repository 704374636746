import FaultTable from 'components/tables/fault/FaultTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';

/**
 * Displays a list of all faults for all devices.
 */
export default function FaultsPage() {
  const [search, setSearch] = useState('');

  return (
    <DashboardLayout
      title="Závady zariadení"
      height="screen"
      withSearch
      onSearch={setSearch}
      breadcrumbs={[{ title: 'Závady zariadení' }]}
    >
      <FaultTable search={search} />
    </DashboardLayout>
  );
}
