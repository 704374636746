import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import PickOrganizationsModal from 'components/modals/pick-organizations-modal/PickOrganizationsModal';

/**
 * Callback for when user selects the list of organizations and user's permissions in it.
 */
type OnPickCallback = (value: {
  pickedOrganizations: {
    [organizationId: number]: { checked: boolean; templateName: string; organizationName: string };
  };
  permissions: number[];
  templateName: string;
}) => void;

/**
 * Parameters of the pickOrganizations function.
 */
interface PickOrganizationsParams {
  title: string;
  hiddenOrganizations: number[];
  permissionsTitle?: string;
  pickOrganizations?: boolean;
  pickPermissions?: boolean;
  autoSelectSingleOrganization?: boolean;
  initialTemplateName: string;
  initialPermissions: number[];
  onPick: OnPickCallback;
}

/**
 * Function for selecting the list of organizations and user's permissions in it.
 */
type PickOrganizations = (params: PickOrganizationsParams) => void;

/**
 * Interface of the PickOrganizationsContext.
 */
interface IPickOrganizationsContext {
  pickOrganizations: PickOrganizations;
}

/**
 * Context for selecting the list of organizations and user's permissions in it.
 */
const PickOrganizationsContext = createContext<IPickOrganizationsContext>(undefined!);

/**
 * Provider for selecting the list of organizations and user's permissions in it.
 */
export function PickOrganizationsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<PickOrganizationsParams | undefined>(undefined);

  /**
   * Opens the modal for selecting the list of organizations and user's permissions in it.
   */
  const pickOrganizations: PickOrganizations = (params) => {
    setParams(params);
    open();
  };

  return (
    <PickOrganizationsContext.Provider value={{ pickOrganizations }}>
      {children}
      {opened && <PickOrganizationsModal opened onClose={close} {...params!} />}
    </PickOrganizationsContext.Provider>
  );
}

/**
 * Hook for selecting the list of organizations and user's permissions in it.
 */
export function usePickOrganizations() {
  return useContext(PickOrganizationsContext);
}
