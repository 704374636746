import { useEffect, useState } from 'react';

type ImmediateActionState<TResponse> =
  | { loading: true; error: null; data: null } // Loading (initial state)
  | { loading: false; error: null; data: TResponse } // Success
  | { loading: false; error: Error; data: null }; // Error

/**
 * Dispatches a REST API action inside a React component.
 */
export default function useImmediateAction<TResponse>(
  action: () => Promise<TResponse>,
  dependencies = []
): ImmediateActionState<TResponse> {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState<null | TResponse>(null);

  useEffect(() => {
    if (!data) {
      action()
        .then(setData)
        .catch(setError)
        .finally(() => setLoading(false));
    }
  }, dependencies);

  if (loading) {
    return { loading: true, error: null, data: null };
  }

  if (error) {
    return { loading: false, error, data: null };
  }

  return { loading: false, error: null, data: data! };
}
