import { Box, Card, Group, Skeleton } from '@mantine/core';
import { useApi } from 'api/api-context';
import NumberBadge from 'components/NumberBadge';
import BarChart from 'components/stats/BarChart';
import H3Medium from 'components/typography/H3Medium';
import panic from 'errors/panic';
import { sum, times } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * Display a chart of number of revisions per month.
 */
export default function RevisionsChartCard() {
  const { getAction } = useApi();
  const [data, setData] = useState<number[]>([]);
  const count = useMemo(() => sum(data), [data]);

  const labels = useMemo(
    () => ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    []
  );

  const tooltips = useMemo(
    () =>
      data.map((value) => {
        if (value === 0) {
          return 'Žiadne revízie';
        }

        if (value === 1) {
          return '1 revízia';
        }

        if (value < 5) {
          return `${value} revízie`;
        }

        return `${value} revízií`;
      }),
    [data]
  );

  useEffect(() => {
    const data = times(12, () => 0);
    const year = new Date().getFullYear();

    const revisionListAction = getAction('RevisionList');

    revisionListAction({
      query: {
        filters: {
          'finishedAt.gt': `${year - 1}-12-31`,
          'finishedAt.lt': `${year + 1}-01-01`,
          'statusId.in': [8, 9, 10],
          'includedInStatistics.eq': 1,
        },
      },
    })
      .then((revisions) => {
        revisions.forEach(({ finishedAt }) => {
          if (finishedAt) {
            const month = new Date(finishedAt).getMonth();
            data[month] += 1;
          }
        });

        setData(data);
      })
      .catch(panic);
  }, []);

  if (data.length === 0) {
    return <Skeleton radius={4} w="100%" />;
  }

  return (
    <Card p={24} mih={300}>
      <Box style={{ display: 'grid', gridTemplateRows: 'auto 1fr', gap: '40px' }} h="100%" w="100%">
        <Group spacing={12}>
          <H3Medium color="gray.8">Ukončené revízie v aktuálnom roku</H3Medium>
          <NumberBadge variant="gray" value={count} />
        </Group>
        <BarChart data={data} labels={labels} tooltips={tooltips} />
      </Box>
    </Card>
  );
}
