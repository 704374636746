import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconFrame } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { usePageBorderSettings } from 'pages/revisions-module/template-editor/editors/visual/border/PageBorderSettingsProvider';

/**
 * Control used to set the page border.
 */
export default function PageBorder({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();
  const { openPageBorderSettings } = usePageBorderSettings();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconFrame />}
      label="Orámovanie"
      onClick={() => openPageBorderSettings({ onClose: () => editor?.chain().focus().run() })}
    />
  );
}
