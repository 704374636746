import { notifications } from '@mantine/notifications';
import { UserUpdateMyCertificatesPayload } from 'api/actions/user-update-my-certificates/user-update-my-certificates-payload';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import MyCertificatesForm, { MyCertificatesFormData } from 'components/forms/my-certificates/MyCertificatesForm';
import { MY_CERTIFICATES_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { DEVICE_TYPES } from 'model/DeviceType';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit the currently logged in user's certificates.
 */
export default function MyCertificatesPage() {
  const { fullName, roleId, getAction } = useApi();
  const navigate = useNavigate();

  const { data, loading, error } = useImmediateAction(() => {
    const userGetMyCertificates = getAction('UserGetMyCertificates');
    return userGetMyCertificates();
  });

  /**
   * Handles the form submission.
   */
  function submit({ certificates }: MyCertificatesFormData) {
    const updateMyCertificatesAction = getAction('UserUpdateMyCertificates');

    const mappedCertificates: UserUpdateMyCertificatesPayload = certificates.map(
      ({ certificate, number, renewalDate, type, stamp }) => ({
        deviceTypeId: DEVICE_TYPES.find((deviceType) => deviceType.slug === type)?.id!,
        certificateNumber: number,
        validFrom: renewalDate,
        stampFileId: stamp,
        certificateFileId: certificate,
      })
    );

    updateMyCertificatesAction({ payload: mappedCertificates })
      .then(() => {
        notifications.show({
          title: 'Osvedčenia boli aktualizované',
          message: 'Vaše osvedčenia boli úspešne aktualizované.',
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (MY_CERTIFICATES_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DASHBOARD_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  // Display the error if there is one.
  useEffect(() => {
    if (error) {
      panic(error);
    }
  }, [error]);

  if (roleId === ROLE_ADMIN_ID) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout title="Moje osvedčenia" breadcrumbs={[{ title: fullName || '' }, { title: 'Moje osvedčenia' }]}>
      {!loading && !error && (
        <MyCertificatesForm
          onSubmit={submit}
          initialValues={{
            certificates: (data || []).map((certificate) => ({
              type: certificate.deviceType!.slug,
              number: certificate.certificateNumber,
              renewalDate: certificate.validFrom,
              stamp: certificate.stampFileId || '',
              certificate: certificate.certificateFileId || '',
            })),
          }}
        />
      )}
    </DashboardLayout>
  );
}
