import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceRevisionGetOptionsPathParameters } from 'api/actions/device-revision-get-options/device-revision-get-options-path-parameters';
import { DeviceRevisionGetOptionsResponse } from 'api/actions/device-revision-get-options/device-revision-get-options-response';

type DeviceRevisionGetOptionsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceRevisionGetOptions action.
 */
export const deviceRevisionGetOptionsActionPath = new SweetPath<keyof DeviceRevisionGetOptionsPathParameters>(
  '/api/device/revision-options/{departmentId}/{deviceTypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceRevisionGetOptions actions.
 */
export default function createDeviceRevisionGetOptionsAction(axios: AxiosInstance) {
  return function deviceRevisionGetOptionsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceRevisionGetOptionsPathParameters;
    query?: DeviceRevisionGetOptionsQueryParameters;
  }) {
    const path = deviceRevisionGetOptionsActionPath.insert(parameters);

    return axios.get<DeviceRevisionGetOptionsResponse, DeviceRevisionGetOptionsResponse>(path, {
      params: query,
      headers,
    });
  };
}
