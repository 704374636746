import { Box } from '@mantine/core';
import { Button, Group, LoadingOverlay, Modal, ScrollArea, Stack } from '@mantine/core';
import Toast from 'components/Toast';
import UploadFile from 'components/files/UploadFile';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

/**
 * The result of an import.
 */
export interface ImportResult {
  key: string;
  label: string | React.ReactNode;
  success: boolean;
}

/**
 * Callback which is called when the file is imported.
 */
export type ImportCallback = (file: File) => Promise<ImportResult[]>;

/**
 * Parameters of the ImportModal component.
 */
export interface ImportModalProps {
  title: string;
  opened: boolean;
  onClose: () => void;
  onImport: ImportCallback;
  onHint?: () => void;
}

/**
 * Modal which allows the user to import the data.
 */
export default function ImportModal({ title, opened, onClose, onImport, onHint }: ImportModalProps) {
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const [result, setResult] = useState<ImportResult[] | null>(null);

  // TODO re-render the table after the user closes the modal - if the import was successful.

  /**
   * Handles the import.
   */
  const handleImport = () => {
    if (!file) {
      panic(new Error('File is not set.'));
      return;
    }

    setLoading(true);

    onImport(file)
      .then(setResult)
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  // Reset the error when the file changes.
  useEffect(() => {
    if (!file) {
      setError('');
    }
  }, [file]);

  // Reset modal on close.
  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setFileId(undefined);
        setFile(undefined);
        setLoading(false);
        setError('');
        setResult(null);
      }, 200);
    }
  }, [opened]);

  return (
    <Modal
      size={512}
      centered
      opened={opened}
      onClose={onClose}
      title={result ? 'Výsledok importu' : title}
      closeOnClickOutside={false}
      closeOnEscape={!loading}
    >
      <LoadingOverlay visible={loading} />
      <ScrollArea py={16}>
        <Stack spacing={8}>
          {result ? (
            <>
              <P2Medium>Na zobrazenie importovaných zariadení musíte znova načítať stránku.</P2Medium>
              {result.map(({ key, label, success }) => (
                <Group key={key} spacing={16}>
                  <P2Regular color={success ? 'green.8' : 'red.8'}>{label}</P2Regular>
                </Group>
              ))}
            </>
          ) : (
            <>
              <Group spacing={4}>
                <P2Regular color="gray.6">Importujte dáta z XML súboru. </P2Regular>
                {onHint && (
                  <Box sx={{ cursor: 'pointer' }} onClick={onHint}>
                    <P2Medium color="blue.8">Zobraziť návod</P2Medium>
                  </Box>
                )}
              </Group>
              <UploadFile
                label=""
                description=""
                value={fileId}
                onChange={setFileId}
                onFileProcessed={setFile}
                hideDownload
                accept="file/xml"
                showSlider={false}
              />
              <Toast
                message={error}
                type="fail"
                fullWidth
                opened={!!error}
                onClose={() => setError('')}
                withCloseButton
              />
            </>
          )}
        </Stack>
      </ScrollArea>
      <Group position="right" spacing={16} pt={16}>
        {result ? (
          <Button variant="primary" onClick={onClose}>
            Zatvoriť
          </Button>
        ) : (
          <>
            <Button variant="link" onClick={onClose} px={8}>
              Zrušiť
            </Button>
            <Button variant="primary" disabled={!file || loading} onClick={handleImport}>
              Importovať
            </Button>
          </>
        )}
      </Group>
    </Modal>
  );
}
