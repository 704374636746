import { merge } from 'lodash';

/**
 * Extracts the JSON attribute from the element.
 */
export default function getJsonAttribute<T>(el: HTMLElement, attr: string, defaultValue: T): T {
  try {
    const raw = el.getAttribute(attr) || '{}';
    const parsed = JSON.parse(raw);

    return merge({ ...defaultValue }, parsed);
  } catch {
    return defaultValue;
  }
}
