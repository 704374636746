import { Button, createStyles, Group, Modal, rem, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { IAutomation, IAutomationType } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { useCallback, useEffect } from 'react';
import RevisionStatusSelect from 'pages/revisions-module/template-editor/editors/pipeline/configurator/RevisionStatusSelect';
import AutomationTypeSelect from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationTypeSelect';
import AutomationSubForm from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationSubForm';
import { nanoid } from 'nanoid';

const useStyles = createStyles((theme) => ({
  wrapper: {
    borderTop: `${rem(1)} solid ${theme.colors.gray[1]}`,
  },
  section: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[1]}`,
  },
}));

/**
 * Parameters of the AutomationConfigurator component.
 */
export interface AutomationConfiguratorProps {
  opened: boolean;
  primaryButtonLabel?: string;
  readOnly?: Partial<Record<keyof IAutomation, boolean>>;
  initialValues?: IAutomation;
  automationTypes?: Partial<Record<IAutomationType, boolean>>;
  onClose: () => void;
  onSubmit: (spec: IAutomation) => void;
}

/**
 * Used to create and edit automations in a pipeline.
 */
export default function AutomationConfigurator({
  opened,
  primaryButtonLabel = 'Vytvoriť automatizáciu',
  readOnly = {},
  initialValues,
  automationTypes,
  onClose,
  onSubmit,
}: AutomationConfiguratorProps) {
  const { classes } = useStyles();

  const form = useForm<IAutomation>({
    initialValues,
    validate: {
      statusSource: (value, { statusTarget }) => {
        if (value === statusTarget) {
          return 'Zdrojový a cieľový stav nemôžu byť rovnaké';
        }

        if (!value) {
          return 'Pole je povinné';
        }

        return null;
      },
      statusTarget: (value, { statusSource }) => {
        if (value === statusSource) {
          return 'Zdrojový a cieľový stav nemôžu byť rovnaké';
        }

        if (!value) {
          return 'Pole je povinné';
        }

        return null;
      },
      type: createValidator([required]),
    },
  });

  const submit = useCallback(
    (spec: IAutomation) => {
      if (spec.id === undefined) {
        spec.id = nanoid();
      }

      onSubmit(spec);
      onClose();
    },
    [onSubmit, onClose]
  );

  useEffect(() => {
    if (opened) {
      form.reset();

      if (initialValues) {
        form.setValues(initialValues);
      }
    }
  }, [opened]);

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Konfigurácia automatizácie"
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={16} pt={16} className={classes.wrapper}>
          <Stack spacing={0}>
            <Stack spacing={12} pb={16} className={classes.section}>
              <RevisionStatusSelect label="Počiatočný stav" size="lg" {...form.getInputProps('statusSource')} />
              <RevisionStatusSelect
                label="Cieľový stav"
                size="lg"
                omitValue={form.values.statusSource}
                {...form.getInputProps('statusTarget')}
              />
            </Stack>
            <Stack spacing={8} py={16} className={classes.section}>
              <AutomationTypeSelect
                readOnly={readOnly.type}
                automationTypes={automationTypes}
                {...form.getInputProps('type')}
              />
              {form.values.type && (
                <Stack spacing={12} bg="blue.0" p={12} sx={{ borderRadius: rem(4), ':empty': { display: 'none' } }}>
                  <AutomationSubForm type={form.values.type} form={form} />
                </Stack>
              )}
            </Stack>
          </Stack>
          <Group position="apart">
            <Button type="button" variant="subtle-gray" size="md" onClick={onClose}>
              Zrušiť
            </Button>
            <Button type="submit" variant="primary" size="md">
              {primaryButtonLabel}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}
