import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignLeft } from '@tabler/icons-react';

/**
 * A button that allows the user to align the selected text to the left.
 */
export default function AlignLeft({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconAlignLeft />}
      label="Zarovnať doľava"
      shortcut="Ctrl + Shift + L"
      active={editor?.isActive({ textAlign: 'left' })}
      onClick={() => editor?.chain().focus().setTextAlign('left').run()}
    />
  );
}
