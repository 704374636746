import { IRowNode } from 'ag-grid-community';
import FaultActions from 'components/tables/fault/FaultActions';
import { FaultRow } from 'components/tables/fault/types';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<FaultRow>; data: FaultRow }) {
  return (
    <FaultActions
      {...data}
      onFaultFixed={({ fixedAt, fixedNote, fixedBy }) =>
        node.setData({
          ...data,
          fixed: {
            fixedAt,
            fixedNote,
            fixedBy: {
              ...fixedBy,
            },
          },
          isFixed: true,
        })
      }
    />
  );
}
