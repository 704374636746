import { Box, Group, Stack, Tooltip } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import { IconMapPinPlus } from '@tabler/icons-react';
import { useMemo } from 'react';

/**
 * A single department.
 */
interface Department {
  departmentId: number;
  departmentName: string;
}

/**
 * Parameters of the DepartmentList component.
 */
export interface DepartmentListProps {
  departments: Department[];
  limit?: number;
}

/**
 * Displays a list of departments.
 */
export default function DepartmentList({ departments, limit = 2 }: DepartmentListProps) {
  const some = useMemo(() => departments.slice(0, limit), [departments, limit]);
  const more = useMemo(() => departments.slice(limit), [departments, limit]);

  const someNames = useMemo(() => some.map(({ departmentName }) => departmentName).join(', '), [some]);

  const hasMore = more.length > 0;

  if (departments.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group spacing={4}>
      <Group spacing={8} align="center">
        <IconMapPinPlus stroke="1.5" height={24} width={24} />
        <P2Regular color="gray.8">{someNames}</P2Regular>
      </Group>
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {more.map(({ departmentId, departmentName }) => (
                <P2Regular key={departmentId} color="gray.8">
                  {departmentName}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Regular color="gray.6">(+{more.length})</P2Regular>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}
