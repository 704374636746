import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataListUsersPathParameters } from 'api/actions/predefined-data-list-users/predefined-data-list-users-path-parameters';
import { PredefinedDataListUsersResponse } from 'api/actions/predefined-data-list-users/predefined-data-list-users-response';

type PredefinedDataListUsersQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataListUsers action.
 */
export const predefinedDataListUsersActionPath = new SweetPath<keyof PredefinedDataListUsersPathParameters>(
  '/api/predefined-data/list/users',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataListUsers actions.
 */
export default function createPredefinedDataListUsersAction(axios: AxiosInstance) {
  return function predefinedDataListUsersAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: PredefinedDataListUsersQueryParameters } = {}) {
    const path = predefinedDataListUsersActionPath.original;

    return axios.get<PredefinedDataListUsersResponse, PredefinedDataListUsersResponse>(path, {
      params: query,
      headers,
    });
  };
}
