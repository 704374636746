import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import DepartmentForm, {
  DepartmentFormData,
  departmentFormInitialValues,
} from 'components/forms/department/DepartmentForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApi } from 'api/api-context';
import { notifications } from '@mantine/notifications';
import { DEPARTMENTS_PAGE_PATH, EDIT_DEPARTMENT_PAGE_PATH } from 'routes/paths';
import panic from 'errors/panic';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import { DEPARTMENT_REDIRECT_AFTER_SAVE } from 'env';

/**
 * Page used to create department.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A8698 Figma Design}
 * - {@link https://www.notion.so/Administrative-Create-Department-7a514e318a0b48a68af9860ca6bd167f?pvs=4 Notion Page}
 */
export default function AddDepartmentPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const [searchParams] = useSearchParams();

  const [initialValues] = useState(() => ({
    ...departmentFormInitialValues,
    organizationId: searchParams.get('organizationId') ?? '',
  }));

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    departmentName,
    departmentNumber,
    streetAddress,
    city,
    zip,
    country,
    organizationId,
    contactPersonId,
  }: DepartmentFormData) {
    const departmentCreateAction = getAction('DepartmentCreate');

    return departmentCreateAction({
      payload: {
        departmentName,
        departmentNumber: departmentNumber ?? undefined,
        streetAddress,
        city,
        zip,
        country,
        organizationId: Number(organizationId),
        contactPersonId: contactPersonId || contactPersonId === '' ? undefined : Number(contactPersonId),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Stredisko bolo vytvorené',
          message: `Stredisko ${departmentName} bolo úspešne vytvorené.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEPARTMENT_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_DEPARTMENT_PAGE_PATH.insert({ departmentId: response.departmentId }));
        } else if (DEPARTMENT_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEPARTMENTS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového strediska"
      breadcrumbs={[
        { title: 'Strediská', link: DEPARTMENTS_PAGE_PATH.original },
        { title: 'Pridanie nového strediska' },
      ]}
    >
      <DepartmentForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
        readOnly={{ people: true }}
      />
    </DashboardLayout>
  );
}
