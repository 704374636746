import { SimpleGrid } from '@mantine/core';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import DevicesCountCard from 'pages/admin-module/dashboard/cards/DevicesCountCard';
import DevicesWithFaultsCard from 'pages/admin-module/dashboard/cards/DevicesWithFaultsCard';
import RevisionsAfterDeadlineCard from 'pages/admin-module/dashboard/cards/RevisionsAfterDeadlineCard';
import ActiveRevisionsCard from 'pages/admin-module/dashboard/cards/ActiveRevisionsCard';
import RevisionsChartCard from 'pages/admin-module/dashboard/cards/RevisionsChartCard';
import RevisionsTableCard from 'pages/admin-module/dashboard/cards/RevisionsTableCard';
import RevisionPlanTableCard from 'pages/admin-module/dashboard/cards/RevisionPlanTableCard';
import { useApi } from 'api/api-context';
import RevisionsWithReservationsCard from 'pages/admin-module/dashboard/cards/RevisionsWithReservationsCard';
import RevisionsNotSuitableCard from 'pages/admin-module/dashboard/cards/RevisionsNotSuitableCard';
import { ReactNode, useMemo } from 'react';

/**
 * Dashboard page.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A8129&mode=dev Figma Design}
 * - {@link https://www.notion.so/Administrative-Dashboard-6ece50e8987b4e40bdf27a81bb644c77?pvs=4 Notion Page}
 */
export default function DashboardPage() {
  const { hasPermissionAnywhere } = useApi();

  const canAssignRevisions = hasPermissionAnywhere('assign-revisions');
  const canFillOutRevisions = hasPermissionAnywhere('fill-out-revisions');
  const canBrowseDevices = hasPermissionAnywhere('browse-devices');
  const canAcceptRevisions = hasPermissionAnywhere('accept-revisions');
  const canDealWithRevisions = hasPermissionAnywhere('*-revisions');

  const cards = useMemo(() => {
    const cards: ReactNode[] = [];

    if (canAssignRevisions || canFillOutRevisions) {
      cards.push(<ActiveRevisionsCard key="ActiveRevisionsCard" />);
    }

    if (canAssignRevisions || canFillOutRevisions) {
      cards.push(<RevisionsAfterDeadlineCard key="RevisionsAfterDeadlineCard" />);
    }

    if (canBrowseDevices) {
      cards.push(<DevicesCountCard key="DevicesCountCard" />);
    }

    if (canAssignRevisions || canAcceptRevisions) {
      cards.push(<RevisionsWithReservationsCard key="RevisionsWithReservationsCard" />);
    }

    if (canAssignRevisions || canAcceptRevisions) {
      cards.push(<RevisionsNotSuitableCard key="RevisionsNotSuitableCard" />);
    }

    if (canBrowseDevices) {
      cards.push(<DevicesWithFaultsCard key="DevicesWithFaultsCard" />);
    }

    return cards;
  }, [canAssignRevisions, canFillOutRevisions, canBrowseDevices, canAcceptRevisions]);

  const cols = useMemo(() => {
    if (cards.length === 6) {
      return 3;
    }

    if (cards.length === 4) {
      return 2;
    }

    return 1;
  }, [cards]);

  return (
    <DashboardLayout breadcrumbs={[{ title: 'Dashboard' }]} title="Dashboard" height="auto">
      <SimpleGrid cols={2} spacing={24}>
        {cards.length > 0 && (
          <SimpleGrid cols={cols} spacing={24}>
            {cards}
          </SimpleGrid>
        )}
        {(canAssignRevisions || canAcceptRevisions) && <RevisionsChartCard />}
        {canDealWithRevisions && <RevisionsTableCard />}
        {canBrowseDevices && <RevisionPlanTableCard />}
      </SimpleGrid>
    </DashboardLayout>
  );
}
