import { Button, createStyles, Group, Stack, Textarea } from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';

interface HtmlEditorModalOptions {
  initialContent?: string;
  onSubmit?: (content: string) => void;
}

const MODAL_ID = 'html-editor';

const useStyles = createStyles((theme) => ({
  input: {
    height: 'calc(100vh - 256px)',
    backgroundColor: theme.colors.gray[0],
    outline: 'none !important',
    boxShadow: 'none !important',
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeight,
  },
}));

/** Content of the modal used to edit HTML content. */
export function HtmlEditorModalBody({ initialContent = '', onSubmit = noop }: HtmlEditorModalOptions) {
  const { classes } = useStyles();
  const [value, setValue] = useState(initialContent);

  const submit = useCallback(() => onSubmit(value), [value, onSubmit]);
  const close = useCallback(() => closeModal(MODAL_ID), []);

  const submitAndClose = useCallback(() => {
    submit();
    close();
  }, [submit, close]);

  return (
    <Stack spacing={16} h="calc(100vh - 200px)">
      <Textarea
        data-autofocus
        classNames={classes}
        value={value}
        onChange={({ currentTarget: { value } }) => setValue(value)}
      />
      <Group spacing={16} position="right">
        <Button size="md" variant="subtle-gray" onClick={close}>
          Zrušiť
        </Button>
        <Button size="md" variant="primary" onClick={submitAndClose}>
          Uložiť
        </Button>
      </Group>
    </Stack>
  );
}

/** Use the HTML editor context. */
export function useHtmlEditor() {
  return useCallback(
    (options: HtmlEditorModalOptions) =>
      openModal({
        modalId: MODAL_ID,
        title: 'HTML kód',
        centered: true,
        size: 'xl',
        closeOnClickOutside: false,
        closeOnEscape: false,
        returnFocus: false,
        children: <HtmlEditorModalBody {...options} />,
      }),
    []
  );
}
