import { Select, SelectProps } from '@mantine/core';
import { useApi } from 'api/api-context';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';
import { useCallback, useEffect, useState } from 'react';
import toBase64 from 'utils/to-base64';
import CertificateItem from 'components/inputs/certificate-select/CertificateItem';

/**
 * The certificate used in select.
 */
export interface CertificateSelectValue {
  certificateNumber: string;
  validFrom?: string;
  stampFileId?: string;
  stampFileUri?: string;
  technicianName?: string;
}

/**
 * Parameters of the CertificateSelect component.
 */
export type CertificateSelectProps = Omit<SelectProps, 'value' | 'onChange' | 'data'> & {
  deviceTypeId: number;
  value?: CertificateSelectValue;
  onChange?: (value: CertificateSelectValue) => void;
  autoSelectSingleResult?: boolean;
  loadingPlaceholder?: string;
};

interface SelectItem {
  label: string;
  value: string;
  image: string;
}

/**
 * Allows the user to select their certificate.
 */
export default function CertificateSelect({
  deviceTypeId,
  autoSelectSingleResult = false,
  value,
  onChange,
  loadingPlaceholder = 'Načítavanie ...',
  placeholder = 'Vyberte osvedčenie',
  ...props
}: CertificateSelectProps) {
  const { getAction } = useApi();
  const { readFile } = useFileManager();
  const [certificates, setCertificates] = useState<CertificateSelectValue[]>([]);
  const [options, setOptions] = useState<SelectItem[]>([]);
  const [loading, setLoading] = useState(true);

  /**
   * Handles the change of the select.
   */
  const onChangeImpl = useCallback(
    (certificateNumber: string) => {
      const certificate = certificates.find(({ certificateNumber: number }) => number === certificateNumber);

      if (certificate) {
        onChange?.(certificate);
      }
    },
    [onChange, certificates]
  );

  /**
   * Used to fetch the certificates.
   */
  const fetchCertificates = useCallback(
    async (deviceTypeId: number) => {
      setLoading(true);
      setCertificates([]);
      setOptions([]);

      const fetchCertificates = getAction('UserGetMyCertificates');

      try {
        const certificates = await fetchCertificates({ query: { filters: { 'deviceTypeId.eq': deviceTypeId } } });
        const stamps = new Map<string, string>();

        for (const { stampFileId } of certificates) {
          if (stampFileId && !stamps.has(stampFileId)) {
            const contents = await readFile({ fileId: stampFileId });
            const parsed = await toBase64(contents);

            if (typeof parsed === 'string') {
              stamps.set(stampFileId, parsed);
            }
          }
        }

        setCertificates(
          certificates.map(({ certificateNumber, validFrom, stampFileId, fullName }) => ({
            certificateNumber,
            validFrom,
            stampFileId,
            stampFileUri: stamps.get(stampFileId ?? ''),
            technicianName: fullName,
          }))
        );

        setOptions(
          certificates.map(({ certificateNumber, deviceTypeId }) => ({
            label: certificateNumber,
            value: certificateNumber,
            image: String(deviceTypeId),
          }))
        );
      } catch (error: any) {
        panic(error);
      } finally {
        setLoading(false);
      }
    },
    [getAction, readFile]
  );

  // Fetch certificates
  useEffect(() => {
    fetchCertificates(deviceTypeId);
  }, [deviceTypeId]);

  // Select single result
  useEffect(() => {
    if (autoSelectSingleResult && certificates.length === 1) {
      onChange?.(certificates[0]);
    }
  }, [autoSelectSingleResult, certificates]);

  const actualPlaceholder =
    loading || (autoSelectSingleResult && certificates.length === 1) ? loadingPlaceholder : placeholder;

  return (
    <Select
      {...props}
      data={options}
      value={value?.certificateNumber}
      onChange={onChangeImpl}
      placeholder={actualPlaceholder}
      itemComponent={CertificateItem}
    />
  );
}
