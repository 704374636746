import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListPathParameters } from 'api/actions/device-list/device-list-path-parameters';
import { DeviceListResponse } from 'api/actions/device-list/device-list-response';

type DeviceListQueryParameters = Record<string, any>;

/**
 * The path of the DeviceList action.
 */
export const deviceListActionPath = new SweetPath<keyof DeviceListPathParameters>('/api/device/list', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceList actions.
 */
export default function createDeviceListAction(axios: AxiosInstance) {
  return function deviceListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DeviceListQueryParameters } = {}) {
    const path = deviceListActionPath.original;

    return axios.get<DeviceListResponse, DeviceListResponse>(path, { params: query, headers });
  };
}
