import { ADD_USER_PAGE_PATH } from 'routes/paths';
import { ColDef, IRowNode } from 'ag-grid-community';
import DataTable from 'components/tables/DataTable';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import RoleSelectFilter from 'components/tables/filters/RoleSelectFilter';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useApi } from 'api/api-context';
import { UserRow } from 'components/tables/user/types';
import FullNameColumn from 'components/tables/user/columns/FullNameColumn';
import OrganizationNameColumn from 'components/tables/user/columns/OrganizationNameColumn';
import RoleNameColumn from 'components/tables/user/columns/RoleNameColumn';
import CertificatesColumn from 'components/tables/user/columns/CertificatesColumn';
import ActionsColumn from 'components/tables/user/columns/ActionsColumn';
import MigratedFromV1NoteColumn from 'components/tables/user/columns/MigratedFromV1NoteColumn';
import { ROLE_ADMIN_ID } from 'model/Role';
import CertificatesSelectFilter from 'components/tables/filters/CertificatesSelectFilter';
import { getDeviceType } from 'model/DeviceType';
import PermissionsColumn from 'components/tables/user/columns/PermissionsColumn';
import { useCounter } from '@mantine/hooks';

/**
 * Parameters of the UserTable component.
 */
export interface UserTableProps {
  search?: string;
  showDiscarded?: boolean;
}

/**
 * Table which displays users.
 */
export default function UserTable({ search = '', showDiscarded = false }: UserTableProps = {}) {
  const { getAction, roleId } = useApi();
  const [searchParams] = useSearchParams();
  const [refreshToken, { increment: forceRefresh }] = useCounter(0);

  /**
   * The columns of the table.
   */
  const columns = useMemo(() => {
    const columns: ColDef[] = [];

    columns.push({
      field: 'checkbox',
      headerName: '',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressMovable: true,
      width: 44,
      minWidth: 44,
      maxWidth: 44,
      cellStyle: ({ data: { status } }: { data: UserRow }) => ({ opacity: status ? 1 : 0.6 }),
    });

    columns.push({ field: 'userId', hide: true, headerName: 'ID používateľa' });

    columns.push({
      field: 'fullName',
      headerName: 'Meno',
      minWidth: 350,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      cellRenderer: FullNameColumn,
    });

    columns.push({
      field: 'preNominals',
      headerName: 'Titul pred menom',
      hide: true,
    });

    columns.push({
      field: 'postNominals',
      headerName: 'Titul za menom',
      hide: true,
    });

    columns.push({
      field: 'email',
      headerName: 'E-mail',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      field: 'phoneNumber',
      headerName: 'Telefónne číslo',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      valueGetter: ({ data: { status } }: { data: UserRow }) => (status ? '1' : '0'),
      field: 'status',
      headerName: 'Aktívny',
      filter: true,
      minWidth: 0,
      maxWidth: 0,
      width: 0,
      cellStyle: { opacity: 0 },
    });

    columns.push({
      field: 'organization.organizationId',
      headerName: 'ID organizácie',
      hide: true,
      filter: true,
    });

    columns.push({
      field: 'organization.organizationName',
      headerName: 'Organizácia',
      minWidth: 250,
      filter: true,
      floatingFilter: true,
      floatingFilterComponent: OrganizationSelectFilter,
      sortable: true,
      resizable: true,
      unSortIcon: true,
      wrapText: true,
      cellRenderer: OrganizationNameColumn,
    });

    if (roleId === ROLE_ADMIN_ID) {
      columns.push({
        field: 'role.roleId',
        headerName: 'ID roly',
        hide: true,
        filter: true,
      });

      columns.push({
        field: 'role.roleName',
        headerName: 'Rola',
        minWidth: 150,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: RoleSelectFilter,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        cellRenderer: RoleNameColumn,
      });
    }

    columns.push({
      field: 'certificateSlugs',
      hide: true,
      filter: true,
    });

    columns.push({
      field: 'certificates',
      headerName: 'Osvedčenia',
      resizable: true,
      filter: true,
      floatingFilter: true,
      floatingFilterComponent: CertificatesSelectFilter,
      minWidth: 240,
      cellRenderer: CertificatesColumn,
    });

    columns.push({
      field: 'permissions',
      headerName: 'Oprávenia',
      minWidth: 400,
      resizable: true,
      wrapText: true,
      cellRenderer: PermissionsColumn,
    });

    columns.push({
      field: 'migratedFromV1Note',
      headerName: 'Poznámka k migrácii',
      minWidth: 350,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellRenderer: MigratedFromV1NoteColumn,
    });

    columns.push({
      field: 'actions',
      headerName: '',
      pinned: 'right',
      width: 194,
      minWidth: 194,
      maxWidth: 194,
      cellRenderer: ({ node, data }: { node: IRowNode<UserRow>; data: UserRow }) =>
        ActionsColumn({ node, data, refresh: forceRefresh }),
    });

    return columns;
  }, [roleId]);

  const [initialFilters] = useState(() => ({
    'organization.organizationId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('organizationId') ?? '',
      permissionSlug: 'manage-users',
    },
  }));

  const action = useCallback(async () => {
    const action = getAction('UserList');

    const users = await action();

    return users.map((user) => ({
      ...user,
      certificateSlugs: user.certificates.map(
        ({ deviceType, deviceTypeId }) => deviceType?.slug || getDeviceType(deviceTypeId)?.slug
      ),
    }));
  }, [getAction]);

  const columnKeys = useMemo(() => {
    const columns: string[] = [];

    columns.push('userId');
    columns.push('fullName');
    columns.push('preNominals');
    columns.push('postNominals');
    columns.push('email');
    columns.push('phoneNumber');
    columns.push('status');
    columns.push('migratedFromV1Note');
    columns.push('organization.organizationId');
    columns.push('organization.organizationName');

    if (roleId === ROLE_ADMIN_ID) {
      columns.push('role.roleId');
      columns.push('role.roleName');
    }

    return columns;
  }, [roleId]);

  const additionalFilters = useMemo(() => {
    if (!showDiscarded) {
      return {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      };
    }

    return { status: null };
  }, [showDiscarded]);

  return (
    <DataTable
      columns={columns}
      action={action}
      refreshToken={refreshToken}
      addButtonText="Pridať nového"
      addButtonTarget={ADD_USER_PAGE_PATH.original}
      search={search}
      initialFilters={initialFilters}
      additionalFilters={additionalFilters}
      dataExport={{
        modalTitle: 'Exportovať používateľov',
        fileName: 'pouzivatelia.xlsx',
        columnKeys,
      }}
    />
  );
}
