import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { Button, ColorInput, Group, Modal, NumberInput, Stack } from '@mantine/core';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import P4Medium from 'components/typography/P4Medium';
import { FontFamilySelect } from 'pages/revisions-module/template-editor/editors/visual/typography/FontFamilySelect';
import { times } from 'lodash';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { getHeadingSizes } from 'pages/revisions-module/template-editor/editors/visual/typography/heading-sizes';

interface OpenPageTypographySettingsParams {
  onClose?: () => void;
}

interface IPageTypographySettingsContext {
  openPageTypographySettings: (params: OpenPageTypographySettingsParams) => void;
}

const PageTypographySettingsContext = createContext<IPageTypographySettingsContext>(undefined!);

/**
 * Content of the modal used to set the page typography.
 */
function PageTypographyModal({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { visual, setVisual } = useTemplateEditorData();

  const initialValues = useMemo(
    () => ({
      fontFamily: visual.fontFamily,
      fontSizeP: visual.fontSizeP,
      lineHeightP: visual.lineHeightP,
      lineHeightH: visual.lineHeightH,
      textColor: visual.textColor,
    }),
    [visual]
  );

  const colorSwatches = useMemo(() => times(10, (i) => `rgb(${i * 25}, ${i * 25}, ${i * 25})`), []);

  const fontSizeProps = useMemo(
    () => ({ size: 'md', min: 1, max: 100, rightSection: <P4Medium c="gray.6">px</P4Medium> }),
    []
  );
  const lineHeightProps = useMemo(
    () => ({
      size: 'md',
      min: 1,
      max: 2,
      step: 0.01,
      precision: 2,
    }),
    []
  );

  const form = useForm({ initialValues });

  useEffect(() => {
    form.resetDirty();
    form.setValues(initialValues);
  }, [opened]);

  useEffect(() => {}, [form.values.fontSizeP]);

  const onSubmit = form.onSubmit((values) => {
    setVisual((visual) => ({ ...visual, ...getHeadingSizes(values.fontSizeP), ...values }));
    onClose();
  });

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Typografia"
      size={415}
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={16} pt={16}>
          <FontFamilySelect {...form.getInputProps('fontFamily')} />
          <NumberInput
            label={
              <Group spacing={8}>
                Veľkosť písma
                <QuestionMarkTooltip withinPortal openDelay={300} label="Veľkosť nadpisov bude vyrátaná automaticky" />
              </Group>
            }
            {...fontSizeProps}
            {...form.getInputProps('fontSizeP')}
          />
          <Group spacing={12} noWrap>
            <NumberInput label="Riadkovanie (text)" {...lineHeightProps} {...form.getInputProps('lineHeightP')} />
            <NumberInput label="Riadkovanie (nadpisy)" {...lineHeightProps} {...form.getInputProps('lineHeightH')} />
          </Group>
          <ColorInput size="md" label="Farba textu" swatches={colorSwatches} {...form.getInputProps('textColor')} />

          <Group pt={8} spacing={16} position="right">
            <Button type="button" onClick={onClose} variant="subtle-gray">
              Zrušiť
            </Button>
            <Button type="submit" variant="primary">
              Nastaviť
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

/**
 * Provides a context for managing the page typography form state.
 */
export function PageTypographySettingsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure();
  const [params, setParams] = useState<OpenPageTypographySettingsParams>({});

  const openPageTypographySettings = useCallback(
    (params: OpenPageTypographySettingsParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const onClose = useCallback(() => {
    params.onClose?.();
    close();
  }, [params, close]);

  const value = useMemo(() => ({ openPageTypographySettings }), [openPageTypographySettings]);

  return (
    <PageTypographySettingsContext.Provider value={value}>
      {children}
      <PageTypographyModal opened={opened} onClose={onClose} />
    </PageTypographySettingsContext.Provider>
  );
}

/**
 * Hook for opening the page typography form.
 */
export function usePageTypographySettings() {
  return useContext(PageTypographySettingsContext);
}
