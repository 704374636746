import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

interface IFillOutRevisionSaveDraftProviderContext {
  saveDraft: (options?: { notify?: boolean }) => Promise<void>;
  loading: boolean;
}

const FillOutRevisionSaveDraftProviderContext = createContext<IFillOutRevisionSaveDraftProviderContext>(undefined!);

/**
 * Provides the revision and form spec to the children.
 */
export function FillOutRevisionSaveDraftProvider({ children }: { children: ReactNode }) {
  const { revision, form } = useFillOutRevisionDataProvider();
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);

  /** Saves the revision data as a draft (without validation). */
  const saveDraft = async ({ notify = true }: { notify?: boolean } = {}) => {
    if (loading) {
      return; // Prevent multiple saves
    }

    setLoading(true);

    try {
      const saveDraft = getAction('RevisionSaveDraft');

      await saveDraft({
        parameters: { revisionId: String(revision.revisionId) },
        payload: { revisionData: JSON.stringify(form.values) },
      });

      if (notify) {
        showNotification({
          title: 'Revízna správa bola uložená',
          message: `Revízna správa ${revision?.revisionName} bola úspešne uložená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });
      }
    } catch (error: any) {
      panic(error);
    } finally {
      setLoading(false);
    }
  };

  const value = useMemo(() => ({ saveDraft, loading }), [saveDraft, loading]);

  return (
    <FillOutRevisionSaveDraftProviderContext.Provider value={value}>
      {children}
    </FillOutRevisionSaveDraftProviderContext.Provider>
  );
}

/**
 * Hook to get the revision and form spec.
 */
export function useFillOutRevisionSaveDraftProvider() {
  return useContext(FillOutRevisionSaveDraftProviderContext);
}
