import { Box, Flex } from '@mantine/core';
import { DepartmentRow } from 'components/tables/department/types';
import { IconMapPinPlus } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the address.
 */
export default function AddressColumn({ data: { streetAddress, city } }: { data: DepartmentRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <IconMapPinPlus stroke="1.5" />
      </Box>
      <P2Regular color="gray.8">
        {streetAddress}, {city}
      </P2Regular>
    </Flex>
  );
}
