import { noop } from 'lodash';
import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { Button, Menu } from '@mantine/core';
import { IconSignature } from '@tabler/icons-react';
import useSignAction from 'components/tables/revision/actions/hooks/use-sign-action';
import { useSignRevisionModal } from 'components/modals/sign-revision-modal/SignRevisionModalProvider';

/**
 * Action performed by the user who is the technician of the revision.
 */
export default function SignAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { signRevision } = useSignAction();
  const { signRevisionModal } = useSignRevisionModal();

  /**
   * Confirms the redirect to the third party to sign the revision.
   */
  const confirmSignRevision = () =>
    signRevisionModal({
      revisionId: revision.revisionId,
      message:
        'Revízna správa bude odoslaná do systému BrainIT. Pre jej podpísanie budete presmerovaný do ich webového portálu.',
      onSign: ({ deadline }) => signRevision({ revision, deadline, onStatusChange }),
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={confirmSignRevision}
        leftIcon={<IconSignature stroke={1.5} size={24} />}
        variant="secondary"
        w={180}
      >
        Podpísať
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={confirmSignRevision} icon={<IconSignature stroke={1.5} size={24} />}>
      Podpísať
    </Menu.Item>
  );
}
