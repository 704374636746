import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconList } from '@tabler/icons-react';

/**
 * Used to insert bullet list.
 */
export default function ControlBulletList() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Bullet list" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        active={editor?.isActive('bulletList')}
      >
        <IconList size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
