export const H1_SCALE = 2.5;
export const H2_SCALE = 2;
export const H3_SCALE = 1.75;
export const H4_SCALE = 1.5;
export const H5_SCALE = 1.25;
export const H6_SCALE = 1.1;

/**
 * Get heading sizes based on paragraph font size.
 */
export function getHeadingSizes(fontSizeP: number) {
  return {
    fontSizeH1: fontSizeP * H1_SCALE,
    fontSizeH2: fontSizeP * H2_SCALE,
    fontSizeH3: fontSizeP * H3_SCALE,
    fontSizeH4: fontSizeP * H4_SCALE,
    fontSizeH5: fontSizeP * H5_SCALE,
    fontSizeH6: fontSizeP * H6_SCALE,
  };
}
