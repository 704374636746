import P2Regular from 'components/typography/P2Regular';
import { PredefinedDataRow } from 'components/tables/predefined/types';
import Excerpt from 'components/Excerpt';

/**
 * Column for the value.
 */
export default function ValueColumn({ data: { value } }: { data: PredefinedDataRow }) {
  return (
    <P2Regular color="gray.8">
      <Excerpt text={value ?? '-'} length={80} />
    </P2Regular>
  );
}
