import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthAddUserDepartmentPairsPayload } from 'api/actions/auth-add-user-department-pairs/auth-add-user-department-pairs-payload';
import { AuthAddUserDepartmentPairsPathParameters } from 'api/actions/auth-add-user-department-pairs/auth-add-user-department-pairs-path-parameters';
import { AuthAddUserDepartmentPairsResponse } from 'api/actions/auth-add-user-department-pairs/auth-add-user-department-pairs-response';

type AuthAddUserDepartmentPairsQueryParameters = Record<string, any>;

/**
 * The path of the AuthAddUserDepartmentPairs action.
 */
export const authAddUserDepartmentPairsActionPath = new SweetPath<keyof AuthAddUserDepartmentPairsPathParameters>(
  '/api/auth/user-department/add',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthAddUserDepartmentPairs actions.
 */
export default function createAuthAddUserDepartmentPairsAction(axios: AxiosInstance) {
  return function authAddUserDepartmentPairsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthAddUserDepartmentPairsQueryParameters;
    payload: AuthAddUserDepartmentPairsPayload;
  }) {
    const path = authAddUserDepartmentPairsActionPath.original;

    return axios.put<
      AuthAddUserDepartmentPairsResponse,
      AuthAddUserDepartmentPairsResponse,
      AuthAddUserDepartmentPairsPayload
    >(path, payload);
  };
}
