import { Box } from '@mantine/core';
import { Button, Group, LoadingOverlay, Modal, ScrollArea, Stack } from '@mantine/core';
import Toast from 'components/Toast';
import UploadFile from 'components/files/UploadFile';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

/**
 * Parameters of the ImportMeasurementsModal component.
 */
export interface ImportMeasurementsModalProps<TMeasurement> {
  title: string;
  opened: boolean;
  onClose: () => void;
  onImport: ImportMeasurementsCallback<TMeasurement>;
  onHint?: () => void;
}

export type ImportMeasurementsCallback<TMeasurement> = (file: File) => Promise<TMeasurement[]>;

/**
 * Modal which allows the user to import the measurements data.
 */
export default function ImportMeasurementsModal<TMeasurement>({
  title,
  opened,
  onClose,
  onImport,
  onHint,
}: ImportMeasurementsModalProps<TMeasurement>) {
  const [fileId, setFileId] = useState<string | undefined>(undefined);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  /**
   * Handles the import.
   */
  const handleImport = () => {
    if (!file) {
      panic(new Error('File is not set.'));
      return;
    }

    setLoading(true);

    onImport(file)
      .then(() => onClose())
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Reset the error when the file changes.
  useEffect(() => {
    if (!file) {
      setError('');
    }
  }, [file]);

  // Reset modal on close.
  useEffect(() => {
    if (!opened) {
      setTimeout(() => {
        setFileId(undefined);
        setFile(undefined);
        setLoading(false);
        setError('');
      }, 200);
    }
  }, [opened]);

  return (
    <Modal
      size={512}
      centered
      opened={opened}
      onClose={onClose}
      title={title}
      closeOnClickOutside={false}
      closeOnEscape={!loading}
    >
      <LoadingOverlay visible={loading} />
      <ScrollArea py={16}>
        <Stack spacing={8}>
          <Group spacing={4}>
            <P2Regular color="gray.6">Importujte dáta z XLSX súboru. </P2Regular>
            {onHint && (
              <Box sx={{ cursor: 'pointer' }} onClick={onHint}>
                <P2Medium color="blue.8">Zobraziť návod</P2Medium>
              </Box>
            )}
          </Group>
          <UploadFile
            label=""
            description=""
            value={fileId}
            onChange={setFileId}
            onFileProcessed={setFile}
            hideDownload
            showSlider={false}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          <Toast message={error} type="fail" fullWidth opened={!!error} onClose={() => setError('')} withCloseButton />
        </Stack>
      </ScrollArea>
      <Group position="right" spacing={16} pt={16}>
        <Button variant="link" onClick={onClose} px={8}>
          Zrušiť
        </Button>
        <Button variant="primary" disabled={!file || loading} onClick={handleImport}>
          Importovať
        </Button>
      </Group>
    </Modal>
  );
}
