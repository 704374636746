import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListAllFaultsPathParameters } from 'api/actions/device-list-all-faults/device-list-all-faults-path-parameters';
import { DeviceListAllFaultsResponse } from 'api/actions/device-list-all-faults/device-list-all-faults-response';

type DeviceListAllFaultsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListAllFaults action.
 */
export const deviceListAllFaultsActionPath = new SweetPath<keyof DeviceListAllFaultsPathParameters>(
  '/api/device/faults',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListAllFaults actions.
 */
export default function createDeviceListAllFaultsAction(axios: AxiosInstance) {
  return function deviceListAllFaultsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DeviceListAllFaultsQueryParameters } = {}) {
    const path = deviceListAllFaultsActionPath.original;

    return axios.get<DeviceListAllFaultsResponse, DeviceListAllFaultsResponse>(path, { params: query, headers });
  };
}
