import { IRowNode } from 'ag-grid-community';
import { UserRow } from 'components/tables/user/types';
import UserActions from 'components/tables/user/UserActions';
import { noop } from 'lodash';

/**
 * Column for the actions.
 */
export default function ActionsColumn({
  node,
  data,
  refresh = noop,
}: {
  node: IRowNode<UserRow>;
  data: UserRow;
  refresh: () => void;
}) {
  return <UserActions {...data} onStatusChange={(status) => node.setData({ ...data, status })} refresh={refresh} />;
}
