import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthAddUserOrganizationPairsPayload } from 'api/actions/auth-add-user-organization-pairs/auth-add-user-organization-pairs-payload';
import { AuthAddUserOrganizationPairsPathParameters } from 'api/actions/auth-add-user-organization-pairs/auth-add-user-organization-pairs-path-parameters';
import { AuthAddUserOrganizationPairsResponse } from 'api/actions/auth-add-user-organization-pairs/auth-add-user-organization-pairs-response';

type AuthAddUserOrganizationPairsQueryParameters = Record<string, any>;

/**
 * The path of the AuthAddUserOrganizationPairs action.
 */
export const authAddUserOrganizationPairsActionPath = new SweetPath<keyof AuthAddUserOrganizationPairsPathParameters>(
  '/api/auth/user-organization/add',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthAddUserOrganizationPairs actions.
 */
export default function createAuthAddUserOrganizationPairsAction(axios: AxiosInstance) {
  return function authAddUserOrganizationPairsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthAddUserOrganizationPairsQueryParameters;
    payload: AuthAddUserOrganizationPairsPayload;
  }) {
    const path = authAddUserOrganizationPairsActionPath.original;

    return axios.put<
      AuthAddUserOrganizationPairsResponse,
      AuthAddUserOrganizationPairsResponse,
      AuthAddUserOrganizationPairsPayload
    >(path, payload);
  };
}
