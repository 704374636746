/**
 * Represents a device type.
 */
export interface DeviceType {
  id: number;
  slug: string;
  name: string;
  shortName: string;
}

export const UNKNOWN_DEVICE_TYPE = {
  id: -1,
  slug: 'unknown',
  name: 'Neznámy typ zariadenia',
  shortName: 'Neznámy',
} as const satisfies DeviceType;

export const ELECTRIC_DEVICE_TYPE = {
  id: 1,
  slug: 'electric',
  name: 'Elektrické zariadenia',
  shortName: 'Elektrické',
} as const satisfies DeviceType;

export const WEAK_CURRENT_DEVICE_TYPE = {
  id: 2,
  slug: 'weak-current',
  name: 'Slaboprúd',
  shortName: 'Slaboprúd',
} as const satisfies DeviceType;

export const GAS_DEVICE_TYPE = {
  id: 3,
  slug: 'gas',
  name: 'Plynové zariadenia',
  shortName: 'Plynové',
} as const satisfies DeviceType;

export const HEATING_DEVICE_TYPE = {
  id: 4,
  slug: 'heating',
  name: 'Vykurovanie',
  shortName: 'Vykurovanie',
} as const satisfies DeviceType;

export const LIFTING_DEVICE_TYPE = {
  id: 5,
  slug: 'lifting',
  name: 'Zdvíhacie zariadenia',
  shortName: 'Zdvíhacie',
} as const satisfies DeviceType;

export const FIRE_SAFETY_DEVICE_TYPE = {
  id: 6,
  slug: 'fire-safety',
  name: 'Ochrana pred požiarmi',
  shortName: 'Ochrana pred požiarmi',
} as const satisfies DeviceType;

export const VENTILATION_DEVICE_TYPE = {
  id: 7,
  slug: 'ventilation',
  name: 'Vzduchotechnika',
  shortName: 'Vzduchotechnika',
} as const satisfies DeviceType;

export const OTHER_DEVICE_TYPE = {
  id: 8,
  slug: 'other',
  name: 'Ostatné',
  shortName: 'Ostatné',
} as const satisfies DeviceType;

export const PRESSURE_DEVICE_TYPE = {
  id: 9,
  slug: 'pressure',
  name: 'Tlakové zariadenia',
  shortName: 'Tlakové',
} as const satisfies DeviceType;

export const COOLING_DEVICE_TYPE = {
  id: 10,
  slug: 'cooling',
  name: 'Chladenie',
  shortName: 'Chladenie',
} as const satisfies DeviceType;

export const DEVICE_TYPES = [
  ELECTRIC_DEVICE_TYPE,
  WEAK_CURRENT_DEVICE_TYPE,
  GAS_DEVICE_TYPE,
  HEATING_DEVICE_TYPE,
  LIFTING_DEVICE_TYPE,
  FIRE_SAFETY_DEVICE_TYPE,
  VENTILATION_DEVICE_TYPE,
  OTHER_DEVICE_TYPE,
  PRESSURE_DEVICE_TYPE,
  COOLING_DEVICE_TYPE,
] as const;

export const DEVICE_TYPE_MAP: Map<number | string, DeviceType> = new Map([
  ...(DEVICE_TYPES.map((type) => [type.id, type]) as any),
  ...(DEVICE_TYPES.map((type) => [type.slug, type]) as any),
]);

/**
 * All known device type ids.
 */
export type DeviceTypeId = (typeof DEVICE_TYPES)[number]['id'];

/**
 * All known device type slugs.
 */
export type DeviceTypeSlug = (typeof DEVICE_TYPES)[number]['slug'];

/**
 * Returns a device type by its id or slug.
 */
export function getDeviceType(idOrSlug: number | string): DeviceType {
  return DEVICE_TYPE_MAP.get(idOrSlug) ?? UNKNOWN_DEVICE_TYPE;
}
