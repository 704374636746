import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import DeviceForm, { DeviceFormData, deviceFormInitialValues } from 'components/forms/device/DeviceForm';
import { DEVICE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DEVICES_PAGE_PATH, EDIT_DEVICE_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to create a device.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=252%3A10726 Figma Design}
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A12046 Figma Design}
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=279%3A18456 Figma Design}
 * - {@link https://www.notion.so/Device-Create-Device-7cf7f651b19e4e92b7d6d2618fd8c879?pvs=4 Notion Page}
 */
export default function AddDevicePage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();

  const [initialValues] = useState(() => ({
    ...deviceFormInitialValues,
    departmentId: searchParams.get('departmentId') ?? '',
    deviceTypeId: searchParams.get('deviceTypeId') ?? '',
  }));

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    deviceName,
    manufacturer,
    serialNumber,
    manufactured,
    internalPossessionsNumber,
    internalNote,
    building,
    floor,
    room,
    longitude,
    latitude,
    deviceTypeId,
    deviceSubtypeId,
    departmentId,
    responsiblePersonId,
    responsiblePersonNote,
    nextRevision,
    files,
    evidenceFields,
  }: DeviceFormData) {
    const deviceCreateAction = getAction('DeviceCreate');

    const mappedFiles = Object.entries(files).map(([key, value]) => {
      return value.map((val) => ({
        fileName: val.fileName,
        fileId: val.fileId,
        fileType: key,
        isMainImage: val.isMainImage,
      }));
    });

    const mappedRevisions = nextRevision
      .filter(({ description, revisionPeriod }) => Number(revisionPeriod) !== 0 && description !== '')
      .filter(({ deleted }) => !deleted)
      .map((revision) => {
        let date: string | undefined = '';

        if (revision.neverExecuted) {
          date = revision.nextDate !== '' ? revision.nextDate! : undefined;
        } else {
          date = revision.date !== '' ? revision.nextDate! : undefined;
        }

        return {
          description: revision.description,
          date,
          revisionPeriod: Number(revision.revisionPeriod),
          neverExecuted: revision.neverExecuted,
        };
      });

    return deviceCreateAction({
      payload: {
        deviceName,
        manufacturer: manufacturer === '' ? undefined : manufacturer,
        serialNumber: serialNumber === '' ? undefined : serialNumber,
        manufactured: String(manufactured) === '' ? undefined : manufactured,
        internalPossessionsNumber,
        internalNote,
        building,
        floor,
        room,
        longitude,
        latitude,
        deviceTypeId: Number(deviceTypeId),
        deviceSubtypeId: Number(deviceSubtypeId),
        departmentId: Number(departmentId),
        responsiblePersonId: responsiblePersonId ? Number(responsiblePersonId) : undefined,
        responsiblePersonNote,
        nextRevision: mappedRevisions,
        files: mappedFiles.flat(),
        evidenceFields: Object.values(evidenceFields),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Zariadenie bolo vytvorené',
          message: `Zariadenie ${deviceName} bolo úspešne vytvorené.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEVICE_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_DEVICE_PAGE_PATH.insert({ deviceId: response.deviceId }), { replace: true });
        } else if (DEVICE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEVICES_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového zariadenia"
      breadcrumbs={[{ title: 'Zariadenia', link: DEVICES_PAGE_PATH.original }, { title: 'Pridanie nového zariadenia' }]}
    >
      <DeviceForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        readOnly={{ faults: true }}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
