import { Fragment, useMemo } from 'react';
import { Menu, ScrollArea } from '@mantine/core';
import P4Medium from 'components/typography/P4Medium';
import {
  DEVICE_PROPERTY_PLACEHOLDERS,
  DOCUMENT_PROPERTY_PLACEHOLDERS,
  getPlaceholderTypeLabel,
  IPlaceholder,
  IPlaceholderContext,
  REVISION_PROPERTY_PLACEHOLDERS,
  TECHNICIAN_PROPERTY_PLACEHOLDERS,
} from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/Placeholder';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { noop } from 'lodash';

interface PlaceholderMenuProps {
  onClick?: (placeholder: IPlaceholder) => void;
  excludeSpecialTechnicianProperties?: boolean;
  excludeSpecialRevisionProperties?: boolean;
  excludeSpecialDocumentProperties?: boolean;
}

/**
 * Menu for displaying placeholders.
 */
export default function PlaceholderMenu({
  onClick = noop,
  excludeSpecialTechnicianProperties = false,
  excludeSpecialRevisionProperties = false,
  excludeSpecialDocumentProperties = false,
}: PlaceholderMenuProps) {
  const { allInputs } = useFormInputs();

  const revisionPlaceholders = useMemo(
    () =>
      allInputs
        .filter(({ context }) => context === 'revision')
        .map(({ spec }) => ({ context: 'revision' as IPlaceholderContext, ...spec })),
    [allInputs]
  );

  // Filter out deviceProperty input types because they are already included in DEVICE_PROPERTY_PLACEHOLDERS.
  const devicePlaceholders = useMemo(
    () =>
      allInputs
        .filter(({ context, spec }) => context === 'device' && spec.type !== 'deviceProperty')
        .map(({ spec }) => ({ context: 'device' as IPlaceholderContext, ...spec })),
    [allInputs]
  );

  const groups = useMemo(() => {
    const groups = [
      { name: 'Polia', placeholders: revisionPlaceholders },
      { name: 'Zariadenie', placeholders: [...DEVICE_PROPERTY_PLACEHOLDERS, ...devicePlaceholders] },
    ];

    if (!excludeSpecialTechnicianProperties) {
      groups.push({ name: 'Vlastnosť revízneho technika', placeholders: TECHNICIAN_PROPERTY_PLACEHOLDERS });
    }

    if (!excludeSpecialRevisionProperties) {
      groups.push({ name: 'Vlastnosť revíznej správy', placeholders: REVISION_PROPERTY_PLACEHOLDERS });
    }

    if (!excludeSpecialDocumentProperties) {
      groups.push({ name: 'Vlastnosť dokumentu', placeholders: DOCUMENT_PROPERTY_PLACEHOLDERS });
    }

    return groups;
  }, [
    revisionPlaceholders,
    devicePlaceholders,
    excludeSpecialTechnicianProperties,
    excludeSpecialRevisionProperties,
    excludeSpecialDocumentProperties,
  ]);

  return (
    <ScrollArea.Autosize mah={400} maw={475}>
      {groups.map(({ name, placeholders }, i) => (
        <Fragment key={name}>
          {i > 0 && <Menu.Divider my={8} />}
          {placeholders.map((placeholder) => (
            <Menu.Item
              key={placeholder.name}
              onClick={() => onClick(placeholder)}
              rightSection={<P4Medium c="gray.5">{getPlaceholderTypeLabel(placeholder.type)}</P4Medium>}
            >
              {placeholder.label}
            </Menu.Item>
          ))}
        </Fragment>
      ))}
    </ScrollArea.Autosize>
  );
}
