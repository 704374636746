import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthListPermissionsPathParameters } from 'api/actions/auth-list-permissions/auth-list-permissions-path-parameters';
import { AuthListPermissionsResponse } from 'api/actions/auth-list-permissions/auth-list-permissions-response';

type AuthListPermissionsQueryParameters = Record<string, any>;

/**
 * The path of the AuthListPermissions action.
 */
export const authListPermissionsActionPath = new SweetPath<keyof AuthListPermissionsPathParameters>(
  '/api/auth/permission/list',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthListPermissions actions.
 */
export default function createAuthListPermissionsAction(axios: AxiosInstance) {
  return function authListPermissionsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthListPermissionsQueryParameters } = {}) {
    const path = authListPermissionsActionPath.original;

    return axios.get<AuthListPermissionsResponse, AuthListPermissionsResponse>(path, { params: query, headers });
  };
}
