import { Group, Modal, TextInput, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash';
import AgGridDeviceMeasurementTable from 'components/forms/revision/fill-out/modals/device-measurement-modal/ag-grid/AgGridDeviceMeasurementTable';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';
import { useState } from 'react';
import { IconSearch } from '@tabler/icons-react';

export interface DeviceMeasurementModalProps {
  deviceTypeId: number;
  measurementType?: string;
  opened: boolean;
  onClose: () => void;
  spec?: Readonly<IFormInputSpec[]>;
  hideFaults?: boolean;
  onSubmit?: (values: any) => void;
  initialValues?: any;
}

/**
 * Modal which displays the device fault form.
 */
export default function DeviceMeasurementModal({
  deviceTypeId,
  measurementType,
  opened,
  onClose,
  spec,
  hideFaults = false,
  onSubmit = noop,
  initialValues,
}: DeviceMeasurementModalProps) {
  const theme = useMantineTheme();
  const [search, setSearch] = useState('');

  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="100%"
      zIndex={100}
    >
      <Modal.Overlay />
      <Modal.Content h="100%">
        <Modal.Header w="100%" px={24} py={16}>
          <Group w="100%" position="apart">
            <Modal.Title>{measurementType ?? 'Meranie'}</Modal.Title>
            <TextInput
              miw={360}
              value={search}
              size="md"
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder="Hľadať meranie"
              rightSection={<IconSearch stroke={1.5} color={theme.colors.gray[6]} />}
            />
          </Group>
        </Modal.Header>
        <Modal.Body p={0} style={{ height: 'calc(100% - 72px)' }}>
          <AgGridDeviceMeasurementTable
            deviceTypeId={deviceTypeId}
            onSubmit={(values) => {
              onClose();
              onSubmit(values);
            }}
            initialValues={initialValues}
            onClose={onClose}
            spec={spec as IFormInputSpec[]}
            hideFaults={hideFaults}
            search={search}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
