import { Checkbox, Group } from '@mantine/core';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { noop } from 'lodash';

/**
 * Parameters of the Checkbox component.
 */
interface CheckboxProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
}

/**
 * Checkbox for specifying whether the field is required.
 */
export function RequiredFieldCheckbox({ value = false, onChange = noop }: CheckboxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => onChange(e.currentTarget.checked)}
      size="md"
      label={
        <Group spacing={8}>
          Povinné pole
          <QuestionMarkTooltip
            color="blue.5"
            size={14}
            label="Formulár nie je možné odoslať bez vyplnenia tohto poľa."
            multiline
            width={220}
          />
        </Group>
      }
    />
  );
}

/**
 * Checkbox for specifying whether the field is copyable.
 */
export function CopyableFieldCheckbox({ value = false, onChange = noop }: CheckboxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => onChange(e.currentTarget.checked)}
      size="md"
      label={
        <Group spacing={8}>
          Povoliť kopírovanie údaju
          <QuestionMarkTooltip
            color="blue.5"
            size={14}
            label="Určí, či pri kopírovaní revíznej správy sa tento údaj skopíruje."
            multiline
            width={220}
          />
        </Group>
      }
    />
  );
}

/**
 * Checkbox for specifying whether the field is read-only.
 */
export function ReadOnlyFieldCheckbox({ value = false, onChange = noop }: CheckboxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => onChange(e.currentTarget.checked)}
      size="md"
      label={
        <Group spacing={8}>
          Iba na čítanie
          <QuestionMarkTooltip
            color="blue.5"
            size={14}
            label="Revízny technik nemôže údaj meniť, iba čítať."
            multiline
            width={220}
          />
        </Group>
      }
    />
  );
}

/**
 * Checkbox for specifying whether the field is hidden.
 */
export function HiddenFieldCheckbox({ value = false, onChange = noop }: CheckboxProps) {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => onChange(e.currentTarget.checked)}
      size="md"
      label={
        <Group spacing={8}>
          Skryté pole
          <QuestionMarkTooltip
            color="blue.5"
            size={14}
            label="Revízny technik nevidí toto pole, ale jeho hodnotu je možné použiť v šablóne."
            multiline
            width={270}
          />
        </Group>
      }
    />
  );
}
