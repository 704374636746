import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconBoxMargin } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { usePageMarginSettings } from 'pages/revisions-module/template-editor/editors/visual/margin/PageMarginSettingsProvider';

/**
 * Control used to set the page margin.
 */
export default function PageMargin({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();
  const { openPageMarginSettings } = usePageMarginSettings();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconBoxMargin />}
      label="Odsadenie"
      onClick={() => openPageMarginSettings({ onClose: () => editor?.chain().focus().run() })}
    />
  );
}
