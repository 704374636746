import { Box, Group, Select, SelectProps } from '@mantine/core';
import RevisionStatusIcon, { STATUS_TEXT_MAP } from 'components/RevisionStatusIcon';
import { noop } from 'lodash';
import { forwardRef, useMemo } from 'react';

interface ItemProps extends React.ComponentPropsWithRef<'div'> {
  label: string;
  value: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, value, ...others }: ItemProps, ref) => {
  return (
    <Group ref={ref} {...others} spacing={8}>
      <RevisionStatusIcon statusId={Number(value)} iconOnly />
      <span>{label}</span>
    </Group>
  );
});

/**
 * Allows the user to select a revision status.
 */
export default function RevisionStatusSelect({
  value,
  onChange = noop,
  omitValue,
  ...props
}: Omit<SelectProps, 'data' | 'onChange' | 'value'> & {
  onChange?: (value: number) => void;
  value?: number;
  omitValue?: number;
}) {
  const statuses = useMemo(() => {
    return Object.entries(STATUS_TEXT_MAP)
      .filter(([statusId]) => statusId !== String(omitValue))
      .map(([statusId, statusText]) => ({
        value: String(statusId),
        label: statusText,
      }));
  }, [omitValue]);

  /**
   * Updates the value.
   */
  const handleChange = (value: string) => {
    onChange(Number(value));
  };

  return (
    <Box pos="relative">
      {value !== undefined && (
        <Box
          pos="absolute"
          top={40}
          left={0}
          style={{ zIndex: -1, cursor: 'pointer' }}
          bg="white"
          px={10}
          mx={5}
          w="fit-content"
        >
          <RevisionStatusIcon statusId={value} iconOnly />
        </Box>
      )}
      <Select
        data={statuses}
        {...props}
        onChange={handleChange}
        searchable
        value={String(value)}
        itemComponent={SelectItem}
        styles={{
          input: {
            backgroundColor: 'transparent',
            paddingLeft: value ? '48px' : undefined,
          },
        }}
      />
    </Box>
  );
}
