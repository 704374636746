import { Button, Checkbox, Group, Modal, Stack, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash';
import { EvidenceField } from 'components/forms/device-subtype/EvidenceFieldRow';
import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { ThreeWaySelectLabel } from 'components/forms/device-subtype/ThreeWaySelect';
import P2Regular from 'components/typography/P2Regular';
import { useMemo } from 'react';
import { IconLock } from '@tabler/icons-react';

interface DefineEvidenceFieldParams {
  initialValues?: EvidenceField;
  type?: 'create' | 'edit';
  opened?: boolean;
  title?: string;
  onClose?: () => void;
  onDefine?: (evidenceField: EvidenceField) => void;
}

/**
 * Allows the user to pick evidence field for a device subtype.
 */
export default function DefineEvidenceFieldModal({
  initialValues,
  title,
  type = 'create',
  opened = false,
  onClose = noop,
  onDefine = noop,
}: DefineEvidenceFieldParams = {}) {
  const theme = useMantineTheme();

  const form = useForm({
    initialValues,
    validate: {
      fieldName: createValidator([required]),
      fieldType: createValidator([required]),
    },
  });

  const isPersisted = useMemo(() => initialValues?.evidenceFieldId !== undefined, [initialValues]);

  /**
   * Is called when the user clicks on the "Pridať" button.
   */
  const onDefineImp = (value: EvidenceField) => {
    onDefine(value);
    form.reset();
    form.clearErrors();
    onClose();
  };

  return (
    <Modal title={title} opened={opened} onClose={onClose} centered withinPortal>
      <form onSubmit={form.onSubmit(onDefineImp)}>
        <Stack spacing={24} pb={24} pt={24} style={{ borderTop: `1px solid ${theme.colors.gray[1]}` }}>
          <TextInput
            size="lg"
            label="Názov evidenčného poľa"
            placeholder="Názov evidenčného poľa"
            form="define-evidence-field-form"
            {...form.getInputProps('fieldName')}
          />
          <Stack spacing={8}>
            <Group spacing={4}>
              <P2Regular color="gray.6">Vyberte typ údaja pre evidenčné pole</P2Regular>
              {isPersisted && (
                <Tooltip label="Typ poľa už nie je možné upraviť" withArrow={false} openDelay={200}>
                  <IconLock size={16} color={theme.colors.red[7]} />
                </Tooltip>
              )}
            </Group>
            <Group
              p={4}
              spacing={4}
              position="center"
              style={{ borderRadius: '7px', border: `1px solid ${theme.colors.gray[2]}` }}
            >
              <ThreeWaySelectLabel
                count={3}
                title="Číslo"
                value="number"
                setValue={(value) => form.setFieldValue('fieldType', value as EvidenceField['fieldType'])}
                active={form.values.fieldType === 'number'}
                disabled={isPersisted}
                disabledText="Nemožno zmeniť typ existujúceho poľa."
              />
              <ThreeWaySelectLabel
                count={3}
                title="Text"
                value="text"
                setValue={(value) => form.setFieldValue('fieldType', value as EvidenceField['fieldType'])}
                active={form.values.fieldType === 'text'}
                disabled={isPersisted}
                disabledText="Nemožno zmeniť typ existujúceho poľa."
              />
              <ThreeWaySelectLabel
                count={3}
                title="Dátum"
                value="date"
                setValue={(value) => form.setFieldValue('fieldType', value as EvidenceField['fieldType'])}
                active={form.values.fieldType === 'date'}
                disabled={isPersisted}
                disabledText="Nemožno zmeniť typ existujúceho poľa."
              />
            </Group>
          </Stack>
          <Checkbox
            size="lg"
            label="Povinný údaj"
            form="define-evidence-field-form"
            {...form.getInputProps('isRequired', { type: 'checkbox' })}
          />
        </Stack>

        <Group position="apart">
          <Button
            size="md"
            variant="subtle-gray"
            onClick={() => {
              form.reset();
              onClose();
            }}
          >
            Zrušiť
          </Button>
          <Button size="md" type="submit" variant="primary">
            {type === 'create' ? 'Pridať nové evidenčné pole' : 'Uložiť zmeny'}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
