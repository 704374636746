import { mergeAttributes, Node } from '@tiptap/core';

export const PageHeader = Node.create({
  name: 'pageHeader',
  group: 'block',
  content: 'paragraph',
  isolating: true,

  parseHTML() {
    return [{ tag: 'div.page-header' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ class: 'page-section page-header' }, HTMLAttributes), 0];
  },
});

export const PageFooter = Node.create({
  name: 'pageFooter',
  group: 'block',
  content: 'paragraph',
  isolating: true,

  parseHTML() {
    return [{ tag: 'div.page-footer' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ class: 'page-section page-footer' }, HTMLAttributes), 0];
  },
});

export const PageBody = Node.create({
  name: 'pageBody',
  group: 'block',
  content: 'block+',
  isolating: true,

  parseHTML() {
    return [{ tag: 'div.page-body' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ class: 'page-section page-body' }, HTMLAttributes), 0];
  },
});
