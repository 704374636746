import { Button, Group, SimpleGrid, useMantineTheme } from '@mantine/core';
import { IconPencil, IconPoint, IconTrashX } from '@tabler/icons-react';
import P1Medium from 'components/typography/P1Medium';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';

export type EvidenceField = {
  key?: string;
  evidenceFieldId?: number;
  fieldName: string;
  fieldType: 'number' | 'date' | 'text';
  isRequired: boolean;
  deleted?: boolean;
};

interface EvidenceFieldRowProps extends EvidenceField {
  onEdit: () => void;
  onDelete: () => void;
}

/**
 * Translates the type of the evidence field to a human-readable form.
 */
function translateType(type: 'number' | 'date' | 'text') {
  switch (type) {
    case 'number':
      return 'Číslo';
    case 'date':
      return 'Dátum';
    case 'text':
      return 'Text';
  }
}

/**
 * Renders the required label.
 */
function RequiredPropLabel({ required }: { required: boolean }) {
  const theme = useMantineTheme();

  return (
    <Group>
      <Group position="center" style={{ borderRadius: '50%' }} bg={required ? 'red.1' : 'gray.1'} h={24} w={24}>
        <IconPoint
          fill={required ? theme.colors.red[8] : theme.colors.gray[6]}
          color={required ? theme.colors.red[8] : theme.colors.gray[6]}
          size={16}
        />
      </Group>
      <P2Regular color={required ? 'red.8' : 'gray.6'}>{required ? 'Povinné' : 'Nepovinné'}</P2Regular>
    </Group>
  );
}

/**
 * Single row in the evidence fields list.
 */
export default function EvidenceFieldRow({
  fieldName,
  fieldType,
  isRequired,
  deleted,
  onEdit,
  onDelete,
}: EvidenceFieldRowProps) {
  const theme = useMantineTheme();

  return (
    <SimpleGrid
      cols={4}
      style={{ borderBottom: `1px solid ${theme.colors.gray[1]}`, alignItems: 'center' }}
      px={16}
      py={24}
    >
      <P1Medium color="gray.8">{fieldName}</P1Medium>
      <P1Regular color="gray.8">{translateType(fieldType)}</P1Regular>
      <RequiredPropLabel required={isRequired} />
      <Group position="right" spacing={12}>
        <Button
          variant="secondary"
          leftIcon={<IconPencil stroke={1.5} color={theme.colors.blue[9]} />}
          onClick={onEdit}
        >
          Editovať
        </Button>
        <Button
          variant="danger-secondary"
          leftIcon={<IconTrashX stroke={1.5} color={theme.colors.red[8]} />}
          onClick={onDelete}
        />
      </Group>
    </SimpleGrid>
  );
}
