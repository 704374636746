import { useApi } from 'api/api-context';
import NumberStatCard from 'components/stats/NumberStatCard';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import { DEVICES_PAGE_PATH } from 'routes/paths';

/**
 * Displays the number of devices.
 */
export default function DevicesCountCard() {
  const { getAction } = useApi();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const deviceListAction = getAction('DeviceList');

    deviceListAction({ query: { filters: { 'showDiscarded.eq': 0, 'includedInStatistics.eq': 1 } } })
      .then(({ length }) => setValue(length))
      .catch(panic);
  }, [getAction]);

  return (
    <NumberStatCard
      loading={value === null}
      title="Zariadenia"
      link={DEVICES_PAGE_PATH.original}
      value={value ?? 0}
      variant="gray"
    />
  );
}
