import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { noop } from 'lodash';
import { Button, Divider, Stack, PasswordInput } from '@mantine/core';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import P2Regular from 'components/typography/P2Regular';
import H2Medium from 'components/typography/H2Medium';
import StoryIcon from 'theme/utils/StoryIcon';
import passwordFormat from 'components/forms/validators/rules/rule-password-format';

/**
 * The data collected from the form.
 */
export interface SetNewPasswordFormData {
  password: string;
  passwordAgain: string;
}

/**
 * Form for user SetNewPassword.
 */
export default function SetNewPasswordForm({
  initialValues = {
    password: '',
    passwordAgain: '',
  },
  onSubmit = noop,
}: FormProps<SetNewPasswordFormData> = {}) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<SetNewPasswordFormData>({
    initialValues,
    validate: {
      password: createValidator([required, passwordFormat]),
      passwordAgain: (passwordAgain, { password }) => {
        if (!passwordAgain) {
          return 'Pole je povinné';
        }

        if (passwordAgain !== password) {
          return 'Heslá sa nezhodujú';
        }
      },
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={4} p={24}>
        <H2Medium color="gray.8">Nastavte svoje nové heslo</H2Medium>
        <P2Regular color="gray.7">Teraz si môžete nastaviť svoje heslo</P2Regular>
      </Stack>

      <Divider my={0} color="gray.1" />

      <Stack p={24} pb={12}>
        <PasswordInput
          withAsterisk
          size="lg"
          label="Vaše nové heslo"
          name="amperia-password"
          {...form.getInputProps('password')}
        />
      </Stack>
      <Stack p={24} pt={12}>
        <PasswordInput
          withAsterisk
          size="lg"
          label="Vaše nové heslo znova"
          name="amperia-passwordAgain"
          {...form.getInputProps('passwordAgain')}
        />
      </Stack>

      <Divider my={0} color="gray.1" />

      <Stack p={24}>
        <Button loading={loading} size="lg" type="submit" leftIcon={<StoryIcon icon="check" />} fullWidth>
          Uložiť
        </Button>
      </Stack>
    </form>
  );
}
