import { MantineThemeOverride, rem } from '@mantine/core';

export type TabsTheme = NonNullable<MantineThemeOverride['components']>['Tabs'];

export const Tabs: TabsTheme = {
  variants: {
    pills: () => ({
      tabsList: {
        gap: rem(4),
      },
      tab: {
        paddingTop: rem(12),
        paddingBottom: rem(12),
        paddingLeft: rem(32),
        paddingRight: rem(32),
        fontSize: rem(16),
        lineHeight: 1.5,
        fontWeight: 500,
        borderRadius: `${rem(4)} ${rem(4)} 0 0`,
        cursor: 'pointer',
        transition: 'background 100ms ease',
        '&:hover': {
          background: 'white',
        },
        '&[data-active]': {
          background: 'white !important',
          color: 'var(--mantine-color-black)',
        },
      },
    }),
  },
};
