import { Box } from '@mantine/core';
import Toast from 'components/Toast';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import SetRevisionSafetyPointAutomationPreview from 'pages/revisions-module/template-editor/editors/pipeline/previews/SetRevisionSafetyPointAutomationPreview';
import PlanNextRevisionAutomationPreview from 'pages/revisions-module/template-editor/editors/pipeline/previews/PlanNextRevisionAutomationPreview';

type TypeCond<T, U> = [T] extends [never] ? { message: U } : {};

/**
 * Displays a preview of the automation.
 */
export default function AutomationPreview({ data }: { data: IAutomation }) {
  switch (data.type) {
    case 'set-revision-safety-point':
      return <SetRevisionSafetyPointAutomationPreview data={data} />;

    case 'plan-next-revision':
      return <PlanNextRevisionAutomationPreview data={data} />;

    default:
      // This is a TS trick to make sure all types are handled in the switch.
      // If there is a new type, the compiler will complain here. To fix it, add a new case to the switch.
      const { message }: TypeCond<typeof data, string> = { message: 'Neznáma automatizácia' };

      return (
        <Box mr={32}>
          <Toast fullWidth type="fail" withCloseButton={false} message={message} />
        </Box>
      );
  }
}
