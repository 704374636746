import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionSaveSignedDocPathParameters } from 'api/actions/revision-save-signed-doc/revision-save-signed-doc-path-parameters';
import { RevisionSaveSignedDocResponse } from 'api/actions/revision-save-signed-doc/revision-save-signed-doc-response';

type RevisionSaveSignedDocQueryParameters = Record<string, any>;

/**
 * The path of the RevisionSaveSignedDoc action.
 */
export const revisionSaveSignedDocActionPath = new SweetPath<keyof RevisionSaveSignedDocPathParameters>(
  '/api/revision/save-signed-doc/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionSaveSignedDoc actions.
 */
export default function createRevisionSaveSignedDocAction(axios: AxiosInstance) {
  return function revisionSaveSignedDocAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionSaveSignedDocPathParameters;
    query?: RevisionSaveSignedDocQueryParameters;
  }) {
    const path = revisionSaveSignedDocActionPath.insert(parameters);

    return axios.post<RevisionSaveSignedDocResponse, RevisionSaveSignedDocResponse>(path, { params: query, headers });
  };
}
