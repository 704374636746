import { AppShell, Box, Breadcrumbs, Button, Flex, Group, Input, Menu, Switch, useMantineTheme } from '@mantine/core';
import {
  IconCopy,
  IconExclamationCircle,
  IconFile,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconTableOptions,
  IconTrash,
} from '@tabler/icons-react';
import H2Medium from 'components/typography/H2Medium';
import Navbar from 'layouts/dashboard/navbar/Navbar';
import { noop } from 'lodash';
import { useMemo } from 'react';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';
import { Breadcrumb } from 'layouts/dashboard/Breadcrumb';
import P1Medium from 'components/typography/P1Medium';
import P2Medium from 'components/typography/P2Medium';
import P2Regular from 'components/typography/P2Regular';
import { FEATURE_TOGGLE_DATA_TABLE_PROFILES } from 'env';

/**
 * Padding of the DashboardLayout.
 */
export const DASHBOARD_LAYOUT_PADDING = 24;

/**
 * Height of the title.
 */
export const DASHBOARD_LAYOUT_TITLE_HEIGHT = 76;

/**
 * Height of the breadcrumbs.
 */
export const DASHBOARD_LAYOUT_BREADCRUMBS_HEIGHT = 24;

/**
 * Total height used by the DashboardLayout.
 */
export const DASHBOARD_LAYOUT_USED_HEIGHT =
  DASHBOARD_LAYOUT_PADDING * 2 + DASHBOARD_LAYOUT_TITLE_HEIGHT + DASHBOARD_LAYOUT_BREADCRUMBS_HEIGHT;

/**
 * Parameters of the DashboardLayout.
 */
export interface DashboardLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  titleColor?: string;
  isDiscarded?: boolean;
  breadcrumbs?: { title: string; link?: string }[];
  height?: 'auto' | 'screen';
  withSearch?: boolean;
  withToggle?: boolean;
  toggleLabel?: string;
  actions?: React.ReactNode;
  hideBreadcrumbs?: boolean;
  onSearch?: (value: string) => void;
  onToggleChange?: (value: boolean) => void;
}

/**
 * Dashboard layout.
 */
export default function DashboardLayout({
  children,
  title = '',
  titleColor = '',
  isDiscarded = false,
  breadcrumbs = [],
  height = 'auto',
  withSearch = false,
  withToggle = false,
  toggleLabel = '',
  actions = null,
  hideBreadcrumbs = false,
  onSearch = noop,
  onToggleChange = noop,
}: DashboardLayoutProps) {
  const theme = useMantineTheme();
  const h = useMemo(() => (height === 'screen' ? `calc(100vh - ${DASHBOARD_LAYOUT_USED_HEIGHT}px)` : 'auto'), [height]);

  return (
    <AppShell padding={DASHBOARD_LAYOUT_PADDING} navbar={<Navbar />}>
      {!hideBreadcrumbs && (
        <Box pb={title ? 0 : 16}>
          <Group h={DASHBOARD_LAYOUT_BREADCRUMBS_HEIGHT} align="center">
            <Breadcrumbs>
              <Breadcrumb title="FM POINT" link={DASHBOARD_PAGE_PATH.original} />
              {breadcrumbs.map(({ title, link }, index) => (
                <Breadcrumb key={index} title={title} link={link} />
              ))}
            </Breadcrumbs>
          </Group>
        </Box>
      )}
      {title !== '' && (
        <Group mih={DASHBOARD_LAYOUT_TITLE_HEIGHT} align="center" position="apart" pb={8}>
          <Group maw={750} spacing={16}>
            <H2Medium color={titleColor}>{title}</H2Medium>
            {withToggle && (
              <Switch mt={6} onChange={(event) => onToggleChange(event.currentTarget.checked)} label={toggleLabel} />
            )}
          </Group>
          <Group spacing={16}>
            <Group spacing={4}>
              {FEATURE_TOGGLE_DATA_TABLE_PROFILES && (
                <Group spacing={4}>
                  <Menu>
                    <Menu.Target>
                      <Button
                        size="sm"
                        variant="tertiary"
                        leftIcon={<IconTableOptions stroke={1.5} size={24} />}
                        onClick={noop}
                      >
                        <Group spacing={4}>
                          <P2Regular>Profil:</P2Regular>
                          <P2Medium>Predvolený</P2Medium>
                          <Box ml={8} w={8} h={8} bg="red.6" style={{ borderRadius: '50%' }} />
                        </Group>
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item rightSection={<Box ml={8} w={8} h={8} bg="red.6" style={{ borderRadius: '50%' }} />}>
                        <Group spacing={4}>Predvolený</Group>
                      </Menu.Item>
                      <Menu.Item>Administrátori</Menu.Item>
                      <Menu.Item>Revízni technici</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item color="blue" icon={<IconPlus size={16} />}>
                        Nový profil (prázdny)
                      </Menu.Item>
                      <Menu.Item color="blue" icon={<IconCopy size={16} />}>
                        Duplikovať profil
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item icon={<IconTrash size={16} />} color="red">
                        Vymazať profil
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  <Button size="sm" variant="tertiary" leftIcon={<IconFile stroke={1.5} />} onClick={noop}>
                    Uložiť
                  </Button>
                  <Button size="sm" variant="danger-secondary" leftIcon={<IconRefresh stroke={1.5} />} onClick={noop}>
                    Obnoviť
                  </Button>
                </Group>
              )}
              {withSearch && (
                <Input
                  miw={336}
                  rightSection={<IconSearch stroke={1.5} color={theme.colors.gray[6]} />}
                  placeholder="Hľadať v tabuľke"
                  onChange={(event) => onSearch(event.currentTarget.value)}
                />
              )}
            </Group>
            {isDiscarded && (
              <Flex bg="red.0" style={{ borderRadius: '40px', border: '1px solid white' }} px={16} py={8} gap={8}>
                <IconExclamationCircle size={24} color={theme.colors.red[9]} />
                <P1Medium color="red.9">Vyradené zariadenie</P1Medium>
              </Flex>
            )}
            {actions}
          </Group>
        </Group>
      )}
      <Box pos="relative" h={h}>
        {children}
      </Box>
    </AppShell>
  );
}
