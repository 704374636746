import { Button, Tabs, TabsValue } from '@mantine/core';
import DeviceFileTabContent from 'components/forms/device/DeviceFilesTabContent';
import { IconPlus } from '@tabler/icons-react';
import { randomId } from '@mantine/hooks';
import { useCallback, useMemo } from 'react';
import { useAddDeviceFile } from 'components/modals/add-device-file/AddDeviceFileProvider';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import { UseFormReturnType } from '@mantine/form';
import useLocalStorage from 'hooks/use-local-storage';

interface DeviceFilesProps {
  deviceId: number;
  context?: string;
  form?: UseFormReturnType<DeviceFormData>;
  files: DeviceFormData['files'];
  readonly?: boolean;
  initialTab?: string;
}

/**
 * Device files component, used to upload or display files.
 */
export default function DeviceFiles({
  deviceId,
  context = 'form',
  form,
  files,
  readonly = false,
  initialTab = 'project-documentation',
}: DeviceFilesProps) {
  const { addDeviceFile } = useAddDeviceFile();
  const key = useMemo(() => `FMPoint.DeviceFiles.${context}.${deviceId}`, [context, deviceId]);
  const [{ activeTab }, setActiveTab] = useLocalStorage<{ activeTab: TabsValue }>(key, { activeTab: initialTab });

  /**
   * Handles the file addition to the form.
   */
  function handleAddFile(remoteId: string, tabName: string, fileName: string) {
    const isMainImage =
      tabName === 'photos' ? form!.values.files.photos.filter(({ deleted }) => !deleted).length === 0 : false;

    form!.insertListItem(`files.${tabName}`, {
      fileId: remoteId,
      fileName,
      originalFileName: '',
      uploadedAt: new Date(),
      key: randomId(),
      deleted: false,
      isMainImage,
    });
  }

  const addFile = useCallback(() => {
    addDeviceFile({
      onAdd: (remoteId, tabName, fileName) => handleAddFile(remoteId, tabName, fileName),
    });
  }, [addDeviceFile, form]);

  return (
    <>
      <Tabs value={activeTab} onTabChange={(activeTab) => setActiveTab({ activeTab })}>
        <Tabs.List>
          <Tabs.Tab value="project-documentation">Projektová dokumentácia</Tabs.Tab>
          <Tabs.Tab value="revisions">Revízne správy</Tabs.Tab>
          <Tabs.Tab value="photos">Fotografie</Tabs.Tab>
          <Tabs.Tab value="other">Ostatné</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="project-documentation">
          <DeviceFileTabContent tabName="project-documentation" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="revisions">
          <DeviceFileTabContent tabName="revisions" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="photos">
          <DeviceFileTabContent tabName="photos" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
        <Tabs.Panel value="other">
          <DeviceFileTabContent tabName="other" form={form} readonly={readonly} files={files} />
        </Tabs.Panel>
      </Tabs>
      {!readonly && (
        <Button size="md" mt={24} variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={addFile}>
          Pridať súbor
        </Button>
      )}
    </>
  );
}
