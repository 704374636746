import PredefinedDataSelect from 'components/inputs/predefined-data-select/PredefinedDataSelect';
import { IFormInputSpecPredefinedSelect } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useEffect, useState } from 'react';

/**
 * Preview for a date input.
 */
export default function PredefinedSelectInputPreview({ spec }: { spec: IFormInputSpecPredefinedSelect }) {
  const { deviceType } = useTemplateEditorData();
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    setValue(null);
  }, [spec.dataTypeId]);

  return (
    <PredefinedDataSelect
      deviceTypeId={deviceType.id}
      predefinedDataType={Number(spec.dataTypeId)}
      required={spec.required}
      readOnly={spec.readOnly}
      size="sm"
      value={value ?? undefined}
      onChange={setValue}
      hideDescription
    />
  );
}
