import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionTechnicianAcceptPathParameters } from 'api/actions/revision-technician-accept/revision-technician-accept-path-parameters';
import { RevisionTechnicianAcceptResponse } from 'api/actions/revision-technician-accept/revision-technician-accept-response';

type RevisionTechnicianAcceptQueryParameters = Record<string, any>;

/**
 * The path of the RevisionTechnicianAccept action.
 */
export const revisionTechnicianAcceptActionPath = new SweetPath<keyof RevisionTechnicianAcceptPathParameters>(
  '/api/revision/technician-accept/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionTechnicianAccept actions.
 */
export default function createRevisionTechnicianAcceptAction(axios: AxiosInstance) {
  return function revisionTechnicianAcceptAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionTechnicianAcceptPathParameters;
    query?: RevisionTechnicianAcceptQueryParameters;
  }) {
    const path = revisionTechnicianAcceptActionPath.insert(parameters);

    return axios.post<RevisionTechnicianAcceptResponse, RevisionTechnicianAcceptResponse>(path, {
      params: query,
      headers,
    });
  };
}
