import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { HealthCheckHealthCheckPathParameters } from 'api/actions/health-check-health-check/health-check-health-check-path-parameters';
import { HealthCheckHealthCheckResponse } from 'api/actions/health-check-health-check/health-check-health-check-response';

type HealthCheckHealthCheckQueryParameters = Record<string, any>;

/**
 * The path of the HealthCheckHealthCheck action.
 */
export const healthCheckHealthCheckActionPath = new SweetPath<keyof HealthCheckHealthCheckPathParameters>(
  '/api/health-check/check',
  { path: '{param}' }
);

/**
 * Creates a contextualized HealthCheckHealthCheck actions.
 */
export default function createHealthCheckHealthCheckAction(axios: AxiosInstance) {
  return function healthCheckHealthCheckAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: HealthCheckHealthCheckQueryParameters } = {}) {
    const path = healthCheckHealthCheckActionPath.original;

    return axios.get<HealthCheckHealthCheckResponse, HealthCheckHealthCheckResponse>(path, { params: query, headers });
  };
}
