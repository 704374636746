import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { noop } from 'lodash';
import { Menu } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useConfirmWithMessage } from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import useReturnAction from 'components/tables/revision/actions/hooks/use-return-action';

/**
 * Action performed by the user who has the permission to return the revision.
 */
export default function ReturnAction({ revision, onStatusChange = noop }: RevisionSingleActionProps) {
  const { returnRevision } = useReturnAction();
  const { confirmWithMessage } = useConfirmWithMessage();

  /**
   * Confirms the return of the revision.
   */
  const confirmReturnRevision = () =>
    confirmWithMessage({
      title: 'Zamietnutie revíznej správy',
      message: 'Naozaj si prajete zamietnuť túto revíznu správu?',
      onConfirmWithMessage: (reason?: string) => returnRevision({ revision, reason, onStatusChange }),
    });

  return (
    <Menu.Item color="gray.8" onClick={confirmReturnRevision} icon={<IconX stroke={1.5} size={24} />}>
      Neprebrať
    </Menu.Item>
  );
}
