import { ActionIcon, LoadingOverlay, Text } from '@mantine/core';
import { Button, Group, Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconDotsVertical, IconEye, IconPencil, IconServer, IconSettings } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useFixDeviceFault } from 'components/modals/fix-device-fault/FixDeviceFaultProvider';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DEVICE_FAULTS_PAGE_PATH, EDIT_DEVICE_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Parameters of the FaultActions component.
 */
export interface FaultActionsProps {
  faultId: number;
  isFixed: boolean;
  faultName: string;
  deviceId: number;
  deviceName: string;
  department: { departmentId: number };
  onFaultFixed: ({
    fixedAt,
    fixedNote,
    fixedBy,
  }: {
    fixedAt: string;
    fixedNote?: string;
    fixedBy: { userId: number; firstName: string; lastName: string; fullName?: string };
  }) => void;
}

/**
 * Actions for the Fault table.
 */
export default function FaultActions({
  faultId,
  isFixed,
  deviceId,
  faultName,
  deviceName,
  department: { departmentId },
  onFaultFixed,
}: FaultActionsProps) {
  const { faultFix } = useFixDeviceFault();
  const { getAction, userId, firstName, lastName, roleId } = useApi();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(roleId !== ROLE_ADMIN_ID);
  const [canEditDevices, setCanEditDevices] = useState(roleId === ROLE_ADMIN_ID);

  /**
   * Handles the fault fix.
   */
  function handleFaultFix({ fixedAt, fixedNote }: { fixedAt: string; fixedNote?: string }) {
    const deviceFaultsFixAction = getAction('DeviceMarkFaultFixed');

    deviceFaultsFixAction({
      parameters: { deviceId: String(deviceId), faultId: String(faultId) },
      payload: { fixedAt, fixedNote: fixedNote ?? undefined },
    })
      .then(() => {
        showNotification({
          title: 'Závada zariadenia bola vyriešená',
          message: `Závada ${faultName} zariadenia ${deviceName} bola označená vyriešená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        onFaultFixed({
          fixedAt,
          fixedNote,
          fixedBy: {
            firstName: firstName!,
            lastName: lastName!,
            fullName: `${firstName!} ${lastName}`,
            userId: userId!,
          },
        });
      })
      .catch(panic);
  }

  useEffect(() => {
    if (opened) {
      setLoading(true);

      const action = getAction('AuthUserHasPermissionInDepartment');

      action({ parameters: { departmentId: String(departmentId), permissionSlug: 'manage-devices' } })
        .then(({ hasPermission }) => setCanEditDevices(hasPermission))
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [opened]);

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        w={120}
        component={Link}
        to={DEVICE_FAULTS_PAGE_PATH.insert({ deviceId })}
        variant="secondary"
        leftIcon={<IconEye />}
      >
        Zobraziť
      </Button>

      <Menu position="bottom-end" opened={opened} withinPortal onChange={setOpened}>
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <LoadingOverlay visible={loading} loaderProps={{ size: 12 }} />
          <Menu.Label>
            <Text maw={160} truncate>
              {faultName}
            </Text>
          </Menu.Label>
          {canEditDevices && !isFixed && (
            <Menu.Item
              onClick={() => faultFix({ onFaultFix: handleFaultFix })}
              icon={<IconSettings stroke={1.5} size={20} />}
            >
              Vyriešiť závadu
            </Menu.Item>
          )}
          <Menu.Item
            icon={canEditDevices ? <IconPencil stroke={1.5} size={20} /> : <IconServer stroke={1.5} size={20} />}
            component={Link}
            to={EDIT_DEVICE_PAGE_PATH.insert({ deviceId })}
          >
            {canEditDevices ? 'Editovať' : 'zobraziť'} zariadenie
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}
