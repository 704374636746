import { Menu, TextInput, TextInputProps } from '@mantine/core';
import PlaceholderMenu from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/PlaceholderMenu';
import { noop } from 'lodash';
import { useMemo } from 'react';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { SPECIAL_PLACEHOLDERS } from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/Placeholder';

export interface IPlaceholderSelectProps extends Omit<TextInputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  excludeSpecialTechnicianProperties?: boolean;
  excludeSpecialRevisionProperties?: boolean;
  excludeSpecialDocumentProperties?: boolean;
}

/**
 * Used to select a placeholder from the PlaceholderMenu.
 */
export default function PlaceholderSelect({
  value,
  onChange = noop,
  excludeSpecialTechnicianProperties = false,
  excludeSpecialRevisionProperties = false,
  excludeSpecialDocumentProperties = false,
  ...textInputProps
}: IPlaceholderSelectProps) {
  const { getInputByName } = useFormInputs();

  const label = useMemo(() => {
    if (!value) {
      return '';
    }

    const input = getInputByName(value);
    const special = SPECIAL_PLACEHOLDERS.find((placeholder) => placeholder.name === value);

    return input?.spec.label ?? special?.label ?? '';
  }, [value, getInputByName]);

  return (
    <Menu withinPortal>
      <Menu.Target>
        <TextInput value={label} onChange={noop} {...textInputProps} />
      </Menu.Target>
      <Menu.Dropdown>
        <PlaceholderMenu
          onClick={(placeholder) => onChange(placeholder.name)}
          excludeSpecialTechnicianProperties={excludeSpecialTechnicianProperties}
          excludeSpecialRevisionProperties={excludeSpecialRevisionProperties}
          excludeSpecialDocumentProperties={excludeSpecialDocumentProperties}
        />
      </Menu.Dropdown>
    </Menu>
  );
}
