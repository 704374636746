import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { Button, ColorInput, Group, Modal, NumberInput, Stack } from '@mantine/core';
import P4Medium from 'components/typography/P4Medium';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconBoxPadding, IconColumns1 } from '@tabler/icons-react';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { times } from 'lodash';

interface OpenPageBorderSettingsParams {
  onClose?: () => void;
}

interface IPageBorderSettingsContext {
  openPageBorderSettings: (params: OpenPageBorderSettingsParams) => void;
}

const PageBorderSettingsContext = createContext<IPageBorderSettingsContext>(undefined!);

/**
 * Content of the modal used to set the page border.
 */
function PageBorderModal({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { visual, setVisual } = useTemplateEditorData();

  const colorSwatches = useMemo(() => times(10, (i) => `rgb(${i * 25}, ${i * 25}, ${i * 25})`), []);

  const initialValues = useMemo(
    () => ({
      frame: visual.frame,
      frameWidth: visual.frameWidth,
      frameColor: visual.frameColor,
      framePadding: visual.framePadding,
    }),
    [visual]
  );

  const form = useForm({ initialValues });

  useEffect(() => {
    form.resetDirty();
    form.setValues(initialValues);
  }, [opened]);

  useEffect(() => {
    form.setFieldValue('frame', form.values.frameWidth > 0);
  }, [form.values.frameWidth]);

  const onSubmit = form.onSubmit((values) => {
    setVisual((visual) => ({ ...visual, ...values }));
    onClose();
  });

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Orámovanie stránky"
      size={415}
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={16} pt={16}>
          <NumberInput
            size="md"
            label={
              <Group spacing={8}>
                Hrúbka orámovania
                <QuestionMarkTooltip withinPortal openDelay={300} label="Nastavte 0 px pre dokument bez orámovania" />
              </Group>
            }
            icon={<IconColumns1 />}
            rightSection={<P4Medium c="gray.6">px</P4Medium>}
            min={0}
            data-autofocus
            {...form.getInputProps('frameWidth')}
          />
          <NumberInput
            size="md"
            label="Odsadenie orámovania"
            icon={<IconBoxPadding />}
            rightSection={<P4Medium c="gray.6">mm</P4Medium>}
            min={1}
            {...form.getInputProps('framePadding')}
          />
          <ColorInput
            size="md"
            label="Farba orámovania"
            swatches={colorSwatches}
            {...form.getInputProps('frameColor')}
          />

          <Group pt={8} spacing={16} position="right">
            <Button type="button" onClick={onClose} variant="subtle-gray">
              Zrušiť
            </Button>
            <Button type="submit" variant="primary">
              Nastaviť
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

/**
 * Provides a context for managing the page border form state.
 */
export function PageBorderSettingsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure();
  const [params, setParams] = useState<OpenPageBorderSettingsParams>({});

  const openPageBorderSettings = useCallback(
    (params: OpenPageBorderSettingsParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const onClose = useCallback(() => {
    params.onClose?.();
    close();
  }, [params, close]);

  const value = useMemo(() => ({ openPageBorderSettings }), [openPageBorderSettings]);

  return (
    <PageBorderSettingsContext.Provider value={value}>
      {children}
      <PageBorderModal opened={opened} onClose={onClose} />
    </PageBorderSettingsContext.Provider>
  );
}

/**
 * Hook for opening the page border form.
 */
export function usePageBorderSettings() {
  return useContext(PageBorderSettingsContext);
}
