import { DateInput } from 'components/inputs/DateInput';
import { IFormInputSpecDate } from 'pages/revisions-module/template-editor/editors/form/types';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

/**
 * Date input for the revision form.
 */
export default function RevisionFieldDate({ spec, name }: RevisionFieldProps<IFormInputSpecDate>) {
  const { form } = useFillOutRevisionDataProvider();

  return (
    <DateInput
      size="lg"
      label={spec.label}
      required={spec.required}
      readOnly={spec.readOnly}
      {...form.getInputProps(name)}
    />
  );
}
