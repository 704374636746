import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetNotificationSettingsPathParameters } from 'api/actions/user-get-notification-settings/user-get-notification-settings-path-parameters';
import { UserGetNotificationSettingsResponse } from 'api/actions/user-get-notification-settings/user-get-notification-settings-response';

type UserGetNotificationSettingsQueryParameters = Record<string, any>;

/**
 * The path of the UserGetNotificationSettings action.
 */
export const userGetNotificationSettingsActionPath = new SweetPath<keyof UserGetNotificationSettingsPathParameters>(
  '/api/user/notification-settings/{userId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserGetNotificationSettings actions.
 */
export default function createUserGetNotificationSettingsAction(axios: AxiosInstance) {
  return function userGetNotificationSettingsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserGetNotificationSettingsPathParameters;
    query?: UserGetNotificationSettingsQueryParameters;
  }) {
    const path = userGetNotificationSettingsActionPath.insert(parameters);

    return axios.get<UserGetNotificationSettingsResponse, UserGetNotificationSettingsResponse>(path, {
      params: query,
      headers,
    });
  };
}
