import { IconTablePlus } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { Stack } from '@mantine/core';
import P3Medium from 'components/typography/P3Medium';
import TableSizePicker from 'components/TableSizePicker';

/**
 * Used to insert a table into the editor.
 */
export default function InsertTable({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <DropdownControl kind={kind} icon={<IconTablePlus />} label="Tabuľka" closeOnClick>
      <Stack spacing={8} px={16} py={8}>
        <P3Medium align="center" c="gray.6">
          Vyberte rozmery tabuľky
        </P3Medium>
        <TableSizePicker
          onPick={({ rows, cols }) => editor.chain().focus().insertTable({ rows, cols, withHeaderRow: false }).run()}
        />
      </Stack>
    </DropdownControl>
  );
}
