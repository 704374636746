import { IAutomationPlanNextRevision } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { Group } from '@mantine/core';
import InputMention from 'pages/revisions-module/template-editor/editors/pipeline/InputMention';
import P3Regular from 'components/typography/P3Regular';
import P3Medium from 'components/typography/P3Medium';

/**
 * The automation preview for plan-next-revision automation.
 */
export default function PlanNextRevisionAutomationPreview({ data }: { data: IAutomationPlanNextRevision }) {
  return (
    <>
      <Group spacing={4}>
        <P3Medium>Kedy:</P3Medium>
        <P3Regular>
          {data.planIfChecked ? <InputMention prefix="Ak je zaškrtnuté pole" name={data.planIfChecked} /> : 'Vždy'}
        </P3Regular>
      </Group>

      <span>&#x7c;</span>

      <Group spacing={4}>
        <P3Medium>Dátum ukončenia:</P3Medium>
        <P3Regular>
          <InputMention prefix="Podľa poľa" name={data.endDate} />
        </P3Regular>
      </Group>

      <span>&#x7c;</span>

      <Group spacing={4}>
        <P3Medium>Perióda:</P3Medium>
        <P3Regular>
          {data.overridePeriod ? <InputMention prefix="Podľa poľa" name={data.overridePeriod} /> : 'Automaticky'}
        </P3Regular>
      </Group>
    </>
  );
}
