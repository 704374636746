import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthUserGetPermissionsInDepartmentPathParameters } from 'api/actions/auth-user-get-permissions-in-department/auth-user-get-permissions-in-department-path-parameters';
import { AuthUserGetPermissionsInDepartmentResponse } from 'api/actions/auth-user-get-permissions-in-department/auth-user-get-permissions-in-department-response';

type AuthUserGetPermissionsInDepartmentQueryParameters = Record<string, any>;

/**
 * The path of the AuthUserGetPermissionsInDepartment action.
 */
export const authUserGetPermissionsInDepartmentActionPath = new SweetPath<
  keyof AuthUserGetPermissionsInDepartmentPathParameters
>('/api/auth/user/permissions/department/{departmentId}', { path: '{param}' });

/**
 * Creates a contextualized AuthUserGetPermissionsInDepartment actions.
 */
export default function createAuthUserGetPermissionsInDepartmentAction(axios: AxiosInstance) {
  return function authUserGetPermissionsInDepartmentAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: AuthUserGetPermissionsInDepartmentPathParameters;
    query?: AuthUserGetPermissionsInDepartmentQueryParameters;
  }) {
    const path = authUserGetPermissionsInDepartmentActionPath.insert(parameters);

    return axios.get<AuthUserGetPermissionsInDepartmentResponse, AuthUserGetPermissionsInDepartmentResponse>(path, {
      params: query,
      headers,
    });
  };
}
