import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { MeasuringDeviceUpdatePayload } from 'api/actions/measuring-device-update/measuring-device-update-payload';
import { MeasuringDeviceUpdatePathParameters } from 'api/actions/measuring-device-update/measuring-device-update-path-parameters';
import { MeasuringDeviceUpdateResponse } from 'api/actions/measuring-device-update/measuring-device-update-response';

type MeasuringDeviceUpdateQueryParameters = Record<string, any>;

/**
 * The path of the MeasuringDeviceUpdate action.
 */
export const measuringDeviceUpdateActionPath = new SweetPath<keyof MeasuringDeviceUpdatePathParameters>(
  '/api/measuring-device/update/{measuringDeviceId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized MeasuringDeviceUpdate actions.
 */
export default function createMeasuringDeviceUpdateAction(axios: AxiosInstance) {
  return function measuringDeviceUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: MeasuringDeviceUpdatePathParameters;
    query?: MeasuringDeviceUpdateQueryParameters;
    payload: MeasuringDeviceUpdatePayload;
  }) {
    const path = measuringDeviceUpdateActionPath.insert(parameters);

    return axios.put<MeasuringDeviceUpdateResponse, MeasuringDeviceUpdateResponse, MeasuringDeviceUpdatePayload>(
      path,
      payload
    );
  };
}
