import { IFormInputSpecNumber } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';
import { NumberInput } from '@mantine/core';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

/**
 * Number input for the revision form.
 */
export default function RevisionFieldNumber({ spec, name }: RevisionFieldProps<IFormInputSpecNumber>) {
  const { form } = useFillOutRevisionDataProvider();

  const step = useMemo(() => {
    if (spec.precision) {
      return 1 / Math.pow(10, spec.precision);
    }

    return 1;
  }, [spec.precision]);

  return (
    <NumberInput
      size="lg"
      required={spec.required}
      readOnly={spec.readOnly}
      label={spec.label}
      min={spec.min}
      max={spec.max}
      precision={spec.precision}
      step={step}
      rightSection={spec.suffix}
      hideControls
      {...form.getInputProps(name)}
    />
  );
}
