import { Select, SelectProps } from '@mantine/core';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import { useEffect } from 'react';
import { translateRevisionPeriod } from 'components/inputs/revision-period-select/RevisionPeriodSelect';

type RevisionPlans = RevisionGetResponse['devices'][number]['revisionPlan'];

type RevisionTermSelectProps = Omit<SelectProps, 'data'> & { options: RevisionPlans; autoSelectSingleResult?: boolean };

/**
 * Select for revision term of a device.
 */
export default function RevisionTermSelect({
  options,
  autoSelectSingleResult = false,
  onChange,
  ...props
}: RevisionTermSelectProps) {
  const data = (options || []).map((rp) => ({
    label: `${rp.description} (${translateRevisionPeriod(rp.revisionPeriod)})`,
    value: String(rp.revisionPlanId),
  }));

  useEffect(() => {
    if (autoSelectSingleResult && data.length === 1) {
      onChange?.(data[0].value);
    }
  }, [autoSelectSingleResult, data.length]);

  return <Select searchable data={data} onChange={onChange} {...props} />;
}
