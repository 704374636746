import FormSection from 'pages/revisions-module/template-editor/editors/form/section/FormSection';
import { useMemo } from 'react';
import Toast from 'components/Toast';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { IConfigureInputParams } from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import { pick } from 'lodash';
import { ALL_TYPES_ON } from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';
import { IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * A single form section which collects properties of a device.
 */
export default function DeviceProperties() {
  const { sections } = useFormSections();

  const deviceSection = useMemo(() => sections.find((section) => section.tab === 'device'), [sections]);

  const { configureInputParams } = useMemo(() => {
    const allowedInputTypes: IFormInputType[] = [
      'text',
      'number',
      'date',
      'checkbox',
      'select',
      'predefinedSelect',
      'deviceProperty',
    ];

    const configureInputParams: Partial<IConfigureInputParams> = {
      inputTypes: pick(ALL_TYPES_ON, allowedInputTypes),
    };

    return { configureInputParams };
  }, []);

  if (!deviceSection) {
    return (
      <Toast
        type="fail"
        message="CHYBA: Nenašla sa sekcia pre zariadenia. Kontaktujte administrátora."
        fullWidth
        withCloseButton={false}
      />
    );
  }

  return (
    <FormSection
      data={deviceSection}
      context="device"
      disableDelete
      readOnlyName
      helpText="Sekcia sa opakuje pre každé zariadenie"
      configureInputParams={configureInputParams}
      isFirst
      isLast
      disableMoveRowToSection
    />
  );
}
