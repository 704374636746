import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { MeasuringDeviceCreatePayload } from 'api/actions/measuring-device-create/measuring-device-create-payload';
import { MeasuringDeviceCreatePathParameters } from 'api/actions/measuring-device-create/measuring-device-create-path-parameters';
import { MeasuringDeviceCreateResponse } from 'api/actions/measuring-device-create/measuring-device-create-response';

type MeasuringDeviceCreateQueryParameters = Record<string, any>;

/**
 * The path of the MeasuringDeviceCreate action.
 */
export const measuringDeviceCreateActionPath = new SweetPath<keyof MeasuringDeviceCreatePathParameters>(
  '/api/measuring-device/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized MeasuringDeviceCreate actions.
 */
export default function createMeasuringDeviceCreateAction(axios: AxiosInstance) {
  return function measuringDeviceCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: MeasuringDeviceCreateQueryParameters;
    payload: MeasuringDeviceCreatePayload;
  }) {
    const path = measuringDeviceCreateActionPath.original;

    return axios.post<MeasuringDeviceCreateResponse, MeasuringDeviceCreateResponse, MeasuringDeviceCreatePayload>(
      path,
      payload
    );
  };
}
