import { ScrollArea, Stack } from '@mantine/core';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';

/**
 * Displays an import hint for the measurements table.
 */
export default function ImportMeasurementsHint() {
  return (
    <ScrollArea h="60vh">
      <Stack spacing={16}>
        <Stack spacing={8}>
          <P1Medium>1. Schéma dokumentu</P1Medium>
          <P2Regular>
            Pre import meraní pre zariadenie je potrebné dodržať výchozie poradie stĺpcov v tabuľke meraní. V opačnom
            prípade, import zlyhá. Pre každý typ revíznej správy je definovaný vlastný formát meraní.
          </P2Regular>
        </Stack>
        <Stack spacing={8}>
          <P1Medium>2. Hodnoty</P1Medium>
          <P2Regular>Všetky číselné a textové hodnoty v XLSX zošite sú akceptované.</P2Regular>
          <P2Regular>
            V prípade, že zošit obsahuje iné formáty (dátumy, časy, menové hodnoty ...), tak tieto hodnoty budú
            ignorované a po úspešnom importe zostane namiesto nich prázdne miesto
          </P2Regular>
        </Stack>
      </Stack>
    </ScrollArea>
  );
}
