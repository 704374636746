import { notifications } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { REST_API_URL } from 'env';

/**
 * Function to call when an unexpected error occurs.
 */
export default function panic(error: Error): void {
  if (error instanceof AxiosError && error.response?.status === 401) {
    return; // No need to show notification, user will be logged out.
  }

  const message = error.message || error.toString();

  notifications.show({
    title: 'Neočakávaná chyba',
    message,
    color: 'red',
    autoClose: false,
  });

  fetch(`${REST_API_URL.replace(/\/$/, '')}/api/auth/log-error`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      source: 'web',
      message: JSON.stringify({
        location: window.location.href,
        session: window.localStorage.getItem('fmpoint.session'),
        error: {
          cause: error.cause,
          message: error.message,
          stack: error.stack,
          name: error.name,
        },
      }),
    }),
  });
}
