import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import { FormMessage } from 'components/forms/FormProps';
import { AdminChangePasswordFormData } from 'components/forms/user/AdminChangePasswordForm';
import UserForm, { UserFormData } from 'components/forms/user/UserForm';
import { USER_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { USERS_PAGE_PATH } from 'routes/paths';
import {
  DUPLICATE_USER_ERROR_CODE,
  ERROR_NOTIFICATION_COLOR,
  INSUFFICIENT_RIGHTS_ERROR_CODE,
  SUCCESS_NOTIFICATION_COLOR,
} from 'utils/constants';

/**
 * Page used to edit a user.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=241%3A8473 Figma Design}
 * - {@link https://www.notion.so/Profile-Edit-Profile-2f032568cc2d4d5f83f94ed7fc8424da?pvs=4 Notion Page}
 */
export default function EditUserPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { getAction } = useApi();
  const [passwordChangeMessage, setPasswordChangeMessage] = useState<FormMessage | undefined>(undefined);
  const { getPermissionTemplate, getDefaultPermissionTemplate } = usePermissionDataProvider();

  const { data, loading, error } = useImmediateAction(() => {
    const userGetAction = getAction('UserGet');
    return userGetAction({ parameters: { userId: userId! } });
  });

  if (error) {
    panic(error);
    return <></>;
  }

  if (!userId) {
    return <Navigate to={USERS_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({
    preNominals,
    postNominals,
    firstName,
    lastName,
    email,
    phoneNumber,
    roleId,
    inOrganizations,
    inDepartments,
  }: UserFormData) {
    const userUpdateAction = getAction('UserUpdate');

    const mappedInOrganizations = Object.entries(inOrganizations)
      .map(([organizationId, value]) => ({
        organizationId: Number(organizationId),
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        permissions: value.permissions,
        checked: value.checked,
      }))
      .filter(({ checked }) => checked)
      .map(({ organizationId, permissions, templateId }) => ({
        organizationId,
        permissions: permissions ?? [],
        templateId,
      }));

    const mappedInDepartments = Object.entries(inDepartments)
      .map(([departmentId, value]) => ({
        departmentId: Number(departmentId),
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        permissions: value.permissions,
        checked: value.checked,
      }))
      .filter(({ checked }) => checked)
      .map(({ departmentId, permissions, templateId }) => ({
        departmentId,
        permissions: permissions ?? [],
        templateId,
      }));

    return userUpdateAction({
      parameters: { userId: userId! },
      payload: {
        preNominals,
        postNominals,
        firstName,
        lastName,
        email,
        phoneNumber,
        roleId: Number(roleId),
        inOrganizations: mappedInOrganizations,
        inDepartments: mappedInDepartments,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Používateľ upravený',
          message: `Používateľ ${firstName} ${lastName} bol úspešne aktualizovaný.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (USER_REDIRECT_AFTER_SAVE === 'list') {
          navigate(USERS_PAGE_PATH.original);
        }
      })
      .catch((error) => {
        if (error.response.data.error.code === DUPLICATE_USER_ERROR_CODE) {
          notifications.show({
            title: 'Chyba!',
            message: 'Používateľ s danou emailovou adresou už existuje.',
            color: ERROR_NOTIFICATION_COLOR,
          });
        } else {
          panic(error);
        }
      });
  }

  /**
   * Handles the password change.
   */
  function changePassword({ newPassword, newPasswordRepeat }: AdminChangePasswordFormData) {
    const adminChangePasswordAction = getAction('UserAdminChangePassword');

    return adminChangePasswordAction({
      payload: {
        newPassword,
        newPasswordRepeat,
      },
      parameters: {
        userId: userId!,
      },
    })
      .then(() => {
        setPasswordChangeMessage({ type: 'success', message: 'Heslo bolo úspešne zmenené.' });
      })
      .catch((e) => {
        if (e.response.data.error.code === INSUFFICIENT_RIGHTS_ERROR_CODE) {
          setPasswordChangeMessage({ type: 'fail', message: 'Na túto akciu nemáte oprávnenie.' });
        } else {
          panic(e);
        }
      });
  }

  return (
    <DashboardLayout
      title={`Úprava užívateľa: ${data?.fullName ?? ''}`}
      breadcrumbs={[
        { title: 'Používatelia', link: USERS_PAGE_PATH.original },
        { title: data?.firstName ?? 'Načítavanie ...' },
      ]}
    >
      {!loading && (
        <UserForm
          onSubmit={onSubmit}
          initialValues={{
            preNominals: data.preNominals ?? '',
            postNominals: data.postNominals ?? '',
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber ? data.phoneNumber : '',
            organizationId: String(data.organizationId),
            roleId: String(data.roleId),
            password: '',
            confirmPassword: '',
            inOrganizations: (data.inOrganizations ?? []).reduce((acc, curr) => {
              acc[String(curr.organizationId)] = {
                checked: true,
                permissions: curr.permissions,
                templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
                organizationName: curr.organizationName!,
              };
              return acc;
            }, {} as { [organizationId: string]: { permissions: number[]; templateName: string; checked: boolean; organizationName: string } }),
            inDepartments: (data.inDepartments ?? []).reduce((acc, curr) => {
              acc[String(curr.departmentId)] = {
                checked: true,
                permissions: curr.permissions,
                templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
                departmentName: curr.departmentName!,
              };
              return acc;
            }, {} as { [departmentId: string]: { permissions: number[]; templateName: string; checked: boolean; departmentName: string } }),
          }}
          hideSections={{
            password: true,
            inOrganizations: false,
            inDepartments: false,
          }}
          readOnly={{
            organizationId: true,
          }}
          passwordChangeMessage={passwordChangeMessage}
          onPasswordChange={changePassword}
        />
      )}
    </DashboardLayout>
  );
}
