import { useApi } from 'api/api-context';
import { useCallback, useMemo } from 'react';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';

/**
 * Fetches device types from API.
 */
function useDeviceTypeSelect({
  parentDeviceTypeId,
  departmentId,
  showDiscarded = true,
}: {
  parentDeviceTypeId: number;
  departmentId: number;
  showDiscarded?: boolean;
}) {
  const { getAction } = useApi();

  const fetchDeviceTypes = useCallback(async () => {
    const action = getAction('DeviceListSubtypes');

    const deviceTypes = await action({
      query: {
        filters: {
          'parentDeviceTypeId.eq': parentDeviceTypeId,
          'showDiscarded.eq': showDiscarded !== undefined && showDiscarded ? 1 : 0,
        },
      },
      parameters: {
        departmentId: String(departmentId),
      },
    });

    return deviceTypes.map((deviceType) => ({
      label: deviceType.deviceTypeName,
      value: String(deviceType.deviceTypeId),
    }));
  }, [getAction, parentDeviceTypeId, departmentId, showDiscarded]);

  return { fetchDeviceTypes };
}
interface DeviceSubtypeSelectProp {
  parentDeviceTypeId: number;
  departmentId: number;
  showDiscarded?: boolean;
}

type DeviceSubtypeSelectProps = DataSelectInheritedProps & DeviceSubtypeSelectProp;

/**
 * Select for device subtypes.
 */
export function DeviceSubtypeSelect({
  parentDeviceTypeId,
  departmentId,
  showDiscarded,
  ...props
}: DeviceSubtypeSelectProps) {
  const key = useMemo(() => nanoid(), [parentDeviceTypeId, departmentId, showDiscarded]);
  const { fetchDeviceTypes } = useDeviceTypeSelect({
    parentDeviceTypeId,
    departmentId,
    showDiscarded,
  });

  return <DataSelect key={key} valueProp="value" labelProp="label" action={fetchDeviceTypes} {...props} />;
}
