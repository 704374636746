import { Card, SimpleGrid } from '@mantine/core';
import { useApi } from 'api/api-context';
import { FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES, FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES } from 'env';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import NavbarItem from 'layouts/dashboard/navbar/NavbarItem';
import { ROLE_ADMIN_ID } from 'model/Role';
import {
  DEVICE_TYPES_PAGE_PATH,
  GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  MEASURING_DEVICES_PAGE_PATH,
  MY_COMPANY_SETTINGS_PAGE_PATH,
  REVISION_TEMPLATES_PAGE_PATH,
  SETTINGS_PAGE_PATH,
  USERS_PREDEFINED_DATA_PAGE_PATH,
} from 'routes/paths';

/**
 * Settings page for the smaller navbar.
 */
export default function SettingsPage() {
  const { hasPermissionAnywhere, roleId } = useApi();

  return (
    <DashboardLayout title="Nastavenia" breadcrumbs={[{ title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original }]}>
      <SimpleGrid cols={4}>
        {hasPermissionAnywhere('fill-out-revisions') && (
          <Card p={16}>
            <NavbarItem to={MY_COMPANY_SETTINGS_PAGE_PATH.original} label="Nastavenia spoločnosti" />
          </Card>
        )}
        {hasPermissionAnywhere('manage-global-predefined-data') && (
          <Card p={16}>
            <NavbarItem to={GLOBAL_PREDEFINED_DATA_PAGE_PATH.original} label="Globálne predvol. údaje" />
          </Card>
        )}
        <Card p={16}>
          <NavbarItem to={USERS_PREDEFINED_DATA_PAGE_PATH.original} label="Predvolené údaje" />
        </Card>
        {hasPermissionAnywhere('fill-out-revisions') && (
          <Card p={16}>
            <NavbarItem to={MEASURING_DEVICES_PAGE_PATH.original} label="Meracie prístroje" />
          </Card>
        )}
        {FEATURE_TOGGLE_CREATE_DEVICE_SUBTYPES && hasPermissionAnywhere('define-device-subtypes') && (
          <Card p={16}>
            <NavbarItem to={DEVICE_TYPES_PAGE_PATH.original} label="Typy zariadení" />
          </Card>
        )}
        {FEATURE_TOGGLE_BROWSE_REVISION_TEMPLATES && roleId === ROLE_ADMIN_ID && (
          <Card p={16}>
            <NavbarItem to={REVISION_TEMPLATES_PAGE_PATH.original} label="Šablóny revíznych správ" />
          </Card>
        )}
      </SimpleGrid>
    </DashboardLayout>
  );
}
