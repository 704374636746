import { Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import PickDepartments, { PickDepartmentItem } from 'components/inputs/multi-checkbox/PickDepartments';
import P2Medium from 'components/typography/P2Medium';
import { useMemo, useState } from 'react';

/**
 * Page used to test various components.
 */
export default function TestPage() {
  const [opened, { close, open }] = useDisclosure(false);
  const [departments, setDepartments] = useState<PickDepartmentItem[]>([]);
  const excludeIds = useMemo(() => departments.map(({ departmentId }) => departmentId), [departments]);

  return (
    <Stack w={1000} m="auto" p={40}>
      <PickDepartments
        opened={opened}
        onClose={close}
        onPick={(departments) => setDepartments((curr) => [...departments, ...curr])}
        excludeIds={excludeIds}
        showDiscarded
      />
      <Stack spacing={20}>
        {departments.length > 0 ? (
          departments.map(({ departmentId, departmentName }) => (
            <Group key={departmentId} position="apart">
              <P2Medium>{departmentName}</P2Medium>
              <Button
                variant="danger-secondary"
                size="md"
                leftIcon={<IconTrash />}
                onClick={() =>
                  setDepartments((curr) => curr.filter((department) => department.departmentId !== departmentId))
                }
              >
                Zmazať stredisko
              </Button>
            </Group>
          ))
        ) : (
          <P2Medium>No departments selected.</P2Medium>
        )}
        <Group>
          <Button variant="subtle" size="md" leftIcon={<IconPlus />} onClick={open}>
            Pridať strediská
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
}
