import { rem } from '@mantine/core';
import { Node, mergeAttributes } from '@tiptap/core';
import getJsonAttribute from 'utils/get-json-attribute';

export interface DeviceFaultsPlaceholderFormat {
  type: 'ul' | 'ol' | 'table';
  showName: boolean;
  showSeverity: boolean;
  showDescription: boolean;
  showMoreInfo: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    deviceFaultsPlaceholder: {
      insertDeviceFaultsPlaceholder: () => ReturnType;
      setDeviceFaultsPlaceholderFormat: (format: DeviceFaultsPlaceholderFormat) => ReturnType;
    };
  }
}

const DEFAULT_FORMAT: DeviceFaultsPlaceholderFormat = {
  type: 'ul',
  showName: true,
  showSeverity: true,
  showDescription: true,
  showMoreInfo: true,
};

/**
 * Pretty prints the format of the device faults placeholder.
 */
function prettyPrintFormat({
  type,
  showName,
  showSeverity,
  showDescription,
  showMoreInfo,
}: DeviceFaultsPlaceholderFormat) {
  const prettyType = {
    ul: 'Nečíslovaný zoznam',
    ol: 'Číslovaný zoznam',
    table: 'Tabuľka',
  }[type];

  const properties = [
    showName ? 'názov' : '',
    showSeverity ? 'závažnosť' : '',
    showDescription ? 'popis' : '',
    showMoreInfo ? 'dodatok' : '',
  ]
    .filter(Boolean)
    .join(', ');

  return `${prettyType} - ${properties}`;
}

export const DeviceFaultsPlaceholder = Node.create({
  name: 'deviceFaultsPlaceholder',
  draggable: true,
  inline: false,
  group: 'block',

  addAttributes() {
    return {
      'data-name': { default: 'faults' },
      'data-format': { default: '' },
      'data-path': { default: '.' },
      'data-type': { default: 'deviceFaults' },
      format: {
        renderHTML: (attributes) => ({ 'data-format': JSON.stringify(attributes.format) }),
        parseHTML: (element) => getJsonAttribute(element, 'data-format', DEFAULT_FORMAT),
        default: DEFAULT_FORMAT,
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div.device-faults-placeholder', priority: 60 }];
  },

  renderHTML({ HTMLAttributes, node }) {
    const format = node.attrs.format as DeviceFaultsPlaceholderFormat;
    const extendedAttributes = mergeAttributes(HTMLAttributes, { class: 'placeholder device-faults-placeholder' });

    const eWrapper = document.createElement('div');
    for (const [key, value] of Object.entries(extendedAttributes)) {
      eWrapper.setAttribute(key, value);
    }
    eWrapper.ondblclick = () => window.dispatchEvent(new CustomEvent('open-device-faults-configurator'));
    eWrapper.title = 'Dvojklikom otvoríte konfigurátor';
    eWrapper.style.cursor = 'pointer';

    const eText = document.createElement('div');
    eText.textContent = 'Závady zariadenia ';

    const eFormat = document.createElement('span');
    eFormat.textContent = `(${prettyPrintFormat(format)})`;
    eFormat.style.color = 'var(--mantine-color-gray-7)';
    eFormat.style.fontSize = rem(12);
    eFormat.style.fontWeight = '500';
    eText.appendChild(eFormat);

    eWrapper.appendChild(eText);

    return eWrapper;
  },

  addCommands() {
    return {
      insertDeviceFaultsPlaceholder:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: 'deviceFaultsPlaceholder' });
        },
      setDeviceFaultsPlaceholderFormat:
        (format: DeviceFaultsPlaceholderFormat) =>
        ({ commands }) => {
          return commands.updateAttributes('deviceFaultsPlaceholder', { format });
        },
    };
  },
});
