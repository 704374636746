import { Button, Menu } from '@mantine/core';

interface NamedControlGroupProps {
  label: string;
  children: React.ReactNode;
}

/**
 * Groups controls in a menu dropdown with a label.
 */
export default function NamedControlGroup({ label, children }: NamedControlGroupProps) {
  return (
    <Menu position="bottom-start">
      <Menu.Target>
        <Button size="xs" variant="subtle-gray">
          {label}
        </Button>
      </Menu.Target>
      <Menu.Dropdown miw={300}>{children}</Menu.Dropdown>
    </Menu>
  );
}
