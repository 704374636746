import { Box, Center, createStyles, rem, SimpleGrid, Stack } from '@mantine/core';
import { noop } from 'lodash';
import { useMemo, useState } from 'react';
import P2Regular from 'components/typography/P2Regular';

export interface ICell {
  col: number;
  row: number;
  index: number;
}

export interface ITableDimensions {
  cols: number;
  rows: number;
}

interface TableSizePickerProps {
  maxCols?: number;
  maxRows?: number;
  onPick?: (size: ITableDimensions) => void;
}

const useStyles = createStyles((theme) => ({
  box: {
    width: rem(18),
    height: rem(18),
    cursor: 'pointer',
    backgroundColor: theme.colors.gray[0],
    border: `${rem(1)} solid ${theme.colors.gray[1]}`,
    '&[data-active="yes"]': {
      backgroundColor: theme.colors.blue[1],
      borderColor: theme.colors.blue[2],
    },
  },
}));

/**
 * Used to select the size of the table.
 */
export default function TableSizePicker({ maxCols = 10, maxRows = 5, onPick = noop }: TableSizePickerProps = {}) {
  const { classes } = useStyles();
  const [hoverCell, setHoverCell] = useState<ICell>(() => ({ col: 0, row: 0, index: 0 }));

  const cells = useMemo(() => {
    const cells: ICell[] = [];

    for (let row = 1; row <= maxRows; row++) {
      for (let col = 1; col <= maxCols; col++) {
        const index = row * maxCols + col;
        cells.push({ row, col, index });
      }
    }

    return cells;
  }, [maxCols, maxRows]);

  return (
    <Stack spacing={8}>
      <SimpleGrid cols={maxCols} spacing={2}>
        {cells.map((cell) => (
          <Box
            key={cell.index}
            onClick={() => onPick({ cols: cell.col, rows: cell.row })}
            onMouseEnter={() => setHoverCell(cell)}
            className={classes.box}
            data-active={cell.col <= hoverCell.col && cell.row <= hoverCell.row ? 'yes' : 'no'}
          />
        ))}
      </SimpleGrid>
      <Center>
        <P2Regular>
          {hoverCell.col} &times; {hoverCell.row}
        </P2Regular>
      </Center>
    </Stack>
  );
}
