import { ActionIcon, Button, Group, Stack, Tooltip } from '@mantine/core';
import { IconPencil, IconTrashX } from '@tabler/icons-react';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import AutomationPreview from 'pages/revisions-module/template-editor/editors/pipeline/AutomationPreview';
import { useAutomationConfigurator } from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationConfiguratorProvider';
import { usePipelineData } from 'pages/revisions-module/template-editor/editors/pipeline/PipelineDataProvider';
import { useConfirm } from 'components/modals/message/MessageProvider';
import P1Regular from 'components/typography/P1Regular';
import P2Medium from 'components/typography/P2Medium';
import { useMemo } from 'react';
import { AUTOMATION_TYPE_NAMES } from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationTypeSelect';

/**
 * A single row in the automation editor.
 */
export default function AutomationRow({ data, index }: { data: IAutomation; index: number }) {
  const { confirm } = useConfirm();
  const { configureAutomation } = useAutomationConfigurator();
  const { updateAutomation, removeAutomation } = usePipelineData();

  const automationTypeName = useMemo(() => AUTOMATION_TYPE_NAMES[data.type].label, [data.type]);

  /** Confirm removing the automation. */
  const confirmRemoveAutomation = () => {
    confirm({
      title: 'Odstrániť automatizáciu',
      content: 'Naozaj chcete odstrániť túto automatizáciu? Táto akcia je nevratná.',
      onConfirm: () => removeAutomation(data.id),
    });
  };

  /** Update the automation */
  const handleConfigureAutomation = () =>
    configureAutomation({
      initialValues: data,
      primaryButtonLabel: 'Upraviť automatizáciu',
      readOnly: { type: true },
      onSubmit: (automation) => updateAutomation(automation),
    });

  return (
    <Group p={16} style={{ borderBottom: '1px solid var(--mantine-color-gray-1)' }} spacing={16} align="flex-start">
      <P1Regular c="gray.6">#{index + 1}</P1Regular>

      <Stack spacing={2}>
        <P2Medium>{automationTypeName}</P2Medium>
        <Group pl={16} spacing={8}>
          &#x21b3;
          <Group spacing={6}>
            <AutomationPreview data={data} />
          </Group>
        </Group>
      </Stack>

      <Group noWrap ml="auto">
        <Button variant="secondary" leftIcon={<IconPencil />} onClick={handleConfigureAutomation}>
          Upraviť
        </Button>
        <Tooltip label="Odstrániť automatizáciu" openDelay={300} withArrow={false} onClick={confirmRemoveAutomation}>
          <ActionIcon variant="subtle-gray" size="md">
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
}
