import { Box, Checkbox, Stack } from '@mantine/core';
import { useApi } from 'api/api-context';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import { isEmpty, noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

/**
 * Pair of measuring device.
 */
export interface MeasuringDevice {
  name: string;
  description: string;
  type?: string;
  manufacturer?: string;
  serialNumber?: string;
}

/**
 * Map from measuring device name to measuring device description.
 */
export type MeasuringDeviceMap = Record<string, MeasuringDevice>;

/**
 * Parameters of the MeasuringDevicesMultiCheckbox component.
 */
interface MeasuringDevicesMultiCheckboxProps {
  value?: (string | MeasuringDevice)[];
  onChange?: (value: MeasuringDevice[]) => void;
}

/**
 * A multi checkbox for measuring device.
 */
export function MeasuringDevicesMultiCheckbox({ value = [], onChange = noop }: MeasuringDevicesMultiCheckboxProps) {
  const { getAction } = useApi();
  const [options, setOptions] = useState<MeasuringDeviceMap>({});
  const [loading, setLoading] = useState(false);
  const selectedKeys = useMemo(() => value.map((item) => (typeof item === 'string' ? item : item.name)), [value]);

  /**
   * Handles the change of the checkbox.
   */
  function handleChange(option: MeasuringDevice) {
    const contains = selectedKeys.includes(option.name);

    if (contains) {
      onChange(
        selectedKeys
          .filter((name) => name !== option.name)
          .map((name) => options[name])
          .filter(Boolean)
      );
    } else {
      onChange(
        selectedKeys
          .map((name) => options[name])
          .concat(option)
          .filter(Boolean)
      );
    }
  }

  // Fetch measuring devices
  useEffect(() => {
    setLoading(true);

    const fetchMeasuringDevices = getAction('MeasuringDeviceList');

    fetchMeasuringDevices()
      .then((response) => {
        const options = response.reduce(
          (acc, { measuringDeviceName: name, description, type, manufacturer, serialNumber }) => ({
            ...acc,
            [name]: {
              name,
              description: description ?? '',
              type,
              manufacturer,
              serialNumber,
            },
          }),
          {} as MeasuringDeviceMap
        );

        setOptions(options);

        onChange(selectedKeys.map((name) => options[name]).filter(Boolean));
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  return (
    <Stack spacing={12}>
      {loading ? (
        <P2Regular>Meracie prístroje sa načítavajú...</P2Regular>
      ) : isEmpty(options) ? (
        <P2Regular>Nemáte žiadne meracie prístroje</P2Regular>
      ) : (
        Object.entries(options).map(([name, data]) => {
          const description = [
            data.description,
            data.serialNumber ? `VČ: ${data.serialNumber}` : '',
            data.manufacturer ? `Výrobca: ${data.manufacturer}` : '',
            data.type ? `Typ: ${data.type}` : '',
          ];

          const descriptionText = description.filter(Boolean).join(' • ');

          return (
            <Checkbox
              size="lg"
              key={name}
              label={
                <Stack spacing={0}>
                  <Box>{name}</Box>
                  <P2Regular maw={660} style={{ whiteSpace: 'pre-wrap' }} color="gray.7">
                    {descriptionText}
                  </P2Regular>
                </Stack>
              }
              checked={selectedKeys.includes(name)}
              onChange={() => handleChange(data)}
            />
          );
        })
      )}
    </Stack>
  );
}
