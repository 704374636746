import { Box, Group, Stack, useMantineTheme } from '@mantine/core';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';

interface ReadonlyTextProps {
  title: string;
  value: string | { key: number | string; value: string }[] | JSX.Element;
}

/**
 * Displays a readonly text field
 */
export default function ReadonlyText({ title, value }: ReadonlyTextProps) {
  const theme = useMantineTheme();

  let valueRow;

  if (typeof value === 'string') {
    valueRow = <P1Regular color="gray.8">{value}</P1Regular>;
  } else if (typeof value === 'object' && Array.isArray(value)) {
    valueRow = (
      <Group spacing={8}>
        {value.map((item) => {
          return (
            <Box key={item.key} pr={8} style={{ borderRight: `1px solid ${theme.colors.gray[4]}` }}>
              <P1Regular color="gray.8">{item.value}</P1Regular>
            </Box>
          );
        })}
      </Group>
    );
  } else {
    valueRow = value;
  }

  return (
    <Stack spacing={0}>
      <P2Regular color="gray.6">{title}</P2Regular>
      {valueRow}
    </Stack>
  );
}
