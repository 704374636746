import { Group, useMantineTheme } from '@mantine/core';
import { IconBuilding, IconMapPinPlus, IconWorld, IconX } from '@tabler/icons-react';
import P2Medium from 'components/typography/P2Medium';

interface ProvenanceLabelProps {
  provenance: 'global' | 'organization' | 'department';
}

/**
 * Returns the label displaying the provenance, based on the parameter.
 */
export default function ProvenanceLabel({ provenance }: ProvenanceLabelProps) {
  const theme = useMantineTheme();

  if (provenance === 'global') {
    return (
      <Group spacing={8}>
        <IconWorld stroke={1.5} color={theme.colors.blue[9]} size={20} />
        <P2Medium color="blue.9">Globálne</P2Medium>
      </Group>
    );
  }

  if (provenance === 'organization') {
    return (
      <Group spacing={8}>
        <IconBuilding stroke={1.5} color={theme.colors.gray[9]} size={20} />
        <P2Medium color="gray.9">Vymedzené pre organizácie:</P2Medium>
      </Group>
    );
  }

  if (provenance === 'department') {
    return (
      <Group spacing={8}>
        <IconMapPinPlus stroke={1.5} color={theme.colors.gray[9]} size={20} />
        <P2Medium color="gray.9">Vymedzené pre strediská:</P2Medium>
      </Group>
    );
  }

  return (
    <Group spacing={8}>
      <IconX stroke={1.5} color={theme.colors.red[9]} size={20} />
      <P2Medium color="red.9">Neznáme</P2Medium>
    </Group>
  );
}
