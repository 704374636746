// Notifications
export const INFO_NOTIFICATION_COLOR = 'blue';
export const SUCCESS_NOTIFICATION_COLOR = 'teal';
export const ERROR_NOTIFICATION_COLOR = 'red';
export const WARNING_NOTIFICATION_COLOR = 'orange';

export const INVALID_EMAIL_OR_PASSWORD_ERROR_CODE = 4010102;
export const INVALID_PASSWORD_ERROR_CODE = 4010103;
export const INACTIVE_USER_ERROR_CODE = 4030101;
export const DUPLICATE_USER_ERROR_CODE = 4000104;
export const CANNOT_SET_ENTITY_STATUS = 4030102;
export const INSUFFICIENT_RIGHTS_ERROR_CODE = 4030103;
export const INVALID_XML_FILE_CONTENTS_ERROR_CODE = 4000001;
export const REVISION_NOT_SIGNED_YET_ERROR_CODE = 4004101;
export const REVISION_SIGNATURE_INVALID_ERROR_CODE = 4004102;
export const NO_ENTITY_EXISTS_ERROR_CODE = 4003101;
