import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentUpdatePayload } from 'api/actions/department-update/department-update-payload';
import { DepartmentUpdatePathParameters } from 'api/actions/department-update/department-update-path-parameters';
import { DepartmentUpdateResponse } from 'api/actions/department-update/department-update-response';

type DepartmentUpdateQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentUpdate action.
 */
export const departmentUpdateActionPath = new SweetPath<keyof DepartmentUpdatePathParameters>(
  '/api/department/update/{departmentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DepartmentUpdate actions.
 */
export default function createDepartmentUpdateAction(axios: AxiosInstance) {
  return function departmentUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DepartmentUpdatePathParameters;
    query?: DepartmentUpdateQueryParameters;
    payload: DepartmentUpdatePayload;
  }) {
    const path = departmentUpdateActionPath.insert(parameters);

    return axios.put<DepartmentUpdateResponse, DepartmentUpdateResponse, DepartmentUpdatePayload>(path, payload);
  };
}
