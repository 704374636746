import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { Select, SelectItem, SelectProps, Stack } from '@mantine/core';
import { useApi } from 'api/api-context';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import P3Regular from 'components/typography/P3Regular';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { nanoid } from 'nanoid';
import { IFormInputSpecPredefinedSelect } from 'pages/revisions-module/template-editor/editors/form/types';
import { useCallback, useEffect, useState } from 'react';

interface PredefinedDataTuple {
  name: string;
  description?: string;
}

interface PredefinedDataSelectProps {
  deviceTypeId: number;
  predefinedDataTypeId: number;
  hideDescription?: boolean;
  value?: PredefinedDataTuple;
  onChange?: (value: PredefinedDataTuple) => void;
}

type Props = PredefinedDataSelectProps & Omit<SelectProps, 'data' | 'onChange'>;
type DescriptionMap = Record<string, string>;

/**
 * A select for predefined data.
 *
 * TODO: Move this to src/components/inputs/predefined-data-select/PredefinedDataSelect.tsx
 */
function PredefinedDataSelect({
  deviceTypeId,
  predefinedDataTypeId,
  hideDescription = false,
  value,
  onChange = noop,
  ...props
}: Props) {
  const { getAction } = useApi();
  const [options, setOptions] = useState<SelectItem[]>(() => [{ value: nanoid(), label: 'Načítavanie ...' }]);
  const [descriptions, setDescriptions] = useState<DescriptionMap>({});

  const handleChange = useCallback(
    (name: string | null) => {
      if (name) {
        const description = descriptions[name] ?? '';
        onChange({ name, description });
      }
    },
    [descriptions, onChange]
  );

  useEffect(() => {
    const action = getAction('PredefinedDataListRevisionData');

    const filters = {
      'deviceTypeId.eq': deviceTypeId,
      'predefinedDataTypeId.eq': predefinedDataTypeId,
    };

    action({ query: { filters } })
      .then((response) => {
        setOptions(response.map(({ key }) => ({ value: key, label: key })));
        setDescriptions(response.reduce((m, { key, value }) => ({ ...m, [key]: value ?? '' }), {} as DescriptionMap));

        const newValue = response.find(({ key }) => key === value?.name);

        if (newValue) {
          onChange({ name: newValue.key, description: newValue.value ?? '' });
        } else {
          onChange({ name: '', description: '' });
        }
      })
      .catch(panic);
  }, [deviceTypeId, predefinedDataTypeId]);

  return (
    <Stack spacing={4}>
      <Select data={options} searchable size="md" value={value?.name} onChange={handleChange} {...props} />
      {value && value.description !== '' && !hideDescription && (
        <P3Regular pl={24} pr={16} color="gray.6">
          {value.description}
        </P3Regular>
      )}
    </Stack>
  );
}

/**
 * Predefined data select input for the revision form.
 */
export default function RevisionFieldPredefinedSelect({
  spec: { label, dataTypeId, useDescription, required },
  name,
}: RevisionFieldProps<IFormInputSpecPredefinedSelect>) {
  const { form, revision } = useFillOutRevisionDataProvider();
  const { value, onChange } = form.getInputProps(name);

  return (
    <PredefinedDataSelect
      size="lg"
      searchable
      name={name}
      label={label}
      placeholder="Vyberte"
      deviceTypeId={revision.deviceType.deviceTypeId}
      predefinedDataTypeId={Number(dataTypeId)}
      value={value}
      required={required}
      onChange={({ name, description }) =>
        onChange(name ? { name, description, primary: useDescription ? description : name } : undefined)
      }
    />
  );
}
