import { Button, Group, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import { Editor } from '@tiptap/react';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';

const MODAL_ID = 'device-measurements-placeholder-configurator';
const EVENT_NAME = 'open-device-measurements-configurator';

/**
 * The configurator for the device measurements placeholder.
 */
function DeviceMeasurementsConfigurator({ editor, sections }: { editor: Editor; sections: IFormSection[] }) {
  const initialValues = useMemo(
    () => ({
      sectionId: String(editor.getAttributes('deviceMeasurementsPlaceholder').sectionId || ''),
    }),
    []
  );

  const form = useForm({ initialValues });

  const options = useMemo(
    () =>
      sections
        .filter(({ tab }) => tab === 'measurements')
        .map((section) => ({ value: section.id, label: section.name })),
    [sections]
  );

  const close = useCallback(() => closeModal(MODAL_ID), []);

  const submit = useCallback(
    ({ sectionId }: typeof initialValues) => {
      editor.chain().focus().setDeviceMeasurementsPlaceholderSectionId(sectionId).run();
      close();
    },
    [editor, close]
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={24}>
        <Select size="sm" required label="Tabuľka meraní" data={options} {...form.getInputProps('sectionId')} />
        <Group pt={8} spacing={16} position="right">
          <Button type="button" onClick={close} variant="subtle-gray">
            Zrušiť
          </Button>
          <Button type="submit" variant="primary">
            Nastaviť
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

/**
 * Hook used to register the configurator for the device measurements placeholder.
 */
export default function useDeviceMeasurementsPlaceholderConfigurator(editor: Editor | null) {
  const { sections } = useFormSections();

  const openConfigurator = useCallback(() => {
    if (editor) {
      openModal({
        modalId: MODAL_ID,
        title: 'Konfigurátor tabuľky meraní',
        size: 400,
        centered: true,
        returnFocus: false,
        children: <DeviceMeasurementsConfigurator editor={editor} sections={sections} />,
      });
    }
  }, [editor, sections]);

  useEffect(() => {
    window.addEventListener(EVENT_NAME, openConfigurator);
    return () => window.removeEventListener(EVENT_NAME, openConfigurator);
  }, [openConfigurator]);
}
