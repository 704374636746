import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { IForm } from 'pages/revisions-module/template-editor/editors/form/types';
import { IPipeline } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { IVisual } from 'pages/revisions-module/template-editor/editors/visual/types';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { DeviceType, getDeviceType, UNKNOWN_DEVICE_TYPE } from 'model/DeviceType';
import { ITemplate } from 'pages/revisions-module/template-editor/editors/template/types';
import { DEFAULT_VISUAL } from 'pages/revisions-module/template-editor/editors/visual/default-visual';
import { DEFAULT_TEMPLATE } from 'pages/revisions-module/template-editor/editors/template/default-template';
import { DEFAULT_FORM } from 'pages/revisions-module/template-editor/editors/form/default-form';
import { DEFAULT_PIPELINE } from 'pages/revisions-module/template-editor/editors/pipeline/default-pipeline';
import { merge } from 'lodash';

interface ITemplateEditorDataContext {
  revisionTemplateId: string;
  deviceType: DeviceType;
  form: IForm;
  setForm: Dispatch<SetStateAction<IForm>>;
  template: ITemplate;
  setTemplate: Dispatch<SetStateAction<ITemplate>>;
  visual: IVisual;
  setVisual: Dispatch<SetStateAction<IVisual>>;
  pipeline: IPipeline;
  setPipeline: Dispatch<SetStateAction<IPipeline>>;
}

const TemplateEditorDataContext = createContext<ITemplateEditorDataContext>(undefined!);

/**
 * Parameters of the TemplateEditorDataProvider component.
 */
interface TemplateEditorDataProviderProps {
  children: React.ReactNode;
  revisionTemplateId: string;
}

/**
 * Provides the form data to the form editor.
 */
export function TemplateEditorDataProvider({ children, revisionTemplateId }: TemplateEditorDataProviderProps) {
  const { getAction } = useApi();
  const [ready, setReady] = useState(false);
  const [deviceType, setDeviceType] = useState<DeviceType>(UNKNOWN_DEVICE_TYPE);
  const [form, setForm] = useState<IForm>(DEFAULT_FORM);
  const [template, setTemplate] = useState<ITemplate>(DEFAULT_TEMPLATE);
  const [visual, setVisual] = useState<IVisual>(DEFAULT_VISUAL);
  const [pipeline, setPipeline] = useState<IPipeline>(DEFAULT_PIPELINE);

  useEffect(() => {
    const getRevisionTemplate = getAction('RevisionGetRevisionTemplate');

    getRevisionTemplate({ parameters: { revisionTemplateId } })
      .then(({ deviceTypeId, formScheme, templateScheme, visualData = '{}', pipelineScheme }) => {
        setDeviceType(getDeviceType(deviceTypeId));

        if (formScheme) {
          setForm(JSON.parse(formScheme) as IForm);
        }

        if (templateScheme) {
          setTemplate(JSON.parse(templateScheme) as ITemplate);
        }

        setVisual(merge(DEFAULT_VISUAL, JSON.parse(visualData) as IVisual));

        if (pipelineScheme) {
          setPipeline(JSON.parse(pipelineScheme) as IPipeline);
        }

        setReady(true);
      })
      .catch(panic);
  }, []);

  const value = useMemo(
    () => ({
      revisionTemplateId,
      deviceType,
      form,
      setForm,
      template,
      setTemplate,
      visual,
      setVisual,
      pipeline,
      setPipeline,
    }),
    [revisionTemplateId, deviceType, form, setForm, template, setTemplate, visual, setVisual, pipeline, setPipeline]
  );

  return (
    <TemplateEditorDataContext.Provider value={value}>{ready ? children : <></>}</TemplateEditorDataContext.Provider>
  );
}

/**
 * Hook to access the form data.
 */
export function useTemplateEditorData() {
  return useContext(TemplateEditorDataContext);
}
