import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import {
  DASHBOARD_PAGE_PATH,
  FILL_OUT_REVISION_PAGE_PATH,
  REVISION_PAGE_PATH,
  REVISIONS_PAGE_PATH,
} from 'routes/paths';
import TemplateAndVisualSelect from 'pages/revisions-module/select-template-and-visual/TemplateAndVisualSelect';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import { LoadingOverlay } from '@mantine/core';
import { NO_ENTITY_EXISTS_ERROR_CODE } from 'utils/constants';

/**
 * Page used to select a revision template and visual.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=2460%3A28511 Figma Design}
 * - {@link https://www.notion.so/Revisions-Fill-Out-Electric-Revision-d79bac79a28a469ea75b51c188643e99?pvs=4 Notion Page}
 * - {@link https://www.notion.so/Revisions-Fill-Out-Gas-Revision-internal-cdbbee2f1ba6404a95b21930f178aa0f?pvs=4 Notion Page}
 * - {@link https://www.notion.so/Revisions-Fill-Out-Gas-Revision-external-07617e6dbbe046f3b9a4d487b4dfc3df?pvs=4 Notion Page}
 * - {@link https://www.notion.so/Revisions-Fill-Out-Pressure-Revision-08c0ffc037594c3799a8f51385fea543?pvs=4 Notion Page}
 */
export default function SelectTemplateAndVisualPage() {
  const navigate = useNavigate();
  const { revisionId } = useParams();
  const { getAction, userId } = useApi();
  const [loading, setLoading] = useState(true);
  const [revision, setRevision] = useState<RevisionGetResponse | null>(null);

  const fillOutRevisionPath = useMemo(() => FILL_OUT_REVISION_PAGE_PATH.insert({ revisionId }), [revisionId]);
  const revisionDetailPath = useMemo(() => REVISION_PAGE_PATH.insert({ revisionId }), [revisionId]);

  // Fetch revision
  useEffect(() => {
    if (revisionId) {
      const action = getAction('RevisionGet');

      action({ parameters: { revisionId } })
        .then(setRevision)
        .catch((e) => {
          if (e?.response?.data?.error?.code === NO_ENTITY_EXISTS_ERROR_CODE) {
            navigate(DASHBOARD_PAGE_PATH.original, { replace: true });
          } else {
            panic(e);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [revisionId]);

  /**
   * Handles the template and visual change, sends the values to API..
   */
  function handleTemplateAndVisualChange({ template, visual }: { template: string; visual: string }) {
    const action = getAction('RevisionSetTemplateAndVisual');

    setLoading(true);

    const templateModified = revision!.revisionTemplate?.slug && revision!.revisionTemplate?.slug !== template;

    action({
      parameters: { revisionId: String(revisionId) },
      payload: {
        revisionData: templateModified ? JSON.stringify(null) : undefined,
        revisionTemplate: template,
        revisionVisual: visual,
      },
    })
      .then(() => navigate(fillOutRevisionPath))
      .catch(panic)
      .finally(() => setLoading(false));
  }

  if (revision && revision.technician.userId !== userId) {
    return <Navigate to={revisionDetailPath} replace />;
  }

  if (revision && revision.revisionStatus.slug !== 'accepted-by-technician') {
    return <Navigate to={fillOutRevisionPath} replace />;
  }

  return (
    <DashboardLayout
      title={'Vyberte šablónu a vizuál revíznej správy'}
      breadcrumbs={[
        { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
        { title: 'Vyberte šablónu a vizuál revíznej správy' },
      ]}
    >
      <LoadingOverlay visible={loading} />
      {revision && (
        <TemplateAndVisualSelect
          deviceTypeId={revision.deviceType.deviceTypeId}
          customerOrganization={revision.organization.organizationId}
          loading={loading}
          onChange={handleTemplateAndVisualChange}
          initialTemplateSlug={revision.revisionTemplate?.slug}
        />
      )}
    </DashboardLayout>
  );
}
