import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconBold } from '@tabler/icons-react';

/**
 * Used to make text bold.
 */
export default function ControlBold() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Bold" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleBold().run()}
        active={editor?.isActive('bold')}
      >
        <IconBold size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
