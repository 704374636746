import { Box, LoadingOverlay } from '@mantine/core';
import { useFileManager } from 'api/file-manager/file-manager-context';
import useFileDownload from 'api/use-file-download';
import P2RegularLink from 'components/typography/P2RegularLink';
import { noop } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * The props of the File Link component.
 */
interface FileLinkProps {
  fileId: string;
  onOpenSlider?: () => void;
}

/**
 * File link component.
 */
export default function FileLink({ fileId, onOpenSlider = noop }: FileLinkProps) {
  const [fileName, setFileName] = useState('');
  const [openSlider, setOpenSlider] = useState(false);

  const { getFileMetadata } = useFileManager();
  const { loading, download } = useFileDownload();

  // Fetch the file metadata.
  useEffect(() => {
    getFileMetadata({ fileId }).then((metadata) => {
      setFileName(`${metadata.name}${metadata.extension}`);
      setOpenSlider(metadata.mimeType.startsWith('image/') || metadata.mimeType === 'application/pdf');
    });
  }, [fileId]);

  return (
    <Box>
      <LoadingOverlay visible={loading} loaderProps={{ size: 'sm' }} />
      <Box
        p={4}
        m={-4}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (openSlider) {
            onOpenSlider();
          } else {
            download(fileId);
          }
        }}
      >
        <P2RegularLink color="blue.8">{fileName}</P2RegularLink>
      </Box>
    </Box>
  );
}
