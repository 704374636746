import { MantineThemeOverride } from '@mantine/core';

export type ColorInputTheme = NonNullable<MantineThemeOverride['components']>['ColorInput'];

export const ColorInput: ColorInputTheme = {
  defaultProps: {
    withEyeDropper: false,
    closeOnColorSwatchClick: true,
    format: 'rgb',
  },
  styles: (theme) => ({}),
};
