import { Button, Card, Center, Flex, Group, Skeleton } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import NumberBadge, { NumberBadgeVariant } from 'components/NumberBadge';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import H3Medium from 'components/typography/H3Medium';
import P1Medium from 'components/typography/P1Medium';
import { Link } from 'react-router-dom';

/**
 * Parameters of the NumberStatCard component.
 */
export interface NumberStatCardProps {
  value?: number | number[];
  title: string | React.ReactNode;
  link: string;
  tooltipText?: string;
  variant?: NumberBadgeVariant;
  loading?: boolean;
}

/**
 * Displays a number in a card.
 */
export default function NumberStatCard({
  value,
  title,
  link,
  tooltipText,
  variant,
  loading = false,
}: NumberStatCardProps) {
  if (loading) {
    return <Skeleton radius={4} mih={132} />;
  }

  return (
    <Card p={16}>
      <Flex h="100%" w="100%" direction="column" align="stretch" justify="space-between">
        <P1Medium color="gray.8">
          {title}
          {tooltipText && (
            <>
              <span style={{ userSelect: 'none' }}>&nbsp;&nbsp;</span>
              <Center inline sx={{ transform: 'translateY(1px)' }}>
                <QuestionMarkTooltip withinPortal multiline width={200} label={tooltipText} openDelay={100} />
              </Center>
            </>
          )}
        </P1Medium>
        <Group position="apart" pt={40} noWrap>
          {value === undefined ? (
            <H3Medium color="gray.8">-</H3Medium>
          ) : (
            <NumberBadge variant={variant} value={value} />
          )}
          {value !== undefined && (
            <Button
              component={Link}
              to={link}
              size="sm"
              variant="subtle-compact"
              rightIcon={<IconChevronRight size={16} />}
            >
              Pozrieť
            </Button>
          )}
        </Group>
      </Flex>
    </Card>
  );
}
