import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataUpdateUsersPayload } from 'api/actions/predefined-data-update-users/predefined-data-update-users-payload';
import { PredefinedDataUpdateUsersPathParameters } from 'api/actions/predefined-data-update-users/predefined-data-update-users-path-parameters';
import { PredefinedDataUpdateUsersResponse } from 'api/actions/predefined-data-update-users/predefined-data-update-users-response';

type PredefinedDataUpdateUsersQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataUpdateUsers action.
 */
export const predefinedDataUpdateUsersActionPath = new SweetPath<keyof PredefinedDataUpdateUsersPathParameters>(
  '/api/predefined-data/update/users/{usersPredefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataUpdateUsers actions.
 */
export default function createPredefinedDataUpdateUsersAction(axios: AxiosInstance) {
  return function predefinedDataUpdateUsersAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataUpdateUsersPathParameters;
    query?: PredefinedDataUpdateUsersQueryParameters;
    payload: PredefinedDataUpdateUsersPayload;
  }) {
    const path = predefinedDataUpdateUsersActionPath.insert(parameters);

    return axios.put<
      PredefinedDataUpdateUsersResponse,
      PredefinedDataUpdateUsersResponse,
      PredefinedDataUpdateUsersPayload
    >(path, payload);
  };
}
