import { Divider, Navbar as MantineNavbar, Image, Box, ScrollArea, useMantineTheme, Center } from '@mantine/core';
import NavbarMenu from 'layouts/dashboard/navbar/NavbarMenu';
import NavbarPerson from 'layouts/dashboard/navbar/NavbarPerson';
import NavbarImpersonating from 'layouts/dashboard/navbar/NavbarImpersonating';
import { IconChevronLeft } from '@tabler/icons-react';
import useLocalStorage from 'hooks/use-local-storage';

/**
 * The navbar of the dashboard.
 */
export default function Navbar() {
  const theme = useMantineTheme();
  const [{ collapsed }, setCollapsed] = useLocalStorage('fmpoint.navbar.collapsed', { collapsed: false });

  return (
    <MantineNavbar width={{ base: collapsed ? 88 : 256 }}>
      <Box
        sx={{
          position: 'fixed',
          left: collapsed ? 69 : 235,
          bottom: 79,
          cursor: 'pointer',
          zIndex: 20,
        }}
        p={8}
        onClick={() => setCollapsed({ collapsed: !collapsed })}
      >
        <Center
          w={24}
          h={24}
          sx={{
            borderRadius: '50%',
            backgroundColor: theme.colors.gray[0],
            border: `1px solid ${theme.colors.gray[3]}`,
            transition: 'background-color 200ms ease',
            '&:hover': {
              backgroundColor: theme.colors.gray[1],
            },
          }}
        >
          <IconChevronLeft
            strokeWidth={2.5}
            size={20}
            color={theme.colors.gray[7]}
            style={{ transform: `rotate(${collapsed ? 180 : 0}deg)`, cursor: 'pointer' }}
          />
        </Center>
      </Box>
      <Box h="100%" sx={{ display: 'grid', gridTemplateColumns: '100%', gridTemplateRows: 'auto 1px 1fr 1px auto' }}>
        <Box py={28} px={24}>
          {collapsed ? (
            <Image src="/img/fmPoint_logo_small.svg" width={24} h="auto" />
          ) : (
            <Image src="/img/fmPoint_logo.svg" width={154} h="auto" />
          )}
        </Box>

        <Divider color="gray.1" w={collapsed ? 88 : 256} />

        <ScrollArea>
          <Box py={24} px={12}>
            <NavbarMenu isCollapsed={collapsed} />
          </Box>
        </ScrollArea>

        <Divider color="gray.1" w={collapsed ? 88 : 256} />

        <Box px={12} py={17}>
          <NavbarPerson isCollapsed={collapsed} />
          <NavbarImpersonating isCollapsed={collapsed} />
        </Box>
      </Box>
    </MantineNavbar>
  );
}
