import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthLogErrorPayload } from 'api/actions/auth-log-error/auth-log-error-payload';
import { AuthLogErrorPathParameters } from 'api/actions/auth-log-error/auth-log-error-path-parameters';
import { AuthLogErrorResponse } from 'api/actions/auth-log-error/auth-log-error-response';

type AuthLogErrorQueryParameters = Record<string, any>;

/**
 * The path of the AuthLogError action.
 */
export const authLogErrorActionPath = new SweetPath<keyof AuthLogErrorPathParameters>('/api/auth/log-error', {
  path: '{param}',
});

/**
 * Creates a contextualized AuthLogError actions.
 */
export default function createAuthLogErrorAction(axios: AxiosInstance) {
  return function authLogErrorAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthLogErrorQueryParameters;
    payload: AuthLogErrorPayload;
  }) {
    const path = authLogErrorActionPath.original;

    return axios.post<AuthLogErrorResponse, AuthLogErrorResponse, AuthLogErrorPayload>(path, payload);
  };
}
