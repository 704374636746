import { GridApi } from 'ag-grid-community';
import DeviceSubtypeSelectFilter, {
  DeviceSubtypeSelectFilterBaseProps,
} from 'components/tables/filters/device-subtype/DeviceSubtypeSelectFilter';

/**
 * Parameters of the DeviceSubtypeSelectFilter component.
 */
export interface DeviceSubtypeSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Select filter for the device subtype. The filter is of type 'contains'.
 */
export default function DeviceSubtypeContainsSelectFilter({ api, context }: DeviceSubtypeSelectFilterBaseProps) {
  return (
    <DeviceSubtypeSelectFilter api={api} context={context} filterKey="deviceSubtype.deviceTypeId" filterType="equals" />
  );
}
