import { UseFormReturnType } from '@mantine/form';
import { IAutomation, IAutomationType } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { Box } from '@mantine/core';
import Toast from 'components/Toast';
import SetRevisionSafetyPointSubForm from 'pages/revisions-module/template-editor/editors/pipeline/configurator/properties/SetRevisionSafetyPointSubForm';
import PlanNextRevisionSubForm from 'pages/revisions-module/template-editor/editors/pipeline/configurator/properties/PlanNextRevisionSubForm';

type TypeCond<T, U> = [T] extends [never] ? { message: U } : {};

/**
 * Parameters of the AutomationSubForm component.
 */
interface AutomationSubFormProps {
  type: IAutomationType;
  form: UseFormReturnType<IAutomation>;
}

/**
 * Used to configure the type-specific properties of an automation.
 */
export default function AutomationSubForm({ type, form }: AutomationSubFormProps) {
  switch (type) {
    case 'set-revision-safety-point':
      return <SetRevisionSafetyPointSubForm form={form} />;

    case 'plan-next-revision':
      return <PlanNextRevisionSubForm form={form} />;

    default:
      // This is a TS trick to make sure all types are handled in the switch.
      // If there is a new type, the compiler will complain here. To fix it, add a new case to the switch.
      const { message }: TypeCond<typeof type, string> = { message: 'Neznáma automatizácia' };

      return (
        <Box mr={32}>
          <Toast fullWidth type="fail" withCloseButton={false} message={message} />
        </Box>
      );
  }
}
