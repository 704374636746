import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionListTechniciansPathParameters } from 'api/actions/revision-list-technicians/revision-list-technicians-path-parameters';
import { RevisionListTechniciansResponse } from 'api/actions/revision-list-technicians/revision-list-technicians-response';

type RevisionListTechniciansQueryParameters = Record<string, any>;

/**
 * The path of the RevisionListTechnicians action.
 */
export const revisionListTechniciansActionPath = new SweetPath<keyof RevisionListTechniciansPathParameters>(
  '/api/revision/list-technicians/{departmentId}/{deviceTypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionListTechnicians actions.
 */
export default function createRevisionListTechniciansAction(axios: AxiosInstance) {
  return function revisionListTechniciansAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionListTechniciansPathParameters;
    query?: RevisionListTechniciansQueryParameters;
  }) {
    const path = revisionListTechniciansActionPath.insert(parameters);

    return axios.get<RevisionListTechniciansResponse, RevisionListTechniciansResponse>(path, {
      params: query,
      headers,
    });
  };
}
