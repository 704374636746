import {
  IconAlertCircle,
  IconAlertTriangle,
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconCopy,
  IconFile,
  IconStar,
  IconPlus,
  IconX,
  IconTrashX,
} from '@tabler/icons-react';

/**
 * The available icons.
 */
export const ICONS = ['star', 'check', 'alert-triangle', 'alert-circle', 'arrow-left', 'arrow-right', 'file', 'copy'];

/**
 * The icon used in the story.
 */
export default function StoryIcon({ icon }: { icon: string | null }) {
  if (icon === 'star') {
    return <IconStar />;
  }

  if (icon === 'check') {
    return <IconCheck />;
  }

  if (icon === 'alert-triangle') {
    return <IconAlertTriangle />;
  }

  if (icon === 'alert-circle') {
    return <IconAlertCircle />;
  }

  if (icon === 'arrow-left') {
    return <IconArrowLeft />;
  }

  if (icon === 'arrow-right') {
    return <IconArrowRight />;
  }

  if (icon === 'file') {
    return <IconFile />;
  }

  if (icon === 'copy') {
    return <IconCopy />;
  }
  if (icon === 'plus') {
    return <IconPlus />;
  }
  if (icon === 'close') {
    return <IconX />;
  }
  if (icon === 'trashX') {
    return <IconTrashX />;
  }

  return null;
}
