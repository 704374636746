import { notifications } from '@mantine/notifications';
import { DeviceSubtypeGetResponse } from 'api/actions/device-subtype-get/device-subtype-get-response';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import DeviceSubtypeForm, { DeviceSubtypeFormData } from 'components/forms/device-subtype/DeviceSubtypeForm';
import { EvidenceField } from 'components/forms/device-subtype/EvidenceFieldRow';
import { DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { DEVICE_TYPES, getDeviceType } from 'model/DeviceType';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DEVICE_SUBTYPES_PAGE_PATH, DEVICE_TYPES_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Determines the restriction type of the device subtype.
 */
function getDeviceSubtypeRestriction(data: DeviceSubtypeGetResponse) {
  if (data.isOrganization) {
    return 'organization';
  }

  if (data.isDepartment) {
    return 'department';
  }

  return 'global';
}

/**
 * Page used to edit a device subtype.
 */
export default function EditDeviceSubtypePage() {
  const { getAction } = useApi();
  const navigate = useNavigate();

  const { deviceTypeId: parentDeviceTypeId, deviceSubtypeId } = useParams();
  const deviceTypeId = useMemo(() => Number(parentDeviceTypeId ?? DEVICE_TYPES[0].id), [parentDeviceTypeId]);
  const parentDeviceTypeName = useMemo(() => getDeviceType(deviceTypeId)?.name, [deviceTypeId]);

  const { data, loading, error } = useImmediateAction(() => {
    const action = getAction('DeviceSubtypeGet');

    return action({
      parameters: {
        deviceTypeId: String(deviceTypeId),
        deviceSubtypeId: String(deviceSubtypeId),
      },
    });
  });

  if (!deviceTypeId || !deviceSubtypeId) {
    return <Navigate to={DEVICE_TYPES_PAGE_PATH.original} />;
  }

  if (error) {
    panic(error);
    return <></>;
  }

  /**
   * Handles form submission.
   */
  function onSubmit({
    deviceSubtypeName,
    deviceSubtypeShortName,
    description,
    restriction,
    departments,
    organizations,
    evidenceFields,
  }: DeviceSubtypeFormData) {
    const deviceSubtypeUpdateAction = getAction('DeviceSubtypeUpdate');

    const isOrganization = restriction === 'organization';
    const isDepartment = restriction === 'department';

    return deviceSubtypeUpdateAction({
      parameters: {
        deviceTypeId: String(deviceTypeId),
        deviceSubtypeId: String(deviceSubtypeId),
      },
      payload: {
        deviceSubtypeName,
        shortName: deviceSubtypeShortName,
        description,
        isGlobal: restriction === 'global',
        isOrganization,
        isDepartment,
        departments: isDepartment ? departments.map(({ departmentId }) => departmentId) : [],
        organizations: isOrganization ? organizations.map(({ organizationId }) => organizationId) : [],
        evidenceFields: evidenceFields.map(({ evidenceFieldId, fieldName, fieldType, isRequired, deleted }) => ({
          evidenceFieldId,
          fieldName,
          fieldType,
          isRequired,
          deleted,
        })),
      },
    })
      .then(() => {
        notifications.show({
          title: 'Podtyp bol úspešne upravený.',
          message: `Podtyp ${deviceSubtypeName} bol úspešne upravený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEVICE_SUBTYPES_PAGE_PATH.insert({ deviceTypeId }));
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title={`Úprava typu zariadenia: ${data?.deviceTypeName}`}
      breadcrumbs={[
        { title: 'Typy zariadení', link: DEVICE_TYPES_PAGE_PATH.original },
        {
          title: `Typy zariadení pre: ${parentDeviceTypeName ?? ''}`,
          link: DEVICE_SUBTYPES_PAGE_PATH.insert({ deviceTypeId }),
        },
        { title: data?.deviceTypeName ?? 'Načítavanie ...' },
      ]}
    >
      {!loading && (
        <DeviceSubtypeForm
          initialValues={{
            parentDeviceTypeId: data.parentDeviceTypeId,
            deviceSubtypeName: data.deviceTypeName,
            deviceSubtypeShortName: data.shortName,
            description: data.description,
            restriction: getDeviceSubtypeRestriction(data),
            departments: data.departments ?? [],
            organizations: data.organizations ?? [],
            evidenceFields: data.evidenceFields
              ? data.evidenceFields.map((field) => ({
                  ...field,
                  deleted: false,
                  key: nanoid(),
                  fieldType: field.fieldType as EvidenceField['fieldType'],
                }))
              : [],
          }}
          onSubmit={onSubmit}
        />
      )}
    </DashboardLayout>
  );
}
