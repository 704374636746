import { createContext, useContext } from 'react';
import { GetActionMap } from 'api/actions/actions';
import createAuthLoginAction from 'api/actions/auth-login/auth-login-action';
import { AuthLoggedUserInfoResponse } from 'api/actions/auth-logged-user-info/auth-logged-user-info-response';

/**
 * Interface of the session stored in local storage.
 */
export interface ISession {
  /**
   * The JWT token.
   */
  jwt: string | null;

  /**
   * The first name of the user.
   */
  firstName: string | null;

  /**
   * The last name of the user.
   */
  lastName: string | null;

  /**
   * The full name of the user.
   */
  fullName: string | null;

  /**
   * The email of the user.
   */
  email: string | null;

  /**
   * The user id.
   */
  userId: number | null;

  /**
   * The role of the user.
   */
  role: string | null;

  /**
   * The role id of the user.
   */
  roleId: number | null;

  /**
   * The permissions of the user.
   */
  permissions: AuthLoggedUserInfoResponse['permissions'] | null;

  /**
   * The organization name of the user.
   */
  organizationName: string | null;
}

/**
 * The impersonate interface.
 */
export type IImpersonate = Omit<ISession, 'jwt'>;

/**
 * The session context interface.
 */
export interface IApiContext extends ISession {
  /**
   * The logout action
   */
  logout: () => void;

  /**
   * The login action.
   */
  login: ReturnType<typeof createAuthLoginAction>;

  /**
   * Returns the action with the given operationId.
   */
  getAction: GetActionMap;

  /**
   * Whether the API connector is ready.
   */
  ready: boolean;

  /**
   * Whether the session should be resumed.
   */
  shouldResumeSession: boolean;

  /**
   * The impersonated user.
   */
  impersonate?: IImpersonate;

  /**
   * The original session.
   */
  originalSession: Omit<ISession, 'jwt'>;

  /**
   * Sets the impersonated user.
   */
  setImpersonate: (impersonate: IImpersonate | undefined) => void;

  /**
   * Whether the user has the given permission anywhere.
   */
  hasPermissionAnywhere: (permission: string) => boolean;
}

/**
 * The session context is used to store the current session.
 *
 * The session context will be provided in <App>.
 */
export const ApiContext = createContext<IApiContext>(undefined!);

/**
 * The use API hook.
 */
export function useApi() {
  return useContext(ApiContext);
}
