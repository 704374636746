import { Button, FileButton, LoadingOverlay, Stack } from '@mantine/core';
import P1Regular from 'components/typography/P1Regular';
import { IconUpload } from '@tabler/icons-react';
import { useState } from 'react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import toBase64 from 'utils/to-base64';
import { FILE_UPLOAD_MAX_SIZE } from 'env';
import panic from 'errors/panic';
import FilePreview from 'components/files/FilePreview';
import { noop } from 'lodash';
import P2Regular from 'components/typography/P2Regular';
import P4Medium from 'components/typography/P4Medium';

interface UploadFileProps {
  label: string;
  description: string;
  accept?: string;
  value?: string;
  error?: string;
  onChange?: (value: string) => void;
  onFileProcessed?: (file?: File) => void;
  showSlider?: boolean;
  hideDownload?: boolean;
  hideDelete?: boolean;
}

/**
 * UploadFile component. Takes care of opening the file manager, sending the file
 * to the File Manager service and returning the file ID.
 */
export default function UploadFile({
  label,
  description,
  accept = 'application/pdf,image/jpeg,image/png,image/jpg',
  value,
  error: formError,
  onChange = noop,
  onFileProcessed = noop,
  showSlider = true,
  hideDownload = false,
  hideDelete = false,
}: UploadFileProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { uploadFile } = useFileManager();

  /**
   * Makes the call to the FileModule API.
   *
   * @param {File} file
   */
  const sendFileToApi = async (file: File) => {
    const contents = await toBase64(file);
    const remoteId = await uploadFile({ fileName: file.name, contents });

    return remoteId;
  };

  /**
   * Handles the file change. Sends the file to the File Manager service.
   */
  async function handleChange(file: File) {
    if (!file) {
      return; // No file selected using OS dialog.
    }

    let error = null;
    let valid = false;

    if (file.size > FILE_UPLOAD_MAX_SIZE) {
      // TODO: Compute this from FILE_UPLOAD_MAX_SIZE.
      error = 'Súbor je príliš veľký. Maximálna veľkosť súboru je 100 MB.';
    } else {
      valid = true;
    }

    // Set error.
    setError(error);

    // Upload the file.
    if (valid) {
      setLoading(true);

      sendFileToApi(file)
        .then((remoteId) => {
          onChange(remoteId);
          onFileProcessed(file);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  if (value) {
    return (
      <Stack spacing={20} pb={20}>
        <P2Regular color="gray.6">{label}</P2Regular>
        <FilePreview
          fileId={value}
          label={label}
          onFileDelete={() => {
            onChange('');
            onFileProcessed(undefined);
          }}
          showSlider={showSlider}
          hideDownload={hideDownload}
          hideDelete={hideDelete}
        />
      </Stack>
    );
  }

  const hasError = error || formError;
  const errorText = hasError ? error || formError : null;

  return (
    <Stack spacing={0}>
      <P2Regular color="gray.6">{label}</P2Regular>
      <Stack pos="relative" spacing={12} p={20} align="flex-start">
        <LoadingOverlay visible={loading} loaderProps={{ size: 'sm' }} />
        <P1Regular color="gray.7">{description}</P1Regular>
        <FileButton onChange={handleChange} accept={accept}>
          {(props) => (
            <Button
              {...props}
              leftIcon={<IconUpload stroke="1.5" />}
              variant={hasError ? 'danger-secondary' : 'tertiary'}
              sx={(theme) => ({ border: hasError ? `1px solid ${theme.colors.red[8]}` : undefined })}
              size="md"
            >
              Pridať súbor
            </Button>
          )}
        </FileButton>
        {hasError && <P4Medium color="red.8">{errorText}</P4Medium>}
      </Stack>
    </Stack>
  );
}
