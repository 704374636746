import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconTypography } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { usePageTypographySettings } from 'pages/revisions-module/template-editor/editors/visual/typography/PageTypographySettingsProvider';

/**
 * Control used to set the page typography.
 */
export default function PageTypography({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();
  const { openPageTypographySettings } = usePageTypographySettings();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconTypography />}
      label="Typografia"
      onClick={() => openPageTypographySettings({ onClose: () => editor?.chain().focus().run() })}
    />
  );
}
