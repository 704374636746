import { Select, SelectProps } from '@mantine/core';
import { ICellEditorParams } from 'ag-grid-community';
import { forwardRef } from 'react';

interface SelectCellEditorProps extends ICellEditorParams {
  columnName: string;
  options: SelectProps['data'];
}

/**
 * Custom cell editor for select input.
 *
 * @see {@link https://www.ag-grid.com/react-data-grid/cell-editors/#custom-components}
 */
const SelectCellEditor = forwardRef<any, SelectCellEditorProps>(
  ({ value, rowIndex, api, stopEditing, columnName, options = [] }: SelectCellEditorProps, ref) => {
    return (
      <Select
        autoFocus
        data={options}
        searchable
        value={value ?? ''}
        onChange={(val) => {
          api.applyTransaction({
            update: [
              {
                ...api.getModel().getRow(rowIndex)?.data,
                [columnName]: val,
              },
            ],
          });
          stopEditing();
        }}
      />
    );
  }
);

export default SelectCellEditor;
