import { NumberInput } from '@mantine/core';
import { IFormInputSpecNumber } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';

/**
 * Preview for a number input.
 */
export default function NumberInputPreview({ spec }: { spec: IFormInputSpecNumber }) {
  const step = useMemo(() => {
    if (spec.precision) {
      return 1 / Math.pow(10, spec.precision);
    }

    return 1;
  }, [spec.precision]);

  return (
    <NumberInput
      required={spec.required}
      readOnly={spec.readOnly}
      min={spec.min}
      max={spec.max}
      precision={spec.precision}
      step={step}
      size="sm"
      rightSection={spec.suffix}
      hideControls
    />
  );
}
