import { useForm } from '@mantine/form';
import { isEmpty, noop, uniq } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import useLoadingAction from 'hooks/use-loading-action';
import { TextInput, SimpleGrid, Group, Anchor, Box, Button, Stack } from '@mantine/core';
import FormInputGroup from 'components/forms/FormInputGroup';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import FormWrapper from 'components/forms/FormWrapper';
import { useEffect, useMemo } from 'react';
import P1Regular from 'components/typography/P1Regular';
import P1RegularLink from 'components/typography/P1RegularLink';
import PersonWithPermissions from 'components/PersonWithPermissions';
import { usePickUsers } from 'components/modals/pick-users-modal/PickUsersProvider';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import Toast from 'components/Toast';
import { DepartmentContactPersonSelect } from 'components/selects/DepartmentContactPersonSelect';
import P2Regular from 'components/typography/P2Regular';

/**
 * The data collected from the form.
 */
export interface DepartmentFormData {
  departmentName: string;
  departmentNumber?: string;
  streetAddress: string;
  city: string;
  zip: string;
  country: string;
  contactPersonId?: string;
  organizationId: string;
  organizationPeople?: {
    [userId: string]: {
      checked: boolean;
      permissions: number[];
      templateName: string;
    };
  };
  people: {
    [userId: string]: {
      checked: boolean;
      deleted: boolean;
      permissions: number[];
      templateName: string;
      isNew: boolean;
    };
  };
}

/**
 * The default initial values.
 */
export const departmentFormInitialValues: DepartmentFormData = {
  departmentName: '',
  departmentNumber: '',
  streetAddress: '',
  city: '',
  zip: '',
  country: '',
  contactPersonId: '',
  organizationId: '',
  organizationPeople: {},
  people: {},
};

/**
 * Parameters of the DepartmentForm.
 */
export interface DepartmentFormProps extends FormProps<DepartmentFormData> {
  readOnly?: Partial<Record<keyof DepartmentFormData, boolean>>;
  notAllowed?: Partial<Record<keyof DepartmentFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for creating and editing department.
 */
export default function DepartmentForm({
  initialValues = departmentFormInitialValues,
  onSubmit = noop,
  readOnly = {},
  notAllowed = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
}: DepartmentFormProps) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const { pickUsers } = usePickUsers();

  const form = useForm<DepartmentFormData>({
    initialValues,
    validate: {
      departmentName: createValidator([required]),
      streetAddress: createValidator([required]),
      city: createValidator([required]),
      zip: createValidator([required]),
      country: createValidator([required]),
      organizationId: createValidator([required]),
    },
  });

  const displayMainAddButton = useMemo(
    () =>
      isEmpty(form.values.organizationPeople) &&
      (isEmpty(form.values.people) ||
        Object.keys(form.values.people)
          .map((userId) => form.values.people[Number(userId)].checked)
          .every((checked) => !checked)),
    [form.values.organizationPeople, form.values.people]
  );

  /**
   * Callback for when user clicks on the "Pridať ľudí" button.
   */
  const addPeopleToDepartment = () => {
    pickUsers({
      title: 'Pridať ľudí do strediska',
      hiddenUsers: uniq([
        ...Object.keys(form.values.people)
          .filter((userId) => form.values.people[Number(userId)].checked)
          .map(Number),
        ...Object.keys(form.values.organizationPeople || {})
          .filter((userId) => form.values.organizationPeople![Number(userId)].checked)
          .map(Number),
      ]),
      onPick: ({ pickedUsers, permissions, templateName }) => {
        pickedUsers.forEach((userId: number) => {
          const oldChecked = form.values.people[userId]?.checked ?? false;
          const newChecked = true;

          form.setFieldValue(`people.${userId}`, {
            checked: newChecked,
            deleted: false,
            permissions,
            templateName,
            isNew: !oldChecked && newChecked,
          });
        });
      },
      inForm: 'department',
      initialTemplateName: 'no-template',
      initialPermissions: [],
      pickUsers: true,
      pickPermissions: true,
    });
  };

  useEffect(() => {
    if (!readOnly.organizationId) {
      form.setFieldValue('contactPersonId', '');
    }
  }, [form.values.organizationId]);

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Organizácia">
        <SimpleGrid cols={2} spacing={40}>
          <OrganizationSelect
            label="Organizácia"
            size="lg"
            placeholder="Vyberte organizáciu"
            readOnly={readOnly.organizationId}
            showDiscarded={false}
            autoSelectSingleResult
            permissionSlug="manage-departments"
            name="amperia-organizationId"
            {...form.getInputProps('organizationId')}
          />
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov strediska"
            size="lg"
            readOnly={readOnly.departmentName}
            name="amperia-departmentName"
            {...form.getInputProps('departmentName')}
          />
          <TextInput
            label="Číslo strediska"
            placeholder="Číslo strediska"
            size="lg"
            readOnly={readOnly.departmentNumber}
            name="amperia-departmentNumber"
            {...form.getInputProps('departmentNumber')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Adresa"
            placeholder="Ulica a číslo"
            size="lg"
            readOnly={readOnly.streetAddress}
            name="051f13c0-6aec-4932-9fb4-ed51732b024f"
            {...form.getInputProps('streetAddress')}
          />
          <TextInput
            label="Mesto"
            placeholder="Mesto"
            size="lg"
            readOnly={readOnly.city}
            name="f56e4b81-dc59-499b-abbb-6df3e78ee0c3"
            {...form.getInputProps('city')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="PSČ"
            placeholder="PSČ"
            size="lg"
            readOnly={readOnly.zip}
            name="1e43089c-650c-4596-af04-5c5d85ccf2be"
            {...form.getInputProps('zip')}
          />
          <TextInput
            label="Štát"
            placeholder="Štát"
            size="lg"
            readOnly={readOnly.country}
            name="630f9799-cecd-40c5-b690-27589690f025"
            {...form.getInputProps('country')}
          />
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup groupTitle="Kontaktná osoba">
        <SimpleGrid cols={2} spacing={40}>
          {form.values.organizationId ? (
            <DepartmentContactPersonSelect
              label="Kontaktná osoba"
              size="lg"
              placeholder="Vyberte kontaktnú osobu"
              organizationId={Number(form.values.organizationId)}
              name="amperia-contactPersonId"
              clearable
              {...form.getInputProps('contactPersonId')}
            />
          ) : (
            <Stack py={16}>
              <P2Regular color="gray.8">Najskôr vyberte organizáciu</P2Regular>
            </Stack>
          )}
        </SimpleGrid>
      </FormInputGroup>

      {readOnly.people ? (
        <FormInputGroup groupTitle="Ľudia v stredisku">
          <Toast
            type="info"
            message="Ľudí v stredisku budete môcť nastaviť až po vytvorení strediska"
            withCloseButton={false}
          />
        </FormInputGroup>
      ) : notAllowed.people ? (
        <FormInputGroup groupTitle="Ľudia v stredisku">
          <Toast type="fail" message="Nemáte oprávnenie spravovať ľudí v stredisku" withCloseButton={false} />
        </FormInputGroup>
      ) : (
        <>
          <FormInputGroup groupTitle="Ľudia v stredisku">
            {isEmpty(form.values.organizationPeople) && displayMainAddButton && (
              <Group pt={26} spacing={8}>
                <P1Regular color="gray.6">Stredisko zatiaľ nemá priradených žiadnych ľudí.</P1Regular>
                <Anchor onClick={addPeopleToDepartment}>
                  <P1RegularLink span color="blue.8">
                    Pridať ľudí
                  </P1RegularLink>
                </Anchor>
              </Group>
            )}

            {!isEmpty(form.values.organizationPeople) && (
              <SimpleGrid cols={2} spacing={40} verticalSpacing={24} pt={26}>
                {Object.keys(form.values.organizationPeople || {}).map((personId) => {
                  const userEntry = form.values.organizationPeople![personId];

                  return (
                    <PersonWithPermissions
                      key={personId}
                      userId={Number(personId)}
                      permissions={userEntry.permissions}
                      templateSlug={userEntry.templateName}
                      inForm="organization"
                      updatePermissionsForPerson={noop}
                      {...form.getInputProps('organizationPeople')}
                      onUserDelete={noop}
                      hideControls
                      displayInfoText
                    />
                  );
                })}
              </SimpleGrid>
            )}

            <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
              {Object.keys(form.values.people).map((personId) => {
                const userEntry = form.values.people[personId];

                return (
                  userEntry.checked && (
                    <PersonWithPermissions
                      key={personId}
                      userId={Number(personId)}
                      permissions={userEntry.permissions}
                      templateSlug={userEntry.templateName}
                      inForm="department"
                      updatePermissionsForPerson={({ permissions, templateName }) => {
                        form.setFieldValue(`people.${personId}.permissions`, permissions);
                        form.setFieldValue(`people.${personId}.templateName`, templateName);
                      }}
                      {...form.getInputProps('people')}
                      onUserDelete={(userId) => {
                        form.setFieldValue(`people.${userId}`, {
                          checked: false,
                          permissions: [],
                          deleted: true,
                          isNew: false,
                        });
                      }}
                    />
                  )
                );
              })}
            </SimpleGrid>
          </FormInputGroup>

          {!displayMainAddButton && (
            <Box pl={40} pb={24}>
              <Button size="md" variant="subtle" leftIcon={<IconPlus stroke="1.5" />} onClick={addPeopleToDepartment}>
                Pridať ľudí
              </Button>
            </Box>
          )}
        </>
      )}
    </FormWrapper>
  );
}
