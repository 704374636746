import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays } from 'date-fns';

export interface RevisionPlanDate {
  date: Date;
  isCurrentMonth: boolean;
}

/**
 * Builds an array of dates for the given month and year.
 */
export function getCalendarDates(year: number, month: number): RevisionPlanDate[] {
  const startDate = startOfWeek(startOfMonth(new Date(year, month - 1)), { weekStartsOn: 1 }); // Monday (1-based)
  const endDate = endOfWeek(endOfMonth(new Date(year, month - 1)), { weekStartsOn: 1 }); // Monday (1-based)

  const dates: RevisionPlanDate[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.push({
      date: currentDate,
      isCurrentMonth: currentDate.getMonth() === month - 1,
    });
    currentDate = addDays(currentDate, 1);
  }

  return dates;
}
