import { InputProps, MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular } from 'theme/typography';

export type TextareaTheme = NonNullable<MantineThemeOverride['components']>['Textarea'];

export const TextareaDefaultProps: Partial<InputProps> = {
  size: 'lg',
  iconWidth: 16,
};

export const Textarea: TextareaTheme = {
  defaultProps: TextareaDefaultProps,
  sizes: {
    lg: (theme) => ({
      input: {
        paddingTop: rem(12),
        paddingBottom: rem(12),
        paddingRight: rem(16),
        paddingLeft: rem(16),
        height: rem(72),
        minHeight: rem(72),
        ...P1Regular,
      },
    }),
    md: (theme) => ({
      input: {
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingRight: rem(12),
        paddingLeft: rem(12),
        height: rem(64),
        minHeight: rem(64),
        ...P1Regular,
      },
    }),
    sm: (theme) => ({
      input: {
        paddingTop: rem(4),
        paddingBottom: rem(4),
        paddingRight: rem(8),
        paddingLeft: rem(8),
        height: rem(56),
        minHeight: rem(56),
        ...P2Regular,
      },
    }),
  },
  styles: (theme) => ({
    input: {
      backgroundColor: theme.white,
      '&:fill': {
        color: theme.colors.blue[8],
      },
      '&:focus': {
        borderColor: theme.colors.blue[8],
        boxShadow: '0px 0px 0px 4px #DBE4FF',
        color: theme.colors.gray[8],
      },
      '&[data-invalid=true]:focus': {
        borderColor: theme.colors.red[8],
        boxShadow: '0px 0px 0px 4px #FFE6E6',
      },
      '&:disabled': {
        borderColor: theme.colors.gray[2],
        backgroundColor: theme.colors.gray[0],
        color: theme.colors.gray[5],
      },
    },
  }),
};
