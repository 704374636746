import { Flex, Group } from '@mantine/core';
import { IconBorderAll, IconCalendar } from '@tabler/icons-react';
import RevisionPlanCalendar from 'components/tables/revision-plan/RevisionPlanCalendar';
import RevisionPlanTable from 'components/tables/revision-plan/RevisionPlanTable';
import P2Medium from 'components/typography/P2Medium';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Component allowing the user to switch between calendar and table view.
 */
function TabBox({
  text,
  isActive,
  icon,
  onClick,
}: {
  text: string;
  isActive: boolean;
  icon: JSX.Element;
  onClick: () => void;
}) {
  return (
    <Group
      py={8}
      px={16}
      spacing={8}
      onClick={onClick}
      sx={() => ({
        cursor: 'pointer',
        borderRadius: '4px',
        backgroundColor: isActive ? 'white' : '',
        ':hover': {
          backgroundColor: 'white',
        },
      })}
    >
      {icon}
      <P2Medium color="gray.8">{text}</P2Medium>
    </Group>
  );
}

/**
 * Page for displaying revisions plans - table or calendar.
 */
export default function RevisionPlansPage() {
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState('table');

  useEffect(() => {
    const tabName = searchParams.get('tab') ?? '';

    if (tabName === 'calendar') {
      setTab('calendar');
    } else {
      setTab('table');
    }
  }, [searchParams.get('tab')]);

  return (
    <DashboardLayout
      title="Sledovanie termínov"
      height="screen"
      withSearch={tab === 'table' ? true : undefined}
      onSearch={setSearch}
      breadcrumbs={[{ title: 'Sledovanie termínov' }]}
      actions={
        <Flex justify="space-between">
          <Flex gap={12}>
            <TabBox
              text="Kalendár"
              icon={<IconCalendar />}
              onClick={() => {
                setSearchParams(undefined);
                setTab('calendar');
              }}
              isActive={tab === 'calendar'}
            />
            <TabBox
              text="Tabuľka"
              icon={<IconBorderAll />}
              onClick={() => {
                setSearchParams(undefined);
                setTab('table');
              }}
              isActive={tab === 'table'}
            />
          </Flex>
        </Flex>
      }
    >
      {tab === 'table' && <RevisionPlanTable search={search} />}
      {tab === 'calendar' && <RevisionPlanCalendar />}
    </DashboardLayout>
  );
}
