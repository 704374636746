import { createStyles, rem } from '@mantine/core';
import { IVisual } from 'pages/revisions-module/template-editor/editors/visual/types';

/** Creates heading styles based on the visual settings. */
export function headingStyles(visual: IVisual, level: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6') {
  const fontSize = visual[`fontSize${level}`];

  return {
    fontSize: `${fontSize}px`,
    lineHeight: visual.lineHeightH,
    fontFamily: visual.fontFamily,
    color: visual.textColor,
  };
}

/** Creates normal text styles based on the visual settings. */
export function normalTextStyles(visual: IVisual) {
  return {
    fontSize: `${visual.fontSizeP}px`,
    lineHeight: visual.lineHeightP,
    fontFamily: visual.fontFamily,
    color: visual.textColor,
  };
}

export const useEditorStyles = createStyles((theme, visual: IVisual) => {
  const { marginTop, marginBottom, marginLeft, marginRight, frame, frameColor, framePadding, frameWidth } = visual;
  const spaceLeft = marginLeft + (frame ? framePadding : 0);

  return {
    root: {
      border: 'none',
    },
    toolbar: {
      zIndex: 10,
      padding: rem(8),
      paddingBottom: 0,
      borderColor: theme.colors.gray[3],
    },
    controlsGroup: {
      gap: rem(2),
      paddingRight: rem(8),
      borderRight: `1px solid ${theme.colors.gray[3]}`,
      marginRight: rem(8),
      '&:last-child': {
        paddingRight: 0,
        borderRight: 'none',
        marginRight: 0,
      },
    },
    content: {
      backgroundColor: theme.colors.gray[0],
      paddingTop: rem(20),
      paddingBottom: rem(20),

      // Zoom
      transformOrigin: 'top center',
      '&:has(> [data-zoom="100"])': { transform: 'scale(1)' },
      '&:has(> [data-zoom="110"])': { transform: 'scale(1.1)' },
      '&:has(> [data-zoom="125"])': { transform: 'scale(1.25)' },
      '&:has(> [data-zoom="150"])': { transform: 'scale(1.5)' },
      '&:has(> [data-zoom="175"])': { transform: 'scale(1.75)' },
      '&:has(> [data-zoom="200"])': { transform: 'scale(2)' },

      '& .ProseMirror': {
        position: 'relative',
        backgroundColor: theme.colors.white,
        boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.2)',
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 0,
        minHeight: '297mm',
        width: '210mm',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 0,

        // Typography
        ...normalTextStyles(visual),
        '& table td, & table th': normalTextStyles(visual),
        '& h1': headingStyles(visual, 'H1'),
        '& h2': headingStyles(visual, 'H2'),
        '& h3': headingStyles(visual, 'H3'),
        '& h4': headingStyles(visual, 'H4'),
        '& h5': headingStyles(visual, 'H5'),
        '& h6': headingStyles(visual, 'H6'),

        // Page break
        '& .page-break, & .loop-block-start, & .loop-block-end': {
          transform: `translateX(calc(-${spaceLeft}mm - 1px))`,
        },

        '& .device-loop .page-break, & .device-loop .loop-block-start, & .device-loop .loop-block-end': {
          transform: `translateX(calc(-${spaceLeft}mm - 1px - 12px))`,
        },

        // Page sections
        '& .page-body': {
          marginLeft: `calc(${marginLeft}mm - 1px)`,
          marginRight: `calc(${marginRight}mm - 1px)`,
          border: `${frameWidth}px solid ${frameColor}`,
          padding: frame ? `${framePadding}mm` : '3mm 0',
        },

        '& .page-footer, & .page-header': {
          paddingLeft: `${marginLeft}mm`,
          paddingRight: `${marginRight}mm`,
        },

        '& .page-header': {
          height: `${marginTop}mm`,
        },

        '& .page-footer': {
          height: `${marginBottom}mm`,
        },
      },
    },
  };
});
