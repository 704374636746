import { Group, Stack } from '@mantine/core';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { IconArrowRight } from '@tabler/icons-react';
import RevisionStatusIcon from 'components/RevisionStatusIcon';
import P3Medium from 'components/typography/P3Medium';
import AutomationRow from 'pages/revisions-module/template-editor/editors/pipeline/AutomationRow';

/**
 * Groups automations by source and target status.
 */
export default function AutomationGroup({ automations }: { automations: IAutomation[] }) {
  if (automations.length === 0) {
    return null;
  }

  const statusSource = automations[0].statusSource;
  const statusTarget = automations[0].statusTarget;

  return (
    <Stack spacing={8}>
      <Group spacing={8} align="center">
        <RevisionStatusIcon statusId={statusSource} />
        <IconArrowRight />
        <RevisionStatusIcon statusId={statusTarget} />
        <P3Medium>({automations.length})</P3Medium>
      </Group>

      <Stack spacing={0}>
        {automations.map((automation, index) => (
          <AutomationRow key={automation.id} data={automation} index={index} />
        ))}
      </Stack>
    </Stack>
  );
}
