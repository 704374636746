import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import FixDeviceFaultModal from 'components/modals/fix-device-fault/FixDeviceFaultModal';

/**
 * Callback for when user selects the list of users and their permissions.
 */
type OnFaultFixCallback = (value: { fixedAt: string; fixedNote?: string }) => void;

/**
 * Parameters of the faultFix function.
 */
interface FaultFixParams {
  onFaultFix: OnFaultFixCallback;
}

/**
 * Function for marking the device's fault as fixed.
 */
type FixFault = (params: FaultFixParams) => void;

/**
 * Interface of the FixDeviceFaultContext.
 */
interface IFixDeviceFaultContext {
  faultFix: FixFault;
}

/**
 * Context for marking the device's fault as fixed.
 */
const FixDeviceFaultContext = createContext<IFixDeviceFaultContext>(undefined!);

/**
 * Provider for marking the device's fault as fixed.
 */
export function FixDeviceFaultProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<FaultFixParams | undefined>(undefined);

  /**
   * Opens the modal for marking the device's fault as fixed.
   */
  const faultFix: FixFault = (params) => {
    setParams(params);
    open();
  };

  return (
    <FixDeviceFaultContext.Provider value={{ faultFix }}>
      {children}
      {opened && <FixDeviceFaultModal opened onClose={close} {...params!} />}
    </FixDeviceFaultContext.Provider>
  );
}

/**
 * Hook for marking the device's fault as fixed.
 */
export function useFixDeviceFault() {
  return useContext(FixDeviceFaultContext);
}
