import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceImportPayload } from 'api/actions/device-import/device-import-payload';
import { DeviceImportPathParameters } from 'api/actions/device-import/device-import-path-parameters';
import { DeviceImportResponse } from 'api/actions/device-import/device-import-response';

type DeviceImportQueryParameters = Record<string, any>;

/**
 * The path of the DeviceImport action.
 */
export const deviceImportActionPath = new SweetPath<keyof DeviceImportPathParameters>('/api/device/import', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceImport actions.
 */
export default function createDeviceImportAction(axios: AxiosInstance) {
  return function deviceImportAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: DeviceImportQueryParameters;
    payload: DeviceImportPayload;
  }) {
    const path = deviceImportActionPath.original;

    return axios.post<DeviceImportResponse, DeviceImportResponse, DeviceImportPayload>(path, payload);
  };
}
