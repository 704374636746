import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentListPathParameters } from 'api/actions/department-list/department-list-path-parameters';
import { DepartmentListResponse } from 'api/actions/department-list/department-list-response';

type DepartmentListQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentList action.
 */
export const departmentListActionPath = new SweetPath<keyof DepartmentListPathParameters>('/api/department/list', {
  path: '{param}',
});

/**
 * Creates a contextualized DepartmentList actions.
 */
export default function createDepartmentListAction(axios: AxiosInstance) {
  return function departmentListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DepartmentListQueryParameters } = {}) {
    const path = departmentListActionPath.original;

    return axios.get<DepartmentListResponse, DepartmentListResponse>(path, { params: query, headers });
  };
}
