import { GridApi } from 'ag-grid-community';
import { OrganizationSelect } from 'components/selects/OrganizationSelect';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'assignedBy.organizationId' as const;

/**
 * Parameters of the AssignedBySelectFilter component.
 */
export interface AssignedBySelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the assignedBy column.
 *
 * This filter assumes:
 *   - column field is `assignedBy.organizationId`
 *   - context contains `initialFilters` with `assignedBy.organizationId` filter
 */
export default function AssignedBySelectFilter({ api, context }: AssignedBySelectFilterProps) {
  const [value, setValue] = useState<string | null>(String(context?.initialFilters?.[FILTER_KEY]?.filter));

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'equals', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <OrganizationSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky organizácie"
      value={value}
      onChange={onChange}
    />
  );
}
