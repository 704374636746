import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';
import { useApi } from 'api/api-context';

/**
 * Fetches devices from API.
 */
function useDeviceSelect({
  departmentId,
  deviceSubtypeId,
  showDiscarded = true,
}: { departmentId?: number; deviceSubtypeId?: number[]; showDiscarded?: boolean } = {}) {
  const { getAction } = useApi();

  const fetchDevices = useCallback(async () => {
    const action = getAction('DeviceList');

    const devices = await action({
      query: {
        filters: {
          'departmentId.eq': departmentId === -1 ? undefined : departmentId,
          'deviceSubtypeId.in': deviceSubtypeId,
          'showDiscarded.eq': showDiscarded !== undefined && showDiscarded ? 1 : 0,
        },
      },
    });

    const filtered = devices.map((device) => ({
      label: (device.status ? '' : ' [VYRADENÉ] ') + device.deviceName,
      value: String(device.deviceId),
    }));

    return filtered;
  }, [getAction, departmentId, deviceSubtypeId]);

  return { fetchDevices };
}

interface AdditionalProps {
  departmentId?: number;
  showDiscarded?: boolean;
}

/**
 * Parameters of the DeviceSelect component.
 */
type DeviceSelectProps = DataSelectInheritedProps & AdditionalProps & { deviceSubtypeId?: number };

/**
 * Select for devices.
 */
export function DeviceSelect({ departmentId, deviceSubtypeId, showDiscarded, ...props }: DeviceSelectProps) {
  const key = useMemo(() => nanoid(), [departmentId, deviceSubtypeId, showDiscarded]);

  const { fetchDevices } = useDeviceSelect({
    departmentId,
    deviceSubtypeId: deviceSubtypeId ? [deviceSubtypeId] : undefined,
    showDiscarded,
  });

  return <DataSelect key={key} valueProp="value" labelProp="label" action={fetchDevices} {...props} />;
}
