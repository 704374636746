import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import { useCallback, useMemo, useState } from 'react';
import DataTable from 'components/tables/DataTable';
import { ADD_DEVICE_SUBTYPE_PAGE_PATH } from 'routes/paths';
import ActionsColumn from 'components/tables/device-subtypes/columns/ActionsColumn';
import DeviceSubtypeIdColumn from 'components/tables/device-subtypes/columns/DeviceSubtypeIdColumn';
import DeviceSubtypeNameColumn from 'components/tables/device-subtypes/columns/DeviceSubtypeNameColumn';
import DescriptionColumn from 'components/tables/device-subtypes/columns/DescriptionColumn';
import RestrictionsColumn from 'components/tables/device-subtypes/columns/RestrictionsColumn';
import { DeviceListTypesResponse } from 'api/actions/device-list-types/device-list-types-response';
import ThreeRestrictionsSelectFilter from 'components/tables/filters/restriction/ThreeRestrictionsSelectFilter';
import { useSearchParams } from 'react-router-dom';
import { DeviceSubtypeRow } from 'components/tables/device-subtypes/types';

const columns: ColDef[] = [
  {
    field: 'checkbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressMovable: true,
    width: 44,
    minWidth: 44,
    maxWidth: 44,
    cellStyle: ({ data: { status } }: { data: DeviceSubtypeRow }) => ({ opacity: status ? 1 : 0.6 }),
  },
  {
    field: 'deviceTypeId',
    headerName: 'ID',
    sortable: true,
    filter: true,
    unSortIcon: true,
    suppressMovable: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellRenderer: DeviceSubtypeIdColumn,
  },
  {
    field: 'deviceTypeName',
    headerName: 'Názov',
    minWidth: 350,
    sortable: true,
    resizable: true,
    filter: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceSubtypeNameColumn,
  },
  {
    field: 'description',
    headerName: 'Popis',
    minWidth: 350,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DescriptionColumn,
  },
  {
    field: 'restriction',
    headerName: 'Vymedzenia',
    minWidth: 550,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: ThreeRestrictionsSelectFilter,
    cellRenderer: RestrictionsColumn,
  },
  {
    valueGetter: ({ data: { status } }: { data: DeviceSubtypeRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívny',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Parameters of the DeviceSubtypesTable component.
 */
export interface DeviceSubtypesTableProps {
  deviceTypeId: number;
  search?: string;
  showDiscarded?: boolean;
}

/**
 * Returns the restriction for the device subtype.
 */
function getDeviceSubtypeRestriction(subtype: DeviceListTypesResponse[number]): string {
  if (subtype.isGlobal) {
    return 'global';
  }

  if (subtype.isOrganization) {
    return 'organization';
  }

  if (subtype.isDepartment) {
    return 'department';
  }

  return 'unknown';
}

/**
 * Table which lists device subtypes based on the parent device type.
 */
export default function DeviceSubtypesTable({
  deviceTypeId,
  search = '',
  showDiscarded = false,
}: DeviceSubtypesTableProps) {
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();

  const [initialFilters] = useState(() => ({
    deviceTypeId: {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('organizationId') ?? '',
      deviceTypeId,
    },
  }));

  const additionalFilters = useMemo(() => {
    if (!showDiscarded) {
      return {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      };
    }

    return { status: null };
  }, [showDiscarded]);

  const action = useCallback(async () => {
    const action = getAction('DeviceListTypes');
    const subtypes = await action({
      query: {
        filters: {
          'parentDeviceTypeId.eq': deviceTypeId,
          'listSubtypes.eq': 1,
        },
      },
    });

    return subtypes.map((subtype) => ({
      ...subtype,
      restriction: getDeviceSubtypeRestriction(subtype),
    }));
  }, [getAction, deviceTypeId]);

  return (
    <DataTable
      columns={columns}
      action={action}
      addButtonText="Vytvoriť nový typ zariadenia"
      addButtonTarget={ADD_DEVICE_SUBTYPE_PAGE_PATH.insert({ deviceTypeId })}
      search={search}
      initialFilters={initialFilters}
      additionalFilters={additionalFilters}
      dataExport={{
        modalTitle: 'Exportovať typy',
        fileName: 'typy-zariadeni.xlsx',
        columnKeys: ['deviceTypeId', 'deviceTypeName', 'description', 'restriction', 'status'],
      }}
    />
  );
}
