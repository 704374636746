import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignRight } from '@tabler/icons-react';

/**
 * Used to align text to the right.
 */
export default function ControlAlignRight() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Align right" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('right').run()}
        active={editor?.isActive({ textAlign: 'right' })}
      >
        <IconAlignRight size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
