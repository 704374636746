import { Box, Group, Stack, Tooltip, useMantineTheme } from '@mantine/core';
import Toast from 'components/Toast';
import { IFormInput } from 'pages/revisions-module/template-editor/editors/form/types';
import TextInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/TextInputPreview';
import NumberInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/NumberInputPreview';
import DateInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/DateInputPreview';
import CheckboxInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/CheckboxInputPreview';
import SelectInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/SelectInputPreview';
import WysiwygInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/WysiwygInputPreview';
import PredefinedSelectInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/PredefinedSelectInputPreview';
import PredefinedListInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/PredefinedListInputPreview';
import MeasuringDeviceInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/MeasuringDeviceInputPreview';
import CertificateInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/CertificateInputPreview';
import QualificationInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/QualificationInputPreview';
import PeriodInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/PeriodInputPreview';
import SafetyPointInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/SafetyPointInputPreview';
import DevicePropertyInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/DevicePropertyInputPreview';
import SumInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/SumInputPreview';
import DatePlusPeriodInputPreview from 'pages/revisions-module/template-editor/editors/form/input/previews/DatePlusPeriodInputPreview';
import P3Regular from 'components/typography/P3Regular';
import { IconCopyOff, IconExclamationCircle, IconEyeOff, IconPencilOff } from '@tabler/icons-react';
import { INPUT_TYPE_NAMES } from 'pages/revisions-module/template-editor/editors/form/configurator/InputTypeSelect';
import { useMemo } from 'react';
import P3Medium from 'components/typography/P3Medium';
import { noop } from 'lodash';

type TypeCond<T, U> = [T] extends [never] ? { message: U } : {};

/**
 * The discriminator of the form input preview.
 */
function FormInputPreviewDiscriminator({ data }: { data: IFormInput }) {
  switch (data.spec.type) {
    case 'text':
      return <TextInputPreview spec={data.spec} />;

    case 'number':
      return <NumberInputPreview spec={data.spec} />;

    case 'date':
      return <DateInputPreview spec={data.spec} />;

    case 'checkbox':
      return <CheckboxInputPreview spec={data.spec} />;

    case 'certificate':
      return <CertificateInputPreview spec={data.spec} />;

    case 'qualification':
      return <QualificationInputPreview spec={data.spec} />;

    case 'select':
      return <SelectInputPreview spec={data.spec} />;

    case 'wysiwyg':
      return <WysiwygInputPreview spec={data.spec} />;

    case 'predefinedSelect':
      return <PredefinedSelectInputPreview spec={data.spec} />;

    case 'predefinedList':
      return <PredefinedListInputPreview spec={data.spec} />;

    case 'measuringDevice':
      return <MeasuringDeviceInputPreview spec={data.spec} />;

    case 'period':
      return <PeriodInputPreview spec={data.spec} />;

    case 'safetyPoint':
      return <SafetyPointInputPreview spec={data.spec} />;

    case 'deviceProperty':
      return <DevicePropertyInputPreview spec={data.spec} />;

    case 'sum':
      return <SumInputPreview spec={data.spec} />;

    case 'datePlusPeriod':
      return <DatePlusPeriodInputPreview spec={data.spec} />;

    default:
      // This is a TS trick to make sure all types are handled in the switch.
      // If there is a new type, the compiler will complain here. To fix it, add a new case to the switch.
      const { message }: TypeCond<typeof data.spec, string> = { message: 'Neznámy typ poľa' };

      return (
        <Box mr={32}>
          <Toast fullWidth type="fail" withCloseButton={false} message={message} />
        </Box>
      );
  }
}

interface FormInputPreviewLabelProps {
  data: IFormInput;
  draggable: boolean;
  onDragStart?: (e: React.DragEvent) => void;
  onDragEnd?: (e: React.DragEvent) => void;
}

/**
 * The label of a form input preview.
 */
export function FormInputPreviewLabel({
  data,
  draggable,
  onDragStart = noop,
  onDragEnd = noop,
}: FormInputPreviewLabelProps) {
  const theme = useMantineTheme();

  const typeName = useMemo(() => INPUT_TYPE_NAMES[data.spec.type].label, [data.spec.type]);

  return (
    <Group spacing={4} pr={32}>
      {!data.deletable && (
        <Tooltip label="Pole sa musí nachádzať vo formulári" withArrow={false} openDelay={200}>
          <IconExclamationCircle size={16} color={theme.colors.gray[6]} />
        </Tooltip>
      )}
      {!data.spec.copyable && (
        <Tooltip label="Údaj sa nekopíruje" withArrow={false} openDelay={200}>
          <IconCopyOff size={16} color={theme.colors.gray[6]} />
        </Tooltip>
      )}
      {data.spec.readOnly && (
        <Tooltip label="Údaj iba na čítanie" withArrow={false} openDelay={200}>
          <IconPencilOff size={16} color={theme.colors.gray[6]} />
        </Tooltip>
      )}
      {data.spec.hidden && (
        <Tooltip label="Skryté pole" withArrow={false} openDelay={200}>
          <IconEyeOff size={16} color={theme.colors.gray[6]} />
        </Tooltip>
      )}
      <Group
        spacing={4}
        draggable={draggable}
        className={draggable ? 'cursor-grab' : ''}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <P3Medium color="gray.8">{data.spec.label}</P3Medium>
        {data.spec.required && <P3Regular color="red.8">*</P3Regular>}
        <P3Regular color="gray.6">&mdash; {typeName}</P3Regular>
      </Group>
    </Group>
  );
}

interface FormInputPreviewProps {
  data: IFormInput;
  draggable: boolean;
  onDragStart?: (e: React.DragEvent) => void;
  onDragEnd?: (e: React.DragEvent) => void;
}

/**
 * The preview of a form input.
 */
export default function FormInputPreview({ data, draggable, onDragStart, onDragEnd }: FormInputPreviewProps) {
  return (
    <Stack spacing={4} w="100%">
      <FormInputPreviewLabel
        data={data}
        draggable={draggable}
        onDragStart={draggable ? onDragStart : noop}
        onDragEnd={draggable ? onDragEnd : noop}
      />
      <FormInputPreviewDiscriminator data={data} />
    </Stack>
  );
}
