import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { MeasuringDeviceDeletePathParameters } from 'api/actions/measuring-device-delete/measuring-device-delete-path-parameters';
import { MeasuringDeviceDeleteResponse } from 'api/actions/measuring-device-delete/measuring-device-delete-response';

type MeasuringDeviceDeleteQueryParameters = Record<string, any>;

/**
 * The path of the MeasuringDeviceDelete action.
 */
export const measuringDeviceDeleteActionPath = new SweetPath<keyof MeasuringDeviceDeletePathParameters>(
  '/api/measuring-device/delete/{measuringDeviceId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized MeasuringDeviceDelete actions.
 */
export default function createMeasuringDeviceDeleteAction(axios: AxiosInstance) {
  return function measuringDeviceDeleteAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: MeasuringDeviceDeletePathParameters;
    query?: MeasuringDeviceDeleteQueryParameters;
  }) {
    const path = measuringDeviceDeleteActionPath.insert(parameters);

    return axios.post<MeasuringDeviceDeleteResponse, MeasuringDeviceDeleteResponse>(path, { params: query, headers });
  };
}
