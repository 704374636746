import { Box, ThemeIcon, rem, useMantineTheme } from '@mantine/core';
import { IconCheck, IconExclamationCircle, IconInfoCircle, IconX } from '@tabler/icons-react';
import { noop } from 'lodash';
import { P2Medium, P4Medium } from 'theme/typography';

/**
 * The type of the toast.
 */
export type ToastType = 'success' | 'info' | 'warning' | 'fail';

/**
 * The parameters of the toast.
 */
export interface ToastParams {
  type: ToastType;
  message: string | React.ReactNode;
  size?: 's' | 'm' | 'l';
  fullWidth?: boolean;
  withCloseButton?: boolean;
  opened?: boolean;
  onClose?: () => void;
}

const iconSize = {
  l: 32,
  m: 24,
  s: 16,
};

/**
 * Toast for displaying messages.
 *
 * @see {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=233-8255&mode=dev Figma Design}
 */
export default function Toast({
  type,
  message,
  size = 'l',
  fullWidth = false,
  withCloseButton = true,
  opened = true,
  onClose = noop,
}: ToastParams) {
  const theme = useMantineTheme();

  const textSize = size === 's' ? P4Medium : P2Medium;

  const toastStyles = {
    paddingTop: size === 'l' ? rem(12) : rem(8),
    paddingBottom: size === 'l' ? rem(12) : rem(8),
    paddingLeft: rem(12),
    paddingRight: rem(12),
    borderRadius: rem(4),
    border: '1px solid',
    ...textSize,
    width: 'fit-content',
    display: 'flex',
    gap: size === 'l' ? rem(12) : rem(8),
    alignItems: 'center',
  };

  let backgroundColor, color, borderColor, icon;

  switch (type) {
    case 'success':
      backgroundColor = theme.colors.green[1];
      color = theme.colors.green[9];
      borderColor = theme.colors.green[7];
      icon = <IconCheck size={16} radius={16} color={theme.colors.gray[0]} stroke={3} />;
      break;

    case 'info':
      backgroundColor = theme.colors.blue[1];
      color = theme.colors.blue[9];
      borderColor = theme.colors.blue[7];
      icon = <IconInfoCircle size={24} radius={16} color={theme.colors.gray[0]} />;
      break;

    case 'warning':
      backgroundColor = '#FFE8DB';
      color = '#C76B36';
      borderColor = '#EB8042';
      icon = <IconExclamationCircle size={24} radius={16} color={theme.colors.gray[0]} />;
      break;

    case 'fail':
      backgroundColor = theme.colors.red[1];
      color = theme.colors.red[9];
      borderColor = theme.colors.red[7];
      icon = <IconX size={16} radius={16} color={theme.colors.gray[0]} stroke={3} />;
      break;

    default:
      backgroundColor = theme.colors.gray[1];
      color = theme.colors.gray[9];
      borderColor = theme.colors.gray[7];
  }

  const toastAdditionalStyles = {
    backgroundColor,
    color,
    borderColor,
  };

  if (!opened) {
    return <></>;
  }

  return (
    <Box w={fullWidth ? '100%' : undefined} sx={{ ...toastStyles, ...toastAdditionalStyles }}>
      <ThemeIcon color={borderColor} radius={16} size={iconSize[size]}>
        {icon}
      </ThemeIcon>
      {message}
      {withCloseButton && (
        <Box w={16} h={16} ml="auto">
          <IconX style={{ cursor: 'pointer' }} onClick={onClose} size={16} color={color} stroke={3} />
        </Box>
      )}
    </Box>
  );
}
