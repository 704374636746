import { LoadingOverlay, Overlay } from '@mantine/core';
import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

interface PDFDisplayProps {
  fileId: string;
  fileName: string;
  isResizing?: boolean;
}

/**
 * Displays PDF in <iframe>.
 */
export default function PDFDisplay({ fileId, fileName, isResizing = false }: PDFDisplayProps) {
  const { createAccessToken } = useFileManager();
  const [url, setUrl] = useState('');
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(true);

  useEffect(() => {
    startLoading();

    createAccessToken({ fileId })
      .then((token) => setUrl(`/api/files/one-time/file/${token}/${encodeURIComponent(fileName)}`))
      .catch(panic);
  }, [fileId]);

  return (
    <Group position="center" w="100%" h="100%" pos="relative">
      <LoadingOverlay visible={loading} />
      {isResizing && <Overlay opacity={0} />}
      {url && <iframe src={url} width="100%" height="100%" title={fileName} onLoad={stopLoading} />}
    </Group>
  );
}
