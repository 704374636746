import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserChangePasswordPayload } from 'api/actions/user-change-password/user-change-password-payload';
import { UserChangePasswordPathParameters } from 'api/actions/user-change-password/user-change-password-path-parameters';
import { UserChangePasswordResponse } from 'api/actions/user-change-password/user-change-password-response';

type UserChangePasswordQueryParameters = Record<string, any>;

/**
 * The path of the UserChangePassword action.
 */
export const userChangePasswordActionPath = new SweetPath<keyof UserChangePasswordPathParameters>(
  '/api/user/change-password',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserChangePassword actions.
 */
export default function createUserChangePasswordAction(axios: AxiosInstance) {
  return function userChangePasswordAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UserChangePasswordQueryParameters;
    payload: UserChangePasswordPayload;
  }) {
    const path = userChangePasswordActionPath.original;

    return axios.post<UserChangePasswordResponse, UserChangePasswordResponse, UserChangePasswordPayload>(path, payload);
  };
}
