import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconCode } from '@tabler/icons-react';
import { useHtmlEditor } from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/html-block/use-html-editor';
import { useCallback } from 'react';
import SimpleControl from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { fromByteArray, toByteArray } from 'base64-js';

/**
 * Control for inserting and editing HTML blocks.
 */
export default function HtmlBlockControl() {
  const { editor } = useRichTextEditorContext();
  const editHtml = useHtmlEditor();

  const encodeBase64 = useCallback((str: string) => fromByteArray(new TextEncoder().encode(str)), []);
  const decodeBase64 = useCallback((str: string) => new TextDecoder().decode(toByteArray(str)), []);

  const edit = useCallback(
    () =>
      editHtml({
        initialContent: decodeBase64(editor?.getAttributes('htmlBlock')['data-content'] || ''),
        onSubmit: (content) => editor?.chain().focus().setHtmlBlockContent(encodeBase64(content)).run(),
      }),
    [editor, editHtml]
  );

  const insert = useCallback(() => editor?.chain().focus().insertHtmlBlock().run(), [editor]);

  const isActive = editor?.isActive('htmlBlock');

  return (
    <SimpleControl
      kind="button"
      icon={<IconCode />}
      label="HTML"
      onClick={isActive ? edit : insert}
      active={isActive}
    />
  );
}
