import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import { noop } from 'lodash';
import { Button, Divider, Group, PasswordInput, Stack, TextInput, Image, Checkbox } from '@mantine/core';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { AuthLoginPayload } from 'api/actions/auth-login/auth-login-payload';
import { Link } from 'react-router-dom';
import { RESET_PASSWORD_PAGE_PATH } from 'routes/paths';
import P2RegularLink from 'components/typography/P2RegularLink';
import { IconChevronRight } from '@tabler/icons-react';
import Toast from 'components/Toast';

/**
 * Form for user login.
 */
export default function LoginForm({
  initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  },
  onSubmit = noop,
  message,
}: FormProps<AuthLoginPayload> = {}) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<AuthLoginPayload>({
    initialValues,
    validate: {
      email: createValidator([required, emailFormat]),
      password: createValidator([required]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack p={24}>
        <Image src="img/fmPoint_logo.svg" width={154} />
      </Stack>
      <Divider my={0} color="gray.1" />
      <Stack p={24} pb={12}>
        <TextInput
          size="lg"
          withAsterisk
          label="Email"
          placeholder="Zadajte váš e-mail"
          name="amperia-email"
          {...form.getInputProps('email')}
        />
      </Stack>
      <Stack spacing={12} p={24} pt={12}>
        <PasswordInput
          size="lg"
          withAsterisk
          label="Heslo"
          placeholder="Zadajte vaše heslo"
          name="amperia-password"
          {...form.getInputProps('password')}
        />
        <Group position="apart">
          <Checkbox
            size="sm"
            label="Zapamätať prihlásenie"
            name="amperia-rememberMe"
            {...form.getInputProps('rememberMe')}
          />
          <Link to={RESET_PASSWORD_PAGE_PATH.original}>
            <P2RegularLink>Zabudol som heslo</P2RegularLink>
          </Link>
        </Group>
        {message && <Toast {...message} opened fullWidth withCloseButton={false} />}
      </Stack>
      <Divider my={0} color="gray.1" />
      <Stack p={24}>
        <Button
          loading={loading}
          type="submit"
          size="lg"
          fullWidth
          rightIcon={<IconChevronRight stroke="1.5" width={24} height={24} />}
        >
          Prihlásiť sa
        </Button>
      </Stack>
    </form>
  );
}
