import { Box, Button, Checkbox, Divider, Flex, Group, Modal, ScrollArea, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApi } from 'api/api-context';
import P2Regular from 'components/typography/P2Regular';
import { groupBy, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import panic from 'errors/panic';
import { DeviceRevisionGetOptionsResponse } from 'api/actions/device-revision-get-options/device-revision-get-options-response';
import P1Medium from 'components/typography/P1Medium';
import P2Medium from 'components/typography/P2Medium';
import P3Regular from 'components/typography/P3Regular';

interface PickDevicesModalParams {
  title: string;
  hiddenDevices: number[];
  opened: boolean;
  deviceTypeId: number;
  departmentId: number;
  onClose: () => void;
  onPick: (value: {
    pickedDevices: {
      [deviceId: number]: {
        deviceName: string;
        term: string;
        deviceSubtypeId: number;
        deviceSubtypeName: string;
        checked: boolean;
        manufacturer?: string;
        type: string;
        serialNumber?: string;
        manufactured?: number;
        internalPossessionsNumber?: string;
        building?: string;
        location?: string;
        organization?: {
          organizationId: number;
          organizationName: string;
        };
        revisionPlan?: {
          revisionPlanId: number;
          description: string;
          date?: string;
          revisionPeriod: number;
          neverExecuted: boolean;
        }[];
      };
    };
  }) => void;
}

type FormValues = {
  pickedDevices: {
    [deviceId: number]: {
      deviceName: string;
      term: string;
      deviceSubtypeId: number;
      deviceSubtypeName: string;
      checked: boolean;
      manufacturer?: string;
      type: string;
      serialNumber?: string;
      manufactured?: number;
      internalPossessionsNumber?: string;
      building?: string;
      floor?: string;
      room?: string;
      location?: string;
      organization?: {
        organizationId: number;
        organizationName: string;
      };
      revisionPlan?: {
        revisionPlanId: number;
        description: string;
        date?: string;
        revisionPeriod: number;
        neverExecuted: boolean;
      }[];
    };
  };
};

type Device = DeviceRevisionGetOptionsResponse[number];

/**
 * Modal for selecting devices.
 */
export default function PickDevicesModal({
  title,
  opened,
  hiddenDevices,
  deviceTypeId,
  departmentId,
  onClose,
  onPick,
}: PickDevicesModalParams) {
  const [devices, setDevices] = useState<Device[]>([]);
  const [groupedDevices, setGroupedDevices] = useState<{ [deviceSubtypeId: number]: Device[] }>({});

  const { getAction } = useApi();

  const form = useForm<FormValues>({
    initialValues: {
      pickedDevices: {},
    },
  });

  /**
   * Submits the form.
   */
  function submit({ pickedDevices }: FormValues) {
    onPick({ pickedDevices });
    onClose();
  }

  useEffect(() => {
    const revisionDevicesGetListAction = getAction('DeviceRevisionGetOptions');

    revisionDevicesGetListAction({
      parameters: { departmentId: String(departmentId), deviceTypeId: String(deviceTypeId) },
    })
      .then((devices) => {
        const filtered = devices.filter((device) => !hiddenDevices.includes(device.deviceId));

        const reduced = filtered.reduce(
          (acc, curr) => {
            acc[curr.deviceId] = {
              deviceName: curr.deviceName,
              term: '',
              deviceSubtypeId: curr.deviceSubtypeId,
              deviceSubtypeName: curr.deviceSubtypeName,
              manufacturer: curr.manufacturer,
              type: curr.type,
              serialNumber: curr.serialNumber,
              manufactured: curr.manufactured,
              checked: false,
              internalPossessionsNumber: curr.internalPossessionsNumber ?? '',
              building: curr.building ?? '',
              floor: curr.floor ?? '',
              room: curr.room ?? '',
              location: curr.location ?? '',
              organization: curr.organization
                ? {
                    organizationId: curr.organization.organizationId,
                    organizationName: curr.organization.organizationName,
                  }
                : undefined,
              revisionPlan: (curr.revisionPlan || []).map((rp) => ({
                revisionPlanId: rp.revisionPlanId!,
                description: rp.description,
                date: rp.date,
                revisionPeriod: rp.revisionPeriod,
                neverExecuted: rp.neverExecuted,
              })),
            };
            return acc;
          },
          {} as {
            [deviceId: number]: {
              deviceName: string;
              term: string;
              deviceSubtypeId: number;
              deviceSubtypeName: string;
              checked: boolean;
              manufacturer?: string;
              type: string;
              serialNumber?: string;
              manufactured?: number;
              internalPossessionsNumber?: string;
              building?: string;
              floor?: string;
              room?: string;
              location?: string;
              organization?: {
                organizationId: number;
                organizationName: string;
              };
              revisionPlan?: {
                revisionPlanId: number;
                description: string;
                date?: string;
                revisionPeriod: number;
                neverExecuted: boolean;
              }[];
            };
          }
        );

        const grouped = groupBy(filtered, 'deviceSubtypeId');

        form.setFieldValue('pickedDevices', reduced);

        setDevices(filtered);
        setGroupedDevices(grouped);
      })
      .catch(panic);
  }, [getAction, departmentId, deviceTypeId, hiddenDevices]);

  return (
    <Modal opened={opened} onClose={onClose} title={title} centered>
      <Divider color="gray.1" mb={24} />
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={24}>
          <ScrollArea h={410}>
            <Stack spacing={24} pb={24}>
              {Object.keys(groupedDevices).map((deviceTypeId) => {
                const devices = groupedDevices[Number(deviceTypeId)];
                const deviceSubtypeName = devices[0].deviceSubtypeName;

                return (
                  <Stack spacing={16} key={deviceSubtypeName}>
                    <P1Medium color="gray.9">{deviceSubtypeName}</P1Medium>
                    <Flex gap={24}>
                      <Box w={4} bg="gray.1" />
                      <Stack w="100%" spacing={16}>
                        {devices.map((device) => (
                          <Stack spacing={0} key={device.deviceId}>
                            <Checkbox
                              key={device.deviceId}
                              label={
                                <Stack spacing={0}>
                                  <P2Medium mb={-2}>{device.deviceName}</P2Medium>
                                  <Group spacing={4} pl={2}>
                                    <P3Regular color="gray.7">ID: {device.deviceId}</P3Regular>
                                    {device.serialNumber && (
                                      <>
                                        <P3Regular color="gray.7">•</P3Regular>
                                        <P3Regular color="gray.7">VČ: {device.serialNumber}</P3Regular>
                                      </>
                                    )}
                                    {device.building && (
                                      <>
                                        <P3Regular color="gray.7">•</P3Regular>
                                        <P3Regular color="gray.7">{device.building}</P3Regular>
                                      </>
                                    )}
                                  </Group>
                                </Stack>
                              }
                              size="lg"
                              form="pick-devices-modal"
                              name={`amperia-pickedDevices.${device.deviceId}.checked`}
                              {...form.getInputProps(`pickedDevices.${device.deviceId}.checked`, { type: 'checkbox' })}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </Flex>
                  </Stack>
                );
              })}
              {isEmpty(devices) && <P2Regular>Neboli nájdené žiadne zariadenia.</P2Regular>}
            </Stack>
          </ScrollArea>
        </Stack>
        {/* TODO find a proper place to display this error */}
        <P2Regular color="red.9">{form.errors.pickedDevices}</P2Regular>

        <Group spacing={40} position="apart" mt={24}>
          <Button variant="subtle-gray" type="button" onClick={onClose}>
            Zrušiť
          </Button>
          <Button
            variant="primary"
            size="md"
            type="submit"
            disabled={isEmpty(devices) || Object.values(form.values.pickedDevices).every((device) => !device.checked)}
          >
            Pridať
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
