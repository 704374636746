import { ICellEditorParams } from 'ag-grid-community';
import { DateInput } from 'components/inputs/DateInput';
import { forwardRef } from 'react';

interface DateInputCellEditorProps extends ICellEditorParams {
  columnName: string;
}

/**
 * Custom cell editor for Date input.
 *
 * @see {@link https://www.ag-grid.com/react-data-grid/cell-editors/#custom-components}
 */
const DateInputCellEditor = forwardRef<any, DateInputCellEditorProps>(
  ({ value, rowIndex, api, stopEditing, columnName }: DateInputCellEditorProps, ref) => {
    return (
      <DateInput
        autoFocus
        value={value ?? ''}
        onChange={(val) => {
          api.applyTransaction({
            update: [
              {
                ...api.getModel().getRow(rowIndex)?.data,
                [columnName]: val,
              },
            ],
          });
          stopEditing();
        }}
      />
    );
  }
);

export default DateInputCellEditor;
