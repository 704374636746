import { IFormInputSpecSum } from 'pages/revisions-module/template-editor/editors/form/types';
import { NumberInput } from '@mantine/core';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useEffect, useState } from 'react';
import { useCounter, useInterval } from '@mantine/hooks';

/**
 * Number input for the revision form.
 *
 * TODO: Consider fields outside of the "revision" context.
 */
export default function RevisionFieldSum({ spec, name }: RevisionFieldProps<IFormInputSpecSum>) {
  const { form } = useFillOutRevisionDataProvider();
  const [value, setValue] = useState(Number(form.values.fields[name]) || 0);
  const [refreshToken, { increment: forceRefresh }] = useCounter();

  const refreshInterval = useInterval(forceRefresh, 1000);

  useEffect(() => {
    refreshInterval.start();
    return refreshInterval.stop;
  }, []);

  useEffect(() => {
    let sum = 0;

    for (const fieldName of spec.inputs) {
      sum += Number(form.values.fields[fieldName]) || 0;
    }

    setValue(sum);
  }, [refreshToken]);

  // Use indirect state change to avoid unnecessary re-renders.
  useEffect(() => {
    form.setFieldValue(name, value);
  }, [value]);

  return (
    <NumberInput
      size="lg"
      required={spec.required}
      readOnly
      label={spec.label}
      precision={spec.precision}
      rightSection={spec.suffix}
      hideControls
      {...form.getInputProps(name)}
    />
  );
}
