import DeviceTable from 'components/tables/device/DeviceTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';

/**
 * Page used to display devices.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=252%3A9379 Figma Design}
 * - {@link https://www.notion.so/Device-List-Devices-98f2a6f387f148258bda1094af96cc47?pvs=4 Notion Page}
 */
export default function DevicesPage() {
  const [search, setSearch] = useState('');
  const [showDiscarded, setShowDiscarded] = useState(false);

  return (
    <DashboardLayout
      title="Zariadenia"
      height="screen"
      withSearch
      onSearch={setSearch}
      breadcrumbs={[{ title: 'Zariadenia' }]}
      withToggle
      onToggleChange={setShowDiscarded}
      toggleLabel="Zobraziť vyradené"
    >
      <DeviceTable search={search} showDiscarded={showDiscarded} />
    </DashboardLayout>
  );
}
