import { mergeAttributes, Node } from '@tiptap/core';
import { nanoid } from 'nanoid';

export interface IfBlockOptions {}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    ifBlock: {
      setIfBlock: () => ReturnType;
      setIfConditionField: (field: string) => ReturnType;
      setIfConditionOperator: (operator: string) => ReturnType;
      setIfConditionValue: (value: string) => ReturnType;
    };
  }
}

export const IfBlock = Node.create<IfBlockOptions>({
  name: 'ifBlock',
  content: 'block+',
  group: 'block',
  defining: true,

  addAttributes() {
    return {
      'data-id': { default: nanoid() },
      'data-field': { default: '' },
      'data-operator': { default: '' },
      'data-value': { default: '' },
    };
  },

  parseHTML() {
    return [{ tag: 'div.if-block' }];
  },

  renderHTML({ HTMLAttributes, node }) {
    const id = node.attrs['data-id'];
    const field = node.attrs['data-field'];
    const operator = node.attrs['data-operator'];
    const value = node.attrs['data-value'];
    const path = `fields.${field}`;

    return [
      'div',
      mergeAttributes({ class: 'if-block' }, HTMLAttributes),
      [
        'div',
        { class: 'if-block-start', 'data-id': id, 'data-path': path, 'data-operator': operator, 'data-value': value },
        ['span', { class: 'if-block-start-prefix' }, ''],
        ['span', { class: 'if-block-start-field', 'data-inject-field-name': field }, ''],
        ['span', { class: 'if-block-start-operator', 'data-operator': operator }, ''],
        ['span', { class: 'if-block-start-value', 'data-value': value }, ''],
      ],
      ['div', { class: 'if-block-body', 'data-id': id }, 0],
      ['div', { class: 'if-block-end', 'data-id': id }],
    ];
  },

  addCommands() {
    return {
      setIfBlock:
        () =>
        ({ commands }) => {
          return commands.wrapIn(this.name);
        },
      setIfConditionField:
        (field: string) =>
        ({ commands }) => {
          return commands.updateAttributes('ifBlock', { 'data-field': field });
        },
      setIfConditionOperator:
        (operator: string) =>
        ({ commands }) => {
          return commands.updateAttributes('ifBlock', { 'data-operator': operator });
        },
      setIfConditionValue:
        (value: string) =>
        ({ commands }) => {
          return commands.updateAttributes('ifBlock', { 'data-value': value });
        },
    };
  },
});
