import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconBold } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to make the selected text bold.
 */
export default function Bold({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconBold />}
      label="Tučné"
      shortcut="Ctrl + B"
      active={editor?.isActive('bold')}
      onClick={() => editor?.chain().focus().toggleBold().run()}
    />
  );
}
