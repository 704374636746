import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionSetTemplateAndVisualPayload } from 'api/actions/revision-set-template-and-visual/revision-set-template-and-visual-payload';
import { RevisionSetTemplateAndVisualPathParameters } from 'api/actions/revision-set-template-and-visual/revision-set-template-and-visual-path-parameters';
import { RevisionSetTemplateAndVisualResponse } from 'api/actions/revision-set-template-and-visual/revision-set-template-and-visual-response';

type RevisionSetTemplateAndVisualQueryParameters = Record<string, any>;

/**
 * The path of the RevisionSetTemplateAndVisual action.
 */
export const revisionSetTemplateAndVisualActionPath = new SweetPath<keyof RevisionSetTemplateAndVisualPathParameters>(
  '/api/revision/set-template-visual/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionSetTemplateAndVisual actions.
 */
export default function createRevisionSetTemplateAndVisualAction(axios: AxiosInstance) {
  return function revisionSetTemplateAndVisualAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionSetTemplateAndVisualPathParameters;
    query?: RevisionSetTemplateAndVisualQueryParameters;
    payload: RevisionSetTemplateAndVisualPayload;
  }) {
    const path = revisionSetTemplateAndVisualActionPath.insert(parameters);

    return axios.put<
      RevisionSetTemplateAndVisualResponse,
      RevisionSetTemplateAndVisualResponse,
      RevisionSetTemplateAndVisualPayload
    >(path, payload);
  };
}
