import { noop } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceFault } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultForm';
import { ColDef, CsvExportParams, DataTypeDefinition, IRowNode } from 'ag-grid-community';
import { nanoid } from 'nanoid';
import { ActionIcon, Button, Flex, Group, Stack, Tooltip } from '@mantine/core';
import {
  IconAlertTriangle,
  IconCheck,
  IconCopy,
  IconFileExport,
  IconPlus,
  IconTableImport,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import { useAcknowledge, useConfirm } from 'components/modals/message/MessageProvider';
import ImportMeasurementsHint from 'components/forms/revision/fill-out/modals/device-measurement-modal/import/ImportMeasurementsHint';
import { read, writeFile } from 'xlsx';
import ImportMeasurementsModal, {
  ImportMeasurementsCallback,
} from 'components/forms/revision/fill-out/modals/device-measurement-modal/import/ImportMeasurementsModal';
import { AgGridReact } from 'ag-grid-react';
import { useDisclosure } from '@mantine/hooks';
import P1Medium from 'components/typography/P1Medium';
import ExportModal from 'components/tables/ExportModal';
import TableWrapper from 'components/tables/TableWrapper';
import ButtonWithConfirm from 'components/modals/confirm/ButtonWithConfirm';
import { IFormInputSpec, IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import defineColumn from 'components/forms/revision/fill-out/modals/device-measurement-modal/ag-grid/columns/define-column';
import { showNotification } from '@mantine/notifications';
import { ERROR_NOTIFICATION_COLOR } from 'utils/constants';
import { useDeviceFault } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultProvider';
import removeAccents from 'remove-accents';

type TableMeasurement<TMeasurement> = TMeasurement & { _id: string; _faults: DeviceFault[] };

export interface DeviceMeasurementFormData<TMeasurement> {
  measurements: TableMeasurement<TMeasurement>[];
}

export interface DeviceMeasurementFormProps<TMeasurement> {
  deviceTypeId: number;
  spec?: Readonly<IFormInputSpec[]>;
  hideFaults?: boolean;
  initialValues?: DeviceMeasurementFormData<TMeasurement>;
  search?: string;
  onSubmit?: (values: DeviceMeasurementFormData<TMeasurement>) => void;
  onClose?: () => void;
}

interface ImportColumn {
  name: string;
  label: string;
  type: IFormInputType | '_faults';
}

/**
 * Parses a JSON string safely.
 */
const parseJsonSafe = (value: string, defaultValue: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return defaultValue;
  }
};

/**
 * Displays actions that can be done with the measurement row.
 */
function ActionsColumn<TMeasurement>({
  node,
  hideFaults,
  onDelete,
  context: { deviceTypeId },
}: {
  node: IRowNode<TableMeasurement<TMeasurement>>;
  hideFaults: boolean;
  onDelete: (index: number) => void;
  context: { deviceTypeId: number };
}) {
  const { specifyFaults } = useDeviceFault();

  /** Handles the addition of a fault. */
  const handleAddFault = (): void => {
    specifyFaults({
      title: 'Závady - Prívod',
      deviceTypeId,
      initialValues: { faults: node?.data?._faults ?? [] },
      onSubmit: ({ faults }) => node.setData({ ...node.data!, _faults: faults }),
    });
  };

  return (
    <Group spacing={12}>
      {!hideFaults && (
        <Button w={140} variant="light" bg="blue.1" leftIcon={<IconAlertTriangle />} onClick={handleAddFault}>
          Závady ({node?.data?._faults.length ?? 0})
        </Button>
      )}
      <Tooltip withinPortal label="Zmazať meranie" openDelay={200} withArrow={false} offset={4}>
        <ActionIcon variant="danger-secondary" size="md" onClick={() => onDelete(node.rowIndex!)}>
          <IconTrashX />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
}

/**
 * First step in acquiring data from XLSX.
 * Converts the file contents to an XLSX workbook using XLSX package.
 */
function convertDataToWorkbook(dataRows: ArrayBuffer) {
  const data = new Uint8Array(dataRows);
  const arr = [];

  for (let i = 0; i !== data.length; ++i) {
    arr[i] = String.fromCharCode(data[i]);
  }

  const bstr = arr.join('');

  return read(bstr, { type: 'binary' });
}

/**
 * Second step in acquiring data from XLSX.
 * Parses the data from the XLSX workbook to a list of measurements.
 * If the parsed data does not have the correct format, an error is thrown.
 */
function parseXLSXData<TMeasurement>(workbook: any, spec: Readonly<IFormInputSpec[]>) {
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  // Index columns by their letter
  const columnLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  const columns: Record<string, ImportColumn> = {};

  for (let i = 0; i < spec.length; i++) {
    const letter = columnLetters[i];
    columns[letter] = spec[i];
  }

  columns[columnLetters[spec.length]] = {
    name: '_faults',
    label: 'Závady',
    type: '_faults',
  };

  // Check header row
  Object.entries(columns).forEach(([letter, { label }]) => {
    const header = sheet[`${letter}1`];

    if (header?.v !== label) {
      throw new Error('Skontrolujte si názvy stĺpcov v súbore.');
    }
  });

  // Parse data
  const data: TableMeasurement<TMeasurement>[] = [];

  for (let rowIndex = 2; columnLetters.some((letter) => !!sheet[letter + rowIndex]); rowIndex++) {
    const row: any = {
      _faults: [],
      _id: nanoid(),
    };

    Object.entries(columns).forEach(([letter, { name, type }]) => {
      const cell = sheet[letter + rowIndex];
      let value = cell?.w;

      if (type === '_faults') {
        value = parseJsonSafe(value, []);
      } else if (type === 'checkbox') {
        const lower = removeAccents(value || '').toLowerCase();
        value = lower === 'ano' || lower === 'true';
      } else {
        // No need to convert other values.
      }

      row[name] = value;
    });

    data.push(row);
  }

  return data;
}

/**
 * Form for collecting device measurement data.
 */
export default function AgGridDeviceMeasurementTable<TMeasurement>({
  deviceTypeId,
  spec = [],
  hideFaults = false,
  initialValues = { measurements: [] },
  search = '',
  onSubmit = noop,
  onClose = noop,
}: DeviceMeasurementFormProps<TMeasurement>) {
  const { confirm } = useConfirm();
  const { acknowledge } = useAcknowledge();

  const ref = useRef<AgGridReact>(null);

  const [selectedRows, setSelectedRows] = useState<TableMeasurement<TMeasurement>[]>([]);
  const [exporting, { open: startExporting, close: stopExporting }] = useDisclosure(false);
  const [importing, { open: startImporting, close: stopImporting }] = useDisclosure(false);

  const requiredColumns = useMemo(() => spec.filter((s) => s.required).map((s) => s.name), [spec]);

  const columns: ColDef[] = useMemo(() => {
    const cols: ColDef[] = [
      {
        field: 'checkbox',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        suppressMovable: true,
        width: 44,
        minWidth: 44,
        maxWidth: 44,
      },
      {
        field: '_id',
        headerName: 'ID merania',
        hide: true,
      },
      {
        field: '_faults',
        headerName: 'Závady',
        hide: true,
        valueGetter: ({ data }) => JSON.stringify(data._faults),
      },
    ];

    spec.forEach((spec, i) => {
      cols.push(defineColumn({ spec, index: i }));
    });

    cols.push({
      field: 'actions',
      headerName: '',
      pinned: 'right',
      width: 220,
      minWidth: 220,
      maxWidth: 220,
      cellRenderer: ({
        node,
        data,
        context,
      }: {
        node: IRowNode<TableMeasurement<TMeasurement>>;
        data: TableMeasurement<TMeasurement>;
        context: { deviceTypeId: number };
      }) => ActionsColumn({ node, hideFaults, context, onDelete: confirmRowDelete }),
    });

    return cols;
  }, spec);

  const columnsWithBulk = useMemo(
    () => [
      {
        field: '_bulk',
        headerName: '',
        suppressMovable: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        colSpan: ({ node }) => (node?.isRowPinned() ? 999 : 0),
      } satisfies ColDef,
      ...columns,
    ],
    [columns]
  );

  const tableFooter = useMemo(
    () => (
      <Group spacing={16}>
        <ButtonWithConfirm
          variant="tertiary"
          leftIcon={<IconX stroke="1.5" height={24} width={24} />}
          size="md"
          loaderPosition="center"
          loaderProps={{ size: 0 }}
          onConfirm={onClose}
          confirmTitle="Zrušiť zmeny v meraniach"
          confirmMessage="Naozaj chcete zrušiť zmeny v meraniach? Všetky neuložené zmeny budú stratené."
        >
          Zrušiť
        </ButtonWithConfirm>
        <Button
          variant="primary"
          leftIcon={<IconCheck stroke="1.5" height={24} width={24} />}
          size="md"
          onClick={() => {
            const nRows = ref.current?.api.getModel().getRowCount() ?? 0;
            const data = [];

            for (let i = 0; i < nRows; i++) {
              const row = ref.current?.api.getModel().getRow(i)?.data;

              for (const requiredColumn of requiredColumns) {
                if (!row[requiredColumn]) {
                  showNotification({
                    title: 'Chýbajúce údaje!',
                    message: 'Niektoré povinné údaje neboli vyplnené.',
                    color: ERROR_NOTIFICATION_COLOR,
                  });
                  return;
                }
              }

              data.push(row);
            }

            ref.current?.api.stopEditing();

            onSubmit({ measurements: data as TableMeasurement<TMeasurement>[] });
          }}
        >
          Uložiť
        </Button>
      </Group>
    ),
    [onClose, onSubmit, ref.current]
  );

  const hasBulkActions = useMemo(() => selectedRows.length > 0, [selectedRows]);

  const dataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition<TMeasurement> } = useMemo(() => {
    return {
      number: {
        baseDataType: 'number',
        extendsDataType: 'number',
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined) {
            return '';
          }

          return String(params.value);
        },
      },
      date: {
        baseDataType: 'date',
        extendsDataType: 'date',
        // display the date in the format of 'dd.MM.yyyy'
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined || String(params.value) === '') {
            return '';
          }

          return new Date(params.value).toLocaleDateString('sk-SK');
        },
      },
      select: {
        baseDataType: 'text',
        extendsDataType: 'text',
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined || String(params.value) === '') {
            return '';
          }

          return params.colDef.cellEditorParams?.options?.find(
            (o: { value: string; label: string }) => o.value === params.value
          )?.label;
        },
      },
    };
  }, []);

  /**
   * Copies the selected rows to the end of the table.
   */
  const bulkActionCopyAtEnd = useCallback(() => {
    const selected = ref.current?.api.getSelectedRows() || [];

    ref.current?.api.applyTransaction({
      add: selected.map((row) => ({
        ...row,
        _id: nanoid(),
      })),
      addIndex: ref.current.api.getModel().getRowCount(),
    });

    ref.current?.api.deselectAll();
  }, [ref.current]);

  /**
   * Copies the selected rows after the index of row that was selected last.
   * We have rows on indexes 0,1,2, the user first selects row with index 0, then index 2
   * and lastly with index 1, the rows will be copied after the row with index 1.
   */
  const bulkActionCopyAtIndex = useCallback(() => {
    const selected = ref.current?.api.getSelectedRows() || [];

    if (selected.length === 0) {
      return;
    }

    const lastSelected = selected[selected.length - 1];
    const lastSelectedIndex = ref.current?.api.getModel().getRowNode(lastSelected._id)?.rowIndex;
    const destinationIndex = lastSelectedIndex === null || lastSelectedIndex === undefined ? 0 : lastSelectedIndex + 1;

    ref.current?.api.applyTransaction({
      add: selected.map((row) => ({
        ...row,
        _id: nanoid(),
      })),
      addIndex: destinationIndex,
    });

    ref.current?.api.deselectAll();
  }, [ref.current]);

  /**
   * Deletes the selected rows from the table.
   */
  const bulkActionDelete = useCallback(() => {
    const selected = ref.current?.api.getSelectedRows() || [];

    confirm({
      title: `Zmazať merania (${selected.length})`,
      content: 'Naozaj si prajete zmazať tieto merania? Akcia je nevratná.',
      onConfirm: () => {
        ref.current?.api.applyTransaction({
          remove: selected,
        });

        ref.current?.api.deselectAll();
      },
    });
  }, [ref.current]);

  const bulkActions = useMemo(() => {
    return (
      <Group spacing={12}>
        <Button variant="primary" leftIcon={<IconCopy />} onClick={bulkActionCopyAtEnd}>
          Kopírovať označené na koniec
        </Button>
        <Button variant="primary" leftIcon={<IconCopy />} onClick={bulkActionCopyAtIndex}>
          Kopírovať označené pod
        </Button>
        <Button variant="primary" leftIcon={<IconTrashX />} onClick={bulkActionDelete}>
          Zmazať označené
        </Button>
      </Group>
    );
  }, [bulkActionCopyAtEnd, bulkActionCopyAtIndex, bulkActionDelete]);

  /**
   * Handles the event when a row is deleted.
   */
  function onMeasurementRowDelete(index: number): void {
    ref.current?.api.applyTransaction({
      remove: [ref.current?.api.getModel().getRow(index)?.data],
    });
  }

  /**
   * Confirms the deletion of a row.
   */
  function confirmRowDelete(index: number): void {
    confirm({
      title: 'Zmazanie merania',
      content: 'Naozaj chcete zmazať toto meranie?',
      onConfirm: () => onMeasurementRowDelete(index),
    });
  }

  /**
   * Adds a new measurement to the table.
   */
  function handleAddMeasurement(): void {
    ref.current?.api.applyTransaction({
      add: [
        {
          _id: nanoid(),
          _faults: [],
          ...(spec.reduce((acc, spec) => ({ ...acc, [spec.name]: '' }), {}) as any),
        },
      ],
      addIndex: ref.current.api.getModel().getRowCount(),
    });
  }

  /**
   * Exports the file as CSV.
   */
  async function exportFile(params: CsvExportParams) {
    const csv = ref.current?.api.getDataAsCsv(params);

    if (!csv) {
      return;
    }

    const wb = read(csv, { type: 'string', cellDates: true });

    wb.Sheets[wb.SheetNames[0]]['!cols'] = spec.map(() => ({ wch: 15 }));

    writeFile(wb, params.fileName ?? 'export.xlsx', { type: 'file', bookType: 'xlsx' });
  }

  const displayImportHint = useCallback(
    () =>
      acknowledge({
        title: 'Import zariadení',
        content: ImportMeasurementsHint,
        zIndex: 300,
      }),
    [acknowledge]
  );

  const handleImport: ImportMeasurementsCallback<TMeasurement> = useCallback(
    async (file) => {
      if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        throw new Error('Súbor musí byť vo formáte XLSX.');
      }

      const contents = await file.arrayBuffer();
      const workbook = convertDataToWorkbook(contents);
      const rows: TableMeasurement<TMeasurement>[] = parseXLSXData(workbook, spec);

      ref.current?.api.setRowData(rows);

      return rows;
    },
    [ref.current, spec]
  );

  const tableActions = useMemo(
    () => (
      <Group spacing={16}>
        <Button
          variant="primary"
          onClick={handleAddMeasurement}
          size="md"
          leftIcon={<IconPlus stroke="1.5" size={24} />}
        >
          Pridať meranie
        </Button>
        <Button
          variant="tertiary"
          size="md"
          leftIcon={<IconFileExport stroke="1.5" size={24} />}
          onClick={startExporting}
        >
          Exportovať
        </Button>
        <ExportModal
          columns={columns}
          title="Exportovať merania"
          dataExport={{
            modalTitle: 'Exportovať merania',
            columnKeys: [...spec.map(({ name }) => name), '_faults'],
            fileName: 'merania.xlsx',
          }}
          opened={exporting}
          onClose={stopExporting}
          onExport={(params) => {
            exportFile(params);
          }}
        />
        <Button
          variant="tertiary"
          size="md"
          leftIcon={<IconTableImport stroke="1.5" size={24} />}
          onClick={startImporting}
        >
          Importovať
        </Button>
        <ImportMeasurementsModal
          title="Importovať merania"
          opened={importing}
          onClose={stopImporting}
          onImport={handleImport}
          onHint={displayImportHint}
        />
      </Group>
    ),
    [columns, spec, startExporting, startImporting, exportFile, handleImport, displayImportHint]
  );

  // Set quick filter.
  useEffect(() => {
    ref.current?.api?.setQuickFilter(search);
  }, [search]);

  return (
    <Stack h="100%" spacing={16}>
      <TableWrapper>
        <AgGridReact
          ref={ref}
          getRowId={({ data }) => data._id}
          rowData={initialValues.measurements}
          columnDefs={columnsWithBulk}
          dataTypeDefinitions={dataTypeDefinitions}
          // drag and drop properties
          rowDragManaged={true}
          rowDragMultiRow={true}
          rowSelection="multiple"
          suppressMoveWhenRowDragging
          context={{ deviceTypeId }}
          onGridReady={({ api }) => {
            api.sizeColumnsToFit();
          }}
          onSelectionChanged={({ api }) => {
            setSelectedRows(api.getSelectedRows());
          }}
          rowHeight={72}
          className="ag-theme-alpine"
          suppressRowClickSelection
          suppressDragLeaveHidesColumns
          suppressColumnVirtualisation
          noRowsOverlayComponent={() => 'Nenájdené žiadne záznamy'}
          pinnedTopRowData={hasBulkActions ? [initialValues.measurements[0]] : []}
        />
        <Flex gap={12} p={16} align="center" h="100%" bg="gray.0" justify="space-between">
          {tableActions}
          {tableFooter}
        </Flex>
        {hasBulkActions && (
          <Group
            position="apart"
            pos="absolute"
            top={122}
            left={0}
            w="100%"
            h={73}
            bg="blue.6"
            px={24}
            py={16}
            sx={{
              zIndex: 200,
              borderTop: '2px solid white',
              borderLeft: '2px solid white',
              borderRight: '2px solid white',
            }}
          >
            {bulkActions}
            <Group spacing={8}>
              <P1Medium color="white">vybrané: {selectedRows.length}</P1Medium>
              <Button
                variant="primary"
                size="sm"
                leftIcon={<IconX strokeWidth={1.5} size={16} />}
                onClick={() => ref.current?.api.deselectAll()}
              >
                Odznačiť všetko
              </Button>
            </Group>
          </Group>
        )}
      </TableWrapper>
    </Stack>
  );
}
