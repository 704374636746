import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceSubtypeUpdatePayload } from 'api/actions/device-subtype-update/device-subtype-update-payload';
import { DeviceSubtypeUpdatePathParameters } from 'api/actions/device-subtype-update/device-subtype-update-path-parameters';
import { DeviceSubtypeUpdateResponse } from 'api/actions/device-subtype-update/device-subtype-update-response';

type DeviceSubtypeUpdateQueryParameters = Record<string, any>;

/**
 * The path of the DeviceSubtypeUpdate action.
 */
export const deviceSubtypeUpdateActionPath = new SweetPath<keyof DeviceSubtypeUpdatePathParameters>(
  '/api/device/subtypes/{deviceTypeId}/update/{deviceSubtypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceSubtypeUpdate actions.
 */
export default function createDeviceSubtypeUpdateAction(axios: AxiosInstance) {
  return function deviceSubtypeUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceSubtypeUpdatePathParameters;
    query?: DeviceSubtypeUpdateQueryParameters;
    payload: DeviceSubtypeUpdatePayload;
  }) {
    const path = deviceSubtypeUpdateActionPath.insert(parameters);

    return axios.put<DeviceSubtypeUpdateResponse, DeviceSubtypeUpdateResponse, DeviceSubtypeUpdatePayload>(
      path,
      payload
    );
  };
}
