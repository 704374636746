import { useDisclosure } from '@mantine/hooks';
import DeviceFaultModal from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultModal';
import { createContext, useContext, useState } from 'react';
import { DeviceFaultFormData } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultForm';

interface SpecifyFaultsParams {
  deviceTypeId: number;
  initialValues?: DeviceFaultFormData;
  title?: string;
  onSubmit?: (values: DeviceFaultFormData) => void;
}

type SpecifyFaultsCallback = (params: SpecifyFaultsParams) => void;

interface IDeviceFaultContext {
  specifyFaults: SpecifyFaultsCallback;
}

const DeviceFaultContext = createContext<IDeviceFaultContext>(undefined!);

/**
 * Provides the device fault modal to the application.
 */
export function DeviceFaultProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<SpecifyFaultsParams>({ deviceTypeId: 0 });

  /**
   * Opens the device fault modal with the specified parameters.
   */
  const specifyFaults: SpecifyFaultsCallback = (params) => {
    setParams(params);
    open();
  };

  return (
    <DeviceFaultContext.Provider value={{ specifyFaults }}>
      {children}
      <DeviceFaultModal opened={opened} onClose={close} {...params} />
    </DeviceFaultContext.Provider>
  );
}

/**
 * Hook for opening the device fault modal.
 */
export function useDeviceFault() {
  return useContext(DeviceFaultContext);
}
