import { Fragment, useMemo } from 'react';
import P2Regular from 'components/typography/P2Regular';
import { Box, Group, Stack, Tooltip } from '@mantine/core';
import P2Medium from 'components/typography/P2Medium';

/**
 * List of organizations.
 */
export default function OrganizationList({
  organizations,
  limit = 3,
}: {
  organizations: { organizationId: number; organizationName: string }[];
  limit?: number;
}) {
  const some = useMemo(() => organizations.slice(0, limit), [organizations, limit]);
  const more = useMemo(() => organizations.slice(limit), [organizations, limit]);

  const hasMore = more.length > 0;

  if (organizations.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group spacing={12}>
      {some.map(({ organizationId, organizationName }) => (
        <Fragment key={organizationId}>
          <P2Regular color="gray.8">{organizationName}</P2Regular>|
        </Fragment>
      ))}
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {more.map(({ organizationId, organizationName }) => (
                <P2Regular key={organizationId} color="gray.8">
                  {organizationName}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Medium color="gray.6">ďalších (+{more.length})</P2Medium>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}
