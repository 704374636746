import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconSettingsCode } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { usePageAdvancedSettings } from 'pages/revisions-module/template-editor/editors/visual/advanced/PageAdvancedSettingsProvider';

/**
 * Control used to set the page advanced settings.
 */
export default function PageAdvanced({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();
  const { openPageAdvancedSettings } = usePageAdvancedSettings();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconSettingsCode />}
      label="Pokročilé nastavenia"
      onClick={() => openPageAdvancedSettings({ onClose: () => editor?.chain().focus().run() })}
    />
  );
}
