import { Group } from '@mantine/core';
import { IAutomationSetRevisionSafetyPoint } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import P3Medium from 'components/typography/P3Medium';
import P3Regular from 'components/typography/P3Regular';
import InputMention from 'pages/revisions-module/template-editor/editors/pipeline/InputMention';

/**
 * The automation preview for set-revision-safety-point automation.
 */
export default function SetRevisionSafetyPointAutomationPreview({ data }: { data: IAutomationSetRevisionSafetyPoint }) {
  return (
    <Group spacing={4}>
      <P3Medium>Záver:</P3Medium>
      <P3Regular>
        <InputMention prefix="Podľa poľa" name={data.inputName} />
      </P3Regular>
    </Group>
  );
}
