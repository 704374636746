import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthCheckResetPasswordTokenPayload } from 'api/actions/auth-check-reset-password-token/auth-check-reset-password-token-payload';
import { AuthCheckResetPasswordTokenPathParameters } from 'api/actions/auth-check-reset-password-token/auth-check-reset-password-token-path-parameters';
import { AuthCheckResetPasswordTokenResponse } from 'api/actions/auth-check-reset-password-token/auth-check-reset-password-token-response';

type AuthCheckResetPasswordTokenQueryParameters = Record<string, any>;

/**
 * The path of the AuthCheckResetPasswordToken action.
 */
export const authCheckResetPasswordTokenActionPath = new SweetPath<keyof AuthCheckResetPasswordTokenPathParameters>(
  '/api/auth/check-password-token',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthCheckResetPasswordToken actions.
 */
export default function createAuthCheckResetPasswordTokenAction(axios: AxiosInstance) {
  return function authCheckResetPasswordTokenAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthCheckResetPasswordTokenQueryParameters;
    payload: AuthCheckResetPasswordTokenPayload;
  }) {
    const path = authCheckResetPasswordTokenActionPath.original;

    return axios.post<
      AuthCheckResetPasswordTokenResponse,
      AuthCheckResetPasswordTokenResponse,
      AuthCheckResetPasswordTokenPayload
    >(path, payload);
  };
}
