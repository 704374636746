import { Box, Button, Divider, Flex, Menu, Stack, rem, useMantineTheme } from '@mantine/core';
import {
  IconBellRinging,
  IconChevronRight,
  IconCookie,
  IconDiscountCheck,
  IconPower,
  IconUser,
} from '@tabler/icons-react';
import Avatar from 'components/Avatar';
import H2Medium from 'components/typography/H2Medium';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import P4Regular from 'components/typography/P4Regular';
import NavbarItem from 'layouts/dashboard/navbar/NavbarItem';
import {
  LOGOUT_PAGE_PATH,
  MY_CERTIFICATES_PAGE_PATH,
  MY_PROFILE_PAGE_PATH,
  NOTIFICATION_SETTINGS_PAGE_PATH,
} from 'routes/paths';
import { useApi } from 'api/api-context';
import { useState } from 'react';
import { ROLE_ADMIN_ID } from 'model/Role';

/**
 * Display the person's name and avatar.
 */
export default function NavbarPerson({ isCollapsed }: { isCollapsed: boolean }) {
  const { fullName, email, role, roleId, organizationName, userId, hasPermissionAnywhere } = useApi();
  const theme = useMantineTheme();
  const [hover, setHover] = useState(false);

  return (
    <Menu position="right-end">
      <Menu.Target>
        <Flex
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          sx={(theme) => ({
            cursor: 'pointer',
            borderRadius: rem(4),
            '&:hover': {
              backgroundColor: theme.colors.gray[0],
            },
          })}
          direction="row"
          justify="start"
          align="center"
          p={12}
          gap={12}
        >
          <Box w={40} h={40}>
            <Avatar fullName={fullName!} size="l" bgColor={hover ? theme.colors.gray[8] : theme.colors.gray[7]} />
          </Box>
          {!isCollapsed && (
            <>
              <Box>
                <P1Medium color={hover ? 'gray.8' : 'gray.7'}>{fullName}</P1Medium>
                <P4Regular color={hover ? 'gray.7' : 'gray.6'}>{organizationName}</P4Regular>
              </Box>
              <IconChevronRight size={16} color={hover ? theme.colors.gray[8] : theme.colors.gray[7]} />
            </>
          )}
        </Flex>
      </Menu.Target>

      <Menu.Dropdown>
        <Box miw={256} maw={400}>
          <Box p={16}>
            <H2Medium truncate>{fullName}</H2Medium>
            <P2Regular truncate>{role}</P2Regular>
            <P2Regular truncate color="gray.6">
              {email}
            </P2Regular>
          </Box>

          <Divider color="gray.1" />

          <Stack spacing={4} px={4} py={4}>
            <NavbarItem
              to={MY_PROFILE_PAGE_PATH.original}
              label="Môj profil"
              icon={<IconUser stroke="1.5" />}
              isCollapsed={false}
            />
            {roleId !== ROLE_ADMIN_ID && hasPermissionAnywhere('fill-out-revisions') && (
              <NavbarItem
                to={MY_CERTIFICATES_PAGE_PATH.original}
                label="Moje osvedčenia"
                icon={<IconDiscountCheck stroke="1.5" />}
                isCollapsed={false}
              />
            )}
            <NavbarItem
              to={NOTIFICATION_SETTINGS_PAGE_PATH.insert({ userId })}
              label="Nastavenia upozornení"
              icon={<IconBellRinging stroke="1.5" />}
              isCollapsed={false}
            />
            {/* TODO do not use hard-coded link, create page when VOP is provided by the client */}
            <NavbarItem
              target="_blank"
              to="/resources/VOP_FM_POINT.pdf"
              label={
                <>
                  Všeobecné obchodné
                  <br />
                  podmienky
                </>
              }
              icon={<IconCookie stroke="1.5" />}
              isCollapsed={false}
            />
          </Stack>

          <Divider color="gray.1" />

          <Box p={4}>
            <Button
              component={NavbarItem}
              label="Odhlásiť sa"
              icon={<IconPower stroke="1.5" />}
              to={LOGOUT_PAGE_PATH.original}
              isCollapsed={false}
            />
          </Box>
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
}
