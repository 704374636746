import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import DepartmentForm, { DepartmentFormData } from 'components/forms/department/DepartmentForm';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { DEPARTMENTS_PAGE_PATH } from 'routes/paths';
import { notifications } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useEffect, useState } from 'react';
import { DepartmentGetResponse } from 'api/actions/department-get/department-get-response';
import { DEPARTMENT_REDIRECT_AFTER_SAVE } from 'env';

/**
 * Page used to edit the department.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A6764 Figma Design}
 * - {@link https://www.notion.so/Administrative-Edit-Department-ca21d2290e334374a5cdfa7a256dac76?pvs=4 Notion Page}
 */
export default function EditDepartmentPage() {
  const navigate = useNavigate();
  const { departmentId } = useParams();
  const { getAction } = useApi();
  const { getPermissionTemplate, getDefaultPermissionTemplate } = usePermissionDataProvider();
  const [department, setDepartment] = useState<DepartmentGetResponse | null>(null);
  const [canManageUsers, setCanManageUsers] = useState(false);

  /**
   * Handles the form submission.
   */
  function onSubmit({
    departmentName,
    departmentNumber,
    streetAddress,
    city,
    zip,
    contactPersonId,
    country,
    people,
  }: DepartmentFormData) {
    const departmentUpdateAction = getAction('DepartmentUpdate');

    const mappedPeople = canManageUsers
      ? Object.entries(people)
          .map(([personId, value]) => ({
            userId: Number(personId),
            checked: value.checked,
            deleted: value.deleted,
            permissions: value.permissions,
            templateId:
              getPermissionTemplate(value.templateName)?.permissionTemplateId ??
              getDefaultPermissionTemplate().permissionTemplateId,
            isNew: value.isNew,
          }))
          .map(({ userId, permissions, templateId, deleted, isNew }) => ({
            deleted,
            userId,
            permissions: permissions ?? [],
            templateId,
            isNew,
          }))
      : undefined;

    return departmentUpdateAction({
      parameters: {
        departmentId: departmentId!,
      },
      payload: {
        departmentName,
        departmentNumber: departmentNumber ?? undefined,
        streetAddress,
        city,
        zip,
        contactPersonId: !contactPersonId || contactPersonId === '' ? undefined : Number(contactPersonId),
        country,
        people: mappedPeople,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Stredisko bolo úspešne upravené',
          message: `Stredisko ${departmentName} bolo úspešne upravené`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEPARTMENT_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEPARTMENTS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  useEffect(() => {
    if (departmentId) {
      const departmentGetAction = getAction('DepartmentGet');
      const userHasPermissionInDepartmentAction = getAction('AuthUserHasPermissionInDepartment');

      Promise.all([
        departmentGetAction({ parameters: { departmentId } }),
        userHasPermissionInDepartmentAction({ parameters: { departmentId, permissionSlug: 'manage-users' } }),
      ])
        .then(([department, { hasPermission: canManageUsers }]) => {
          setDepartment(department);
          setCanManageUsers(canManageUsers);
        })
        .catch(panic);
    }
  }, [departmentId]);

  if (!departmentId) {
    return <Navigate to={DEPARTMENTS_PAGE_PATH.original} />;
  }

  return (
    <DashboardLayout
      title={`Úprava strediska: ${department?.departmentName ?? ''}`}
      breadcrumbs={[
        { title: 'Strediská', link: DEPARTMENTS_PAGE_PATH.original },
        { title: department?.departmentName ?? 'Načítavanie ...' },
      ]}
    >
      {department && (
        <DepartmentForm
          initialValues={{
            departmentName: department.departmentName,
            departmentNumber: department.departmentNumber ?? '',
            streetAddress: department.streetAddress,
            city: department.city,
            zip: department.zip,
            country: department.country,
            contactPersonId: department.contactPerson?.userId ? String(department.contactPerson.userId) : '',
            organizationId: String(department.organization.organizationId),
            organizationPeople: (department.peopleInOrganization || []).reduce((acc, curr) => {
              acc[String(curr.userId)] = {
                checked: true,
                permissions: curr.permissions,
                templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
              };

              return acc;
            }, {} as { [userId: string]: { checked: boolean; permissions: number[]; templateName: string } }),
            people: (department.people || []).reduce((acc, curr) => {
              acc[String(curr.userId)] = {
                checked: true,
                deleted: curr.deleted,
                permissions: curr.permissions,
                templateName: getPermissionTemplate(curr.templateId)?.slug ?? 'no-template',
                isNew: curr.isNew,
              };

              return acc;
            }, {} as { [userId: string]: { checked: boolean; deleted: boolean; permissions: number[]; templateName: string; isNew: boolean } }),
          }}
          readOnly={{ organizationId: true }}
          notAllowed={{ people: !canManageUsers }}
          onSubmit={onSubmit}
        />
      )}
    </DashboardLayout>
  );
}
