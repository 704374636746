import { MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular } from 'theme/typography';

export type NavLinkTheme = NonNullable<MantineThemeOverride['components']>['NavLink'];

export const NavLink: NavLinkTheme = {
  styles: (theme) => ({
    root: {
      padding: rem(12),
      borderRadius: rem(4),
      color: theme.colors.gray[7],
      ...P1Regular,
      '&:hover': {
        color: theme.colors.blue[8],
        backgroundColor: theme.colors.blue[0],
      },
      '&[data-active="true"]': {
        color: theme.colors.blue[8],
        backgroundColor: theme.colors.blue[0],
      },
    },
    icon: {
      marginRight: rem(24),
    },
    label: {
      ...P1Regular,
    },
    children: {
      paddingLeft: 0,
      '& button': {
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingLeft: rem(60),
        '& span': {
          ...P2Regular,
        },
      },
    },
  }),
};
