import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListTypesPathParameters } from 'api/actions/device-list-types/device-list-types-path-parameters';
import { DeviceListTypesResponse } from 'api/actions/device-list-types/device-list-types-response';

type DeviceListTypesQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListTypes action.
 */
export const deviceListTypesActionPath = new SweetPath<keyof DeviceListTypesPathParameters>('/api/device/types', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceListTypes actions.
 */
export default function createDeviceListTypesAction(axios: AxiosInstance) {
  return function deviceListTypesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DeviceListTypesQueryParameters } = {}) {
    const path = deviceListTypesActionPath.original;

    return axios.get<DeviceListTypesResponse, DeviceListTypesResponse>(path, { params: query, headers });
  };
}
