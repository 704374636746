import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import PreDefinedDataForm, {
  PreDefinedDataFormData,
  preDefinedDataFormInitialValues,
} from 'components/forms/pre-defined-data/PreDefinedDataForm';
import { PREDEFINED_DATA_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  SETTINGS_PAGE_PATH,
} from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add global pre-defined data.
 *
 * - {@link N/A Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-Create-Global-Pre-defined-Data-42c9a403aa434f93a278224c14fc3304?pvs=4 Notion Page}
 */
export default function AddGlobalPredefinedDataPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const [initialValues] = useState(() => ({
    ...preDefinedDataFormInitialValues,
    isGlobal: true,
    isPersonal: false,
  }));

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({ predefinedDataName, predefinedDataDescription, predefinedDataTypeId }: PreDefinedDataFormData) {
    const preDefinedDataCreateAction = getAction('PredefinedDataCreateGlobal');

    return preDefinedDataCreateAction({
      payload: {
        predefinedDataName,
        predefinedDataDescription: predefinedDataDescription ?? undefined,
        predefinedDataTypeId: Number(predefinedDataTypeId),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Globálny predvolený údaj bol vytvorený',
          message: `Globálny predvolený údaj ${predefinedDataName} bol úspešne vytvorený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (PREDEFINED_DATA_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH.insert({ predefinedDataId: response.globalPredefinedDataId }));
        } else if (PREDEFINED_DATA_REDIRECT_AFTER_SAVE === 'list') {
          navigate(GLOBAL_PREDEFINED_DATA_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového globálneho predvoleného údaja"
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        {
          title: 'Globálne predvolené údaje',
          link: GLOBAL_PREDEFINED_DATA_PAGE_PATH.original,
        },
        { title: 'Pridanie nového globálneho predvoleného údaja' },
      ]}
    >
      <PreDefinedDataForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
