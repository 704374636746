import { Box, Group, Loader, Notification, SimpleGrid, Stack, useMantineTheme } from '@mantine/core';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import { useApi } from 'api/api-context';
import RevisionStatusIcon from 'components/RevisionStatusIcon';
import FormInputGroup from 'components/forms/FormInputGroup';
import RevisionPreview from 'components/revision/RevisionPreview';
import RevisionActions from 'components/tables/revision/RevisionActions';
import H3Medium from 'components/typography/H3Medium';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import ReadonlyText from 'components/typography/ReadonlyText';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, REVISIONS_PAGE_PATH } from 'routes/paths';
import RevisionFile from 'pages/revisions-module/revisions/RevisionFile';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useDocumentVisibility } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  ERROR_NOTIFICATION_COLOR,
  INFO_NOTIFICATION_COLOR,
  NO_ENTITY_EXISTS_ERROR_CODE,
  REVISION_NOT_SIGNED_YET_ERROR_CODE,
  REVISION_SIGNATURE_INVALID_ERROR_CODE,
} from 'utils/constants';
import RevisionDevices from 'pages/revisions-module/revisions/RevisionDevices';
import RevisionPlanChangeLog from 'pages/revisions-module/revisions/RevisionPlanChangeLog';
import { FEATURE_TOGGLE_REVISION_PLAN_CHANGE_LOG } from 'env';

/**
 * The status of a revision.
 */
type RevisionStatus = RevisionGetResponse['revisionStatus'];

type Revision = RevisionGetResponse & {
  deviceSubtype: { deviceTypeId: number; deviceTypeName: string; slug: string }[];
};

/**
 * Page used to display a single revision.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A14250 Figma Design}
 * - {@link https://www.notion.so/Revisions-Detail-of-the-Revision-2e9974f490fe4cfbb67dd2b32299e33f?pvs=4 Notion Page}
 */
export default function RevisionPage() {
  const theme = useMantineTheme();
  const documentState = useDocumentVisibility();
  const { getAction, userId, fullName, roleId } = useApi();
  const { revisionId } = useParams();
  const [revision, setRevision] = useState<Revision | null>(null);
  const [refreshKey, setRefreshKey] = useState(nanoid);
  const [userCanViewDevices, setUserCanViewDevices] = useState(roleId === ROLE_ADMIN_ID);
  const [checkingSignature, setCheckingSignature] = useState(false);
  const navigate = useNavigate();

  const revisionData = useMemo(() => {
    if (!revision || !revision.revisionData) {
      return null;
    }

    return JSON.parse(revision.revisionData);
  }, [revision]);

  /**
   * Handles the status change of the revision.
   */
  const onStatusChange = useCallback(
    ({ status: revisionStatus, reason, fileId }: { status: RevisionStatus; reason?: string; fileId?: string }) => {
      setRevision((revision) => {
        if (!revision) {
          return revision;
        }

        const logs = revision.logs ?? [];
        const maxId = Math.max(...logs.map((log) => log.revisionChangeLogId));

        logs.push({
          revisionId: revision.revisionId,
          revisionChangeLogId: maxId + 1,
          oldStatus: revision.revisionStatus,
          newStatus: revisionStatus,
          timestamp: new Date().toISOString(),
          // first name and last name do not matter, fullName is used instead
          user: { userId: userId!, fullName: fullName!, firstName: '', lastName: '' },
          note: reason ?? undefined,
        });

        setRefreshKey(nanoid());

        return { ...revision, revisionStatus, logs, fileId };
      });
    },
    [setRevision]
  );

  // Fetch the revision data.
  useEffect(() => {
    if (revisionId) {
      const action = getAction('RevisionGet');

      action({ parameters: { revisionId } })
        .then((response) => {
          const deviceSubtypes = response.devices.map((device) => device.deviceSubtype);
          const deviceSubtypesMap: {
            [deviceTypeId: number]: { deviceTypeName: string; deviceTypeId: number; slug: string };
          } = {};
          deviceSubtypes.forEach((deviceSubtype) => {
            deviceSubtypesMap[deviceSubtype!.deviceTypeId] = deviceSubtype!;
          });

          const uniqDeviceSubtypes = Object.values(deviceSubtypesMap).map((val) => val);

          setRevision({ ...response, deviceSubtype: uniqDeviceSubtypes });
        })
        .catch((e) => {
          if (e?.response?.data?.error?.code === NO_ENTITY_EXISTS_ERROR_CODE) {
            navigate(DASHBOARD_PAGE_PATH.original, { replace: true });
          } else {
            panic(e);
          }
        });
    }
  }, [refreshKey, revisionId]);

  // Fetch permissions for the user.
  useEffect(() => {
    if (roleId === ROLE_ADMIN_ID) {
      setUserCanViewDevices(true);
    } else if (revision) {
      const userHasPermissionInDepartment = getAction('AuthUserHasPermissionInDepartment');

      userHasPermissionInDepartment({
        parameters: { departmentId: String(revision.department.departmentId), permissionSlug: 'browse-devices' },
      })
        .then(({ hasPermission }) => setUserCanViewDevices(hasPermission))
        .catch(panic);
    }
  }, [roleId, revision]);

  // Refresh the revision if it is being signed and the document becomes
  // visible (user returns to the tab).
  useEffect(() => {
    (async () => {
      if (revisionId && documentState === 'visible' && revision?.revisionStatus.slug === 'being-signed') {
        try {
          setCheckingSignature(true);

          const getRevision = getAction('RevisionGet');
          const getSignedDoc = getAction('RevisionSaveSignedDoc');

          const { revisionStatus } = await getRevision({ parameters: { revisionId } });

          if (revisionStatus.slug !== 'being-signed') {
            setRefreshKey(nanoid());
            return; // The revision is no longer being signed.
          }

          await getSignedDoc({ parameters: { revisionId } });

          setRefreshKey(nanoid()); // No error = refresh the revision.
        } catch (error: any) {
          const code = error?.response?.data?.error?.code;

          if (code === REVISION_NOT_SIGNED_YET_ERROR_CODE) {
            const message =
              revision.technician.userId === userId
                ? 'Využite tlačidlo "Podpísať", aby ste dokončili podpísanie revíznej správy pomocou služby BrainIT.'
                : 'Revízny technik ešte nepodpísal revíznu správu. Skúste to prosím neskôr alebo kontaktujte revízneho technika.';

            showNotification({
              color: ERROR_NOTIFICATION_COLOR,
              title: 'Dokument ešte nie je podpísaný',
              message,
            });
          } else if (code === REVISION_SIGNATURE_INVALID_ERROR_CODE) {
            showNotification({
              color: ERROR_NOTIFICATION_COLOR,
              title: 'Podpis revíznej správy je neplatný',
              message:
                'Podpis revíznej správy je neplatný. Skontrolujte platnosť svojho certifikátu alebo kontaktujte administrátora.',
            });
          } else {
            panic(error);
          }
        } finally {
          setCheckingSignature(false);
        }
      }
    })();
  }, [documentState, revision?.revisionStatus.slug]);

  return (
    <DashboardLayout
      title={`Detail revíznej správy: ${revision?.revisionName ?? ''}`}
      breadcrumbs={[
        { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
        { title: revision?.revisionName ?? 'Načítavam...' },
      ]}
      actions={revision && <RevisionActions revision={revision} hideView onStatusChange={onStatusChange} />}
    >
      {revision && (
        <Stack spacing={24}>
          <FormInputGroup
            groupTitle="Základné informácie"
            stackProps={{ bg: 'white', pb: 40, pr: 40, style: { borderRadius: '4px' } }}
          >
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText title="Názov" value={revision.revisionName} />
              <ReadonlyText title="Deadline" value={new Date(revision.deadline).toLocaleDateString()} />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText title="Organizácia" value={revision.organization.organizationName} />
              <ReadonlyText title="Stredisko" value={revision.department.departmentName} />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText title="Typ" value={revision.deviceType.deviceTypeName} />
              <ReadonlyText
                title="Druh"
                value={revision.deviceSubtype.map((item) => ({ key: item.deviceTypeId, value: item.deviceTypeName }))}
              />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText title="Zadávateľ" value={revision.assignedBy.organizationName} />
              <ReadonlyText title="Revízny technik" value={revision.technician.fullName} />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText
                title="Ukončená"
                value={revision.finishedAt ? new Date(revision.finishedAt).toLocaleDateString() : '-'}
              />
              <ReadonlyText
                title="Preplánovanie termínov"
                value={
                  revision.planNextRevision === 'skipped'
                    ? 'Preskočené'
                    : revision.planNextRevision === 'planned'
                    ? 'Vykonané'
                    : '-'
                }
              />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText
                title="Deadline na prepečiatkovanie"
                value={revision.documentValidUntil ? new Date(revision.documentValidUntil).toLocaleDateString() : '-'}
              />
              <ReadonlyText
                title="Dátum nasledujúcej revíznej správy"
                value={revision.nextRevisionDate ? new Date(revision.nextRevisionDate).toLocaleDateString() : '-'}
              />
            </SimpleGrid>
            <SimpleGrid cols={2} spacing={40}>
              <ReadonlyText
                title="Stav"
                value={<RevisionStatusIcon statusId={revision.revisionStatus.revisionStatusId} />}
              />
            </SimpleGrid>
          </FormInputGroup>

          {FEATURE_TOGGLE_REVISION_PLAN_CHANGE_LOG && userCanViewDevices && (
            <RevisionPlanChangeLog revision={revision} />
          )}

          {revision && revision.fileId && <RevisionFile revision={revision} />}

          <Stack pt={24} pb={40} px={40} bg="white" style={{ borderRadius: '4px' }} spacing={24}>
            <H3Medium color="gray.7">Záznamy</H3Medium>
            <Stack spacing={0}>
              <Box className="grid grid-cols-[25%_15%_20%_1fr]" p={16} bg="gray.0">
                <P1Medium>Nový stav</P1Medium>
                <P1Medium>Používateľ</P1Medium>
                <P1Medium>Dátum</P1Medium>
                <P1Medium>Poznámka</P1Medium>
              </Box>
              {(revision.logs || []).map((log) => {
                return (
                  <Box
                    py={24}
                    px={16}
                    key={log.revisionChangeLogId}
                    style={{ borderBottom: `1px solid ${theme.colors.gray[1]}`, alignItems: 'flex-start' }}
                    className="grid grid-cols-[25%_15%_20%_1fr]"
                  >
                    <RevisionStatusIcon statusId={log.newStatus.revisionStatusId} />
                    <P2Regular>{log.user?.fullName ?? '-'}</P2Regular>
                    <P2Regular>{new Date(log.timestamp).toLocaleString()}</P2Regular>
                    <P2Regular>
                      <Box
                        sx={{ whiteSpace: 'pre-wrap', b: { fontWeight: 500 } }}
                        dangerouslySetInnerHTML={{ __html: log.note ?? '' }}
                      />
                    </P2Regular>
                  </Box>
                );
              })}
            </Stack>
          </Stack>

          {userCanViewDevices && <RevisionDevices revision={revision} />}

          {revision &&
            revisionData &&
            revision.revisionTemplate?.slug &&
            revision.revisionStatus.slug !== 'confirmed' &&
            revision.revisionStatus.slug !== 'being-signed' &&
            revision.revisionStatus.slug !== 'signed-with-kep' && (
              <Stack spacing={24} py={24} px={40} style={{ borderRadius: '4px' }} bg="white">
                <H3Medium color="gray.7">Náhľad revíznej správy</H3Medium>
                <Box pos="relative">
                  <RevisionPreview height="calc(100vh - 150px)" revisionData={revisionData} revision={revision} />
                </Box>
              </Stack>
            )}
        </Stack>
      )}
      {checkingSignature && (
        <Notification
          color={INFO_NOTIFICATION_COLOR}
          title={
            <Group spacing={8}>
              <Box>Kontrola stavu podpisovania</Box>
              <Loader size={16} />
            </Group>
          }
          style={{ position: 'fixed', bottom: 16, right: 16 }}
          maw={440}
        >
          FMPoint momentálne komunikuje so službou BrainIT, aby zistil stav podpisovania revíznej správy a overuje
          platnosť podpisu. Táto kontrola trvá približne 15 sekúnd.
        </Notification>
      )}
    </DashboardLayout>
  );
}
