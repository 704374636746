import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceUpdatePayload } from 'api/actions/device-update/device-update-payload';
import { DeviceUpdatePathParameters } from 'api/actions/device-update/device-update-path-parameters';
import { DeviceUpdateResponse } from 'api/actions/device-update/device-update-response';

type DeviceUpdateQueryParameters = Record<string, any>;

/**
 * The path of the DeviceUpdate action.
 */
export const deviceUpdateActionPath = new SweetPath<keyof DeviceUpdatePathParameters>('/api/device/update/{deviceId}', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceUpdate actions.
 */
export default function createDeviceUpdateAction(axios: AxiosInstance) {
  return function deviceUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceUpdatePathParameters;
    query?: DeviceUpdateQueryParameters;
    payload: DeviceUpdatePayload;
  }) {
    const path = deviceUpdateActionPath.insert(parameters);

    return axios.put<DeviceUpdateResponse, DeviceUpdateResponse, DeviceUpdatePayload>(path, payload);
  };
}
