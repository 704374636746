import createAuthLoginAction from 'api/actions/auth-login/auth-login-action';
import createAuthLoggedUserInfoAction from 'api/actions/auth-logged-user-info/auth-logged-user-info-action';
import createAuthLogoutAction from 'api/actions/auth-logout/auth-logout-action';
import createAuthLogout_2Action from 'api/actions/auth-logout-2/auth-logout-2-action';
import createAuthForgotPasswordAction from 'api/actions/auth-forgot-password/auth-forgot-password-action';
import createAuthResetPasswordAction from 'api/actions/auth-reset-password/auth-reset-password-action';
import createAuthCheckResetPasswordTokenAction from 'api/actions/auth-check-reset-password-token/auth-check-reset-password-token-action';
import createAuthListRolesAction from 'api/actions/auth-list-roles/auth-list-roles-action';
import createAuthListPermissionsAction from 'api/actions/auth-list-permissions/auth-list-permissions-action';
import createAuthListPermissionTemplatesAction from 'api/actions/auth-list-permission-templates/auth-list-permission-templates-action';
import createAuthListFaultSeveritiesAction from 'api/actions/auth-list-fault-severities/auth-list-fault-severities-action';
import createAuthUserHasPermissionInDepartmentAction from 'api/actions/auth-user-has-permission-in-department/auth-user-has-permission-in-department-action';
import createAuthRemoveUserOrganizationPairsAction from 'api/actions/auth-remove-user-organization-pairs/auth-remove-user-organization-pairs-action';
import createAuthRemoveUserDepartmentPairsAction from 'api/actions/auth-remove-user-department-pairs/auth-remove-user-department-pairs-action';
import createAuthSetUserOrganizationPermissionsAction from 'api/actions/auth-set-user-organization-permissions/auth-set-user-organization-permissions-action';
import createAuthSetUserDepartmentPermissionsAction from 'api/actions/auth-set-user-department-permissions/auth-set-user-department-permissions-action';
import createAuthAddUserOrganizationPairsAction from 'api/actions/auth-add-user-organization-pairs/auth-add-user-organization-pairs-action';
import createAuthAddUserDepartmentPairsAction from 'api/actions/auth-add-user-department-pairs/auth-add-user-department-pairs-action';
import createAuthUserGetPermissionsInDepartmentAction from 'api/actions/auth-user-get-permissions-in-department/auth-user-get-permissions-in-department-action';
import createAuthGetDepartmentsWithPermissionAction from 'api/actions/auth-get-departments-with-permission/auth-get-departments-with-permission-action';
import createAuthGetOrganizationsWithPermissionAction from 'api/actions/auth-get-organizations-with-permission/auth-get-organizations-with-permission-action';
import createAuthLogErrorAction from 'api/actions/auth-log-error/auth-log-error-action';
import createDepartmentListAction from 'api/actions/department-list/department-list-action';
import createDepartmentGetAction from 'api/actions/department-get/department-get-action';
import createDepartmentCreateAction from 'api/actions/department-create/department-create-action';
import createDepartmentUpdateAction from 'api/actions/department-update/department-update-action';
import createDepartmentSetStatusAction from 'api/actions/department-set-status/department-set-status-action';
import createDepartmentGetContactPersonsAction from 'api/actions/department-get-contact-persons/department-get-contact-persons-action';
import createOrganizationListAction from 'api/actions/organization-list/organization-list-action';
import createOrganizationGetAction from 'api/actions/organization-get/organization-get-action';
import createOrganizationCreateAction from 'api/actions/organization-create/organization-create-action';
import createOrganizationUpdateAction from 'api/actions/organization-update/organization-update-action';
import createOrganizationSetStatusAction from 'api/actions/organization-set-status/organization-set-status-action';
import createMeasuringDeviceListAction from 'api/actions/measuring-device-list/measuring-device-list-action';
import createMeasuringDeviceGetAction from 'api/actions/measuring-device-get/measuring-device-get-action';
import createMeasuringDeviceCreateAction from 'api/actions/measuring-device-create/measuring-device-create-action';
import createMeasuringDeviceUpdateAction from 'api/actions/measuring-device-update/measuring-device-update-action';
import createMeasuringDeviceDeleteAction from 'api/actions/measuring-device-delete/measuring-device-delete-action';
import createHealthCheckHealthCheckAction from 'api/actions/health-check-health-check/health-check-health-check-action';
import createPredefinedDataListUsersAction from 'api/actions/predefined-data-list-users/predefined-data-list-users-action';
import createPredefinedDataListGlobalAction from 'api/actions/predefined-data-list-global/predefined-data-list-global-action';
import createPredefinedDataGetUsersAction from 'api/actions/predefined-data-get-users/predefined-data-get-users-action';
import createPredefinedDataGetGlobalAction from 'api/actions/predefined-data-get-global/predefined-data-get-global-action';
import createPredefinedDataCreateUsersAction from 'api/actions/predefined-data-create-users/predefined-data-create-users-action';
import createPredefinedDataCreateGlobalAction from 'api/actions/predefined-data-create-global/predefined-data-create-global-action';
import createPredefinedDataUpdateUsersAction from 'api/actions/predefined-data-update-users/predefined-data-update-users-action';
import createPredefinedDataUpdateGlobalAction from 'api/actions/predefined-data-update-global/predefined-data-update-global-action';
import createPredefinedDataDeleteUsersAction from 'api/actions/predefined-data-delete-users/predefined-data-delete-users-action';
import createPredefinedDataDeleteGlobalAction from 'api/actions/predefined-data-delete-global/predefined-data-delete-global-action';
import createPredefinedDataListTypesAction from 'api/actions/predefined-data-list-types/predefined-data-list-types-action';
import createPredefinedDataListRevisionDataAction from 'api/actions/predefined-data-list-revision-data/predefined-data-list-revision-data-action';
import createDeviceListAction from 'api/actions/device-list/device-list-action';
import createDeviceGetAction from 'api/actions/device-get/device-get-action';
import createDeviceCreateAction from 'api/actions/device-create/device-create-action';
import createDeviceUpdateAction from 'api/actions/device-update/device-update-action';
import createDeviceSetStatusAction from 'api/actions/device-set-status/device-set-status-action';
import createDeviceListAllFaultsAction from 'api/actions/device-list-all-faults/device-list-all-faults-action';
import createDeviceListFaultsAction from 'api/actions/device-list-faults/device-list-faults-action';
import createDeviceMarkFaultFixedAction from 'api/actions/device-mark-fault-fixed/device-mark-fault-fixed-action';
import createDeviceListTypesAction from 'api/actions/device-list-types/device-list-types-action';
import createDeviceListSubtypesAction from 'api/actions/device-list-subtypes/device-list-subtypes-action';
import createDeviceSubtypeCreateAction from 'api/actions/device-subtype-create/device-subtype-create-action';
import createDeviceSubtypeGetAction from 'api/actions/device-subtype-get/device-subtype-get-action';
import createDeviceSubtypeUpdateAction from 'api/actions/device-subtype-update/device-subtype-update-action';
import createDeviceSubtypeSetStatusAction from 'api/actions/device-subtype-set-status/device-subtype-set-status-action';
import createDeviceListRevisionPlanAction from 'api/actions/device-list-revision-plan/device-list-revision-plan-action';
import createDeviceImportAction from 'api/actions/device-import/device-import-action';
import createDeviceResponsiblePersonGetOptionsAction from 'api/actions/device-responsible-person-get-options/device-responsible-person-get-options-action';
import createDeviceRevisionGetOptionsAction from 'api/actions/device-revision-get-options/device-revision-get-options-action';
import createDeviceActiveTypesAction from 'api/actions/device-active-types/device-active-types-action';
import createDeviceListEvidenceFieldsAction from 'api/actions/device-list-evidence-fields/device-list-evidence-fields-action';
import createRevisionListAction from 'api/actions/revision-list/revision-list-action';
import createRevisionGetAction from 'api/actions/revision-get/revision-get-action';
import createRevisionCreateAction from 'api/actions/revision-create/revision-create-action';
import createRevisionUpdateAction from 'api/actions/revision-update/revision-update-action';
import createRevisionSetTemplateAndVisualAction from 'api/actions/revision-set-template-and-visual/revision-set-template-and-visual-action';
import createRevisionSaveDraftAction from 'api/actions/revision-save-draft/revision-save-draft-action';
import createRevisionDeleteAction from 'api/actions/revision-delete/revision-delete-action';
import createRevisionReassignAction from 'api/actions/revision-reassign/revision-reassign-action';
import createRevisionTechnicianDeclineAction from 'api/actions/revision-technician-decline/revision-technician-decline-action';
import createRevisionTechnicianAcceptAction from 'api/actions/revision-technician-accept/revision-technician-accept-action';
import createRevisionTechnicianSubmitAction from 'api/actions/revision-technician-submit/revision-technician-submit-action';
import createRevisionRejectAction from 'api/actions/revision-reject/revision-reject-action';
import createRevisionApproveAction from 'api/actions/revision-approve/revision-approve-action';
import createRevisionResumeWorkAction from 'api/actions/revision-resume-work/revision-resume-work-action';
import createRevisionCustomerReturnAction from 'api/actions/revision-customer-return/revision-customer-return-action';
import createRevisionCustomerConfirmAction from 'api/actions/revision-customer-confirm/revision-customer-confirm-action';
import createRevisionListExecutingCompaniesOptionsAction from 'api/actions/revision-list-executing-companies-options/revision-list-executing-companies-options-action';
import createRevisionListTechniciansAction from 'api/actions/revision-list-technicians/revision-list-technicians-action';
import createRevisionSignDocAction from 'api/actions/revision-sign-doc/revision-sign-doc-action';
import createRevisionSaveSignedDocAction from 'api/actions/revision-save-signed-doc/revision-save-signed-doc-action';
import createRevisionListTemplatesAndVisualsAction from 'api/actions/revision-list-templates-and-visuals/revision-list-templates-and-visuals-action';
import createRevisionGetRevisionTemplateAction from 'api/actions/revision-get-revision-template/revision-get-revision-template-action';
import createRevisionRevisionTemplateCreateAction from 'api/actions/revision-revision-template-create/revision-revision-template-create-action';
import createRevisionRevisionTemplateUpdateAction from 'api/actions/revision-revision-template-update/revision-revision-template-update-action';
import createRevisionRevisionTemplateConfigureAction from 'api/actions/revision-revision-template-configure/revision-revision-template-configure-action';
import createRevisionRevisionTemplateSetStatusAction from 'api/actions/revision-revision-template-set-status/revision-revision-template-set-status-action';
import createUserListAction from 'api/actions/user-list/user-list-action';
import createUserGetAction from 'api/actions/user-get/user-get-action';
import createUserCreateAction from 'api/actions/user-create/user-create-action';
import createUserUpdateMeAction from 'api/actions/user-update-me/user-update-me-action';
import createUserUpdateMyCertificatesAction from 'api/actions/user-update-my-certificates/user-update-my-certificates-action';
import createUserUpdateMyCompanySettingsAction from 'api/actions/user-update-my-company-settings/user-update-my-company-settings-action';
import createUserUpdateAction from 'api/actions/user-update/user-update-action';
import createUserChangePasswordAction from 'api/actions/user-change-password/user-change-password-action';
import createUserAdminChangePasswordAction from 'api/actions/user-admin-change-password/user-admin-change-password-action';
import createUserSetStatusAction from 'api/actions/user-set-status/user-set-status-action';
import createUserGetNotificationSettingsAction from 'api/actions/user-get-notification-settings/user-get-notification-settings-action';
import createUserUpdateNotificationSettingsAction from 'api/actions/user-update-notification-settings/user-update-notification-settings-action';
import createUserGetMyCertificatesAction from 'api/actions/user-get-my-certificates/user-get-my-certificates-action';
import createUserGetMyCompanyQualificationsAction from 'api/actions/user-get-my-company-qualifications/user-get-my-company-qualifications-action';
import createUserGetFollowedDepartmentsListAction from 'api/actions/user-get-followed-departments-list/user-get-followed-departments-list-action';
import { AxiosInstance } from 'axios';

/**
 * All action names.
 */
export type ActionName =
  | 'AuthLogin'
  | 'AuthLoggedUserInfo'
  | 'AuthLogout'
  | 'AuthLogout_2'
  | 'AuthForgotPassword'
  | 'AuthResetPassword'
  | 'AuthCheckResetPasswordToken'
  | 'AuthListRoles'
  | 'AuthListPermissions'
  | 'AuthListPermissionTemplates'
  | 'AuthListFaultSeverities'
  | 'AuthUserHasPermissionInDepartment'
  | 'AuthRemoveUserOrganizationPairs'
  | 'AuthRemoveUserDepartmentPairs'
  | 'AuthSetUserOrganizationPermissions'
  | 'AuthSetUserDepartmentPermissions'
  | 'AuthAddUserOrganizationPairs'
  | 'AuthAddUserDepartmentPairs'
  | 'AuthUserGetPermissionsInDepartment'
  | 'AuthGetDepartmentsWithPermission'
  | 'AuthGetOrganizationsWithPermission'
  | 'AuthLogError'
  | 'DepartmentList'
  | 'DepartmentGet'
  | 'DepartmentCreate'
  | 'DepartmentUpdate'
  | 'DepartmentSetStatus'
  | 'DepartmentGetContactPersons'
  | 'OrganizationList'
  | 'OrganizationGet'
  | 'OrganizationCreate'
  | 'OrganizationUpdate'
  | 'OrganizationSetStatus'
  | 'MeasuringDeviceList'
  | 'MeasuringDeviceGet'
  | 'MeasuringDeviceCreate'
  | 'MeasuringDeviceUpdate'
  | 'MeasuringDeviceDelete'
  | 'HealthCheckHealthCheck'
  | 'PredefinedDataListUsers'
  | 'PredefinedDataListGlobal'
  | 'PredefinedDataGetUsers'
  | 'PredefinedDataGetGlobal'
  | 'PredefinedDataCreateUsers'
  | 'PredefinedDataCreateGlobal'
  | 'PredefinedDataUpdateUsers'
  | 'PredefinedDataUpdateGlobal'
  | 'PredefinedDataDeleteUsers'
  | 'PredefinedDataDeleteGlobal'
  | 'PredefinedDataListTypes'
  | 'PredefinedDataListRevisionData'
  | 'DeviceList'
  | 'DeviceGet'
  | 'DeviceCreate'
  | 'DeviceUpdate'
  | 'DeviceSetStatus'
  | 'DeviceListAllFaults'
  | 'DeviceListFaults'
  | 'DeviceMarkFaultFixed'
  | 'DeviceListTypes'
  | 'DeviceListSubtypes'
  | 'DeviceSubtypeCreate'
  | 'DeviceSubtypeGet'
  | 'DeviceSubtypeUpdate'
  | 'DeviceSubtypeSetStatus'
  | 'DeviceListRevisionPlan'
  | 'DeviceImport'
  | 'DeviceResponsiblePersonGetOptions'
  | 'DeviceRevisionGetOptions'
  | 'DeviceActiveTypes'
  | 'DeviceListEvidenceFields'
  | 'RevisionList'
  | 'RevisionGet'
  | 'RevisionCreate'
  | 'RevisionUpdate'
  | 'RevisionSetTemplateAndVisual'
  | 'RevisionSaveDraft'
  | 'RevisionDelete'
  | 'RevisionReassign'
  | 'RevisionTechnicianDecline'
  | 'RevisionTechnicianAccept'
  | 'RevisionTechnicianSubmit'
  | 'RevisionReject'
  | 'RevisionApprove'
  | 'RevisionResumeWork'
  | 'RevisionCustomerReturn'
  | 'RevisionCustomerConfirm'
  | 'RevisionListExecutingCompaniesOptions'
  | 'RevisionListTechnicians'
  | 'RevisionSignDoc'
  | 'RevisionSaveSignedDoc'
  | 'RevisionListTemplatesAndVisuals'
  | 'RevisionGetRevisionTemplate'
  | 'RevisionRevisionTemplateCreate'
  | 'RevisionRevisionTemplateUpdate'
  | 'RevisionRevisionTemplateConfigure'
  | 'RevisionRevisionTemplateSetStatus'
  | 'UserList'
  | 'UserGet'
  | 'UserCreate'
  | 'UserUpdateMe'
  | 'UserUpdateMyCertificates'
  | 'UserUpdateMyCompanySettings'
  | 'UserUpdate'
  | 'UserChangePassword'
  | 'UserAdminChangePassword'
  | 'UserSetStatus'
  | 'UserGetNotificationSettings'
  | 'UserUpdateNotificationSettings'
  | 'UserGetMyCertificates'
  | 'UserGetMyCompanyQualifications'
  | 'UserGetFollowedDepartmentsList';

/**
 * Overloads for the getAction function.
 */
export type GetActionMap = ((action: 'AuthLogin') => ReturnType<typeof createAuthLoginAction>) &
  ((action: 'AuthLoggedUserInfo') => ReturnType<typeof createAuthLoggedUserInfoAction>) &
  ((action: 'AuthLogout') => ReturnType<typeof createAuthLogoutAction>) &
  ((action: 'AuthLogout_2') => ReturnType<typeof createAuthLogout_2Action>) &
  ((action: 'AuthForgotPassword') => ReturnType<typeof createAuthForgotPasswordAction>) &
  ((action: 'AuthResetPassword') => ReturnType<typeof createAuthResetPasswordAction>) &
  ((action: 'AuthCheckResetPasswordToken') => ReturnType<typeof createAuthCheckResetPasswordTokenAction>) &
  ((action: 'AuthListRoles') => ReturnType<typeof createAuthListRolesAction>) &
  ((action: 'AuthListPermissions') => ReturnType<typeof createAuthListPermissionsAction>) &
  ((action: 'AuthListPermissionTemplates') => ReturnType<typeof createAuthListPermissionTemplatesAction>) &
  ((action: 'AuthListFaultSeverities') => ReturnType<typeof createAuthListFaultSeveritiesAction>) &
  ((action: 'AuthUserHasPermissionInDepartment') => ReturnType<typeof createAuthUserHasPermissionInDepartmentAction>) &
  ((action: 'AuthRemoveUserOrganizationPairs') => ReturnType<typeof createAuthRemoveUserOrganizationPairsAction>) &
  ((action: 'AuthRemoveUserDepartmentPairs') => ReturnType<typeof createAuthRemoveUserDepartmentPairsAction>) &
  ((
    action: 'AuthSetUserOrganizationPermissions'
  ) => ReturnType<typeof createAuthSetUserOrganizationPermissionsAction>) &
  ((action: 'AuthSetUserDepartmentPermissions') => ReturnType<typeof createAuthSetUserDepartmentPermissionsAction>) &
  ((action: 'AuthAddUserOrganizationPairs') => ReturnType<typeof createAuthAddUserOrganizationPairsAction>) &
  ((action: 'AuthAddUserDepartmentPairs') => ReturnType<typeof createAuthAddUserDepartmentPairsAction>) &
  ((
    action: 'AuthUserGetPermissionsInDepartment'
  ) => ReturnType<typeof createAuthUserGetPermissionsInDepartmentAction>) &
  ((action: 'AuthGetDepartmentsWithPermission') => ReturnType<typeof createAuthGetDepartmentsWithPermissionAction>) &
  ((
    action: 'AuthGetOrganizationsWithPermission'
  ) => ReturnType<typeof createAuthGetOrganizationsWithPermissionAction>) &
  ((action: 'AuthLogError') => ReturnType<typeof createAuthLogErrorAction>) &
  ((action: 'DepartmentList') => ReturnType<typeof createDepartmentListAction>) &
  ((action: 'DepartmentGet') => ReturnType<typeof createDepartmentGetAction>) &
  ((action: 'DepartmentCreate') => ReturnType<typeof createDepartmentCreateAction>) &
  ((action: 'DepartmentUpdate') => ReturnType<typeof createDepartmentUpdateAction>) &
  ((action: 'DepartmentSetStatus') => ReturnType<typeof createDepartmentSetStatusAction>) &
  ((action: 'DepartmentGetContactPersons') => ReturnType<typeof createDepartmentGetContactPersonsAction>) &
  ((action: 'OrganizationList') => ReturnType<typeof createOrganizationListAction>) &
  ((action: 'OrganizationGet') => ReturnType<typeof createOrganizationGetAction>) &
  ((action: 'OrganizationCreate') => ReturnType<typeof createOrganizationCreateAction>) &
  ((action: 'OrganizationUpdate') => ReturnType<typeof createOrganizationUpdateAction>) &
  ((action: 'OrganizationSetStatus') => ReturnType<typeof createOrganizationSetStatusAction>) &
  ((action: 'MeasuringDeviceList') => ReturnType<typeof createMeasuringDeviceListAction>) &
  ((action: 'MeasuringDeviceGet') => ReturnType<typeof createMeasuringDeviceGetAction>) &
  ((action: 'MeasuringDeviceCreate') => ReturnType<typeof createMeasuringDeviceCreateAction>) &
  ((action: 'MeasuringDeviceUpdate') => ReturnType<typeof createMeasuringDeviceUpdateAction>) &
  ((action: 'MeasuringDeviceDelete') => ReturnType<typeof createMeasuringDeviceDeleteAction>) &
  ((action: 'HealthCheckHealthCheck') => ReturnType<typeof createHealthCheckHealthCheckAction>) &
  ((action: 'PredefinedDataListUsers') => ReturnType<typeof createPredefinedDataListUsersAction>) &
  ((action: 'PredefinedDataListGlobal') => ReturnType<typeof createPredefinedDataListGlobalAction>) &
  ((action: 'PredefinedDataGetUsers') => ReturnType<typeof createPredefinedDataGetUsersAction>) &
  ((action: 'PredefinedDataGetGlobal') => ReturnType<typeof createPredefinedDataGetGlobalAction>) &
  ((action: 'PredefinedDataCreateUsers') => ReturnType<typeof createPredefinedDataCreateUsersAction>) &
  ((action: 'PredefinedDataCreateGlobal') => ReturnType<typeof createPredefinedDataCreateGlobalAction>) &
  ((action: 'PredefinedDataUpdateUsers') => ReturnType<typeof createPredefinedDataUpdateUsersAction>) &
  ((action: 'PredefinedDataUpdateGlobal') => ReturnType<typeof createPredefinedDataUpdateGlobalAction>) &
  ((action: 'PredefinedDataDeleteUsers') => ReturnType<typeof createPredefinedDataDeleteUsersAction>) &
  ((action: 'PredefinedDataDeleteGlobal') => ReturnType<typeof createPredefinedDataDeleteGlobalAction>) &
  ((action: 'PredefinedDataListTypes') => ReturnType<typeof createPredefinedDataListTypesAction>) &
  ((action: 'PredefinedDataListRevisionData') => ReturnType<typeof createPredefinedDataListRevisionDataAction>) &
  ((action: 'DeviceList') => ReturnType<typeof createDeviceListAction>) &
  ((action: 'DeviceGet') => ReturnType<typeof createDeviceGetAction>) &
  ((action: 'DeviceCreate') => ReturnType<typeof createDeviceCreateAction>) &
  ((action: 'DeviceUpdate') => ReturnType<typeof createDeviceUpdateAction>) &
  ((action: 'DeviceSetStatus') => ReturnType<typeof createDeviceSetStatusAction>) &
  ((action: 'DeviceListAllFaults') => ReturnType<typeof createDeviceListAllFaultsAction>) &
  ((action: 'DeviceListFaults') => ReturnType<typeof createDeviceListFaultsAction>) &
  ((action: 'DeviceMarkFaultFixed') => ReturnType<typeof createDeviceMarkFaultFixedAction>) &
  ((action: 'DeviceListTypes') => ReturnType<typeof createDeviceListTypesAction>) &
  ((action: 'DeviceListSubtypes') => ReturnType<typeof createDeviceListSubtypesAction>) &
  ((action: 'DeviceSubtypeCreate') => ReturnType<typeof createDeviceSubtypeCreateAction>) &
  ((action: 'DeviceSubtypeGet') => ReturnType<typeof createDeviceSubtypeGetAction>) &
  ((action: 'DeviceSubtypeUpdate') => ReturnType<typeof createDeviceSubtypeUpdateAction>) &
  ((action: 'DeviceSubtypeSetStatus') => ReturnType<typeof createDeviceSubtypeSetStatusAction>) &
  ((action: 'DeviceListRevisionPlan') => ReturnType<typeof createDeviceListRevisionPlanAction>) &
  ((action: 'DeviceImport') => ReturnType<typeof createDeviceImportAction>) &
  ((action: 'DeviceResponsiblePersonGetOptions') => ReturnType<typeof createDeviceResponsiblePersonGetOptionsAction>) &
  ((action: 'DeviceRevisionGetOptions') => ReturnType<typeof createDeviceRevisionGetOptionsAction>) &
  ((action: 'DeviceActiveTypes') => ReturnType<typeof createDeviceActiveTypesAction>) &
  ((action: 'DeviceListEvidenceFields') => ReturnType<typeof createDeviceListEvidenceFieldsAction>) &
  ((action: 'RevisionList') => ReturnType<typeof createRevisionListAction>) &
  ((action: 'RevisionGet') => ReturnType<typeof createRevisionGetAction>) &
  ((action: 'RevisionCreate') => ReturnType<typeof createRevisionCreateAction>) &
  ((action: 'RevisionUpdate') => ReturnType<typeof createRevisionUpdateAction>) &
  ((action: 'RevisionSetTemplateAndVisual') => ReturnType<typeof createRevisionSetTemplateAndVisualAction>) &
  ((action: 'RevisionSaveDraft') => ReturnType<typeof createRevisionSaveDraftAction>) &
  ((action: 'RevisionDelete') => ReturnType<typeof createRevisionDeleteAction>) &
  ((action: 'RevisionReassign') => ReturnType<typeof createRevisionReassignAction>) &
  ((action: 'RevisionTechnicianDecline') => ReturnType<typeof createRevisionTechnicianDeclineAction>) &
  ((action: 'RevisionTechnicianAccept') => ReturnType<typeof createRevisionTechnicianAcceptAction>) &
  ((action: 'RevisionTechnicianSubmit') => ReturnType<typeof createRevisionTechnicianSubmitAction>) &
  ((action: 'RevisionReject') => ReturnType<typeof createRevisionRejectAction>) &
  ((action: 'RevisionApprove') => ReturnType<typeof createRevisionApproveAction>) &
  ((action: 'RevisionResumeWork') => ReturnType<typeof createRevisionResumeWorkAction>) &
  ((action: 'RevisionCustomerReturn') => ReturnType<typeof createRevisionCustomerReturnAction>) &
  ((action: 'RevisionCustomerConfirm') => ReturnType<typeof createRevisionCustomerConfirmAction>) &
  ((
    action: 'RevisionListExecutingCompaniesOptions'
  ) => ReturnType<typeof createRevisionListExecutingCompaniesOptionsAction>) &
  ((action: 'RevisionListTechnicians') => ReturnType<typeof createRevisionListTechniciansAction>) &
  ((action: 'RevisionSignDoc') => ReturnType<typeof createRevisionSignDocAction>) &
  ((action: 'RevisionSaveSignedDoc') => ReturnType<typeof createRevisionSaveSignedDocAction>) &
  ((action: 'RevisionListTemplatesAndVisuals') => ReturnType<typeof createRevisionListTemplatesAndVisualsAction>) &
  ((action: 'RevisionGetRevisionTemplate') => ReturnType<typeof createRevisionGetRevisionTemplateAction>) &
  ((action: 'RevisionRevisionTemplateCreate') => ReturnType<typeof createRevisionRevisionTemplateCreateAction>) &
  ((action: 'RevisionRevisionTemplateUpdate') => ReturnType<typeof createRevisionRevisionTemplateUpdateAction>) &
  ((action: 'RevisionRevisionTemplateConfigure') => ReturnType<typeof createRevisionRevisionTemplateConfigureAction>) &
  ((action: 'RevisionRevisionTemplateSetStatus') => ReturnType<typeof createRevisionRevisionTemplateSetStatusAction>) &
  ((action: 'UserList') => ReturnType<typeof createUserListAction>) &
  ((action: 'UserGet') => ReturnType<typeof createUserGetAction>) &
  ((action: 'UserCreate') => ReturnType<typeof createUserCreateAction>) &
  ((action: 'UserUpdateMe') => ReturnType<typeof createUserUpdateMeAction>) &
  ((action: 'UserUpdateMyCertificates') => ReturnType<typeof createUserUpdateMyCertificatesAction>) &
  ((action: 'UserUpdateMyCompanySettings') => ReturnType<typeof createUserUpdateMyCompanySettingsAction>) &
  ((action: 'UserUpdate') => ReturnType<typeof createUserUpdateAction>) &
  ((action: 'UserChangePassword') => ReturnType<typeof createUserChangePasswordAction>) &
  ((action: 'UserAdminChangePassword') => ReturnType<typeof createUserAdminChangePasswordAction>) &
  ((action: 'UserSetStatus') => ReturnType<typeof createUserSetStatusAction>) &
  ((action: 'UserGetNotificationSettings') => ReturnType<typeof createUserGetNotificationSettingsAction>) &
  ((action: 'UserUpdateNotificationSettings') => ReturnType<typeof createUserUpdateNotificationSettingsAction>) &
  ((action: 'UserGetMyCertificates') => ReturnType<typeof createUserGetMyCertificatesAction>) &
  ((action: 'UserGetMyCompanyQualifications') => ReturnType<typeof createUserGetMyCompanyQualificationsAction>) &
  ((action: 'UserGetFollowedDepartmentsList') => ReturnType<typeof createUserGetFollowedDepartmentsListAction>);

/**
 * The map from action name to action creator.
 */
export const actions: Map<ActionName, (axios: AxiosInstance) => Promise<any>> = new Map([
  ['AuthLogin', createAuthLoginAction],
  ['AuthLoggedUserInfo', createAuthLoggedUserInfoAction],
  ['AuthLogout', createAuthLogoutAction],
  ['AuthLogout_2', createAuthLogout_2Action],
  ['AuthForgotPassword', createAuthForgotPasswordAction],
  ['AuthResetPassword', createAuthResetPasswordAction],
  ['AuthCheckResetPasswordToken', createAuthCheckResetPasswordTokenAction],
  ['AuthListRoles', createAuthListRolesAction],
  ['AuthListPermissions', createAuthListPermissionsAction],
  ['AuthListPermissionTemplates', createAuthListPermissionTemplatesAction],
  ['AuthListFaultSeverities', createAuthListFaultSeveritiesAction],
  ['AuthUserHasPermissionInDepartment', createAuthUserHasPermissionInDepartmentAction],
  ['AuthRemoveUserOrganizationPairs', createAuthRemoveUserOrganizationPairsAction],
  ['AuthRemoveUserDepartmentPairs', createAuthRemoveUserDepartmentPairsAction],
  ['AuthSetUserOrganizationPermissions', createAuthSetUserOrganizationPermissionsAction],
  ['AuthSetUserDepartmentPermissions', createAuthSetUserDepartmentPermissionsAction],
  ['AuthAddUserOrganizationPairs', createAuthAddUserOrganizationPairsAction],
  ['AuthAddUserDepartmentPairs', createAuthAddUserDepartmentPairsAction],
  ['AuthUserGetPermissionsInDepartment', createAuthUserGetPermissionsInDepartmentAction],
  ['AuthGetDepartmentsWithPermission', createAuthGetDepartmentsWithPermissionAction],
  ['AuthGetOrganizationsWithPermission', createAuthGetOrganizationsWithPermissionAction],
  ['AuthLogError', createAuthLogErrorAction],
  ['DepartmentList', createDepartmentListAction],
  ['DepartmentGet', createDepartmentGetAction],
  ['DepartmentCreate', createDepartmentCreateAction],
  ['DepartmentUpdate', createDepartmentUpdateAction],
  ['DepartmentSetStatus', createDepartmentSetStatusAction],
  ['DepartmentGetContactPersons', createDepartmentGetContactPersonsAction],
  ['OrganizationList', createOrganizationListAction],
  ['OrganizationGet', createOrganizationGetAction],
  ['OrganizationCreate', createOrganizationCreateAction],
  ['OrganizationUpdate', createOrganizationUpdateAction],
  ['OrganizationSetStatus', createOrganizationSetStatusAction],
  ['MeasuringDeviceList', createMeasuringDeviceListAction],
  ['MeasuringDeviceGet', createMeasuringDeviceGetAction],
  ['MeasuringDeviceCreate', createMeasuringDeviceCreateAction],
  ['MeasuringDeviceUpdate', createMeasuringDeviceUpdateAction],
  ['MeasuringDeviceDelete', createMeasuringDeviceDeleteAction],
  ['HealthCheckHealthCheck', createHealthCheckHealthCheckAction],
  ['PredefinedDataListUsers', createPredefinedDataListUsersAction],
  ['PredefinedDataListGlobal', createPredefinedDataListGlobalAction],
  ['PredefinedDataGetUsers', createPredefinedDataGetUsersAction],
  ['PredefinedDataGetGlobal', createPredefinedDataGetGlobalAction],
  ['PredefinedDataCreateUsers', createPredefinedDataCreateUsersAction],
  ['PredefinedDataCreateGlobal', createPredefinedDataCreateGlobalAction],
  ['PredefinedDataUpdateUsers', createPredefinedDataUpdateUsersAction],
  ['PredefinedDataUpdateGlobal', createPredefinedDataUpdateGlobalAction],
  ['PredefinedDataDeleteUsers', createPredefinedDataDeleteUsersAction],
  ['PredefinedDataDeleteGlobal', createPredefinedDataDeleteGlobalAction],
  ['PredefinedDataListTypes', createPredefinedDataListTypesAction],
  ['PredefinedDataListRevisionData', createPredefinedDataListRevisionDataAction],
  ['DeviceList', createDeviceListAction],
  ['DeviceGet', createDeviceGetAction],
  ['DeviceCreate', createDeviceCreateAction],
  ['DeviceUpdate', createDeviceUpdateAction],
  ['DeviceSetStatus', createDeviceSetStatusAction],
  ['DeviceListAllFaults', createDeviceListAllFaultsAction],
  ['DeviceListFaults', createDeviceListFaultsAction],
  ['DeviceMarkFaultFixed', createDeviceMarkFaultFixedAction],
  ['DeviceListTypes', createDeviceListTypesAction],
  ['DeviceListSubtypes', createDeviceListSubtypesAction],
  ['DeviceSubtypeCreate', createDeviceSubtypeCreateAction],
  ['DeviceSubtypeGet', createDeviceSubtypeGetAction],
  ['DeviceSubtypeUpdate', createDeviceSubtypeUpdateAction],
  ['DeviceSubtypeSetStatus', createDeviceSubtypeSetStatusAction],
  ['DeviceListRevisionPlan', createDeviceListRevisionPlanAction],
  ['DeviceImport', createDeviceImportAction],
  ['DeviceResponsiblePersonGetOptions', createDeviceResponsiblePersonGetOptionsAction],
  ['DeviceRevisionGetOptions', createDeviceRevisionGetOptionsAction],
  ['DeviceActiveTypes', createDeviceActiveTypesAction],
  ['DeviceListEvidenceFields', createDeviceListEvidenceFieldsAction],
  ['RevisionList', createRevisionListAction],
  ['RevisionGet', createRevisionGetAction],
  ['RevisionCreate', createRevisionCreateAction],
  ['RevisionUpdate', createRevisionUpdateAction],
  ['RevisionSetTemplateAndVisual', createRevisionSetTemplateAndVisualAction],
  ['RevisionSaveDraft', createRevisionSaveDraftAction],
  ['RevisionDelete', createRevisionDeleteAction],
  ['RevisionReassign', createRevisionReassignAction],
  ['RevisionTechnicianDecline', createRevisionTechnicianDeclineAction],
  ['RevisionTechnicianAccept', createRevisionTechnicianAcceptAction],
  ['RevisionTechnicianSubmit', createRevisionTechnicianSubmitAction],
  ['RevisionReject', createRevisionRejectAction],
  ['RevisionApprove', createRevisionApproveAction],
  ['RevisionResumeWork', createRevisionResumeWorkAction],
  ['RevisionCustomerReturn', createRevisionCustomerReturnAction],
  ['RevisionCustomerConfirm', createRevisionCustomerConfirmAction],
  ['RevisionListExecutingCompaniesOptions', createRevisionListExecutingCompaniesOptionsAction],
  ['RevisionListTechnicians', createRevisionListTechniciansAction],
  ['RevisionSignDoc', createRevisionSignDocAction],
  ['RevisionSaveSignedDoc', createRevisionSaveSignedDocAction],
  ['RevisionListTemplatesAndVisuals', createRevisionListTemplatesAndVisualsAction],
  ['RevisionGetRevisionTemplate', createRevisionGetRevisionTemplateAction],
  ['RevisionRevisionTemplateCreate', createRevisionRevisionTemplateCreateAction],
  ['RevisionRevisionTemplateUpdate', createRevisionRevisionTemplateUpdateAction],
  ['RevisionRevisionTemplateConfigure', createRevisionRevisionTemplateConfigureAction],
  ['RevisionRevisionTemplateSetStatus', createRevisionRevisionTemplateSetStatusAction],
  ['UserList', createUserListAction],
  ['UserGet', createUserGetAction],
  ['UserCreate', createUserCreateAction],
  ['UserUpdateMe', createUserUpdateMeAction],
  ['UserUpdateMyCertificates', createUserUpdateMyCertificatesAction],
  ['UserUpdateMyCompanySettings', createUserUpdateMyCompanySettingsAction],
  ['UserUpdate', createUserUpdateAction],
  ['UserChangePassword', createUserChangePasswordAction],
  ['UserAdminChangePassword', createUserAdminChangePasswordAction],
  ['UserSetStatus', createUserSetStatusAction],
  ['UserGetNotificationSettings', createUserGetNotificationSettingsAction],
  ['UserUpdateNotificationSettings', createUserUpdateNotificationSettingsAction],
  ['UserGetMyCertificates', createUserGetMyCertificatesAction],
  ['UserGetMyCompanyQualifications', createUserGetMyCompanyQualificationsAction],
  ['UserGetFollowedDepartmentsList', createUserGetFollowedDepartmentsListAction],
] as any);
