import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import ApiProvider from 'api/api-provider';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import { router } from 'routes/AppRoutes';
import theme from 'theme';
import FileManagerProvider from 'api/file-manager/file-manager-provider';
import { PickDeviceTypeProvider } from 'components/modals/pick-device-type/PickDeviceTypeProvider';
import MessageProvider from 'components/modals/message/MessageProvider';
import { AddDeviceFileProvider } from 'components/modals/add-device-file/AddDeviceFileProvider';
import { PickUsersProvider } from 'components/modals/pick-users-modal/PickUsersProvider';
import { PermissionDataProvider } from 'model/PermissionDataProvider';
import { FixDeviceFaultProvider } from 'components/modals/fix-device-fault/FixDeviceFaultProvider';
import ConfirmWithMessageProvider from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import { DndProvider } from 'react-dnd';
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview';
import { PickOrganizationsProvider } from 'components/modals/pick-organizations-modal/PickOrganizationsProvider';
import { PickDepartmentsProvider } from 'components/modals/pick-departments-modal/PickDepartmentsProvider';
import { PickDevicesProvider } from 'components/modals/pick-devices-modal/PickDevicesProvider';
import { DefineEvidenceFieldProvider } from 'components/forms/device-subtype/DefineEvidenceFieldProvider';

// Firebase
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';
import {
  FEATURE_TOGGLE_FIREBASE,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from 'env';
import SignRevisionModalProvider from 'components/modals/sign-revision-modal/SignRevisionModalProvider';

if (FEATURE_TOGGLE_FIREBASE) {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  // Initialize Firebase
  const firebase = initializeApp(firebaseConfig);
  getPerformance(firebase);
}

/**
 * The entry point of the application.
 */
export default function App() {
  return (
    <React.StrictMode>
      <MantineProvider withGlobalStyles withNormalizeCSS withCSSVariables theme={theme}>
        <Notifications autoClose={8000} />
        <ModalsProvider>
          <MessageProvider>
            <ConfirmWithMessageProvider>
              <PickDeviceTypeProvider>
                <ApiProvider>
                  <SignRevisionModalProvider>
                    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                      <PermissionDataProvider>
                        <PickUsersProvider>
                          <PickOrganizationsProvider>
                            <PickDepartmentsProvider>
                              <PickDevicesProvider>
                                <FileManagerProvider>
                                  <AddDeviceFileProvider>
                                    <FixDeviceFaultProvider>
                                      <DefineEvidenceFieldProvider>
                                        <RouterProvider router={router} />
                                      </DefineEvidenceFieldProvider>
                                    </FixDeviceFaultProvider>
                                  </AddDeviceFileProvider>
                                </FileManagerProvider>
                              </PickDevicesProvider>
                            </PickDepartmentsProvider>
                          </PickOrganizationsProvider>
                        </PickUsersProvider>
                      </PermissionDataProvider>
                    </DndProvider>
                  </SignRevisionModalProvider>
                </ApiProvider>
              </PickDeviceTypeProvider>
            </ConfirmWithMessageProvider>
          </MessageProvider>
        </ModalsProvider>
      </MantineProvider>
    </React.StrictMode>
  );
}
