import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceGetPathParameters } from 'api/actions/device-get/device-get-path-parameters';
import { DeviceGetResponse } from 'api/actions/device-get/device-get-response';

type DeviceGetQueryParameters = Record<string, any>;

/**
 * The path of the DeviceGet action.
 */
export const deviceGetActionPath = new SweetPath<keyof DeviceGetPathParameters>('/api/device/get/{deviceId}', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceGet actions.
 */
export default function createDeviceGetAction(axios: AxiosInstance) {
  return function deviceGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceGetPathParameters;
    query?: DeviceGetQueryParameters;
  }) {
    const path = deviceGetActionPath.insert(parameters);

    return axios.get<DeviceGetResponse, DeviceGetResponse>(path, { params: query, headers });
  };
}
