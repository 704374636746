/**
 * Retrieve an environment variable. A different default value can be specified
 * for each environment. A single default value can be specified for all
 * environments.
 */
export default function getenv(
  name: string,
  defaultValue: { development: any; test: any; production: any } | string
): string {
  const env = process.env.NODE_ENV as 'development' | 'test' | 'production' | undefined;

  if (typeof defaultValue === 'string') {
    return defaultValue;
  }

  if (env && Object.prototype.hasOwnProperty.call(defaultValue, env)) {
    return defaultValue[env];
  }

  throw new Error(`Environment variable is not set: ${name}.`);
}
