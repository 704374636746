import P2Regular from 'components/typography/P2Regular';
import { Box, Group, Tooltip } from '@mantine/core';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import P3Regular from 'components/typography/P3Regular';
import { getDeviceType } from 'model/DeviceType';

/**
 * A single qualification.
 */
interface Qualification {
  qualificationId?: number;
  qualificationNumber: string;
  deviceType?: { deviceTypeName: string; deviceTypeId: number; slug: string };
  deviceTypeId: number;
}

/**
 * Parameters of the QualificationList component.
 */
export interface QualificationListProps {
  qualifications: Qualification[];
  limit?: number;
}

/**
 * A list of qualifications.
 */
export default function QualificationList({ qualifications, limit = 2 }: QualificationListProps) {
  if (qualifications.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group noWrap spacing={4}>
      {qualifications.map(({ qualificationId, deviceTypeId, deviceType, qualificationNumber }) => (
        <Tooltip
          bg="gray.2"
          key={qualificationId}
          withArrow
          label={
            <Group spacing={4}>
              <P2Regular color="gray.8">{deviceType?.deviceTypeName || getDeviceType(deviceTypeId)?.name}</P2Regular>
              <P3Regular color="gray.7">({qualificationNumber})</P3Regular>
            </Group>
          }
        >
          <Box p={4}>
            <DeviceTypeLabel iconOnly deviceType={deviceType?.slug || getDeviceType(deviceTypeId)?.slug || ''} />
          </Box>
        </Tooltip>
      ))}
    </Group>
  );
}
