import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconNewSection } from '@tabler/icons-react';

/**
 * Used to insert a section end into the editor.
 */
export default function InsertSectionEnd({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconNewSection />}
      label="Koniec sekcie"
      onClick={() => editor?.chain().focus().insertPageBreak({ force: false }).run()}
    />
  );
}
