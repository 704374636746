import { IRowNode } from 'ag-grid-community';
import DeviceSubtypesActions from 'components/tables/device-subtypes/DeviceSubtypesActions';
import { useParams } from 'react-router-dom';
import { DeviceSubtypeRow } from 'components/tables/device-subtypes/types';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<DeviceSubtypeRow>; data: DeviceSubtypeRow }) {
  const { deviceTypeId } = useParams();

  return (
    <DeviceSubtypesActions
      deviceTypeId={deviceTypeId ? Number(deviceTypeId) : 0}
      deviceSubtypeId={data.deviceTypeId}
      deviceSubtypeName={data.deviceTypeName}
      status={data.status!}
      canDiscard={data.canDiscard!}
      isOrganization={data.isOrganization!}
      isDepartment={data.isDepartment!}
      organizations={data.organizations!}
      departments={data.departments!}
      onStatusChange={(status) => node.setData({ ...data, status })}
    />
  );
}
