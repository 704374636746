import { Box, Button, Card, Divider, Group, Skeleton, Stack, Tooltip } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { DeviceListRevisionPlanResponse } from 'api/actions/device-list-revision-plan/device-list-revision-plan-response';
import { useApi } from 'api/api-context';
import DateDisplay from 'components/DateDisplay';
import NumberBadge from 'components/NumberBadge';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import RevisionPlanActions from 'components/tables/revision-plan/RevisionPlanActions';
import H3Medium from 'components/typography/H3Medium';
import P2Regular from 'components/typography/P2Regular';
import P3Regular from 'components/typography/P3Regular';
import panic from 'errors/panic';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DEVICE_REVISION_PLANS_PAGE_PATH } from 'routes/paths';

/**
 * Parameters of the RevisionPlanTableCard component.
 */
export interface RevisionPlanTableCardProps {
  limit?: number;
}

/**
 * Displays a simplified table of revision plans.
 */
export default function RevisionPlanTableCard({ limit = 5 }: RevisionPlanTableCardProps = {}) {
  const { getAction } = useApi();
  const [plans, setPlans] = useState<DeviceListRevisionPlanResponse>([]);
  const [loading, setLoading] = useState(true);
  const somePlans = useMemo(() => plans.slice(0, limit), [plans]);

  useEffect(() => {
    const action = getAction('DeviceListRevisionPlan');

    action({ query: { filters: { 'includedInStatistics.eq': 1 } } })
      .then(setPlans)
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Skeleton radius={4} w="100%" mih={400} />;
  }

  return (
    <Card p={24} h="fit-content">
      <Group position="apart" pb={24}>
        <Group spacing={4}>
          <H3Medium color="gray.8">Bližiace sa termíny zariadení</H3Medium>
          <Box sx={{ transform: 'scale(0.66)' }}>
            <NumberBadge variant="gray" value={plans.length} />
          </Box>
        </Group>
        <Button
          component={Link}
          to={DEVICE_REVISION_PLANS_PAGE_PATH.original}
          size="sm"
          variant="subtle-compact"
          rightIcon={<IconChevronRight size={16} />}
        >
          Pozrieť všetko
        </Button>
      </Group>
      {somePlans.map((plan) => (
        <Stack key={plan.revisionPlanId} spacing={0}>
          <Divider color="gray.1" />
          <Group noWrap spacing={16} pt={12} pb={16} position="apart">
            <Stack spacing={4}>
              <Group spacing={12}>
                <Group mr={12} noWrap spacing={4}>
                  <Tooltip label={plan.deviceType.deviceTypeName}>
                    <Box>
                      <DeviceTypeLabel iconOnly deviceType={plan.deviceType.deviceTypeId} />
                    </Box>
                  </Tooltip>
                  <Group noWrap spacing={4}>
                    <P2Regular color="gray.8">{plan.device.deviceName}</P2Regular>
                  </Group>
                </Group>
                {plan.nextRevision ? <DateDisplay date={new Date(plan.nextRevision)} /> : '-'}
              </Group>
              <P3Regular color="gray.7">
                {plan.type} • {plan.organization.organizationName} ({plan.department.departmentName})
              </P3Regular>
            </Stack>
            <RevisionPlanActions
              {...plan}
              deviceId={plan.device.deviceId}
              deviceName={plan.device.deviceName}
              manufactured={plan.device.manufactured}
              manufacturer={plan.device.manufacturer}
              serialNumber={plan.device.serialNumber}
            />
          </Group>
        </Stack>
      ))}
      <Divider color="gray.1" />
      <Group pt={16} position="apart">
        <Button
          component={Link}
          to={DEVICE_REVISION_PLANS_PAGE_PATH.original}
          variant="subtle-gray"
          size="sm"
          rightIcon={<IconChevronRight size={16} />}
        >
          Všetky blížiace sa termíny ({plans.length})
        </Button>
        <P3Regular color="gray.7">
          Zobrazené: {somePlans.length} / {plans.length}
        </P3Regular>
      </Group>
    </Card>
  );
}
