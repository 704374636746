import { Select, SelectProps } from '@mantine/core';

type RevisionPeriodSelectProps = Omit<SelectProps, 'data'>;

export const REVISION_PERIOD_DATA = [
  { label: '1 m', value: '1' },
  { label: '3 m', value: '3' },
  { label: '6 m', value: '6' },
  { label: '1 r', value: '12' },
  { label: '2 r', value: '24' },
  { label: '3 r', value: '36' },
  { label: '4 r', value: '48' },
  { label: '5 r', value: '60' },
  { label: '6 r', value: '72' },
  { label: '9 r', value: '108' },
  { label: '10 r', value: '120' },
] as const;

/**
 * Translates the month period to a human readable format.
 */
export const translateRevisionPeriod = (period: number) => {
  const keyValue = REVISION_PERIOD_DATA.find(({ value }) => Number(value) === period);

  if (keyValue) {
    return keyValue.label;
  }

  return '-';
};

/**
 * Select for revision period.
 */
export default function RevisionPeriodSelect({ ...props }: RevisionPeriodSelectProps) {
  return <Select data={REVISION_PERIOD_DATA} searchable {...props} />;
}
