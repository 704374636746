import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconListNumbers } from '@tabler/icons-react';

/**
 * Used to insert ordered list.
 */
export default function ControlOrderedList() {
  const { editor } = useRichTextEditorContext();
  return (
    <Tooltip label="Ordered list" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleOrderedList().run()}
        active={editor?.isActive('orderedList')}
      >
        <IconListNumbers size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
