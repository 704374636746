import { rem } from '@mantine/core';
import { GridApi } from 'ag-grid-community';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { useCallback, useEffect, useState } from 'react';
import { FILTER_KEY as DEVICE_TYPE_SELECT_FILTER_KEY } from 'components/tables/filters/DeviceTypeSelectFilter';

export interface DeviceSubtypeSelectFilterBaseProps {
  api: GridApi;
  context: any;
}

/**
 * Parameters of the DeviceSubtypeSelectFilter component.
 */
interface DeviceSubtypeSelectFilterProps extends DeviceSubtypeSelectFilterBaseProps {
  filterKey: string;
  filterType: 'contains' | 'equals';
}

/**
 * Filter component for the deviceSubtype column.
 *
 * This filter assumes:
 *   - column field is `filterKey`
 *   - context contains `initialFilters` with `filterKey` filter
 */
export default function DeviceSubtypeSelectFilter({
  filterKey,
  filterType,
  api,
  context,
}: DeviceSubtypeSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[filterKey]?.filter);
  const [parentDeviceTypeId, setParentDeviceTypeId] = useState<number | undefined>(0);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [filterKey]: { filterType: 'text', type: filterType, filter: value },
      });
    },
    [api, setValue, filterKey, filterType]
  );

  useEffect(() => {
    /**
     * Handles the change of the parent device type id.
     */
    const onFilterChanged = () => {
      const parentDeviceTypeId = api.getFilterModel()[DEVICE_TYPE_SELECT_FILTER_KEY]?.filter ?? 0;
      setParentDeviceTypeId(parentDeviceTypeId);
    };

    api.addEventListener('filterChanged', onFilterChanged);
    return () => api.removeEventListener('filterChanged', onFilterChanged);
  }, []);

  return (
    <DeviceTypeSelect
      lazyLoad
      parentDeviceTypeId={parentDeviceTypeId}
      listSubtypes={true}
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy zariadení"
      value={value}
      onChange={onChange}
      styles={{ dropdown: { minWidth: rem(250) } }}
    />
  );
}
