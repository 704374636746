import { MantineThemeOverride } from '@mantine/core';

export type AppShellTheme = NonNullable<MantineThemeOverride['components']>['AppShell'];

export const AppShell: AppShellTheme = {
  styles: (theme) => ({
    main: {
      backgroundColor: theme.colors.gray[1],
    },
  }),
};
