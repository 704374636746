import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceSubtypeCreatePayload } from 'api/actions/device-subtype-create/device-subtype-create-payload';
import { DeviceSubtypeCreatePathParameters } from 'api/actions/device-subtype-create/device-subtype-create-path-parameters';
import { DeviceSubtypeCreateResponse } from 'api/actions/device-subtype-create/device-subtype-create-response';

type DeviceSubtypeCreateQueryParameters = Record<string, any>;

/**
 * The path of the DeviceSubtypeCreate action.
 */
export const deviceSubtypeCreateActionPath = new SweetPath<keyof DeviceSubtypeCreatePathParameters>(
  '/api/device/subtypes/{deviceTypeId}/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceSubtypeCreate actions.
 */
export default function createDeviceSubtypeCreateAction(axios: AxiosInstance) {
  return function deviceSubtypeCreateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceSubtypeCreatePathParameters;
    query?: DeviceSubtypeCreateQueryParameters;
    payload: DeviceSubtypeCreatePayload;
  }) {
    const path = deviceSubtypeCreateActionPath.insert(parameters);

    return axios.post<DeviceSubtypeCreateResponse, DeviceSubtypeCreateResponse, DeviceSubtypeCreatePayload>(
      path,
      payload
    );
  };
}
