import { Box, NumberInput, TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DeviceGetResponse } from 'api/actions/device-get/device-get-response';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import { DateInput } from 'components/inputs/DateInput';

export type DeviceEvidenceFieldType = DeviceGetResponse['evidenceFields'][number];

type EvidenceFieldProps = DeviceEvidenceFieldType & {
  form: UseFormReturnType<DeviceFormData>;
};
/**
 * Displays a single evidence field with correct input based on its type.
 */
export default function DeviceEvidenceField({
  fieldName,
  fieldType,
  isRequired,
  evidenceFieldId,
  form,
}: EvidenceFieldProps) {
  let input;

  switch (fieldType) {
    case 'text':
      input = (
        <TextInput
          size="lg"
          label={fieldName}
          placeholder={fieldName}
          {...form.getInputProps(`evidenceFields.${evidenceFieldId!}.fieldValue`)}
          required={isRequired}
        />
      );
      break;
    case 'number':
      input = (
        <NumberInput
          size="lg"
          label={fieldName}
          placeholder={fieldName}
          hideControls
          {...form.getInputProps(`evidenceFields.${evidenceFieldId!}.fieldNumberValue`)}
          required={isRequired}
        />
      );
      break;
    case 'date':
      input = (
        <DateInput
          size="lg"
          label={fieldName}
          placeholder={fieldName}
          {...form.getInputProps(`evidenceFields.${evidenceFieldId!}.fieldDateValue`)}
          required={isRequired}
        />
      );
      break;
    default:
      input = <Box>Neznámy typ evidenčného poľa {fieldType}</Box>;
  }

  return input;
}
