import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserUpdateMePayload } from 'api/actions/user-update-me/user-update-me-payload';
import { UserUpdateMePathParameters } from 'api/actions/user-update-me/user-update-me-path-parameters';
import { UserUpdateMeResponse } from 'api/actions/user-update-me/user-update-me-response';

type UserUpdateMeQueryParameters = Record<string, any>;

/**
 * The path of the UserUpdateMe action.
 */
export const userUpdateMeActionPath = new SweetPath<keyof UserUpdateMePathParameters>('/api/user/update', {
  path: '{param}',
});

/**
 * Creates a contextualized UserUpdateMe actions.
 */
export default function createUserUpdateMeAction(axios: AxiosInstance) {
  return function userUpdateMeAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UserUpdateMeQueryParameters;
    payload: UserUpdateMePayload;
  }) {
    const path = userUpdateMeActionPath.original;

    return axios.put<UserUpdateMeResponse, UserUpdateMeResponse, UserUpdateMePayload>(path, payload);
  };
}
