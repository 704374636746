import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates that the password is not shorter than 8 characters, has at least one upper case, one lower case letter, one digit and one special symbol.
 */
export default function passwordFormat(value: string): ValidationError | null {
  if (value.length < 8) {
    return { message: 'Heslo musí mať aspoň 8 znakov' };
  }

  const errors = [];

  if (!/[A-Z]/.test(value)) {
    errors.push('jedno veľké písmeno');
  }

  if (!/[a-z]/.test(value)) {
    errors.push('jedno malé písmeno');
  }

  if (!/[0-9]/.test(value)) {
    errors.push('jednu číslicu');
  }

  if (!/[^A-Za-z0-9]/.test(value)) {
    errors.push('jeden špeciálny znak');
  }

  if (errors.length > 0) {
    const last = errors.pop();

    if (errors.length === 0) {
      return { message: `Heslo musí obsahovať aspoň ${last}` };
    }

    return { message: `Heslo musí obsahovať aspoň ${errors.join(', ')} a ${last}` };
  }

  return null;
}
