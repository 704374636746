import { Box, Group } from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import TemplateEditorTab from 'pages/revisions-module/template-editor/navigation/TemplateEditorTab';
import SplitScreenButton from 'pages/revisions-module/template-editor/navigation/SplitScreenButton';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS } from 'env';

/**
 * Props for the TemplateEditorNavigation component.
 */
interface TemplateEditorNavigationProps {
  value: string[];
  onChange: (value: string[]) => void;
}

/**
 * The main navigation for the template editor.
 */
export default function TemplateEditorNavigation({ value, onChange }: TemplateEditorNavigationProps) {
  const [splitScreen, setSplitScreen] = useState(false);

  const formTabVisible = useMemo(() => value.includes('form'), [value]);
  const templateTabVisible = useMemo(() => value.includes('template'), [value]);
  const automationTabVisible = useMemo(() => value.includes('automation'), [value]);

  const openTab = useCallback(
    (tab: string) => {
      if (splitScreen && tab === 'form') {
        return;
      }

      const tabs: string[] = [];

      if (splitScreen) {
        tabs.push('form');
      }

      tabs.push(tab);

      onChange(tabs);
    },
    [splitScreen]
  );

  useEffect(() => {
    const tabs: string[] = ['form'];

    if (splitScreen) {
      tabs.push('template');
    }

    onChange(tabs);
  }, [splitScreen]);

  const columns = useMemo(
    () => (splitScreen ? 'grid-cols-[calc(50%-7px)_180px_180px_1fr]' : 'grid-cols-[180px_180px_180px_1fr]'),
    [splitScreen]
  );

  return (
    <Box className={`grid gap-2 ${columns}`}>
      <Group position="left">
        <TemplateEditorTab text="Editor formuláru" active={formTabVisible} onClick={() => openTab('form')} />
      </Group>
      <TemplateEditorTab text="Editor šablóny" active={templateTabVisible} onClick={() => openTab('template')} />
      {FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS ? (
        <TemplateEditorTab text="Automatizácie" active={automationTabVisible} onClick={() => openTab('automation')} />
      ) : (
        <Box />
      )}
      <Group position="right">
        <SplitScreenButton value={splitScreen} onChange={setSplitScreen} />
      </Group>
    </Box>
  );
}
