import { MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular, P3Regular } from 'theme/typography';

export type MultiSelectTheme = NonNullable<MantineThemeOverride['components']>['MultiSelect'];

export const MultiSelect: MultiSelectTheme = {
  defaultProps: (theme) => ({
    size: 'md',
    withinPortal: true,
  }),
  styles: (theme) => ({
    input: {
      borderColor: theme.colors.gray[4],
      background: theme.white,
      boxShadow: 'none !important',
      height: 'auto',
      minHeight: `${rem(48)} !important`,
      '&:hover': {
        borderColor: theme.colors.blue[8],
      },
      '&:focus': {
        borderColor: theme.colors.blue[8],
        color: theme.colors.gray[8],
      },
      '&:disabled': {
        borderColor: theme.colors.gray[2],
        color: theme.colors.gray[4],
        backgroundColor: theme.white,
      },
      '&[readonly]': {
        borderColor: theme.colors.gray[2],
        color: theme.colors.gray[6],
        backgroundColor: theme.white,
        cursor: 'not-allowed',
      },
      '&:filled': {
        color: theme.colors.gray[8],
      },
    },
    dropdown: {
      '&:hover': {
        Color: theme.colors.blue[8],
      },
    },
    label: {
      color: theme.colors.gray[6],
      '&:disabled': {
        color: theme.colors.gray[2],
      },
    },
    values: {
      minHeight: 0,
      display: 'flex',
      gap: rem(8),
    },
    value: {
      minHeight: rem(24),
      height: rem(24),
      margin: 0,
      paddingLeft: rem(4),
      paddingRight: rem(4),
      color: theme.colors.gray[8],
      ...P2Regular,
    },
    button: {
      margin: 0,
    },
  }),
  sizes: {
    lg: (theme) => ({
      input: {
        paddingLeft: rem(16),
        paddingRight: rem(16),
        paddingTop: rem(12),
        paddingBottom: rem(12),
        height: rem(48),
        ...P2Regular,
      },
      dropdown: {
        ...P1Regular,
      },
      item: {
        ...P1Regular,
      },
      label: {
        ...P2Regular,
      },
    }),
    md: (theme) => ({
      input: {
        paddingLeft: rem(12),
        paddingRight: rem(12),
        paddingTop: rem(8),
        paddingBottom: rem(8),
        ...P1Regular,
      },
      dropdown: {
        ...P1Regular,
      },
      item: {
        ...P1Regular,
      },
      label: {
        ...P2Regular,
      },
    }),
    sm: (theme) => ({
      input: {
        paddingLeft: rem(8),
        paddingRight: rem(8),
        paddingTop: rem(4),
        paddingBottom: rem(4),
        ...P2Regular,
      },
      dropdown: {
        ...P2Regular,
      },
      item: {
        ...P2Regular,
      },
      label: {
        ...P3Regular,
      },
    }),
  },
};
