import { IRowNode } from 'ag-grid-community';
import { DeviceRow } from 'components/tables/device/types';
import DeviceActions from 'components/tables/device/DeviceActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<DeviceRow>; data: DeviceRow }) {
  return (
    <DeviceActions
      {...data}
      canDiscard={data.canDiscard}
      onStatusChange={(status) => node.setData({ ...data, status })}
    />
  );
}
