import { AgGridReact } from 'ag-grid-react';
import TableWrapper from 'components/tables/TableWrapper';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import panic from 'errors/panic';
import { useDisclosure } from '@mantine/hooks';
import { ColDef, CsvExportParams, GetRowIdFunc, GridApi } from 'ag-grid-community';
import { noop } from 'lodash';
import { Button, Flex, Group } from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconFileExport, IconPlus, IconTableImport, IconX } from '@tabler/icons-react';
import P3Regular from 'components/typography/P3Regular';
import ExportModal from 'components/tables/ExportModal';
import ImportModal, { ImportCallback } from 'components/tables/ImportModal';
import { read, writeFile } from 'xlsx';
import P1Medium from 'components/typography/P1Medium';
import { nanoid } from 'nanoid';
import useLocalStorage from 'hooks/use-local-storage';
import { FEATURE_TOGGLE_DATA_TABLE_PROFILES } from 'env';

/**
 * The parameters of data export.
 */
export interface DataTableExport {
  modalTitle: string;
  columnKeys: string[];
  fileName: string;
}

/**
 * The parameters of data import.
 */
export interface DataTableImport {
  modalTitle: string;
  action: ImportCallback;
  onHint?: () => void;
}

/**
 * Parameters of the DataTable component.
 */
export interface DataTableProps<TData> {
  action: () => Promise<TData[]>;
  columns: ColDef[];
  addButtonText?: string;
  addButtonTarget?: string;
  hideAddButton?: boolean;
  rowHeight?: number;
  search?: string;
  initialFilters?: Record<string, any>;
  additionalFilters?: Record<string, any>;
  dataExport?: DataTableExport;
  dataImport?: DataTableImport;
  selectedRows?: TData[];
  bulkActions?: ReactNode;
  onReady?: (api: GridApi<TData>) => void;
  onFirstDataRendered?: (api: GridApi<TData>) => void;
  onSelectedRowsChange?: (rows: TData[]) => void;
  getRowId?: GetRowIdFunc<TData>;
  refreshToken?: number;
}

/**
 * Renders data in a table using the specified action to fetch the data.
 */
export default function DataTable<TData>({
  action,
  columns,
  addButtonText,
  addButtonTarget,
  hideAddButton = false,
  rowHeight = 72,
  search,
  initialFilters = {},
  additionalFilters = {},
  dataExport,
  dataImport,
  selectedRows = [],
  bulkActions = [],
  onReady = noop,
  onFirstDataRendered = noop,
  onSelectedRowsChange = noop,
  getRowId,
  refreshToken,
}: DataTableProps<TData>) {
  const ref = useRef<AgGridReact>(null);

  const [filterModel, setFilterModel] = useLocalStorage<any>('fmpoint.data-table.filter-model', null);

  const [ready, setReady] = useState<boolean>(false);
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(true);
  const [data, setData] = useState<TData[]>([]);
  const [exporting, { open: startExporting, close: stopExporting }] = useDisclosure(false);
  const [importing, { open: startImporting, close: stopImporting }] = useDisclosure(false);

  const columnsKey = useMemo(() => nanoid(), [columns]);

  const columnsWithBulk = useMemo(
    () => [
      {
        field: '_bulk',
        headerName: '',
        suppressMovable: true,
        width: 0,
        minWidth: 0,
        maxWidth: 0,
        colSpan: ({ node }) => (node?.isRowPinned() ? 999 : 0),
      } satisfies ColDef,
      ...columns,
    ],
    [columns]
  );

  const [visibleRowsCount, setVisibleRowsCount] = useState<number>(0);

  /**
   * Exports the file as CSV.
   */
  async function exportFile(params: CsvExportParams) {
    const csv = ref.current?.api.getDataAsCsv(params)?.replace(/","(=+)/g, '"," $1');

    if (!csv) {
      return;
    }

    const wb = read(csv, {
      type: 'string',
      cellDates: true,
      cellFormula: false,
    });

    const fileName = params.fileName ?? 'export.xlsx';

    writeFile(wb, fileName, {
      type: 'file',
      bookType: 'xlsx',
    });
  }

  // Fetch the data.
  useEffect(() => {
    startLoading();
    action().then(setData).catch(panic).finally(stopLoading);
  }, [refreshToken]);

  // Set quick filter.
  useEffect(() => {
    ref.current?.api?.setQuickFilter(search || '');
  }, [search]);

  useEffect(() => {
    if (ready) {
      ref.current?.api?.setFilterModel({
        ...ref.current?.api?.getFilterModel(),
        ...additionalFilters,
      });
    }
  }, [additionalFilters]);

  useEffect(() => {
    if (ready && !loading) {
      if (filterModel && FEATURE_TOGGLE_DATA_TABLE_PROFILES) {
        ref.current?.api.setFilterModel(filterModel);
      } else {
        ref.current?.api.setFilterModel({ ...additionalFilters, ...initialFilters });
      }
    }
  }, [ready, loading]);

  const hasBulkActions = bulkActions && selectedRows.length > 0 && data.length > 0;

  return (
    <TableWrapper>
      <AgGridReact
        ref={ref}
        rowData={data}
        getRowId={getRowId}
        columnDefs={columnsWithBulk}
        onGridReady={({ api }) => {
          api.sizeColumnsToFit();
          setReady(true);
          onReady(api);
        }}
        onFirstDataRendered={({ api }) => {
          onFirstDataRendered(api);
        }}
        onFilterChanged={({ api }) => {
          setVisibleRowsCount(api.getDisplayedRowCount());

          if (ready && !loading) {
            setFilterModel(api.getFilterModel());
          }
        }}
        onModelUpdated={({ api }) => {
          setVisibleRowsCount(api.getDisplayedRowCount());

          if (ready && !loading) {
            setFilterModel(api.getFilterModel());
          }
        }}
        onSelectionChanged={({ api }) => {
          onSelectedRowsChange(api.getSelectedRows());
        }}
            context={{ initialFilters }}
        rowHeight={rowHeight}
        className="ag-theme-alpine"
        rowSelection="multiple"
        enableCellTextSelection
        suppressCellFocus
        suppressRowClickSelection
        suppressDragLeaveHidesColumns
        suppressColumnVirtualisation
        noRowsOverlayComponent={() => (loading ? 'Načítavanie ...' : 'Nenájdené žiadne záznamy')}
        pinnedTopRowData={hasBulkActions ? [data[0]] : []}
      />
      <Flex gap={12} p={16} align="center" h="100%" bg="gray.0" justify="space-between">
        <Group spacing={16}>
          {!hideAddButton && addButtonTarget && addButtonText && (
            <Button
              variant="primary"
              component={Link}
              to={addButtonTarget}
              size="md"
              leftIcon={<IconPlus stroke="1.5" size={24} />}
            >
              {addButtonText}
            </Button>
          )}
          {dataExport && (
            <>
              <Button
                variant="tertiary"
                size="md"
                leftIcon={<IconFileExport stroke="1.5" size={24} />}
                onClick={startExporting}
              >
                Exportovať
              </Button>
              <ExportModal
                key={columnsKey}
                columns={columns}
                title={dataExport.modalTitle}
                dataExport={dataExport}
                opened={exporting}
                onClose={stopExporting}
                onExport={exportFile}
              />
            </>
          )}
          {dataImport && (
            <>
              <Button
                variant="tertiary"
                size="md"
                leftIcon={<IconTableImport stroke="1.5" size={24} />}
                onClick={startImporting}
              >
                Importovať
              </Button>
              <ImportModal
                title={dataImport.modalTitle}
                opened={importing}
                onClose={stopImporting}
                onImport={dataImport.action}
                onHint={dataImport.onHint}
              />
            </>
          )}
        </Group>
        <P3Regular color="gray.7">Záznamy: {visibleRowsCount}</P3Regular>
      </Flex>
      {hasBulkActions && (
        <Group
          position="apart"
          pos="absolute"
          top={98}
          left={0}
          w="100%"
          h={73}
          bg="blue.6"
          px={24}
          py={16}
          sx={{
            zIndex: 200,
            borderTop: '2px solid white',
            borderLeft: '2px solid white',
            borderRight: '2px solid white',
          }}
        >
          {bulkActions}
          <Group spacing={8}>
            <P1Medium color="white">vybrané: {selectedRows.length}</P1Medium>
            <Button
              variant="primary"
              size="sm"
              leftIcon={<IconX strokeWidth={1.5} size={16} />}
              onClick={() => ref.current?.api.deselectAll()}
            >
              Odznačiť všetko
            </Button>
          </Group>
        </Group>
      )}
    </TableWrapper>
  );
}
