import { IconRowInsertTop } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * Adds a row before the selected row.
 */
export default function AddRowBefore({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      label="Vložiť riadok hore"
      icon={<IconRowInsertTop />}
      onClick={() => editor.chain().focus().addRowBefore().run()}
    />
  );
}
