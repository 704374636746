import { Button, Center, Divider, Stack } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import H2Medium from 'components/typography/H2Medium';
import P2Regular from 'components/typography/P2Regular';
import SimpleLayout from 'layouts/simple/SimpleLayout';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH, RESET_PASSWORD_PAGE_PATH } from 'routes/paths';

/**
 * Expired reset link page.
 *
 * {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=1375%3A16849 Figma Design}
 * {@link https://www.notion.so/Profile-Expired-Reset-Link-1a8b35dbc1384416bc6dca0c7b32de2f?pvs=4 Notion Page}
 */
export default function ExpiredResetLinkPage() {
  return (
    <SimpleLayout>
      <Stack p={24} spacing={16} h={310}>
        <Center>
          <Center bg="red.7" w={44} h={44} style={{ borderRadius: '100%' }}>
            <IconX stroke="2" width={24} height={24} color="white" />
          </Center>
        </Center>
        <Stack spacing={4}>
          <H2Medium align="center" color="gray.8">
            Link expiroval
          </H2Medium>
          <P2Regular align="center" color="gray.7">
            Bohužiaľ link na reset hesla expiroval z dôvodu vypršania času.
          </P2Regular>
        </Stack>
      </Stack>

      <Divider color="gray.1" />

      <Stack p={24} spacing={10}>
        <Button component={Link} to={RESET_PASSWORD_PAGE_PATH.original} fullWidth size="lg" variant="primary">
          Resetovať heslo znova
        </Button>
        <Button component={Link} to={LOGIN_PAGE_PATH.original} fullWidth size="lg" variant="subtle-gray">
          Hotovo
        </Button>
      </Stack>
    </SimpleLayout>
  );
}
