import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListEvidenceFieldsPathParameters } from 'api/actions/device-list-evidence-fields/device-list-evidence-fields-path-parameters';
import { DeviceListEvidenceFieldsResponse } from 'api/actions/device-list-evidence-fields/device-list-evidence-fields-response';

type DeviceListEvidenceFieldsQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListEvidenceFields action.
 */
export const deviceListEvidenceFieldsActionPath = new SweetPath<keyof DeviceListEvidenceFieldsPathParameters>(
  '/api/device/evidence-fields/{departmentId}/{deviceSubtypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListEvidenceFields actions.
 */
export default function createDeviceListEvidenceFieldsAction(axios: AxiosInstance) {
  return function deviceListEvidenceFieldsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceListEvidenceFieldsPathParameters;
    query?: DeviceListEvidenceFieldsQueryParameters;
  }) {
    const path = deviceListEvidenceFieldsActionPath.insert(parameters);

    return axios.get<DeviceListEvidenceFieldsResponse, DeviceListEvidenceFieldsResponse>(path, {
      params: query,
      headers,
    });
  };
}
