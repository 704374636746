import { mergeAttributes, Node } from '@tiptap/core';

export interface HtmlBlockOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    htmlBlock: {
      insertHtmlBlock: () => ReturnType;
      setHtmlBlockContent: (content: string) => ReturnType;
    };
  }
}

export const HtmlBlock = Node.create<HtmlBlockOptions>({
  name: 'htmlBlock',
  draggable: true,
  inline: false,
  group: 'block',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'html-block',
      },
    };
  },

  addAttributes() {
    return {
      'data-content': {
        default: '',
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div.html-block' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      insertHtmlBlock:
        () =>
        ({ commands }) =>
          commands.insertContent({ type: this.name }),
      setHtmlBlockContent:
        (content: string) =>
        ({ commands }) =>
          commands.updateAttributes(this.name, { 'data-content': content }),
    };
  },
});
