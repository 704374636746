import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { EditorOptions, useEditor } from '@tiptap/react';
import { Document } from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import { HtmlBlock } from 'pages/revisions-module/template-editor/editors/template/extensions/HtmlBlock';
import { PageBreak } from 'pages/revisions-module/template-editor/editors/template/extensions/PageBreak';
import { Tabulator } from 'pages/revisions-module/template-editor/editors/template/extensions/Tabulator';
import { TableCell } from 'pages/revisions-module/template-editor/editors/template/extensions/TableCell';
import { DeviceLoop } from 'pages/revisions-module/template-editor/editors/template/extensions/DeviceLoop';
import { IfBlock } from 'pages/revisions-module/template-editor/editors/template/extensions/if-block/IfBlock';
import {
  PageBody,
  PageFooter,
  PageHeader,
} from 'pages/revisions-module/template-editor/editors/template/extensions/PageSections';
import {
  InlinePlaceholder,
  BlockPlaceholder,
} from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/Placeholder';
import { DeviceFaultsPlaceholder } from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/device-faults/DeviceFaultsPlaceholder';
import { DeviceMeasurementsPlaceholder } from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/device-measurements/DeviceMeasurementsPlaceholder';
import useDeviceFaultsPlaceholderConfigurator from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/device-faults/DeviceFaultsPlaceholderConfigurator';
import Highlight from '@tiptap/extension-highlight';
import { RichTextEditor } from '@mantine/tiptap';
import Toolbar from 'pages/revisions-module/template-editor/editors/template/toolbar/Toolbar';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useEditorStyles } from 'pages/revisions-module/template-editor/editors/template/styles/use-editor-styles';
import { useInjectFieldNameStyles } from 'pages/revisions-module/template-editor/editors/template/styles/use-inject-field-name-styles';
import { useInjectSectionNameStyles } from 'pages/revisions-module/template-editor/editors/template/styles/use-inject-section-name-styles';
import IfBlockBubbleMenu from 'pages/revisions-module/template-editor/editors/template/extensions/if-block/IfBlockBubbleMenu';
import useDeviceMeasurementsPlaceholderConfigurator from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/device-measurements/DeviceMeasurementsPlaceholderConfigurator';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';

/**
 * The template editor part of the template editor page.
 */
export default function TemplateEditor() {
  const editorRef = useRef<HTMLDivElement>(null);
  const { allInputs } = useFormInputs();
  const { sections } = useFormSections();
  const { visual } = useTemplateEditorData();
  const { template, setTemplate } = useTemplateEditorData();
  const { classes } = useEditorStyles(visual);
  const injectFieldNameStyles = useInjectFieldNameStyles(allInputs);
  const injectSectionNameStyles = useInjectSectionNameStyles(sections);

  const sxStyles = useMemo(
    () => ({ ...injectFieldNameStyles, ...injectSectionNameStyles }),
    [injectFieldNameStyles, injectSectionNameStyles]
  );

  const saveTemplateDebounced = useMemo(() => debounce(setTemplate, 500, { maxWait: 5000 }), []);

  const editorOptions: Partial<EditorOptions> = useMemo(
    () => ({
      extensions: [
        Highlight,
        Link,
        StarterKit,
        PageHeader,
        PageBody,
        PageFooter,
        Document.extend({ content: 'pageHeader pageBody pageFooter' }),
        Subscript,
        Superscript,
        TextAlign.configure({ types: ['heading', 'paragraph'] }),
        Underline,
        Table.configure({ resizable: false }),
        TableRow,
        TableHeader,
        TableCell,
        HtmlBlock,
        PageBreak,
        Tabulator,
        DeviceLoop,
        IfBlock,
        InlinePlaceholder,
        BlockPlaceholder,
        DeviceFaultsPlaceholder,
        DeviceMeasurementsPlaceholder,
      ],
      autofocus: true,
      content: template.template,
      onUpdate: ({ editor }) => saveTemplateDebounced({ template: editor.getHTML() }),
      parseOptions: {
        preserveWhitespace: 'full',
      },
    }),
    []
  );

  const editor = useEditor(editorOptions, []);

  useDeviceFaultsPlaceholderConfigurator(editor);
  useDeviceMeasurementsPlaceholderConfigurator(editor);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: visual.customCss }} />
      <RichTextEditor pos="relative" withTypographyStyles editor={editor} classNames={classes}>
        <Toolbar />
        <RichTextEditor.Content ref={editorRef} sx={sxStyles} />
        <IfBlockBubbleMenu />
      </RichTextEditor>
    </>
  );
}
