import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates that the input is not empty.
 */
export default function required(value: string): ValidationError | null {
  if (value === '' || value === null || value === undefined) {
    return {
      message: 'Pole je poivnné',
    };
  }

  return null;
}
