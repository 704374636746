import { ColDef, GridApi } from 'ag-grid-community';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import DataTable from 'components/tables/DataTable';
import { ADD_REVISION_PAGE_PATH } from 'routes/paths';
import OrganizationSelectFilter from 'components/tables/filters/OrganizationSelectFilter';
import DepartmentSelectFilter from 'components/tables/filters/DepartmentSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import RevisionStatusFloatingFilter from 'components/tables/filters/revision-status/RevisionStatusFloatingFilter';
import { useApi } from 'api/api-context';
import DeviceSubtypeContainsSelectFilter from 'components/tables/filters/device-subtype/DeviceSubtypeContainsSelectFilter';
import AssignedBySelectFilter from 'components/tables/filters/AssignedBySelectFilter';
import TechnicianSelectFilter from 'components/tables/filters/TechnicianSelectFilter';
import RevisionDeadlineSelectFilter from 'components/tables/filters/RevisionDeadlineSelectFilter';
import { isAfter } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { noop } from 'lodash';
import RevisionStatusFilter from 'components/tables/filters/revision-status/RevisionStatusFilter';
import OrganizationNameColumn from 'components/tables/revision/columns/OrganizationNameColumn';
import DepartmentNameColumn from 'components/tables/revision/columns/DepartmentNameColumn';
import AssignedByOrganizationNameColumn from 'components/tables/revision/columns/AssignedByOrganizationNameColumn';
import DeviceTypeNameColumn from 'components/tables/revision/columns/DeviceTypeNameColumn';
import DeviceSubtypesColumn from 'components/tables/revision/columns/DeviceSubtypesColumn';
import RevisionStatusColumn from 'components/tables/revision/columns/RevisionStatusColumn';
import RevisionNameColumn from 'components/tables/revision/columns/RevisionNameColumn';
import TechnicianNameColumn from 'components/tables/revision/columns/TechnicianNameColumn';
import DeadlineColumn from 'components/tables/revision/columns/DeadlineColumn';
import DevicesColumn from 'components/tables/revision/columns/DevicesColumn';
import ActionsColumn from 'components/tables/revision/columns/ActionsColumn';
import { OnStatusChangeParams, RevisionRow } from 'components/tables/revision/types';
import SafetyPointColumn from 'components/tables/revision/columns/SafetyPointColumn';
import RevisionSafetyPointSelectFilter from 'components/tables/filters/RevisionSafetyPointSelectFilter';
import { Group } from '@mantine/core';
import BulkAcceptAction from 'components/tables/revision/actions/bulk/BulkAcceptAction';
import BulkApproveAction from 'components/tables/revision/actions/bulk/BulkApproveAction';
import BulkConfirmAction from 'components/tables/revision/actions/bulk/BulkConfirmAction';
import BulkDeleteAction from 'components/tables/revision/actions/bulk/BulkDeleteAction';
import FinishedAtColumn from 'components/tables/revision/columns/FinishedAtColumn';
import NextRevisionDateColumn from 'components/tables/revision/columns/NextRevisionDateColumn';
import DocumentValidUntilColumn from 'components/tables/revision/columns/DocumentValidUntilColumn';
import { FEATURE_TOGGLE_KEP } from 'env';
import RevisionDeviceSelectFilter from 'components/tables/filters/RevisionDeviceSelectFilter';
import RevisionTemplateColumn from 'components/tables/revision/columns/RevisionTemplateColumn';
import RevisionTemplateSelectFilter from 'components/tables/filters/RevisionTemplateSelectFilter';

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'revisionId',
    headerName: 'ID revíznej správy',
    hide: true,
    filter: true,
  },
  {
    field: 'checkbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    suppressMovable: true,
    width: 44,
    minWidth: 44,
    maxWidth: 44,
    cellStyle: () => ({ opacity: 1 }),
  },
  { field: 'organization.organizationId', hide: true, filter: true, headerName: 'ID organizácie' },
  {
    field: 'organization.organizationName',
    headerName: 'Organizácia',
    minWidth: 225,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: OrganizationSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  { field: 'department.departmentId', hide: true, filter: true, headerName: 'ID strediska' },
  {
    field: 'department.departmentName',
    headerName: 'Stredisko',
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DepartmentSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DepartmentNameColumn,
  },
  {
    field: 'assignedBy.organizationId',
    hide: true,
    filter: true,
    headerName: 'ID organizácie zadávateľa',
  },
  {
    field: 'assignedBy.organizationName',
    headerName: 'Zadávateľ',
    minWidth: 200,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: AssignedBySelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: AssignedByOrganizationNameColumn,
  },
  {
    field: 'deviceType.deviceTypeId',
    hide: true,
    filter: true,
    headerName: 'ID typu zariadenia',
  },
  {
    field: 'deviceType.deviceTypeName',
    headerName: 'Zariadenie',
    minWidth: 190,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceTypeSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceTypeNameColumn,
  },
  {
    field: 'deviceSubtypes.deviceTypeId',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceSubtypes.deviceTypeName',
    hide: true,
    filter: true,
  },
  {
    field: 'deviceSubtypesString',
    hide: true,
    headerName: 'Typ zariadenia',
  },
  {
    field: 'deviceSubtypes',
    headerName: 'Typ zariadenia',
    minWidth: 220,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceSubtypeContainsSelectFilter,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: DeviceSubtypesColumn,
  },
  {
    field: 'revisionStatus.revisionStatusName',
    headerName: 'Stav revíznej správy',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellRenderer: noop,
  },
  {
    field: 'revisionStatus.revisionStatusId',
    headerName: 'Stav',
    minWidth: 180,
    resizable: true,
    wrapText: true,
    sortable: true,
    unSortIcon: true,
    filter: RevisionStatusFilter,
    floatingFilter: true,
    floatingFilterComponent: RevisionStatusFloatingFilter,
    cellRenderer: RevisionStatusColumn,
  },
  {
    field: 'revisionName',
    headerName: 'Názov',
    minWidth: 250,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: RevisionNameColumn,
  },
  {
    field: 'revisionTemplate.slug',
    headerName: 'Šablóna',
    minWidth: 250,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionTemplateSelectFilter,
    cellRenderer: RevisionTemplateColumn,
  },
  {
    field: 'technician.userId',
    hide: true,
    filter: true,
    headerName: 'ID revízneho technika',
  },

  {
    field: 'technician.fullName',
    headerName: 'Revízny technik',
    minWidth: 180,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: TechnicianSelectFilter,
    cellRenderer: TechnicianNameColumn,
  },
  {
    field: 'deadlineStatus',
    hide: true,
    filter: true,
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    resizable: true,
    minWidth: 170,
    sortable: true,
    unSortIcon: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionDeadlineSelectFilter,
    cellRenderer: DeadlineColumn,
  },
  {
    field: 'safetyPoint',
    hide: true,
    filter: true,
  },
  {
    field: 'safetyPointPretty',
    headerName: 'Záver',
    resizable: true,
    minWidth: 180,
    sortable: true,
    unSortIcon: true,
    filter: true,
    floatingFilter: true,
    wrapText: true,
    floatingFilterComponent: RevisionSafetyPointSelectFilter,
    cellRenderer: SafetyPointColumn,
  },
  {
    field: 'finishedAt',
    headerName: 'Dátum ukončenia',
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: FinishedAtColumn,
  },
  {
    field: 'nextRevisionDate',
    headerName: 'Dátum nasledujúcej revíznej správy',
    wrapHeaderText: true,
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: NextRevisionDateColumn,
  },
  {
    field: 'documentValidUntil',
    headerName: 'Deadline na prepečiatkovanie',
    wrapHeaderText: true,
    resizable: true,
    minWidth: 200,
    sortable: true,
    unSortIcon: true,
    cellRenderer: DocumentValidUntilColumn,
  },
  {
    field: 'kepMessageUuid',
    headerName: 'NFQES UUID',
    hide: true,
  },
  {
    field: '_search.deviceId',
    hide: true,
    filter: true,
  },
  {
    field: '_search.deviceName',
    headerName: 'Názov zariadenia',
    resizable: true,
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: RevisionDeviceSelectFilter,
    wrapText: true,
    cellStyle: { display: 'flex' },
    cellRenderer: DevicesColumn,
  },
  {
    field: 'actions',
    headerName: '',
    width: 260,
    minWidth: 260,
    maxWidth: 260,
    pinned: 'right',
    cellStyle: { display: 'flex' },
    cellRenderer: ActionsColumn,
  },
];

/**
 * Parameters of the RevisionTable component.
 */
export interface RevisionTableProps {
  search?: string;
}

/**
 * Translates the safety point to a human readable string.
 */
function translateSafetyPoint(safetyPoint: string | undefined) {
  switch (safetyPoint) {
    case 'suitable':
      return 'Vyhovuje';
    case 'not-suitable':
      return 'Nevyhovuje';
    case 'with-reservations':
      return 'Vyhovuje s výhradami';
    default:
      return '-';
  }
}

/**
 * Table which displays revisions.
 */
function RevisionTableImpl({ search = '' }: RevisionTableProps = {}) {
  const { getAction, hasPermissionAnywhere } = useApi();
  const [searchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState<RevisionRow[]>([]);
  const [api, setApi] = useState<GridApi<RevisionRow> | null>(null);

  const [initialFilters] = useState(() => ({
    'organization.organizationId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('organizationId') ?? '',
    },
    'department.departmentId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('departmentId') ?? '',
    },
    'deviceType.deviceTypeId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('deviceTypeId') ?? '',
    },
    'deviceSubtypes.deviceTypeId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('deviceSubtypeId') ?? '',
    },
    '_search.deviceId': {
      filterType: 'text',
      type: 'contains',
      filter: searchParams.get('deviceId') ?? '',
    },
    'revisionStatus.revisionStatusId': (
      searchParams.get('revisionStatusId') ?? (FEATURE_TOGGLE_KEP ? '1,2,3,4,5,6,7,8,9,10' : '1,2,3,4,5,6,7,8')
    )
      .split(',')
      .filter(Boolean)
      .map((statusId) => Number(statusId.trim())),
    'assignedBy.organizationId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('assignedBy') ?? '',
    },
    'technician.userId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('technician') ?? '',
    },
    deadlineStatus: {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('deadlineStatus') ?? '',
    },
    safetyPoint: {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('safetyPoint') ?? '',
    },
  }));

  const action = useCallback(async () => {
    const action = getAction('RevisionList');

    const revisions = await action();

    return revisions.map((revision) => ({
      ...revision,
      deviceSubtypes: {
        deviceTypeId: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeId).join(', '),
        deviceTypeName: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeName).join(', '),
      },
      deadlineStatus: isAfter(new Date(revision.deadline), new Date()) ? 'before-deadline' : 'after-deadline',
      safetyPointPretty: translateSafetyPoint(revision.safetyPoint),
      _search: {
        deviceName: revision.devices.map(({ deviceName }) => deviceName).join(', '),
        deviceId: revision.devices.map(({ deviceId }) => deviceId).join(', '),
      },
      deviceSubtypesString: revision.deviceSubtype.map((deviceSubtype) => deviceSubtype.deviceTypeName).join(', '),
    }));
  }, [getAction]);

  /**
   * Handles the status change of a revision.
   */
  const onStatusChange = useCallback(
    ({ revisionId, status }: OnStatusChangeParams) => {
      const row = api?.getRowNode(String(revisionId));

      if (row && row.data) {
        row.setData({ ...row.data, revisionStatus: status });
      }
    },
    [api]
  );

  return (
    <DataTable
      getRowId={({ data: { revisionId } }) => String(revisionId)}
      columns={columns}
      action={action}
      search={search}
      addButtonText="Pridať novú"
      addButtonTarget={ADD_REVISION_PAGE_PATH.original}
      initialFilters={initialFilters}
      hideAddButton={!hasPermissionAnywhere('assign-revisions')}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      onReady={setApi}
      bulkActions={
        <Group spacing={32}>
          <BulkAcceptAction
            revisions={selectedRows}
            onStatusChange={onStatusChange}
            onBulkActionComplete={() => api?.deselectAll()}
          />
          <BulkApproveAction
            revisions={selectedRows}
            onStatusChange={onStatusChange}
            onBulkActionComplete={() => api?.deselectAll()}
          />
          <BulkConfirmAction
            revisions={selectedRows}
            onStatusChange={onStatusChange}
            onBulkActionComplete={() => api?.deselectAll()}
          />
          <BulkDeleteAction
            revisions={selectedRows}
            onStatusChange={onStatusChange}
            onBulkActionComplete={() => api?.deselectAll()}
          />
        </Group>
      }
      dataExport={{
        modalTitle: 'Exportovať revízne správy',
        fileName: 'revizne-spravy.xlsx',
        columnKeys: [
          'revisionId',
          'organization.organizationId',
          'organization.organizationName',
          'department.departmentId',
          'department.departmentName',
          'assignedBy.organizationId',
          'assignedBy.organizationName',
          'deviceType.deviceTypeId',
          'deviceType.deviceTypeName',
          'deviceSubtypesString',
          'revisionStatus.revisionStatusName',
          'revisionName',
          'technician.userId',
          'technician.fullName',
          'deadline',
          'safetyPointPretty',
          'finishedAt',
          'nextRevisionDate',
          'documentValidUntil',
          'kepMessageUuid',
          '_search.deviceName',
        ],
      }}
    />
  );
}

interface IRevisionTableContext {}

const RevisionTableContext = createContext<IRevisionTableContext>(undefined!);

/**
 * Table which displays revisions.
 */
export default function RevisionTable({ search = '' }: RevisionTableProps = {}) {
  const value = useMemo(() => ({}), []);

  return (
    <RevisionTableContext.Provider value={value}>
      <RevisionTableImpl search={search} />
    </RevisionTableContext.Provider>
  );
}

/**
 * Hook to access the revision table context.
 */
export function useRevisionTableContext() {
  return useContext(RevisionTableContext);
}
