import { useApi } from 'api/api-context';
import NumberStatCard from 'components/stats/NumberStatCard';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import { REVISIONS_PAGE_PATH } from 'routes/paths';

/**
 * Displays the number of revisions that have `with-reservations` as the safety point.
 */
export default function RevisionsWithReservationsCard() {
  const { getAction } = useApi();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const revisionListAction = getAction('RevisionList');

    revisionListAction({ query: { filters: { 'safetyPoint.eq': 'with-reservations', 'includedInStatistics.eq': 1 } } })
      .then(({ length }) => setValue(length))
      .catch(panic);
  }, []);

  return (
    <NumberStatCard
      loading={value === null}
      title="Revízie vyhovujúce s výhradami"
      link={`${REVISIONS_PAGE_PATH.original}?safetyPoint=with-reservations`}
      value={value ?? 0}
      variant={value === 0 ? 'gray' : 'red'}
    />
  );
}
