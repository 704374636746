import P2Regular from 'components/typography/P2Regular';
import { RevisionPlanRow } from 'components/tables/revision-plan/types';
import DateDisplay from 'components/DateDisplay';

/**
 * Column for the next revision.
 */
export default function NextRevisionColumn({ data: { nextRevision } }: { data: RevisionPlanRow }) {
  return (
    <P2Regular color="gray.8">
      {nextRevision ? <DateDisplay date={new Date(nextRevision)} withRelative /> : '-'}
    </P2Regular>
  );
}
