import P2Medium from 'components/typography/P2Medium';

/**
 * Link for more information about the BrainIT service.
 */
export default function BrainItMoreInfo() {
  return (
    <a style={{ width: 'fit-content' }} href="https://nfqes.com/sk/navody" rel="external noreferrer" target="_blank">
      <P2Medium color="gray.7">Viac o službe BrainIT</P2Medium>
    </a>
  );
}
