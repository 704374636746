import { UseFormReturnType } from '@mantine/form';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useMemo } from 'react';
import { noop } from 'lodash';
import { ActionIcon, Button, Group, NumberInput, Select, Stack, TextInput, Tooltip } from '@mantine/core';
import P3Regular from 'components/typography/P3Regular';
import { IconPlus, IconTrash } from '@tabler/icons-react';

/**
 * Parameters of the SumInputOptions component.
 */
interface SumInputOptionsProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

/**
 * Input used to specify the options of a select input.
 */
function SumInputOptions({ value = ['', ''], onChange = noop }: SumInputOptionsProps) {
  const { allInputs } = useFormInputs();

  const options = useMemo(
    () =>
      allInputs
        .filter(({ context, spec: { type } }) => context === 'revision' && type === 'number')
        .map(({ spec: { name, label } }) => ({ value: name, label })),
    [allInputs]
  );

  /** Updates the value of the nth option. */
  const updateNthOption = (index: number, option: string) => {
    const newValue = [...value];
    newValue[index] = option;
    onChange(newValue);
  };

  /** Removes the nth option. */
  const removeNthOption = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  /** Adds a new option. */
  const addOption = () => onChange([...value, '']);

  return (
    <Stack spacing={8}>
      <P3Regular color="gray.6">Vyberte polia</P3Regular>
      {value.map((name, i) => (
        <Group key={i} spacing={8}>
          <Select
            size="sm"
            searchable
            w="calc(100% - 40px)"
            data={options}
            value={name}
            onChange={(name) => updateNthOption(i, name || '')}
          />
          <Tooltip label="Odstrániť položku" withArrow={false} openDelay={300}>
            <ActionIcon w={32} variant="subtle-gray" size="sm" onClick={() => removeNthOption(i)}>
              <IconTrash size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ))}
      <Button variant="secondary" size="sm" leftIcon={<IconPlus />} mr={40} onClick={addOption}>
        Pridať položku
      </Button>
    </Stack>
  );
}

/**
 * Sub-form for sum input properties
 */
export default function SumInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return (
    <>
      <NumberInput
        label="Počet desatinných miest"
        min={0}
        max={8}
        defaultValue={0}
        {...form.getInputProps('precision')}
      />
      <TextInput label="Suffix" {...form.getInputProps('suffix')} />
      <SumInputOptions {...form.getInputProps('inputs')} />
    </>
  );
}
