import { RichTextEditor } from '@mantine/tiptap';
import ControlBold from 'components/inputs/wysiwyg/controls/bold/ControlBold';
import ControlItalic from 'components/inputs/wysiwyg/controls/italic/ControlItalic';
import ControlUnderline from 'components/inputs/wysiwyg/controls/underline/ControlUnderline';
import ControlStrikethrough from 'components/inputs/wysiwyg/controls/strikethrough/ControlStrikethrough';
import ControlAlignLeft from 'components/inputs/wysiwyg/controls/align-left/ControlAlignLeft';
import ControlAlignRight from 'components/inputs/wysiwyg/controls/align-right/ControlAlignRight';
import ControlAlignCenter from 'components/inputs/wysiwyg/controls/align-center/ControlAlignCenter';
import ControlBulletList from 'components/inputs/wysiwyg/controls/bullet-list/ControlBulletList';
import ControlOrderedList from 'components/inputs/wysiwyg/controls/ordered-list/ControlOrderedList';

/**
 * The toolbar of the wysiwyg editor.
 */
export default function WysiwygEditorToolbar() {
  return (
    <RichTextEditor.Toolbar>
      <RichTextEditor.ControlsGroup>
        <ControlBold />
        <ControlItalic />
        <ControlUnderline />
        <ControlStrikethrough />
      </RichTextEditor.ControlsGroup>

      <RichTextEditor.ControlsGroup>
        <ControlAlignLeft />
        <ControlAlignCenter />
        <ControlAlignRight />
      </RichTextEditor.ControlsGroup>

      <RichTextEditor.ControlsGroup>
        <ControlBulletList />
        <ControlOrderedList />
      </RichTextEditor.ControlsGroup>
    </RichTextEditor.Toolbar>
  );
}
