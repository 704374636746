import { Menu, Skeleton } from '@mantine/core';
import { Button } from '@mantine/core';
import { ActionIcon } from '@mantine/core';
import { Portal } from '@mantine/core';
import { Text } from '@mantine/core';
import { Group } from '@mantine/core';
import { IconCalendar, IconDotsVertical, IconEye, IconPencil } from '@tabler/icons-react';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ADD_REVISION_PAGE_PATH, DEVICE_REVISION_PLANS_PAGE_PATH, EDIT_DEVICE_PAGE_PATH } from 'routes/paths';

/**
 * Parameters of the RevisionPlanActions component.
 */
export interface RevisionPlanActionsProps {
  revisionPlanId: number;
  type: string;
  nextRevision?: string;
  revisionPeriod: number;
  organization: { organizationId: number; organizationName: string };
  department: { departmentId: number; departmentName: string };
  deviceId: number;
  deviceName: string;
  manufacturer?: string;
  serialNumber?: string;
  manufactured?: number;
  deviceType: { deviceTypeId: number; deviceTypeName: string };
  deviceSubtype: { deviceTypeId: number; deviceTypeName: string };
  showCalendar?: boolean;
}

/**
 * Displays actions for a revision plan in a table.
 */
export default function RevisionPlanActions({
  revisionPlanId,
  type,
  nextRevision,
  revisionPeriod,
  organization,
  department,
  deviceId,
  deviceName,
  manufacturer,
  serialNumber,
  manufactured,
  deviceType,
  deviceSubtype,
  showCalendar = true,
}: RevisionPlanActionsProps) {
  const { getAction, roleId } = useApi();
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(roleId !== ROLE_ADMIN_ID);
  const [canAssignRevisions, setCanAssignRevisions] = useState<boolean>(roleId === ROLE_ADMIN_ID);
  const [canEditDevices, setCanEditDevices] = useState<boolean>(roleId === ROLE_ADMIN_ID);

  const addRevisionPageLink = useMemo(() => {
    let query = `revisionName=${type}`;

    if (nextRevision) {
      query = `${query}&nextRevision=${nextRevision}`;
    }

    query = `${query}&organizationId=${organization.organizationId}&departmentId=${department.departmentId}&deviceTypeId=${deviceType.deviceTypeId}&deviceId=${deviceId}&deviceName=${deviceName}&deviceSubtypeId=${deviceSubtype.deviceTypeId}&deviceSubtypeName=${deviceSubtype.deviceTypeName}&manufacturer=${manufacturer}&serialNumber=${serialNumber}&manufactured=${manufactured}&termId=${revisionPlanId}&revisionPlanDescription=${type}&revisionPeriod=${revisionPeriod}`;

    return `${ADD_REVISION_PAGE_PATH.original}?${query}`;
  }, [
    revisionPlanId,
    type,
    deviceId,
    nextRevision,
    organization,
    department,
    deviceType,
    deviceSubtype,
    revisionPeriod,
    deviceName,
    manufacturer,
    serialNumber,
    manufactured,
  ]);

  const calendarPageLink = useMemo(() => {
    const deadline = new Date(nextRevision!);
    const query = `year=${deadline.getFullYear()}&month=${
      deadline.getMonth() + 1
    }&day=${deadline.getDate()}&tab=calendar&revisionPlanId=${revisionPlanId}`;

    return `${DEVICE_REVISION_PLANS_PAGE_PATH.original}?${query}`;
  }, [deviceId, revisionPlanId, nextRevision]);

  const editPageLink = useMemo(
    () => `${EDIT_DEVICE_PAGE_PATH.insert({ deviceId })}?revisionPlanId=${revisionPlanId}`,
    [deviceId, revisionPlanId]
  );

  // Check permissions on open.
  useEffect(() => {
    const action = getAction('AuthUserHasPermissionInDepartment');

    if (roleId === ROLE_ADMIN_ID) {
      setCanAssignRevisions(true);
      setCanEditDevices(true);
      setLoading(false);
    } else {
      Promise.all([
        action({ parameters: { departmentId: String(department.departmentId), permissionSlug: 'assign-revisions' } }),
        action({ parameters: { departmentId: String(department.departmentId), permissionSlug: 'manage-devices' } }),
      ])
        .then(([{ hasPermission: canAssignRevisions }, { hasPermission: canEditDevices }]) => {
          setCanAssignRevisions(canAssignRevisions);
          setCanEditDevices(canEditDevices);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [roleId, department.departmentId]);

  const primaryAction = useMemo(() => {
    if (loading) {
      return <Skeleton w={120} h={40} radius={4} />;
    }

    return (
      <Button
        w={120}
        size="md"
        component={Link}
        to={editPageLink}
        variant="secondary"
        leftIcon={canEditDevices ? <IconPencil /> : <IconEye />}
      >
        {canEditDevices ? 'Editovať' : 'Zobraziť'}
      </Button>
    );
  }, [loading, editPageLink, canEditDevices]);

  return (
    <Group spacing={12} noWrap>
      {primaryAction}
      <Menu position="bottom-end" zIndex={2000} opened={opened} onChange={setOpened}>
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {type}
              </Text>
            </Menu.Label>
            {showCalendar && nextRevision && (
              <Menu.Item
                component={Link}
                to={calendarPageLink}
                icon={<IconCalendar stroke="1.5" size={20} />}
                color="gray.8"
              >
                Zobraziť v kalendári
              </Menu.Item>
            )}
            {canAssignRevisions && (
              <Menu.Item
                component={Link}
                to={addRevisionPageLink}
                icon={<IconPlus stroke="1.5" size={20} />}
                color="gray.8"
              >
                Zadať revíziu
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
