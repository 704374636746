import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetMyCertificatesPathParameters } from 'api/actions/user-get-my-certificates/user-get-my-certificates-path-parameters';
import { UserGetMyCertificatesResponse } from 'api/actions/user-get-my-certificates/user-get-my-certificates-response';

type UserGetMyCertificatesQueryParameters = Record<string, any>;

/**
 * The path of the UserGetMyCertificates action.
 */
export const userGetMyCertificatesActionPath = new SweetPath<keyof UserGetMyCertificatesPathParameters>(
  '/api/user/technician-certificates',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserGetMyCertificates actions.
 */
export default function createUserGetMyCertificatesAction(axios: AxiosInstance) {
  return function userGetMyCertificatesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: UserGetMyCertificatesQueryParameters } = {}) {
    const path = userGetMyCertificatesActionPath.original;

    return axios.get<UserGetMyCertificatesResponse, UserGetMyCertificatesResponse>(path, { params: query, headers });
  };
}
