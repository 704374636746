import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataListGlobalPathParameters } from 'api/actions/predefined-data-list-global/predefined-data-list-global-path-parameters';
import { PredefinedDataListGlobalResponse } from 'api/actions/predefined-data-list-global/predefined-data-list-global-response';

type PredefinedDataListGlobalQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataListGlobal action.
 */
export const predefinedDataListGlobalActionPath = new SweetPath<keyof PredefinedDataListGlobalPathParameters>(
  '/api/predefined-data/list/global',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataListGlobal actions.
 */
export default function createPredefinedDataListGlobalAction(axios: AxiosInstance) {
  return function predefinedDataListGlobalAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: PredefinedDataListGlobalQueryParameters } = {}) {
    const path = predefinedDataListGlobalActionPath.original;

    return axios.get<PredefinedDataListGlobalResponse, PredefinedDataListGlobalResponse>(path, {
      params: query,
      headers,
    });
  };
}
