/**
 * Traverses the DOM tree to find the parent element that satisfies the given predicate.
 */
export default function findElementParent(element: Element, predicate: (element: Element) => boolean): Element | null {
  let currentElement: Element | null = element;

  while (currentElement) {
    if (predicate(currentElement)) {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return null;
}
