import { NumberInput } from '@mantine/core';
import { IFormInputSpecSum } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Preview for a number input.
 */
export default function NumberInputPreview({ spec }: { spec: IFormInputSpecSum }) {
  return (
    <NumberInput
      required={spec.required}
      readOnly
      size="sm"
      rightSection={spec.suffix}
      hideControls
      precision={spec.precision}
    />
  );
}
