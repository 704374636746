import { rem } from '@mantine/core';
import { Node, mergeAttributes } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    deviceMeasurementsPlaceholder: {
      insertDeviceMeasurementsPlaceholder: () => ReturnType;
      setDeviceMeasurementsPlaceholderSectionId: (sectionId: string) => ReturnType;
    };
  }
}

export const DeviceMeasurementsPlaceholder = Node.create({
  name: 'deviceMeasurementsPlaceholder',
  draggable: true,
  inline: false,
  group: 'block',

  addAttributes() {
    return {
      'data-name': { default: 'measurements' },
      'data-format': { default: '' },
      'data-type': { default: 'deviceMeasurements' },
      sectionId: {
        default: '',
        parseHTML: (element) => element.getAttribute('data-section-id'),
        renderHTML: (attributes) => ({ 'data-section-id': attributes.sectionId }),
      },
      'data-path': {
        renderHTML: (attributes) => ({ 'data-path': `measurements.${attributes.sectionId}` }),
      },
    };
  },

  parseHTML() {
    return [{ tag: 'div.device-measurements-placeholder', priority: 60 }];
  },

  renderHTML({ HTMLAttributes, node }) {
    const sectionId = node.attrs.sectionId;

    const extendedAttributes = mergeAttributes(HTMLAttributes, {
      class: 'placeholder device-measurements-placeholder',
    });

    const eWrapper = document.createElement('div');
    for (const [key, value] of Object.entries(extendedAttributes)) {
      eWrapper.setAttribute(key, value);
    }
    eWrapper.ondblclick = () => window.dispatchEvent(new CustomEvent('open-device-measurements-configurator'));
    eWrapper.title = 'Dvojklikom otvoríte konfigurátor';
    eWrapper.style.cursor = 'pointer';

    const eText = document.createElement('div');
    eText.textContent = 'Tabuľka meraní ';

    const eFormat = document.createElement('span');

    eFormat.innerHTML = `(${
      sectionId ? `<span data-inject-section-name="${sectionId}"></span>` : 'Dvojklikom vyberte tabuľku meraní'
    })`;

    eFormat.style.color = sectionId ? 'var(--mantine-color-gray-7)' : 'var(--mantine-color-red-7)';
    eFormat.style.fontSize = rem(12);
    eFormat.style.fontWeight = '500';
    eText.appendChild(eFormat);

    eWrapper.appendChild(eText);

    return eWrapper;
  },

  addCommands() {
    return {
      insertDeviceMeasurementsPlaceholder:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: 'deviceMeasurementsPlaceholder' });
        },
      setDeviceMeasurementsPlaceholderSectionId:
        (sectionId: string) =>
        ({ commands }) => {
          return commands.updateAttributes('deviceMeasurementsPlaceholder', { sectionId });
        },
    };
  },
});
