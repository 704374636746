import { Box, Flex } from '@mantine/core';
import { FaultRow } from 'components/tables/fault/types';
import { IconServer } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the device name.
 */
export default function DeviceNameColumn({ data: { deviceName } }: { data: FaultRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <IconServer stroke={1.5} />
      </Box>
      <P2Regular color="gray.8">{deviceName}</P2Regular>
    </Flex>
  );
}
