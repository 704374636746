import { merge } from 'lodash';
import { useCallback, useState } from 'react';

/**
 * Reads an object from local storage.
 */
function readObjectFromLocalStorage<T>(key: string): T {
  try {
    const value = localStorage.getItem(key) || '{}';
    return JSON.parse(value) as T;
  } catch (e) {
    return {} as T;
  }
}

/**
 * Uses local storage to store the specified value. Uses a write-through cache.
 */
export default function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState(() => merge(defaultValue, readObjectFromLocalStorage<T>(key)));

  /**
   * Decorated value setter which also updates the local storage.
   */
  const setValueDecorated = useCallback(
    (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      setValue(value);
    },
    [setValue]
  );

  return [value, setValueDecorated];
}
