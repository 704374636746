import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataDeleteUsersPathParameters } from 'api/actions/predefined-data-delete-users/predefined-data-delete-users-path-parameters';
import { PredefinedDataDeleteUsersResponse } from 'api/actions/predefined-data-delete-users/predefined-data-delete-users-response';

type PredefinedDataDeleteUsersQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataDeleteUsers action.
 */
export const predefinedDataDeleteUsersActionPath = new SweetPath<keyof PredefinedDataDeleteUsersPathParameters>(
  '/api/predefined-data/delete/users/{predefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataDeleteUsers actions.
 */
export default function createPredefinedDataDeleteUsersAction(axios: AxiosInstance) {
  return function predefinedDataDeleteUsersAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataDeleteUsersPathParameters;
    query?: PredefinedDataDeleteUsersQueryParameters;
  }) {
    const path = predefinedDataDeleteUsersActionPath.insert(parameters);

    return axios.post<PredefinedDataDeleteUsersResponse, PredefinedDataDeleteUsersResponse>(path, {
      params: query,
      headers,
    });
  };
}
