import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import AddDeviceFileModal from 'components/modals/add-device-file/AddDeviceFileModal';

/**
 * Callback for when user uploads the device file.
 */
type OnAddFileCallback = (remoteId: string, fileName: string, tabName: string) => void;

/**
 * Parameters of the addDeviceFile function.
 */
interface AddDeviceFileParams {
  onAdd: OnAddFileCallback;
}

/**
 * Function for adding the file to the device.
 */
type AddDeviceFile = (params: AddDeviceFileParams) => void;

/**
 * Interface of the AddDeviceFileContext.
 */
interface IAddDeviceFileContext {
  addDeviceFile: AddDeviceFile;
}

/**
 * Context for adding the file to the device.
 */
const AddDeviceFileContext = createContext<IAddDeviceFileContext>(undefined!);

/**
 * Provider for adding the file to the device.
 */
export function AddDeviceFileProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<AddDeviceFileParams | undefined>(undefined);

  /**
   * Opens the modal for adding the file to the device.
   */
  const addDeviceFile: AddDeviceFile = (params) => {
    setParams(params);
    open();
  };

  return (
    <AddDeviceFileContext.Provider value={{ addDeviceFile }}>
      {children}
      <AddDeviceFileModal opened={opened} onClose={close} {...params!} />
    </AddDeviceFileContext.Provider>
  );
}

/**
 * Hook for adding file to the device.
 */
export function useAddDeviceFile() {
  return useContext(AddDeviceFileContext);
}
