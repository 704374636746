import { Button, Group, LoadingOverlay, Modal, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useApi } from 'api/api-context';
import BrainItMoreInfo from 'components/BrainItMoreInfo';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { DateInput } from 'components/inputs/DateInput';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import Toast from 'components/Toast';
import P1Regular from 'components/typography/P1Regular';
import { addDays } from 'date-fns';
import panic from 'errors/panic';
import useLoadingAction from 'hooks/use-loading-action';
import { useEffect, useState } from 'react';

/**
 * Parameters of the SignRevisionModal component.
 */
export interface SignRevisionModalProps {
  revisionId: number;
  message: string | JSX.Element;
  opened: boolean;
  onClose: () => void;
  onSign: (({ deadline }: { deadline: string }) => void) | (() => Promise<void>);
}

interface FormValues {
  deadline: string;
}

/**
 * Modal sign revision and set document signature validity.
 */
export default function SignRevisionModal({ revisionId, message, opened, onClose, onSign }: SignRevisionModalProps) {
  const { getAction } = useApi();
  const [{ loading }, onSignImpl] = useLoadingAction(onSign);
  const [inKepHashArchive, setInKepHashArchive] = useState(true);
  const [organizationName, setOrganizationName] = useState('');
  const [fetching, setFetching] = useState(true);

  const form = useForm<FormValues>({
    initialValues: {
      deadline: '',
    },
    validate: {
      deadline: createValidator([required]),
    },
  });

  useEffect(() => {
    if (opened) {
      const revisionGetAction = getAction('RevisionGet');

      revisionGetAction({ parameters: { revisionId: String(revisionId) } })
        .then(({ nextRevisionDate, organization }) => {
          setOrganizationName(organization.organizationName);

          if (!organization.inKepHashArchive) {
            const [tomorrow] = addDays(new Date(), 1).toISOString().split('T');
            form.setFieldValue('deadline', tomorrow);
            setInKepHashArchive(false);
          } else if (nextRevisionDate) {
            form.setFieldValue('deadline', nextRevisionDate);
          } else {
            form.setFieldValue('deadline', '');
          }
        })
        .catch(panic)
        .finally(() => setFetching(false));
    }
  }, [opened, revisionId]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      title="Podpísať revíznu správu"
      zIndex={1000}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      closeButtonProps={{ loading }}
    >
      <LoadingOverlay visible={fetching} loaderProps={{ size: 'md' }} zIndex={1000} />
      <form onSubmit={form.onSubmit(onSignImpl)}>
        <Stack spacing={24}>
          <Stack spacing={16}>
            <P1Regular>{message}</P1Regular>
            <DateInput
              size="md"
              label={
                <Group noWrap spacing={4}>
                  <span>Deadline na prepečiatkovanie</span>
                  <QuestionMarkTooltip
                    multiline
                    width={300}
                    label="Deadline na prepečiatkovanie je dátum, do kedy má byť dokument uložený v KEP Hash archíve. Typicky je to dátum ďalšej revízie."
                  />
                </Group>
              }
              placeholder="Zadajte dátum"
              popoverPosition="bottom-start"
              readOnly={loading || !inKepHashArchive}
              {...form.getInputProps('deadline')}
            />
            {!inKepHashArchive && (
              <Toast
                type="info"
                message={`Organizácia ${organizationName} nepoužíva KEP Hash archív, deadline bude nastavený na zajtrajší dátum.`}
                withCloseButton={false}
              />
            )}
          </Stack>
          {!loading && (
            <Group position="apart">
              <BrainItMoreInfo />
              <Group position="right">
                <Button variant="link" onClick={onClose}>
                  Zrušiť
                </Button>
                <Button variant="primary" type="submit">
                  Prejsť na BrainIT
                </Button>
              </Group>
            </Group>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
