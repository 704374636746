import { NavLink as MenuItem, Tooltip } from '@mantine/core';
import { HTMLAttributeAnchorTarget, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * The parameters of the NavbarItem.
 */
export interface NavbarItemProps {
  isCollapsed?: boolean;
  to: string;
  label?: string | React.ReactNode;
  icon?: React.ReactNode;
  target?: HTMLAttributeAnchorTarget;
}

/**
 * An item in the navbar.
 */
export default function NavbarItem({ isCollapsed, to, icon, label, target }: NavbarItemProps) {
  const styles = useMemo(
    () => ({
      label: { display: isCollapsed ? 'none' : 'initial' },
      icon: { marginRight: isCollapsed ? '0 !important' : '24px' },
      body: { width: isCollapsed ? '80%' : '100%' },
    }),
    [isCollapsed]
  );

  return (
    <NavLink
      target={target}
      tabIndex={-1}
      to={to}
      style={{
        textDecoration: 'none',
      }}
    >
      {({ isActive }) => (
        <Tooltip withinPortal position="right" withArrow={false} label={label} openDelay={isCollapsed ? 200 : 10 ** 7}>
          <MenuItem active={isActive} icon={icon} label={isCollapsed ? '' : label} styles={styles} />
        </Tooltip>
      )}
    </NavLink>
  );
}
