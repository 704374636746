import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { Button, createStyles, Group, Modal, Stack, Textarea } from '@mantine/core';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

interface OpenPageAdvancedSettingsParams {
  onClose?: () => void;
}

interface IPageAdvancedSettingsContext {
  openPageAdvancedSettings: (params: OpenPageAdvancedSettingsParams) => void;
}

const PageAdvancedSettingsContext = createContext<IPageAdvancedSettingsContext>(undefined!);

const useStyles = createStyles(() => ({
  input: {
    height: 'calc(100vh - 300px)',
  },
}));

/**
 * Content of the modal used to set the page border.
 */
function PageAdvancedSettingsModal({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { classes } = useStyles();
  const { visual, setVisual } = useTemplateEditorData();

  const initialValues = useMemo(() => ({ customCss: visual.customCss }), [visual]);
  const form = useForm({ initialValues });

  useEffect(() => {
    form.resetDirty();
    form.setValues(initialValues);
  }, [opened]);

  const onSubmit = form.onSubmit((values) => {
    setVisual((visual) => ({ ...visual, ...values }));
    onClose();
  });

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Pokročilé nastavenia stránky"
      size="60vw"
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={16} pt={16}>
          <Textarea classNames={classes} data-autofocus {...form.getInputProps('customCss')} label="Dodatočné CSS" />

          <Group pt={8} spacing={16} position="right">
            <Button type="button" onClick={onClose} variant="subtle-gray">
              Zrušiť
            </Button>
            <Button type="submit" variant="primary">
              Nastaviť
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

/**
 * Provides a context for managing the page border form state.
 */
export function PageAdvancedSettingsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure();
  const [params, setParams] = useState<OpenPageAdvancedSettingsParams>({});

  const openPageAdvancedSettings = useCallback(
    (params: OpenPageAdvancedSettingsParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const onClose = useCallback(() => {
    params.onClose?.();
    close();
  }, [params, close]);

  const value = useMemo(() => ({ openPageAdvancedSettings }), [openPageAdvancedSettings]);

  return (
    <PageAdvancedSettingsContext.Provider value={value}>
      {children}
      <PageAdvancedSettingsModal opened={opened} onClose={onClose} />
    </PageAdvancedSettingsContext.Provider>
  );
}

/**
 * Hook for opening the page border form.
 */
export function usePageAdvancedSettings() {
  return useContext(PageAdvancedSettingsContext);
}
