import { Box, Checkbox, Flex, Group, ScrollArea, Skeleton, Stack, TextInput, useMantineTheme } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import P1Medium from 'components/typography/P1Medium';
import P1Regular from 'components/typography/P1Regular';
import P2Regular from 'components/typography/P2Regular';
import { groupBy, isEmpty, noop } from 'lodash';
import { useMemo, useState } from 'react';

export interface MultiCheckboxItem {
  value: number;
  label: string;
  iconLeft?: string | React.ReactNode | null;
  iconRight?: string | React.ReactNode | null;
  selected?: boolean;
  disabled?: boolean;
  group?: string;
  [key: string]: any;
}

export interface MultiCheckboxPropsBase {
  group?: boolean;
  withSearch?: boolean;
  excludeIds?: number[];
  value?: number[];
  onChange?: (value: number[]) => void;
}

interface MultiCheckboxProps extends MultiCheckboxPropsBase {
  data: MultiCheckboxItem[];
  searchPlaceholder?: string;
  noResultPlaceholder?: string;
  loading?: boolean;
}

/**
 * Searchable multi-checkbox input. Allows to search the listed values and check more than one
 * checkbox.
 */
export default function MultiCheckbox({
  data,
  group = false,
  loading = false,
  withSearch = false,
  excludeIds = [],
  value = [],
  onChange = noop,
}: MultiCheckboxProps) {
  const theme = useMantineTheme();
  const [search, setSearch] = useState('');
  const normalizedSearch = useMemo(() => search.toLocaleLowerCase(), [search]); // TODO: Remove diacritics
  const excludeIdsSet = useMemo(() => new Set(excludeIds), [excludeIds]);

  // TODO remove diacritics in _search
  const enhancedData = useMemo(
    () =>
      data.map((item) => {
        const labelSearch = item.label.toLocaleLowerCase();
        const groupSearch = item.group?.toLocaleLowerCase() ?? '';
        const fullSearch = `${labelSearch}${groupSearch}`;

        return { ...item, _search: fullSearch };
      }),
    [data]
  );

  const filteredData = useMemo(
    () => enhancedData.filter(({ _search, value }) => !excludeIdsSet.has(value) && _search.includes(normalizedSearch)),
    [enhancedData, normalizedSearch, excludeIdsSet]
  );

  const groupedData = useMemo(
    () => Object.entries(group ? groupBy(filteredData, 'group') : { '': filteredData }),
    [filteredData, group]
  );

  if (loading) {
    return (
      <Stack spacing={24} pb={24}>
        <Skeleton height={40} radius={4} animate />
        <Skeleton height={40} radius={4} animate />
        <Skeleton height={40} radius={4} animate />
      </Stack>
    );
  }

  return (
    <Stack spacing={24}>
      {withSearch && (
        <Box pt={4}>
          <TextInput
            size="md"
            placeholder="Hľadať"
            rightSection={<IconSearch stroke="1.5" color={theme.colors.gray[6]} />}
            value={search}
            onChange={(event) => setSearch(event.currentTarget.value)}
          />
        </Box>
      )}
      <ScrollArea h={410}>
        <Stack spacing={24} pl={8} pt={8} pb={24}>
          {groupedData.map(([groupName, items]) => (
            <Stack spacing={16} key={groupName}>
              {group && <P1Medium color="gray.9">{groupName}</P1Medium>}
              <Flex gap={24}>
                {group && <Box w={4} bg="gray.1" />}
                <Stack w="100%" spacing={16}>
                  {items.map((item) => (
                    <Checkbox
                      key={item.value}
                      label={
                        <Group position="left" spacing={8}>
                          {item.iconLeft}
                          <P1Regular>{item.label}</P1Regular>
                          {item.iconRight}
                        </Group>
                      }
                      size="lg"
                      checked={value.includes(item.value)}
                      onChange={(event) => {
                        const { checked } = event.currentTarget;
                        const newValue = checked ? [...value, item.value] : value.filter((val) => val !== item.value);
                        onChange(newValue);
                      }}
                    />
                  ))}
                </Stack>
              </Flex>
            </Stack>
          ))}
          {(isEmpty(filteredData) || isEmpty(data)) && <P2Regular>Neboli nájdené žiadne záznamy.</P2Regular>}
        </Stack>
      </ScrollArea>
    </Stack>
  );
}
