import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthRemoveUserDepartmentPairsPayload } from 'api/actions/auth-remove-user-department-pairs/auth-remove-user-department-pairs-payload';
import { AuthRemoveUserDepartmentPairsPathParameters } from 'api/actions/auth-remove-user-department-pairs/auth-remove-user-department-pairs-path-parameters';
import { AuthRemoveUserDepartmentPairsResponse } from 'api/actions/auth-remove-user-department-pairs/auth-remove-user-department-pairs-response';

type AuthRemoveUserDepartmentPairsQueryParameters = Record<string, any>;

/**
 * The path of the AuthRemoveUserDepartmentPairs action.
 */
export const authRemoveUserDepartmentPairsActionPath = new SweetPath<keyof AuthRemoveUserDepartmentPairsPathParameters>(
  '/api/auth/user-department/remove',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthRemoveUserDepartmentPairs actions.
 */
export default function createAuthRemoveUserDepartmentPairsAction(axios: AxiosInstance) {
  return function authRemoveUserDepartmentPairsAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthRemoveUserDepartmentPairsQueryParameters;
    payload: AuthRemoveUserDepartmentPairsPayload;
  }) {
    const path = authRemoveUserDepartmentPairsActionPath.original;

    return axios.put<
      AuthRemoveUserDepartmentPairsResponse,
      AuthRemoveUserDepartmentPairsResponse,
      AuthRemoveUserDepartmentPairsPayload
    >(path, payload);
  };
}
