import { Stack } from '@mantine/core';
import PermissionBox from 'components/inputs/permission-input/PermissionBox';
import { groupBy, uniq } from 'lodash';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useMemo } from 'react';

interface PermissionInputProps {
  permissionType: 'organization' | 'department';
  value: number[];
  onChange: (value: number[]) => void;
}

/**
 * Displays all available permissions and allows to check/uncheck them.
 */
export default function PermissionInput({ permissionType, value, onChange }: PermissionInputProps) {
  const { departmentPermissions, organizationPermissions } = usePermissionDataProvider();
  const permissions = useMemo(
    () =>
      permissionType === 'department'
        ? departmentPermissions
        : permissionType === 'organization'
        ? organizationPermissions
        : [],
    [permissionType]
  );

  const allPermissions = useMemo(() => groupBy(permissions, 'permissionGroup'), [permissions]);

  /**
   * Checks if the permission is checked.
   */
  const hasPermission = (permissionId: number) => value.includes(permissionId);

  /**
   * Add multiple permissions.
   */
  const addPermissions = (permissions: number[]) => {
    onChange(uniq([...value, ...permissions]));
  };

  /**
   * Remove multiple permissions.
   */
  const removePermissions = (permissions: number[]) => {
    onChange(value.filter((id) => !permissions.includes(id)));
  };

  /**
   * Toggles permission.
   */
  const setPermission = (permissionId: number, checked: boolean) => {
    if (checked) {
      addPermissions([permissionId]);
    } else {
      removePermissions([permissionId]);
    }
  };

  return (
    <Stack spacing={12}>
      {Object.entries(allPermissions).map(([permissionGroup, permissions]) => (
        <PermissionBox
          key={permissionGroup}
          permissionGroup={permissionGroup}
          permissions={permissions}
          setPermission={setPermission}
          hasPermission={hasPermission}
          removePermissions={removePermissions}
          addPermissions={addPermissions}
        />
      ))}
    </Stack>
  );
}
