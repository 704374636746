import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataCreateGlobalPayload } from 'api/actions/predefined-data-create-global/predefined-data-create-global-payload';
import { PredefinedDataCreateGlobalPathParameters } from 'api/actions/predefined-data-create-global/predefined-data-create-global-path-parameters';
import { PredefinedDataCreateGlobalResponse } from 'api/actions/predefined-data-create-global/predefined-data-create-global-response';

type PredefinedDataCreateGlobalQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataCreateGlobal action.
 */
export const predefinedDataCreateGlobalActionPath = new SweetPath<keyof PredefinedDataCreateGlobalPathParameters>(
  '/api/predefined-data/create/global',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataCreateGlobal actions.
 */
export default function createPredefinedDataCreateGlobalAction(axios: AxiosInstance) {
  return function predefinedDataCreateGlobalAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: PredefinedDataCreateGlobalQueryParameters;
    payload: PredefinedDataCreateGlobalPayload;
  }) {
    const path = predefinedDataCreateGlobalActionPath.original;

    return axios.post<
      PredefinedDataCreateGlobalResponse,
      PredefinedDataCreateGlobalResponse,
      PredefinedDataCreateGlobalPayload
    >(path, payload);
  };
}
