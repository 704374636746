import { ActionIcon, Box, Divider, Group, Menu, Portal, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconStar, IconTrashX } from '@tabler/icons-react';
import { useCallback, useMemo, useState } from 'react';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import { UseFormReturnType } from '@mantine/form';
import { isEmpty, noop } from 'lodash';
import P1Medium from 'components/typography/P1Medium';
import FileLink from 'components/files/FileLink';
import P2Regular from 'components/typography/P2Regular';
import useFileDownload from 'api/use-file-download';
import FileThumbnail from 'components/files/FileThumbnail';
import { useConfirm } from 'components/modals/message/MessageProvider';
import Slider from 'components/modals/slider/Slider';

interface DeviceFileTabContentProps {
  form?: UseFormReturnType<DeviceFormData>;
  files: DeviceFormData['files'];
  tabName: 'project-documentation' | 'revisions' | 'photos' | 'other';
  readonly?: boolean;
}

/**
 * The tab content of the Device files section.
 */
export default function DeviceFileTabContent({ form, files, tabName, readonly }: DeviceFileTabContentProps) {
  const theme = useMantineTheme();
  const { download } = useFileDownload();
  const { confirm } = useConfirm();
  const [openedSlider, setOpenSlider] = useState(false);
  const [initialFileId, setInitialFileId] = useState('');
  const [hideSliderControls, setHideSliderControls] = useState(false);
  const fileIds = useMemo(
    () => (files[tabName] || []).filter(({ deleted }) => !deleted).map(({ fileId }) => fileId),
    [files, tabName]
  );

  /**
   * Sets the file as the main image.
   */
  const setAsMainImage = (fileId: string, index: number) => {
    // ensure that the main image is only one
    form!.setFieldValue(
      `files.${tabName}`,
      (files[tabName] || []).map((file) => ({ ...file, isMainImage: false }))
    );
    form!.setFieldValue(`files.${tabName}.${index}.isMainImage`, true);
  };

  /**
   * Removes the file from the form
   */
  const deleteFile = (index: number) => {
    // soft delete the file
    form!.setFieldValue(`files.${tabName}.${index}.deleted`, true);

    // mark other image as the main one if the main got deleted
    if (tabName === 'photos') {
      const isMainImage = form!.getInputProps(`files.${tabName}.${index}.isMainImage`).value;
      if (isMainImage) {
        const nextMainImage = (files[tabName] || []).find(({ deleted, isMainImage }) => !deleted && !isMainImage);
        if (nextMainImage) {
          form!.setFieldValue(`files.${tabName}.${files[tabName].indexOf(nextMainImage)}.isMainImage`, true);
        }
      }
    }
  };

  /**
   * Confirms the file deletion.
   */
  const handleDelete = useCallback(
    (index: number) =>
      confirm({
        title: 'Potvrdenie zmazania',
        content: 'Naozaj si prajete vymazať súbor?',
        onConfirm: () => deleteFile(index),
      }),
    [deleteFile]
  );

  const gridStyle = useMemo(
    () => ({
      display: 'grid',
      gridTemplateColumns: `${tabName === 'photos' ? '24px ' : ''}80px 4fr 3fr 4fr 40px`,
      gap: '24px',
      borderRadius: '4px',
      alignItems: 'center',
    }),
    [tabName]
  );

  return (
    <Box pt={24}>
      <Box sx={gridStyle} bg="gray.0" p={16}>
        {tabName === 'photos' && <P1Medium color="gray.8"></P1Medium>}
        <P1Medium color="gray.8" pl={4}>
          Náhľad
        </P1Medium>
        <P1Medium color="gray.8">Názov</P1Medium>
        <P1Medium color="gray.8">Súbor</P1Medium>
        <P1Medium color="gray.8">Nahratý</P1Medium>
      </Box>
      {(isEmpty(files[tabName]) || files[tabName].every(({ deleted }) => deleted)) && (
        <Box py={24} px={16}>
          <P1Medium> Momentálne nie sú nahraté žiadne súbory</P1Medium>
        </Box>
      )}
      {/* The rows with individual files */}
      {(files[tabName] || []).map((file, i) =>
        file.deleted ? null : (
          <Box key={file.key}>
            <Box sx={gridStyle} p={16}>
              {tabName === 'photos' &&
                (form?.getInputProps(`files.${tabName}.${i}.isMainImage`).value || file.isMainImage ? (
                  <Group w={24} style={{ cursor: readonly ? 'not-allowed' : 'initial' }}>
                    <Tooltip label="Hlavný obrázok" withArrow={false} openDelay={500}>
                      <IconStar stroke={2} color="#E6B24E" fill="#E6B24E" />
                    </Tooltip>
                  </Group>
                ) : (
                  <Group
                    w={24}
                    style={{ cursor: readonly ? 'not-allowed' : 'pointer' }}
                    onClick={() => (readonly ? noop : setAsMainImage(file.fileId, i))}
                  >
                    <Tooltip label="Nastaviť ako hlavný obrázok" withArrow={false} openDelay={500}>
                      <IconStar stroke={2} color={`${theme.colors.gray[5]}`} />
                    </Tooltip>
                  </Group>
                ))}
              <Group w={80}>
                <FileThumbnail
                  fileId={file.fileId}
                  onOpenSlider={() => {
                    setInitialFileId(file.fileId);
                    setOpenSlider(true);
                    setHideSliderControls(tabName !== 'photos');
                  }}
                />
              </Group>
              <TextInput
                size="sm"
                value={file.fileName}
                onChange={readonly ? noop : form!.getInputProps(`files.${tabName}.${i}.fileName`).onChange}
                readOnly={readonly}
                name={`amperia-files.${tabName}.${i}.fileName`}
              />
              <FileLink
                fileId={file.fileId}
                onOpenSlider={() => {
                  setInitialFileId(file.fileId);
                  setOpenSlider(true);
                  setHideSliderControls(tabName !== 'photos');
                }}
              />
              <P2Regular>{file.uploadedAt.toLocaleString()}</P2Regular>
              <Menu position="bottom-end">
                <Menu.Target>
                  <ActionIcon variant="tertiary" size="md">
                    <IconDotsVertical stroke="1.5" height={24} width={24} />
                  </ActionIcon>
                </Menu.Target>
                <Portal>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => {
                        download(file.fileId);
                      }}
                      icon={<IconDownload stroke={1.5} size={20} />}
                    >
                      Stiahnuť
                    </Menu.Item>
                    {!readonly && (
                      <Menu.Item
                        color="red.8"
                        onClick={() => handleDelete(i)}
                        icon={<IconTrashX stroke={1.5} size={20} />}
                      >
                        Vymazať
                      </Menu.Item>
                    )}
                  </Menu.Dropdown>
                </Portal>
              </Menu>
            </Box>
            <Divider color="gray.1" />
          </Box>
        )
      )}

      <Slider
        key={initialFileId}
        opened={openedSlider}
        fileIds={hideSliderControls ? [initialFileId] : fileIds}
        onClose={() => setOpenSlider(false)}
        initialImageId={initialFileId}
        hideControls={hideSliderControls}
      />
    </Box>
  );
}
