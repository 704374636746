import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import emailFormat from 'components/forms/validators/rules/rule-email-format';
import { noop } from 'lodash';
import { Button, Divider, Stack, TextInput } from '@mantine/core';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import P2Regular from 'components/typography/P2Regular';
import H2Medium from 'components/typography/H2Medium';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from 'routes/paths';

/**
 * The data collected from the form.
 */
export interface ResetPasswordFormData {
  email: string;
}

/**
 * Form for reset password.
 */
export default function ResetPasswordForm({
  initialValues = {
    email: '',
  },
  onSubmit = noop,
}: FormProps<ResetPasswordFormData> = {}) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const form = useForm<ResetPasswordFormData>({
    initialValues,
    validate: {
      email: createValidator([required, emailFormat]),
    },
  });

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={4} p={24}>
        <H2Medium>Zabudnuté heslo</H2Medium>
        <P2Regular color="gray.7">
          Zadajte e-mail adresu, pod ktorou ste zaregistrovaný. Následne vám pošleme link na zadanie nového hesla
        </P2Regular>
      </Stack>
      <Divider my={0} color="gray.1" />
      <Stack p={24}>
        <TextInput
          withAsterisk
          size="lg"
          label="E-mail"
          placeholder="Zadajte váš e-mail"
          name="amperia-email"
          {...form.getInputProps('email')}
        />
      </Stack>
      <Divider my={0} color="gray.1" />
      <Stack spacing={10} p={24}>
        <Button loading={loading} size="lg" type="submit" fullWidth>
          Poslať link
        </Button>
        <Button
          loading={loading}
          loaderPosition="center"
          loaderProps={{ size: 0 }}
          component={Link}
          to={LOGIN_PAGE_PATH.original}
          fullWidth
          size="lg"
          variant="subtle-gray"
          type="button"
        >
          Zrušiť
        </Button>
      </Stack>
    </form>
  );
}
