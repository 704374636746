import { Box, LoadingOverlay } from '@mantine/core';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import SetNewPasswordForm, { SetNewPasswordFormData } from 'components/forms/set-new-password/SetNewPasswordForm';
import panic from 'errors/panic';
import SimpleLayout from 'layouts/simple/SimpleLayout';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { EXPIRED_RESET_LINK_PAGE_PATH, LOGIN_PAGE_PATH } from 'routes/paths';

/**
 * The content of the page where the user sets a new password.
 */
function SetNewPasswordPageContent({ email, token }: { email: string; token: string }) {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const { data, loading, error } = useImmediateAction(() => {
    const checkResetPasswordTokenAction = getAction('AuthCheckResetPasswordToken');
    return checkResetPasswordTokenAction({ payload: { email: email!, token: token! } });
  });

  if (!loading && (!data || error)) {
    return <Navigate to={EXPIRED_RESET_LINK_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function handleSubmit({ password, passwordAgain }: SetNewPasswordFormData) {
    const setNewPasswordAction = getAction('AuthResetPassword');

    return setNewPasswordAction({ payload: { email, token, password, passwordConfirmation: passwordAgain } })
      .then(() => navigate(`${LOGIN_PAGE_PATH.original}?resetPassword=success`))
      .catch(panic);
  }

  return (
    <SimpleLayout>
      <Box pos="relative">
        <LoadingOverlay visible={loading} />
        <SetNewPasswordForm onSubmit={handleSubmit} />
      </Box>
    </SimpleLayout>
  );
}

/**
 * The page where the user sets a new password.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=189%3A9644 Figma Design}
 * - {@link https://www.notion.so/Profile-Set-New-Password-d3dac689add64d04bbc071c7e9492bcc?pvs=4 Notion Page}
 */
export default function SetNewPasswordPage() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  if (!token || !email) {
    return <Navigate to={EXPIRED_RESET_LINK_PAGE_PATH.original} />;
  }

  return <SetNewPasswordPageContent email={email} token={token} />;
}
