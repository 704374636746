import { Button, Group, Modal, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import P1Regular from 'components/typography/P1Regular';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

/**
 * Parameters of the ConfirmWithMessageModal component.
 */
export interface ConfirmWithMessageModalProps {
  title?: string;
  message: string;
  opened: boolean;
  requiredReason?: boolean;
  onClose: () => void;
  onConfirmWithMessage: ((reason?: string) => void) | (() => Promise<void>);
}

interface FormValues {
  reason: string;
}

/**
 * Modal used to confirm an action.
 */
export default function ConfirmWithMessageModal({
  title,
  message,
  opened,
  requiredReason = false,
  onClose,
  onConfirmWithMessage,
}: ConfirmWithMessageModalProps) {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm<FormValues>({
    initialValues: {
      reason: '',
    },
    validate: {
      reason: (value) => {
        if (requiredReason && value === '') {
          return 'Pole je poivnné.';
        }

        return null;
      },
    },
  });

  /**
   * Submits the form.
   */
  async function submit({ reason }: FormValues) {
    try {
      setLoading(true);
      setClicked(true);
      await onConfirmWithMessage(requiredReason ? reason : reason === '' ? undefined : reason);
      setLoading(false);
      form.reset();
      form.clearErrors();
      onClose();
    } catch (err: any) {
      panic(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (opened) {
      setClicked(false);
    }
  }, [opened]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      title={title}
      zIndex={1000}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      closeButtonProps={{ loading }}
    >
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={24}>
          <P1Regular>{message}</P1Regular>
          <Textarea
            placeholder={`Dôvod ${requiredReason ? '' : '(nepovinné)'}`}
            size="md"
            form="confirm-with-message-modal"
            disabled={loading}
            name="amperia-reason"
            {...form.getInputProps('reason')}
          />
          {!loading && !clicked && (
            <Group position="apart">
              <Button
                variant="link"
                onClick={() => {
                  form.reset();
                  form.clearErrors();

                  onClose();
                }}
              >
                Zrušiť
              </Button>
              <Button variant="primary" type="submit">
                Potvrdiť
              </Button>
            </Group>
          )}
        </Stack>
      </form>
    </Modal>
  );
}
