import { Box, Button, Group, Modal } from '@mantine/core';
import P1Regular from 'components/typography/P1Regular';
import panic from 'errors/panic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isFunction } from 'lodash';
import { ICallback, IContent } from 'components/modals/message/MessageProvider';

/**
 * Parameters of the MessageModal component.
 */
export interface MessageModalProps {
  title?: string;
  content: IContent;
  opened: boolean;
  onClose: () => void;
  onMessage: ICallback;
  size?: string | number;
  zIndex?: number;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hidePrimaryButton?: boolean;
  hideSecondaryButton?: boolean;
  footerLeftSection?: React.ReactNode;
  returnFocus?: boolean;
}

/**
 * Modal used to confirm an action.
 */
export default function MessageModal({
  title,
  content,
  opened,
  onClose,
  onMessage,
  size = 'md',
  zIndex = 1000,
  primaryButtonText = 'Áno',
  secondaryButtonText = 'Nie',
  hidePrimaryButton = false,
  hideSecondaryButton = false,
  footerLeftSection = <Box />,
  returnFocus = true,
}: MessageModalProps) {
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const messageContent = useMemo(() => (isFunction(content) ? content({ onClose }) : content), [content, onClose]);

  /**
   * Handles the click on the confirm button.
   */
  const onClick = useCallback(async () => {
    try {
      setLoading(true);
      setClicked(true);
      await onMessage();
      setLoading(false);
      onClose();
    } catch (err: any) {
      panic(err);
    } finally {
      setLoading(false);
    }
  }, [setLoading, onMessage, onClose]);

  useEffect(() => {
    if (opened) {
      setClicked(false);
    }
  }, [opened]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={opened}
      title={title}
      zIndex={zIndex}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      closeButtonProps={{ loading }}
      size={size}
      returnFocus={returnFocus}
    >
      <Box mb={32}>
        <P1Regular>{messageContent}</P1Regular>
      </Box>
      {!loading && !clicked && (
        <Group position="apart" spacing={16}>
          {footerLeftSection}
          <Group position="right" spacing={16}>
            {!hideSecondaryButton && (
              <Button variant="link" onClick={onClose} px={8}>
                {secondaryButtonText}
              </Button>
            )}
            {!hidePrimaryButton && (
              <Button variant="primary" onClick={onClick}>
                {primaryButtonText}
              </Button>
            )}
          </Group>
        </Group>
      )}
    </Modal>
  );
}
