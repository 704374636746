import { IVisual } from 'pages/revisions-module/template-editor/editors/visual/types';
import { getHeadingSizes } from 'pages/revisions-module/template-editor/editors/visual/typography/heading-sizes';

const DEFAULT_FONT_SIZE_P = 14;

export const DEFAULT_VISUAL: IVisual = {
  marginTop: 20,
  marginBottom: 20,
  marginLeft: 17,
  marginRight: 17,

  frame: false,
  frameWidth: 0,
  frameColor: 'rgb(0 0, 0)',
  framePadding: 0,

  fontFamily: 'sans-serif',
  fontSizeP: DEFAULT_FONT_SIZE_P,
  ...getHeadingSizes(DEFAULT_FONT_SIZE_P),
  lineHeightP: 1.5,
  lineHeightH: 1.2,
  textColor: 'rgb(0, 0, 0)',

  customCss: '',
};
