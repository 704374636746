import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { MeasuringDeviceListPathParameters } from 'api/actions/measuring-device-list/measuring-device-list-path-parameters';
import { MeasuringDeviceListResponse } from 'api/actions/measuring-device-list/measuring-device-list-response';

type MeasuringDeviceListQueryParameters = Record<string, any>;

/**
 * The path of the MeasuringDeviceList action.
 */
export const measuringDeviceListActionPath = new SweetPath<keyof MeasuringDeviceListPathParameters>(
  '/api/measuring-device/list',
  { path: '{param}' }
);

/**
 * Creates a contextualized MeasuringDeviceList actions.
 */
export default function createMeasuringDeviceListAction(axios: AxiosInstance) {
  return function measuringDeviceListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: MeasuringDeviceListQueryParameters } = {}) {
    const path = measuringDeviceListActionPath.original;

    return axios.get<MeasuringDeviceListResponse, MeasuringDeviceListResponse>(path, { params: query, headers });
  };
}
