import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionGetRevisionTemplatePathParameters } from 'api/actions/revision-get-revision-template/revision-get-revision-template-path-parameters';
import { RevisionGetRevisionTemplateResponse } from 'api/actions/revision-get-revision-template/revision-get-revision-template-response';

type RevisionGetRevisionTemplateQueryParameters = Record<string, any>;

/**
 * The path of the RevisionGetRevisionTemplate action.
 */
export const revisionGetRevisionTemplateActionPath = new SweetPath<keyof RevisionGetRevisionTemplatePathParameters>(
  '/api/revision/revision-template/get/{revisionTemplateId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionGetRevisionTemplate actions.
 */
export default function createRevisionGetRevisionTemplateAction(axios: AxiosInstance) {
  return function revisionGetRevisionTemplateAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionGetRevisionTemplatePathParameters;
    query?: RevisionGetRevisionTemplateQueryParameters;
  }) {
    const path = revisionGetRevisionTemplateActionPath.insert(parameters);

    return axios.get<RevisionGetRevisionTemplateResponse, RevisionGetRevisionTemplateResponse>(path, {
      params: query,
      headers,
    });
  };
}
