import { createStyles, Group, rem, SimpleGrid, Stack } from '@mantine/core';
import H3Medium from 'components/typography/H3Medium';
import RevisionField from 'components/forms/revision/fill-out/RevisionField';
import DeviceFaultsButton from 'components/forms/revision/fill-out/devices/DeviceFaultsButton';
import DeviceMeasurementButton from 'components/forms/revision/fill-out/devices/DeviceMeasurementButton';
import { RevisionDevice } from 'components/forms/revision/fill-out/types';
import { useMemo } from 'react';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import P3Regular from 'components/typography/P3Regular';
import { FEATURE_TOGGLE_SHOW_DEVICE_ID_IN_REVISION_FILL_OUT_FORM } from 'env';

const useStyles = createStyles((theme) => ({
  header: {
    border: `${rem(1)} solid ${theme.colors.gray[2]}`,
    borderTopLeftRadius: rem(4),
    borderTopRightRadius: rem(4),
  },
  body: {
    borderLeft: `${rem(1)} solid ${theme.colors.gray[2]}`,
    borderRight: `${rem(1)} solid ${theme.colors.gray[2]}`,
    borderBottom: `${rem(1)} solid ${theme.colors.gray[2]}`,
    borderBottomLeftRadius: rem(4),
    borderBottomRightRadius: rem(4),
  },
}));

/**
 * A single device in the revision form.
 */
export default function DeviceSection({ device }: { device: RevisionDevice }) {
  const { classes } = useStyles();
  const { sectionsByTab } = useFillOutRevisionDataProvider();
  const rows = useMemo(() => sectionsByTab.device[0]?.rows ?? [], [sectionsByTab]);
  const namePrefix = useMemo(() => `devices.${device.deviceId}.fields.`, [device.deviceId]);

  return (
    <Stack spacing={0}>
      <Group bg="gray.0" className={classes.header} position="apart" p={24}>
        <Group spacing={8}>
          <H3Medium>{device.fields._deviceName}</H3Medium>
          {FEATURE_TOGGLE_SHOW_DEVICE_ID_IN_REVISION_FILL_OUT_FORM && (
            <P3Regular c="gray.6">(ID: {device.deviceId})</P3Regular>
          )}
        </Group>
        <Group spacing={16}>
          {sectionsByTab.measurements.map((measurement) => (
            <DeviceMeasurementButton key={measurement.id} device={device} measurement={measurement} />
          ))}
          <DeviceFaultsButton device={device} />
        </Group>
      </Group>
      <Stack p={24} spacing={16} className={classes.body}>
        {rows.map((row) => (
          <SimpleGrid key={row.id} cols={row.inputs.length} spacing={24}>
            {row.inputs.map((input) => (
              <RevisionField key={input.id} field={input} namePrefix={namePrefix} />
            ))}
          </SimpleGrid>
        ))}
      </Stack>
    </Stack>
  );
}
