import { useCallback, useState } from 'react';

type TKeyName = string;

type TItem = Record<TKeyName, any>;

type ImmutableListReturnType = [
  TItem[],
  {
    add: (item: TItem) => void;
    remove: (key: TItem[TKeyName]) => void;
    update: (key: TItem[TKeyName], updates: Partial<Omit<TItem, TKeyName>>) => void;
  }
];

/**
 * Hook to manage an immutable list.
 */
export default function useImmutableList(
  keyProp: TKeyName,
  initialList: TItem[] | (() => TItem[]) = []
): ImmutableListReturnType {
  const [list, setList] = useState(initialList);

  const add = useCallback((item: TItem) => setList((prev) => [...prev, item]), []);

  const remove = useCallback(
    (key: TItem[TKeyName]) => setList((prev) => prev.filter((item) => item[keyProp] !== key)),
    []
  );

  const update = useCallback(
    (key: TItem[TKeyName], updates: Partial<Omit<TItem, TKeyName>>) =>
      setList((prev) => prev.map((item) => (item[keyProp] === key ? { ...item, ...updates } : item))),
    []
  );

  return [list, { add, remove, update }];
}
