import { DeviceType, getDeviceType } from 'model/DeviceType';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import { Group } from '@mantine/core';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import { isObject } from 'lodash';
import panic from 'errors/panic';

/**
 * Parameters of the DeviceTypeLabel component.
 */
export interface DeviceTypeLabelProps {
  /**
   * The device type to determine the appropriate label. Either a device type
   * object or a device type ID or slug.
   */
  deviceType: number | string | DeviceType;

  /**
   * Size of the type label.
   */
  size?: 'sm' | 'md';

  /**
   * Whether to show only the icon.
   */
  iconOnly?: boolean;

  /**
   * The label suffix.
   */
  suffix?: string;
}

/**
 * Label for device type with icon.
 */
export default function DeviceTypeLabel({
  deviceType,
  size = 'sm',
  iconOnly = false,
  suffix = '',
}: DeviceTypeLabelProps) {
  const Text = size === 'sm' ? P2Regular : P1Medium;
  const type = isObject(deviceType) ? deviceType : getDeviceType(deviceType);

  if (!type) {
    panic(new Error(`Unknown device type: ${deviceType}`));
    return <></>;
  }

  return (
    <Group spacing={8} noWrap>
      <DeviceTypeIcon typeId={type.id} size={size} />
      {!iconOnly && <Text color="gray.8">{size === 'sm' ? type.shortName : type.name}</Text>}
      {suffix && <Text color="gray.8">{suffix}</Text>}
    </Group>
  );
}
