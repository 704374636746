import { TextInput } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Sub-form for number input properties
 */
export default function SafetyPointInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return (
    <>
      <TextInput required size="sm" label="Text pre možnosť vyhovuje" {...form.getInputProps('suitableText')} />
      <TextInput required size="sm" label="Text pre možnosť nevyhovuje" {...form.getInputProps('notSuitableText')} />
      <TextInput
        required
        size="sm"
        label="Text pre možnosť vyhovuje s výhradami"
        {...form.getInputProps('withReservationsText')}
      />
    </>
  );
}
