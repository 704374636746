import { Box, Button, Group, MantineColor, Skeleton, useMantineTheme } from '@mantine/core';
import { IconTrashX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import P1Medium from 'components/typography/P1Medium';
import P1Regular from 'components/typography/P1Regular';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useEffect, useMemo, useState } from 'react';

/**
 * Single row in the list of selected organizations/departments.
 */
export function SelectedEntityRow({
  name,
  deleteText,
  onDelete,
}: {
  name: string;
  deleteText: string;
  onDelete: () => void;
}) {
  const theme = useMantineTheme();

  return (
    <Group position="apart" style={{ borderBottom: `1px solid ${theme.colors.gray[1]}` }} px={16} py={24}>
      <P1Medium color="gray.8">{name}</P1Medium>
      <Button
        variant="danger-secondary"
        leftIcon={<IconTrashX stroke={1.5} color={theme.colors.red[8]} />}
        onClick={onDelete}
      >
        {deleteText}
      </Button>
    </Group>
  );
}

/**
 * Label in the three-way select component.
 */
export function ThreeWaySelectLabel({
  count,
  title,
  active,
  value,
  setValue,
  disabled = false,
  disabledText = '',
}: {
  count: number;
  title: string;
  active: boolean;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  disabledText?: string;
}) {
  const theme = useMantineTheme();

  const bg: MantineColor = useMemo(() => {
    if (active) {
      return disabled ? 'gray.6' : 'blue.8';
    }

    return 'white';
  }, [active, disabled]);

  const hoverBg = useMemo(() => {
    if (disabled) {
      return undefined;
    }

    return active ? theme.colors.blue[9] : theme.colors.gray[1];
  }, [theme, active, disabled]);

  return (
    <Box
      bg={bg}
      w={`${Math.floor(98 / count)}%`}
      py={8}
      px={12}
      sx={(theme) => ({
        borderRadius: '4px',
        textAlign: 'center',
        cursor: disabled ? 'not-allowed' : 'pointer',
        '&:hover': {
          backgroundColor: hoverBg,
        },
      })}
      onClick={() => {
        if (!disabled) {
          setValue(value);
        }
      }}
      title={disabled ? disabledText : ''}
    >
      <P1Regular color={active ? 'white' : 'gray.8'}>{title}</P1Regular>
    </Box>
  );
}

interface ThreeWaySelectProps {
  value: 'global' | 'organization' | 'department';
  onChange: (value: 'global' | 'organization' | 'department') => void;
  hideDepartment?: boolean;
}

/**
 * Three-way select component.
 */
export default function ThreeWaySelect({ value, onChange, hideDepartment = false }: ThreeWaySelectProps) {
  const theme = useMantineTheme();
  const { roleId, getAction } = useApi();
  const [allowedGlobal, setAllowedGlobal] = useState(roleId === ROLE_ADMIN_ID);
  const [allowedOrganization, setAllowedOrganization] = useState(roleId === ROLE_ADMIN_ID);
  const [allowedDepartment, setAllowedDepartment] = useState(roleId === ROLE_ADMIN_ID);
  const [loading, setLoading] = useState(roleId !== ROLE_ADMIN_ID);

  useEffect(() => {
    if (roleId === ROLE_ADMIN_ID) {
      setAllowedGlobal(true);
      setAllowedOrganization(true);
      setAllowedDepartment(true);
      setLoading(false);
    } else {
      const getDepartments = getAction('AuthGetDepartmentsWithPermission');
      const getOrganizations = getAction('AuthGetOrganizationsWithPermission');

      setAllowedGlobal(false);

      Promise.all([
        getDepartments({ parameters: { permissionSlug: 'define-device-subtypes' } }).then(
          (departmentsWithPermission) => {
            setAllowedDepartment(departmentsWithPermission.length > 0);
          }
        ),
        getOrganizations({ parameters: { permissionSlug: 'define-device-subtypes' } }).then(
          (organizationsWithPermission) => {
            setAllowedOrganization(organizationsWithPermission.length > 0);
          }
        ),
      ])
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [roleId]);

  return loading ? (
    <Skeleton />
  ) : (
    <Group
      p={4}
      spacing={4}
      position="center"
      style={{ borderRadius: '7px', border: `1px solid ${theme.colors.gray[2]}` }}
    >
      {allowedGlobal && (
        <ThreeWaySelectLabel
          count={hideDepartment ? 2 : 3}
          title="Globálne"
          value="global"
          setValue={(value) => onChange(value as 'global' | 'organization' | 'department')}
          active={value === 'global'}
        />
      )}
      {allowedOrganization && (
        <ThreeWaySelectLabel
          count={allowedGlobal && !hideDepartment ? 3 : 2}
          title="Organizácia"
          value="organization"
          setValue={(value) => onChange(value as 'global' | 'organization' | 'department')}
          active={value === 'organization'}
        />
      )}
      {allowedDepartment && !hideDepartment && (
        <ThreeWaySelectLabel
          count={allowedGlobal ? 3 : allowedOrganization ? 2 : 1}
          title="Stredisko"
          value="department"
          setValue={(value) => onChange(value as 'global' | 'organization' | 'department')}
          active={value === 'department'}
        />
      )}
    </Group>
  );
}
