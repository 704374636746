import { Modal } from '@mantine/core';
import DeviceFaultForm, {
  DeviceFaultFormData,
} from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultForm';
import { noop } from 'lodash';

export interface DeviceFaultModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit?: (values: DeviceFaultFormData) => void;
  initialValues?: DeviceFaultFormData;
  title?: string;
  deviceTypeId: number;
}

/**
 * Modal which displays the device fault form.
 */
export default function DeviceFaultModal({
  opened,
  onClose,
  onSubmit = noop,
  initialValues,
  title = 'Závady zariadenia',
  deviceTypeId,
}: DeviceFaultModalProps) {
  return (
    <Modal.Root
      opened={opened}
      onClose={onClose}
      size="100%"
      closeOnClickOutside={false}
      closeOnEscape={false}
      zIndex={101}
    >
      <Modal.Overlay />
      <Modal.Content h="100%">
        <Modal.Header px={40}>
          <Modal.Title>{title}</Modal.Title>
          <Modal.CloseButton sx={{ opacity: 0, cursor: 'default' }} onClick={noop} />
        </Modal.Header>
        <Modal.Body p={0} style={{ height: 'calc(100% - 68px)' }}>
          <DeviceFaultForm
            deviceTypeId={deviceTypeId}
            onSubmit={(values) => {
              onClose();
              onSubmit(values);
            }}
            initialValues={initialValues}
            onClose={onClose}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
}
