import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import RevisionTemplateForm, {
  RevisionTemplateFormData,
} from 'components/forms/revision-template/RevisionTemplateForm';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { ROLE_ADMIN_ID } from 'model/Role';
import { Navigate, useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, REVISION_TEMPLATES_PAGE_PATH, TEMPLATE_EDITOR_PAGE_PATH } from 'routes/paths';

/**
 * Allows the user to create new revision template.
 */
export default function AddRevisionTemplatePage() {
  const { roleId, getAction } = useApi();
  const navigate = useNavigate();

  /**
   * Handles the form submission.
   */
  function onSubmit({ name, slug, deviceTypeId, restriction, organizations }: RevisionTemplateFormData) {
    const revisionTemplateCreateAction = getAction('RevisionRevisionTemplateCreate');

    const isOrganization = restriction === 'organization';

    return revisionTemplateCreateAction({
      payload: {
        name,
        slug,
        deviceTypeId: Number(deviceTypeId),
        isOrganization,
        isGlobal: restriction === 'global',
        organizations: isOrganization ? organizations.map(({ organizationId }) => organizationId) : [],
      },
    })
      .then(({ revisionTemplateId }) => {
        notifications.show({
          title: 'Šablóna revíznej správy bola úspešne vytvorená',
          message: `Šablóna revíznej správy ${name} bola úspešne vytvorená.`,
        });

        if (FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR) {
          navigate(TEMPLATE_EDITOR_PAGE_PATH.insert({ revisionTemplateId }));
        } else {
          navigate(REVISION_TEMPLATES_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  if (roleId !== ROLE_ADMIN_ID) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout
      title="Pridanie novej šablóny revíznej správy"
      breadcrumbs={[
        { title: 'Šablóny revíznych správ', link: REVISION_TEMPLATES_PAGE_PATH.original },
        { title: 'Pridanie novej šablóny revíznej správy' },
      ]}
    >
      <RevisionTemplateForm
        onSubmit={onSubmit}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
