import { useDisclosure } from '@mantine/hooks';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import AutomationConfigurator, {
  AutomationConfiguratorProps,
} from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationConfigurator';

/**
 * Parameters of the automation configurator.
 */
export type IConfigureAutomationParams = Omit<AutomationConfiguratorProps, 'opened' | 'onClose'>;

/**
 * The ConfigureAutomation function.
 */
type ConfigureAutomation = (params: IConfigureAutomationParams) => void;

/**
 * Context for the automation configurator.
 */
const AutomationConfiguratorContext = createContext<{ configureAutomation: ConfigureAutomation }>(undefined!);

/**
 * Provides the automation configurator to the pipeline editor.
 */
export default function AutomationConfiguratorProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure(false);

  const [params, setParams] = useState<IConfigureAutomationParams>({
    onSubmit: noop,
  });

  const configureAutomation: ConfigureAutomation = useCallback(
    (params: IConfigureAutomationParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const value = useMemo(() => ({ configureAutomation }), [configureAutomation]);

  return (
    <AutomationConfiguratorContext.Provider value={value}>
      {children}
      <AutomationConfigurator opened={opened} onClose={close} {...params} />
    </AutomationConfiguratorContext.Provider>
  );
}

/**
 * Uses the automation configurator.
 */
export function useAutomationConfigurator() {
  return useContext(AutomationConfiguratorContext);
}
