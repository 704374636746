import { MultiSelect, MultiSelectProps, Select, SelectProps } from '@mantine/core';
import useImmediateAction from 'api/use-immediate-action';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * The base interface for the DataSelectProps type.
 */
interface IDataSelectProps<TRow> {
  action: () => Promise<TRow[]>;
  onReady?: (data: TRow[]) => void;
  valueProp: keyof TRow;
  labelProp: keyof TRow;
  imageProp?: keyof TRow;
  groupProp?: keyof TRow;
  loadingPlaceholder?: string;
  lazyLoad?: boolean;
}

/**
 * The parameters for the DataSelect component.
 */
type DataSelectProps<TRow> = IDataSelectProps<TRow> &
  Omit<SelectProps, 'data' | 'multiple'> & {
    autoSelectSingleResult?: boolean;
  };

/**
 * The parameters for the DataMultiSelect component.
 */
type DataMultiSelectProps<TRow> = IDataSelectProps<TRow> & Omit<MultiSelectProps, 'data' | 'multiple'>;

/**
 * The inherited parameters for the DataSelect component.
 */
export type DataSelectInheritedProps = Omit<
  DataSelectProps<unknown>,
  'action' | 'valueProp' | 'labelProp' | 'imageProp' | 'groupProp'
>;

/**
 * The inherited parameters for the DataMultiSelect component.
 */
export type DataMultiSelectInheritedProps = Omit<
  DataMultiSelectProps<unknown>,
  'action' | 'valueProp' | 'labelProp' | 'imageProp' | 'groupProp'
>;

/**
 * Computes value and label pairs from the data.
 *
 * The value should always be string, otherwise the Select will behave unexpectedly
 */
function computePairs<TRow>(
  data: TRow[],
  valueProp: keyof TRow,
  labelProp: keyof TRow,
  imageProp?: keyof TRow,
  groupProp?: keyof TRow
) {
  return data.map((item: TRow) => {
    const res: { value: string; label: string; image?: string; group?: string } = {
      value: String(item[valueProp]),
      label: String(item[labelProp]),
    };

    if (imageProp) {
      res.image = String(item[imageProp]);
    }

    if (groupProp) {
      res.group = String(item[groupProp]);
    }

    return res;
  });
}

/**
 * Used to fetch objects from the database and display them in a select.
 */
export function DataSelect<TRow>({
  action,
  onReady = noop,
  valueProp,
  labelProp,
  placeholder,
  imageProp = undefined,
  groupProp = undefined,
  loadingPlaceholder = 'Načítavanie ...',
  autoSelectSingleResult = false,
  lazyLoad = false,
  value,
  onChange,
  ...rest
}: DataSelectProps<TRow>) {
  const fetchDataOnMount = !!value || !lazyLoad;

  const [data, setData] = useState<TRow[] | null>(null);
  const [loading, setLoading] = useState(fetchDataOnMount);

  const valueLabelPairs = useMemo(() => {
    if (loading && !autoSelectSingleResult) {
      return [{ value: nanoid(), label: 'Načítavanie ...' }];
    }

    return computePairs(data ?? [], valueProp, labelProp, imageProp, groupProp);
  }, [data, loading, valueProp, labelProp, imageProp, groupProp]);

  /**
   * Fetches the data from the server.
   */
  const fetchData = useCallback(() => {
    action()
      .then((data) => {
        setData(data);
        onReady(data);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, [action, setData, setLoading]);

  /**
   * Fetches the data from the server if lazyLoad is true and the data is not
   * loaded yet.
   */
  const maybeLazyLoad = useCallback(() => {
    if (lazyLoad && !loading && data === null) {
      setLoading(true);
      fetchData();
    }
  }, [lazyLoad, loading, data, fetchData]);

  // Apply autoSelectSingleResult when the data is loaded.
  useEffect(() => {
    if (autoSelectSingleResult && valueLabelPairs.length === 1 && !value) {
      onChange?.(valueLabelPairs[0].value);
    }
  }, [autoSelectSingleResult, value, valueLabelPairs]);

  // Apply auto select in individual items.
  useEffect(() => {
    if (!value) {
      const autoSelect = data?.find((item) => (item as any).autoSelect);

      if (autoSelect) {
        onChange?.(String((autoSelect as any)[valueProp]));
      }
    }
  }, [data]);

  // Fetch data when the component is mounted if lazyLoad is false.
  useEffect(() => {
    if (fetchDataOnMount) {
      fetchData();
    }
  }, []);

  return (
    <Select
      {...rest}
      value={value}
      onChange={onChange}
      placeholder={loading && !lazyLoad ? loadingPlaceholder : placeholder}
      data={valueLabelPairs}
      searchable
      nothingFound="Žiadne výsledky"
      onDropdownOpen={maybeLazyLoad}
    />
  );
}

/**
 * Used to fetch objects from the database and display them in a select (multiple).
 */
export function DataMultiSelect<TRow>({
  action,
  valueProp,
  labelProp,
  placeholder,
  imageProp = undefined,
  groupProp = undefined,
  loadingPlaceholder = 'Načítavanie ...',
  ...rest
}: DataMultiSelectProps<TRow>) {
  const { data, loading, error } = useImmediateAction(action);
  const valueLabelPairs = useMemo(
    () => computePairs(data ?? [], valueProp, labelProp, imageProp, groupProp),
    [data, valueProp, labelProp, imageProp, groupProp]
  );

  if (error) {
    panic(error);
    return <></>;
  }

  return (
    <MultiSelect
      {...rest}
      placeholder={loading ? loadingPlaceholder : placeholder}
      data={valueLabelPairs}
      searchable
      nothingFound="Žiadne výsledky"
    />
  );
}
