import { Tooltip } from '@mantine/core';
import { useRichTextEditorContext, RichTextEditor } from '@mantine/tiptap';
import { IconItalic } from '@tabler/icons-react';

/**
 * Used to make text italic.
 */
export default function ControlItalic() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Italic" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().toggleItalic().run()}
        active={editor?.isActive('italic')}
      >
        <IconItalic size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
