import { IconList } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import BulletList from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/list/BulletList';
import OrderedList from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/list/OrderedList';

/**
 * Control used to choose the list style of the selected text.
 */
export default function List({ kind }: { kind: DropdownControlKind }) {
  return (
    <DropdownControl label="Odrážky a číslovanie" kind={kind} icon={<IconList />}>
      <BulletList kind="menu-item" />
      <OrderedList kind="menu-item" />
    </DropdownControl>
  );
}
