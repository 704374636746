import { ColDef } from 'ag-grid-community';
import DataTable from 'components/tables/DataTable';
import { useApi } from 'api/api-context';
import { useCallback, useMemo, useState } from 'react';
import { RevisionListTemplatesAndVisualsResponse } from 'api/actions/revision-list-templates-and-visuals/revision-list-templates-and-visuals-response';
import RevisionTemplateIdColumn from 'components/tables/revision-template/columns/RevisionTemplateIdColumn';
import RevisionTemplateNameColumn from 'components/tables/revision-template/columns/RevisionTemplateNameColumn';
import RestrictionsColumn from 'components/tables/revision-template/columns/RestrictionsColumn';
import ActionsColumn from 'components/tables/revision-template/columns/ActionsColumn';
import TwoRestrictionsSelectFilter from 'components/tables/filters/restriction/TwoRestrictionsSelectFilter';
import DeviceTypeSelectFilter from 'components/tables/filters/DeviceTypeSelectFilter';
import DeviceTypeNameColumn from 'components/tables/revision-template/columns/DeviceTypeNameColumn';
import { useSearchParams } from 'react-router-dom';
import { RevisionTemplateRow } from 'components/tables/revision-template/types';
import { ADD_REVISION_TEMPLATE_PAGE_PATH } from 'routes/paths';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR } from 'env';

const columns: ColDef[] = [
  {
    field: 'checkbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressMovable: true,
    width: 44,
    minWidth: 44,
    maxWidth: 44,
  },
  {
    field: 'revisionTemplateId',
    headerName: 'ID',
    sortable: true,
    filter: true,
    unSortIcon: true,
    suppressMovable: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellRenderer: RevisionTemplateIdColumn,
  },
  {
    field: 'name',
    headerName: 'Názov',
    minWidth: 350,
    sortable: true,
    resizable: true,
    filter: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: RevisionTemplateNameColumn,
  },
  {
    field: 'deviceType.deviceTypeId',
    headerName: 'ID zariadenia',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'deviceType.deviceTypeName',
    headerName: 'Zariadenie',
    minWidth: 200,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: DeviceTypeSelectFilter,
    cellRenderer: DeviceTypeNameColumn,
  },
  {
    field: 'restriction',
    headerName: 'Vymedzenia',
    minWidth: 550,
    sortable: true,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: TwoRestrictionsSelectFilter,
    cellRenderer: RestrictionsColumn,
  },
  {
    field: 'restrictionPretty',
    headerName: 'Vymedzenia',
    hide: true,
  },
  {
    field: 'restrictedOrganizations',
    headerName: 'Organizácie',
    hide: true,
  },
  {
    valueGetter: ({ data: { status } }: { data: RevisionTemplateRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívna',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'actions',
    headerName: '',
    pinned: 'right',
    width: 230,
    minWidth: 230,
    maxWidth: 230,
    cellRenderer: ActionsColumn,
  },
];

/**
 * Parameters of the RevisionTemplatesTable component.
 */
export interface RevisionTemplatesTableProps {
  search?: string;
  showDiscarded?: boolean;
}

/**
 * Returns the restriction for the revision template.
 */
function getRevisionTemplateRestriction(template: RevisionListTemplatesAndVisualsResponse[number]): string {
  if (template.isGlobal) {
    return 'global';
  }

  if (template.isOrganization) {
    return 'organization';
  }

  return 'unknown';
}

/**
 * Returns the restriction for the revision template in a pretty format.
 */
function getPrettyRevisionTemplateRestriction(template: RevisionListTemplatesAndVisualsResponse[number]): string {
  if (template.isGlobal) {
    return 'Globálne';
  }

  if (template.isOrganization) {
    return 'Organizácia';
  }

  return 'Neznáme';
}

/**
 * Table which lists all revision templates.
 */
export default function RevisionTemplatesTable({
  search = '',
  showDiscarded = false,
}: RevisionTemplatesTableProps = {}) {
  const { getAction } = useApi();
  const [searchParams] = useSearchParams();

  const [initialFilters] = useState(() => ({
    'deviceType.deviceTypeId': {
      filterType: 'text',
      type: 'equals',
      filter: searchParams.get('deviceTypeId') ?? '',
    },
  }));

  const action = useCallback(async () => {
    const action = getAction('RevisionListTemplatesAndVisuals');

    const templates = await action({});

    return templates.map((template) => ({
      ...template,
      restriction: getRevisionTemplateRestriction(template),
      restrictionPretty: getPrettyRevisionTemplateRestriction(template),
      restrictedOrganizations: template.organizations
        ? template.organizations
            .map(({ organizationId, organizationName }) => `ID ${organizationId}: ${organizationName}`)
            .join(', ')
        : '-',
    }));
  }, [getAction]);

  const additionalFilters = useMemo(() => {
    if (!showDiscarded) {
      return {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      };
    }

    return { status: null };
  }, [showDiscarded]);

  return (
    <DataTable
      columns={columns}
      action={action}
      search={search}
      initialFilters={initialFilters}
      additionalFilters={additionalFilters}
      addButtonText={FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR ? 'Pridať šablónu' : undefined}
      addButtonTarget={FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR ? ADD_REVISION_TEMPLATE_PAGE_PATH.original : undefined}
      dataExport={{
        modalTitle: 'Exportovať šablóny revíznych správ',
        fileName: 'sablony-reviznych-sprav.xlsx',
        columnKeys: [
          'revisionTemplateId',
          'name',
          'restrictionPretty',
          'restrictedOrganizations',
          'deviceType.deviceTypeId',
          'deviceType.deviceTypeName',
          'status',
        ],
      }}
    />
  );
}
