import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionApprovePathParameters } from 'api/actions/revision-approve/revision-approve-path-parameters';
import { RevisionApproveResponse } from 'api/actions/revision-approve/revision-approve-response';

type RevisionApproveQueryParameters = Record<string, any>;

/**
 * The path of the RevisionApprove action.
 */
export const revisionApproveActionPath = new SweetPath<keyof RevisionApprovePathParameters>(
  '/api/revision/approve/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionApprove actions.
 */
export default function createRevisionApproveAction(axios: AxiosInstance) {
  return function revisionApproveAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionApprovePathParameters;
    query?: RevisionApproveQueryParameters;
  }) {
    const path = revisionApproveActionPath.insert(parameters);

    return axios.post<RevisionApproveResponse, RevisionApproveResponse>(path, { params: query, headers });
  };
}
