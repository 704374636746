import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthListPermissionTemplatesPathParameters } from 'api/actions/auth-list-permission-templates/auth-list-permission-templates-path-parameters';
import { AuthListPermissionTemplatesResponse } from 'api/actions/auth-list-permission-templates/auth-list-permission-templates-response';

type AuthListPermissionTemplatesQueryParameters = Record<string, any>;

/**
 * The path of the AuthListPermissionTemplates action.
 */
export const authListPermissionTemplatesActionPath = new SweetPath<keyof AuthListPermissionTemplatesPathParameters>(
  '/api/auth/permission-template/list',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthListPermissionTemplates actions.
 */
export default function createAuthListPermissionTemplatesAction(axios: AxiosInstance) {
  return function authListPermissionTemplatesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthListPermissionTemplatesQueryParameters } = {}) {
    const path = authListPermissionTemplatesActionPath.original;

    return axios.get<AuthListPermissionTemplatesResponse, AuthListPermissionTemplatesResponse>(path, {
      params: query,
      headers,
    });
  };
}
