import { useForm } from '@mantine/form';
import { noop } from 'lodash';
import { Box, Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { IconAlertTriangle } from '@tabler/icons-react';
import FormFooter from 'components/forms/FormFooter';
import { DeviceFaultFormRow } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultFormRow';
import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';

/**
 * The row data for the device fault form.
 */
export interface DeviceFault {
  id: string;
  fault: string;
  severity: string;
  description: string;
  moreInfo?: string;
}

/**
 * The data collected from the form.
 */
export interface DeviceFaultFormData {
  faults: DeviceFault[];
}

/**
 * Parameters of the DeviceFaultForm component.
 */
export interface DeviceFaultFormProps {
  deviceTypeId: number;
  initialValues?: DeviceFaultFormData;
  onSubmit?: (values: DeviceFaultFormData) => void;
  onClose?: () => void;
}

/**
 * Form for collecting device fault data.
 */
export default function DeviceFaultForm({
  deviceTypeId,
  initialValues = { faults: [] },
  onSubmit = noop,
  onClose = noop,
}: DeviceFaultFormProps) {
  const theme = useMantineTheme();

  const form = useForm({
    initialValues,
    validate: {
      faults: {
        fault: createValidator([required]),
        severity: createValidator([required]),
      },
    },
  });

  /**
   * Adds a new fault entry to the form rows.
   */
  const addFault = useCallback(() => {
    form.insertListItem('faults', {
      id: nanoid(),
      fault: '',
      severity: '',
      description: '',
      moreInfo: '',
    });
  }, [form]);

  /**
   * Removes a fault entry from the form rows.
   */
  const removeFault = useCallback((i: number) => form.removeListItem('faults', i), [form]);

  return (
    <form onSubmit={form.onSubmit(onSubmit)} style={{ height: '100%' }}>
      <Flex direction="column" justify="space-between" h="100%">
        <Box px={40} pb={40}>
          <Flex bg="gray.0" style={{ borderTopLeftRadius: 4, borderTopRightRadius: 4 }} w="100%" align="flex-start">
            <Flex gap={24} py={24} px={16} align="flex-start" style={{ flex: '1 0 0' }}>
              <Flex gap={8} style={{ flex: '1 1 0' }}>
                <Text size="md" weight={500} color={theme.colors.gray[8]}>
                  Závada
                </Text>
              </Flex>
              <Flex gap={8} style={{ flex: '1 1 0' }}>
                <Text size="md" weight={500} color={theme.colors.gray[8]}>
                  Závažnosť
                </Text>
              </Flex>
              <Flex gap={8} style={{ flex: '1 1 0' }}>
                <Text size="md" weight={500} color={theme.colors.gray[8]}>
                  Popis
                </Text>
              </Flex>
            </Flex>

            <Box w={72} h={72} style={{ flexShrink: 0 }} />
          </Flex>

          {form.values.faults.map(({ id }, index) => (
            <DeviceFaultFormRow key={id} form={form} index={index} deviceTypeId={deviceTypeId} onDelete={removeFault} />
          ))}

          <Button variant="subtle" leftIcon={<IconAlertTriangle />} mt={24} onClick={addFault}>
            Pridať závadu
          </Button>
        </Box>

        <FormFooter
          onSecondaryClick={onClose}
          closeConfirmTitle="Zrušiť zmeny v závadách"
          closeConfirmMessage="Naozaj chcete zrušiť zmeny v závadách? Všetky neuložené zmeny budú stratené."
        />
      </Flex>
    </form>
  );
}
