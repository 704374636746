import { RevisionSingleActionProps } from 'components/tables/revision/types';
import useApproveAction from 'components/tables/revision/actions/hooks/use-approve-action';
import useConfirmAction from 'components/tables/revision/actions/hooks/use-confirm-action';
import { noop } from 'lodash';
import { Button, Menu } from '@mantine/core';
import { IconRubberStamp } from '@tabler/icons-react';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the user who has the permission to approve and accept the revision.
 */
export default function ApproveAndConfirmAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { approveRevision } = useApproveAction();
  const { confirmRevision } = useConfirmAction();
  const { confirm } = useConfirm();

  /**
   * Confirms the approval and confirmation of the revision.
   */
  const confirmApproveAndConfirmRevision = () =>
    confirm({
      title: 'Schválenie a prebratie revíznej správy',
      content: 'Naozaj si prajete schváliť a prebrať túto revíznu správu?',
      onConfirm: async () => {
        await approveRevision({ revision, onStatusChange });
        await confirmRevision({ revision, onStatusChange });
      },
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={confirmApproveAndConfirmRevision}
        leftIcon={<IconRubberStamp stroke={1.5} size={24} />}
        variant="primary"
        w={180}
      >
        Schváliť a prebrať
      </Button>
    );
  }

  return (
    <Menu.Item
      color="gray.8"
      onClick={confirmApproveAndConfirmRevision}
      icon={<IconRubberStamp stroke={1.5} size={24} />}
    >
      Schváliť a prebrať
    </Menu.Item>
  );
}
