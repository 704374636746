import { createContext, useCallback, useContext, useMemo } from 'react';
import { IFormRow } from 'pages/revisions-module/template-editor/editors/form/types';
import { nanoid } from 'nanoid';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';

interface IFormRowsDataContext {
  mutateSectionRows: (sectionId: string, modify: (rows: IFormRow[]) => IFormRow[]) => void;
  addRow: (sectionId: string) => string;
  removeRow: (sectionId: string, rowId: string) => void;
  moveRow: (sectionId: string, prevIndex: number, nextIndex: number) => void;
  moveInputToNewRow: (sectionId: string, rowId: string, inputId: string) => void;
  moveInputToExistingRow: (sectionId: string, inputId: string, targetRowId: string) => void;
}

const FormRowsDataContext = createContext<IFormRowsDataContext>(undefined!);

/**
 * Provides the form rows data to the form editor.
 */
export function FormRowsDataProvider({ children }: { children: React.ReactNode }) {
  const { mutateSections } = useFormSections();

  const mutateSectionRows = useCallback((sectionId: string, modify: (rows: IFormRow[]) => IFormRow[]) => {
    mutateSections((prev) =>
      prev.map((section) => (section.id === sectionId ? { ...section, rows: modify(section.rows) } : section))
    );
  }, []);

  const addRow = useCallback((sectionId: string) => {
    const row = { id: nanoid(), inputs: [] };
    mutateSectionRows(sectionId, (rows) => [...rows, row]);
    return row.id;
  }, []);

  const removeRow = useCallback((sectionId: string, rowId: string) => {
    mutateSectionRows(sectionId, (rows) => rows.filter((row) => row.id !== rowId));
  }, []);

  const moveRow = useCallback((sectionId: string, prevIndex: number, nextIndex: number) => {
    mutateSectionRows(sectionId, (rows) => {
      const newRows = [...rows];
      const [removedRow] = newRows.splice(prevIndex, 1);
      newRows.splice(nextIndex, 0, removedRow);
      return newRows;
    });
  }, []);

  const moveInputToNewRow = useCallback((sectionId: string, rowId: string, inputId: string) => {
    mutateSectionRows(sectionId, (rows) => {
      const row = rows.find((row) => row.id === rowId);
      const input = row?.inputs.find((input) => input.id === inputId);

      if (!row || !input) {
        return rows;
      }

      return rows
        .map((row) => (row.id === rowId ? { ...row, inputs: row.inputs.filter((input) => input.id !== inputId) } : row))
        .concat({ id: nanoid(), inputs: [input] });
    });
  }, []);

  const moveInputToExistingRow = useCallback((sectionId: string, inputId: string, targetRowId: string) => {
    mutateSectionRows(sectionId, (rows) => {
      const row = rows.find((row) => row.inputs.some((input) => input.id === inputId));
      const targetRow = rows.find((row) => row.id === targetRowId);
      const input = row?.inputs.find((input) => input.id === inputId);

      if (!row || !targetRow || !input || row === targetRow) {
        return rows;
      }

      const rowId = row.id;

      return rows.map((row) =>
        row.id === rowId
          ? { ...row, inputs: row.inputs.filter((input) => input.id !== inputId) }
          : row.id === targetRowId
          ? { ...targetRow, inputs: [...targetRow.inputs, input] }
          : row
      );
    });
  }, []);

  const value = useMemo(
    () => ({ mutateSectionRows, addRow, removeRow, moveRow, moveInputToNewRow, moveInputToExistingRow }),
    [mutateSectionRows, addRow, removeRow, moveRow, moveInputToNewRow, moveInputToExistingRow]
  );

  return <FormRowsDataContext.Provider value={value}>{children}</FormRowsDataContext.Provider>;
}

/**
 * Uses the form rows data.
 */
export function useFormRows() {
  return useContext(FormRowsDataContext);
}
