import { Link } from 'react-router-dom';

/**
 * Parameters of the Breadcrumb component.
 */
export interface BreadcrumbProps {
  title: string;
  link?: string;
}

/**
 * Displays a single breadcrumb.
 */
export function Breadcrumb({ title, link }: BreadcrumbProps) {
  if (link) {
    return <Link to={link}>{title}</Link>;
  }

  return <span>{title}</span>;
}
