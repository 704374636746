import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionGetPathParameters } from 'api/actions/revision-get/revision-get-path-parameters';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';

type RevisionGetQueryParameters = Record<string, any>;

/**
 * The path of the RevisionGet action.
 */
export const revisionGetActionPath = new SweetPath<keyof RevisionGetPathParameters>('/api/revision/get/{revisionId}', {
  path: '{param}',
});

/**
 * Creates a contextualized RevisionGet actions.
 */
export default function createRevisionGetAction(axios: AxiosInstance) {
  return function revisionGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionGetPathParameters;
    query?: RevisionGetQueryParameters;
  }) {
    const path = revisionGetActionPath.insert(parameters);

    return axios.get<RevisionGetResponse, RevisionGetResponse>(path, { params: query, headers });
  };
}
