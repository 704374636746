import { notifications } from '@mantine/notifications';
import { RevisionGetRevisionTemplateResponse } from 'api/actions/revision-get-revision-template/revision-get-revision-template-response';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import RevisionTemplateForm, {
  RevisionTemplateFormData,
} from 'components/forms/revision-template/RevisionTemplateForm';
import { REVISION_TEMPLATE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { ROLE_ADMIN_ID } from 'model/Role';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, REVISION_TEMPLATES_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Determines the restriction type of the revision template.
 */
function getRevisionTemplateRestriction(data: RevisionGetRevisionTemplateResponse) {
  if (data.isOrganization) {
    return 'organization';
  }

  return 'global';
}

/**
 * Page used to edit a revision template.
 */
export default function EditRevisionTemplatePage() {
  const { roleId, getAction } = useApi();
  const navigate = useNavigate();

  const { revisionTemplateId } = useParams();

  const { data, loading, error } = useImmediateAction(() => {
    const action = getAction('RevisionGetRevisionTemplate');

    return action({
      parameters: {
        revisionTemplateId: String(revisionTemplateId),
      },
    });
  });

  if (roleId !== ROLE_ADMIN_ID || !revisionTemplateId) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  if (error) {
    panic(error);
    return <></>;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({ name, restriction, organizations }: RevisionTemplateFormData) {
    const revisionTemplateUpdateAction = getAction('RevisionRevisionTemplateUpdate');

    const isOrganization = restriction === 'organization';

    return revisionTemplateUpdateAction({
      parameters: {
        revisionTemplateId: String(revisionTemplateId),
      },
      payload: {
        name,
        isGlobal: restriction === 'global',
        isOrganization,
        organizations: isOrganization ? organizations.map(({ organizationId }) => organizationId) : [],
      },
    })
      .then(() => {
        notifications.show({
          title: 'Šablóna revíznej správy bola úspešne upravená',
          message: `Šablóna revíznej správy ${name} bola úspešne upravená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (REVISION_TEMPLATE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(REVISION_TEMPLATES_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title={`Úprava šablóny revíznej správy: ${data?.name}`}
      breadcrumbs={[
        { title: 'Šablóny revíznych správ', link: REVISION_TEMPLATES_PAGE_PATH.original },
        { title: data?.name ?? 'Načítavanie ...' },
      ]}
    >
      {!loading && (
        <RevisionTemplateForm
          initialValues={{
            name: data.name,
            restriction: getRevisionTemplateRestriction(data),
            organizations: data.organizations ?? [],
            slug: data.slug,
            deviceTypeId: String(data.deviceTypeId),
          }}
          onSubmit={onSubmit}
          readOnly={{ deviceTypeId: true }}
        />
      )}
    </DashboardLayout>
  );
}
