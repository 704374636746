import { validate } from 'email-validator';
import { ValidationError } from 'components/forms/validators/validation-error';

/**
 * Validates the email format.
 */
export default function emailFormat(value: string): ValidationError | null {
  if (!validate(value)) {
    return {
      message: 'Emailová adresa je v nesprávnom formáte',
    };
  }

  return null;
}
