import { Button } from '@mantine/core';
import { IconSubtask } from '@tabler/icons-react';
import { RevisionDevice } from 'components/forms/revision/fill-out/types';
import { IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';
import { useMemo } from 'react';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useDeviceMeasurement } from 'components/forms/revision/fill-out/modals/device-measurement-modal/DeviceMeasurementProvider';

interface DeviceMeasurementButtonProps {
  device: RevisionDevice;
  measurement: IFormSection;
}

/**
 * A button which opens a dialog for specifying device measurements.
 */
export default function DeviceMeasurementButton({
  device: { deviceId, measurements: measurementMap },
  measurement: measurementSection,
}: DeviceMeasurementButtonProps) {
  const { revision, form } = useFillOutRevisionDataProvider();
  const { specifyMeasurements } = useDeviceMeasurement();

  const measurements = useMemo(() => measurementMap[measurementSection.id] ?? [], [measurementMap, measurementSection]);

  const spec = useMemo(
    () => measurementSection.rows.map(({ inputs }) => inputs.map(({ spec }) => spec).flat()).flat() as any[],
    [measurementSection]
  );

  /** Opens the measurements modal. */
  const openMeasurementsModal = () =>
    specifyMeasurements({
      measurementType: measurementSection.name,
      deviceTypeId: revision.deviceType.deviceTypeId,
      spec,
      initialValues: { measurements },
      onSubmit: ({ measurements }) =>
        form.setFieldValue(`devices.${deviceId}.measurements.${measurementSection.id}`, measurements),
    });

  return (
    <Button size="md" variant="subtle" leftIcon={<IconSubtask stroke="1.5" />} onClick={openMeasurementsModal}>
      {measurementSection.name} {measurements.length > 0 && `(${measurements.length})`}
    </Button>
  );
}
