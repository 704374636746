import { Box, Tabs } from '@mantine/core';
import FormTab from 'pages/revisions-module/template-editor/editors/form/FormTab';
import DevicesTab from 'pages/revisions-module/template-editor/editors/form/DevicesTab';
import { InputConfiguratorProvider } from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';

/**
 * The form editor part of the template editor page.
 */
export default function FormEditor() {
  return (
    <InputConfiguratorProvider>
      <Tabs defaultValue="info" keepMounted={false}>
        <Tabs.List mx={-24} px={24} mt={-24} pt={8} pos="sticky" top={0} bg="white" style={{ zIndex: 10 }}>
          <Tabs.Tab value="info">Základné informácie</Tabs.Tab>
          <Tabs.Tab value="description">Popis</Tabs.Tab>
          <Tabs.Tab value="specification">Špecifikácia</Tabs.Tab>
          <Tabs.Tab value="devices">Zariadenia</Tabs.Tab>
          <Tabs.Tab value="conclusion">Záver</Tabs.Tab>
        </Tabs.List>
        <Box pt={40}>
          <Tabs.Panel value="info">
            <FormTab slug="info" />
          </Tabs.Panel>
          <Tabs.Panel value="description">
            <FormTab slug="description" />
          </Tabs.Panel>
          <Tabs.Panel value="specification">
            <FormTab slug="specification" />
          </Tabs.Panel>
          <Tabs.Panel value="devices">
            <DevicesTab />
          </Tabs.Panel>
          <Tabs.Panel value="conclusion">
            <FormTab slug="conclusion" />
          </Tabs.Panel>
        </Box>
      </Tabs>
    </InputConfiguratorProvider>
  );
}
