import { Box, Group } from '@mantine/core';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import P2Regular from 'components/typography/P2Regular';
import { RevisionRow } from 'components/tables/revision/types';

/**
 * Column for the device type name.
 */
export default function DeviceTypeNameColumn({ data: { deviceType } }: { data: RevisionRow }) {
  return (
    <Group spacing={8} noWrap align="center">
      <Box w={24} h={24}>
        <DeviceTypeIcon typeId={deviceType.deviceTypeId} />
      </Box>
      <P2Regular color="gray.8">{deviceType.deviceTypeName}</P2Regular>
    </Group>
  );
}
