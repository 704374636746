import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import PreDefinedDataForm, { PreDefinedDataFormData } from 'components/forms/pre-defined-data/PreDefinedDataForm';
import { PREDEFINED_DATA_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { SETTINGS_PAGE_PATH, USERS_PREDEFINED_DATA_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit user's pre-defined data.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A11731 Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-Edit-User-s-Pre-defined-Data-673c73b25ee046c484604a4aa7760cf3?pvs=4 Notion Page}
 */
export default function EditUsersPredefinedDataPage() {
  const navigate = useNavigate();
  const { predefinedDataId } = useParams();
  const { getAction } = useApi();

  const { data, loading, error } = useImmediateAction(() => {
    const preDefinedDataGetAction = getAction('PredefinedDataGetUsers');
    return preDefinedDataGetAction({ parameters: { usersPredefinedDataId: predefinedDataId! } });
  });

  if (error) {
    panic(error);
    return <></>;
  }

  if (!predefinedDataId) {
    return <Navigate to={USERS_PREDEFINED_DATA_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({ predefinedDataName, predefinedDataDescription, predefinedDataTypeId }: PreDefinedDataFormData) {
    const preDefinedDataUpdateAction = getAction('PredefinedDataUpdateUsers');

    return preDefinedDataUpdateAction({
      payload: {
        predefinedDataName,
        predefinedDataDescription: predefinedDataDescription ?? undefined,
        predefinedDataTypeId: Number(predefinedDataTypeId),
      },
      parameters: {
        usersPredefinedDataId: predefinedDataId!,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Predvolený údaj bol upravený',
          message: `Predvolený údaj ${predefinedDataName} bol úspešne upravený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (PREDEFINED_DATA_REDIRECT_AFTER_SAVE === 'list') {
          navigate(USERS_PREDEFINED_DATA_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Úprava predvoleného údaju"
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        { title: 'Predvolené údaje', link: USERS_PREDEFINED_DATA_PAGE_PATH.original },
        { title: 'Úprava predvoleného údaju' },
      ]}
    >
      {!loading && (
        <PreDefinedDataForm
          initialValues={{
            predefinedDataName: data?.key ?? '',
            deviceTypeId: String(data?.deviceType.deviceTypeId) ?? '',
            predefinedDataDescription: data?.value ?? '',
            predefinedDataTypeId: String(data?.predefinedDataType.predefinedDataTypeId) ?? '',
            isGlobal: false,
            isPersonal: true,
          }}
          onSubmit={onSubmit}
          readOnly={{
            predefinedDataTypeId: true,
            deviceTypeId: true,
          }}
        />
      )}
    </DashboardLayout>
  );
}
