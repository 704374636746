import { Box, Button, Group } from '@mantine/core';
import { IconCheck, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import ButtonWithConfirm from 'components/modals/confirm/ButtonWithConfirm';

export interface RevisionFormFooterProps {
  loading: boolean;
  isFirstStep: boolean;
  isLastStep: boolean;
  onPrevStep: () => void;
  onNextStep: () => void;
  onSave: () => void;
  onSubmit: () => void;
}

/**
 * The revision form footer. Allows to submit or cancel the form.
 */
export default function RevisionFormFooter({
  loading,
  isFirstStep,
  isLastStep,
  onPrevStep,
  onNextStep,
  onSave,
  onSubmit,
}: RevisionFormFooterProps) {
  return (
    <Box
      h={72}
      w="100%"
      py={16}
      px={40}
      sx={() => ({
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        boxShadow: '0px -4px 12px 0px rgba(197, 198, 211, 0.40)',
      })}
    >
      <Group position="apart">
        <Button
          variant="secondary"
          leftIcon={<IconCheck stroke="1.5" height={24} width={24} />}
          size="md"
          loading={loading}
          onClick={onSave}
        >
          Uložiť
        </Button>
        <Group spacing={16}>
          <Button
            variant="subtle-gray"
            leftIcon={<IconChevronLeft stroke="1.5" height={24} width={24} />}
            size="md"
            loading={loading}
            loaderPosition="center"
            loaderProps={{ size: 0 }}
            disabled={isFirstStep}
            onClick={onPrevStep}
          >
            Späť
          </Button>
          {isLastStep ? (
            <ButtonWithConfirm
              type="button"
              variant="primary"
              rightIcon={<IconChevronRight stroke="1.5" height={24} width={24} />}
              size="md"
              loading={loading}
              loaderPosition="center"
              confirmTitle="Naozaj chcete odovzdať revíznu správu?"
              confirmMessage="Po odovzdaní revíznej správy už nebude možné ju upravovať."
              onConfirm={onSubmit}
              loaderProps={{ size: 0 }}
            >
              Ukončiť a odovzdať
            </ButtonWithConfirm>
          ) : (
            <Button
              variant="subtle"
              rightIcon={<IconChevronRight stroke="1.5" height={24} width={24} />}
              size="md"
              loading={loading}
              loaderPosition="center"
              loaderProps={{ size: 0 }}
              onClick={onNextStep}
            >
              Pokračovať ďalej
            </Button>
          )}
        </Group>
      </Group>
    </Box>
  );
}
