import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionRevisionTemplateSetStatusPayload } from 'api/actions/revision-revision-template-set-status/revision-revision-template-set-status-payload';
import { RevisionRevisionTemplateSetStatusPathParameters } from 'api/actions/revision-revision-template-set-status/revision-revision-template-set-status-path-parameters';
import { RevisionRevisionTemplateSetStatusResponse } from 'api/actions/revision-revision-template-set-status/revision-revision-template-set-status-response';

type RevisionRevisionTemplateSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the RevisionRevisionTemplateSetStatus action.
 */
export const revisionRevisionTemplateSetStatusActionPath = new SweetPath<
  keyof RevisionRevisionTemplateSetStatusPathParameters
>('/api/revision/revision-template/status/{revisionTemplateId}', { path: '{param}' });

/**
 * Creates a contextualized RevisionRevisionTemplateSetStatus actions.
 */
export default function createRevisionRevisionTemplateSetStatusAction(axios: AxiosInstance) {
  return function revisionRevisionTemplateSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionRevisionTemplateSetStatusPathParameters;
    query?: RevisionRevisionTemplateSetStatusQueryParameters;
    payload: RevisionRevisionTemplateSetStatusPayload;
  }) {
    const path = revisionRevisionTemplateSetStatusActionPath.insert(parameters);

    return axios.put<
      RevisionRevisionTemplateSetStatusResponse,
      RevisionRevisionTemplateSetStatusResponse,
      RevisionRevisionTemplateSetStatusPayload
    >(path, payload);
  };
}
