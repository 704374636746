import { IPipeline } from 'pages/revisions-module/template-editor/editors/pipeline/types';

export const DEFAULT_PIPELINE: IPipeline = {
  automations: [
    {
      id: 'zaver-reviznej-spravy',
      statusSource: 7, // Schválená - čakajúca na prebratie
      statusTarget: 8, // Prebratá
      type: 'set-revision-safety-point',
      inputName: 'zaver-reviznej-spravy',
    },
    {
      id: 'datum-ukoncenia',
      statusSource: 7, // Schválená - čakajúca na prebratie
      statusTarget: 8, // Prebratá
      type: 'plan-next-revision',
      endDate: 'datum-ukoncenia',
    },
  ],
};
