import { Box, Stack } from '@mantine/core';
import { FormEventHandler } from 'react';
import FormFooter from 'components/forms/FormFooter';

/**
 * Parameters of the FormWrapper component.
 */
export interface FormWrapperProps {
  primaryButtonText: string;
  primaryIcon: React.ReactNode;
  secondaryButtonText?: string;
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
  children: React.ReactNode;
  pt?: number;
  hidePrimaryButton?: boolean;
  hideSecondaryButton?: boolean;
  skipSecondaryButtonConfirm?: boolean;
  hideFooter?: boolean;
}

/**
 * Wrapper for forms.
 */
export default function FormWrapper({
  primaryButtonText,
  primaryIcon,
  secondaryButtonText,
  onSubmit,
  loading,
  children,
  pt,
  hidePrimaryButton = false,
  hideSecondaryButton = false,
  skipSecondaryButtonConfirm = false,
  hideFooter = false,
}: FormWrapperProps) {
  return (
    <Box pt={pt} sx={(theme) => ({ borderRadius: theme.radius.sm, backgroundColor: theme.white })}>
      <form onSubmit={onSubmit}>
        <Stack spacing={0} pb={16}>
          {children}
        </Stack>

        {!hideFooter && (
          <FormFooter
            loading={loading}
            primaryButtonText={primaryButtonText}
            primaryIcon={primaryIcon}
            secondaryButtonText={secondaryButtonText}
            hidePrimaryButton={hidePrimaryButton}
            hideSecondaryButton={hideSecondaryButton}
            skipSecondaryButtonConfirm={skipSecondaryButtonConfirm}
          />
        )}
      </form>
    </Box>
  );
}
