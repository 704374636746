import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import PickDevicesModal from 'components/modals/pick-devices-modal/PickDevicesModal';

/**
 * Callback for selecting the list of devices.
 */
type OnPickCallback = (value: {
  pickedDevices: {
    [deviceId: number]: {
      deviceName: string;
      term: string;
      deviceSubtypeId: number;
      deviceSubtypeName: string;
      checked: boolean;
      manufacturer?: string;
      type: string;
      serialNumber?: string;
      manufactured?: number;
      internalPossessionsNumber?: string;
      building?: string;
      location?: string;
      organization?: {
        organizationId: number;
        organizationName: string;
      };
      revisionPlan?: {
        revisionPlanId: number;
        description: string;
        date?: string;
        revisionPeriod: number;
        neverExecuted: boolean;
      }[];
    };
  };
}) => void;

/**
 * Parameters of the pickDevices function.
 */
interface PickDevicesParams {
  title: string;
  hiddenDevices: number[];
  deviceTypeId: number;
  departmentId: number;
  onPick: OnPickCallback;
}

/**
 * Function for selecting the list of devices.
 */
type PickDevices = (params: PickDevicesParams) => void;

/**
 * Interface of the PickDevicesContext.
 */
interface IPickDevicesContext {
  pickDevices: PickDevices;
}

/**
 * Context for selecting the list of devices.
 */
const PickDevicesContext = createContext<IPickDevicesContext>(undefined!);

/**
 * Provider for selecting the list of devices.
 */
export function PickDevicesProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<PickDevicesParams | undefined>(undefined);

  /**
   * Opens the modal for selecting the list of devices.
   */
  const pickDevices: PickDevices = (params) => {
    setParams(params);
    open();
  };

  return (
    <PickDevicesContext.Provider value={{ pickDevices }}>
      {children}
      {opened && <PickDevicesModal opened onClose={close} {...params!} />}
    </PickDevicesContext.Provider>
  );
}

/**
 * Hook for selecting the list of devices.
 */
export function usePickDevices() {
  return useContext(PickDevicesContext);
}
