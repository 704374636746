import { Box, Group, Stack } from '@mantine/core';
import { IconAlignLeft } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import AlignLeft from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/align/AlignLeft';
import AlignCenter from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/align/AlignCenter';
import AlignRight from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/align/AlignRight';
import AlignJustify from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/align/AlignJustify';
import { useMemo } from 'react';

/**
 * Control used to choose the alignment of the selected text.
 */
export default function Align({
  kind,
  childKind = kind,
}: {
  kind: DropdownControlKind;
  childKind?: DropdownControlKind;
}) {
  const gap = useMemo(() => (childKind === 'action-icon' ? 4 : 0), [childKind]);

  return (
    <DropdownControl label="Zarovnanie" kind={kind} icon={<IconAlignLeft />} closeOnClick={childKind === 'action-icon'}>
      <Box component={childKind === 'action-icon' ? Group : Stack} spacing={gap} p={gap}>
        <AlignLeft kind={childKind} />
        <AlignCenter kind={childKind} />
        <AlignRight kind={childKind} />
        <AlignJustify kind={childKind} />
      </Box>
    </DropdownControl>
  );
}
