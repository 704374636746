import { useMemo } from 'react';
import P2Regular from 'components/typography/P2Regular';
import { Box, Group, Stack, Tooltip } from '@mantine/core';

/**
 * A single device.
 */
interface Device {
  deviceId: number;
  deviceName: string;
}

/**
 * Parameters of the DeviceList component.
 */
export interface DeviceListProps {
  devices: Device[];
  limit?: number;
}

/**
 * Displays a list of devices.
 */
export default function DeviceList({ devices, limit = 2 }: DeviceListProps) {
  const some = useMemo(() => devices.slice(0, limit), [devices, limit]);
  const more = useMemo(() => devices.slice(limit), [devices, limit]);

  const someNames = useMemo(() => some.map(({ deviceName }) => deviceName).join(', '), [some]);

  const hasMore = more.length > 0;

  if (devices.length === 0) {
    return <P2Regular color="gray.6">-</P2Regular>;
  }

  return (
    <Group spacing={4} noWrap>
      <P2Regular color="gray.8">{someNames}</P2Regular>
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {more.map(({ deviceId, deviceName }) => (
                <P2Regular key={deviceId} color="gray.8">
                  {deviceName}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Regular color="gray.6">(+{more.length})</P2Regular>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}
