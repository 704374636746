import { Box, Flex } from '@mantine/core';
import { IconMapPinPlus } from '@tabler/icons-react';
import { DeviceRow } from 'components/tables/device/types';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the department name.
 */
export default function DepartmentNameColumn({ data: { department } }: { data: DeviceRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <IconMapPinPlus stroke="1.5" />
      </Box>
      <P2Regular color="gray.8">{department.departmentName}</P2Regular>
    </Flex>
  );
}
