import { useApi } from 'api/api-context';
import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface Props {
  permission?: string;
  oneOfPermissions?: string[];
  allOfPermissions?: string[];
  redirect: string;
}

/**
 * Requires the user to have the given permissions.
 */
export default function RequirePermission({ permission, oneOfPermissions, allOfPermissions, redirect }: Props) {
  const { hasPermissionAnywhere } = useApi();

  const hasAccess = useMemo(() => {
    if (permission && !hasPermissionAnywhere(permission)) {
      return false;
    }

    if (oneOfPermissions && !oneOfPermissions.some(hasPermissionAnywhere)) {
      return false;
    }

    if (allOfPermissions && !allOfPermissions.every(hasPermissionAnywhere)) {
      return false;
    }

    return true;
  }, [permission, oneOfPermissions, allOfPermissions, hasPermissionAnywhere]);

  if (!hasAccess) {
    return <Navigate to={redirect} />;
  }

  return <Outlet />;
}
