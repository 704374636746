import CompanyQualificationSelect from 'components/inputs/company-qualification-select/CompanyQualificationSelect';
import { IFormInputSpecQualification } from 'pages/revisions-module/template-editor/editors/form/types';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

/**
 * Qualification select input for the revision form.
 */
export default function RevisionFieldQualification({ spec, name }: RevisionFieldProps<IFormInputSpecQualification>) {
  const { form, revision } = useFillOutRevisionDataProvider();

  return (
    <CompanyQualificationSelect
      required={spec.required}
      readOnly={spec.readOnly}
      size="lg"
      searchable
      autoSelectSingleResult
      placeholder="Vyberte oprávenie firmy"
      loadingPlaceholder="Načítavanie ..."
      name={name}
      label={spec.label}
      deviceTypeId={revision.deviceType.deviceTypeId}
      revisionId={revision.revisionId}
      {...form.getInputProps(name)}
    />
  );
}
