import { Box } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import Toast from 'components/Toast';
import { IFormInputSpec, IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import NumberInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/NumberInputPropertiesSubForm';
import SelectInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/SelectInputPropertiesSubForm';
import PredefinedSelectInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/PredefinedSelectInputPropertiesSubForm';
import PredefinedListInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/PredefinedListInputPropertiesSubForm';
import SafetyPointInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/SafetyPointInputPropertiesSubForm';
import DevicePropertyInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/DevicePropertyInputPropertiesSubForm';
import SumInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/SumInputPropertiesSubForm';
import DatePlusPeriodInputPropertiesSubForm from 'pages/revisions-module/template-editor/editors/form/configurator/properties/DatePlusPeriodInputPropertiesSubForm';

type TypeCond<T, U> = [T] extends [never] ? { message: U } : {};

/**
 * Parameters of the InputPropertiesSubForm component.
 */
interface InputPropertiesSubFormProps {
  type: IFormInputType;
  form: UseFormReturnType<IFormInputSpec>;
}

/**
 * Used to configure the type-specific properties of an input.
 */
export default function InputPropertiesSubForm({ type, form }: InputPropertiesSubFormProps) {
  switch (type) {
    case 'text':
      return <></>;

    case 'number':
      return <NumberInputPropertiesSubForm form={form} />;

    case 'date':
      return <></>;

    case 'checkbox':
      return <></>;

    case 'certificate':
      return <></>;

    case 'qualification':
      return <></>;

    case 'select':
      return <SelectInputPropertiesSubForm form={form} />;

    case 'wysiwyg':
      return <></>;

    case 'predefinedSelect':
      return <PredefinedSelectInputPropertiesSubForm form={form} />;

    case 'predefinedList':
      return <PredefinedListInputPropertiesSubForm form={form} />;

    case 'measuringDevice':
      return <></>;

    case 'period':
      return <></>;

    case 'safetyPoint':
      return <SafetyPointInputPropertiesSubForm form={form} />;

    case 'deviceProperty':
      return <DevicePropertyInputPropertiesSubForm form={form} />;

    case 'sum':
      return <SumInputPropertiesSubForm form={form} />;

    case 'datePlusPeriod':
      return <DatePlusPeriodInputPropertiesSubForm form={form} />;

    default:
      // This is a TS trick to make sure all types are handled in the switch.
      // If there is a new type, the compiler will complain here. To fix it, add a new case to the switch.
      const { message }: TypeCond<typeof type, string> = { message: 'Neznámy typ poľa' };

      return (
        <Box mr={32}>
          <Toast fullWidth type="fail" withCloseButton={false} message={message} />
        </Box>
      );
  }
}
