import { ActionIcon, Button, Group, Menu, Portal, Skeleton, Text } from '@mantine/core';
import {
  IconBackspace,
  IconDotsVertical,
  IconEye,
  IconLicense,
  IconMapCheck,
  IconPencil,
  IconSettingsExclamation,
  IconTrashX,
} from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useAcknowledge, useConfirm } from 'components/modals/message/MessageProvider';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DEVICE_FAULTS_PAGE_PATH, EDIT_DEVICE_PAGE_PATH, REVISIONS_PAGE_PATH } from 'routes/paths';

/**
 * Parameters of the DeviceActions component.
 */
export interface DeviceActionsProps {
  deviceId: number;
  deviceName: string;
  department: { departmentId: number };
  canDiscard: boolean;
  status: boolean;
  latitude?: number;
  longitude?: number;
  onStatusChange: (status: boolean) => void;
}

/**
 * Displays actions for a device in a table
 */
export default function DeviceActions({
  deviceId,
  deviceName,
  department: { departmentId },
  canDiscard,
  latitude,
  longitude,
  status,
  onStatusChange,
}: DeviceActionsProps) {
  const { getAction, roleId } = useApi();
  const { confirm } = useConfirm();
  const { acknowledge } = useAcknowledge();

  const [canEdit, setCanEdit] = useState(roleId === ROLE_ADMIN_ID);
  const [canSeeRevisions, setCanSeeRevisions] = useState(roleId === ROLE_ADMIN_ID);
  const [loading, setLoading] = useState(roleId !== ROLE_ADMIN_ID);

  useEffect(() => {
    if (roleId === ROLE_ADMIN_ID) {
      setCanEdit(true);
      setCanSeeRevisions(true);
      setLoading(false);
    } else {
      const getPermissions = getAction('AuthUserGetPermissionsInDepartment');

      getPermissions({ parameters: { departmentId: String(departmentId) } })
        .then((permissions) => {
          setCanEdit(permissions.some(({ slug, hasPermission }) => slug === 'manage-devices' && hasPermission));
          setCanSeeRevisions(
            permissions.some(({ slug, hasPermission }) => slug.endsWith('-revisions') && hasPermission)
          );
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [roleId, departmentId]);

  /**
   * Makes a call to the API to set the status of the device.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const deviceSetStatusAction = getAction('DeviceSetStatus');

      deviceSetStatusAction({ parameters: { deviceId: String(deviceId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch(panic);
    },
    [getAction, deviceId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      if (!status && !canDiscard) {
        acknowledge({
          title: 'Nemožno vyradiť zariadenie',
          content:
            'Existujú rozpracované revízne správy týkajúce sa zariadenia. Ukončite rozpracované revízne správy a potom môžete zariadenie vyradiť.',
        });
      } else {
        const title = status ? 'Zaradiť zariadenie' : 'Vyradiť zariadenie';
        const message = status ? 'Naozaj chcete zaradiť zariadenie?' : 'Naozaj chcete vyradiť zariadenie?';

        confirm({
          title,
          content: message,
          onConfirm: () => setStatusRemote(status),
        });
      }
    },
    [confirm, setStatusRemote]
  );

  const mainAction = useMemo(() => {
    if (loading) {
      return <Skeleton width={120} height={40} radius={4} />;
    }

    return (
      <Button
        w={120}
        size="md"
        component={Link}
        to={EDIT_DEVICE_PAGE_PATH.insert({ deviceId })}
        variant="secondary"
        leftIcon={canEdit ? <IconPencil /> : <IconEye />}
        disabled={!status}
      >
        {canEdit ? 'Editovať' : 'Zobraziť'}
      </Button>
    );
  }, [loading, canEdit, deviceId, status]);

  return (
    <Group spacing={12} noWrap>
      {mainAction}
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {deviceName}
              </Text>
            </Menu.Label>
            {status && (
              <Menu.Item
                component={Link}
                to={DEVICE_FAULTS_PAGE_PATH.insert({ deviceId })}
                icon={<IconSettingsExclamation stroke="1.5" size={20} />}
                color="gray.8"
              >
                Zobraziť závady
              </Menu.Item>
            )}
            {longitude && latitude && (
              <Menu.Item
                component={Link}
                to={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                icon={<IconMapCheck stroke={1.5} size={20} />}
                target="_blank"
              >
                Zobraziť na mape
              </Menu.Item>
            )}
            {canSeeRevisions && (
              <Menu.Item
                component={Link}
                to={`${REVISIONS_PAGE_PATH.original}?deviceId=${deviceId}`}
                icon={<IconLicense stroke={1.5} size={20} />}
              >
                Zobraziť revízne správy
              </Menu.Item>
            )}
            {canEdit && (
              <Menu.Item
                color={status ? 'red.8' : 'blue.8'}
                onClick={() => confirmSetStatus(!status)}
                icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
              >
                {status ? 'Vyradiť' : 'Zaradiť'}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
