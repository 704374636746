import { IRowNode } from 'ag-grid-community';
import DepartmentActions from 'components/tables/department/DepartmentActions';
import { DepartmentRow } from 'components/tables/department/types';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<DepartmentRow>; data: DepartmentRow }) {
  return (
    <DepartmentActions
      {...data}
      canDiscard={data.canDiscard}
      onStatusChange={(status) => node.setData({ ...data, status })}
    />
  );
}
