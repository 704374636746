import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionRevisionTemplateCreatePayload } from 'api/actions/revision-revision-template-create/revision-revision-template-create-payload';
import { RevisionRevisionTemplateCreatePathParameters } from 'api/actions/revision-revision-template-create/revision-revision-template-create-path-parameters';
import { RevisionRevisionTemplateCreateResponse } from 'api/actions/revision-revision-template-create/revision-revision-template-create-response';

type RevisionRevisionTemplateCreateQueryParameters = Record<string, any>;

/**
 * The path of the RevisionRevisionTemplateCreate action.
 */
export const revisionRevisionTemplateCreateActionPath = new SweetPath<
  keyof RevisionRevisionTemplateCreatePathParameters
>('/api/revision/revision-template/create', { path: '{param}' });

/**
 * Creates a contextualized RevisionRevisionTemplateCreate actions.
 */
export default function createRevisionRevisionTemplateCreateAction(axios: AxiosInstance) {
  return function revisionRevisionTemplateCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: RevisionRevisionTemplateCreateQueryParameters;
    payload: RevisionRevisionTemplateCreatePayload;
  }) {
    const path = revisionRevisionTemplateCreateActionPath.original;

    return axios.post<
      RevisionRevisionTemplateCreateResponse,
      RevisionRevisionTemplateCreateResponse,
      RevisionRevisionTemplateCreatePayload
    >(path, payload);
  };
}
