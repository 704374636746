import { Menu } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { useEffect, useMemo, useState } from 'react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';

export const ZOOM_OPTIONS = [100, 110, 125, 150, 175, 200];

/**
 * Allows the user to zoom in and out of the template.
 */
export default function Zoom({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();
  const [zoom, setZoom] = useState(100);
  const text = useMemo(() => `${zoom}%`, [zoom]);

  useEffect(() => {
    if (editor) {
      editor.setOptions({
        editorProps: {
          attributes: {
            ...editor.options.editorProps.attributes,
            'data-zoom': zoom.toString(),
          },
        },
      });
    }
  }, [editor, zoom]);

  return (
    <DropdownControl kind={kind} label="Lupa" text={text}>
      {ZOOM_OPTIONS.map((zoom) => (
        <Menu.Item
          key={zoom}
          onClick={() => {
            setZoom(zoom);
            editor.chain().focus().run();
          }}
        >
          {zoom}%
        </Menu.Item>
      ))}
    </DropdownControl>
  );
}
