import { ActionIcon, Flex, Input, SimpleGrid, useMantineTheme } from '@mantine/core';
import { IconTrashX } from '@tabler/icons-react';
import { UseFormReturnType } from '@mantine/form';
import { DeviceFaultFormData } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultForm';
import { FaultSeveritySelect } from 'components/selects/FaultSeveritySelect';
import { useCallback } from 'react';
import { noop } from 'lodash';
import PredefinedDataSelect from 'components/inputs/predefined-data-select/PredefinedDataSelect';
import { useConfirm } from 'components/modals/message/MessageProvider';

interface DeviceFaultFormRowProps {
  deviceTypeId: number;
  form: UseFormReturnType<DeviceFaultFormData, (values: DeviceFaultFormData) => DeviceFaultFormData>;
  index: number;
  onDelete?: (index: number) => void;
}

/**
 * Renders a row of a device fault form.
 */
export function DeviceFaultFormRow({
  deviceTypeId,
  form,
  index,
  onDelete = noop,
}: DeviceFaultFormRowProps): JSX.Element {
  const theme = useMantineTheme();
  const { confirm } = useConfirm();

  /**
   * Confirms the row delete.
   */
  const confirmRowDelete = useCallback(() => {
    confirm({
      title: 'Zmazanie závady',
      content: 'Naozaj chcete zmazať túto závadu?',
      onConfirm: () => onDelete(index),
    });
  }, [confirm, index, onDelete]);

  return (
    <Flex align="flex-start" style={{ borderBottom: `1px solid ${theme.colors.gray[1]}` }}>
      <SimpleGrid spacing={24} py={24} px={16} cols={3} style={{ flex: '1 0 0' }}>
        <PredefinedDataSelect
          searchable
          size="md"
          placeholder="Závada"
          deviceTypeId={deviceTypeId}
          predefinedDataType="Závady"
          onDescriptionChange={(description) => form.setFieldValue(`faults.${index}.description`, description)}
          {...form.getInputProps(`faults.${index}.fault`)}
        />
        <FaultSeveritySelect
          searchable
          size="md"
          placeholder="Závažnosť"
          loadingPlaceholder="Závažnosť"
          {...form.getInputProps(`faults.${index}.severity`)}
        />
        <Input size="md" placeholder="Popis" {...form.getInputProps(`faults.${index}.moreInfo`)} />
      </SimpleGrid>

      <Flex w={72} h="auto" py={24} px={16} align="flex-start" gap={12} style={{ flexShrink: 0 }}>
        <ActionIcon variant="danger-secondary" size="md" onClick={confirmRowDelete}>
          <IconTrashX />
        </ActionIcon>
      </Flex>
    </Flex>
  );
}
