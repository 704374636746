import { useApi } from 'api/api-context';
import NumberStatCard from 'components/stats/NumberStatCard';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import { REVISIONS_PAGE_PATH } from 'routes/paths';

/**
 * Displays the number of revisions after deadline.
 */
export default function RevisionsAfterDeadlineCard() {
  const { getAction } = useApi();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const now = new Date().toISOString();

    const revisionListAction = getAction('RevisionList');

    revisionListAction({
      query: { filters: { 'deadline.lt': now, 'statusId.in': [1, 2, 3, 4, 5, 6, 7], 'includedInStatistics.eq': 1 } },
    })
      .then(({ length }) => setValue(length))
      .catch(panic);
  }, []);

  return (
    <NumberStatCard
      loading={value === null}
      title="Revízie po termíne"
      link={`${REVISIONS_PAGE_PATH.original}?deadlineStatus=after-deadline&revisionStatusId=1,2,3,4,5,6,7`}
      value={value ?? 0}
      variant={value === 0 ? 'gray' : 'red'}
    />
  );
}
