import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionResumeWorkPayload } from 'api/actions/revision-resume-work/revision-resume-work-payload';
import { RevisionResumeWorkPathParameters } from 'api/actions/revision-resume-work/revision-resume-work-path-parameters';
import { RevisionResumeWorkResponse } from 'api/actions/revision-resume-work/revision-resume-work-response';

type RevisionResumeWorkQueryParameters = Record<string, any>;

/**
 * The path of the RevisionResumeWork action.
 */
export const revisionResumeWorkActionPath = new SweetPath<keyof RevisionResumeWorkPathParameters>(
  '/api/revision/resume-work/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionResumeWork actions.
 */
export default function createRevisionResumeWorkAction(axios: AxiosInstance) {
  return function revisionResumeWorkAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionResumeWorkPathParameters;
    query?: RevisionResumeWorkQueryParameters;
    payload: RevisionResumeWorkPayload;
  }) {
    const path = revisionResumeWorkActionPath.insert(parameters);

    return axios.post<RevisionResumeWorkResponse, RevisionResumeWorkResponse, RevisionResumeWorkPayload>(path, payload);
  };
}
