import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthForgotPasswordPayload } from 'api/actions/auth-forgot-password/auth-forgot-password-payload';
import { AuthForgotPasswordPathParameters } from 'api/actions/auth-forgot-password/auth-forgot-password-path-parameters';
import { AuthForgotPasswordResponse } from 'api/actions/auth-forgot-password/auth-forgot-password-response';

type AuthForgotPasswordQueryParameters = Record<string, any>;

/**
 * The path of the AuthForgotPassword action.
 */
export const authForgotPasswordActionPath = new SweetPath<keyof AuthForgotPasswordPathParameters>(
  '/api/auth/forgot-password',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthForgotPassword actions.
 */
export default function createAuthForgotPasswordAction(axios: AxiosInstance) {
  return function authForgotPasswordAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: AuthForgotPasswordQueryParameters;
    payload: AuthForgotPasswordPayload;
  }) {
    const path = authForgotPasswordActionPath.original;

    return axios.post<AuthForgotPasswordResponse, AuthForgotPasswordResponse, AuthForgotPasswordPayload>(path, payload);
  };
}
