import { Group, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

interface ImageDisplayProps {
  fileId: string;
  alt?: string;
  title?: string;
  thumbnailUrl?: string;
}

/**
 * Displays a single image.
 */
export default function ImageDisplay({ fileId, alt, title, thumbnailUrl = '' }: ImageDisplayProps) {
  const { readFile } = useFileManager();
  const [url, setUrl] = useState(thumbnailUrl);
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(true);

  useEffect(() => {
    startLoading();

    readFile({ fileId })
      .then((file) => {
        const url = URL.createObjectURL(file);
        setUrl(url);
      })
      .catch(panic);

    // createAccessToken({ fileId })
    //   .then((token: string) => setUrl(`/api/files/one-time/file/${token}`))
    //   .catch(panic);
  }, [fileId]);

  return (
    <Group position="center" w="100%" h="100%" pos="relative">
      <LoadingOverlay visible={loading} />
      <img
        className="max-h-full max-w-full rounded-t-[4px]"
        src={url}
        alt={loading ? '' : alt}
        title={loading ? '' : title}
        onLoad={stopLoading}
      />
    </Group>
  );
}
