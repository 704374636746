import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionTechnicianSubmitPathParameters } from 'api/actions/revision-technician-submit/revision-technician-submit-path-parameters';
import { RevisionTechnicianSubmitResponse } from 'api/actions/revision-technician-submit/revision-technician-submit-response';

type RevisionTechnicianSubmitQueryParameters = Record<string, any>;

/**
 * The path of the RevisionTechnicianSubmit action.
 */
export const revisionTechnicianSubmitActionPath = new SweetPath<keyof RevisionTechnicianSubmitPathParameters>(
  '/api/revision/technician-submit/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionTechnicianSubmit actions.
 */
export default function createRevisionTechnicianSubmitAction(axios: AxiosInstance) {
  return function revisionTechnicianSubmitAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionTechnicianSubmitPathParameters;
    query?: RevisionTechnicianSubmitQueryParameters;
  }) {
    const path = revisionTechnicianSubmitActionPath.insert(parameters);

    return axios.post<RevisionTechnicianSubmitResponse, RevisionTechnicianSubmitResponse>(path, {
      params: query,
      headers,
    });
  };
}
