import { Box, Flex, Group, Stack, StackProps } from '@mantine/core';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import H3Medium from 'components/typography/H3Medium';

/**
 * The props of the FormInputGroup.
 */
export interface FormInputGroupProps {
  groupTitle: string | React.ReactNode;
  groupTooltip?: string;
  infoBox?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
  stackProps?: StackProps;
}

/**
 * Input group with the title, left border and children.
 */
export default function FormInputGroup({
  groupTitle,
  groupTooltip,
  infoBox,
  children,
  stackProps,
}: FormInputGroupProps) {
  return (
    <Stack pt={24} pb={24} pl={40} pr={24} spacing="xl" {...stackProps}>
      <Group spacing={16}>
        <Group noWrap spacing={8}>
          {groupTitle && <H3Medium color="gray.7">{groupTitle}</H3Medium>}
          {groupTooltip && (
            <QuestionMarkTooltip withinPortal multiline width={200} label={groupTooltip} openDelay={100} />
          )}
        </Group>
        {infoBox}
      </Group>
      <Flex gap={32}>
        <Box w={8} bg="gray.0" />
        <Stack w="100%" spacing={24}>
          {children}
        </Stack>
      </Flex>
    </Stack>
  );
}
