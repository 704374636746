import { Button, Checkbox, Divider, Group, Modal, ScrollArea, Stack, TextInput, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import P2Regular from 'components/typography/P2Regular';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import panic from 'errors/panic';
import PermissionTemplateInput from 'components/inputs/permission-template-input/PermissionTemplateInput';
import { DepartmentListResponse } from 'api/actions/department-list/department-list-response';

type Step = 'departments' | 'permission';

interface PickUsersModalParams {
  title: string;
  permissionsTitle?: string;
  opened: boolean;
  hiddenDepartments: number[];
  pickDepartments?: boolean;
  pickPermissions?: boolean;
  initialStep?: Step;
  initialTemplateName: string;
  initialPermissions: number[];
  onClose: () => void;
  onPick: (value: {
    pickedDepartments: {
      [departmentId: number]: { checked: boolean; templateName: string; departmentName: string };
    };
    permissions: number[];
    templateName: string;
  }) => void;
}

type FormValues = {
  pickedDepartments: {
    [departmentId: number]: { checked: boolean; templateName: string; departmentName: string };
  };
  permissions: { templateName: string; permissions: number[] };
};

type Department = DepartmentListResponse[number] & { search: string };

/**
 * Modal for selecting permissions of a user in a department.
 */
export default function PickUsersModal({
  title,
  permissionsTitle,
  hiddenDepartments,
  opened,
  pickDepartments = true,
  pickPermissions = true,
  initialTemplateName,
  initialPermissions,
  onClose,
  onPick,
}: PickUsersModalParams) {
  const [search, setSearch] = useState<string>('');
  const [step, setStep] = useState<'departments' | 'permission'>(pickDepartments ? 'departments' : 'permission');
  const [departments, setDepartments] = useState<Department[]>([]);

  const { getAction } = useApi();
  const theme = useMantineTheme();

  const filteredDepartments = useMemo(() => {
    const normalizedSearch = search.toLowerCase(); // TODO: Remove diacritics

    return departments.filter((department) => department.departmentName.toLowerCase().includes(normalizedSearch));
  }, [search, departments]);

  const form = useForm<FormValues>({
    initialValues: {
      pickedDepartments: {},
      permissions: { permissions: initialPermissions, templateName: initialTemplateName },
    },
  });

  /**
   * Submits the form.
   */
  function submit({ pickedDepartments, permissions }: FormValues) {
    onPick({ pickedDepartments, permissions: permissions.permissions, templateName: permissions.templateName });
    onClose();
  }

  useEffect(() => {
    const departmentGetListAction = getAction('DepartmentList');
    // retrieve all people from the app
    // the backend will take care of filtering out the ones the current user doesn't have access to
    departmentGetListAction({ query: { filters: { 'showDiscarded.eq': 0, 'permissionSlug.eq': 'manage-users' } } })
      .then((departments) => {
        const filtered = departments
          .filter(({ departmentId }) => !hiddenDepartments.includes(departmentId))
          .map((department) => ({
            ...department,
            search: department.departmentName.toLowerCase(),
          }));

        const reduced = filtered.reduce((acc, curr) => {
          acc[curr.departmentId] = {
            checked: false,
            templateName: 'no-template',
            departmentName: curr.departmentName,
          };
          return acc;
        }, {} as { [departmentId: number]: { checked: boolean; templateName: string; departmentName: string } });

        form.setFieldValue('pickedDepartments', reduced);

        setDepartments(filtered);
      })
      .catch(panic);
  }, [getAction]);

  const multiStep = pickDepartments && pickPermissions;

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        step === 'departments'
          ? title
          : multiStep && step === 'permission'
          ? 'Nastaviť oprávnenia v stredisku'
          : permissionsTitle
      }
      centered
    >
      <Divider color="gray.1" mb={24} />
      <form onSubmit={form.onSubmit(submit)}>
        {step === 'departments' ? (
          <Stack spacing={24}>
            <TextInput
              rightSection={<IconSearch stroke="1.5" color={theme.colors.gray[6]} />}
              value={search}
              placeholder="Hľadať strediská"
              size="md"
              onChange={(event) => setSearch(event.currentTarget.value)}
              // TODO name
              name="amperia-search-departments"
            />
            <ScrollArea h={410}>
              <Stack spacing={24} pb={24}>
                {filteredDepartments.map((department) => (
                  <Checkbox
                    key={department.departmentId}
                    label={department.departmentName}
                    size="lg"
                    form="pick-departments-modal"
                    name={`amperia-pickedDepartments.${department.departmentId}.checked`}
                    {...form.getInputProps(`pickedDepartments.${department.departmentId}.checked`, {
                      type: 'checkbox',
                    })}
                  />
                ))}
                {isEmpty(departments) && <P2Regular>Neboli nájdené žiadne strediská.</P2Regular>}
              </Stack>
            </ScrollArea>
          </Stack>
        ) : (
          <PermissionTemplateInput {...form.getInputProps('permissions')} permissionType="department" />
        )}

        {/* TODO find a proper place to display this error */}
        <P2Regular color="red.9">{form.errors.pickedDepartments}</P2Regular>
        <P2Regular color="red.9">{form.errors.permissions}</P2Regular>

        <Group spacing={40} position="apart" mt={24}>
          <Button
            variant="subtle-gray"
            type="button"
            leftIcon={
              multiStep && step === 'permission' ? (
                <IconChevronLeft stroke="1.5" color={theme.colors.gray[7]} />
              ) : undefined
            }
            onClick={() => {
              if (multiStep && step === 'permission') {
                setStep('departments');
              } else {
                onClose();
              }
            }}
          >
            {multiStep && step === 'permission' ? 'Späť' : 'Zrušiť'}
          </Button>
          {step === 'departments' && (
            <Button
              variant="primary"
              size="md"
              type="button"
              rightIcon={<IconChevronRight stroke="1.5" color={theme.colors.gray[0]} />}
              onClick={() => setStep('permission')}
              disabled={Object.values(form.values.pickedDepartments).every((value) => !value.checked)}
            >
              Pokračovať
            </Button>
          )}
          {step === 'permission' && (
            <Button
              variant="primary"
              size="md"
              type="submit"
              disabled={form.values.permissions.permissions.length === 0}
            >
              Uložiť {multiStep && 'a pridať strediská'}
            </Button>
          )}
        </Group>
      </form>
    </Modal>
  );
}
