import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import RevisionForm, { RevisionFormData } from 'components/forms/revision/RevisionForm';
import { REVISION_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { REVISIONS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit a revision.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A13966 Figma Design}
 * - {@link https://www.notion.so/Revisions-Edit-Revision-5d166cffe77342669c89964dcfdddaaa?pvs=4 Notion Page}
 */
export default function EditRevisionPage() {
  const navigate = useNavigate();
  const { revisionId } = useParams();
  const { getAction } = useApi();

  const { data, loading, error } = useImmediateAction(() => {
    const revisionGetAction = getAction('RevisionGet');
    return revisionGetAction({ parameters: { revisionId: revisionId! } });
  });

  if (error) {
    panic(error);
    return <></>;
  }

  if (!revisionId) {
    return <Navigate to={REVISIONS_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({ revisionName, deadline, technicianId, devices }: RevisionFormData) {
    const mappedDevices = Object.keys(devices)
      .map((deviceId) => {
        const device = devices[deviceId];
        return {
          deviceId: Number(deviceId),
          deviceName: device.deviceName,
          termId: device.termId && device.termId !== '' ? Number(device.termId) : undefined,
          deviceSubtypeId: device.deviceSubtypeId,
          deviceSubtypeName: device.deviceSubtypeName,
          checked: device.checked,
          manufacturer: device.manufacturer === '' ? undefined : device.manufacturer,
          type: device.type,
          serialNumber: device.serialNumber === '' ? undefined : device.serialNumber,
          manufactured: device.manufactured ?? undefined,
          internalPossessionsNumber: device.internalPossessionsNumber ?? '',
          location: device.location ?? '',
          organization: device.organization ?? { organizationId: 0, organizationName: '' },
        };
      })
      .filter((device) => device.checked);

    const revisionUpdateAction = getAction('RevisionUpdate');

    return revisionUpdateAction({
      parameters: { revisionId: String(revisionId) },
      payload: {
        revisionName,
        deadline,
        technicianId: Number(technicianId),
        devices: mappedDevices,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Revízna správa bola upravená',
          message: `Revízna správa ${revisionName} bola úspešne upravená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (REVISION_REDIRECT_AFTER_SAVE === 'list') {
          navigate(REVISIONS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title={`Úprava revíznej správy: ${data?.revisionName ?? ''}`}
      breadcrumbs={[
        { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
        { title: data?.revisionName ?? 'Načítavanie ...' },
      ]}
    >
      {!loading && (
        <RevisionForm
          context="edit"
          initialValues={{
            revisionName: data.revisionName,
            deadline: data.deadline,
            organizationId: String(data.organization.organizationId),
            departmentId: String(data.department.departmentId),
            deviceTypeId: String(data.deviceType.deviceTypeId),
            devices: data.devices.reduce(
              (acc, item) => {
                acc[String(item.deviceId)] = {
                  deviceName: item.deviceName,
                  termId: item.termId ? String(item.termId) : '',
                  deviceSubtypeId: item.deviceSubtype?.deviceTypeId ?? 0,
                  deviceSubtypeName: item.deviceSubtype?.deviceTypeName ?? '',
                  checked: true,
                  manufacturer: item.manufacturer,
                  type: item.type,
                  serialNumber: item.serialNumber,
                  manufactured: item.manufactured,
                  internalPossessionsNumber: item.internalPossessionsNumber ?? '',
                  building: item.building ?? '',
                  location: item.location ?? '',
                  organization: item.organization,
                  revisionPlan: (item.revisionPlan || []).map((rp) => ({
                    revisionPlanId: rp.revisionPlanId!,
                    description: rp.description,
                    date: rp.date,
                    revisionPeriod: rp.revisionPeriod,
                    neverExecuted: rp.neverExecuted,
                  })),
                };
                return acc;
              },
              {} as {
                [deviceId: string]: {
                  deviceName: string;
                  termId: string;
                  deviceSubtypeId: number;
                  deviceSubtypeName: string;
                  checked: boolean;
                  manufacturer?: string;
                  type: string;
                  serialNumber?: string;
                  manufactured?: number;
                  internalPossessionsNumber?: string;
                  building?: string;
                  location?: string;
                  organization?: { organizationId: number; organizationName: string };
                  revisionPlan?: {
                    revisionPlanId: number;
                    description: string;
                    date?: string;
                    revisionPeriod: number;
                    neverExecuted: boolean;
                  }[];
                };
              }
            ),
            technicianId: String(data.technician.userId),
          }}
          onSubmit={onSubmit}
          noValueChange={{
            organizationId: true,
            departmentId: true,
            deviceTypeId: true,
            technicianId: true,
          }}
          readOnly={{
            organizationId: true,
            departmentId: true,
            deviceTypeId: true,
            technicianId:
              data.revisionStatus.slug === 'created' || data.revisionStatus.slug === 'declined-by-technician'
                ? undefined
                : true,
            devices: data.revisionStatus.slug === 'created' ? false : true,
          }}
        />
      )}
    </DashboardLayout>
  );
}
