import { Button, Checkbox, Group, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeModal, openModal } from '@mantine/modals';
import { Editor } from '@tiptap/react';
import P3Regular from 'components/typography/P3Regular';
import { useCallback, useEffect, useMemo } from 'react';
import { DeviceFaultsPlaceholderFormat } from 'pages/revisions-module/template-editor/editors/template/extensions/placeholder/device-faults/DeviceFaultsPlaceholder';

const MODAL_ID = 'device-faults-placeholder-configurator';
const EVENT_NAME = 'open-device-faults-configurator';

/**
 * The configurator for the device faults placeholder.
 */
function DeviceFaultsConfigurator({ editor }: { editor: Editor }) {
  const initialValues = useMemo(() => {
    const { format } = editor.getAttributes('deviceFaultsPlaceholder');
    return format as DeviceFaultsPlaceholderFormat;
  }, []);

  const form = useForm<DeviceFaultsPlaceholderFormat>({ initialValues });

  const formats = useMemo(
    () => [
      { value: 'ul', label: 'Nečíslovaný zoznam' },
      { value: 'ol', label: 'Číslovaný zoznam' },
      { value: 'table', label: 'Tabuľka' },
    ],
    []
  );

  const close = useCallback(() => closeModal(MODAL_ID), []);

  const submit = useCallback(
    (format: DeviceFaultsPlaceholderFormat) => {
      editor.chain().focus().setDeviceFaultsPlaceholderFormat(format).run();
      close();
    },
    [editor, close]
  );

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack spacing={24}>
        <Select size="sm" required label="Formát výpisu" data={formats} {...form.getInputProps('type')} />
        <Stack spacing={8}>
          <P3Regular c="gray.6">Zobraziť údaje:</P3Regular>
          <Checkbox label="Názov" {...form.getInputProps('showName', { type: 'checkbox' })} />
          <Checkbox label="Závažnosť" {...form.getInputProps('showSeverity', { type: 'checkbox' })} />
          <Checkbox label="Popis" {...form.getInputProps('showDescription', { type: 'checkbox' })} />
          <Checkbox label="Dodatok (vlastný popis)" {...form.getInputProps('showMoreInfo', { type: 'checkbox' })} />
        </Stack>
        <Group pt={8} spacing={16} position="right">
          <Button type="button" onClick={close} variant="subtle-gray">
            Zrušiť
          </Button>
          <Button type="submit" variant="primary">
            Nastaviť
          </Button>
        </Group>
      </Stack>
    </form>
  );
}

/**
 * Hook used to register the configurator for the device faults placeholder.
 */
export default function useDeviceFaultsPlaceholderConfigurator(editor: Editor | null) {
  const openConfigurator = useCallback(() => {
    if (editor) {
      openModal({
        modalId: MODAL_ID,
        title: 'Konfigurátor výpisu závad',
        size: 400,
        centered: true,
        returnFocus: false,
        children: <DeviceFaultsConfigurator editor={editor} />,
      });
    }
  }, [editor]);

  useEffect(() => {
    window.addEventListener(EVENT_NAME, openConfigurator);
    return () => window.removeEventListener(EVENT_NAME, openConfigurator);
  }, [openConfigurator]);
}
