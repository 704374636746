import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import MeasuringDeviceForm, { MeasuringDeviceFormData } from 'components/forms/measuring-device/MeasuringDeviceForm';
import { MEASURING_DEVICE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { EDIT_MEASURING_DEVICE_PAGE_PATH, MEASURING_DEVICES_PAGE_PATH, SETTINGS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add measuring device.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=251%3A8831 Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-Create-New-Measuring-Device-3f809cc4a9de45c59d7eb0f45c63d343?pvs=4 Notion Page}
 */
export default function AddMeasuringDevicePage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({ deviceName, manufacturer, serialNumber, type, deviceInfo }: MeasuringDeviceFormData) {
    const measuringDeviceCreateAction = getAction('MeasuringDeviceCreate');

    return measuringDeviceCreateAction({
      payload: {
        measuringDeviceName: deviceName,
        manufacturer,
        type,
        serialNumber,
        description: deviceInfo,
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Merací prístroj bol vytvorený',
          message: `Merací prístroj ${deviceName} bol úspešne vytvorený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (MEASURING_DEVICE_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_MEASURING_DEVICE_PAGE_PATH.insert({ measuringDeviceId: response.measuringDeviceId }));
        } else if (MEASURING_DEVICE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(MEASURING_DEVICES_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového meracieho prístroja"
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        { title: 'Meracie prístroje', link: MEASURING_DEVICES_PAGE_PATH.original },
        { title: 'Pridanie nového meracieho prístroja' },
      ]}
    >
      <MeasuringDeviceForm
        onSubmit={onSubmit}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
