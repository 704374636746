import { createContext, ReactNode, useContext, useMemo } from 'react';
import { ERROR_NOTIFICATION_COLOR, SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';
import { showNotification } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import {
  IExtendedFormInput,
  useFillOutRevisionDataProvider,
} from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useFillOutRevisionSaveDraftProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionSaveDraftProvider';
import { REVISION_PAGE_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mantine/core';
import { FormErrors } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

interface IFillOutRevisionSubmitProviderContext {
  submit: (options?: { notify?: boolean }) => void;
  loading: boolean;
}

const FillOutRevisionSubmitProviderContext = createContext<IFillOutRevisionSubmitProviderContext>(undefined!);

/**
 * Provides the revision and form spec to the children.
 */
export function FillOutRevisionSubmitProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { revision, form, findInputByName } = useFillOutRevisionDataProvider();
  const { saveDraft } = useFillOutRevisionSaveDraftProvider();
  const { getAction } = useApi();
  const [loading, { open: startLoading, close: stopLoading }] = useDisclosure(false);

  const revisionDetailPath = useMemo(
    () => REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId }),
    [revision.revisionId]
  );

  /** Sends the revision data to the server. */
  const sendRequest = async () => {
    const submit = getAction('RevisionTechnicianSubmit');

    await saveDraft({ notify: false });
    await submit({ parameters: { revisionId: String(revision.revisionId) } });

    showNotification({
      title: 'Revízna správa bola odovzdaná',
      message: `Revíznu správu ${revision?.revisionName} ste úspešne odovzdali.`,
      color: SUCCESS_NOTIFICATION_COLOR,
    });

    navigate(revisionDetailPath);
  };

  /** Handles form errors. */
  const handleErrors = (errors: FormErrors) => {
    const missingFields: Record<string, IExtendedFormInput> = {};

    for (const nameWithPrefix in errors) {
      const name = nameWithPrefix.replace(/^fields\./, '').replace(/^devices\.\d+\.fields\./, '');
      const input = findInputByName(name);

      if (input && !missingFields[input.id]) {
        missingFields[input.id] = input;
      }
    }

    showNotification({
      title: 'Vo formulári sú chyby!',
      message: (
        <Stack spacing={8}>
          Vyplňte všetky povinné polia:
          <Stack spacing={4}>
            {Object.values(missingFields).map((field) => (
              <Box key={field.id}>
                - {field.spec.label} ({field.tabTitle})
              </Box>
            ))}
          </Stack>
        </Stack>
      ),
      color: ERROR_NOTIFICATION_COLOR,
    });
  };

  /** Sends the request if the form is valid. */
  const sendRequestIfValid = async () => {
    const { hasErrors, errors } = form.validate();

    if (hasErrors) {
      handleErrors(errors);
    } else {
      await sendRequest();
    }
  };

  /** Submits the revision data (with validation). */
  const submit = () => {
    if (!loading) {
      startLoading();
      sendRequestIfValid().catch(panic).finally(stopLoading);
    }
  };

  const value = useMemo(() => ({ submit, loading }), [submit, loading]);

  return (
    <FillOutRevisionSubmitProviderContext.Provider value={value}>
      {children}
    </FillOutRevisionSubmitProviderContext.Provider>
  );
}

/**
 * Hook to get the revision and form spec.
 */
export function useFillOutRevisionSubmitProvider() {
  return useContext(FillOutRevisionSubmitProviderContext);
}
