import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import OrganizationForm, { OrganizationFormData } from 'components/forms/organization/OrganizationForm';
import { ORGANIZATION_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { getDeviceType } from 'model/DeviceType';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useNavigate } from 'react-router-dom';
import { EDIT_ORGANIZATION_PAGE_PATH, ORGANIZATIONS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add a new organization.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A7858 Figma Design}
 * - {@link https://www.notion.so/Administrative-Create-Organization-fe6c84beb7cd4e41b683f338752be311?pvs=4 Notion Page}
 */
export default function AddOrganizationPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();
  const { getPermissionTemplate, getDefaultPermissionTemplate } = usePermissionDataProvider();

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    organizationName,
    streetAddress,
    city,
    zip,
    country,
    selfAdministration,
    companyQualifications,
    people,
    includedInStatistics,
    inKepHashArchive,
  }: OrganizationFormData) {
    const organizationCreateAction = getAction('OrganizationCreate');

    const mappedPeople = Object.entries(people)
      .map(([personId, value]) => ({
        userId: Number(personId),
        checked: value.checked,
        deleted: value.deleted,
        permissions: value.permissions,
        templateId:
          getPermissionTemplate(value.templateName)?.permissionTemplateId ??
          getDefaultPermissionTemplate().permissionTemplateId,
        isNew: value.isNew,
      }))
      .map(({ userId, permissions, templateId, deleted, isNew }) => ({
        deleted,
        userId,
        permissions: permissions ?? [],
        templateId,
        isNew,
      }));

    return organizationCreateAction({
      payload: {
        organizationName,
        streetAddress,
        city,
        zip,
        country,
        selfAdministration,
        companyQualifications: (companyQualifications || []).map(({ qualificationNumber, type }) => ({
          deviceTypeId: getDeviceType(type)!.id,
          qualificationNumber,
        })),
        people: mappedPeople,
        includedInStatistics,
        inKepHashArchive,
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Organizácia bola vytvorená',
          message: `Organizácia ${organizationName} bola úspešne vytvorená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (ORGANIZATION_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_ORGANIZATION_PAGE_PATH.insert({ organizationId: response.organizationId }));
        } else if (ORGANIZATION_REDIRECT_AFTER_SAVE === 'list') {
          navigate(ORGANIZATIONS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie novej organizácie"
      breadcrumbs={[
        { title: 'Organizácie', link: ORGANIZATIONS_PAGE_PATH.original },
        { title: 'Pridanie novej organizácie' },
      ]}
    >
      <OrganizationForm
        onSubmit={onSubmit}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
      />
    </DashboardLayout>
  );
}
