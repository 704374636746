import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthGetDepartmentsWithPermissionPathParameters } from 'api/actions/auth-get-departments-with-permission/auth-get-departments-with-permission-path-parameters';
import { AuthGetDepartmentsWithPermissionResponse } from 'api/actions/auth-get-departments-with-permission/auth-get-departments-with-permission-response';

type AuthGetDepartmentsWithPermissionQueryParameters = Record<string, any>;

/**
 * The path of the AuthGetDepartmentsWithPermission action.
 */
export const authGetDepartmentsWithPermissionActionPath = new SweetPath<
  keyof AuthGetDepartmentsWithPermissionPathParameters
>('/api/auth/departments-with-permission/{permissionSlug}', { path: '{param}' });

/**
 * Creates a contextualized AuthGetDepartmentsWithPermission actions.
 */
export default function createAuthGetDepartmentsWithPermissionAction(axios: AxiosInstance) {
  return function authGetDepartmentsWithPermissionAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: AuthGetDepartmentsWithPermissionPathParameters;
    query?: AuthGetDepartmentsWithPermissionQueryParameters;
  }) {
    const path = authGetDepartmentsWithPermissionActionPath.insert(parameters);

    return axios.get<AuthGetDepartmentsWithPermissionResponse, AuthGetDepartmentsWithPermissionResponse>(path, {
      params: query,
      headers,
    });
  };
}
