import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceMarkFaultFixedPayload } from 'api/actions/device-mark-fault-fixed/device-mark-fault-fixed-payload';
import { DeviceMarkFaultFixedPathParameters } from 'api/actions/device-mark-fault-fixed/device-mark-fault-fixed-path-parameters';
import { DeviceMarkFaultFixedResponse } from 'api/actions/device-mark-fault-fixed/device-mark-fault-fixed-response';

type DeviceMarkFaultFixedQueryParameters = Record<string, any>;

/**
 * The path of the DeviceMarkFaultFixed action.
 */
export const deviceMarkFaultFixedActionPath = new SweetPath<keyof DeviceMarkFaultFixedPathParameters>(
  '/api/device/faults/{deviceId}/mark-fixed/{faultId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceMarkFaultFixed actions.
 */
export default function createDeviceMarkFaultFixedAction(axios: AxiosInstance) {
  return function deviceMarkFaultFixedAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceMarkFaultFixedPathParameters;
    query?: DeviceMarkFaultFixedQueryParameters;
    payload: DeviceMarkFaultFixedPayload;
  }) {
    const path = deviceMarkFaultFixedActionPath.insert(parameters);

    return axios.put<DeviceMarkFaultFixedResponse, DeviceMarkFaultFixedResponse, DeviceMarkFaultFixedPayload>(
      path,
      payload
    );
  };
}
