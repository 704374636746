import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserUpdateNotificationSettingsPayload } from 'api/actions/user-update-notification-settings/user-update-notification-settings-payload';
import { UserUpdateNotificationSettingsPathParameters } from 'api/actions/user-update-notification-settings/user-update-notification-settings-path-parameters';
import { UserUpdateNotificationSettingsResponse } from 'api/actions/user-update-notification-settings/user-update-notification-settings-response';

type UserUpdateNotificationSettingsQueryParameters = Record<string, any>;

/**
 * The path of the UserUpdateNotificationSettings action.
 */
export const userUpdateNotificationSettingsActionPath = new SweetPath<
  keyof UserUpdateNotificationSettingsPathParameters
>('/api/user/notification-settings/{userId}', { path: '{param}' });

/**
 * Creates a contextualized UserUpdateNotificationSettings actions.
 */
export default function createUserUpdateNotificationSettingsAction(axios: AxiosInstance) {
  return function userUpdateNotificationSettingsAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserUpdateNotificationSettingsPathParameters;
    query?: UserUpdateNotificationSettingsQueryParameters;
    payload: UserUpdateNotificationSettingsPayload;
  }) {
    const path = userUpdateNotificationSettingsActionPath.insert(parameters);

    return axios.post<
      UserUpdateNotificationSettingsResponse,
      UserUpdateNotificationSettingsResponse,
      UserUpdateNotificationSettingsPayload
    >(path, payload);
  };
}
