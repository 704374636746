import { noop } from 'lodash';
import { createContext, useCallback, useContext, useState } from 'react';
import SignRevisionModal from 'components/modals/sign-revision-modal/SignRevisionModal';
import { nanoid } from 'nanoid';

/**
 * Parameters of the SignRevisionModal function.
 */
export interface ISignRevisionModalParams {
  revisionId: number;
  message: string | JSX.Element;
  onSign: ({ deadline }: { deadline: string }) => void;
}

/**
 * SignRevisionModal function.
 */
export type ISignRevisionModal = (params: ISignRevisionModalParams) => void;

/**
 * Typedef for the SignRevisionModalProvider context.
 */
export interface ISignRevisionModalContext {
  signRevisionModal: ISignRevisionModal;
}

/**
 * The signRevisionModal context.
 */
const SignRevisionModalContext = createContext<ISignRevisionModalContext>(undefined!);

/**
 * Provides the SignRevisionModalContext.
 */
export default function SignRevisionModalProvider({ children }: { children: React.ReactNode }) {
  const [opened, setOpened] = useState(false);
  const [key, setKey] = useState(nanoid);

  const [params, setParams] = useState<ISignRevisionModalParams>({
    revisionId: 0,
    message: '',
    onSign: noop,
  });

  const signRevisionModal: ISignRevisionModal = useCallback((params) => {
    setParams(params);
    setOpened(true);
    setKey(nanoid());
  }, []);

  return (
    <SignRevisionModalContext.Provider value={{ signRevisionModal }}>
      {children}
      <SignRevisionModal {...params} key={key} opened={opened} onClose={() => setOpened(false)} />
    </SignRevisionModalContext.Provider>
  );
}

/**
 * Uses the SignRevisionModalContext.
 */
export function useSignRevisionModal() {
  return useContext(SignRevisionModalContext);
}
