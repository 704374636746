import { Button, Menu, SimpleGrid } from '@mantine/core';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconOmega } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import { Fragment } from 'react';

const SYMBOLS = [
  {
    group: 'Matematické symboly',
    key: 'math',
    symbols: [
      { symbol: '+', key: 'plus', label: 'Plus' },
      { symbol: '−', key: 'minus', label: 'Mínus' },
      { symbol: '×', key: 'times', label: 'Krát' },
      { symbol: '÷', key: 'divide', label: 'Delené' },
      { symbol: '=', key: 'equals', label: 'Rovná sa' },
      { symbol: '≠', key: 'not-equals', label: 'Nerovná sa' },
      { symbol: '≈', key: 'approx', label: 'Približne' },
      { symbol: '≤', key: 'less-than-equal', label: 'Menšie alebo rovné' },
      { symbol: '≥', key: 'greater-than-equal', label: 'Väčšie alebo rovné' },
      { symbol: '∞', key: 'infinity', label: 'Nekonečno' },
      { symbol: '∑', key: 'sum', label: 'Súčet' },
      { symbol: '∇', key: 'nabla', label: 'Nabla' },
      { symbol: '∆', key: 'delta', label: 'Delta' },
    ],
  },
];

/**
 * Control used to select a special symbol to insert into the template.
 */
export default function InsertSpecialSymbol({ kind }: { kind: DropdownControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <DropdownControl kind={kind} label="Špeciálne znaky" icon={<IconOmega />} closeOnClick>
      {SYMBOLS.map(({ group, symbols, key }) => (
        <Fragment key={key}>
          <Menu.Label>{group}</Menu.Label>
          <SimpleGrid cols={8} spacing={4} p={8} pt={0}>
            {symbols.map(({ key, label, symbol }) => (
              <Button
                key={key}
                title={label}
                variant="subtle-gray"
                size="xs"
                onClick={() => editor?.chain().focus().insertContent(symbol).run()}
              >
                {symbol}
              </Button>
            ))}
          </SimpleGrid>
        </Fragment>
      ))}
    </DropdownControl>
  );
}
