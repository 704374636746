import { ActionIcon, Box, Button, Menu, Stack } from '@mantine/core';
import { IconUserShare } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useConfirm } from 'components/modals/message/MessageProvider';
import P1Medium from 'components/typography/P1Medium';
import P4Regular from 'components/typography/P4Regular';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { WELCOME_PAGE_PATH } from 'routes/paths';

/**
 * Displays the impersonating user's name and a button to stop impersonating.
 */
export default function NavbarImpersonating({ isCollapsed }: { isCollapsed: boolean }) {
  const navigate = useNavigate();
  const { confirm } = useConfirm();
  const { impersonate, originalSession, setImpersonate } = useApi();

  /**
   * Stops impersonating.
   */
  const stopImpersonating = useCallback(() => {
    confirm({
      title: 'Ukončiť vystupovanie',
      content: `Naozaj chcete ukončiť vystupovanie ako ${impersonate?.fullName}? Budete prihlásený ako pôvodný používateľ a presmerovaný na úvodnú stránku.`,
      onConfirm: () => {
        setImpersonate(undefined);
        navigate(WELCOME_PAGE_PATH.original);
      },
    });
  }, [confirm, navigate, setImpersonate, impersonate]);

  if (!impersonate) {
    return <></>;
  }

  const navbarImpersonatingInner = (
    <Stack spacing={8} p={12}>
      <Stack spacing={8} bg="blue.0" px={12} py={8}>
        <Stack spacing={8}>
          <P4Regular color="gray.6">Prihlásený ako:</P4Regular>
          <Box>
            <P1Medium color="gray.7">{originalSession.fullName}</P1Medium>
            <P4Regular color="gray.6">{originalSession.organizationName}</P4Regular>
          </Box>
        </Stack>
        <Button size="sm" variant="secondary" onClick={stopImpersonating}>
          Návrat do účtu
        </Button>
      </Stack>
    </Stack>
  );

  return !isCollapsed ? (
    navbarImpersonatingInner
  ) : (
    <Box bg="blue.0" p={8} mt={8}>
      <Menu position="right-end" offset={{ mainAxis: 22 }}>
        <Menu.Target>
          <ActionIcon variant="subtle" size="md">
            <IconUserShare stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Box w={256}>{navbarImpersonatingInner}</Box>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
}
