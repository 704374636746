import { Box, SimpleGrid } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import { useEffect, useState } from 'react';
import { useApi } from 'api/api-context';
import { EvidenceField } from 'components/forms/device-subtype/EvidenceFieldRow';
import panic from 'errors/panic';
import { intersection, isEmpty } from 'lodash';
import DeviceEvidenceField, { DeviceEvidenceFieldType } from 'components/forms/device/DeviceEvidenceField';
import P2Regular from 'components/typography/P2Regular';

interface DeviceEvidenceFieldsProps {
  deviceSubtypeId: number;
  departmentId: number;
  form: UseFormReturnType<DeviceFormData>;
}

/**
 * Downloads the evidence fields for the subtype.
 * Updates the form value, if necessary and renders the evidence fields.
 */
export default function DeviceEvidenceFields({ deviceSubtypeId, departmentId, form }: DeviceEvidenceFieldsProps) {
  const { getAction } = useApi();
  const [loading, setLoading] = useState(false);
  const [evidenceFields, setEvidenceFields] = useState<EvidenceField[]>([]);

  useEffect(() => {
    setLoading(true);
    const evidenceFieldsGetListAction = getAction('DeviceListEvidenceFields');

    evidenceFieldsGetListAction({
      parameters: { departmentId: String(departmentId), deviceSubtypeId: String(deviceSubtypeId) },
    })
      .then((response) => {
        const fields = response.map((field) => ({
          ...field,
          fieldType: field.fieldType as EvidenceField['fieldType'],
        }));

        setEvidenceFields(fields);

        const evidenceFieldIds = fields.map((field) => field.evidenceFieldId!);

        const formEvidenceFields = Object.keys(form.values.evidenceFields).map(Number);

        const sharedFields = intersection(evidenceFieldIds, formEvidenceFields);

        // no value for evidence fields
        // the evidence fields are different = the subtype has changed
        if (isEmpty(form.values.evidenceFields) || sharedFields.length !== formEvidenceFields.length) {
          const reduced = response.reduce((acc, field) => {
            acc[field.evidenceFieldId!] = {
              ...field,
              fieldValue: field.fieldType === 'text' ? '' : undefined,
              fieldNumberValue: undefined,
              fieldDateValue: undefined,
            };

            return acc;
          }, {} as { [evidenceFieldId: number]: DeviceEvidenceFieldType });

          form.setFieldValue('evidenceFields', reduced);
        }
      })
      .catch(panic)
      .finally(() => {
        setLoading(false);
      });
  }, [deviceSubtypeId, departmentId]);

  return !loading && isEmpty(evidenceFields) ? (
    <P2Regular>Pre typ zariadenia neexistujú evidenčné polia.</P2Regular>
  ) : (
    <SimpleGrid cols={2} spacing={40}>
      {evidenceFields.map((field) => (
        <Box key={field.evidenceFieldId}>
          <DeviceEvidenceField form={form} {...field} />
        </Box>
      ))}
    </SimpleGrid>
  );
}
