import { IFormInputSpecText } from 'pages/revisions-module/template-editor/editors/form/types';
import { TextInput } from '@mantine/core';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';

/**
 * Text input for the revision form.
 */
export default function RevisionFieldText({ spec, name }: RevisionFieldProps<IFormInputSpecText>) {
  const { form } = useFillOutRevisionDataProvider();

  return (
    <TextInput
      size="lg"
      name={name}
      label={spec.label}
      required={spec.required}
      readOnly={spec.readOnly}
      {...form.getInputProps(name)}
    />
  );
}
