import { Box, Tooltip } from '@mantine/core';
import { CSSProperties, useMemo } from 'react';

/**
 * Parameters of the excerpt component.
 */
export interface ExcerptProps {
  text: string;
  length?: number;
  suffix?: string;
  multilineThreshold?: number;
  multilineWidth?: number;
  wrapNewLines?: boolean;
}

/**
 * Displays an excerpt of the given text.
 */
export default function Excerpt({
  text,
  length = 100,
  suffix = '',
  multilineThreshold = 70,
  multilineWidth = 350,
  wrapNewLines = false,
}: ExcerptProps) {
  const label = useMemo(() => `${text}${suffix}`, [text, suffix]);
  const excerpt = useMemo(() => text.substring(0, length), [text, length]);
  const trimmedExcerpt = useMemo(() => excerpt.trim(), [excerpt]);
  const isTruncated = text.length > length;
  const isMultiline = text.length > multilineThreshold;
  const tooltipWidth = isMultiline ? multilineWidth : undefined;
  const whitespaceStyle: CSSProperties = { whiteSpace: wrapNewLines ? 'pre-wrap' : undefined };

  if (isTruncated) {
    return (
      <Tooltip
        style={whitespaceStyle}
        withArrow
        withinPortal
        label={label}
        multiline={isMultiline}
        width={tooltipWidth}
      >
        <Box style={whitespaceStyle} sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>
          {trimmedExcerpt}&hellip;{suffix}
        </Box>
      </Tooltip>
    );
  }

  return <Box style={whitespaceStyle}>{excerpt}</Box>;
}
