import { useApi } from 'api/api-context';
import PredefinedDataTable from 'components/tables/predefined/PreDefinedDataTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import {
  ADD_GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH,
  SETTINGS_PAGE_PATH,
} from 'routes/paths';

/**
 * Page used to display global pre-defined data.
 *
 * - {@link N/A Figma Design}
 * - {@link https://www.notion.so/Pre-defined-Data-List-Global-Pre-defined-Data-2c733e5a7f694b8082c6fddd21892f49?pvs=4 Notion Page}
 */
export default function GlobalPredefinedDataPage() {
  const [search, setSearch] = useState('');
  const { getAction } = useApi();

  return (
    <DashboardLayout
      title="Globálne predvolené údaje"
      breadcrumbs={[{ title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original }, { title: 'Globálne predvolené údaje' }]}
      height="screen"
      withSearch
      onSearch={setSearch}
    >
      <PredefinedDataTable
        action={getAction('PredefinedDataListGlobal')}
        addPath={ADD_GLOBAL_PREDEFINED_DATA_PAGE_PATH.original}
        editPath={EDIT_GLOBAL_PREDEFINED_DATA_PAGE_PATH}
        tableType="global"
        search={search}
      />
    </DashboardLayout>
  );
}
