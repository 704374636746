import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import RevisionForm, { RevisionFormData, revisionFormInitialValues } from 'components/forms/revision/RevisionForm';
import { REVISION_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EDIT_REVISION_PAGE_PATH, REVISIONS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to create a new revision.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A14718 Figma Design}
 * - {@link https://www.notion.so/Revisions-Create-New-Revision-584249b54d264591af493f80b23856b3?pvs=4 Notion Page}
 */
export default function AddRevisionPage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const [searchParams] = useSearchParams();
  const [initialValues] = useState(() => ({
    ...revisionFormInitialValues,
    revisionName: searchParams.get('revisionName') ?? '',
    deadline: searchParams.get('deadline') ?? '',
    organizationId: searchParams.get('organizationId') ?? '',
    departmentId: searchParams.get('departmentId') ?? '',
    deviceTypeId: searchParams.get('deviceTypeId') ?? '',
    technicianId: searchParams.get('technicianId') ?? '',
    devices:
      searchParams.get('deviceId') && searchParams.get('termId')
        ? {
            [String(searchParams.get('deviceId'))]: {
              deviceName: searchParams.get('deviceName') ?? '',
              termId: searchParams.get('termId') ?? '',
              deviceSubtypeId: Number(searchParams.get('deviceSubtypeId')) ?? 0,
              deviceSubtypeName: searchParams.get('deviceSubtypeName') ?? '',
              checked: true,
              manufacturer: searchParams.get('manufacturer') ?? '',
              type: searchParams.get('deviceSubtypeName') ?? '',
              serialNumber: searchParams.get('serialNumber') ?? '',
              manufactured: Number(searchParams.get('manufactured')) ?? 0,
              revisionPlan: [
                {
                  revisionPlanId: Number(searchParams.get('termId')) ?? 0,
                  description: searchParams.get('revisionPlanDescription') ?? '',
                  date: '', // this is not needed at the moment
                  revisionPeriod: Number(searchParams.get('revisionPeriod')) ?? 1,
                  neverExecuted: searchParams.get('neverExecuted') ? Boolean(searchParams.get('neverExecuted')) : false,
                },
              ],
            },
          }
        : {},
  }));

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    revisionName,
    deadline,
    organizationId,
    departmentId,
    deviceTypeId,
    devices,
    technicianId,
  }: RevisionFormData) {
    if (!deadline) {
      throw new Error('Deadline is required');
    }

    const mappedDevices = Object.keys(devices)
      .map((deviceId) => ({
        deviceId: Number(deviceId),
        deviceSubtypeId: devices[deviceId].deviceSubtypeId,
        checked: devices[deviceId].checked,
        termId:
          devices[deviceId].termId && devices[deviceId].termId !== '' ? Number(devices[deviceId].termId) : undefined,
      }))
      .filter((device) => device.checked);

    const revisionCreateAction = getAction('RevisionCreate');

    return revisionCreateAction({
      payload: {
        revisionName,
        deadline,
        organizationId: Number(organizationId),
        departmentId: Number(departmentId),
        deviceTypeId: Number(deviceTypeId),
        devices: mappedDevices,
        technicianId: Number(technicianId),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Revízna správa bola vytvorená',
          message: `Revízna správa ${revisionName} bola úspešne vytvorená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (REVISION_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_REVISION_PAGE_PATH.insert({ revisionId: response.revisionId }));
        } else if (REVISION_REDIRECT_AFTER_SAVE === 'list') {
          navigate(REVISIONS_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      breadcrumbs={[
        { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
        { title: 'Vytvorenie revíznej správy' },
      ]}
      title="Vytvorenie revíznej správy"
    >
      <RevisionForm
        context="create"
        onSubmit={onSubmit}
        initialValues={initialValues}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke={1.5} size={24} />}
        readOnly={{
          organizationId: searchParams.get('organizationId') ? true : undefined,
          departmentId: searchParams.get('departmentId') ? true : undefined,
          deviceTypeId: searchParams.get('deviceTypeId') ? true : undefined,
        }}
        noValueChange={{
          organizationId: searchParams.get('organizationId') ? true : undefined,
          departmentId: searchParams.get('departmentId') ? true : undefined,
          deviceTypeId: searchParams.get('deviceTypeId') ? true : undefined,
        }}
      />
    </DashboardLayout>
  );
}
