import { ActionIcon, Center, Flex, Group, Portal, Stack, useMantineTheme } from '@mantine/core';
import { noop } from 'lodash';
import { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import useImmutableList from 'hooks/use-immutable-list';
import { IconChevronLeft, IconChevronRight, IconX } from '@tabler/icons-react';
import ImageDisplay from 'components/modals/slider/ImageDisplay';
import { useApi } from 'api/api-context';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';
import P1Medium from 'components/typography/P1Medium';
import Moveable from 'react-moveable';
import PDFDisplay from 'components/modals/slider/PDFDisplay';
import { useDisclosure } from '@mantine/hooks';

type Image = {
  fileId: string;
  ready: boolean;
  metadata?: any; // FileMetadata
};

/**
 * Initializes the images.
 */
const initImages = (fileIds: string[]) => fileIds.map((fileId) => ({ fileId, ready: false } as Image));

interface SliderProps {
  initialImageId: string;
  opened: boolean;
  onClose?: () => void;
  fileIds?: string[];
  hideControls?: boolean;
}

/**
 * The slider for images.
 */
export default function Slider({
  initialImageId,
  opened,
  onClose = noop,
  fileIds = [],
  hideControls = false,
}: SliderProps) {
  const theme = useMantineTheme();
  const { getAction } = useApi();
  const { getFileMetadata } = useFileManager();
  const ref = useRef<HTMLDivElement>(null);

  const [isResizing, { open: startResizing, close: stopResizing }] = useDisclosure(false);

  const [images, { update: updateImage, remove: removeImage }] = useImmutableList('fileId', () => initImages(fileIds));
  const [currImageId, setCurrImageId] = useState(initialImageId);
  const [mediaType, setMediaType] = useState('');

  const modalWidth = useMemo(() => (mediaType === 'pdf' ? 'calc(100vw - 200px)' : 600), [mediaType]);
  const modalHeight = useMemo(() => (mediaType === 'pdf' ? 'calc(100vh - 40px)' : 500), [mediaType]);

  const allImagesReady = useMemo(() => images.every(({ ready }) => ready), [images]);
  const currImage = useMemo(() => images.find(({ fileId }) => fileId === currImageId), [images, currImageId]);
  const currImageIndex = useMemo(() => images.findIndex(({ fileId }) => fileId === currImageId), [images, currImageId]);
  const currImageName = useMemo(() => currImage?.metadata?.basename, [currImage]);

  const nextImageIndex = useMemo(() => Math.min(currImageIndex + 1, images.length - 1), [currImageIndex, images]);
  const prevImageIndex = useMemo(() => Math.max(currImageIndex - 1, 0), [currImageIndex]);
  const nextImageId = useMemo(() => images[nextImageIndex]?.fileId, [images, nextImageIndex]);
  const prevImageId = useMemo(() => images[prevImageIndex]?.fileId, [images, prevImageIndex]);
  const isLeftButtonDisabled = useMemo(() => currImageIndex === 0, [currImageIndex]);
  const isRightButtonDisabled = useMemo(() => currImageIndex === images.length - 1, [currImageIndex, images]);

  const goToPrevImage = useCallback(() => setCurrImageId(prevImageId), [prevImageId]);
  const goToNextImage = useCallback(() => setCurrImageId(nextImageId), [nextImageId]);

  const fetchMetadata = useCallback(
    async (fileId: string) => {
      const metadata = await getFileMetadata({ fileId });

      if (metadata.mimeType.startsWith('image/') || metadata.mimeType === 'application/pdf') {
        updateImage(fileId, { metadata, ready: true });

        if (metadata.mimeType === 'application/pdf') {
          setMediaType('pdf');
        } else {
          setMediaType('image');
        }
      } else {
        removeImage(fileId);
      }
    },
    [getAction, getFileMetadata]
  );

  const initData = useCallback(async () => {
    const nonInitialAttachmentIds = fileIds.filter((id) => id !== initialImageId);

    await fetchMetadata(initialImageId);
    await Promise.all(nonInitialAttachmentIds.map(fetchMetadata));
  }, [initialImageId, fileIds, fetchMetadata]);

  useEffect(() => {
    if (opened) {
      initData().catch(panic);
    }
  }, [opened]);

  useEffect(() => {
    /** Handling keyboard navigation */
    const handleKeyboardNavigation = (event: any) => {
      if (!hideControls && opened && event.key === 'ArrowLeft') {
        goToPrevImage();
      } else if (!hideControls && opened && event.key === 'ArrowRight') {
        goToNextImage();
      }

      if (opened && event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [opened, goToPrevImage, goToNextImage]);

  if (!opened || !currImage || !currImage.ready) {
    return null;
  }

  return (
    <Portal>
      <Group
        hidden={opened}
        pos="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="#28293466"
        style={{ zIndex: 1000 }}
        position="center"
      >
        <Center
          w={modalWidth}
          h={modalHeight} // fixed value so that the blue dot stays in the bottom right corner
          miw={400}
          mih={300}
          pb={8}
          className="slider-target"
          ref={ref}
          bg="white"
          pos="relative"
          style={{ borderRadius: '4px', cursor: 'grab', overflow: 'hidden' }}
        >
          {/* close icon */}
          <Group
            position="apart"
            pos="absolute"
            w="100%"
            top={0}
            right={0}
            p={8}
            bg="white"
            style={{ zIndex: 2500, overflow: 'visible' }}
            className="group/close-icon"
          >
            <P1Medium pl={8} color="gray.8">
              {currImage.metadata.basename}
            </P1Medium>
            <ActionIcon variant="tertiary" onClick={onClose} size={40} p={8} className="cursor-pointer">
              <IconX color={theme.colors.gray[7]} stroke={1.5} size={16} />
            </ActionIcon>
          </Group>
          {/* the actual content */}
          <Stack spacing={0} pt={40} bg="white" w="100%" h="100%" align="center" style={{ borderRadius: '4px' }}>
            <Center w="100%" h={hideControls ? '100%' : 'calc(100% - 56px)'} p={8}>
              {mediaType === 'image' && <ImageDisplay fileId={currImageId} alt={currImageName} title={currImageName} />}
              {mediaType === 'pdf' && (
                <PDFDisplay fileId={currImageId} fileName={currImageName} isResizing={isResizing} />
              )}
            </Center>

            {/* controls */}
            {!hideControls && (
              <Flex justify="space-between" align="center" w="100%" p={8}>
                <ActionIcon
                  variant="tertiary"
                  style={{ border: `1px solid ${theme.colors.gray[isLeftButtonDisabled ? 1 : 2]}` }}
                  onClick={goToPrevImage}
                  disabled={isLeftButtonDisabled}
                >
                  <IconChevronLeft
                    stroke={1.5}
                    color={isLeftButtonDisabled ? theme.colors.gray[3] : theme.colors.gray[7]}
                  />
                </ActionIcon>

                {allImagesReady && (
                  <P1Medium align="center" color="gray.8">
                    {currImageIndex + 1} / {images.length}
                  </P1Medium>
                )}

                <ActionIcon
                  variant="tertiary"
                  style={{ border: `1px solid ${theme.colors.gray[isLeftButtonDisabled ? 1 : 2]}` }}
                  onClick={goToNextImage}
                  disabled={isRightButtonDisabled}
                >
                  <IconChevronRight
                    stroke={1.5}
                    color={isRightButtonDisabled ? theme.colors.gray[3] : theme.colors.gray[7]}
                  />
                </ActionIcon>
              </Flex>
            )}
          </Stack>
        </Center>
        <Moveable
          target={ref}
          origin={false} // hide red dot in the middle
          // allow drag
          draggable={true}
          throttleDrag={1}
          edgeDraggable={false}
          startDragRotate={0}
          throttleDragRotate={0}
          onDrag={(e) => {
            e.target.style.transform = e.transform;
            e.target.style.cursor = 'grabbing';
          }}
          onDragEnd={(e) => {
            e.target.style.cursor = 'grab';
          }}
          // allow resize
          resizable={true}
          keepRatio={false}
          throttleResize={1}
          renderDirections={['se']} // bottom right corner
          onResize={(e) => {
            e.target.style.width = `${e.width}px`;
            e.target.style.height = `${e.height}px`;
            e.target.style.transform = e.drag.transform;
          }}
          onResizeStart={startResizing}
          onResizeEnd={stopResizing}
        />
      </Group>
    </Portal>
  );
}
