import { useApi } from 'api/api-context';
import { DataSelectInheritedProps, DataSelect } from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

interface DepartmentContactPersonSelectProp {
  organizationId: number;
}

/**
 * Fetches users from API.
 */
function useDepartmentContactPersonSelect({ organizationId }: DepartmentContactPersonSelectProp) {
  const { getAction } = useApi();

  const fetchUsers = useCallback(async () => {
    const action = getAction('DepartmentGetContactPersons');

    const users = await action({ parameters: { organizationId: String(organizationId) } });

    return users.map((user) => ({
      label: user.fullName,
      value: user.userId,
    }));
  }, [getAction, organizationId]);

  return { fetchUsers };
}

type DepartmentContactPersonSelectProps = DataSelectInheritedProps & DepartmentContactPersonSelectProp;

/**
 * Select input for users.
 */
export function DepartmentContactPersonSelect({ organizationId, ...props }: DepartmentContactPersonSelectProps) {
  const key = useMemo(() => nanoid(), [organizationId]);
  const { fetchUsers } = useDepartmentContactPersonSelect({ organizationId });

  return <DataSelect key={key} action={fetchUsers} labelProp="label" valueProp="value" {...props} />;
}
