import { Extension } from '@tiptap/core';

export const Tabulator = Extension.create({
  addKeyboardShortcuts() {
    const tabulator = '\u0009';

    return {
      Tab: () => {
        if (!this.editor.isActive('table')) {
          return this.editor.chain().focus().insertContent(tabulator).run();
        }

        return false;
      },
    };
  },
});
