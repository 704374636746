import { AuthListPermissionTemplatesResponse } from 'api/actions/auth-list-permission-templates/auth-list-permission-templates-response';
import { AuthListPermissionsResponse } from 'api/actions/auth-list-permissions/auth-list-permissions-response';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export type Permission = AuthListPermissionsResponse[number];

export type PermissionTemplate = AuthListPermissionTemplatesResponse[number];

/**
 * Permission Data context.
 */
const PermissionDataContext = createContext<{
  permissions: Permission[];
  permissionTemplates: PermissionTemplate[];
  organizationPermissions: Permission[];
  departmentPermissions: Permission[];
  organizationPermissionTemplates: PermissionTemplate[];
  departmentPermissionTemplates: PermissionTemplate[];
  getPermission: (idOrSlug: number | string) => Permission | undefined;
  getPermissionTemplate: (idOrSlug: number | string) => PermissionTemplate | undefined;
  getDefaultPermissionTemplate: () => PermissionTemplate;
}>(undefined!);

/**
 * Uses the Permission Data context.
 */
export function usePermissionDataProvider() {
  return useContext(PermissionDataContext);
}

/**
 * Loads the permissions from the API.
 */
export function PermissionDataProvider({ children }: { children: React.ReactNode }) {
  const { ready, jwt, getAction } = useApi();
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [permissionTemplates, setPermissionTemplates] = useState<PermissionTemplate[]>([]);

  const organizationPermissions = useMemo(() => permissions.filter((p) => p.isOrganization), [permissions]);

  const departmentPermissions = useMemo(() => permissions.filter((p) => p.isDepartment), [permissions]);

  const organizationPermissionTemplates = useMemo(
    () => permissionTemplates.filter((t) => t.isOrganization),
    [permissionTemplates]
  );
  const departmentPermissionTemplates = useMemo(
    () => permissionTemplates.filter((t) => t.isDepartment),
    [permissionTemplates]
  );

  /**
   * Returns the permission by its ID or slug.
   */
  const getPermission = useCallback(
    (idOrSlug: number | string) =>
      permissions.find((permission) => permission.permissionId === idOrSlug || permission.slug === idOrSlug),
    [permissions]
  );

  const getPermissionTemplate = useCallback(
    (idOrSlug: number | string) =>
      permissionTemplates.find(
        (permissionTemplate: any) =>
          permissionTemplate.permissionTemplateId === idOrSlug || permissionTemplate.slug === idOrSlug
      ),
    [permissionTemplates]
  );

  const getDefaultPermissionTemplate = useCallback(
    () => permissionTemplates.find(({ slug }) => slug === 'no-template')!,
    [permissionTemplates]
  );

  useEffect(() => {
    if (ready && jwt) {
      const permissionsGetListAction = getAction('AuthListPermissions');
      permissionsGetListAction()
        .then((permissions) => {
          setPermissions(permissions);
        })
        .catch(panic);

      const permissionTemplatesGetListAction = getAction('AuthListPermissionTemplates');
      permissionTemplatesGetListAction()
        .then((permissionTemplates) => {
          setPermissionTemplates(permissionTemplates);
        })
        .catch(panic);
    }
  }, [ready, jwt]);

  return (
    <PermissionDataContext.Provider
      value={{
        permissions,
        permissionTemplates,
        organizationPermissions,
        departmentPermissions,
        organizationPermissionTemplates,
        departmentPermissionTemplates,
        getPermission,
        getPermissionTemplate,
        getDefaultPermissionTemplate,
      }}
    >
      {children}
    </PermissionDataContext.Provider>
  );
}
