import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import { EvidenceField } from 'components/forms/device-subtype/EvidenceFieldRow';
import DefineEvidenceFieldModal from 'components/forms/device-subtype/DefineEvidenceFieldModal';

/**
 * Callback for defining the evidence field.
 */
type OnDefineCallback = (value: EvidenceField) => void;

/**
 * Parameters of the defineEvidenceField function.
 */
interface DefineEvidenceFieldParams {
  title: string;
  type: 'create' | 'edit';
  initialValues?: EvidenceField;
  onDefine: OnDefineCallback;
}

/**
 * Function for defining evidence field.
 */
type DefineEvidenceField = (params: DefineEvidenceFieldParams) => void;

/**
 * Interface of the DefineEvidenceFieldContext.
 */
interface IDefineEvidenceFieldContext {
  defineEvidenceField: DefineEvidenceField;
}

/**
 * Context for defining evidence field.
 */
const DefineEvidenceFieldContext = createContext<IDefineEvidenceFieldContext>(undefined!);

/**
 * Provider for defining evidence field.
 */
export function DefineEvidenceFieldProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<DefineEvidenceFieldParams | undefined>(undefined);

  /**
   * Opens the modal for defining evidence field.
   */
  const defineEvidenceField: DefineEvidenceField = (params) => {
    setParams(params);
    open();
  };

  return (
    <DefineEvidenceFieldContext.Provider value={{ defineEvidenceField }}>
      {children}
      {opened && <DefineEvidenceFieldModal opened onClose={close} {...params!} />}
    </DefineEvidenceFieldContext.Provider>
  );
}

/**
 * Hook for defining evidence field.
 */
export function useDefineEvidenceField() {
  return useContext(DefineEvidenceFieldContext);
}
