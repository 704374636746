import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OrganizationSetStatusPayload } from 'api/actions/organization-set-status/organization-set-status-payload';
import { OrganizationSetStatusPathParameters } from 'api/actions/organization-set-status/organization-set-status-path-parameters';
import { OrganizationSetStatusResponse } from 'api/actions/organization-set-status/organization-set-status-response';

type OrganizationSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the OrganizationSetStatus action.
 */
export const organizationSetStatusActionPath = new SweetPath<keyof OrganizationSetStatusPathParameters>(
  '/api/organization/status/{organizationId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized OrganizationSetStatus actions.
 */
export default function createOrganizationSetStatusAction(axios: AxiosInstance) {
  return function organizationSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: OrganizationSetStatusPathParameters;
    query?: OrganizationSetStatusQueryParameters;
    payload: OrganizationSetStatusPayload;
  }) {
    const path = organizationSetStatusActionPath.insert(parameters);

    return axios.put<OrganizationSetStatusResponse, OrganizationSetStatusResponse, OrganizationSetStatusPayload>(
      path,
      payload
    );
  };
}
