import { Box, Group, MantineTheme, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import {
  IconPencil,
  IconUserCheck,
  IconTool,
  IconCheck,
  IconX,
  IconUserX,
  IconProgressCheck,
  IconTrashX,
  IconSignature,
  IconCertificate,
} from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';
import { FEATURE_TOGGLE_KEP } from 'env';

/**
 * Parameters of the StatusIcon component.
 */
interface StatusIconProps {
  statusId: number;
  textColorOverride?: string;
  iconOnly?: boolean;
  textOnly?: boolean;
}

/**
 * Map from status id to icon.
 */
const STATUS_ICON_MAP: Record<number, (theme: MantineTheme) => React.ReactNode> = {
  1: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.gray[2]}>
      <IconPencil size={16} color={theme.colors.gray[7]} />
    </ThemeIcon>
  ),
  2: () => (
    <ThemeIcon radius="lg" size={24} color="#E4E8F8">
      <IconUserCheck size={16} color="#2841A4" />
    </ThemeIcon>
  ),
  3: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.red[1]}>
      <IconUserX size={16} color={theme.colors.red[9]} />
    </ThemeIcon>
  ),
  4: () => (
    <ThemeIcon radius="lg" size={24} color="#F3E5D0">
      <IconTool size={16} color="#B2751A" />
    </ThemeIcon>
  ),
  5: () => (
    <ThemeIcon radius="lg" size={24} color="#EDEFD8">
      <IconProgressCheck size={16} color="#727920" />
    </ThemeIcon>
  ),
  6: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.red[0]}>
      <IconX size={16} color={theme.colors.red[9]} />
    </ThemeIcon>
  ),
  7: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.green[1]}>
      <IconCheck size={16} color={theme.colors.green[9]} />
    </ThemeIcon>
  ),
  8: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.green[9]}>
      <IconCheck size={16} color={theme.colors.green[0]} />
    </ThemeIcon>
  ),
  9: (theme) =>
    FEATURE_TOGGLE_KEP ? (
      <ThemeIcon radius="lg" size={24} color="#4B118C">
        <IconCertificate size={16} color={theme.white} />
      </ThemeIcon>
    ) : (
      // use icon for status 8
      <ThemeIcon radius="lg" size={24} color={theme.colors.green[9]}>
        <IconCheck size={16} color={theme.colors.green[0]} />
      </ThemeIcon>
    ),
  10: (theme) =>
    FEATURE_TOGGLE_KEP ? (
      <ThemeIcon radius="lg" size={24} color={theme.colors.blue[5]}>
        <IconSignature size={16} color={theme.white} />
      </ThemeIcon>
    ) : (
      // use icon for status 8
      <ThemeIcon radius="lg" size={24} color={theme.colors.green[9]}>
        <IconCheck size={16} color={theme.colors.green[0]} />
      </ThemeIcon>
    ),
  999: (theme) => (
    <ThemeIcon radius="lg" size={24} color={theme.colors.red[1]}>
      <IconTrashX size={16} color={theme.colors.red[9]} />
    </ThemeIcon>
  ),
};

/**
 * Map from status id to text color.
 */
const STATUS_TEXT_COLOR_MAP: Record<number, string | undefined> = {
  1: 'gray.7',
  2: '#2841A4',
  3: 'red.9',
  4: '#B2751A',
  5: '#727920',
  6: 'red.9',
  7: 'green.9',
  8: 'green.9',
  9: FEATURE_TOGGLE_KEP ? '#4B118C' : 'green.9',
  10: FEATURE_TOGGLE_KEP ? 'blue.9' : 'green.9',
  999: 'red.9',
};

/**
 * Map from status id to text
 */
export const STATUS_TEXT_MAP: Record<number, string | undefined> = {
  1: 'Vytvorená',
  2: 'Potvrdená revíznym technikom',
  3: 'Zamietnutá revíznym technikom',
  4: 'Rozpracovaná',
  5: 'Ukončená – čakajúca na schválenie',
  6: 'Zamietnutá',
  7: 'Schválená – čakajúca na prebratie',
  8: 'Prebratá',
  9: FEATURE_TOGGLE_KEP ? 'Na elektronickom podpise' : 'Prebratá',
  10: FEATURE_TOGGLE_KEP ? 'Podpísaná s KEP' : 'Prebratá',
  999: 'Vymazaná',
};

/**
 * Renders an icon based on the provided revision status ID.
 */
export default function RevisionStatusIcon({
  statusId,
  textColorOverride,
  textOnly = false,
  iconOnly = false,
}: StatusIconProps) {
  const theme = useMantineTheme();
  const icon = STATUS_ICON_MAP[statusId](theme);
  const textColor = STATUS_TEXT_COLOR_MAP[statusId];
  const text = STATUS_TEXT_MAP[statusId];

  if (iconOnly) {
    return (
      <Tooltip withinPortal label={text}>
        <Box>{icon}</Box>
      </Tooltip>
    );
  }

  return (
    <Group spacing={8} align="center" noWrap>
      {textOnly ? null : icon}
      <P2Regular lh={1.4} color={textColorOverride ?? textColor}>
        {text}
      </P2Regular>
    </Group>
  );
}
