import { MantineThemeOverride, rem } from '@mantine/core';
import { P2Regular, P3Regular, P4Medium } from 'theme/typography';

export type InputWrapperTheme = NonNullable<MantineThemeOverride['components']>['InputWrapper'];

export const InputWrapper: InputWrapperTheme = {
  sizes: {
    lg: () => ({
      label: {
        ...P2Regular,
      },
    }),
    md: () => ({
      label: {
        ...P2Regular,
      },
    }),
    sm: () => ({
      label: {
        ...P3Regular,
      },
    }),
  },
  styles: (theme) => ({
    label: {
      color: theme.colors.gray[6],
      marginBottom: rem(4),
    },
    error: {
      color: theme.colors.red[8],
      ...P4Medium,
    },
  }),
};
