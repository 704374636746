import { Box, Button, Divider, Group, LoadingOverlay, Stack } from '@mantine/core';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import H3Medium from 'components/typography/H3Medium';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { groupBy, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { DEVICES_PAGE_PATH, EDIT_DEVICE_PAGE_PATH } from 'routes/paths';
import FaultSeverityLabel from 'components/device/FaultSeverityLabel';
import { DeviceListFaultsResponse } from 'api/actions/device-list-faults/device-list-faults-response';
import P2Medium from 'components/typography/P2Medium';
import P3Regular from 'components/typography/P3Regular';
import { useFixDeviceFault } from 'components/modals/fix-device-fault/FixDeviceFaultProvider';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import { ROLE_ADMIN_ID } from 'model/Role';

/**
 * Page used to display device's faults.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=256%3A13142&mode=dev Figma Design}
 * - {@link https://www.notion.so/Devices-List-Faults-For-Device-fcc74793e8e54a4aa41412a62fa3082b?pvs=4 Notion Page}
 */
export default function DeviceFaultsPage() {
  const { deviceId } = useParams();
  const { getAction, roleId } = useApi();
  const { faultFix } = useFixDeviceFault();
  const [loadData, setLoadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DeviceListFaultsResponse | undefined>(undefined);
  const [canManageDevices, setCanManageDevices] = useState(roleId === ROLE_ADMIN_ID);

  const otherFaults = useMemo(() => {
    return data?.faults?.filter((fault) => !fault.revision);
  }, [data, loadData]);

  const revisionFaults = useMemo(() => {
    return groupBy(
      data?.faults?.filter((fault) => fault.revision),
      'revision.revisionId'
    );
  }, [data, loadData]);

  /**
   * Marks the fault as fixed in the DB.
   */
  function handleMarkFaultAsFixed({
    faultId,
    fixedAt,
    fixedNote,
  }: {
    faultId: number;
    fixedAt: string;
    fixedNote?: string;
  }) {
    const deviceFaultsFixAction = getAction('DeviceMarkFaultFixed');

    deviceFaultsFixAction({
      parameters: { deviceId: deviceId!, faultId: String(faultId) },
      payload: { fixedAt, fixedNote },
    })
      .then(() => {
        setLoadData(true);
        showNotification({
          title: 'Závada zariadenia bola vyriešená',
          message: `Závada zariadenia ${data?.deviceName ?? ''} bola označená vyriešená.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });
      })
      .catch(panic);
  }

  /**
   * Opens the modal for marking the device's fault as fixed.
   */
  function markFaultAsFixed(faultId: number) {
    faultFix({
      onFaultFix: ({ fixedAt, fixedNote }) => handleMarkFaultAsFixed({ faultId, fixedAt, fixedNote }),
    });
  }

  /**
   * Renders the list of faults - for a single revision, for other faults.
   */
  const renderFaults = useCallback(
    (
      title: string,
      faults: DeviceListFaultsResponse['faults'],
      revision?: { revisionId: number; revisionName: string; finishedAt?: string },
      isRevision: boolean = false
    ) => {
      const gridStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 174px',
        gap: '24px',
        alignItems: 'center',
      };

      return (
        <Stack spacing={0} key={title} pb={48} bg="white.0" sx={{ borderRadius: '4px' }}>
          <Box
            py={24}
            px={16}
            bg="gray.0"
            sx={(theme) => ({ border: `1px solid ${theme.white}`, borderRadius: '4px 4px 0 0' })}
          >
            <Stack spacing={4}>
              {isRevision && (
                <Group position="apart">
                  <P2Regular color="gray.8">Revízna správa</P2Regular>
                  <P2Regular color="gray.8">
                    {revision!.finishedAt
                      ? `Ukončená ${new Date(revision!.finishedAt).toLocaleDateString()}`
                      : 'Revízna správa ešte nebola ukončená'}
                  </P2Regular>
                </Group>
              )}
              <H3Medium color="gray.8">{title}</H3Medium>
            </Stack>
            <Divider color="gray.2" my={24} />
            <Box style={gridStyle}>
              <P1Medium color="gray.8">Typ</P1Medium>
              <P1Medium color="gray.8">Názov</P1Medium>
              <P1Medium color="gray.8">Popis</P1Medium>
              <P1Medium color="gray.8">Dodatok</P1Medium>
              <P1Medium color="gray.8" pl={12}>
                Riešenie
              </P1Medium>
            </Box>
          </Box>
          {/* if this function is called, the faults will be present */}
          {faults!.map((item) => {
            const isFixed = item.fixed?.fixedAt ? true : false;

            return (
              <Box
                py={24}
                px={16}
                key={item.faultName}
                sx={(theme) => ({
                  background: isFixed ? theme.colors.gray[0] : theme.white,
                  borderBottom: `1px solid ${theme.colors.gray[1]}`,
                  borderLeft: `1px solid ${theme.white}`,
                  borderRight: `1px solid ${theme.white}`,
                })}
              >
                <Box style={gridStyle}>
                  <Box>
                    <FaultSeverityLabel
                      faultSeverityId={item.faultSeverity.faultSeverityId}
                      faultSeverityName={item.faultSeverity.faultSeverityName}
                      isFaultFixed={isFixed}
                    />
                  </Box>
                  <P2Regular color="gray.8">{item.faultName}</P2Regular>
                  <P2Regular color="gray.8">{item.description ?? '-'}</P2Regular>
                  <P2Regular color="gray.8">{item.moreInfo ?? '-'}</P2Regular>
                  <Group position="right">
                    {isFixed ? (
                      <Group spacing={8} py={8} px={16}>
                        <IconCheck stroke="1.5" />
                        <P2Medium>Vyriešená závada</P2Medium>
                      </Group>
                    ) : canManageDevices ? (
                      <Button
                        variant="secondary"
                        rightIcon={<IconChevronRight stroke="1.5" />}
                        onClick={() => markFaultAsFixed(item.faultId)}
                      >
                        Vyriešiť závadu
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Group>
                </Box>
                {isFixed && (
                  <Box py={16} pb={24} style={gridStyle}>
                    <Stack>
                      <P3Regular color="gray.6">Dátum riešenia závady</P3Regular>
                      {/* If isFixed === true, the fault has fixedAt prop  */}
                      <P2Regular color="gray.8">{new Date(item.fixed?.fixedAt!).toLocaleDateString()}</P2Regular>
                    </Stack>
                    <Stack>
                      <P3Regular color="gray.6">Popis riešenia</P3Regular>
                      <P2Regular color="gray.8">{item.fixed?.fixedNote ?? '-'}</P2Regular>
                    </Stack>
                  </Box>
                )}
              </Box>
            );
          })}
        </Stack>
      );
    },
    [data, loadData, canManageDevices]
  );

  useEffect(() => {
    if (loadData) {
      setLoading(true);

      const deviceFaultsGetAction = getAction('DeviceListFaults');

      deviceFaultsGetAction({ parameters: { deviceId: deviceId! } })
        .then((response) => {
          setData(response);
        })
        .catch(panic)
        .finally(() => {
          setLoading(false);
          setLoadData(false);
        });
    }
  }, [loadData]);

  useEffect(() => {
    if (roleId === ROLE_ADMIN_ID) {
      setCanManageDevices(true);
    } else if (data) {
      const hasPermission = getAction('AuthUserHasPermissionInDepartment');

      hasPermission({
        parameters: { departmentId: String(data.department.departmentId), permissionSlug: 'manage-devices' },
      })
        .then(({ hasPermission }) => setCanManageDevices(hasPermission))
        .catch(panic);
    }
  }, [roleId, data]);

  if (!deviceId) {
    return <Navigate to={DEVICES_PAGE_PATH.original} />;
  }

  return (
    <DashboardLayout
      title={`Závady pre zariadenie ${data?.deviceName ?? ''} `}
      breadcrumbs={[
        { title: 'Zariadenia', link: DEVICES_PAGE_PATH.original },
        { title: `${data?.deviceName ?? ''}`, link: EDIT_DEVICE_PAGE_PATH.insert({ deviceId: deviceId! }) },
        { title: 'Závady zariadenia' },
      ]}
    >
      <LoadingOverlay visible={loading} />
      <Stack spacing={24}>
        {revisionFaults &&
          !isEmpty(revisionFaults) &&
          Object.values(revisionFaults).map((items) => {
            // all grouped items have the same revision
            const revision = items[0].revision!;

            return renderFaults(revision.revisionName, items, revision, true);
          })}

        {otherFaults && !isEmpty(otherFaults) && (
          <Box>{renderFaults('Ostatné závady', otherFaults, undefined, false)}</Box>
        )}
      </Stack>

      {!loading && (!data?.faults || isEmpty(data?.faults)) && (
        <P2Regular color="gray.7">Zariadenie nemá žiadne závady</P2Regular>
      )}
    </DashboardLayout>
  );
}
