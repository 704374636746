import { IconBoxAlignBottom, IconBoxAlignLeft, IconBoxAlignRight, IconBoxAlignTop } from '@tabler/icons-react';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { Box, Button, createStyles, Group, Modal, NumberInput, NumberInputProps, rem, Stack } from '@mantine/core';
import P4Medium from 'components/typography/P4Medium';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';

interface OpenPageMarginSettingsParams {
  onClose?: () => void;
}

interface IPageMarginSettingsContext {
  openPageMarginSettings: (params: OpenPageMarginSettingsParams) => void;
}

const PageMarginSettingsContext = createContext<IPageMarginSettingsContext>(undefined!);

const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 110px)',
    gridTemplateRows: 'auto 158px auto',
    gap: rem(12),
    alignItems: 'center',
  },
  page: {
    alignSelf: 'stretch',
    border: `${rem(2)} solid ${theme.colors.gray[6]}`,
  },
}));

/**
 * Content of the modal used to set the page margin.
 */
function PageMarginModal({ opened, onClose }: { opened: boolean; onClose: () => void }) {
  const { classes } = useStyles();
  const { visual, setVisual } = useTemplateEditorData();

  const initialValues = useMemo(
    () => ({
      marginTop: visual.marginTop,
      marginBottom: visual.marginBottom,
      marginLeft: visual.marginLeft,
      marginRight: visual.marginRight,
    }),
    [visual]
  );

  const form = useForm({ initialValues });

  const marginPreviewStyle = useMemo(
    () => ({
      borderTop: `${rem(form.values.marginTop / 2)} solid var(--mantine-color-gray-2)`,
      borderBottom: `${rem(form.values.marginBottom / 2)} solid var(--mantine-color-gray-2)`,
      borderLeft: `${rem(form.values.marginLeft / 2)} solid var(--mantine-color-gray-2)`,
      borderRight: `${rem(form.values.marginRight / 2)} solid var(--mantine-color-gray-2)`,
    }),
    [form.values]
  );

  const props: Partial<NumberInputProps> = useMemo(
    () => ({
      size: 'md',
      min: 4,
      max: 40,
      required: true,
      rightSection: <P4Medium c="gray.6">mm</P4Medium>,
      styles: { required: { display: 'none' } },
    }),
    []
  );

  useEffect(() => {
    form.resetDirty();
    form.setValues(initialValues);
  }, [opened]);

  const onSubmit = form.onSubmit((values) => {
    setVisual((visual) => ({ ...visual, ...values }));
    onClose();
  });

  const isDirty = form.isDirty();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Odsadenie stránky"
      size={415}
      centered
      closeOnClickOutside={!isDirty}
      closeOnEscape={!isDirty}
    >
      <form onSubmit={onSubmit}>
        <Stack spacing={24} pt={16}>
          <Box className={classes.grid}>
            <span></span>
            <NumberInput {...props} data-autofocus icon={<IconBoxAlignTop />} {...form.getInputProps('marginTop')} />
            <span></span>

            <NumberInput {...props} icon={<IconBoxAlignLeft />} {...form.getInputProps('marginLeft')} />
            <Box className={classes.page}>
              <Box w="100%" h="100%" style={marginPreviewStyle} />
            </Box>
            <NumberInput {...props} icon={<IconBoxAlignRight />} {...form.getInputProps('marginRight')} />

            <span></span>
            <NumberInput {...props} icon={<IconBoxAlignBottom />} {...form.getInputProps('marginBottom')} />
            <span></span>
          </Box>

          <Group spacing={16} position="right">
            <Button type="button" onClick={onClose} variant="subtle-gray">
              Zrušiť
            </Button>
            <Button type="submit" variant="primary">
              Nastaviť
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
}

/**
 * Provides a context for managing the page margin form state.
 */
export function PageMarginSettingsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { open, close }] = useDisclosure();
  const [params, setParams] = useState<OpenPageMarginSettingsParams>({});

  const openPageMarginSettings = useCallback(
    (params: OpenPageMarginSettingsParams) => {
      setParams(params);
      open();
    },
    [open]
  );

  const onClose = useCallback(() => {
    params.onClose?.();
    close();
  }, [params, close]);

  const value = useMemo(() => ({ openPageMarginSettings }), [openPageMarginSettings]);

  return (
    <PageMarginSettingsContext.Provider value={value}>
      {children}
      <PageMarginModal opened={opened} onClose={onClose} />
    </PageMarginSettingsContext.Provider>
  );
}

/**
 * Hook for opening the page margin form.
 */
export function usePageMarginSettings() {
  return useContext(PageMarginSettingsContext);
}
