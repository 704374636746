import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import MultiCheckbox, {
  MultiCheckboxItem,
  MultiCheckboxPropsBase,
} from 'components/inputs/multi-checkbox/MultiCheckbox';

/**
 * Component for selecting multiple users using checkboxes.
 */
export default function UserMultiCheckbox(props: MultiCheckboxPropsBase = {}) {
  const { getAction } = useApi();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<MultiCheckboxItem[]>([]);

  // Fetch users from the API.
  useEffect(() => {
    const userGetListAction = getAction('UserList');

    userGetListAction({ query: { filters: { 'showDiscarded.eq': 0 } } })
      .then((users) => {
        setUsers(
          users.map((user) => ({
            label: user.fullName,
            value: user.userId,
          }))
        );
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, [getAction]);

  return (
    <MultiCheckbox
      {...props}
      loading={loading}
      data={users}
      searchPlaceholder="Hľadať používateľov"
      noResultPlaceholder="Neboli nájdení žiadni používatelia."
    />
  );
}
