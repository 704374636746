import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { noop } from 'lodash';
import { Button, Menu } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import useAcceptAction from 'components/tables/revision/actions/hooks/use-accept-action';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the revision technician to accept the revision.
 */
export default function AcceptAction({ revision, onStatusChange = noop, kind }: RevisionSingleActionProps) {
  const { acceptRevision } = useAcceptAction();
  const { confirm } = useConfirm();

  /**
   * Confirms the acceptance of the revision
   */
  const confirmAcceptRevision = () =>
    confirm({
      title: 'Prijať revíznu správu',
      content: 'Naozaj si prajete prijať túto revíznu správu?',
      onConfirm: () => acceptRevision({ revision, onStatusChange }),
    });

  if (kind === 'button') {
    return (
      <Button variant="primary" size="md" onClick={confirmAcceptRevision} leftIcon={<IconCheck />} w={180}>
        Prijať
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={confirmAcceptRevision} icon={<IconCheck stroke={1.5} size={20} />}>
      Prijať
    </Menu.Item>
  );
}
