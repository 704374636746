import { ScrollArea, Stack, Tabs, TabsPanelProps } from '@mantine/core';

/**
 * The height of the tab panel.
 */
const HEIGHT = 'calc(100vh - 200px)';

/**
 * Parameters of the RevisionTab component.
 */
export interface RevisionTabProps extends TabsPanelProps {
  skinny?: boolean;
}

/**
 * Custom Tab panel for the electric revision form.
 */
export default function RevisionTab({ value, children, skinny = false, ...otherProps }: RevisionTabProps) {
  return (
    <Tabs.Panel value={value} h={HEIGHT} {...otherProps}>
      <ScrollArea h={HEIGHT}>
        <Stack spacing={40} p={skinny ? 0 : 40}>
          {children}
        </Stack>
      </ScrollArea>
    </Tabs.Panel>
  );
}
