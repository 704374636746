import QualificationList from 'components/QualificationList';
import { UserRow } from 'components/tables/user/types';

/**
 * Column for the certificates.
 */
export default function CertificatesColumn({ data: { certificates } }: { data: UserRow }) {
  return (
    <QualificationList
      qualifications={certificates.map(({ certificateNumber, deviceType, deviceTypeId }, i) => ({
        qualificationId: i,
        qualificationNumber: certificateNumber,
        deviceTypeId,
        deviceType,
      }))}
    />
  );
}
