import { ActionIcon, Box, Button, createStyles, Group, Overlay, rem, Stack, Tooltip } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconPlus, IconTrashX, IconX } from '@tabler/icons-react';
import { useConfirm } from 'components/modals/message/MessageProvider';
import FormRow, { FormRowTexts } from 'pages/revisions-module/template-editor/editors/form/row/FormRow';
import {
  IConfigureInputParams,
  useInputConfigurator,
} from 'pages/revisions-module/template-editor/editors/form/configurator/InputConfiguratorProvider';
import FormSectionName from 'pages/revisions-module/template-editor/editors/form/section/FormSectionName';
import { IFormInputContext, IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { useFormRows } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useCallback, useMemo, useRef, useState } from 'react';
import findElementParent from 'utils/find-element-parent';

/**
 * Texts for the FormSection component.
 */
export interface FormSectionTexts {
  removeTooltip: string;
  removeConfirm: string;
  moveUpTooltip: string;
  moveDownTooltip: string;
  newRow: string;
  rowTexts?: FormRowTexts;
}

/**
 * Parameters of the FormSection component.
 */
export interface FormSectionProps {
  data: IFormSection;
  context: IFormInputContext;
  isFirst: boolean;
  isLast: boolean;
  helpText?: string;
  readOnlyName?: boolean;
  disableDelete?: boolean;
  maxInputsInRow?: number;
  texts?: FormSectionTexts;
  configureInputParams?: Partial<IConfigureInputParams>;
  disableMoveInput?: boolean;
  disableMoveRowToSection?: boolean;
}

/**
 * Styles for the FormSection component.
 */
const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: rem(4),
    overflow: 'hidden',
    border: `${rem(1)} solid ${theme.colors.gray[3]}`,
  },
  header: {
    borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
    borderRadius: `${rem(4)} ${rem(4)} 0 0`,
  },
  addRowButtonWrapper: {
    border: `${rem(1)} dashed ${theme.colors.blue[5]}`,
    borderRadius: rem(8),
  },
}));

/**
 * A single section in the form editor.
 */
export default function FormSection({
  data,
  context,
  helpText,
  isFirst,
  isLast,
  readOnlyName = false,
  disableDelete = false,
  maxInputsInRow,
  texts = {
    moveUpTooltip: 'Presunúť sekciu vyššie',
    moveDownTooltip: 'Presunúť sekciu nižšie',
    removeTooltip: 'Odstrániť sekciu',
    removeConfirm: 'Naozaj chcete odstrániť túto sekciu? Táto akcia je nevratná.',
    newRow: 'Pridať pole do nového riadku',
    rowTexts: undefined,
  },
  configureInputParams = {},
  disableMoveInput = false,
  disableMoveRowToSection = false,
}: FormSectionProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { classes } = useStyles();
  const { confirm } = useConfirm();
  const { newestSectionId, removeSection, updateSectionName, moveSectionDown, moveSectionUp } = useFormSections();
  const { addRow, moveRow, moveInputToExistingRow } = useFormRows();
  const { addInput } = useFormInputs();
  const { configureInput } = useInputConfigurator();

  const [draggingRow, setDraggingRow] = useState<number | null>(null);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [movingInput, setMovingInput] = useState<string | null>(null);

  const canBeDeleted = useMemo(
    () => data.rows.every((row) => row.inputs.every(({ deletable }) => deletable)),
    [data.rows]
  );

  /** Confirm removing the section. */
  const confirmRemoveSection = () =>
    confirm({
      title: texts.removeTooltip,
      content: texts.removeConfirm,
      onConfirm: () => removeSection(data.id),
    });

  /** Creates a new row with a new input */
  const addInputToNewRow = () =>
    configureInput({
      ...configureInputParams,
      primaryButtonLabel: texts.newRow,
      onSubmit: (spec) => {
        const rowId = addRow(data.id);
        addInput(data.id, rowId, context, spec);
      },
    });

  const onDragOver = useCallback(
    (e: React.DragEvent) => {
      const hoverRow = findElementParent(e.target as Element, (el) => el.classList.contains('form-row'));

      if (draggingRow !== null && hoverRow !== null) {
        e.preventDefault();
        const isAboveHalf = e.clientY - hoverRow.getBoundingClientRect().top < hoverRow.clientHeight / 2;
        const index = Number(hoverRow.getAttribute('data-index')) + (isAboveHalf ? 0 : 1);
        setHoveredIndex(index);
      }
    },
    [draggingRow]
  );

  const onDragEnd = useCallback(() => {
    setDraggingRow(null);
    setHoveredIndex(null);
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();

      if (hoveredIndex !== null && draggingRow !== null && hoveredIndex !== draggingRow) {
        const shifted = Math.max(0, hoveredIndex > draggingRow ? hoveredIndex - 1 : hoveredIndex);
        moveRow(data.id, draggingRow, shifted);
      }

      setDraggingRow(null);
      setHoveredIndex(null);
    },
    [data.id, draggingRow, hoveredIndex, moveRow]
  );

  return (
    <Group spacing={8} noWrap align="stretch" ref={wrapperRef}>
      <Stack spacing={8} justify="space-between" w={32}>
        {isFirst ? (
          <Box />
        ) : (
          <Tooltip label={texts.moveUpTooltip} openDelay={300} withArrow={false}>
            <ActionIcon variant="subtle-gray" size="sm" onClick={() => moveSectionUp(data.id)}>
              <IconChevronUp />
            </ActionIcon>
          </Tooltip>
        )}
        {!isLast && (
          <Tooltip label={texts.moveDownTooltip} openDelay={300} withArrow={false}>
            <ActionIcon
              variant="subtle-gray"
              size="sm"
              onClick={(e) => {
                moveSectionDown(data.id);
                setTimeout(() => wrapperRef.current?.scrollIntoView({ block: 'center' }), 0);
              }}
            >
              <IconChevronDown />
            </ActionIcon>
          </Tooltip>
        )}
      </Stack>
      <Stack w="calc(100% - 40px)" spacing={0} className={classes.wrapper}>
        <Group mih={74} position="apart" spacing={24} p={16} bg="gray.0" className={classes.header}>
          <Group spacing={8}>
            <FormSectionName
              value={data.name}
              onChange={(name) => updateSectionName(data.id, name)}
              readOnly={readOnlyName}
              autoFocus={newestSectionId === data.id}
            />
            {helpText && <QuestionMarkTooltip label={helpText} />}
          </Group>
          <Group spacing={8}>
            {movingInput && (
              <Button size="sm" variant="danger-secondary" leftIcon={<IconX />} onClick={() => setMovingInput(null)}>
                Zrušiť presun poľa
              </Button>
            )}
            {!disableDelete && (
              <Tooltip label={texts.removeTooltip} openDelay={300} withArrow={false} onClick={confirmRemoveSection}>
                <ActionIcon variant="subtle-gray" size="md" disabled={!canBeDeleted}>
                  <IconTrashX />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        </Group>
        <Stack spacing={0} px={16} onDragOver={onDragOver} onDrop={onDrop}>
          {data.rows.map((row, index) => (
            <Stack pos="relative" spacing={0} key={row.id}>
              {movingInput && (
                <Overlay zIndex={5} py={8} bg="transparent">
                  <Box
                    bg="blue.7"
                    h="100%"
                    opacity={0.2}
                    className="cursor-pointer rounded-lg transition-opacity hover:opacity-40"
                    onClick={() => {
                      setMovingInput(null);
                      moveInputToExistingRow(data.id, movingInput, row.id);
                    }}
                  />
                </Overlay>
              )}
              {hoveredIndex === index && <Box h={2} bg="blue.5" />}
              <FormRow
                index={index}
                sectionId={data.id}
                data={row}
                context={context}
                maxInputsInRow={maxInputsInRow}
                texts={texts.rowTexts}
                configureInputParams={configureInputParams}
                dropMode={draggingRow !== null}
                dragMode={draggingRow === index}
                onDragStart={() => setDraggingRow(index)}
                onDragEnd={onDragEnd}
                disableMoveInput={disableMoveInput}
                disableMoveToSection={disableMoveRowToSection}
                startMovingInputToRow={setMovingInput}
              />
            </Stack>
          ))}
          {hoveredIndex === data.rows.length && <Box h={2} bg="blue.5" />}
        </Stack>
        <Box p={16}>
          <Box p={8} className={classes.addRowButtonWrapper}>
            <Button variant="subtle" size="md" leftIcon={<IconPlus />} fullWidth onClick={addInputToNewRow}>
              {texts.newRow}
            </Button>
          </Box>
        </Box>
      </Stack>
    </Group>
  );
}
