import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthListFaultSeveritiesPathParameters } from 'api/actions/auth-list-fault-severities/auth-list-fault-severities-path-parameters';
import { AuthListFaultSeveritiesResponse } from 'api/actions/auth-list-fault-severities/auth-list-fault-severities-response';

type AuthListFaultSeveritiesQueryParameters = Record<string, any>;

/**
 * The path of the AuthListFaultSeverities action.
 */
export const authListFaultSeveritiesActionPath = new SweetPath<keyof AuthListFaultSeveritiesPathParameters>(
  '/api/auth/fault-severity/list',
  { path: '{param}' }
);

/**
 * Creates a contextualized AuthListFaultSeverities actions.
 */
export default function createAuthListFaultSeveritiesAction(axios: AxiosInstance) {
  return function authListFaultSeveritiesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthListFaultSeveritiesQueryParameters } = {}) {
    const path = authListFaultSeveritiesActionPath.original;

    return axios.get<AuthListFaultSeveritiesResponse, AuthListFaultSeveritiesResponse>(path, {
      params: query,
      headers,
    });
  };
}
