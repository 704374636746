import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionSaveDraftPayload } from 'api/actions/revision-save-draft/revision-save-draft-payload';
import { RevisionSaveDraftPathParameters } from 'api/actions/revision-save-draft/revision-save-draft-path-parameters';
import { RevisionSaveDraftResponse } from 'api/actions/revision-save-draft/revision-save-draft-response';

type RevisionSaveDraftQueryParameters = Record<string, any>;

/**
 * The path of the RevisionSaveDraft action.
 */
export const revisionSaveDraftActionPath = new SweetPath<keyof RevisionSaveDraftPathParameters>(
  '/api/revision/save-draft/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionSaveDraft actions.
 */
export default function createRevisionSaveDraftAction(axios: AxiosInstance) {
  return function revisionSaveDraftAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionSaveDraftPathParameters;
    query?: RevisionSaveDraftQueryParameters;
    payload: RevisionSaveDraftPayload;
  }) {
    const path = revisionSaveDraftActionPath.insert(parameters);

    return axios.put<RevisionSaveDraftResponse, RevisionSaveDraftResponse, RevisionSaveDraftPayload>(path, payload);
  };
}
