import { MouseEventHandler, useCallback } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Parameters of the LinkWithConfirm component.
 */
export interface LinkWithConfirmProps extends Omit<LinkProps, 'to'> {
  confirmMessage: string;
  confirmTitle?: string;
  to: string | number;
}

/**
 * Link component which shows a confirmation dialog before navigating to the
 * given URL.
 */
export default function LinkWithConfirm({ confirmMessage, to, confirmTitle = '', ...props }: LinkWithConfirmProps) {
  const { confirm } = useConfirm();
  const navigate = useNavigate();

  /**
   * Handle the user's click on the link.
   */
  const click: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      confirm({
        title: confirmTitle,
        content: confirmMessage,
        onConfirm: () => navigate(to as any),
      });

      return false;
    },
    [navigate, confirmMessage, confirmTitle, to]
  );

  return <Link {...props} to="#cancel" onClick={click} />;
}
