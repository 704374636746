import { MantineThemeOverride, rem } from '@mantine/core';

export type PopoverTheme = NonNullable<MantineThemeOverride['components']>['Popover'];

export const Popover: PopoverTheme = {
  styles: (theme) => ({
    dropdown: {
      minWidth: rem(120),
      boxShadow: '0px 4px 12px 0px rgba(197, 198, 211, 0.40)',
      borderRadius: rem(4),
      borderColor: theme.colors.gray[1],
    },
  }),
};
