import { BackgroundImage, Box, Container, Divider, Grid, Image, Text, rem, useMantineTheme } from '@mantine/core';

/**
 * Parameters of the SimpleLayout component.
 */
export interface SimpleLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  contentStyle?: React.CSSProperties;
}

/**
 * Simple layout used for pages that don't need a navbar or header.
 */
export default function SimpleLayout({ children, contentStyle = {} }: SimpleLayoutProps) {
  const theme = useMantineTheme();

  return (
    <Grid justify="center" align="center" columns={2} style={{ margin: rem(0) }}>
      <Grid.Col span={1} style={{ padding: rem(0) }}>
        <BackgroundImage src="img/screen_background.jpg" h="100vh" display="flex" style={{ alignItems: 'end' }}>
          <Container pl={40} pb={20} m={0} w={640}>
            <Image src="img/logo.svg" height={32} width={143} />
            <Text color="#F2F2F7" size={56} lh="64px" fw={700} pt={24} pb={24} w={640}>
              We know buildings inside-out.
            </Text>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Text color="#FFF" size={12} lh="24px" fw={400}>
                © {new Date().getFullYear()} - Amperia, s. r. o.
              </Text>
              <Divider orientation="vertical" color="#FFFFFF30" mx="md" />
              <Text color="#3F58D5" size={12} lh="24px" fw={400} underline>
                info@amperia.sk
              </Text>
            </Box>
          </Container>
        </BackgroundImage>
      </Grid.Col>
      <Grid.Col
        span={1}
        h="100vh"
        display="flex"
        style={{ alignItems: 'center', justifyContent: 'center', background: theme.colors.gray[1] }}
      >
        <Container bg="#FFF" w={384} p={0} style={{ borderRadius: rem(4), ...contentStyle }}>
          {children}
        </Container>
      </Grid.Col>
    </Grid>
  );
}
