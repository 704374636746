import { Modal, Divider, Flex, Stack, Group, Box } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import DateDisplay from 'components/DateDisplay';
import { IconBuildingSkyscraper, IconMapPinPlus } from '@tabler/icons-react';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import RevisionPlanActions from 'components/tables/revision-plan/RevisionPlanActions';

interface RevisionPlanModalProps {
  opened: boolean;
  onClose: () => void;
  revisionPlanId: number;
  revisionPeriod: number;
  type: string;
  organization: { organizationId: number; organizationName: string };
  department: { departmentId: number; departmentName: string };
  deviceId: number;
  deviceType: { deviceTypeId: number; deviceTypeName: string };
  deviceSubtype: { deviceTypeId: number; deviceTypeName: string };
  nextRevision?: Date;
  deviceName: string;
  manufactured?: number;
  manufacturer?: string;
  serialNumber?: string;
}

/**
 * Display a detail of the revision plan.
 */
export default function RevisionPlanModal({
  opened,
  onClose,
  revisionPlanId,
  revisionPeriod,
  type,
  organization,
  department,
  deviceId,
  deviceType,
  deviceSubtype,
  nextRevision,
  deviceName,
  manufactured,
  manufacturer,
  serialNumber,
}: RevisionPlanModalProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={type}
      centered
      overlayProps={{ opacity: 0.2 }}
      size={512}
      zIndex={1000}
    >
      <Stack spacing={24}>
        <Divider color="gray.1" />
        <Group spacing={16} grow>
          <Box>
            <P2Regular mb={4} color="gray.6">
              Organizácia
            </P2Regular>
            <Flex gap={8}>
              <IconBuildingSkyscraper stroke="1.5" size={24} />
              <P2Regular color="gray.8">{organization.organizationName}</P2Regular>
            </Flex>
          </Box>
          <Box>
            <P2Regular mb={4} color="gray.6">
              Stredisko
            </P2Regular>
            <Flex gap={8}>
              <IconMapPinPlus stroke="1.5" size={24} />
              <P2Regular color="gray.8">{department.departmentName}</P2Regular>
            </Flex>
          </Box>
        </Group>
        <Box>
          <P2Regular mb={4} color="gray.6">
            Zariadenie
          </P2Regular>
          <P2Regular color="gray.8">{deviceName}</P2Regular>
        </Box>
        <Group spacing={16} grow>
          <Box>
            <P2Regular mb={4} color="gray.6">
              Typ zariadenia
            </P2Regular>
            <DeviceTypeLabel deviceType={deviceType.deviceTypeId} />
          </Box>
          <Box>
            <P2Regular mb={4} color="gray.6">
              Druh
            </P2Regular>
            <P2Regular color="gray.8">{deviceSubtype.deviceTypeName}</P2Regular>
          </Box>
        </Group>
        <Box>
          <P2Regular mb={4} color="gray.6">
            Deadline
          </P2Regular>
          {nextRevision ? <DateDisplay date={nextRevision} withRelative /> : '-'}
        </Box>
        <Divider color="gray.1" />
        <RevisionPlanActions
          type={type}
          revisionPlanId={revisionPlanId}
          revisionPeriod={revisionPeriod}
          deviceId={deviceId}
          nextRevision={nextRevision ? nextRevision.toISOString() : undefined}
          deviceType={deviceType}
          deviceSubtype={deviceSubtype}
          organization={organization}
          department={department}
          deviceName={deviceName}
          manufactured={manufactured}
          manufacturer={manufacturer}
          serialNumber={serialNumber}
          showCalendar={false}
        />
      </Stack>
    </Modal>
  );
}
