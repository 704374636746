import { noop } from 'lodash';
import { RevisionActionProps } from 'components/tables/revision/types';
import { useApi } from 'api/api-context';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';

/**
 * Implements the accept action for revision.
 */
export default function useAcceptAction() {
  const { getAction } = useApi();

  /**
   * Makes a call to the API to accept the revision.
   */
  async function acceptRevision({
    revision: { revisionId, revisionName },
    onStatusChange = noop,
  }: RevisionActionProps) {
    const revisionAcceptAction = getAction('RevisionTechnicianAccept');

    try {
      const { status } = await revisionAcceptAction({ parameters: { revisionId: String(revisionId) } });

      onStatusChange({ revisionId, status });

      showNotification({
        title: 'Revízna správa bola prijatá',
        message: `Revíznu správu ${revisionName} ste úspešne prijali.`,
        color: SUCCESS_NOTIFICATION_COLOR,
      });
    } catch (error: any) {
      panic(error);
    }
  }

  return { acceptRevision };
}
