import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataGetUsersPathParameters } from 'api/actions/predefined-data-get-users/predefined-data-get-users-path-parameters';
import { PredefinedDataGetUsersResponse } from 'api/actions/predefined-data-get-users/predefined-data-get-users-response';

type PredefinedDataGetUsersQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataGetUsers action.
 */
export const predefinedDataGetUsersActionPath = new SweetPath<keyof PredefinedDataGetUsersPathParameters>(
  '/api/predefined-data/get/users/{usersPredefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataGetUsers actions.
 */
export default function createPredefinedDataGetUsersAction(axios: AxiosInstance) {
  return function predefinedDataGetUsersAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataGetUsersPathParameters;
    query?: PredefinedDataGetUsersQueryParameters;
  }) {
    const path = predefinedDataGetUsersActionPath.insert(parameters);

    return axios.get<PredefinedDataGetUsersResponse, PredefinedDataGetUsersResponse>(path, { params: query, headers });
  };
}
