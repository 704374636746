import { mergeAttributes, Node } from '@tiptap/core';

export interface PageBreakOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pageBreak: {
      insertPageBreak: ({ force }: { force: boolean }) => ReturnType;
    };
  }
}

export const PageBreak = Node.create<PageBreakOptions>({
  name: 'pageBreak',
  draggable: true,
  inline: false,
  group: 'block',

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'page-break',
      },
    };
  },

  addAttributes() {
    return {
      'data-force': { default: 'false' },
    };
  },

  parseHTML() {
    return [{ tag: 'div.page-break' }];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
  },

  addCommands() {
    return {
      insertPageBreak:
        ({ force }) =>
        ({ commands }) =>
          commands.insertContent({ type: this.name, attrs: { 'data-force': force.toString() } }),
    };
  },
});
