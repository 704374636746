import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionCreatePayload } from 'api/actions/revision-create/revision-create-payload';
import { RevisionCreatePathParameters } from 'api/actions/revision-create/revision-create-path-parameters';
import { RevisionCreateResponse } from 'api/actions/revision-create/revision-create-response';

type RevisionCreateQueryParameters = Record<string, any>;

/**
 * The path of the RevisionCreate action.
 */
export const revisionCreateActionPath = new SweetPath<keyof RevisionCreatePathParameters>('/api/revision/create', {
  path: '{param}',
});

/**
 * Creates a contextualized RevisionCreate actions.
 */
export default function createRevisionCreateAction(axios: AxiosInstance) {
  return function revisionCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: RevisionCreateQueryParameters;
    payload: RevisionCreatePayload;
  }) {
    const path = revisionCreateActionPath.original;

    return axios.post<RevisionCreateResponse, RevisionCreateResponse, RevisionCreatePayload>(path, payload);
  };
}
