import RestrictionSelectBaseFilter, {
  RestrictionSelectFilterBaseProps,
} from 'components/tables/filters/restriction/RestrictionSelectBaseFilter';

/**
 * Filter component for the restriction column with two options - global and organization.
 */
export default function TwoRestrictionsSelectFilter({ api, context }: RestrictionSelectFilterBaseProps) {
  return (
    <RestrictionSelectBaseFilter
      api={api}
      context={context}
      data={[
        { value: 'global', label: 'Globálne' },
        { value: 'organization', label: 'Organizácia' },
      ]}
    />
  );
}
