import { MantineThemeOverride, rem } from '@mantine/core';

export type MenuTheme = NonNullable<MantineThemeOverride['components']>['Menu'];

export const Menu: MenuTheme = {
  styles: (theme) => ({
    dropdown: {
      minWidth: rem(120),
      boxShadow: '0px 4px 12px 0px rgba(197, 198, 211, 0.40)',
      borderRadius: rem(4),
      padding: '0 !important',
      borderColor: theme.colors.gray[1],
    },
    item: {
      paddingTop: rem(8),
      paddingBottom: rem(8),
      paddingLeft: rem(16),
      paddingRight: rem(16),
      borderRadius: 0,
    },
    itemIcon: {
      marginRight: rem(8),
    },
    label: {
      marginBottom: rem(4),
    },
    divider: {
      marginTop: 0,
      marginBottom: 0,
      borderTopColor: theme.colors.gray[1],
    },
  }),
};
