import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserAdminChangePasswordPayload } from 'api/actions/user-admin-change-password/user-admin-change-password-payload';
import { UserAdminChangePasswordPathParameters } from 'api/actions/user-admin-change-password/user-admin-change-password-path-parameters';
import { UserAdminChangePasswordResponse } from 'api/actions/user-admin-change-password/user-admin-change-password-response';

type UserAdminChangePasswordQueryParameters = Record<string, any>;

/**
 * The path of the UserAdminChangePassword action.
 */
export const userAdminChangePasswordActionPath = new SweetPath<keyof UserAdminChangePasswordPathParameters>(
  '/api/user/admin-change-password/{userId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized UserAdminChangePassword actions.
 */
export default function createUserAdminChangePasswordAction(axios: AxiosInstance) {
  return function userAdminChangePasswordAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserAdminChangePasswordPathParameters;
    query?: UserAdminChangePasswordQueryParameters;
    payload: UserAdminChangePasswordPayload;
  }) {
    const path = userAdminChangePasswordActionPath.insert(parameters);

    return axios.post<UserAdminChangePasswordResponse, UserAdminChangePasswordResponse, UserAdminChangePasswordPayload>(
      path,
      payload
    );
  };
}
