import { MantineThemeOverride, PasswordInputProps, rem } from '@mantine/core';
import { P1Regular, P2Regular } from 'theme/typography';

export type PasswordInputTheme = NonNullable<MantineThemeOverride['components']>['PasswordInput'];

export const PasswordInputDefaultProps: Partial<PasswordInputProps> = {
  size: 'lg',
};

export const PasswordInput: PasswordInputTheme = {
  defaultProps: PasswordInputDefaultProps,
  sizes: {
    lg: (theme) => ({
      input: {
        paddingTop: rem(12),
        paddingBottom: rem(12),
        paddingRight: rem(16),
        paddingLeft: rem(16),
        height: rem(48),
        minHeight: rem(48),
        ...P1Regular,
      },
      innerInput: {
        ...P1Regular,
      },
    }),
    md: (theme) => ({
      input: {
        paddingTop: rem(8),
        paddingBottom: rem(8),
        paddingRight: rem(12),
        paddingLeft: rem(12),
        height: rem(40),
        minHeight: rem(40),
        ...P1Regular,
      },
      innerInput: {
        ...P1Regular,
      },
    }),
    sm: (theme) => ({
      input: {
        paddingTop: rem(4),
        paddingBottom: rem(4),
        paddingRight: rem(8),
        paddingLeft: rem(8),
        height: rem(32),
        minHeight: rem(32),
        ...P2Regular,
      },
      innerInput: {
        ...P2Regular,
      },
    }),
  },
  styles: (theme) => ({
    input: {
      backgroundColor: theme.white,
      '&:fill': {
        color: theme.colors.blue[8],
      },
      '&:focus-within': {
        borderColor: theme.colors.blue[8],
        boxShadow: '0px 0px 0px 4px #DBE4FF',
        color: theme.colors.gray[8],
      },
      '&[data-invalid=true]:focus-within': {
        borderColor: theme.colors.red[8],
        boxShadow: '0px 0px 0px 4px #FFE3E8',
      },
      '&:disabled': {
        borderColor: theme.colors.gray[2],
        color: theme.colors.gray[4],
        backgroundColor: theme.white,
      },
      '&[data-disabled]': {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[2],
      },
    },

    innerInput: {
      backgroundColor: theme.white,
      '&:fill': {
        color: theme.colors.blue[8],
      },
      '&:focus': {
        color: theme.colors.gray[8],
      },
      '&:disabled': {
        color: theme.colors.gray[4],
        backgroundColor: theme.white,
        opacity: 1,
      },
    },
  }),
};
