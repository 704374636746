import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceCreatePayload } from 'api/actions/device-create/device-create-payload';
import { DeviceCreatePathParameters } from 'api/actions/device-create/device-create-path-parameters';
import { DeviceCreateResponse } from 'api/actions/device-create/device-create-response';

type DeviceCreateQueryParameters = Record<string, any>;

/**
 * The path of the DeviceCreate action.
 */
export const deviceCreateActionPath = new SweetPath<keyof DeviceCreatePathParameters>('/api/device/create', {
  path: '{param}',
});

/**
 * Creates a contextualized DeviceCreate actions.
 */
export default function createDeviceCreateAction(axios: AxiosInstance) {
  return function deviceCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: DeviceCreateQueryParameters;
    payload: DeviceCreatePayload;
  }) {
    const path = deviceCreateActionPath.original;

    return axios.post<DeviceCreateResponse, DeviceCreateResponse, DeviceCreatePayload>(path, payload);
  };
}
