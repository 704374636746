import { Menu, ActionIcon, Box, Group, LoadingOverlay } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconTrashX } from '@tabler/icons-react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import P2RegularLink from 'components/typography/P2RegularLink';
import { useCallback, useEffect, useState } from 'react';
import useFileDownload from 'api/use-file-download';
import FileThumbnail from 'components/files/FileThumbnail';
import Excerpt from 'components/Excerpt';
import { noop } from 'lodash';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * The parameters of the FilePreview component.
 */
interface FilePreviewProps {
  fileId: string;
  label?: string;
  onFileDelete?: () => void;
  showSlider?: boolean;
  onOpenSlider?: () => void;
  hideDownload?: boolean;
  hideDelete?: boolean;
  excerptLength?: number;
}

/**
 * The file preview component.
 */
export default function FilePreview({
  fileId,
  label,
  onFileDelete = noop,
  showSlider = true,
  onOpenSlider = noop,
  hideDownload = false,
  hideDelete = false,
  excerptLength = 50,
}: FilePreviewProps) {
  const { confirm } = useConfirm();
  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const { getFileMetadata } = useFileManager();
  const { loading, download } = useFileDownload();

  /**
   * Confirms the file deletion.
   */
  const handleDelete = useCallback(
    () =>
      confirm({
        title: 'Zmazať súbor',
        content: 'Naozaj chcete zmazať súbor?',
        onConfirm: onFileDelete,
      }),
    [onFileDelete]
  );

  // Fetch the file metadata.
  useEffect(() => {
    getFileMetadata({ fileId }).then((metadata) => {
      setFileName(metadata.name);
      setFileExtension(metadata.extension);
    });
  }, [fileId]);

  return (
    <Box
      pos="relative"
      bg="gray.0"
      p={0}
      m={0}
      h={80}
      w="full"
      sx={{ display: 'grid', gridTemplateColumns: '80px 1fr', borderRadius: '4px' }}
    >
      <LoadingOverlay visible={loading} loaderProps={{ size: 'sm' }} />
      <FileThumbnail fileId={fileId} showSlider={showSlider} onOpenSlider={onOpenSlider} />
      <Group position="apart" p={20} noWrap>
        <Box
          p={4}
          m={-4}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            // different behavior when needed
            if (showSlider) {
              onOpenSlider();
            } else {
              download(fileId);
            }
          }}
        >
          <P2RegularLink color="gray.7">
            <Excerpt text={fileName} suffix={fileExtension} length={excerptLength} />
          </P2RegularLink>
        </Box>

        <Menu shadow="md" width={160} position="bottom-end">
          <Menu.Target>
            <ActionIcon variant="subtle-gray" size="md">
              <IconDotsVertical stroke="1.5" height={24} width={24} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            {label && <Menu.Label>{label}</Menu.Label>}
            {!hideDownload && (
              <Menu.Item color="gray.8" icon={<IconDownload stroke={1.5} size={20} />} onClick={() => download(fileId)}>
                Stiahnúť
              </Menu.Item>
            )}
            {!hideDelete && (
              <Menu.Item color="red.8" icon={<IconTrashX stroke={1.5} size={20} />} onClick={handleDelete}>
                Zmazať súbor
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </Box>
  );
}
