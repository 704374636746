import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserListPathParameters } from 'api/actions/user-list/user-list-path-parameters';
import { UserListResponse } from 'api/actions/user-list/user-list-response';

type UserListQueryParameters = Record<string, any>;

/**
 * The path of the UserList action.
 */
export const userListActionPath = new SweetPath<keyof UserListPathParameters>('/api/user/list', { path: '{param}' });

/**
 * Creates a contextualized UserList actions.
 */
export default function createUserListAction(axios: AxiosInstance) {
  return function userListAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: UserListQueryParameters } = {}) {
    const path = userListActionPath.original;

    return axios.get<UserListResponse, UserListResponse>(path, { params: query, headers });
  };
}
