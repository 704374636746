import { Center, MantineColor } from '@mantine/core';
import { IconQuestionMark } from '@tabler/icons-react';

/**
 * Parameters of the QuestionMark component.
 */
export interface QuestionMarkProps {
  size?: number;
  color?: MantineColor;
}

/**
 * Displays a question mark icon with a tooltip.
 */
export default function QuestionMark({ size = 16, color = 'blue.7' }: QuestionMarkProps = {}) {
  return (
    <Center inline bg={color} sx={{ borderRadius: '50%' }} w={size} h={size}>
      <IconQuestionMark color="white" strokeWidth="3" size={size - 2} />
    </Center>
  );
}
