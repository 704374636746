import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceSubtypeGetPathParameters } from 'api/actions/device-subtype-get/device-subtype-get-path-parameters';
import { DeviceSubtypeGetResponse } from 'api/actions/device-subtype-get/device-subtype-get-response';

type DeviceSubtypeGetQueryParameters = Record<string, any>;

/**
 * The path of the DeviceSubtypeGet action.
 */
export const deviceSubtypeGetActionPath = new SweetPath<keyof DeviceSubtypeGetPathParameters>(
  '/api/device/subtypes/{deviceTypeId}/get/{deviceSubtypeId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceSubtypeGet actions.
 */
export default function createDeviceSubtypeGetAction(axios: AxiosInstance) {
  return function deviceSubtypeGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceSubtypeGetPathParameters;
    query?: DeviceSubtypeGetQueryParameters;
  }) {
    const path = deviceSubtypeGetActionPath.insert(parameters);

    return axios.get<DeviceSubtypeGetResponse, DeviceSubtypeGetResponse>(path, { params: query, headers });
  };
}
