import { useCallback, useState } from 'react';
import { useApi } from 'api/api-context';
import { Stack } from '@mantine/core';
import FormFooter from 'components/forms/FormFooter';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { showNotification } from '@mantine/notifications';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';
import panic from 'errors/panic';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { useFormSections } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { ITemplate } from 'pages/revisions-module/template-editor/editors/template/types';
import { IForm } from 'pages/revisions-module/template-editor/editors/form/types';
import { IVisual } from 'pages/revisions-module/template-editor/editors/visual/types';
import { IPipeline } from 'pages/revisions-module/template-editor/editors/pipeline/types';

/**
 * Wrapper which provides save functionality to the form editor.
 */
export default function TemplateEditorFormWrapper({ children }: { children: React.ReactNode }) {
  const { getAction } = useApi();
  const { revisionTemplateId, form, template, visual, pipeline } = useTemplateEditorData();
  const { getInputByName } = useFormInputs();
  const { getSectionById } = useFormSections();
  const [loading, setLoading] = useState(false);

  const postprocessForm = useCallback((): IForm => form, [form]);

  const postprocessTemplate = useCallback((): ITemplate => {
    const eTemplate = document.createElement('div');
    eTemplate.innerHTML = template.template;

    // Set the format for number and sum placeholders.
    const placeholders = eTemplate.querySelectorAll('.placeholder[data-type="number"], .placeholder[data-type="sum"]');
    placeholders.forEach((placeholder) => {
      const inputName = placeholder.getAttribute('data-name') ?? '';
      const input = getInputByName(inputName);

      if (input && (input.spec.type === 'number' || input.spec.type === 'sum')) {
        const { precision = 0 } = input.spec;
        placeholder.setAttribute('data-format', `t{,${precision}}`);
      }
    });

    // Set the format for deviceMeasurement
    const deviceMeasurements = eTemplate.querySelectorAll('.device-measurements-placeholder');
    deviceMeasurements.forEach((deviceMeasurement) => {
      const sectionId = deviceMeasurement.getAttribute('data-section-id') ?? '';
      const section = getSectionById(sectionId);

      if (section && section.rows.length > 0) {
        const columns = section.rows
          .filter(({ inputs }) => inputs.length > 0)
          .map(({ inputs: [{ spec }] }) => ({ key: spec.name, label: spec.label }));

        deviceMeasurement.setAttribute('data-format', JSON.stringify({ columns }));
      }
    });

    return { template: eTemplate.innerHTML };
  }, [form, template]);

  const postprocessVisual = useCallback((): IVisual => visual, [visual]);

  const postprocessPipeline = useCallback((): IPipeline => pipeline, [pipeline]);

  /**
   * Submits the formScheme, templateScheme and visualData.
   */
  const submit = () => {
    const configureRevisionTemplate = getAction('RevisionRevisionTemplateConfigure');

    setLoading(true);

    const form = postprocessForm();
    const template = postprocessTemplate();
    const visual = postprocessVisual();
    const pipeline = postprocessPipeline();

    configureRevisionTemplate({
      parameters: { revisionTemplateId },
      payload: {
        formScheme: JSON.stringify(form),
        templateScheme: JSON.stringify(template),
        visualData: JSON.stringify(visual),
        pipelineScheme: JSON.stringify(pipeline),
      },
    })
      .then(() => {
        showNotification({
          title: 'Šablóna bola úspešne uložená',
          message: '',
          color: SUCCESS_NOTIFICATION_COLOR,
        });
      })
      .catch(panic)
      .finally(() => setLoading(false));
  };

  return (
    <Stack spacing={0}>
      {children}
      <FormFooter onPrimaryClick={submit} loading={loading} />
    </Stack>
  );
}
