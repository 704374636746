import { IRowNode } from 'ag-grid-community';
import { RevisionRow } from 'components/tables/revision/types';
import RevisionActions from 'components/tables/revision/RevisionActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<RevisionRow>; data: RevisionRow }) {
  return (
    <RevisionActions
      revision={data}
      onStatusChange={({ status }) => node.setData({ ...data, revisionStatus: { ...status } })}
    />
  );
}
