import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OrganizationCreatePayload } from 'api/actions/organization-create/organization-create-payload';
import { OrganizationCreatePathParameters } from 'api/actions/organization-create/organization-create-path-parameters';
import { OrganizationCreateResponse } from 'api/actions/organization-create/organization-create-response';

type OrganizationCreateQueryParameters = Record<string, any>;

/**
 * The path of the OrganizationCreate action.
 */
export const organizationCreateActionPath = new SweetPath<keyof OrganizationCreatePathParameters>(
  '/api/organization/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized OrganizationCreate actions.
 */
export default function createOrganizationCreateAction(axios: AxiosInstance) {
  return function organizationCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: OrganizationCreateQueryParameters;
    payload: OrganizationCreatePayload;
  }) {
    const path = organizationCreateActionPath.original;

    return axios.post<OrganizationCreateResponse, OrganizationCreateResponse, OrganizationCreatePayload>(path, payload);
  };
}
