import { Stack } from '@mantine/core';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import DeviceSection from 'components/forms/revision/fill-out/devices/DeviceSection';

/**
 * Content of the devices tab in the Electric revision form
 */
export default function DevicesTabContent() {
  const { form } = useFillOutRevisionDataProvider();

  return (
    <Stack spacing={40}>
      {Object.values(form.values.devices).map((device) => (
        <DeviceSection key={device.deviceId} device={device} />
      ))}
    </Stack>
  );
}
