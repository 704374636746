import { useDisclosure } from '@mantine/hooks';
import { createContext, useContext, useState } from 'react';
import PickDepartmentsModal from 'components/modals/pick-departments-modal/PickDepartmentsModal';

/**
 * Callback for when user selects the list of departments and user's permissions in it.
 */
type OnPickCallback = (value: {
  pickedDepartments: { [departmentId: number]: { checked: boolean; templateName: string; departmentName: string } };
  permissions: number[];
  templateName: string;
}) => void;

/**
 * Parameters of the pickDepartments function.
 */
interface PickDepartmentsParams {
  title: string;
  hiddenDepartments: number[];
  permissionsTitle?: string;
  pickDepartments?: boolean;
  pickPermissions?: boolean;
  initialTemplateName: string;
  initialPermissions: number[];
  onPick: OnPickCallback;
}

/**
 * Function for selecting the list of departments and user's permissions in it.
 */
type PickDepartments = (params: PickDepartmentsParams) => void;

/**
 * Interface of the PickDepartmentsContext.
 */
interface IPickDepartmentsContext {
  pickDepartments: PickDepartments;
}

/**
 * Context for selecting the list of departments and user's permissions in it.
 */
const PickDepartmentsContext = createContext<IPickDepartmentsContext>(undefined!);

/**
 * Provider for selecting the list of departments and user's permissions in it.
 */
export function PickDepartmentsProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<PickDepartmentsParams | undefined>(undefined);

  /**
   * Opens the modal for selecting the list of departments and user's permissions in it.
   */
  const pickDepartments: PickDepartments = (params) => {
    setParams(params);
    open();
  };

  return (
    <PickDepartmentsContext.Provider value={{ pickDepartments }}>
      {children}
      {opened && <PickDepartmentsModal opened onClose={close} {...params!} />}
    </PickDepartmentsContext.Provider>
  );
}

/**
 * Hook for selecting the list of departments and user's permissions in it.
 */
export function usePickDepartments() {
  return useContext(PickDepartmentsContext);
}
