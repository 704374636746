import { Group, Select, Tooltip, useMantineTheme } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { IFormInputType } from 'pages/revisions-module/template-editor/editors/form/types';
import { forwardRef, useMemo } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  group: string;
  help?: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, help, ...others }: ItemProps, ref) => {
  return (
    <Group ref={ref} {...others} spacing={8}>
      <span>{label}</span>
      {help && <QuestionMarkTooltip label={help} />}
    </Group>
  );
});

/**
 * Map from every input type to its name.
 */
export const INPUT_TYPE_NAMES: Record<IFormInputType, ItemProps> = {
  text: { label: 'Textové pole', group: 'Základné typy' },
  number: { label: 'Číselné pole', group: 'Základné typy' },
  date: { label: 'Dátumové pole', group: 'Základné typy' },
  checkbox: { label: 'Zaškrtávacie pole', group: 'Základné typy' },
  select: { label: 'Výber z možností', group: 'Základné typy' },
  wysiwyg: { label: 'Textový editor', group: 'Základné typy' },

  predefinedSelect: { label: 'Výber predvoleného údaju', group: 'Predvolené údaje' },
  predefinedList: { label: 'Zoznam predvolených údajov', group: 'Predvolené údaje' },
  measuringDevice: { label: 'Meracie prístroje', group: 'Predvolené údaje' },

  certificate: { label: 'Osvedčenie revízneho technika', group: 'Osvedčenia a oprávnenia' },
  qualification: { label: 'Oprávenie firmy', group: 'Osvedčenia a oprávnenia' },

  period: { label: 'Perióda', group: 'Špeciálne typy' },
  safetyPoint: { label: 'Záver revíznej správy', group: 'Špeciálne typy' },

  deviceProperty: { label: 'Vlastnosť zariadenia', group: 'Zariadenia' },

  sum: { label: 'Súčet', group: 'Výpočty' },
  datePlusPeriod: { label: 'Budúci dátum', group: 'Výpočty', help: 'Súčet dátumového poľa a periódy' },
};

/**
 * Options for input type select.
 */
const OPTIONS = Object.entries(INPUT_TYPE_NAMES).map(([value, props]) => ({
  value: value as IFormInputType,
  ...props,
}));

/**
 * Map from every input type to boolean value indicating whether the input type is enabled.
 */
export const ALL_TYPES_ON: Record<IFormInputType, boolean> = {
  text: true,
  number: true,
  date: true,
  checkbox: true,
  select: true,
  wysiwyg: true,

  predefinedSelect: true,
  predefinedList: true,
  measuringDevice: true,

  certificate: true,
  qualification: true,

  period: true,
  safetyPoint: true,

  deviceProperty: true,

  sum: true,
  datePlusPeriod: true,
};

/**
 * Parameters of the InputTypeSelect component.
 */
export interface InputTypeSelectProps {
  value: IFormInputType;
  onChange: (value: IFormInputType) => void;
  readOnly?: boolean;
  inputTypes?: Partial<Record<IFormInputType, boolean>>;
}

/**
 * Select for input type.
 */
export default function InputTypeSelect({
  value,
  onChange,
  readOnly = false,
  inputTypes = ALL_TYPES_ON,
}: InputTypeSelectProps) {
  const theme = useMantineTheme();

  const filteredOptions = useMemo(() => OPTIONS.filter((option) => inputTypes[option.value]), [inputTypes]);

  return (
    <Select
      data={filteredOptions}
      size="lg"
      value={value}
      onChange={onChange}
      searchable
      readOnly={readOnly}
      disabled={readOnly}
      itemComponent={SelectItem}
      label={
        <Group spacing={4}>
          <span>Typ poľa</span>
          {readOnly && (
            <Tooltip label="Typ poľa už nie je možné upraviť" withArrow={false} openDelay={200}>
              <IconLock size={16} color={theme.colors.red[7]} />
            </Tooltip>
          )}
        </Group>
      }
    />
  );
}
