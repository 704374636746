import { notifications } from '@mantine/notifications';
import { IconPlus } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import DeviceSubtypeForm, {
  DeviceSubtypeFormData,
  deviceSubtypeFormInitialValues,
} from 'components/forms/device-subtype/DeviceSubtypeForm';
import { DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { DEVICE_TYPES, getDeviceType } from 'model/DeviceType';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DEVICE_SUBTYPES_PAGE_PATH, DEVICE_TYPES_PAGE_PATH, EDIT_DEVICE_SUBTYPE_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to add a new device subtype.
 */
export default function AddDeviceSubtypePage() {
  const navigate = useNavigate();
  const { getAction } = useApi();

  const { deviceTypeId: paramDeviceTypeId } = useParams();
  const deviceTypeId = useMemo(() => Number(paramDeviceTypeId ?? DEVICE_TYPES[0].id), [paramDeviceTypeId]);
  const deviceTypeName = useMemo(() => getDeviceType(deviceTypeId)?.name, [deviceTypeId]);

  /**
   * Runs when the form is submitted.
   */
  function onSubmit({
    deviceSubtypeName,
    deviceSubtypeShortName,
    description,
    restriction,
    departments,
    organizations,
    evidenceFields,
  }: DeviceSubtypeFormData) {
    const deviceSubtypeCreateAction = getAction('DeviceSubtypeCreate');

    const isGlobal = restriction === 'global';
    const isOrganization = restriction === 'organization';
    const isDepartment = restriction === 'department';

    return deviceSubtypeCreateAction({
      parameters: {
        deviceTypeId: String(deviceTypeId),
      },
      payload: {
        deviceSubtypeName,
        shortName: deviceSubtypeShortName,
        description,
        isGlobal,
        isOrganization,
        isDepartment,
        departments: isDepartment ? departments.map(({ departmentId }) => departmentId) : [],
        organizations: isOrganization ? organizations.map(({ organizationId }) => organizationId) : [],
        evidenceFields: evidenceFields.map(({ fieldName, fieldType, isRequired }) => ({
          fieldName,
          fieldType,
          isRequired,
        })),
      },
    })
      .then((response) => {
        notifications.show({
          title: 'Podtyp bol vytvorený.',
          message: `Podtyp ${deviceSubtypeName} bol úspešne vytvorený.`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE === 'same') {
          navigate(EDIT_DEVICE_SUBTYPE_PAGE_PATH.insert({ deviceTypeId, deviceSubtypeId: response.deviceTypeId }));
        } else if (DEVICE_SUBTYPE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DEVICE_SUBTYPES_PAGE_PATH.insert({ deviceTypeId }));
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title="Pridanie nového typu"
      breadcrumbs={[
        { title: 'Typy zariadení', link: DEVICE_TYPES_PAGE_PATH.original },
        { title: `Typy zariadení pre: ${deviceTypeName}`, link: DEVICE_SUBTYPES_PAGE_PATH.insert({ deviceTypeId }) },
        { title: 'Pridanie nového typu' },
      ]}
    >
      <DeviceSubtypeForm
        onSubmit={onSubmit}
        primaryButtonText="Vytvoriť"
        primaryIcon={<IconPlus stroke="1.5" height={24} width={24} />}
        initialValues={{ ...deviceSubtypeFormInitialValues, parentDeviceTypeId: deviceTypeId }}
      />
    </DashboardLayout>
  );
}
