import { Box, createStyles, MantineColor, rem } from '@mantine/core';
import { useMemo } from 'react';

/**
 * Props for the TemplateEditorTab component.
 */
export interface TemplateEditorTabProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

/**
 * Styles for the TemplateEditorTab component.
 */
const useStyles = createStyles(() => ({
  tab: {
    width: rem(180),
    cursor: 'pointer',
    borderRadius: '4px 4px 0 0',
    transition: 'background 100ms ease',
    ':hover': { background: 'white' },
  },
}));

/**
 * A single tab in the navigation.
 */
export default function TemplateEditorTab({ text, active, onClick }: TemplateEditorTabProps) {
  const { classes } = useStyles();
  const bg: MantineColor = useMemo(() => (active ? 'white' : ''), [active]);

  return (
    <Box ta="center" py={8} bg={bg} fz={16} lh={1.5} fw={500} onClick={onClick} className={classes.tab}>
      {text}
    </Box>
  );
}
