import { Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';
import { useMemo } from 'react';
import { useFormInputs } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';

/**
 * Sub-form for set revision safety point automation.
 */
export default function SetRevisionSafetyPointSubForm({ form }: { form: UseFormReturnType<IAutomation> }) {
  const { allInputs } = useFormInputs();

  const inputData = useMemo(
    () =>
      allInputs
        .filter((input) => input.context === 'revision' && input.spec.type === 'safetyPoint')
        .map((input) => ({ value: input.spec.name, label: input.spec.label })),
    [allInputs]
  );

  return (
    <Select
      data={inputData}
      label="Použiť hodnotu z poľa"
      required
      searchable
      size="sm"
      {...form.getInputProps('inputName')}
    />
  );
}
