import { Box, Button, Card, Divider, Group, Skeleton, Stack, Tooltip } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { RevisionListResponse } from 'api/actions/revision-list/revision-list-response';
import { useApi } from 'api/api-context';
import DeviceList from 'components/DeviceList';
import NumberBadge from 'components/NumberBadge';
import RevisionStatusIcon from 'components/RevisionStatusIcon';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import RevisionActions from 'components/tables/revision/RevisionActions';
import H3Medium from 'components/typography/H3Medium';
import P3Regular from 'components/typography/P3Regular';
import panic from 'errors/panic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { REVISIONS_PAGE_PATH } from 'routes/paths';

/**
 * Parameters of the RevisionsTableCard component.
 */
export interface RevisionsTableCardProps {
  limit?: number;
}

/**
 * Displays a simplified table of revisions.
 */
export default function RevisionsTableCard({ limit = 5 }: RevisionsTableCardProps = {}) {
  const { getAction } = useApi();
  const [revisions, setRevisions] = useState<RevisionListResponse>([]);
  const [loading, setLoading] = useState(true);
  const someRevisions = useMemo(() => revisions.slice(0, limit), [revisions]);
  const moreLink = useMemo(() => `${REVISIONS_PAGE_PATH.original}?revisionStatusId=1,2,3,4,5,6,7`, []);

  /**
   * Updates the revision with the given ID.
   */
  const updateRevision = useCallback(
    (revisionId: number, update: Partial<RevisionListResponse[number]>) =>
      setRevisions((revisions) =>
        revisions.map((revision) => (revision.revisionId === revisionId ? { ...revision, ...update } : revision))
      ),
    [setRevisions]
  );

  useEffect(() => {
    const revisionListAction = getAction('RevisionList');

    revisionListAction({ query: { filters: { 'statusId.in': [1, 2, 3, 4, 5, 6, 7], 'includedInStatistics.eq': 1 } } })
      .then(setRevisions) // todo: Filter
      .catch(panic)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Skeleton radius={4} w="100%" mih={400} />;
  }

  return (
    <Card p={24} h="fit-content">
      <Group position="apart" pb={24}>
        <Group spacing={4}>
          <H3Medium color="gray.8">Revízne správy čakajúce na akciu</H3Medium>
          <Box sx={{ transform: 'scale(0.66)' }}>
            <NumberBadge variant="gray" value={revisions.length} />
          </Box>
        </Group>
        <Button
          component={Link}
          to={moreLink}
          size="sm"
          variant="subtle-compact"
          rightIcon={<IconChevronRight size={16} />}
        >
          Pozrieť všetko
        </Button>
      </Group>
      {someRevisions.map((revision) => (
        <Stack key={revision.revisionId} spacing={0}>
          <Divider color="gray.1" />
          <Group noWrap mih={82} spacing={16} pt={12} pb={16} position="apart">
            <Stack spacing={4}>
              <Group spacing={12}>
                <Group mr={12} noWrap spacing={4}>
                  <Tooltip label={revision.deviceType.deviceTypeName}>
                    <Box>
                      <DeviceTypeLabel iconOnly deviceType={revision.deviceType.deviceTypeId} />
                    </Box>
                  </Tooltip>
                  <DeviceList devices={revision.devices} limit={1} />
                </Group>
                <RevisionStatusIcon statusId={revision.revisionStatus.revisionStatusId} />
              </Group>
              <P3Regular color="gray.7">
                {revision.revisionName} • {revision.organization.organizationName} ({revision.department.departmentName}
                )
              </P3Regular>
            </Stack>
            <RevisionActions
              revision={revision}
              hideCopy
              onStatusChange={({ status }) => updateRevision(revision.revisionId, { revisionStatus: status })}
            />
          </Group>
        </Stack>
      ))}
      <Divider color="gray.1" />
      <Group pt={16} position="apart">
        <Button
          component={Link}
          to={moreLink}
          variant="subtle-gray"
          size="sm"
          rightIcon={<IconChevronRight size={16} />}
        >
          Všetky čakajúce revízie ({revisions.length})
        </Button>
        <P3Regular color="gray.7">
          Zobrazené: {someRevisions.length} / {revisions.length}
        </P3Regular>
      </Group>
    </Card>
  );
}
