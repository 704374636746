import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataDeleteGlobalPathParameters } from 'api/actions/predefined-data-delete-global/predefined-data-delete-global-path-parameters';
import { PredefinedDataDeleteGlobalResponse } from 'api/actions/predefined-data-delete-global/predefined-data-delete-global-response';

type PredefinedDataDeleteGlobalQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataDeleteGlobal action.
 */
export const predefinedDataDeleteGlobalActionPath = new SweetPath<keyof PredefinedDataDeleteGlobalPathParameters>(
  '/api/predefined-data/delete/global/{predefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataDeleteGlobal actions.
 */
export default function createPredefinedDataDeleteGlobalAction(axios: AxiosInstance) {
  return function predefinedDataDeleteGlobalAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataDeleteGlobalPathParameters;
    query?: PredefinedDataDeleteGlobalQueryParameters;
  }) {
    const path = predefinedDataDeleteGlobalActionPath.insert(parameters);

    return axios.post<PredefinedDataDeleteGlobalResponse, PredefinedDataDeleteGlobalResponse>(path, {
      params: query,
      headers,
    });
  };
}
