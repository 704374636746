import { useApi } from 'api/api-context';
import {
  DataSelectInheritedProps,
  DataSelect,
  DataMultiSelectInheritedProps,
  DataMultiSelect,
} from 'components/selects/DataSelect';

const LABEL_PROP = 'faultSeverityName' as const;
const VALUE_PROP = 'faultSeverityId' as const;
const ACTION_NAME = 'AuthListFaultSeverities' as const;

/**
 * Select input for fault severities.
 */
export function FaultSeveritySelect({ ...props }: DataSelectInheritedProps) {
  const { getAction } = useApi();
  return <DataSelect action={getAction(ACTION_NAME)} labelProp={LABEL_PROP} valueProp={VALUE_PROP} {...props} />;
}

/**
 * Select input for fault severities (multiple).
 */
export function FaultSeverityMultiSelect({ ...props }: DataMultiSelectInheritedProps) {
  const { getAction } = useApi();
  return <DataMultiSelect action={getAction(ACTION_NAME)} labelProp={LABEL_PROP} valueProp={VALUE_PROP} {...props} />;
}
