import { Flex, Tooltip, useMantineTheme } from '@mantine/core';
import { OrganizationRow } from 'components/tables/organization/types';
import BadgeDeleted from 'components/tables/Badge';
import P2Medium from 'components/typography/P2Medium';
import { IconChartPieOff, IconCircleLetterS, IconKeyOff } from '@tabler/icons-react';

/**
 * Column for the organization name.
 */
export default function OrganizationNameColumn({
  data: { organizationName, status, selfAdministration, inKepHashArchive, includedInStatistics },
}: {
  data: OrganizationRow;
}) {
  const theme = useMantineTheme();
  const rowOpacity = status ? 1 : 0.6;

  return (
    <Flex gap={8} align="center">
      {!status && <BadgeDeleted text="Vyradená" />}
      <P2Medium color="gray.8" style={{ opacity: rowOpacity }}>
        {organizationName}
      </P2Medium>
      {selfAdministration && (
        <Tooltip label="Organizácia sa spravuje sama" disabled={!status}>
          <IconCircleLetterS fill={theme.colors.gray[8]} color="white" style={{ opacity: rowOpacity }} />
        </Tooltip>
      )}
      {!includedInStatistics && (
        <Tooltip label="Organizácia je vyňatá zo štatistík" disabled={!status}>
          <IconChartPieOff size={20} color={theme.colors.red[8]} style={{ opacity: rowOpacity }} />
        </Tooltip>
      )}
      {!inKepHashArchive && (
        <Tooltip label="KEP hash archív je vypnutý" disabled={!status}>
          <IconKeyOff size={20} color={theme.colors.red[8]} style={{ opacity: rowOpacity }} />
        </Tooltip>
      )}
    </Flex>
  );
}
