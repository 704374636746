import { useApi } from 'api/api-context';
import NumberStatCard from 'components/stats/NumberStatCard';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import { REVISIONS_PAGE_PATH } from 'routes/paths';

/**
 * Displays the number of active revisions.
 */
export default function ActiveRevisionsCard() {
  const { getAction } = useApi();
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const revisionListAction = getAction('RevisionList');

    // Active revisions are those that have not been finished yet.
    revisionListAction({ query: { filters: { 'statusId.in': [1, 2, 3, 4, 5, 6, 7], 'includedInStatistics.eq': 1 } } })
      .then(({ length }) => setValue(length))
      .catch(panic);
  }, []);

  return (
    <NumberStatCard
      loading={value === null}
      title="Prebiehajúce revízie"
      link={`${REVISIONS_PAGE_PATH.original}?revisionStatusId=1,2,3,4,5,6,7`}
      value={value ?? 0}
      variant="gray"
    />
  );
}
