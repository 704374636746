import { Stack } from '@mantine/core';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import Toast from 'components/Toast';
import FilePreview from 'components/files/FilePreview';
import Slider from 'components/modals/slider/Slider';
import H3Medium from 'components/typography/H3Medium';
import { ReactElement, useCallback, useMemo, useState } from 'react';

/**
 * Parameters of the RevisionFile component.
 */
export interface RevisionFileProps {
  revision: RevisionGetResponse;
}

/**
 * Displays the revision PDF.
 */
export default function RevisionFile({ revision }: RevisionFileProps) {
  const [openedSlider, setOpenSlider] = useState(false);
  const [initialFileId, setInitialFileId] = useState('');

  const handleOpenSlider = useCallback(
    (fileId: string) => {
      setInitialFileId(fileId);
      setOpenSlider(true);
    },
    [revision]
  );

  const contents = useMemo(() => {
    const files: ReactElement[] = [];

    if (revision.fileId) {
      files.push(
        <FilePreview
          key="fileId"
          fileId={revision.fileId}
          hideDelete
          excerptLength={100}
          showSlider
          onOpenSlider={() => handleOpenSlider(revision.fileId!)}
        />
      );
    }

    if (revision.signedFileId) {
      files.push(
        <FilePreview
          key="signedFileId"
          fileId={revision.signedFileId}
          hideDelete
          excerptLength={100}
          showSlider
          onOpenSlider={() => handleOpenSlider(revision.signedFileId!)}
        />
      );
    }

    if (files.length === 0) {
      return (
        <Toast type="warning" message="PDF súbor revíznej správy nie je dostupný" withCloseButton={false} fullWidth />
      );
    }

    return <Stack spacing={24}>{files}</Stack>;
  }, [revision]);

  return (
    <Stack spacing={24} py={24} px={40} style={{ borderRadius: '4px' }} bg="white">
      <H3Medium color="gray.7">Revízna správa</H3Medium>
      {contents}

      <Slider
        key={initialFileId}
        opened={openedSlider}
        fileIds={[initialFileId]}
        onClose={() => setOpenSlider(false)}
        initialImageId={initialFileId}
        hideControls={true}
      />
    </Stack>
  );
}
