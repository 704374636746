import { isEmpty, noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import { usePickDeviceType } from 'components/modals/pick-device-type/PickDeviceTypeProvider';
import useLoadingAction from 'hooks/use-loading-action';
import { useCallback, useState } from 'react';
import { nanoid } from 'nanoid';
import { useForm } from '@mantine/form';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import FormWrapper from 'components/forms/FormWrapper';
import FormInputGroup from 'components/forms/FormInputGroup';
import { Anchor, Box, Button, Flex, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import P1Regular from 'components/typography/P1Regular';
import P1RegularLink from 'components/typography/P1RegularLink';
import { IconCheck, IconPlus, IconTrashX } from '@tabler/icons-react';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import { useConfirm } from 'components/modals/message/MessageProvider';

export interface CompanySettingsFormData {
  companyName: string;
  streetAddress: string;
  city: string;
  zip: string;
  country: string;
  companyQualifications: {
    qualificationNumber: string;
    type: string;
  }[];
}

/**
 * Form for creating and editing company settings.
 */
export default function CompanySettingsForm({
  initialValues = {
    companyName: '',
    streetAddress: '',
    city: '',
    zip: '',
    country: '',
    companyQualifications: [],
  },
  onSubmit = noop,
}: FormProps<CompanySettingsFormData> = {}) {
  const { pickDeviceType } = usePickDeviceType();
  const { confirm } = useConfirm();
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const [extendedInitialValues] = useState(() => ({
    ...initialValues,
    companyQualifications: initialValues.companyQualifications.map((qualification) => ({
      ...qualification,
      uuid: nanoid(),
    })),
  }));

  const form = useForm({
    initialValues: extendedInitialValues,
    validate: {
      companyName: createValidator([required]),
      streetAddress: createValidator([required]),
      city: createValidator([required]),
      zip: createValidator([required]),
      country: createValidator([required]),
      companyQualifications: {
        qualificationNumber: createValidator([required]),
      },
    },
  });

  /**
   * Adds a new qualification to the form.
   */
  const addQualification = useCallback(() => {
    pickDeviceType({
      title: 'Pridať oprávnenie spoločnosti',
      addButtonLabel: 'Pridať oprávnenie',
      onPick: ({ slug }) =>
        form.insertListItem('companyQualifications', {
          type: slug,
          qualificationNumber: '',
          uuid: nanoid(),
        }),
    });
  }, [pickDeviceType, form]);

  /**
   * Removes a qualification from the form.
   */
  const removeQualification = useCallback((i: number) => form.removeListItem('companyQualifications', i), [form]);

  /**
   * Asks the user to confirm the removal of the qualification.
   */
  const removeQualificationConfirm = useCallback(
    (index: number) => {
      const hasValue = !!form.values.companyQualifications[index].qualificationNumber;

      if (hasValue) {
        confirm({
          title: 'Zmazať oprávnenie',
          content: 'Naozaj chcete zmazať oprávnenie?',
          onConfirm: () => removeQualification(index),
        });
      } else {
        removeQualification(index);
      }
    },
    [removeQualification, confirm, form]
  );

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText="Uložiť"
      primaryIcon={<IconCheck stroke="1.5" height={24} width={24} />}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov spoločnosti"
            size="lg"
            name="amperia-companyName"
            {...form.getInputProps('companyName')}
          />
          <TextInput
            label="Adresa"
            placeholder="Ulica a číslo"
            size="lg"
            name="6eb367e1-1c6f-48bb-bbac-876b317872db"
            {...form.getInputProps('streetAddress')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Mesto"
            placeholder="Mesto"
            size="lg"
            name="2badbd07-cbb6-455b-a3a3-b4572f893f6b"
            {...form.getInputProps('city')}
          />
          <TextInput
            label="PSČ"
            placeholder="PSČ"
            size="lg"
            name="0ad99c5d-5380-460e-981a-69371a00e8d5"
            {...form.getInputProps('zip')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Štát"
            placeholder="Štát"
            size="lg"
            name="42637dd6-4056-4911-a310-fb3584cf1acd"
            {...form.getInputProps('country')}
          />
        </SimpleGrid>
      </FormInputGroup>
      {isEmpty(form.values.companyQualifications) && (
        <FormInputGroup groupTitle="Oprávnenia">
          <Group py={26} spacing={8}>
            <P1Regular color="gray.6">Spoločnosť zatiaľ nemá priradené žiadne oprávnenia.</P1Regular>
            <Anchor onClick={addQualification}>
              <P1RegularLink span color="blue.8">
                Pridať oprávnenia
              </P1RegularLink>
            </Anchor>
          </Group>
        </FormInputGroup>
      )}
      {!isEmpty(form.values.companyQualifications) && (
        <FormInputGroup groupTitle="Oprávnenia" stackProps={{ pb: 16 }} />
      )}
      {(form.values.companyQualifications || []).map((qualification, index) => (
        <Flex gap={32} key={qualification.uuid} pl={40} pb={40}>
          <Box w={8} bg="gray.0" />
          <Stack w="100%" spacing={24}>
            <DeviceTypeLabel deviceType={qualification.type} size="md" />
            <SimpleGrid cols={2} spacing={40}>
              <TextInput
                label="Číslo oprávnenia"
                placeholder="Zadajte číslo oprávnenia"
                size="lg"
                name={`amperia-companyQualifications.${index}.qualificationNumber`}
                {...form.getInputProps(`companyQualifications.${index}.qualificationNumber`)}
              />
              <Group mt={27} sx={{ transform: 'translateX(-32px)' }}>
                <Button
                  type="button"
                  variant="danger-secondary"
                  leftIcon={<IconTrashX stroke="1.5" size={24} />}
                  size="lg"
                  onClick={() => removeQualificationConfirm(index)}
                >
                  Zmazať
                </Button>
              </Group>
            </SimpleGrid>
          </Stack>
        </Flex>
      ))}
      {!isEmpty(form.values.companyQualifications) && (
        <Stack align="flex-start" pl={40} pb={20}>
          <Button variant="subtle" size="md" leftIcon={<IconPlus stroke="1.5" />} onClick={addQualification}>
            Pridať oprávnenie
          </Button>
        </Stack>
      )}
    </FormWrapper>
  );
}
