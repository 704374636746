import { ActionIcon, Button, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { IconPlus, IconX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import PredefinedDataSelect from 'components/inputs/predefined-data-select/PredefinedDataSelect';
import panic from 'errors/panic';
import { nanoid } from 'nanoid';
import { IFormInputSpecPredefinedList } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useEffect, useMemo, useState } from 'react';

/**
 * Preview for a date input.
 */
export default function PredefinedListInputPreview({ spec }: { spec: IFormInputSpecPredefinedList }) {
  const { getAction } = useApi();
  const { deviceType } = useTemplateEditorData();
  const [value, setValue] = useState<{ id: string; name: string }[]>([]);

  const valueWithDuplicates = useMemo(() => {
    if (!spec.allowDuplicates || value.length < 2) {
      return value;
    }

    const newValue = [...value];
    newValue[1] = { id: nanoid(), name: newValue[0].name };

    return newValue;
  }, [value, spec.allowDuplicates]);

  useEffect(() => {
    const action = getAction('PredefinedDataListRevisionData');

    const filters: Record<string, string | number> = {
      'deviceTypeId.eq': deviceType.id,
      'predefinedDataTypeId.eq': spec.dataTypeId,
    };

    action({ query: { filters } })
      .then((response) => setValue(response.slice(0, 3).map(({ key }) => ({ name: key, id: nanoid() }))))
      .catch(panic);
  }, [spec.dataTypeId]);

  return (
    <Stack spacing={8}>
      {valueWithDuplicates.map(({ id, name }) => (
        <Group key={id} align="start" spacing={4} w="100%">
          <SimpleGrid w="calc(100% - 32px)" spacing={8} cols={spec.withMoreInfo ? 2 : 1}>
            <PredefinedDataSelect
              size="sm"
              deviceTypeId={deviceType.id}
              predefinedDataType={Number(spec.dataTypeId)}
              value={name}
              hideDescription
            />
            {spec.withMoreInfo && <TextInput size="sm" placeholder="Zadajte vlastný popis (nepovinné)" />}
          </SimpleGrid>
          <ActionIcon size="sm" p={4} w={24} h={24} mt={4} variant="subtle-gray" style={{ opacity: 0.3 }}>
            <IconX size={16} />
          </ActionIcon>
        </Group>
      ))}
      <Group style={{ opacity: 0.3 }}>
        <Button size="sm" variant="subtle-gray" leftIcon={<IconPlus />}>
          Pridať predvolný údaj
        </Button>
      </Group>
    </Stack>
  );
}
