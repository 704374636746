import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataUpdateGlobalPayload } from 'api/actions/predefined-data-update-global/predefined-data-update-global-payload';
import { PredefinedDataUpdateGlobalPathParameters } from 'api/actions/predefined-data-update-global/predefined-data-update-global-path-parameters';
import { PredefinedDataUpdateGlobalResponse } from 'api/actions/predefined-data-update-global/predefined-data-update-global-response';

type PredefinedDataUpdateGlobalQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataUpdateGlobal action.
 */
export const predefinedDataUpdateGlobalActionPath = new SweetPath<keyof PredefinedDataUpdateGlobalPathParameters>(
  '/api/predefined-data/update/global/{globalPredefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataUpdateGlobal actions.
 */
export default function createPredefinedDataUpdateGlobalAction(axios: AxiosInstance) {
  return function predefinedDataUpdateGlobalAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataUpdateGlobalPathParameters;
    query?: PredefinedDataUpdateGlobalQueryParameters;
    payload: PredefinedDataUpdateGlobalPayload;
  }) {
    const path = predefinedDataUpdateGlobalActionPath.insert(parameters);

    return axios.put<
      PredefinedDataUpdateGlobalResponse,
      PredefinedDataUpdateGlobalResponse,
      PredefinedDataUpdateGlobalPayload
    >(path, payload);
  };
}
