import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import { FormMessage } from 'components/forms/FormProps';
import { ChangePasswordFormData } from 'components/forms/my-profile/ChangePasswordForm';
import MyProfileForm, { MyProfileFormData } from 'components/forms/my-profile/MyProfileForm';
import { MY_PROFILE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';
import {
  DUPLICATE_USER_ERROR_CODE,
  ERROR_NOTIFICATION_COLOR,
  INVALID_PASSWORD_ERROR_CODE,
  SUCCESS_NOTIFICATION_COLOR,
} from 'utils/constants';

/**
 * The personal profile page.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=145%3A3623 Figma Design}
 * - {@link https://www.notion.so/Profile-My-Profile-73a3012c8d9c45108fc31c1352e465eb?pvs=4 Notion Page}
 */
export default function MyProfilePage() {
  const navigate = useNavigate();
  const { getAction, fullName } = useApi();
  const [passwordChangeMessage, setPasswordChangeMessage] = useState<FormMessage | undefined>(undefined);

  const { data, loading, error } = useImmediateAction(() => {
    const userGetMeAction = getAction('AuthLoggedUserInfo');
    return userGetMeAction();
  });

  /**
   * Handles the form submission.
   */
  function onSubmit({ email, firstName, lastName, phoneNumber, postNominals, preNominals }: MyProfileFormData) {
    const userUpdateMeAction = getAction('UserUpdateMe');

    userUpdateMeAction({
      payload: {
        preNominals,
        postNominals,
        firstName,
        lastName,
        email,
        phoneNumber,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Profil bol aktualizovaný',
          message: 'Vaše osobné informácie boli úspešne aktualizované.',
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (MY_PROFILE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(DASHBOARD_PAGE_PATH.original);
        }
      })
      .catch((error) => {
        if (error.response.data.error.code === DUPLICATE_USER_ERROR_CODE) {
          notifications.show({
            title: 'Chyba!',
            message: 'Používateľ s danou emailovou adresou už existuje.',
            color: ERROR_NOTIFICATION_COLOR,
          });
        } else {
          panic(error);
        }
      });
  }

  /**
   * Handles the password change.
   */
  function changePassword({ password, newPassword, newPasswordRepeat }: ChangePasswordFormData) {
    const userChangePasswordAction = getAction('UserChangePassword');

    return userChangePasswordAction({ payload: { oldPassword: password, newPassword, newPasswordRepeat } })
      .then(() => {
        setPasswordChangeMessage({ type: 'success', message: 'Heslo bolo úspešne zmenené.' });
      })
      .catch((e) => {
        if (e?.response?.data?.error?.code === INVALID_PASSWORD_ERROR_CODE) {
          setPasswordChangeMessage({ type: 'fail', message: 'Zadali ste nesprávne heslo. Heslo nebolo zmenené.' });
        } else {
          panic(e);
        }
      });
  }

  // Display the error if there is one.
  useEffect(() => {
    if (error) {
      panic(error);
    }
  }, [error]);

  return (
    <DashboardLayout
      title="Editácia osobných údajov"
      breadcrumbs={[{ title: fullName || 'Profile' }, { title: 'Editácia osobných údajov' }]}
    >
      {!loading && !error && (
        <MyProfileForm
          initialValues={{
            preNominals: data.preNominals ?? '',
            postNominals: data.postNominals ?? '',
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber ?? '',
          }}
          passwordChangeMessage={passwordChangeMessage}
          onSubmit={onSubmit}
          onPasswordChange={changePassword}
        />
      )}
    </DashboardLayout>
  );
}
