import { Tooltip } from '@mantine/core';
import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignCenter } from '@tabler/icons-react';

/**
 * Used to align text to the middle.
 */
export default function ControlAlignCenter() {
  const { editor } = useRichTextEditorContext();

  return (
    <Tooltip label="Align center" openDelay={300} closeDelay={50} withArrow={false}>
      <RichTextEditor.Control
        onClick={() => editor?.chain().focus().setTextAlign('center').run()}
        active={editor?.isActive({ textAlign: 'center' })}
      >
        <IconAlignCenter size={20} />
      </RichTextEditor.Control>
    </Tooltip>
  );
}
