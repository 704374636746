import { ActionIcon, Button, Group, Stack, TextInput, Tooltip } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import P3Regular from 'components/typography/P3Regular';
import { noop } from 'lodash';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Parameters of the SelectInputOptions component.
 */
interface SelectInputOptionsProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}

/**
 * Input used to specify the options of a select input.
 */
function SelectInputOptions({ value = ['', '', ''], onChange = noop }: SelectInputOptionsProps) {
  /** Updates the value of the nth option. */
  const updateNthOption = (index: number, option: string) => {
    const newValue = [...value];
    newValue[index] = option;
    onChange(newValue);
  };

  /** Removes the nth option. */
  const removeNthOption = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onChange(newValue);
  };

  /** Adds a new option. */
  const addOption = () => onChange([...value, '']);

  return (
    <Stack spacing={8}>
      <P3Regular color="gray.6">Špecifikujte možnosti</P3Regular>
      {value.map((option, i) => (
        <Group key={i} spacing={8}>
          <TextInput w="calc(100% - 40px)" value={option} onChange={(e) => updateNthOption(i, e.currentTarget.value)} />
          <Tooltip label="Odstrániť možnosť" withArrow={false} openDelay={300}>
            <ActionIcon w={32} variant="subtle-gray" size="sm" onClick={() => removeNthOption(i)}>
              <IconTrash size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
      ))}
      <Button variant="secondary" size="sm" leftIcon={<IconPlus />} mr={40} onClick={addOption}>
        Pridať možnosť
      </Button>
    </Stack>
  );
}

/**
 * Sub-form for number input properties
 */
export default function NumberInputPropertiesSubForm({ form }: { form: UseFormReturnType<IFormInputSpec> }) {
  return <SelectInputOptions {...form.getInputProps('options')} />;
}
