import { useForm } from '@mantine/form';
import { IconCheck, IconPlus } from '@tabler/icons-react';
import { isEmpty, noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import { useConfirm } from 'components/modals/message/MessageProvider';
import useLoadingAction from 'hooks/use-loading-action';
import { useDisclosure } from '@mantine/hooks';
import { useCallback, useEffect, useMemo } from 'react';
import FormInputGroup from 'components/forms/FormInputGroup';
import { Box, Button, SimpleGrid, TextInput } from '@mantine/core';
import FormWrapper from 'components/forms/FormWrapper';
import ThreeWaySelect, { SelectedEntityRow } from 'components/forms/device-subtype/ThreeWaySelect';
import P1Regular from 'components/typography/P1Regular';
import { Stack } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import P1Medium from 'components/typography/P1Medium';
import PickOrganizations from 'components/inputs/multi-checkbox/PickOrganizations';
import slugify from 'slugify';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR } from 'env';

export interface RevisionTemplateFormData {
  name: string;
  restriction: 'global' | 'organization';
  organizations: { organizationId: number; organizationName: string }[];
  slug: string;
  deviceTypeId: string;
}

export const revisionTemplateInitialValues = {
  name: '',
  restriction: 'global' as const,
  organizations: [],
  slug: '',
  deviceTypeId: '',
};

export interface RevisionTemplateFormProps extends FormProps<RevisionTemplateFormData> {
  readOnly?: Partial<Record<keyof RevisionTemplateFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
}

/**
 * Form for editing a revision template.
 */
export default function RevisionTemplateForm({
  initialValues = revisionTemplateInitialValues,
  onSubmit = noop,
  readOnly = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" size={24} />,
}: RevisionTemplateFormProps) {
  const { confirm } = useConfirm();
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const [openedOrganizations, { close: closeOrganizations, open: openOrganizations }] = useDisclosure(false);

  const form = useForm({
    initialValues,
    validate: {
      name: createValidator([required]),
      organizations: (value) => {
        if (form.values.restriction === 'organization' && isEmpty(value)) {
          return 'Vyberte aspoň jednu organizáciu';
        }

        return null;
      },
    },
  });

  /**
   * Confirms the removal of the organization from the list of selected organizations.
   */
  const confirmRemoveOrganization = useCallback(
    (organizationName: string, index: number) => {
      confirm({
        title: 'Odstrániť organizáciu',
        content: `Naozaj chcete odstrániť organizáciu ${organizationName} zo zoznamu?`,
        onConfirm: () => form.removeListItem('organizations', index),
      });
    },
    [confirm]
  );

  const addOrganizationButton = useMemo(
    () => (
      <Box>
        <Button onClick={openOrganizations} variant="subtle" leftIcon={<IconPlus />}>
          Pridať organizácie
        </Button>
      </Box>
    ),
    [openOrganizations]
  );

  useEffect(() => {
    if (form.values.name !== '') {
      const slug = slugify(form.values.name, { lower: true });

      form.setFieldValue('slug', slug);
    }
  }, [form.values.name]);

  return (
    <FormWrapper
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné informácie">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov šablóny"
            placeholder="Názov šablóny"
            size="lg"
            name="amperia-name"
            {...form.getInputProps('name')}
          />
          {FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR && (
            <DeviceTypeSelect
              label="Zariadenie"
              placeholder="Vyberte zariadenie"
              size="lg"
              name="amperia-device-type"
              disabled={readOnly.deviceTypeId}
              readOnly={readOnly.deviceTypeId}
              {...form.getInputProps('deviceTypeId')}
            />
          )}
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup groupTitle="Vymedzenie pre:">
        <SimpleGrid cols={2} spacing={40}>
          <ThreeWaySelect {...form.getInputProps('restriction')} hideDepartment />
        </SimpleGrid>
        {form.values.restriction === 'global' && (
          <P1Regular color="gray.6">Šablóna bude dostupná pre všetky organizácie.</P1Regular>
        )}
        {form.values.restriction === 'organization' &&
          (isEmpty(form.values.organizations) ? (
            <Stack align="flex-start">
              <P1Regular color="gray.6">
                Zatiaľ nie sú pridané žiadne organizácie. Táto šablóna bude dostupná len pre vybrané organizácie.
              </P1Regular>
              <P2Regular color="red.8">{form.errors.organizations}</P2Regular>
              {addOrganizationButton}
            </Stack>
          ) : (
            <Stack pt={10} spacing={24}>
              <Box>
                <Box bg="gray.0" style={{ borderRadius: '4px' }} p={16}>
                  <P1Medium color="gray.8">Organizácia</P1Medium>
                </Box>

                {form.values.organizations.map((organization, index) => (
                  <SelectedEntityRow
                    key={organization.organizationId}
                    name={organization.organizationName}
                    deleteText="Zmazať organizáciu"
                    onDelete={() => {
                      confirmRemoveOrganization(organization.organizationName, index);
                    }}
                  />
                ))}
              </Box>

              {addOrganizationButton}
            </Stack>
          ))}
      </FormInputGroup>

      <PickOrganizations
        opened={openedOrganizations}
        onClose={closeOrganizations}
        onPick={(organizations) => {
          for (const item of organizations) {
            form.insertListItem('organizations', item);
          }
        }}
        excludeIds={form.values.organizations.map(({ organizationId }) => organizationId)}
      />

      {/* TODO inputs for formScheme, templateScheme and visualData */}
    </FormWrapper>
  );
}
