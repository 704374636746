import { Button, Group, Stack } from '@mantine/core';
import { RevisionGetResponse as Revision } from 'api/actions/revision-get/revision-get-response';
import DeviceFiles from 'components/forms/device/DeviceFiles';
import { DeviceFormData } from 'components/forms/device/DeviceForm';
import H3Medium from 'components/typography/H3Medium';
import { groupBy } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { EDIT_DEVICE_PAGE_PATH } from 'routes/paths';

type Device = Revision['devices'][number];

/**
 * A single device in a revision.
 */
function RevisionDevice({ device }: { device: Device }) {
  const deviceFiles: DeviceFormData['files'] = useMemo(
    () =>
      groupBy(
        device.files?.map((file) => ({
          fileId: file.fileId,
          fileName: file.fileName,
          originalFileName: file.fileName,
          uploadedAt: new Date(file.created!),
          key: file.fileId,
          fileType: file.fileType,
          isMainImage: file.isMainImage,
        })),
        'fileType'
      ) as any,
    [device.files]
  );

  const detailLink = useMemo(() => EDIT_DEVICE_PAGE_PATH.insert({ deviceId: device.deviceId }), [device.deviceId]);

  return (
    <Stack spacing={24} pt={24} px={40} pb={40} bg="white" style={{ borderRadius: '4px' }}>
      <Group position="apart" spacing={16}>
        <H3Medium color="gray.7">Zariadenie: {device.deviceName}</H3Medium>
        <Button component={Link} to={detailLink} size="md" variant="subtle">
          Zobraziť detail zariadenia
        </Button>
      </Group>
      <DeviceFiles context="revision" deviceId={device.deviceId} files={deviceFiles} readonly initialTab="revisions" />
    </Stack>
  );
}

/**
 * Component for displaying devices in a revision.
 */
export default function RevisionDevices({ revision }: { revision: Revision }) {
  return (
    <Stack spacing={24}>
      {revision.devices.map((device) => (
        <RevisionDevice key={device.deviceId} device={device} />
      ))}
    </Stack>
  );
}
