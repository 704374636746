import { useState } from 'react';

/**
 * Decorates a function to dispatch a loading action before and after the
 * function is called.
 */
export default function useLoadingAction<TArgs extends any[], TReturn>(
  action: (...args: TArgs) => TReturn | Promise<TReturn>
): [{ loading: boolean; success: boolean | undefined }, (...args: TArgs) => Promise<TReturn>] {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  /**
   * Handle the form submission.
   */
  async function decoratedAction(...args: TArgs) {
    setSuccess(undefined);
    setLoading(true);

    try {
      const res = await action(...args);
      setSuccess(true);
      return res;
    } catch (e) {
      setSuccess(false);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  return [{ loading, success }, decoratedAction];
}
