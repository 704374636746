import { Button, ButtonProps } from '@mantine/core';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Parameters of the ButtonWithConfirm component.
 */
export interface ButtonWithConfirmProps extends Omit<ButtonProps, 'click'> {
  confirmMessage: string;
  confirmTitle?: string;
  skipConfirm?: boolean;
  onConfirm?: () => void;
}

/**
 * Button component which shows a confirmation dialog before executing the
 * given callback.
 */
export default function ButtonWithConfirm({
  confirmMessage,
  confirmTitle = '',
  skipConfirm = false,
  onConfirm = noop,
  ...props
}: ButtonWithConfirmProps) {
  const { confirm } = useConfirm();

  /**
   * Handle the user's click on the link.
   */
  const click = useCallback(() => {
    if (skipConfirm) {
      onConfirm();
    } else {
      confirm({
        title: confirmTitle,
        content: confirmMessage,
        onConfirm,
      });
    }

    return false;
  }, [confirmMessage, confirmTitle, onConfirm]);

  return <Button {...props} onClick={click} />;
}
