import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { isString, noop } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PredefinedDataCustomInputRow from 'components/inputs/predefined-data-custom-input/PredefinedDataCustomInputRow';
import { Button, Group, MantineSize, Skeleton, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { nanoid } from 'nanoid';

export interface PredefinedDataOption {
  value: string; // = name
  label: string;
  group: string;
  description: string;
}

export interface PredefinedDataTriplet {
  name: string; // = value
  description?: string;
  moreInfo?: string;
  id?: string;
}

interface PredefinedDataCustomInputProps {
  deviceTypeId: number;
  predefinedDataType: string | number;
  withMoreInfo?: boolean;
  moreInfoMultiline?: boolean;
  allowDuplicates?: boolean;
  size?: MantineSize;
  value?: PredefinedDataTriplet[];
  onChange?: (value: PredefinedDataTriplet[]) => void;
}

/**
 * Custom input for predefined data in revisions.
 */
export default function PredefinedDataCustomInput({
  deviceTypeId,
  predefinedDataType,
  withMoreInfo = false,
  moreInfoMultiline = false,
  allowDuplicates = false,
  size = 'lg',
  value = [],
  onChange = noop,
}: PredefinedDataCustomInputProps) {
  const { getAction } = useApi();
  const [options, setOptions] = useState<PredefinedDataOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [autoFocus, setAutoFocus] = useState<number | null>(null);

  const optionsByName = useMemo(() => new Map(options.map((option) => [option.value, option])), [options]);

  const excludeNames = useMemo(
    () => new Set(allowDuplicates ? [] : value.map(({ name }) => name)),
    [allowDuplicates, value]
  );

  const availableOptions = useMemo(
    () => options.filter(({ value }) => !excludeNames.has(value)),
    [excludeNames, options]
  );

  // Fetch the options.
  useEffect(() => {
    setLoading(true);

    const action = getAction('PredefinedDataListRevisionData');

    const filters: Record<string, string | number> = {
      'deviceTypeId.eq': deviceTypeId,
    };

    if (isString(predefinedDataType)) {
      filters['predefinedDataType.eq'] = predefinedDataType;
    } else {
      filters['predefinedDataTypeId.eq'] = predefinedDataType;
    }

    action({ query: { filters } })
      .then((response) => {
        const options = response.map(({ key, value, origin }) => ({
          value: key,
          label: key,
          group: origin === 'global' ? 'Globálne' : 'Osobné',
          description: value ?? '',
        }));

        options.sort((a, b) => a.value.localeCompare(b.value));

        setOptions(options);
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, [deviceTypeId, predefinedDataType]);

  // Set IDs for the rows if they are not set.
  useEffect(() => {
    if (value.some(({ id }) => !id)) {
      onChange(value.map((item) => ({ ...item, id: item.id ?? nanoid() })));
    }
  }, [value]);

  // Reset the autofocus.
  useEffect(() => {
    setAutoFocus(null);
  }, [value]);

  /**
   * Adds a new row to the list.
   */
  const addRow = useCallback(() => {
    onChange([...value, { id: nanoid(), name: '', description: '', moreInfo: '' }]);
    setAutoFocus(value.length);
  }, [value]);

  return (
    <>
      {loading ? (
        <Stack spacing={16}>
          <Skeleton w="full" h={48} radius={4} animate />
          <Skeleton w="full" h={48} radius={4} animate />
          <Skeleton w="full" h={48} radius={4} animate />
        </Stack>
      ) : (
        <Stack>
          {value
            .filter(({ id }) => !!id)
            .map((item, index) => {
              return (
                <PredefinedDataCustomInputRow
                  key={item.id}
                  value={item}
                  options={
                    allowDuplicates
                      ? options
                      : ([...availableOptions, optionsByName.get(item.name)].filter(Boolean) as PredefinedDataOption[])
                  }
                  optionExists={item.name === '' || optionsByName.has(item.name)}
                  moreInfoMultiline={moreInfoMultiline}
                  withMoreInfo={withMoreInfo}
                  autoFocus={autoFocus === index}
                  onChange={(newItem) =>
                    onChange(value.map((oldItem) => (oldItem.id === newItem.id ? newItem : oldItem)))
                  }
                  onRowDelete={() => onChange(value.filter((oldItem) => oldItem.id !== item.id))}
                  size={size}
                />
              );
            })}
          {availableOptions.length > 0 && (
            <Group>
              <Button variant="subtle" leftIcon={<IconPlus />} onClick={addRow}>
                Pridať nový riadok
              </Button>
            </Group>
          )}
        </Stack>
      )}
    </>
  );
}
