import { SimpleGrid, Stack } from '@mantine/core';
import { RevisionGetResponse as Revision } from 'api/actions/revision-get/revision-get-response';
import H3Medium from 'components/typography/H3Medium';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import { useMemo } from 'react';

/**
 * Displays the changes of revision plans made when the revision has been completed.
 */
export default function RevisionPlanChangeLog({ revision }: { revision: Revision }) {
  const gridStyle = useMemo(
    () => ({
      borderBottom: '1px solid var(--mantine-color-gray-1)',
      alignItems: 'flex-start',
    }),
    []
  );

  if (!revision.revisionPlanChangeLogs || revision.revisionPlanChangeLogs.length === 0) {
    return null;
  }

  return (
    <Stack pt={24} pb={40} px={40} bg="white" style={{ borderRadius: '4px' }} spacing={24}>
      <H3Medium color="gray.7">Zmeny termínov zariadení</H3Medium>
      <Stack spacing={0}>
        <SimpleGrid spacing={24} p={16} cols={5} bg="gray.0">
          <P1Medium>Termín</P1Medium>
          <P1Medium>Pôvodný dátum</P1Medium>
          <P1Medium>Pôvodná perióda</P1Medium>
          <P1Medium>Nový dátum</P1Medium>
          <P1Medium>Nová perióda</P1Medium>
        </SimpleGrid>
        {revision.revisionPlanChangeLogs.map((log) => {
          return (
            <SimpleGrid key={log.revisionPlanChangeLogId} spacing={24} py={24} px={16} style={gridStyle} cols={5}>
              <P2Regular>{log.typeBefore}</P2Regular>
              <P2Regular>{log.nextRevisionBefore ?? '-'}</P2Regular>
              <P2Regular>{log.periodBefore}</P2Regular>
              <P2Regular>{log.nextRevisionAfter ?? '-'}</P2Regular>
              <P2Regular>{log.periodAfter}</P2Regular>
            </SimpleGrid>
          );
        })}
      </Stack>
    </Stack>
  );
}
