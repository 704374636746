import { ActionIcon, Button, Group, Menu, Portal, Text } from '@mantine/core';
import {
  IconBackspace,
  IconDotsVertical,
  IconMapPinPlus,
  IconMapPins,
  IconPencil,
  IconTrashX,
  IconUserPlus,
  IconUsers,
} from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  ADD_DEPARTMENT_PAGE_PATH,
  ADD_USER_PAGE_PATH,
  DEPARTMENTS_PAGE_PATH,
  EDIT_ORGANIZATION_PAGE_PATH,
  USERS_PAGE_PATH,
} from 'routes/paths';
import { notifications } from '@mantine/notifications';
import { CANNOT_SET_ENTITY_STATUS, ERROR_NOTIFICATION_COLOR } from 'utils/constants';
import { useAcknowledge, useConfirm } from 'components/modals/message/MessageProvider';

/**
 * Parameters of the OrganizationActions component.
 */
interface OrganizationActionsProps {
  organizationId: number;
  organizationName: string;
  canDiscard: boolean;
  status: boolean;
  onStatusChange?: (deleted: boolean) => void;
}

/**
 * The actions which can be performed on a single row.
 */
export default function OrganizationActions({
  organizationId,
  organizationName,
  canDiscard,
  status,
  onStatusChange = noop,
}: OrganizationActionsProps) {
  const { getAction } = useApi();
  const { confirm } = useConfirm();
  const { acknowledge } = useAcknowledge();

  /**
   * Makes a call to the API to set the status of the organization.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const organizationSetStatus = getAction('OrganizationSetStatus');

      organizationSetStatus({ parameters: { organizationId: String(organizationId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch((e) => {
          if (e.response.data.error.code === CANNOT_SET_ENTITY_STATUS) {
            notifications.show({
              title: 'Chyba!',
              message: 'Túto organizáciu nemôžete deaktivovať.',
              color: ERROR_NOTIFICATION_COLOR,
            });
          } else {
            panic(e);
          }
        });
    },
    [getAction, organizationId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      if (!status && !canDiscard) {
        acknowledge({
          title: 'Nemožno vyradiť organizáciu',
          content: 'Organizácia má priradené strediská. Vyraďte najskôr strediská a potom organizáciu.',
        });
      } else {
        const title = status ? 'Zaradiť organizáciu' : 'Vyradiť organizáciu';
        const message = status ? 'Naozaj chcete zaradiť organizáciu?' : 'Naozaj chcete vyradiť organizáciu?';

        confirm({
          title,
          content: message,
          onConfirm: () => setStatusRemote(status),
        });
      }
    },
    [confirm, setStatusRemote]
  );

  return (
    <Group spacing={12} noWrap>
      <Button
        size="md"
        variant="secondary"
        disabled={!status}
        component={Link}
        to={EDIT_ORGANIZATION_PAGE_PATH.insert({ organizationId })}
        leftIcon={<IconPencil />}
      >
        Editovať
      </Button>
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {organizationName}
              </Text>
            </Menu.Label>
            {status && (
              <>
                <Menu.Item
                  component={Link}
                  to={`${ADD_DEPARTMENT_PAGE_PATH.original}?organizationId=${organizationId}`}
                  icon={<IconMapPinPlus stroke={1.5} size={20} />}
                  color="gray.8"
                >
                  Pridať stredisko
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  to={`${DEPARTMENTS_PAGE_PATH.original}?organizationId=${organizationId}`}
                  icon={<IconMapPins stroke={1.5} size={20} />}
                  color="gray.8"
                >
                  Zobraziť strediská
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  component={Link}
                  to={`${ADD_USER_PAGE_PATH.original}?organizationId=${organizationId}`}
                  icon={<IconUserPlus stroke={1.5} size={20} />}
                  color="gray.8"
                >
                  Pridať užívateľa
                </Menu.Item>
                <Menu.Item
                  component={Link}
                  to={`${USERS_PAGE_PATH.original}?organizationId=${organizationId}`}
                  icon={<IconUsers stroke={1.5} size={20} />}
                  color="gray.8"
                >
                  Zobraziť užívateľov
                </Menu.Item>
                <Menu.Divider />
              </>
            )}
            <Menu.Item
              color={status ? 'red.8' : 'blue.8'}
              onClick={() => confirmSetStatus(!status)}
              icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
            >
              {status ? 'Vyradiť' : 'Zaradiť'}
            </Menu.Item>
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
