import { Button, Divider, Group, Modal, Radio, Stack } from '@mantine/core';
import { DEVICE_TYPES, DeviceType, getDeviceType } from 'model/DeviceType';
import { useEffect, useState } from 'react';

interface PickDeviceTypeModalParams {
  title: string;
  addButtonLabel: string;
  opened: boolean;
  onClose: () => void;
  onPick: (value: DeviceType) => void;
}

/**
 * Modal for selecting the type of technician's certificate.
 */
export default function PickDeviceTypeModal({
  title,
  addButtonLabel,
  opened,
  onClose,
  onPick,
}: PickDeviceTypeModalParams) {
  const [value, setValue] = useState<DeviceType | undefined>(undefined);

  // Reset the value when the modal is closed.
  useEffect(() => {
    if (!opened) {
      setValue(undefined);
    }
  }, [opened]);

  return (
    <Modal opened={opened} onClose={onClose} title={title} centered>
      <Divider color="gray.1" mb={24} />
      <Stack spacing={24}>
        <Radio.Group value={value?.slug} onChange={(slug) => setValue(getDeviceType(slug))}>
          <Stack>
            {DEVICE_TYPES.map(({ id, slug, name }) => (
              <Radio key={id} value={slug} label={name} name={name} />
            ))}
          </Stack>
        </Radio.Group>
        <Group spacing={40} position="apart">
          <Button variant="subtle-gray" onClick={onClose}>
            Zrušiť
          </Button>
          <Button
            variant="primary"
            size="md"
            onClick={() => {
              onPick(value!);
              onClose();
            }}
            disabled={!value}
          >
            {addButtonLabel}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
}
