import { ActionIcon, Box, Group, Select, Stack, TextInput, Textarea, Tooltip } from '@mantine/core';
import {
  PredefinedDataOption,
  PredefinedDataTriplet,
} from 'components/inputs/predefined-data-custom-input/PredefinedDataCustomInput';
import { IconTrashX } from '@tabler/icons-react';
import { noop } from 'lodash';
import { useCallback, useMemo } from 'react';
import P3Regular from 'components/typography/P3Regular';
import QuestionMarkTooltip from 'components/QuestionMarkTooltip';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { MantineSize } from '@mantine/core';

interface PredefinedDataCustomInputRowProps {
  value: PredefinedDataTriplet;
  options: PredefinedDataOption[];
  optionExists: boolean;
  withMoreInfo?: boolean;
  moreInfoMultiline?: boolean;
  autoFocus?: boolean;
  size?: MantineSize;
  onChange?: (value: PredefinedDataTriplet) => void;
  onRowDelete?: () => void;
}

/**
 * Single row in the PredefinedDataCustomInput.
 */
export default function PredefinedDataCustomInputRow({
  value,
  options,
  optionExists,
  withMoreInfo = false,
  moreInfoMultiline = false,
  autoFocus = false,
  size = 'lg',
  onChange = noop,
  onRowDelete = noop,
}: PredefinedDataCustomInputRowProps) {
  const { confirm } = useConfirm();

  const questionMarkText = useMemo(
    () => `Predvolená hodnota "${value.name}" bola odstránená alebo upravená`,
    [value.name]
  );

  const confirmRowDelete = useCallback(() => {
    if (value.name === '') {
      onRowDelete();
    } else {
      confirm({
        title: 'Naozaj chcete odstrániť tento riadok?',
        content: 'Táto akcia je nevratná.',
        onConfirm: onRowDelete,
      });
    }
  }, [value, confirm, onRowDelete]);

  const moreInfoInput = useMemo(() => {
    if (!withMoreInfo) {
      return <></>;
    }

    /** Implements the on change callback for both textarea and input */
    const onChangeImpl = (e: { currentTarget: { value: string } }) =>
      onChange({ ...value, moreInfo: e.currentTarget.value });

    const placeholder = 'Zadajte vlastný popis (nepovinné)';
    const readOnly = !optionExists;
    const val = value.moreInfo;

    if (moreInfoMultiline) {
      return (
        <Box ml={40}>
          <Textarea
            size={size}
            value={val}
            onChange={onChangeImpl}
            placeholder={placeholder}
            readOnly={readOnly}
            autosize
          />
        </Box>
      );
    }

    return <TextInput size={size} value={val} onChange={onChangeImpl} placeholder={placeholder} readOnly={readOnly} />;
  }, [withMoreInfo, moreInfoMultiline, value, onChange, optionExists, size]);

  const gridStylesDef = useMemo(() => {
    if (moreInfoMultiline) {
      return { gridTemplateColumns: '1fr', gap: '5px' };
    }

    return { gridTemplateColumns: `1fr ${withMoreInfo ? '1fr' : ''} auto`, gap: '16px', alignItems: 'flex-start' };
  }, [withMoreInfo, moreInfoMultiline]);

  const helpAndRowDelete = useMemo(
    () => (
      <Group spacing={8} w={80}>
        <Box w={16}>{!optionExists && <QuestionMarkTooltip multiline width={220} label={questionMarkText} />}</Box>
        <Tooltip label="Odstrániť riadok" withArrow={false} openDelay={200} offset={4}>
          <ActionIcon size={size} variant="danger-secondary" onClick={confirmRowDelete}>
            <IconTrashX />
          </ActionIcon>
        </Tooltip>
      </Group>
    ),
    [optionExists, questionMarkText, confirmRowDelete, size]
  );

  return (
    <Box display="grid" sx={gridStylesDef}>
      <Box sx={moreInfoMultiline ? { display: 'grid', gridTemplateColumns: '1fr 80px', gap: '16px' } : {}}>
        <Stack spacing={4}>
          {optionExists ? (
            <Select
              data={options}
              value={value.name}
              size={size}
              searchable
              autoFocus={autoFocus}
              onChange={(name) => {
                if (name) {
                  const description = options.find((item) => item.value === name)?.description ?? '';
                  onChange({ ...value, name, description });
                }
              }}
            />
          ) : (
            <TextInput value={value.name} size={size} readOnly />
          )}
          {value.description !== '' && (
            <P3Regular pl={24} pr={16} color="gray.6">
              {value.description}
            </P3Regular>
          )}
        </Stack>
        {moreInfoMultiline && helpAndRowDelete}
      </Box>
      {moreInfoInput}
      {!moreInfoMultiline && helpAndRowDelete}
    </Box>
  );
}
