import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataCreateUsersPayload } from 'api/actions/predefined-data-create-users/predefined-data-create-users-payload';
import { PredefinedDataCreateUsersPathParameters } from 'api/actions/predefined-data-create-users/predefined-data-create-users-path-parameters';
import { PredefinedDataCreateUsersResponse } from 'api/actions/predefined-data-create-users/predefined-data-create-users-response';

type PredefinedDataCreateUsersQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataCreateUsers action.
 */
export const predefinedDataCreateUsersActionPath = new SweetPath<keyof PredefinedDataCreateUsersPathParameters>(
  '/api/predefined-data/create/users',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataCreateUsers actions.
 */
export default function createPredefinedDataCreateUsersAction(axios: AxiosInstance) {
  return function predefinedDataCreateUsersAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: PredefinedDataCreateUsersQueryParameters;
    payload: PredefinedDataCreateUsersPayload;
  }) {
    const path = predefinedDataCreateUsersActionPath.original;

    return axios.post<
      PredefinedDataCreateUsersResponse,
      PredefinedDataCreateUsersResponse,
      PredefinedDataCreateUsersPayload
    >(path, payload);
  };
}
