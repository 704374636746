import { IFormInputSpecDatePlusPeriod } from 'pages/revisions-module/template-editor/editors/form/types';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';
import { useEffect, useState } from 'react';
import { useCounter, useInterval } from '@mantine/hooks';
import { addMonths, isValid } from 'date-fns';
import { DateInput, stringifyDate } from 'components/inputs/DateInput';

/**
 * Number input for the revision form.
 */
export default function RevisionFieldDatePlusPeriod({ spec, name }: RevisionFieldProps<IFormInputSpecDatePlusPeriod>) {
  const { form } = useFillOutRevisionDataProvider();
  const [value, setValue] = useState(form.values.fields[name] || '');
  const [refreshToken, { increment: forceRefresh }] = useCounter();

  const refreshInterval = useInterval(forceRefresh, 1000);

  useEffect(() => {
    refreshInterval.start();
    return refreshInterval.stop;
  }, []);

  useEffect(() => {
    const date = new Date(form.values.fields[spec.dateInput]);
    const period = Number(form.values.fields[spec.periodInput]);
    const checked = spec.checkboxInput ? !!form.values.fields[spec.checkboxInput] : true;

    if (checked && isValid(date) && !isNaN(period)) {
      setValue(stringifyDate(addMonths(date, period)));
    } else {
      setValue('');
    }
  }, [refreshToken]);

  // Use indirect state change to avoid unnecessary re-renders.
  useEffect(() => {
    form.setFieldValue(name, value);
  }, [value]);

  return <DateInput size="lg" required={spec.required} readOnly label={spec.label} {...form.getInputProps(name)} />;
}
