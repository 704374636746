import { ADD_ORGANIZATION_PAGE_PATH } from 'routes/paths';
import { ColDef } from 'ag-grid-community';
import { useApi } from 'api/api-context';
import DataTable from 'components/tables/DataTable';
import QualificationsSelectFilter from 'components/tables/filters/QualificationsSelectFilter';
import { useCallback, useMemo } from 'react';
import { getDeviceType } from 'model/DeviceType';
import SelfAdministrationSelectFilter from 'components/tables/filters/SelfAdministrationSelectFilter';
import { OrganizationRow } from 'components/tables/organization/types';
import OrganizationIdColumn from 'components/tables/organization/columns/OrganizationIdColumn';
import OrganizationNameColumn from 'components/tables/organization/columns/OrganizationNameColumn';
import AddressColumn from 'components/tables/organization/columns/AddressColumn';
import QualificationsColumn from 'components/tables/organization/columns/QualificationsColumn';
import DepartmentsColumn from 'components/tables/organization/columns/DepartmentsColumn';
import ActionsColumn from 'components/tables/organization/columns/ActionsColumn';

/**
 * Computes the cell style.
 */
const cellStyle = ({ data: { status } }: { data: OrganizationRow }) => ({ opacity: status ? 1 : 0.6 });

/**
 * The columns of the table.
 */
const columns: ColDef[] = [
  {
    field: 'checkbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    suppressMovable: true,
    width: 44,
    minWidth: 44,
    maxWidth: 44,
    cellStyle,
  },
  {
    field: 'organizationId',
    headerName: 'ID',
    sortable: true,
    unSortIcon: true,
    suppressMovable: true,
    width: 72,
    minWidth: 72,
    maxWidth: 72,
    cellStyle,
    cellRenderer: OrganizationIdColumn,
  },
  { field: 'organizationSelfAdministration', headerName: 'Organizácia sa spravuje sama', hide: true, filter: true },
  { field: 'organizationIncludedInStatistics', headerName: 'Rátať do štatistík', hide: true, filter: true },
  { field: 'organizationInKepHashArchive', headerName: 'KEP hash archív', hide: true, filter: true },
  {
    field: 'organizationName',
    headerName: 'Organizácia',
    minWidth: 350,
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: SelfAdministrationSelectFilter,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: OrganizationNameColumn,
  },
  {
    field: 'streetAddress',
    headerName: 'Ulica',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'city',
    headerName: 'Mesto',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'address',
    headerName: 'Adresa',
    minWidth: 300,
    resizable: true,
    unSortIcon: true,
    wrapText: true,
    cellRenderer: AddressColumn,
  },
  {
    field: 'qualificationSlugs',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'qualificationNames',
    headerName: '',
    filter: true,
    width: 0,
    minWidth: 0,
    maxWidth: 0,
    cellStyle: { opacity: 0 },
  },
  {
    field: 'qualifications',
    headerName: 'Oprávnenia',
    minWidth: 250,
    filter: true,
    floatingFilter: true,
    floatingFilterComponent: QualificationsSelectFilter,
    resizable: true,
    cellStyle,
    cellRenderer: QualificationsColumn,
  },
  {
    field: 'departments',
    headerName: 'Strediská',
    minWidth: 400,
    resizable: true,
    wrapText: true,
    cellStyle,
    cellRenderer: DepartmentsColumn,
  },
  {
    field: 'actions',
    headerName: '',
    pinned: 'right',
    width: 194,
    minWidth: 194,
    maxWidth: 194,
    cellRenderer: ActionsColumn,
  },
  {
    valueGetter: ({ data: { status } }: { data: OrganizationRow }) => (status ? '1' : '0'),
    field: 'status',
    headerName: 'Aktívna',
    filter: true,
    minWidth: 0,
    maxWidth: 0,
    width: 0,
    cellStyle: { opacity: 0 },
  },
];

/**
 * Parameters of the OrganizationsTable component.
 */
export interface OrganizationsTableProps {
  search?: string;
  showDiscarded?: boolean;
}

/**
 * Table which displays organizations.
 */
export default function OrganizationTable({ search = '', showDiscarded = false }: OrganizationsTableProps = {}) {
  const { getAction } = useApi();

  const action = useCallback(async () => {
    const action = getAction('OrganizationList');

    const organizations = await action();

    return organizations.map((organization) => ({
      ...organization,
      qualificationNames: organization.qualifications.map(
        ({ deviceType, deviceTypeId }) =>
          deviceType?.deviceTypeName || getDeviceType(deviceTypeId)?.name || getDeviceType(deviceTypeId)
      ),
      qualificationSlugs: organization.qualifications.map(
        ({ deviceType, deviceTypeId }) => deviceType?.slug || getDeviceType(deviceTypeId)?.slug
      ),
      organizationSelfAdministration: organization.selfAdministration ? 'Áno' : 'Nie',
      organizationIncludedInStatistics: organization.includedInStatistics ? 'Áno' : 'Nie',
      organizationInKepHashArchive: organization.inKepHashArchive ? 'Áno' : 'Nie',
    }));
  }, [getAction]);

  const additionalFilters = useMemo(() => {
    if (!showDiscarded) {
      return {
        status: {
          filterType: 'text',
          type: 'equals',
          filter: '1',
        },
      };
    }

    return { status: null };
  }, [showDiscarded]);

  return (
    <DataTable
      columns={columns}
      action={action}
      addButtonText="Vytvoriť novú"
      addButtonTarget={ADD_ORGANIZATION_PAGE_PATH.original}
      search={search}
      additionalFilters={additionalFilters}
      dataExport={{
        modalTitle: 'Exportovať organizácie',
        fileName: 'organizacie.xlsx',
        columnKeys: [
          'organizationId',
          'organizationName',
          'streetAddress',
          'city',
          'organizationSelfAdministration',
          'organizationIncludedInStatistics',
          'organizationInKepHashArchive',
          'status',
        ],
      }}
    />
  );
}
