import { Box } from '@mantine/core';
import P2Medium from 'components/typography/P2Medium';

interface FaultSeverityLabelProps {
  faultSeverityId: number;
  faultSeverityName: string;
  isFaultFixed: boolean;
}

/**
 * Displays fault severity in a colored label.
 */
export default function FaultSeverityLabel({
  faultSeverityId,
  faultSeverityName,
  isFaultFixed,
}: FaultSeverityLabelProps) {
  const boxStyle = { borderRadius: '4px', maxWidth: 'max-content', paddingLeft: '8px', paddingRight: '8px' };

  if (isFaultFixed) {
    return (
      <Box bg="gray.2" style={boxStyle}>
        <P2Medium color="gray.8">{faultSeverityName}</P2Medium>
      </Box>
    );
  }

  // non-threatening-to-safety-of-persons-and-property
  if (faultSeverityId === 2) {
    return (
      <Box bg="#F5F4DB" style={boxStyle}>
        <P2Medium color="#565C0A">{faultSeverityName}</P2Medium>
      </Box>
    );
  }

  // resulting-from-new-norm
  if (faultSeverityId === 3) {
    return (
      <Box bg="#FCE6CF" style={boxStyle}>
        <P2Medium color="#B85014">{faultSeverityName}</P2Medium>
      </Box>
    );
  }

  // general-threat-to-health-safety-of-persons-and-property
  if (faultSeverityId === 4) {
    return (
      <Box bg="red.1" style={boxStyle}>
        <P2Medium color="red.9">{faultSeverityName}</P2Medium>
      </Box>
    );
  }

  // immediate-threat-to-health-and-property
  if (faultSeverityId === 5) {
    return (
      <Box bg="red.8" style={boxStyle}>
        <P2Medium color="white.0">{faultSeverityName}</P2Medium>
      </Box>
    );
  }

  return (
    <Box bg="gray.2" style={boxStyle}>
      <P2Medium color="gray.8">Neznámy typ</P2Medium>
    </Box>
  );
}
