import { IRowNode } from 'ag-grid-community';
import { OrganizationRow } from 'components/tables/organization/types';
import OrganizationActions from 'components/tables/organization/OrganizationActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ node, data }: { node: IRowNode<OrganizationRow>; data: OrganizationRow }) {
  return (
    <OrganizationActions
      {...data}
      canDiscard={data.canDiscard}
      onStatusChange={(status) => node.setData({ ...data, status })}
    />
  );
}
