import DeviceSubtypesTable from 'components/tables/device-subtypes/DeviceSubtypesTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { DEVICE_TYPES, getDeviceType } from 'model/DeviceType';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DEVICE_TYPES_PAGE_PATH } from 'routes/paths';

/**
 * Page used to list device subtypes for a certain device type.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=3544-35067&mode=design&t=MpCWSR3huDKPVh81-0 Figma Design}
 */
export default function DeviceSubtypesPage() {
  const [search, setSearch] = useState('');
  const [showDiscarded, setShowDiscarded] = useState(false);

  const { deviceTypeId: paramDeviceTypeId } = useParams();
  const deviceTypeId = useMemo(() => Number(paramDeviceTypeId ?? DEVICE_TYPES[0].id), [paramDeviceTypeId]);
  const deviceTypeName = useMemo(() => getDeviceType(deviceTypeId)?.name, [deviceTypeId]);

  const shortTitle = useMemo(() => `Typy pre: ${deviceTypeName}`, [deviceTypeName]);
  const longTitle = useMemo(() => `Typy zariadení pre: ${deviceTypeName}`, [deviceTypeName]);

  return (
    <DashboardLayout
      title={shortTitle}
      height="screen"
      withSearch
      onSearch={setSearch}
      withToggle
      onToggleChange={setShowDiscarded}
      toggleLabel="Zobraziť vyradené"
      breadcrumbs={[{ title: 'Typy zariadení', link: DEVICE_TYPES_PAGE_PATH.original }, { title: longTitle }]}
    >
      <DeviceSubtypesTable search={search} deviceTypeId={deviceTypeId} showDiscarded={showDiscarded} />
    </DashboardLayout>
  );
}
