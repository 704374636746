import { rem } from '@mantine/core';

// See https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=73-554&mode=dev

export const H1Medium = {
  fontSize: rem(24),
  fontWeight: 500,
  lineHeight: rem(32),
};

export const H2Medium = {
  fontSize: rem(20),
  fontWeight: 500,
  lineHeight: rem(28),
};

export const H2Regular = {
  fontSize: rem(20),
  fontWeight: 400,
  lineHeight: rem(28),
};

export const H3Medium = {
  fontSize: rem(18),
  fontWeight: 500,
  lineHeight: rem(28),
};

export const P1Regular = {
  fontSize: rem(16),
  fontWeight: 400,
  lineHeight: rem(24),
};

export const P1RegularLink = {
  fontSize: rem(16),
  fontWeight: 400,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P1Medium = {
  fontSize: rem(16),
  fontWeight: 500,
  lineHeight: rem(24),
};

export const P2Regular = {
  fontSize: rem(14),
  fontWeight: 400,
  lineHeight: rem(24),
};

export const P2RegularLink = {
  fontSize: rem(14),
  fontWeight: 400,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P2Medium = {
  fontSize: rem(14),
  fontWeight: 500,
  lineHeight: rem(24),
};

export const P3Regular = {
  fontSize: rem(13),
  fontWeight: 400,
  lineHeight: rem(24),
};

export const P3Medium = {
  fontSize: rem(13),
  fontWeight: 500,
  lineHeight: rem(24),
};

export const P4Regular = {
  fontSize: rem(12),
  fontWeight: 400,
  lineHeight: rem(16),
};

export const P4Medium = {
  fontSize: rem(12),
  fontWeight: 500,
  lineHeight: rem(16),
};
