import { useApi } from 'api/api-context';
import { Navigate, Outlet } from 'react-router-dom';

/**
 * Require auth component.
 */
export default function RequireAuth({ redirect }: { redirect: string }) {
  const { jwt } = useApi();

  return jwt ? <Outlet /> : <Navigate to={redirect} replace />;
}
