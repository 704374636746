import { Select } from '@mantine/core';
import CertificateItem from 'components/inputs/certificate-select/CertificateItem';
import { IFormInputSpecCertificate } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { useMemo } from 'react';

/**
 * Preview for a certificate input.
 */
export default function CertificateInputPreview({ spec }: { spec: IFormInputSpecCertificate }) {
  const { deviceType } = useTemplateEditorData();

  const data = useMemo(
    () => [
      { label: 'Osvedčenie #1', value: '1', image: deviceType.id },
      { label: 'Osvedčenie #2', value: '2', image: deviceType.id },
    ],
    [deviceType.id]
  );

  return (
    <Select
      data={data}
      itemComponent={CertificateItem}
      placeholder="Vyberte osvedčenie"
      searchable
      readOnly={spec.readOnly}
      required={spec.required}
      size="sm"
    />
  );
}
