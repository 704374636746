import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetMyCompanyQualificationsPathParameters } from 'api/actions/user-get-my-company-qualifications/user-get-my-company-qualifications-path-parameters';
import { UserGetMyCompanyQualificationsResponse } from 'api/actions/user-get-my-company-qualifications/user-get-my-company-qualifications-response';

type UserGetMyCompanyQualificationsQueryParameters = Record<string, any>;

/**
 * The path of the UserGetMyCompanyQualifications action.
 */
export const userGetMyCompanyQualificationsActionPath = new SweetPath<
  keyof UserGetMyCompanyQualificationsPathParameters
>('/api/user/company-qualifications', { path: '{param}' });

/**
 * Creates a contextualized UserGetMyCompanyQualifications actions.
 */
export default function createUserGetMyCompanyQualificationsAction(axios: AxiosInstance) {
  return function userGetMyCompanyQualificationsAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: UserGetMyCompanyQualificationsQueryParameters } = {}) {
    const path = userGetMyCompanyQualificationsActionPath.original;

    return axios.get<UserGetMyCompanyQualificationsResponse, UserGetMyCompanyQualificationsResponse>(path, {
      params: query,
      headers,
    });
  };
}
