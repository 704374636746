import SimpleControl, {
  SimpleControlKind as Kind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconSpace } from '@tabler/icons-react';

/**
 * Used to insert a non-breaking space into the editor.
 */
export default function InsertNonBreakingSpace({ kind, hideIcon = false }: { kind: Kind; hideIcon?: boolean }) {
  const { editor } = useRichTextEditorContext();
  const nbsp = '\u00A0';

  return (
    <SimpleControl
      kind={kind}
      icon={hideIcon ? undefined : <IconSpace />}
      label="Nedelená medzera"
      shortcut="AltGr + Space"
      onClick={() => editor?.chain().focus().insertContent(nbsp).run()}
    />
  );
}
