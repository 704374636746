import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionRevisionTemplateUpdatePayload } from 'api/actions/revision-revision-template-update/revision-revision-template-update-payload';
import { RevisionRevisionTemplateUpdatePathParameters } from 'api/actions/revision-revision-template-update/revision-revision-template-update-path-parameters';
import { RevisionRevisionTemplateUpdateResponse } from 'api/actions/revision-revision-template-update/revision-revision-template-update-response';

type RevisionRevisionTemplateUpdateQueryParameters = Record<string, any>;

/**
 * The path of the RevisionRevisionTemplateUpdate action.
 */
export const revisionRevisionTemplateUpdateActionPath = new SweetPath<
  keyof RevisionRevisionTemplateUpdatePathParameters
>('/api/revision/revision-template/update/{revisionTemplateId}', { path: '{param}' });

/**
 * Creates a contextualized RevisionRevisionTemplateUpdate actions.
 */
export default function createRevisionRevisionTemplateUpdateAction(axios: AxiosInstance) {
  return function revisionRevisionTemplateUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionRevisionTemplateUpdatePathParameters;
    query?: RevisionRevisionTemplateUpdateQueryParameters;
    payload: RevisionRevisionTemplateUpdatePayload;
  }) {
    const path = revisionRevisionTemplateUpdateActionPath.insert(parameters);

    return axios.post<
      RevisionRevisionTemplateUpdateResponse,
      RevisionRevisionTemplateUpdateResponse,
      RevisionRevisionTemplateUpdatePayload
    >(path, payload);
  };
}
