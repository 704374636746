import { ActionIconProps, MantineThemeOverride, rem } from '@mantine/core';

export type ActionIconTheme = NonNullable<MantineThemeOverride['components']>['ActionIcon'];

export const ActionIconDefaultProps: Partial<ActionIconProps> = {
  variant: 'primary',
  size: 'md',
};

export const ActionIcon: ActionIconTheme = {
  variants: {
    primary: (theme) => ({
      root: {
        color: theme.colors.gray[0],
        backgroundColor: theme.colors.blue[8],
        '&:hover': {
          backgroundColor: theme.colors.blue[9],
        },
        '&:focus': {
          backgroundColor: theme.colors.blue[9],
        },
        '&:disabled': {
          backgroundColor: theme.colors.blue[2],
          color: theme.colors.gray[0],
        },
      },
    }),
    secondary: (theme) => ({
      root: {
        color: theme.colors.blue[8],
        backgroundColor: theme.colors.blue[1],
        '&:hover': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[2],
        },
        '&:focus': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[2],
        },
        '&:disabled': {
          backgroundColor: theme.colors.blue[0],
          color: theme.colors.blue[3],
        },
      },
    }),
    tertiary: (theme) => ({
      root: {
        color: theme.colors.gray[7],
        backgroundColor: theme.white,
        border: '1px solid #DCDCE5',
        '&:hover': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[1],
          borderColor: theme.colors.gray[3],
        },
        '&:focus': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[1],
          borderColor: theme.colors.gray[3],
        },
        '&:disabled': {
          color: theme.colors.gray[3],
          backgroundColor: theme.white,
          borderColor: theme.colors.gray[1],
        },
      },
    }),
    subtle: (theme) => ({
      root: {
        color: theme.colors.blue[8],
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[0],
        },
        '&:focus': {
          color: theme.colors.blue[9],
          backgroundColor: theme.colors.blue[0],
        },
        '&:disabled': {
          backgroundColor: theme.white,
          color: theme.colors.blue[2],
          border: 'none',
        },
      },
    }),
    'subtle-gray': (theme) => ({
      root: {
        color: theme.colors.gray[7],
        backgroundColor: 'transparent',
        '&:hover': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[1],
        },
        '&:focus': {
          color: theme.colors.gray[8],
          backgroundColor: theme.colors.gray[1],
        },
        '&:disabled': {
          backgroundColor: 'transparent',
          color: theme.colors.gray[3],
        },
      },
    }),
    'subtle-blue': (theme) => ({
      root: {
        color: theme.colors.gray[8],
        backgroundColor: theme.colors.blue[1],
        '&:disabled': {
          backgroundColor: 'transparent',
          color: theme.colors.blue[3],
        },
      },
    }),
    danger: (theme) => ({
      root: {
        color: theme.colors.gray[0],
        backgroundColor: theme.colors.red[8],
        '&:hover': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[9],
        },
        '&:focus': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[9],
        },
        '&:disabled': {
          color: theme.colors.gray[0],
          backgroundColor: theme.colors.red[2],
        },
      },
    }),
    'danger-secondary': (theme) => ({
      root: {
        color: theme.colors.red[8],
        backgroundColor: theme.white,
        border: '1px solid #DCDCE5',
        '&:hover': {
          color: theme.colors.red[9],
          backgroundColor: theme.white,
        },
        '&:focus': {
          color: theme.colors.red[9],
          backgroundColor: theme.white,
        },
        '&:disabled': {
          color: theme.colors.red[3],
          backgroundColor: theme.white,
        },
      },
    }),
  },
  sizes: {
    lg: (theme) => ({
      root: {
        padding: rem(12),
        width: rem(24 + 12 * 2),
        height: rem(24 + 12 * 2),
      },
      icon: {
        width: rem(24),
        height: rem(24),
      },
    }),
    md: (theme) => ({
      root: {
        padding: rem(8),
        width: rem(24 + 8 * 2),
        height: rem(24 + 8 * 2),
      },
      icon: {
        width: rem(24),
        height: rem(24),
      },
    }),
    sm: (theme) => ({
      root: {
        padding: rem(8),
        width: rem(16 + 8 * 2),
        height: rem(16 + 8 * 2),
      },
      icon: {
        width: rem(16),
        height: rem(16),
      },
    }),
    xs: (theme) => ({
      root: {
        padding: rem(6),
        width: rem(16 + 6 * 2),
        height: rem(16 + 6 * 2),
      },
      icon: {
        width: rem(16),
        height: rem(16),
      },
    }),
  },
};
