import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataGetGlobalPathParameters } from 'api/actions/predefined-data-get-global/predefined-data-get-global-path-parameters';
import { PredefinedDataGetGlobalResponse } from 'api/actions/predefined-data-get-global/predefined-data-get-global-response';

type PredefinedDataGetGlobalQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataGetGlobal action.
 */
export const predefinedDataGetGlobalActionPath = new SweetPath<keyof PredefinedDataGetGlobalPathParameters>(
  '/api/predefined-data/get/global/{globalPredefinedDataId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataGetGlobal actions.
 */
export default function createPredefinedDataGetGlobalAction(axios: AxiosInstance) {
  return function predefinedDataGetGlobalAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: PredefinedDataGetGlobalPathParameters;
    query?: PredefinedDataGetGlobalQueryParameters;
  }) {
    const path = predefinedDataGetGlobalActionPath.insert(parameters);

    return axios.get<PredefinedDataGetGlobalResponse, PredefinedDataGetGlobalResponse>(path, {
      params: query,
      headers,
    });
  };
}
