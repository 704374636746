import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconListNumbers } from '@tabler/icons-react';

/**
 * A button that allows the user to toggle an ordered list.
 */
export default function OrderedList({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconListNumbers />}
      label="Číslovaný zoznam"
      shortcut="Ctrl + Shift + 7"
      active={editor?.isActive('orderedList')}
      onClick={() => editor?.chain().focus().toggleOrderedList().run()}
    />
  );
}
