/** @typedef {import('@tiptap/react').Editor} Editor */

import { RichTextEditor, Link, RichTextEditorStylesNames } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import SubScript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEffect, useMemo, useRef, useState } from 'react';
import WysiwygEditorToolbar from 'components/inputs/wysiwyg/WysiwygEditorToolbar';
import { Box, Styles } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import { debounce, noop } from 'lodash';

/**
 * Parameters of the WysiwygEditor component.
 */
interface WysiwygEditorProps {
  editorRef?: ((instance: { getHTML: () => string } | null) => void) | React.RefObject<{ getHTML: () => string }>;
  autofocus?: boolean;
  onChange?: (value: string) => void;
  value?: string;
  label?: string;
  styles?: Styles<RichTextEditorStylesNames, Record<string, any>>;
  classNames?: Partial<Record<RichTextEditorStylesNames, string>>;
}

/**
 * A wysiwyg editor (implementation).
 *
 * @param {WysiwygEditorProps}
 */
function WysiwygEditorImpl({
  editorRef,
  autofocus = false,
  onChange = noop,
  value = '',
  styles = {},
  classNames = {},
}: WysiwygEditorProps) {
  const contentRef = useRef(null);
  const [debouncedContent, setDebouncedContent] = useState(value);
  const onChangeDebounced = useMemo(() => debounce(setDebouncedContent, 200, { maxWait: 5000 }), []);

  const editor = useEditor({
    extensions: [
      Highlight,
      Link,
      StarterKit,
      SubScript,
      Superscript,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Underline,
    ],
    autofocus,
    content: value,
    onUpdate: ({ editor }) => {
      onChangeDebounced(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: 'min-h-[125px] resize-y overflow-auto',
      },
    },
  });

  // Set editorRef
  useEffect(() => {
    if (editorRef) {
      editorRef = {
        current: {
          getHTML: () => editor?.getHTML() || '',
        },
      };
    }
  }, [editor, editorRef]);

  useEffect(() => {
    onChange(debouncedContent);
  }, [debouncedContent]);

  return (
    <RichTextEditor pos="relative" withTypographyStyles editor={editor} styles={styles} classNames={classNames}>
      <WysiwygEditorToolbar />
      <RichTextEditor.Content ref={contentRef} />
    </RichTextEditor>
  );
}

/**
 * A wysiwyg editor.
 */
export default function WysiwygEditor(props: WysiwygEditorProps) {
  return (
    <Box>
      {props.label && (
        <P2Regular color="gray.6" mb={4}>
          {props.label}
        </P2Regular>
      )}
      <WysiwygEditorImpl {...props} />
    </Box>
  );
}
