import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconSubscript } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to make the selected text subscript.
 */
export default function Subscript({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconSubscript />}
      label="Dolný index"
      shortcut="Ctrl + ,"
      active={editor?.isActive('subscript')}
      onClick={() => editor?.chain().focus().toggleSubscript().run()}
    />
  );
}
