import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListRevisionPlanPathParameters } from 'api/actions/device-list-revision-plan/device-list-revision-plan-path-parameters';
import { DeviceListRevisionPlanResponse } from 'api/actions/device-list-revision-plan/device-list-revision-plan-response';

type DeviceListRevisionPlanQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListRevisionPlan action.
 */
export const deviceListRevisionPlanActionPath = new SweetPath<keyof DeviceListRevisionPlanPathParameters>(
  '/api/device/revision-plan',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListRevisionPlan actions.
 */
export default function createDeviceListRevisionPlanAction(axios: AxiosInstance) {
  return function deviceListRevisionPlanAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: DeviceListRevisionPlanQueryParameters } = {}) {
    const path = deviceListRevisionPlanActionPath.original;

    return axios.get<DeviceListRevisionPlanResponse, DeviceListRevisionPlanResponse>(path, { params: query, headers });
  };
}
