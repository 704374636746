import { useDisclosure } from '@mantine/hooks';
import DeviceMeasurementModal from 'components/forms/revision/fill-out/modals/device-measurement-modal/DeviceMeasurementModal';
import { createContext, useContext, useState } from 'react';
import { IFormInputSpec } from 'pages/revisions-module/template-editor/editors/form/types';

interface SpecifyMeasurementsParams {
  deviceTypeId: number;
  measurementType?: string;
  spec?: Readonly<IFormInputSpec[]>;
  hideFaults?: boolean;
  initialValues?: any;
  onSubmit?: (values: any) => void;
}

type SpecifyMeasurementsCallback = (params: SpecifyMeasurementsParams) => void;

interface IDeviceMeasurementContext {
  specifyMeasurements: SpecifyMeasurementsCallback;
}

const DeviceMeasurementContext = createContext<IDeviceMeasurementContext>(undefined!);

/**
 * Provides the device measurement modal to the application.
 */
export function DeviceMeasurementProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<SpecifyMeasurementsParams>({ deviceTypeId: 0 });

  /**
   * Opens the device measurement modal with the specified parameters.
   */
  const specifyMeasurements: SpecifyMeasurementsCallback = (params) => {
    setParams(params);
    open();
  };

  return (
    <DeviceMeasurementContext.Provider value={{ specifyMeasurements }}>
      {children}
      <DeviceMeasurementModal opened={opened} onClose={close} {...params} />
    </DeviceMeasurementContext.Provider>
  );
}

/**
 * Hook for opening the device measurement modal.
 */
export function useDeviceMeasurement() {
  return useContext(DeviceMeasurementContext);
}
