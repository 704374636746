import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentCreatePayload } from 'api/actions/department-create/department-create-payload';
import { DepartmentCreatePathParameters } from 'api/actions/department-create/department-create-path-parameters';
import { DepartmentCreateResponse } from 'api/actions/department-create/department-create-response';

type DepartmentCreateQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentCreate action.
 */
export const departmentCreateActionPath = new SweetPath<keyof DepartmentCreatePathParameters>(
  '/api/department/create',
  { path: '{param}' }
);

/**
 * Creates a contextualized DepartmentCreate actions.
 */
export default function createDepartmentCreateAction(axios: AxiosInstance) {
  return function departmentCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: DepartmentCreateQueryParameters;
    payload: DepartmentCreatePayload;
  }) {
    const path = departmentCreateActionPath.original;

    return axios.post<DepartmentCreateResponse, DepartmentCreateResponse, DepartmentCreatePayload>(path, payload);
  };
}
