import { MantineThemeOverride } from '@mantine/core';

// Layout
import { AppShell } from 'theme/components/Layout/AppShell/AppShell';
import { AspectRatio } from 'theme/components/Layout/AspectRatio/AspectRatio';
import { Center } from 'theme/components/Layout/Center/Center';
import { Container } from 'theme/components/Layout/Container/Container';
import { Flex } from 'theme/components/Layout/Flex/Flex';
import { Grid } from 'theme/components/Layout/Grid/Grid';
import { Group } from 'theme/components/Layout/Group/Group';
import { MediaQuery } from 'theme/components/Layout/MediaQuery/MediaQuery';
import { SimpleGrid } from 'theme/components/Layout/SimpleGrid/SimpleGrid';
import { Space } from 'theme/components/Layout/Space/Space';
import { Stack } from 'theme/components/Layout/Stack/Stack';
// Buttons
import { ActionIcon } from 'theme/components/Buttons/ActionIcon/ActionIcon';
import { Button } from 'theme/components/Buttons/Button/Button';
import { CloseButton } from 'theme/components/Buttons/CloseButton/CloseButton';
import { CopyButton } from 'theme/components/Buttons/CopyButton/CopyButton';
import { FileButton } from 'theme/components/Buttons/FileButton/FileButton';
import { UnstyledButton } from 'theme/components/Buttons/UnstyledButton/UnstyledButton';
// Inputs
import { Autocomplete } from 'theme/components/Inputs/Autocomplete/Autocomplete';
import { Checkbox } from 'theme/components/Inputs/Checkbox/Checkbox';
import { Chip } from 'theme/components/Inputs/Chip/Chip';
import { ColorInput } from 'theme/components/Inputs/ColorInput/ColorInput';
import { ColorPicker } from 'theme/components/Inputs/ColorPicker/ColorPicker';
import { FileInput } from 'theme/components/Inputs/FileInput/FileInput';
import { Input } from 'theme/components/Inputs/Input/Input';
import { InputWrapper } from 'theme/components/Inputs/Input/InputWrapper';
import { JsonInput } from 'theme/components/Inputs/JsonInput/JsonInput';
import { MultiSelect } from 'theme/components/Inputs/MultiSelect/MultiSelect';
import { NativeSelect } from 'theme/components/Inputs/NativeSelect/NativeSelect';
import { NumberInput } from 'theme/components/Inputs/NumberInput/NumberInput';
import { PasswordInput } from 'theme/components/Inputs/PasswordInput/PasswordInput';
import { PinInput } from 'theme/components/Inputs/PinInput/PinInput';
import { Radio } from 'theme/components/Inputs/Radio/Radio';
import { Rating } from 'theme/components/Inputs/Rating/Rating';
import { SegmentedControl } from 'theme/components/Inputs/SegmentedControl/SegmentedControl';
import { Select } from 'theme/components/Inputs/Select/Select';
import { Slider } from 'theme/components/Inputs/Slider/Slider';
import { Switch } from 'theme/components/Inputs/Switch/Switch';
import { Textarea } from 'theme/components/Inputs/Textarea/Textarea';
import { TextInput } from 'theme/components/Inputs/TextInput/TextInput';
import { TransferList } from 'theme/components/Inputs/TransferList/TransferList';
// Navigation
import { Anchor } from 'theme/components/Navigation/Anchor/Anchor';
import { Breadcrumbs } from 'theme/components/Navigation/Breadcrumbs/Breadcrumbs';
import { Burger } from 'theme/components/Navigation/Burger/Burger';
import { NavLink } from 'theme/components/Navigation/NavLink/NavLink';
import { Pagination } from 'theme/components/Navigation/Pagination/Pagination';
import { Stepper } from 'theme/components/Navigation/Stepper/Stepper';
import { Tabs } from 'theme/components/Navigation/Tabs/Tabs';
// Data Display
import { Accordion } from 'theme/components/DataDisplay/Accordion/Accordion';
import { Avatar } from 'theme/components/DataDisplay/Avatar/Avatar';
import { BackgroundImage } from 'theme/components/DataDisplay/BackgroundImage/BackgroundImage';
import { Badge } from 'theme/components/DataDisplay/Badge/Badge';
import { Card } from 'theme/components/DataDisplay/Card/Card';
import { ColorSwatch } from 'theme/components/DataDisplay/ColorSwatch/ColorSwatch';
import { Image } from 'theme/components/DataDisplay/Image/Image';
import { Indicator } from 'theme/components/DataDisplay/Indicator/Indicator';
import { Kbd } from 'theme/components/DataDisplay/Kbd/Kbd';
import { Spoiler } from 'theme/components/DataDisplay/Spoiler/Spoiler';
import { ThemeIcon } from 'theme/components/DataDisplay/ThemeIcon/ThemeIcon';
import { Timeline } from 'theme/components/DataDisplay/Timeline/Timeline';
// Overlays
import { Affix } from 'theme/components/Overlays/Affix/Affix';
import { Dialog } from 'theme/components/Overlays/Dialog/Dialog';
import { Drawer } from 'theme/components/Overlays/Drawer/Drawer';
import { HoverCard } from 'theme/components/Overlays/HoverCard/HoverCard';
import { LoadingOverlay } from 'theme/components/Overlays/LoadingOverlay/LoadingOverlay';
import { Menu } from 'theme/components/Overlays/Menu/Menu';
import { Modal } from 'theme/components/Overlays/Modal/Modal';
import { Overlay } from 'theme/components/Overlays/Overlay/Overlay';
import { Popover } from 'theme/components/Overlays/Popover/Popover';
import { Tooltip } from 'theme/components/Overlays/Tooltip/Tooltip';
// Typography
import { Blockquote } from 'theme/components/Typography/Blockquote/Blockquote';
import { Code } from 'theme/components/Typography/Code/Code';
import { Highlight } from 'theme/components/Typography/Highlight/Highlight';
import { List } from 'theme/components/Typography/List/List';
import { Mark } from 'theme/components/Typography/Mark/Mark';
import { Table } from 'theme/components/Typography/Table/Table';
import { Text } from 'theme/components/Typography/Text/Text';
import { Title } from 'theme/components/Typography/Title/Title';
import { TypographyStylesProvider } from 'theme/components/Typography/TypographyStylesProvider/TypographyStylesProvider';
// Feedback
import { Alert } from 'theme/components/Feedback/Alert/Alert';
import { Loader } from 'theme/components/Feedback/Loader/Loader';
import { Notification } from 'theme/components/Feedback/Notification/Notification';
import { Progress } from 'theme/components/Feedback/Progress/Progress';
import { RingProgress } from 'theme/components/Feedback/RingProgress/RingProgress';
import { Skeleton } from 'theme/components/Feedback/Skeleton/Skeleton';
// Miscellaneous
import { Box } from 'theme/components/Miscellaneous/Box/Box';
import { Collapse } from 'theme/components/Miscellaneous/Collapse/Collapse';
import { Divider } from 'theme/components/Miscellaneous/Divider/Divider';
import { FocusTrap } from 'theme/components/Miscellaneous/FocusTrap/FocusTrap';
import { Paper } from 'theme/components/Miscellaneous/Paper/Paper';
import { Portal } from 'theme/components/Miscellaneous/Portal/Portal';
import { ScrollArea } from 'theme/components/Miscellaneous/ScrollArea/ScrollArea';
import { Transition } from 'theme/components/Miscellaneous/Transition/Transition';

export const components: MantineThemeOverride['components'] = {
  // Layout
  AppShell,
  AspectRatio,
  Center,
  Container,
  Flex,
  Grid,
  Group,
  MediaQuery,
  SimpleGrid,
  Space,
  Stack,
  // Buttons
  ActionIcon,
  Button,
  CloseButton,
  CopyButton,
  FileButton,
  UnstyledButton,
  // Inputs
  Autocomplete,
  Checkbox,
  Chip,
  ColorInput,
  ColorPicker,
  FileInput,
  Input,
  InputWrapper,
  JsonInput,
  MultiSelect,
  NativeSelect,
  NumberInput,
  PasswordInput,
  PinInput,
  Radio,
  Rating,
  SegmentedControl,
  Select,
  Slider,
  Switch,
  Textarea,
  TextInput,
  TransferList,
  // Navigation
  Anchor,
  Breadcrumbs,
  Burger,
  NavLink,
  Pagination,
  Stepper,
  Tabs,
  Accordion,
  Avatar,
  BackgroundImage,
  Badge,
  Card,
  ColorSwatch,
  Image,
  Indicator,
  Kbd,
  Spoiler,
  ThemeIcon,
  Timeline,
  // Overlays
  Affix,
  Dialog,
  Drawer,
  HoverCard,
  LoadingOverlay,
  Menu,
  Modal,
  Overlay,
  Popover,
  Tooltip,
  // Typography
  Blockquote,
  Code,
  Highlight,
  List,
  Mark,
  Table,
  Text,
  Title,
  TypographyStylesProvider,
  // Feedback
  Alert,
  Loader,
  Notification,
  Progress,
  RingProgress,
  Skeleton,
  // Miscellaneous
  Box,
  Collapse,
  Divider,
  FocusTrap,
  Paper,
  Portal,
  ScrollArea,
  Transition,
};
