import { Stack } from '@mantine/core';
import RevisionSection from 'components/forms/revision/fill-out/RevisionSection';
import { IFormSection } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Content of a tab in the revision form.
 */
export default function RevisionTabContent({ sections }: { sections: IFormSection[] }) {
  return (
    <Stack spacing={40}>
      {sections.map((section) => (
        <RevisionSection key={section.id} section={section} />
      ))}
    </Stack>
  );
}
