import { Group, Stack, Text, Textarea, TextareaProps } from '@mantine/core';
import P3Regular from 'components/typography/P3Regular';

/**
 * Displays the number of characters left in the textarea.
 */
function CharacterCount({ value, maxLength }: Pick<TextareaProps, 'value' | 'maxLength'>) {
  if (maxLength === undefined || typeof value !== 'string') {
    return <></>;
  }

  return (
    <P3Regular color="gray.5">
      <Group spacing={2}>
        <Text>{value.length}</Text>
        <Text>/</Text>
        <Text>{maxLength}</Text>
      </Group>
    </P3Regular>
  );
}

/**
 * Textarea with character count.
 */
export default function TextareaWithCharacterCount({ value, maxLength, ...props }: TextareaProps) {
  return (
    <Stack spacing={4}>
      <Textarea value={value} maxLength={maxLength} {...props} />
      <Group position="right">
        <CharacterCount value={value} maxLength={maxLength} />
      </Group>
    </Stack>
  );
}
