import { IForm } from 'pages/revisions-module/template-editor/editors/form/types';

const sections: IForm['sections'] = [
  { id: 'device', name: 'Údaje zariadenia', tab: 'device', rows: [] },
  {
    id: 'basic-info',
    name: '',
    tab: 'info',
    rows: [
      {
        id: 'basic-info-row-0',
        inputs: [
          {
            id: 'datum-ukoncenia',
            context: 'revision',
            deletable: true,
            spec: {
              type: 'date',
              copyable: false,
              required: true,
              readOnly: false,
              hidden: false,
              name: 'datum-ukoncenia',
              label: 'Dátum ukončenia',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'conclusion',
    name: 'Záver',
    tab: 'conclusion',
    rows: [
      {
        id: 'conclusion-row-0',
        inputs: [
          {
            id: 'zaver-reviznej-spravy',
            context: 'revision',
            deletable: true,
            spec: {
              type: 'safetyPoint',
              copyable: false,
              required: true,
              readOnly: false,
              hidden: false,
              name: 'zaver-reviznej-spravy',
              label: 'Záver revíznej správy',
              suitableText: 'Vyhovuje',
              notSuitableText: 'Nevyhovuje',
              withReservationsText: 'Vyhovuje s výhradami',
            },
          },
        ],
      },
    ],
  },
];

const inputNames = sections.flatMap(({ rows }) => rows.flatMap(({ inputs }) => inputs.map(({ spec }) => spec.name)));

export const DEFAULT_FORM: IForm = { sections, inputNames };
