import { useApi } from 'api/api-context';
import { DeviceFaultProvider } from 'components/forms/revision/fill-out/modals/device-fault-modal/DeviceFaultProvider';
import { DeviceMeasurementProvider } from 'components/forms/revision/fill-out/modals/device-measurement-modal/DeviceMeasurementProvider';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH, REVISIONS_PAGE_PATH, REVISION_PAGE_PATH } from 'routes/paths';
import panic from 'errors/panic';
import { NO_ENTITY_EXISTS_ERROR_CODE } from 'utils/constants';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import FillOutRevisionForm from 'components/forms/revision/fill-out/FillOutRevisionForm';

/**
 * Page used to fill out a revision.
 */
export default function FillOutRevisionPage() {
  const navigate = useNavigate();
  const { revisionId } = useParams();
  const { getAction, userId } = useApi();
  const [revision, setRevision] = useState<RevisionGetResponse | null>(null);
  const revisionDetailPath = useMemo(() => REVISION_PAGE_PATH.insert({ revisionId }), [revisionId]);

  const breadcrumbs = useMemo(
    () => [
      { title: 'Revízne správy', link: REVISIONS_PAGE_PATH.original },
      { title: revision ? `${revision.revisionName} (${revision?.revisionTemplate?.name})` : 'Načítavam' },
    ],
    [revision]
  );

  // Fetch revision
  useEffect(() => {
    if (revisionId) {
      const action = getAction('RevisionGet');

      action({ parameters: { revisionId } })
        .then(setRevision)
        .catch((e) => {
          if (e?.response?.data?.error?.code === NO_ENTITY_EXISTS_ERROR_CODE) {
            navigate(DASHBOARD_PAGE_PATH.original, { replace: true });
          } else {
            panic(e);
          }
        });
    }
  }, [revisionId]);

  if (revision && revision.technician.userId !== userId) {
    return <Navigate to={revisionDetailPath} replace />;
  }

  if (revision && revision.revisionStatus.slug !== 'in-progress') {
    return <Navigate to={revisionDetailPath} replace />;
  }

  return (
    <DashboardLayout breadcrumbs={breadcrumbs}>
      <DeviceFaultProvider>
        <DeviceMeasurementProvider>{revision && <FillOutRevisionForm revision={revision} />}</DeviceMeasurementProvider>
      </DeviceFaultProvider>
    </DashboardLayout>
  );
}
