import RevisionTable from 'components/tables/revision/RevisionTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';

/**
 * Page used to display revisions.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A10497 Figma Design}
 * - {@link https://www.notion.so/Revisions-List-Revisions-7f2582bc435a42f3b77b9c52debd5602?pvs=4 Notion Page}
 */
export default function RevisionsPage() {
  const [search, setSearch] = useState('');

  return (
    <DashboardLayout
      title="Revízne správy"
      height="screen"
      withSearch
      onSearch={setSearch}
      breadcrumbs={[{ title: 'Revízne správy' }]}
    >
      <RevisionTable search={search} />
    </DashboardLayout>
  );
}
