import { useApi } from 'api/api-context';
import axios from 'axios';
import Qs from 'qs';
import { FileManagerContext } from 'api/file-manager/file-manager-context';
import { useCallback, useMemo } from 'react';
import { FILE_MANAGER_TIMEOUT, FILE_MANAGER_URL } from 'env';

/**
 * File Manager Provider.
 */
export default function FileManagerProvider({ children }: { children: React.ReactNode }) {
  const { jwt } = useApi();

  /**
   * The connector object = axios instance.
   */
  const connector = useMemo(() => {
    const connector = axios.create({
      timeout: FILE_MANAGER_TIMEOUT,
      baseURL: FILE_MANAGER_URL,
    });

    connector.interceptors.request.use((cfg) => {
      // Add JWT to the request.
      if (jwt && !cfg.headers.Authorization) {
        cfg.headers.Authorization = `Bearer ${jwt}`;
      }

      // Serialize deep objects.
      cfg.paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'brackets', encode: false });

      return cfg;
    });

    return connector;
  }, [jwt]);

  /**
   * Uploads the file and returns its ID.
   */
  const uploadFile = useCallback(
    async ({ fileName, contents }: { fileName: string; contents: string | ArrayBuffer | null }): Promise<string> => {
      try {
        const { data } = await connector.post('/file/upload', { fileName, contents });

        return data.fileId;
      } catch (e: any) {
        if (e?.response?.status === 413) {
          throw new Error('Súbor je príliš veľký. Maximálna veľkosť súboru je 100 MB.');
        }

        throw e;
      }
    },
    [connector]
  );

  /**
   * Retrieves the contents of the file.
   */
  const readFile = useCallback(
    async ({ fileId }: { fileId: string }): Promise<Blob> => {
      const { data } = await connector.get(`/file/${fileId}`, { responseType: 'blob' });
      return data;
    },
    [connector]
  );

  /**
   * Retrieves the metadata of the file.
   */
  const getFileMetadata = useCallback(
    async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/metadata`);
      return data;
    },
    [connector]
  );

  /**
   * Retrieves the thumbnail of the file.
   */
  const getFileThumbnail = useCallback(
    async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.get(`/file/${fileId}/thumbnail`, { responseType: 'blob' });
      return data;
    },
    [connector]
  );

  /**
   * Creates an access token for the file.
   */
  const createAccessToken = useCallback(
    async ({ fileId }: { fileId: string }) => {
      const { data } = await connector.post(`/file/${fileId}/create-access-token`);
      return data.token;
    },
    [connector]
  );

  return (
    <FileManagerContext.Provider value={{ uploadFile, readFile, getFileMetadata, getFileThumbnail, createAccessToken }}>
      {children}
    </FileManagerContext.Provider>
  );
}
