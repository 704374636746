import { Box, Checkbox, Collapse, Divider, Group, Stack, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { Permission } from 'model/PermissionDataProvider';
import { useMemo } from 'react';

interface PermissionBoxProps {
  permissionGroup: string;
  permissions: Permission[];
  setPermission: (permissionId: number, checked: boolean) => void;
  hasPermission: (permissionId: number) => boolean;
  removePermissions: (permissions: number[]) => void;
  addPermissions: (permissions: number[]) => void;
}

/**
 * Box displaying permission for selected users.
 */
export default function PermissionBox({
  permissionGroup,
  permissions,
  setPermission,
  hasPermission,
  removePermissions,
  addPermissions,
}: PermissionBoxProps) {
  const theme = useMantineTheme();

  const [opened, { toggle }] = useDisclosure(true);

  const allChecked = permissions.every((permission) => hasPermission(permission.permissionId));
  const indeterminate = permissions.some((permission) => hasPermission(permission.permissionId)) && !allChecked;

  const permissionIds = useMemo(() => permissions.map((permission) => permission.permissionId), [permissions]);

  return (
    <Box>
      <Group
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            toggle();
          }
        }}
        position="apart"
        bg="gray.0"
        p={16}
        style={{ borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
      >
        <Checkbox
          // TODO name
          label={permissionGroup}
          // TODO change this when Mantine's version is higher than 7.0.0
          // Temporary hack - the indeterminate state is also "checked"
          // In newer versions, the checkbox has `data-indeterminate` attribute
          checked={allChecked || indeterminate}
          indeterminate={indeterminate}
          size="lg"
          onChange={() => {
            if (allChecked) {
              removePermissions(permissionIds);
            } else {
              addPermissions(permissionIds);
            }
          }}
        />
        <Box onClick={toggle}>
          {opened ? <IconChevronUp color={theme.colors.gray[7]} /> : <IconChevronDown color={theme.colors.gray[7]} />}
        </Box>
      </Group>
      {opened && (
        <Box px={16} bg="gray.0">
          <Divider color="gray.3" />
        </Box>
      )}
      <Collapse
        in={opened}
        bg="gray.0"
        style={{ borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
        pt={16}
        pl={32}
        pb={24}
      >
        <Stack spacing={16}>
          {permissions.map((permission) => (
            <Checkbox
              key={permission.permissionId}
              label={permission.permissionName}
              checked={hasPermission(permission.permissionId)}
              size="md"
              // TODO name
              onChange={(event) => setPermission(permission.permissionId, event.currentTarget.checked)}
            />
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
}
