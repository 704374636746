import { useForm } from '@mantine/form';
import {
  Group,
  TextInput,
  Divider,
  Button,
  Flex,
  Grid,
  Stack,
  SimpleGrid,
  useMantineTheme,
  Box,
  NumberInput,
  Checkbox,
} from '@mantine/core';
import { randomId } from '@mantine/hooks';
import FormInputGroup from 'components/forms/FormInputGroup';
import { noop } from 'lodash';
import { FormProps } from 'components/forms/FormProps';
import useLoadingAction from 'hooks/use-loading-action';
import { IconAlertTriangle, IconCheck, IconPlus, IconTrashX } from '@tabler/icons-react';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import H3Medium from 'components/typography/H3Medium';
import P2Medium from 'components/typography/P2Medium';
import { DEVICE_FAULTS_PAGE_PATH } from 'routes/paths';
import { DepartmentSelect } from 'components/selects/DepartmentSelect';
import FormWrapper from 'components/forms/FormWrapper';
import { DeviceTypeSelect } from 'components/selects/DeviceTypeSelect';
import { DeviceSubtypeSelect } from 'components/selects/DeviceSubtypeSelect';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FaultSeveritySelect } from 'components/selects/FaultSeveritySelect';
import P1Medium from 'components/typography/P1Medium';
import P3Regular from 'components/typography/P3Regular';
import P2Regular from 'components/typography/P2Regular';
import LinkWithConfirm from 'components/modals/confirm/LinkWithConfirm';
import Toast from 'components/Toast';
import DeviceFiles from 'components/forms/device/DeviceFiles';
import { addMonths } from 'date-fns';
import { DeviceResponsiblePersonSelect } from 'components/selects/DeviceResponsiblePersonSelect';
import { DateInput, stringifyDate } from 'components/inputs/DateInput';
import { useConfirm } from 'components/modals/message/MessageProvider';
import RevisionPeriodSelect from 'components/inputs/revision-period-select/RevisionPeriodSelect';
import { showNotification } from '@mantine/notifications';
import { ERROR_NOTIFICATION_COLOR } from 'utils/constants';
import { FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS } from 'env';
import DeviceEvidenceFields from 'components/forms/device/DeviceEvidenceFields';
import { DeviceEvidenceFieldType } from 'components/forms/device/DeviceEvidenceField';
import { Text } from '@mantine/core';

/**
 * The data collected from the form.
 */
export interface DeviceFormData {
  deviceName: string;
  manufacturer?: string;
  serialNumber?: string;
  manufactured?: number;
  internalPossessionsNumber?: string;
  internalNote?: string;
  building?: string;
  floor?: string;
  room?: string;
  latitude?: number;
  longitude?: number;
  deviceTypeId: string;
  deviceSubtypeId: string;
  departmentId: string;
  responsiblePersonId?: string;
  responsiblePersonNote?: string;
  externalId?: string;
  nextRevision: {
    revisionPlanId?: number;
    description: string;
    date: string;
    revisionPeriod: string;
    key: string;
    externalId?: string;
    deleted: boolean;
    nextDate?: string;
    neverExecuted: boolean;
  }[];
  faults: {
    faultId?: number;
    faultName: string;
    faultSeverityId: string;
    description?: string;
    key: string;
    fixed?: { fixedAt?: string; fixedNote?: string };
    deleted: boolean;
  }[];
  files: {
    'project-documentation': {
      deviceFileId?: number;
      fileId: string;
      fileName: string;
      originalFileName: string;
      uploadedAt: Date;
      key: string;
      deleted: boolean;
      isMainImage?: boolean;
    }[];
    revisions: {
      deviceFileId?: number;
      fileId: string;
      fileName: string;
      originalFileName: string;
      uploadedAt: Date;
      key: string;
      deleted: boolean;
      isMainImage?: boolean;
    }[];
    photos: {
      deviceFileId?: number;
      fileId: string;
      fileName: string;
      originalFileName: string;
      uploadedAt: Date;
      key: string;
      deleted: boolean;
      isMainImage?: boolean;
    }[];
    other: {
      deviceFileId?: number;
      fileId: string;
      fileName: string;
      originalFileName: string;
      uploadedAt: Date;
      key: string;
      deleted: boolean;
      isMainImage?: boolean;
    }[];
  };
  evidenceFields: { [evidenceFieldId: string]: DeviceEvidenceFieldType };
}

/**
 * The default initial values.
 */
export const deviceFormInitialValues: DeviceFormData = {
  deviceName: '',
  manufacturer: '',
  serialNumber: '',
  internalPossessionsNumber: '',
  internalNote: '',
  building: '',
  floor: '',
  room: '',
  deviceTypeId: '',
  deviceSubtypeId: '',
  departmentId: '',
  responsiblePersonId: '',
  responsiblePersonNote: '',
  nextRevision: [
    { description: '', date: '', revisionPeriod: '', key: randomId(), deleted: false, neverExecuted: false },
  ],
  faults: [],
  files: {
    'project-documentation': [],
    revisions: [],
    photos: [],
    other: [],
  },
  evidenceFields: {},
};

/**
 * Parameters of the DeviceForm.;
 */
export interface DeviceFormProps extends FormProps<DeviceFormData> {
  readOnly?: Partial<Record<keyof DeviceFormData, boolean>>;
  primaryButtonText?: string;
  primaryIcon?: React.ReactNode;
  hidePrimaryButton?: boolean;
  hideSecondaryButton?: boolean;
  hideFooter?: boolean;
}

/**
 * Form for creating and editing device.
 */
export default function DeviceForm({
  initialValues = deviceFormInitialValues,
  onSubmit = noop,
  readOnly = {},
  primaryButtonText = 'Uložiť',
  primaryIcon = <IconCheck stroke="1.5" height={24} width={24} />,
  hidePrimaryButton = false,
  hideSecondaryButton = false,
  hideFooter = false,
}: DeviceFormProps) {
  const deadlinesAnchorRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [{ loading }, submit] = useLoadingAction(onSubmit);
  const { confirm } = useConfirm();
  const [openedToast, setOpenedToast] = useState(true);

  const { deviceId } = useParams();
  const theme = useMantineTheme();

  const form = useForm<DeviceFormData>({
    initialValues,
    validate: {
      deviceName: createValidator([required]),
      deviceTypeId: createValidator([required]),
      deviceSubtypeId: createValidator([required]),
      departmentId: createValidator([required]),
      faults: {
        faultName: createValidator([required]),
        faultSeverityId: createValidator([required]),
      },
      // TODO validators for the nested fields
    },
  });

  useEffect(() => {
    if (!readOnly.deviceSubtypeId) {
      form.setFieldValue('deviceSubtypeId', '');
    }
  }, [form.values.deviceTypeId, form.values.departmentId]);

  /**
   * Opens confirm modal which confirmation deletes the next revision.
   */
  function handleDeleteNextRevision(index: number) {
    confirm({
      title: 'Potvrdenie zmazania',
      content: 'Naozaj si prajete vymazať sledovaný termín?',
      onConfirm: () => {
        form.setFieldValue(`nextRevision.${index}.deleted`, true);
      },
    });
  }

  /**
   * Opens confirm modal which confirmation deletes the device fault.
   */
  function handleDeleteFault(index: number) {
    confirm({
      title: 'Potvrdenie zmazania',
      content: 'Naozaj si prajete vymazať závadu zariadenia?',
      onConfirm: () => {
        // mark faults as deleted only if they have faultId
        // otherwise, delete them - they were not saved yet
        if (form.values.faults[index].faultId) {
          form.setFieldValue(`faults.${index}.deleted`, true);
        } else {
          form.removeListItem('faults', index);
        }
      },
    });
  }

  // COMPONENT : Device faults
  const deviceFaultsFields = useMemo(() => {
    return form.values.faults.map((item, index) => {
      if (item.deleted) {
        return null;
      }

      const isFixed = item.fixed?.fixedAt !== undefined;

      return (
        <Stack
          key={item.key}
          pt={16}
          pb={32}
          px={40}
          ml={40}
          mr={24}
          mb={24}
          spacing={24}
          bg="gray.0"
          sx={() => ({
            borderRadius: '4px',
          })}
        >
          <Group
            pb={16}
            position="apart"
            style={{
              borderBottom: `1px solid ${theme.colors.gray[2]}`,
            }}
          >
            {isFixed ? (
              <Group spacing={8}>
                <IconCheck stroke="1.5" height={24} width={24} />
                <P1Medium>Vyriešená závada</P1Medium>
              </Group>
            ) : (
              <Group spacing={8}>
                <IconAlertTriangle stroke="1.5" height={24} width={24} />
                <P1Medium>Nevyriešená závada</P1Medium>
              </Group>
            )}
            <Button
              variant="danger-secondary"
              leftIcon={<IconTrashX stroke="1.5" height={24} width={24} color={theme.colors.red[8]} />}
              type="button"
              size="md"
              loading={loading}
              onClick={() => handleDeleteFault(index)}
            >
              Zmazať
            </Button>
          </Group>
          <Stack spacing={24}>
            <Grid align="center" justify="flex-start" grow gutter={40}>
              <Grid.Col span={6}>
                <TextInput
                  label="Názov"
                  placeholder="--"
                  size="lg"
                  name={`amperia-faults.${index}.faultName`}
                  {...form.getInputProps(`faults.${index}.faultName`)}
                  readOnly={isFixed}
                />
              </Grid.Col>

              <Grid.Col span={6}>
                <FaultSeveritySelect
                  label="Nedostatok"
                  size="lg"
                  placeholder="Vyberte nedostatok"
                  searchable
                  name={`amperia-faults.${index}.faultSeverityId`}
                  {...form.getInputProps(`faults.${index}.faultSeverityId`)}
                  readOnly={isFixed}
                />
              </Grid.Col>
            </Grid>
            <SimpleGrid cols={1}>
              <TextInput
                label="Popis"
                placeholder="--"
                size="lg"
                name={`amperia-faults.${index}.description`}
                {...form.getInputProps(`faults.${index}.description`)}
                readOnly={isFixed}
              />
            </SimpleGrid>
          </Stack>
          {isFixed && (
            <Box
              py={16}
              pb={24}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 174px',
                gap: '24px',
                alignItems: 'center',
              }}
            >
              <Stack>
                <P3Regular color="gray.6">Dátum riešenia závady</P3Regular>
                {/* If isFixed === true, the fault has fixedAt prop  */}
                <P2Regular color="gray.8">{new Date(item.fixed?.fixedAt!).toLocaleDateString()}</P2Regular>
              </Stack>
              <Stack>
                <P3Regular color="gray.6">Popis riešenia</P3Regular>
                <P2Regular color="gray.8">{item.fixed?.fixedNote ?? '-'}</P2Regular>
              </Stack>
            </Box>
          )}
        </Stack>
      );
    });
  }, [form]);

  useEffect(() => {
    if (searchParams.get('revisionPlanId') && deadlinesAnchorRef.current) {
      deadlinesAnchorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // COMPONENT: Device deadlines
  const deadlines = useMemo(() => {
    return form.values.nextRevision.map((item, index) =>
      item.deleted ? null : (
        <Box
          key={item.key}
          pt={24}
          pb={24}
          pl={40}
          pr={40}
          ml={40}
          mr={24}
          mb={24}
          bg="gray.0"
          sx={() => ({
            display: 'grid',
            gridTemplateColumns: '1fr 135px',
            // justifyContent: 'flex-end',
            alignItems: 'flex-end',
            alignContent: 'flex-end',
            gap: '40px',
            alignSelf: 'stretch',
            flexWrap: 'wrap',
            borderRadius: '4px',
          })}
        >
          <Stack spacing={24}>
            <TextInput
              label="Úkon"
              placeholder="--"
              size="lg"
              disabled={readOnly.nextRevision}
              readOnly={readOnly.nextRevision}
              w="100%"
              name="amperia-nextRevision[].description"
              {...form.getInputProps(`nextRevision.${index}.description`)}
            />
            <Group grow spacing={40} style={{ alignItems: 'flex-start' }}>
              <Stack spacing={8}>
                <DateInput
                  label="Dátum"
                  size="lg"
                  disabled={readOnly.nextRevision || item.neverExecuted}
                  readOnly={readOnly.nextRevision || item.neverExecuted}
                  value={form.getInputProps(`nextRevision.${index}.date`).value}
                  onChange={(val) => {
                    if (val && item.revisionPeriod) {
                      form.setFieldValue(
                        `nextRevision.${index}.nextDate`,
                        stringifyDate(addMonths(new Date(val), Number(item.revisionPeriod)))
                      );
                    }

                    form.getInputProps(`nextRevision.${index}.date`).onChange(val);
                  }}
                />
                <Checkbox
                  label="Neevidujeme dátum vykonania"
                  {...form.getInputProps(`nextRevision.${index}.neverExecuted`, { type: 'checkbox' })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      form.setFieldValue(`nextRevision.${index}.date`, '');
                    } else {
                      form.setFieldValue(`nextRevision.${index}.nextDate`, '');
                    }

                    form.getInputProps(`nextRevision.${index}.neverExecuted`, { type: 'checkbox' }).onChange(e);
                  }}
                  name="amperia-nextRevision[].neverExecuted"
                  disabled={readOnly.nextRevision}
                  readOnly={readOnly.nextRevision}
                />
              </Stack>

              <RevisionPeriodSelect
                label="Perióda"
                size="lg"
                placeholder="Vyberte periódu revízie"
                disabled={readOnly.nextRevision}
                readOnly={readOnly.nextRevision}
                value={form.getInputProps(`nextRevision.${index}.revisionPeriod`).value}
                onChange={(val) => {
                  if (form.values.nextRevision[index].date !== '' && val) {
                    form.setFieldValue(
                      `nextRevision.${index}.nextDate`,
                      stringifyDate(addMonths(new Date(item.date), Number(val)))
                    );
                  }

                  form.getInputProps(`nextRevision.${index}.revisionPeriod`).onChange(val);
                }}
                name="amperia-nextRevision[].revisionPeriod"
              />
              <DateInput
                // TODO name
                label="Nasledujúci termín (nepovinné)"
                size="lg"
                disabled={!item.neverExecuted}
                readOnly={!item.neverExecuted}
                value={form.getInputProps(`nextRevision.${index}.nextDate`).value}
                onChange={(e) => {
                  if (item.date !== '' && item.revisionPeriod) {
                    noop();
                  } else {
                    form.getInputProps(`nextRevision.${index}.nextDate`).onChange(e);
                  }
                }}
              />

              <TextInput
                label="Externé ID"
                placeholder="---"
                size="lg"
                disabled
                readOnly
                name="amperia-nextRevision[].externalId"
                {...form.getInputProps(`nextRevision.${index}.externalId`)}
              />
            </Group>
          </Stack>

          <Flex align="flex-end" maw={135}>
            <Box py={32}>
              <Button
                mt={27}
                type="button"
                variant="danger-secondary"
                leftIcon={
                  <IconTrashX
                    stroke="1.5"
                    height={24}
                    width={24}
                    color={readOnly.nextRevision ? theme.colors.red[2] : theme.colors.red[8]}
                  />
                }
                size="md"
                loading={loading}
                onClick={() => handleDeleteNextRevision(index)}
                disabled={readOnly.nextRevision}
              >
                Zmazať
              </Button>
            </Box>
          </Flex>
        </Box>
      )
    );
  }, [form.values.nextRevision, readOnly.nextRevision]);

  /**
   * Submit with validation.
   */
  const onSubmitImpl: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const { hasErrors } = form.validate();

    if (!hasErrors) {
      submit(form.values);
      form.resetDirty();
    } else {
      showNotification({
        title: 'Vo formulári sú chyby',
        message: (
          <Text>
            Prosím, skontrolujte všetky polia a opravte chyby.
            <br />
            Po odstránení chýb môžete formulár znovu odoslať.
          </Text>
        ),
        color: ERROR_NOTIFICATION_COLOR,
      });
    }
  };

  return (
    <FormWrapper
      onSubmit={onSubmitImpl}
      loading={loading}
      primaryButtonText={primaryButtonText}
      primaryIcon={primaryIcon}
      hidePrimaryButton={hidePrimaryButton}
      hideSecondaryButton={hideSecondaryButton}
      hideFooter={hideFooter}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup groupTitle="Základné info">
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Názov"
            placeholder="Názov zariadenia"
            size="lg"
            name="amperia-deviceName"
            {...form.getInputProps('deviceName')}
            disabled={readOnly.deviceName}
            readOnly={readOnly.deviceName}
          />
          <DepartmentSelect
            label="Stredisko"
            size="lg"
            placeholder="Vyberte stredisko"
            showDiscarded={false}
            groupByOrganization={true}
            permissionSlug="manage-devices"
            name="amperia-departmentId"
            {...form.getInputProps('departmentId')}
            readOnly={readOnly.departmentId}
            disabled={readOnly.departmentId}
            onChange={(value) => {
              if (value) {
                if (form.values.departmentId !== value) {
                  form.setFieldValue('responsiblePersonId', '');
                }

                form.setFieldValue('departmentId', value);
              }
            }}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Výrobca"
            placeholder="Výrobca zariadenia"
            size="lg"
            disabled={readOnly.manufacturer}
            readOnly={readOnly.manufacturer}
            name="amperia-manufacturer"
            {...form.getInputProps('manufacturer')}
          />
          <TextInput
            label="Výrobné číslo"
            placeholder="Výrobné číslo zariadenia"
            size="lg"
            disabled={readOnly.serialNumber}
            readOnly={readOnly.serialNumber}
            name="amperia-serialNumber"
            {...form.getInputProps('serialNumber')}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <NumberInput
            label="Rok výroby"
            hideControls
            size="lg"
            disabled={readOnly.manufactured}
            readOnly={readOnly.manufactured}
            placeholder="Rok výroby zariadenia"
            name="amperia-manufactured"
            {...form.getInputProps('manufactured')}
            precision={0}
            step={1}
          />
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <DeviceTypeSelect
            label="Zariadenie"
            size="lg"
            placeholder="Vyberte zariadenie"
            disabled={readOnly.deviceTypeId}
            readOnly={readOnly.deviceTypeId}
            name="amperia-deviceTypeId"
            {...form.getInputProps('deviceTypeId')}
          />
          {form.values.deviceTypeId !== '' && form.values.departmentId !== '' && (
            <DeviceSubtypeSelect
              label="Typ zariadenia"
              size="lg"
              parentDeviceTypeId={Number(form.values.deviceTypeId)}
              departmentId={Number(form.values.departmentId)}
              placeholder="Vyberte typ zariadenia"
              disabled={readOnly.deviceTypeId}
              readOnly={readOnly.deviceTypeId}
              showDiscarded={false}
              name="amperia-deviceSubtypeId"
              {...form.getInputProps('deviceSubtypeId')}
            />
          )}
        </SimpleGrid>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Externé ID"
            size="lg"
            placeholder="---"
            disabled
            readOnly
            name="amperia-externalId"
            {...form.getInputProps('externalId')}
          />
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup groupTitle="Umiestnenie">
        <Group grow spacing={40}>
          <TextInput
            label="Budova"
            placeholder="Názov budovy"
            size="lg"
            disabled={readOnly.building}
            readOnly={readOnly.building}
            name="amperia-building"
            {...form.getInputProps('building')}
          />
          <TextInput
            label="Podlažie"
            placeholder="Podlažie v budove"
            size="lg"
            disabled={readOnly.floor}
            readOnly={readOnly.floor}
            name="amperia-floor"
            {...form.getInputProps('floor')}
          />
        </Group>
        <SimpleGrid cols={2} spacing={40}>
          <TextInput
            label="Miestnosť"
            placeholder="Miestnosť v budove"
            size="lg"
            disabled={readOnly.room}
            readOnly={readOnly.room}
            name="amperia-room"
            {...form.getInputProps('room')}
          />
        </SimpleGrid>
        <Group grow spacing={40}>
          <NumberInput
            precision={6}
            min={-90}
            max={90}
            hideControls
            decimalSeparator=","
            label="Zemepisná šírka"
            placeholder="48,590324"
            size="lg"
            disabled={readOnly.latitude}
            readOnly={readOnly.latitude}
            name="amperia-latitude"
            {...form.getInputProps('latitude')}
          />
          <NumberInput
            precision={6}
            min={-180}
            max={180}
            hideControls
            decimalSeparator=","
            label="Zemepisná dĺžka"
            placeholder="18,081704"
            size="lg"
            disabled={readOnly.longitude}
            readOnly={readOnly.longitude}
            name="amperia-longitude"
            {...form.getInputProps('longitude')}
          />
        </Group>
      </FormInputGroup>

      <FormInputGroup groupTitle="Doplňujúce info">
        <Group grow spacing={40}>
          <TextInput
            label="Interné č. majetku"
            placeholder="Interné číslo majetku"
            size="lg"
            disabled={readOnly.internalPossessionsNumber}
            readOnly={readOnly.internalPossessionsNumber}
            name="amperia-internalPossessionsNumber"
            {...form.getInputProps('internalPossessionsNumber')}
          />
          <TextInput
            label="Poznámka"
            placeholder="Poznámka k int. číslu majetku"
            size="lg"
            disabled={readOnly.internalNote}
            readOnly={readOnly.internalNote}
            name="amperia-internalNote"
            {...form.getInputProps('internalNote')}
          />
        </Group>
      </FormInputGroup>

      <FormInputGroup
        groupTitle="Zodpovedná osoba"
        infoBox={
          form.isDirty('departmentId') && readOnly.responsiblePersonId ? (
            <Toast
              type="warning"
              message="Skontrolujte zodpovednú osobu"
              size="s"
              opened={openedToast}
              withCloseButton
              onClose={() => setOpenedToast(false)}
            />
          ) : undefined
        }
      >
        <Group grow spacing={40}>
          {form.values.departmentId ? (
            <DeviceResponsiblePersonSelect
              clearable
              label="Zodpovedná osoba"
              size="lg"
              placeholder="Vyberte zodpovednú osobu"
              departmentId={Number(form.values.departmentId)}
              disabled={readOnly.responsiblePersonNote}
              readOnly={readOnly.responsiblePersonNote}
              name="amperia-responsiblePersonId"
              {...form.getInputProps('responsiblePersonId')}
            />
          ) : (
            <Stack py={16}>
              <P2Regular color="gray.8">Najskôr vyberte organizáciu</P2Regular>
            </Stack>
          )}
          <TextInput
            label="Poznámka"
            placeholder="Poznámka k zodpovednej osobe"
            size="lg"
            disabled={readOnly.responsiblePersonNote}
            readOnly={readOnly.responsiblePersonNote}
            name="amperia-responsiblePersonNote"
            {...form.getInputProps('responsiblePersonNote')}
          />
        </Group>
      </FormInputGroup>

      {FEATURE_TOGGLE_DEVICE_SUBTYPE_EVIDENCE_FIELDS && (
        <FormInputGroup groupTitle="Evidenčné polia">
          {form.values.departmentId !== '' && form.values.deviceSubtypeId !== '' ? (
            <DeviceEvidenceFields
              key={`${form.values.deviceSubtypeId}-${form.values.departmentId}`}
              form={form}
              deviceSubtypeId={Number(form.values.deviceSubtypeId)}
              departmentId={Number(form.values.departmentId)}
            />
          ) : (
            <P2Regular>Najskôr vyberte stredisko a typ zariadenia.</P2Regular>
          )}
        </FormInputGroup>
      )}

      <Divider mt={40} color="gray.1" />

      {/* START SECTION: Revision deadlines */}
      <Flex align="center" ml={40} mt={40} mb={16} gap={24}>
        <H3Medium color="gray.7">Sledovanie termínov</H3Medium>
      </Flex>

      <Box sx={{ transform: 'translateY(-80px)' }} ref={deadlinesAnchorRef} />
      {deadlines}

      <Group position="left" mt="md" ml={40}>
        <Button
          onClick={() =>
            form.insertListItem('nextRevision', {
              description: '',
              date: '',
              revisionPeriod: '',
              key: randomId(),
              deleted: false,
              neverExecuted: false,
            })
          }
          leftIcon={<IconPlus stroke="1.5" height={24} width={24} />}
          size="md"
          variant="subtle"
          disabled={readOnly.nextRevision}
        >
          Pridať ďalší termín
        </Button>
      </Group>
      {/* END SECTION: Revision deadlines */}

      {/* START SECTION: Device faults */}
      {!readOnly.faults && (
        <>
          <Divider mt={40} color="gray.1" />
          <Flex align="center" ml={40} mt={40} mb={16} gap={24}>
            <H3Medium color="gray.7">Zoznam závad</H3Medium>
            <LinkWithConfirm
              to={DEVICE_FAULTS_PAGE_PATH.insert({ deviceId: deviceId! })}
              confirmMessage="Ak odídete, zmeny vo formulári sa neuložia. Chcete odísť?"
              confirmTitle="Pozor!"
            >
              <P2Medium>Zobraziť zoznam všetkých závad</P2Medium>
            </LinkWithConfirm>
          </Flex>

          {deviceFaultsFields}

          <Group position="left" mt="md" ml={40}>
            <Button
              onClick={() =>
                form.insertListItem('faults', {
                  faultName: '',
                  faultType: 'Bez závady',
                  description: '',
                  key: randomId(),
                  deleted: false,
                })
              }
              leftIcon={<IconPlus stroke="1.5" height={24} width={24} />}
              size="md"
              variant="subtle"
            >
              Pridať ďalšiu závadu
            </Button>
          </Group>
        </>
      )}
      {/* END SECTION: Device faults */}

      {/* START SECTION: Device files */}
      <Divider mt={40} color="gray.1" />
      <Flex align="center" ml={40} mt={40} mb={16}>
        <H3Medium color="gray.7">Súbory</H3Medium>
      </Flex>
      <Box pl={40} pr={40} pb={40}>
        <DeviceFiles
          context="form"
          deviceId={deviceId ? Number(deviceId) : 0}
          form={form}
          files={form.values.files}
          readonly={readOnly.files}
        />
      </Box>
      {/* END SECTION: Device files */}
    </FormWrapper>
  );
}
