import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentGetContactPersonsPathParameters } from 'api/actions/department-get-contact-persons/department-get-contact-persons-path-parameters';
import { DepartmentGetContactPersonsResponse } from 'api/actions/department-get-contact-persons/department-get-contact-persons-response';

type DepartmentGetContactPersonsQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentGetContactPersons action.
 */
export const departmentGetContactPersonsActionPath = new SweetPath<keyof DepartmentGetContactPersonsPathParameters>(
  '/api/department/contact-persons/{organizationId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DepartmentGetContactPersons actions.
 */
export default function createDepartmentGetContactPersonsAction(axios: AxiosInstance) {
  return function departmentGetContactPersonsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DepartmentGetContactPersonsPathParameters;
    query?: DepartmentGetContactPersonsQueryParameters;
  }) {
    const path = departmentGetContactPersonsActionPath.insert(parameters);

    return axios.get<DepartmentGetContactPersonsResponse, DepartmentGetContactPersonsResponse>(path, {
      params: query,
      headers,
    });
  };
}
