import { Button, Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { groupBy } from 'lodash';
import { usePipelineData } from 'pages/revisions-module/template-editor/editors/pipeline/PipelineDataProvider';
import { useAutomationConfigurator } from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationConfiguratorProvider';
import { useMemo } from 'react';
import AutomationGroup from 'pages/revisions-module/template-editor/editors/pipeline/AutomationGroup';
import { IAutomation } from 'pages/revisions-module/template-editor/editors/pipeline/types';

/** Returns the text representation of the automation transition. */
const getAutomationTransition = ({ statusSource, statusTarget }: IAutomation) => `${statusSource} -> ${statusTarget}`;

/**
 * The automation editor part of the template editor page.
 */
export default function PipelineEditor() {
  const { automations, addAutomation } = usePipelineData();
  const { configureAutomation } = useAutomationConfigurator();

  const groups = useMemo(() => Object.entries(groupBy(automations, getAutomationTransition)), [automations]);

  return (
    <Stack spacing={24}>
      <Stack spacing={40}>
        {groups.map(([key, automations]) => (
          <AutomationGroup key={key} automations={automations} />
        ))}
      </Stack>
      <Button
        variant="tertiary"
        size="lg"
        leftIcon={<IconPlus />}
        onClick={() => configureAutomation({ onSubmit: addAutomation })}
      >
        Pridať automatizáciu
      </Button>
    </Stack>
  );
}
