import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceActiveTypesPathParameters } from 'api/actions/device-active-types/device-active-types-path-parameters';
import { DeviceActiveTypesResponse } from 'api/actions/device-active-types/device-active-types-response';

type DeviceActiveTypesQueryParameters = Record<string, any>;

/**
 * The path of the DeviceActiveTypes action.
 */
export const deviceActiveTypesActionPath = new SweetPath<keyof DeviceActiveTypesPathParameters>(
  '/api/device/types/active/{departmentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceActiveTypes actions.
 */
export default function createDeviceActiveTypesAction(axios: AxiosInstance) {
  return function deviceActiveTypesAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceActiveTypesPathParameters;
    query?: DeviceActiveTypesQueryParameters;
  }) {
    const path = deviceActiveTypesActionPath.insert(parameters);

    return axios.get<DeviceActiveTypesResponse, DeviceActiveTypesResponse>(path, { params: query, headers });
  };
}
