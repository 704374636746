import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DepartmentSetStatusPayload } from 'api/actions/department-set-status/department-set-status-payload';
import { DepartmentSetStatusPathParameters } from 'api/actions/department-set-status/department-set-status-path-parameters';
import { DepartmentSetStatusResponse } from 'api/actions/department-set-status/department-set-status-response';

type DepartmentSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the DepartmentSetStatus action.
 */
export const departmentSetStatusActionPath = new SweetPath<keyof DepartmentSetStatusPathParameters>(
  '/api/department/status/{departmentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DepartmentSetStatus actions.
 */
export default function createDepartmentSetStatusAction(axios: AxiosInstance) {
  return function departmentSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DepartmentSetStatusPathParameters;
    query?: DepartmentSetStatusQueryParameters;
    payload: DepartmentSetStatusPayload;
  }) {
    const path = departmentSetStatusActionPath.insert(parameters);

    return axios.put<DepartmentSetStatusResponse, DepartmentSetStatusResponse, DepartmentSetStatusPayload>(
      path,
      payload
    );
  };
}
