import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { MeasuringDeviceGetPathParameters } from 'api/actions/measuring-device-get/measuring-device-get-path-parameters';
import { MeasuringDeviceGetResponse } from 'api/actions/measuring-device-get/measuring-device-get-response';

type MeasuringDeviceGetQueryParameters = Record<string, any>;

/**
 * The path of the MeasuringDeviceGet action.
 */
export const measuringDeviceGetActionPath = new SweetPath<keyof MeasuringDeviceGetPathParameters>(
  '/api/measuring-device/get/{measuringDeviceId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized MeasuringDeviceGet actions.
 */
export default function createMeasuringDeviceGetAction(axios: AxiosInstance) {
  return function measuringDeviceGetAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: MeasuringDeviceGetPathParameters;
    query?: MeasuringDeviceGetQueryParameters;
  }) {
    const path = measuringDeviceGetActionPath.insert(parameters);

    return axios.get<MeasuringDeviceGetResponse, MeasuringDeviceGetResponse>(path, { params: query, headers });
  };
}
