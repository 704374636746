import { GridApi } from 'ag-grid-community';
import { MeasuringDeviceRow } from 'components/tables/measuring-device/types';
import MeasuringDeviceActions from 'components/tables/measuring-device/MeasuringDeviceActions';

/**
 * Column for the actions.
 */
export default function ActionsColumn({ api, data }: { api: GridApi; data: MeasuringDeviceRow }) {
  return (
    <MeasuringDeviceActions
      {...data}
      onDelete={() => {
        api.applyTransaction({ remove: [data] });
      }}
    />
  );
}
