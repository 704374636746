import UserTable from 'components/tables/user/UserTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { useState } from 'react';

/**
 * Page used to display a list of users.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=233%3A8532 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Users-5bf19a6ae3124e39a2e827dc477efa3a?pvs=4 Notion Page}
 */
export default function UsersPage() {
  const [search, setSearch] = useState('');
  const [showDiscarded, setShowDiscarded] = useState(false);

  return (
    <DashboardLayout
      title="Používatelia"
      breadcrumbs={[{ title: 'Používatelia' }]}
      height="screen"
      withSearch
      onSearch={setSearch}
      withToggle
      onToggleChange={setShowDiscarded}
      toggleLabel="Zobraziť vyradených"
    >
      <UserTable search={search} showDiscarded={showDiscarded} />
    </DashboardLayout>
  );
}
