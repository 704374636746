import { Flex } from '@mantine/core';
import { DepartmentRow } from 'components/tables/department/types';
import BadgeDeleted from 'components/tables/Badge';
import P2Medium from 'components/typography/P2Medium';

/**
 * Column for the department name.
 */
export default function DepartmentNameColumn({ data: { departmentName, status } }: { data: DepartmentRow }) {
  const opacity = status ? 1 : 0.6;

  return (
    <Flex gap={10} align="center">
      {!status && <BadgeDeleted text="Vyradené" />}
      <P2Medium color="gray.8" style={{ opacity }}>
        {departmentName}
      </P2Medium>
    </Flex>
  );
}
