import { Menu } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import { useConfirmWithMessage } from 'components/modals/confirm-with-message/ConfirmWithMessageProvider';
import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { noop } from 'lodash';
import useDeclineAction from 'components/tables/revision/actions/hooks/use-decline-action';

/**
 * Action performed by the revision technician to decline the revision.
 */
export default function DeclineAction({ revision, onStatusChange = noop }: RevisionSingleActionProps) {
  const { declineRevision } = useDeclineAction();
  const { confirmWithMessage } = useConfirmWithMessage();

  /**
   * Confirms the decline of the revision.
   */
  const confirmDeclineRevision = () =>
    confirmWithMessage({
      title: 'Odmietnutie revíznej správy',
      message: 'Naozaj si prajete odmietnuť túto revíznu správu?',
      requiredReason: true,
      onConfirmWithMessage: (reason?: string) => declineRevision({ revision, reason: reason ?? '', onStatusChange }),
    });

  return (
    <Menu.Item color="gray.8" onClick={confirmDeclineRevision} icon={<IconX stroke={1.5} size={24} />}>
      Odmietnuť
    </Menu.Item>
  );
}
