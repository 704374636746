import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconItalic } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to make the selected text italic.
 */
export default function Italic({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconItalic />}
      label="Kurzíva"
      shortcut="Ctrl + I"
      active={editor?.isActive('italic')}
      onClick={() => editor?.chain().focus().toggleItalic().run()}
    />
  );
}
