import { MantineThemeOverride, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { P2Regular } from 'theme/typography';

export type BreadcrumbsTheme = NonNullable<MantineThemeOverride['components']>['Breadcrumbs'];

export const Breadcrumbs: BreadcrumbsTheme = {
  styles: (theme) => ({
    root: {
      color: theme.colors.gray[7],
      a: {
        fontFamily: theme.fontFamily,
        color: theme.colors.gray[8],
        textDecoration: 'underline',
        '&:last-child': {
          textDecoration: 'none',
          color: theme.colors.gray[6],
        },
      },
      ...P2Regular,
    },
    separator: {
      marginLeft: rem(8),
      marginRight: rem(8),
    },
  }),
  defaultProps: (theme) => ({
    separator: <IconChevronRight color={theme.colors.gray[6]} width={20} height={20} stroke={2} />,
  }),
};
