import P2Regular from 'components/typography/P2Regular';
import { FaultRow } from 'components/tables/fault/types';
import Excerpt from 'components/Excerpt';

/**
 * Column for the fault more info.
 */
export default function MoreInfoColumn({ data: { moreInfo } }: { data: FaultRow }) {
  return (
    <P2Regular color="gray.7">
      <Excerpt text={moreInfo ?? '-'} length={70} />
    </P2Regular>
  );
}
