import { IconBold } from '@tabler/icons-react';
import DropdownControl, {
  DropdownControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/DropdownControl';
import Bold from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Bold';
import Italic from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Italic';
import Underline from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Underline';
import Strike from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Strike';
import Superscript from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Superscript';
import Subscript from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/format/text/Subscript';

/**
 * Control used to select which text marks to apply to the selected text.
 */
export default function TextMarks({ kind }: { kind: DropdownControlKind }) {
  return (
    <DropdownControl label="Text" kind={kind} icon={<IconBold />}>
      <Bold kind="menu-item" />
      <Italic kind="menu-item" />
      <Underline kind="menu-item" />
      <Strike kind="menu-item" />
      <Superscript kind="menu-item" />
      <Subscript kind="menu-item" />
    </DropdownControl>
  );
}
