import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthLoggedUserInfoPathParameters } from 'api/actions/auth-logged-user-info/auth-logged-user-info-path-parameters';
import { AuthLoggedUserInfoResponse } from 'api/actions/auth-logged-user-info/auth-logged-user-info-response';

type AuthLoggedUserInfoQueryParameters = Record<string, any>;

/**
 * The path of the AuthLoggedUserInfo action.
 */
export const authLoggedUserInfoActionPath = new SweetPath<keyof AuthLoggedUserInfoPathParameters>('/api/auth/me', {
  path: '{param}',
});

/**
 * Creates a contextualized AuthLoggedUserInfo actions.
 */
export default function createAuthLoggedUserInfoAction(axios: AxiosInstance) {
  return function authLoggedUserInfoAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthLoggedUserInfoQueryParameters } = {}) {
    const path = authLoggedUserInfoActionPath.original;

    return axios.get<AuthLoggedUserInfoResponse, AuthLoggedUserInfoResponse>(path, { params: query, headers });
  };
}
