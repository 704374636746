import { Box, Button, Flex, Group, Radio, rem, SimpleGrid, Stack } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { RevisionListTemplatesAndVisualsResponse } from 'api/actions/revision-list-templates-and-visuals/revision-list-templates-and-visuals-response';
import { useApi } from 'api/api-context';
import FormInputGroup from 'components/forms/FormInputGroup';
import Toast from 'components/Toast';
import P2Regular from 'components/typography/P2Regular';
import panic from 'errors/panic';
import { useEffect, useMemo, useState } from 'react';

/**
 * Parameters of the TemplateAndVisualSelect component.
 */
export interface TemplateAndVisualSelectProps {
  deviceTypeId: number;
  customerOrganization: number;
  loading: boolean;
  initialTemplateSlug?: string;
  initialVisualSlug?: string;
  onChange: ({ template, visual }: { template: string; visual: string }) => void;
}

type Template = RevisionListTemplatesAndVisualsResponse[number];

/**
 * Allows to select revision template and visual.
 */
export default function TemplateAndVisualSelect({
  deviceTypeId,
  customerOrganization,
  loading,
  initialTemplateSlug = '',
  initialVisualSlug = '',
  onChange,
}: TemplateAndVisualSelectProps) {
  const { getAction } = useApi();
  const [templateSlug, setTemplateSlug] = useState(initialTemplateSlug);
  const [visualSlug, setVisualSlug] = useState(initialVisualSlug);
  const [templates, setTemplates] = useState<Template[]>([]);

  const initialTemplate = useMemo(
    () => templates.find((t) => t.slug === initialTemplateSlug),
    [initialTemplateSlug, templates]
  );

  const selectedTemplate = useMemo(() => templates.find((t) => t.slug === templateSlug), [templateSlug, templates]);
  const availableVisuals = useMemo(() => selectedTemplate?.visuals || [], [selectedTemplate]);

  // Select the only visual if there is only one.
  useEffect(() => {
    if (availableVisuals && availableVisuals.length === 1) {
      setVisualSlug(availableVisuals[0].slug);
    }
  }, [availableVisuals]);

  useEffect(() => {
    const action = getAction('RevisionListTemplatesAndVisuals');
    action({
      query: {
        filters: {
          'deviceTypeId.eq': deviceTypeId,
          'executingOrganization.eq': customerOrganization,
          'showDiscarded.eq': 0, // do not show discarded templates
        },
      },
    })
      .then((response) =>
        setTemplates(
          response.map((template) => ({
            ...template,
            visuals: [{ revisionVisualId: 999, name: 'Všeobecný', slug: 'te-temp-visual' }],
          }))
        )
      )
      .catch(panic);
  }, [deviceTypeId]);

  return (
    <Flex
      mih="calc(100vh - 160px)"
      bg="white"
      style={{ borderRadius: '4px' }}
      direction="column"
      justify="space-between"
    >
      <Stack p={40} spacing={40}>
        <FormInputGroup groupTitle="Šablóna revíznej správy" stackProps={{ pl: 0, pr: 0, pb: 0, pt: 0 }}>
          <Radio.Group value={templateSlug} onChange={setTemplateSlug}>
            <SimpleGrid cols={2} spacing={40} verticalSpacing={24}>
              {templates.map(({ name, slug }) => (
                <Box
                  key={slug}
                  p={24}
                  bg="gray.0"
                  pos="relative"
                  sx={{
                    borderRadius: '4px',
                    border: slug === initialTemplateSlug ? '2px solid var(--mantine-color-blue-7)' : '',
                    '&::after': {
                      content: '"Predvolená šablóna"',
                      display: slug === initialTemplateSlug ? 'block' : 'none',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      background: 'var(--mantine-color-blue-7)',
                      color: 'white',
                      padding: `${rem(2)} ${rem(4)}`,
                      borderRadius: `0 0 0 ${rem(4)}`,
                    },
                  }}
                >
                  <Radio value={slug} label={name} />
                </Box>
              ))}
            </SimpleGrid>
          </Radio.Group>
          {initialTemplate && templateSlug !== initialTemplateSlug && (
            <Toast
              type="warning"
              fullWidth
              withCloseButton={false}
              message={<span>Zmenou šablóny sa vymažú všetky vyplnené údaje v revíznej správe.</span>}
            />
          )}
        </FormInputGroup>

        <FormInputGroup groupTitle="Vizuál revíznej správy" stackProps={{ pl: 0, pr: 0, pb: 0, pt: 0 }}>
          <Radio.Group value={visualSlug} onChange={setVisualSlug}>
            <SimpleGrid cols={2} spacing={40}>
              {availableVisuals.length > 0 ? (
                availableVisuals.map(({ slug, name }) => (
                  <Box key={slug} p={24} bg="gray.0" style={{ borderRadius: '4px' }}>
                    <Radio value={slug} label={name} />
                  </Box>
                ))
              ) : (
                <P2Regular py={16} color="gray.7">
                  Najprv vyberte šablónu revíznej správy
                </P2Regular>
              )}
            </SimpleGrid>
          </Radio.Group>
        </FormInputGroup>
      </Stack>
      <Group
        h={88}
        w="100%"
        py={24}
        px={40}
        style={{ borderRadius: '0 0 4px 4px', boxShadow: '0px -4px 12px 0px rgba(197, 198, 211, 0.40)' }}
        position="right"
      >
        <Button
          rightIcon={<IconChevronRight stroke={1.5} size={24} />}
          onClick={() => onChange({ template: templateSlug, visual: visualSlug })}
          loading={loading}
          disabled={!templateSlug || !visualSlug}
        >
          Pokračovať na revíznu správu
        </Button>
      </Group>
    </Flex>
  );
}
