import { Checkbox, Stack } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import P3Regular from 'components/typography/P3Regular';
import { IFormInputSpecMeasuringDevice } from 'pages/revisions-module/template-editor/editors/form/types';

/**
 * Preview for a date input.
 */
export default function MeasuringDeviceInputPreview({ spec }: { spec: IFormInputSpecMeasuringDevice }) {
  return (
    <Stack spacing={12} pt={12}>
      <Checkbox
        size="md"
        label={
          <Stack spacing={0}>
            <P2Regular>Merací prístroj #1</P2Regular>
            <P3Regular maw={660} style={{ whiteSpace: 'pre-wrap' }} color="gray.7">
              Kalibrovaný 4.4.2021 • VČ: 12345678 • Výrobca: Amperia • Typ: Ampérmeter
            </P3Regular>
          </Stack>
        }
        disabled={spec.readOnly}
        defaultChecked
      />
      <Checkbox
        size="md"
        label={
          <Stack spacing={0}>
            <P2Regular>Merací prístroj #2</P2Regular>
            <P3Regular maw={660} style={{ whiteSpace: 'pre-wrap' }} color="gray.7">
              Kalibrovaný 3.12.2022 • VČ: 87654321 • Výrobca: FMPoint • Typ: Voltmeter
            </P3Regular>
          </Stack>
        }
        disabled={spec.readOnly}
      />
    </Stack>
  );
}
