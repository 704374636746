import { Button, Divider, Group, Modal, Stack, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DateInput } from 'components/inputs/DateInput';
import { useMemo } from 'react';

interface FixDeviceFaultModalProps {
  onFaultFix: (data: { fixedAt: string; fixedNote?: string }) => void;
  opened: boolean;
  onClose: () => void;
}

type FormValues = {
  fixedAt: string;
  fixedNote?: string;
};

/**
 * Modal for marking the device's fault as fixed.
 */
export default function FixDeviceFaultModal({ onFaultFix, opened, onClose }: FixDeviceFaultModalProps) {
  const now = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }, []);

  const form = useForm({
    initialValues: {
      fixedAt: now,
    },
  });

  /**
   * Handles the form submission, passes the data to the parent component.
   */
  function submit({ fixedAt, fixedNote }: FormValues) {
    onFaultFix({ fixedAt, fixedNote });
    onClose();
  }

  return (
    <Modal centered onClose={onClose} opened={opened} title="Riešenie závady">
      <Divider color="gray.1" mb={24} mt={12} />
      <form onSubmit={form.onSubmit(submit)}>
        <Stack spacing={24} pb={48}>
          <DateInput
            // TODO name
            label="Dátum riešenia závady"
            size="lg"
            {...form.getInputProps('fixedAt')}
          />
          <Textarea
            label="Popis riešenia"
            placeholder="Popis riešenia"
            size="lg"
            name="amperia-fixedNote"
            {...form.getInputProps('fixedNote')}
          />
        </Stack>

        <Group position="apart" spacing={16} pt={24}>
          <Button variant="link" onClick={onClose} px={8}>
            Zrušiť
          </Button>
          <Button type="submit" variant="primary">
            Uložiť a označiť ako vyriešené
          </Button>
        </Group>
      </form>
    </Modal>
  );
}
