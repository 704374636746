import { SelectItem, Stack } from '@mantine/core';
import { Select, SelectProps } from '@mantine/core';
import { useApi } from 'api/api-context';
import P3Regular from 'components/typography/P3Regular';
import panic from 'errors/panic';
import { isString, noop } from 'lodash';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';

interface PredefinedDataSelectProps {
  deviceTypeId: number;
  predefinedDataType: string | number;
  value?: string;
  loadingPlaceholder?: string;
  hideDescription?: boolean;
  onChange?: (value: string) => void;
  onDescriptionChange?: (value: string) => void;
}

type Props = PredefinedDataSelectProps & Omit<SelectProps, 'data'>;
type DescriptionMap = Record<string, string>;

/**
 * A select for predefined data.
 */
export default function PredefinedDataSelect({
  deviceTypeId,
  predefinedDataType,
  value = '',
  loadingPlaceholder = 'Načítavanie ...',
  hideDescription = false,
  onChange = noop,
  onDescriptionChange = noop,
  ...props
}: Props) {
  const { getAction } = useApi();
  const [options, setOptions] = useState<SelectItem[]>(() => [{ value: nanoid(), label: 'Načítavanie ...' }]);
  const [loading, setLoading] = useState(true);
  const [descriptions, setDescriptions] = useState<DescriptionMap>({});

  const description = useMemo(() => descriptions[value], [descriptions, value]);

  useEffect(() => {
    const action = getAction('PredefinedDataListRevisionData');

    const filters: Record<string, string | number> = {
      'deviceTypeId.eq': deviceTypeId,
    };

    if (isString(predefinedDataType)) {
      filters['predefinedDataType.eq'] = predefinedDataType;
    } else {
      filters['predefinedDataTypeId.eq'] = predefinedDataType;
    }

    action({ query: { filters } })
      .then((response) => {
        setOptions(response.map(({ key }) => ({ value: key, label: key })));
        setDescriptions(response.reduce((m, { key, value }) => ({ ...m, [key]: value ?? '' }), {} as DescriptionMap));
      })
      .catch(panic)
      .finally(() => setLoading(false));
  }, [deviceTypeId, predefinedDataType]);

  useEffect(() => {
    if (description !== undefined) {
      onDescriptionChange(description);
    }
  }, [description]);

  return (
    <Stack spacing={4}>
      <Select
        data={options}
        searchable
        size="md"
        placeholder={loading ? loadingPlaceholder : 'Vyberte'}
        value={value}
        onChange={(newValue) => onChange(newValue as string)}
        {...props}
      />
      {description !== '' && !hideDescription && (
        <P3Regular pl={24} pr={16} color="gray.6">
          {description}
        </P3Regular>
      )}
    </Stack>
  );
}
