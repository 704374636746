import { noop } from 'lodash';
import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { Button, Menu } from '@mantine/core';
import { IconSignature } from '@tabler/icons-react';
import BrainItMoreInfo from 'components/BrainItMoreInfo';
import { useNavigate } from 'react-router-dom';
import { REVISION_PAGE_PATH } from 'routes/paths';
import { BRAIN_IT_WINDOW_OPEN_TARGET } from 'env';
import { useWarn } from 'components/modals/message/MessageProvider';

/**
 * Action performed by the user who is the technician of the revision.
 */
export default function FinishSigningAction({
  revision: { revisionId, kepSignUrl },
  onStatusChange = noop,
  kind,
}: RevisionSingleActionProps) {
  const { warn } = useWarn();
  const navigate = useNavigate();

  /**
   * Confirms the redirect to the third party to sign the revision.
   */
  const continueSigningRevision = () =>
    warn({
      title: 'Dokončiť podpisovanie',
      content:
        'Revízna správa už bola odoslaná do systému BrainIT. Pre dokončenie podpisovania budete presmerovaný do ich webového portálu.',
      footerLeftSection: <BrainItMoreInfo />,
      primaryButtonText: 'Prejsť na BrainIT',
      onWarned: () => {
        navigate(REVISION_PAGE_PATH.insert({ revisionId }));
        window.open(kepSignUrl, BRAIN_IT_WINDOW_OPEN_TARGET);
      },
    });

  if (kind === 'button') {
    return (
      <Button
        size="md"
        onClick={continueSigningRevision}
        leftIcon={<IconSignature stroke={1.5} size={24} />}
        variant="secondary"
        w={180}
      >
        Podpísať
      </Button>
    );
  }

  return (
    <Menu.Item color="gray.8" onClick={continueSigningRevision} icon={<IconSignature stroke={1.5} size={24} />}>
      Podpísať
    </Menu.Item>
  );
}
