import { ActionIcon, Box, Button, Group, LoadingOverlay, Menu, useMantineTheme } from '@mantine/core';
import { UserGetResponse } from 'api/actions/user-get/user-get-response';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Avatar from 'components/Avatar';
import P1Medium from 'components/typography/P1Medium';
import { IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';
import { usePickUsers } from 'components/modals/pick-users-modal/PickUsersProvider';
import { OrganizationFormData } from 'components/forms/organization/OrganizationForm';
import { DepartmentFormData } from 'components/forms/department/DepartmentForm';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import P3Regular from 'components/typography/P3Regular';
import { useConfirm } from 'components/modals/message/MessageProvider';

type User = UserGetResponse;

interface PersonWithPermissionsProps {
  userId: number;
  permissions: number[];
  templateSlug: string;
  value: OrganizationFormData['people'] | DepartmentFormData['people'];
  updatePermissionsForPerson: ({ permissions, templateName }: { permissions: number[]; templateName: string }) => void;
  inForm: 'department' | 'organization';
  onUserDelete: (userId: number) => void;
  hideControls?: boolean;
  displayInfoText?: boolean;
}

/**
 * Renders the person's name, their permissions and allows to update the permissions.
 */
export default function PersonWithPermissions({
  userId,
  permissions,
  templateSlug,
  value: formValue,
  updatePermissionsForPerson,
  inForm,
  onUserDelete,
  hideControls = false,
  displayInfoText = false,
}: PersonWithPermissionsProps) {
  const { getAction } = useApi();
  const theme = useMantineTheme();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const { confirm } = useConfirm();
  const { pickUsers } = usePickUsers();
  const { getPermission, getPermissionTemplate } = usePermissionDataProvider();

  const infoText = useMemo(() => {
    return inForm === 'organization' ? 'z organizácie' : 'zo strediska';
  }, [inForm]);

  /**
   * Confirms the user's removal from the department/organization.
   */
  const confirmUserDelete = useCallback(() => {
    confirm({
      title: 'Zmazať používateľa',
      content: `Naozaj chcete zmazať používateľa ${infoText}?`,
      onConfirm: () => onUserDelete(userId),
    });
  }, [confirm, onUserDelete, userId]);

  /**
   * Opens modal where the user's permissions can be updated and on the modal's close, updates the permissions.
   */
  const updateUsersPermissions = () => {
    pickUsers({
      title: '',
      permissionsTitle: `Oprávnenia pre: ${user?.firstName} ${user?.lastName}`,
      hiddenUsers: [], // The users are not displayed in the modal, so there are no hidden users.
      initialPermissions: permissions,
      initialTemplateName: formValue[userId].templateName,
      pickPermissions: true,
      pickUsers: false,
      inForm,
      onPick: ({ permissions, templateName }) => {
        updatePermissionsForPerson({ permissions, templateName });
      },
    });
  };

  useEffect(() => {
    const userGetAction = getAction('UserGet');

    userGetAction({ parameters: { userId: String(userId) } })
      .then((response) => setUser(response))
      .catch(panic)
      .finally(() => setLoading(false));
  }, [userId]);

  return (
    <Box>
      {loading ? (
        <LoadingOverlay visible={loading} />
      ) : (
        <>
          <Group
            style={{
              border: `1px solid ${theme.colors.gray[2]}`,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              alignContent: 'center',
            }}
            bg="gray.0"
            py={12}
            px={16}
            position="apart"
            mih={56}
            spacing={16}
          >
            <Group spacing={12}>
              <Avatar fullName={`${user?.firstName} ${user?.lastName}`} size="m" bgColor={theme.colors.gray[7]} />
              <Group spacing={8} maw={380}>
                <P1Medium color="gray.8">
                  {user?.firstName} {user?.lastName}
                </P1Medium>
                <P2Regular>({getPermissionTemplate(templateSlug)?.permissionTemplateName})</P2Regular>
              </Group>
            </Group>
            {!hideControls && (
              <Group spacing={12}>
                <Button
                  variant="subtle-gray"
                  size="sm"
                  leftIcon={<IconPencil stroke="1.5" color={theme.colors.gray[7]} />}
                  onClick={updateUsersPermissions}
                >
                  Upraviť oprávnenia
                </Button>
                <Box style={{ borderLeft: `1px solid ${theme.colors.gray[2]}` }} h={24}></Box>
                <Menu shadow="md" width={300} position="bottom-end">
                  <Menu.Target>
                    <ActionIcon variant="subtle-gray" size="sm">
                      <IconDotsVertical stroke="1.5" height={24} width={24} />
                    </ActionIcon>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item color="red.8" icon={<IconTrashX stroke={1.5} size={20} />} onClick={confirmUserDelete}>
                      Zmazať používateľa {infoText}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Group>
            )}
            {displayInfoText && <P3Regular>Používateľ a jeho práva sú podedené z organizácie</P3Regular>}
          </Group>
          <Group
            style={{
              borderBottom: `1px solid ${theme.colors.gray[2]}`,
              borderLeft: `1px solid ${theme.colors.gray[2]}`,
              borderRight: `1px solid ${theme.colors.gray[2]}`,
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
            p={16}
            spacing={8}
          >
            {permissions.map(
              (permission) =>
                getPermission(permission) && (
                  <Box
                    style={{ border: `1px solid ${theme.colors.gray[2]}`, borderRadius: '4px' }}
                    bg="white"
                    px={8}
                    key={permission}
                  >
                    <P2Regular>{getPermission(permission)?.permissionName}</P2Regular>
                  </Box>
                )
            )}
          </Group>
        </>
      )}
    </Box>
  );
}
