import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconStrikethrough } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to make the selected text strikethrough.
 */
export default function Strike({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconStrikethrough />}
      label="Prečiarknuté"
      shortcut="Ctrl + Shift + S"
      active={editor?.isActive('strike')}
      onClick={() => editor?.chain().focus().toggleStrike().run()}
    />
  );
}
