import { Box, ScrollArea } from '@mantine/core';

/**
 * Props for the EditorTabPane component.
 */
export interface TemplateEditorTabPaneProps {
  children: React.ReactNode;
  visible: boolean;
  innerPadding?: number;
}

/**
 * The tab pane for the editor.
 */
export default function TemplateEditorTabPane({ children, visible, innerPadding = 24 }: TemplateEditorTabPaneProps) {
  return (
    <ScrollArea
      h="calc(100vh - 171px)"
      bg="white"
      display={visible ? 'block' : 'none'}
      styles={{
        viewport: { overflowY: 'scroll', overflowX: 'hidden' },
        scrollbar: { '&[data-orientation="horizontal"]': { display: 'none !important' } },
      }}
      classNames={{ viewport: '!overflow-x-hidden !overflow-y-scroll' }}
    >
      <Box pos="relative" p={innerPadding}>
        {children}
      </Box>
    </ScrollArea>
  );
}
