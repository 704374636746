import { createStyles, rem, TextInput } from '@mantine/core';
import H3Medium from 'components/typography/H3Medium';
import { useCallback } from 'react';
import { H3Medium as H3MediumStyles } from 'theme/typography';

/**
 * Parameters of the FormSectionName component.
 */
interface IFormSectionNameProps {
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  autoFocus?: boolean;
}

/**
 * Styles for the FormSectionName component.
 */
const useStyles = createStyles((theme) => {
  const borderTransparent = `${rem(1)} solid transparent`;
  const borderDotted = `${rem(1)} dotted ${theme.colors.gray[4]}`;

  return {
    input: {
      ...H3MediumStyles,
      border: 'none',
      background: 'transparent !important',
      borderBottom: borderTransparent,
      borderRadius: 0,
      transition: 'all 100ms ease',
      '&:hover, &:focus': {
        borderBottom: borderDotted,
      },
    },
  };
});

/**
 * The name of a form section.
 */
export default function FormSectionName({
  value,
  onChange,
  readOnly = false,
  autoFocus = false,
}: IFormSectionNameProps) {
  const { classes } = useStyles();

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        e.currentTarget.blur();
      }
    },
    [onChange]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      onChange(e.currentTarget.value);
    },
    [onChange]
  );

  if (readOnly) {
    return <H3Medium pl={8}>{value}</H3Medium>;
  }

  return (
    <TextInput
      readOnly={readOnly}
      autoFocus={autoFocus}
      miw={500}
      defaultValue={value}
      placeholder="(Bez názvu)"
      classNames={classes}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      tabIndex={readOnly ? -1 : 0}
    />
  );
}
