import { IFormInputSpecCheckbox } from 'pages/revisions-module/template-editor/editors/form/types';
import { Checkbox } from '@mantine/core';
import { RevisionFieldProps } from 'components/forms/revision/fill-out/types';
import { useFillOutRevisionDataProvider } from 'components/forms/revision/fill-out/data/FillOutRevisionDataProvider';

/**
 * Checkbox input for the revision form.
 */
export default function RevisionFieldCheckbox({ spec, name }: RevisionFieldProps<IFormInputSpecCheckbox>) {
  const { form } = useFillOutRevisionDataProvider();

  return (
    <Checkbox
      label={spec.label}
      size="lg"
      name={name}
      required={spec.required}
      readOnly={spec.readOnly}
      disabled={spec.readOnly}
      {...form.getInputProps(name, { type: 'checkbox' })}
    />
  );
}
