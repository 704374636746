import { IconFold } from '@tabler/icons-react';
import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { useMemo } from 'react';

/**
 * Merges the selected cells in the table.
 */
export default function MergeCells({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  const iconStyle = useMemo(() => ({ transform: 'rotate(90deg)' }), []);

  const isCell = editor.isActive('tableCell');
  const isSingleCell = editor.isActive('tableCell', { rowspan: 1, colspan: 1 });

  const active = isCell && !isSingleCell;
  const label = active ? 'Zrušiť zlúčenie buniek' : 'Zlúčiť bunky';

  return (
    <SimpleControl
      kind={kind}
      label={label}
      active={active}
      icon={<IconFold style={iconStyle} />}
      onClick={() => editor.chain().focus().mergeOrSplit().run()}
    />
  );
}
