import { Box, Flex } from '@mantine/core';
import { RevisionTemplateRow } from 'components/tables/revision-template/types';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for device type name.
 */
export default function DeviceTypeNameColumn({ data: { deviceType } }: { data: RevisionTemplateRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <DeviceTypeIcon typeId={deviceType.deviceTypeId} />
      </Box>
      <P2Regular color="gray.8">{deviceType.deviceTypeName}</P2Regular>
    </Flex>
  );
}
