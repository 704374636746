import { Box, Group, useMantineTheme } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import DeviceTypeLabel from 'components/device/DeviceTypeLabel';
import { Link } from 'react-router-dom';
import { DEVICE_SUBTYPES_PAGE_PATH } from 'routes/paths';

/**
 * Single box in the DeviceTypes page
 */
export default function DeviceTypeBox({ deviceTypeId }: { deviceTypeId: number }) {
  const theme = useMantineTheme();

  return (
    <Box
      bg="gray.0"
      style={{ borderRadius: '4px', textDecoration: 'none' }}
      p={24}
      component={Link}
      to={DEVICE_SUBTYPES_PAGE_PATH.insert({ deviceTypeId })}
    >
      <Group position="apart">
        <DeviceTypeLabel deviceType={deviceTypeId} size="md" />
        <IconChevronRight stroke={2} size={24} color={theme.colors.gray[8]} />
      </Group>
    </Box>
  );
}
