import { Box, Group, ThemeIcon } from '@mantine/core';
import { RevisionPlanDate } from 'components/tables/revision-plan/RevisionPlanCalendarUtils';
import P4Regular from 'components/typography/P4Regular';
import RevisionPlanModal from 'components/tables/revision-plan/RevisionPlanModal';
import { useDisclosure } from '@mantine/hooks';

/**
 * The props for the PlanItem component.
 */
interface PlanItemProps {
  revisionPlanId: number;
  revisionPeriod: number;
  type: string;
  deviceId: number;
  deviceName: string;
  dateItem: RevisionPlanDate;
  organization: { organizationId: number; organizationName: string };
  department: { departmentId: number; departmentName: string };
  nextRevision?: Date;
  deviceType: { deviceTypeId: number; deviceTypeName: string };
  deviceSubtype: { deviceTypeId: number; deviceTypeName: string };
  manufactured?: number;
  manufacturer?: string;
  serialNumber?: string;
  initialOpened?: boolean;
}

/**
 * Single plan item in the calendar cell.
 */
export default function PlanItem({
  revisionPlanId,
  revisionPeriod,
  type,
  deviceId,
  deviceName,
  dateItem,
  organization,
  department,
  nextRevision,
  deviceType,
  deviceSubtype,
  manufactured,
  manufacturer,
  serialNumber,
  initialOpened = false,
}: PlanItemProps) {
  const [opened, { open, close }] = useDisclosure(initialOpened);

  return (
    <>
      <Group
        onClick={open}
        p={4}
        noWrap
        spacing={8}
        sx={() => ({
          '&:hover': {
            backgroundColor: '#DCDCE5 !important',
            borderRadius: '4px',
            transition: 'background-color 0.5s',
            cursor: 'pointer',
          },
        })}
      >
        <ThemeIcon color="red.8" size={4} children={undefined} />
        <Box w="100%" style={{ whiteSpace: 'nowrap' }}>
          <P4Regular
            color={dateItem.isCurrentMonth ? 'gray.8' : 'gray.6'}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {type} - {deviceName}
          </P4Regular>
        </Box>
      </Group>
      <RevisionPlanModal
        opened={opened}
        onClose={close}
        revisionPlanId={revisionPlanId}
        revisionPeriod={revisionPeriod}
        type={type}
        organization={organization}
        department={department}
        nextRevision={nextRevision ? nextRevision : undefined}
        deviceId={deviceId}
        deviceType={deviceType}
        deviceSubtype={deviceSubtype}
        deviceName={deviceName}
        manufactured={manufactured}
        manufacturer={manufacturer}
        serialNumber={serialNumber}
      />
    </>
  );
}
