import { Button, Divider, FileButton, Flex, Group, LoadingOverlay, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconUpload } from '@tabler/icons-react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import createValidator from 'components/forms/validators/create-validator';
import required from 'components/forms/validators/rules/rule-required';
import P1Regular from 'components/typography/P1Regular';
import P4Medium from 'components/typography/P4Medium';
import { FILE_UPLOAD_MAX_SIZE } from 'env';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';
import toBase64 from 'utils/to-base64';

/**
 * The parameters of the add device file modal.
 */
export interface AddDeviceFileModalParams {
  onAdd: (remoteId: string, tabName: string, fileName: string) => void;
  opened: boolean;
  onClose: () => void;
}

type FormValues = {
  fileName: string;
  tabName: string;
  file: { fileName: string; remoteId: string; originalName: string } | null;
};

/**
 * Modal for adding a file.
 *
 * @see {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=256-11634 Figma Design}
 */
export default function AddDeviceFileModal({ onAdd, opened, onClose }: AddDeviceFileModalParams) {
  const [loading, setLoading] = useState(false);
  const { uploadFile } = useFileManager();

  const form = useForm<FormValues>({
    initialValues: {
      fileName: '',
      tabName: '',
      file: null,
    },
    validate: {
      fileName: createValidator([required]),
      tabName: createValidator([required]),
      file: (file) => {
        if (!file) {
          return 'Nebol nahratý žiaden súbor';
        }

        return null;
      },
    },
  });

  /**
   * Makes the call to the FileModule API.
   *
   * @param {File} file
   */
  const sendFileToApi = async (file: File) => {
    const contents = await toBase64(file);
    const remoteId = await uploadFile({ fileName: file.name, contents });

    return remoteId;
  };

  /**
   * Handles the file change. Sends the file to the File Manager service.
   */
  function handleChange(file: File) {
    if (!file) {
      return; // No file selected using OS dialog.
    }

    let error = null;
    let valid = false;

    if (file.size > FILE_UPLOAD_MAX_SIZE) {
      // TODO: Compute this from FILE_UPLOAD_MAX_SIZE.
      error = 'Súbor je príliš veľký. Maximálna veľkosť súboru je 100 MB.';
    } else {
      valid = true;
    }

    // Set error.
    form.setFieldError('file', error);

    // Upload the file.
    if (valid) {
      setLoading(true);

      sendFileToApi(file)
        .then((remoteId) => {
          form.setFieldValue('file', {
            fileName: file.name,
            remoteId,
            originalName: file.name,
          });
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }

  /**
   * Handles the form submission, passes the data to the DeviceForm.
   */
  function submit({ fileName, tabName, file }: FormValues) {
    onAdd(file!.remoteId, tabName, fileName);
    onClose();
  }

  // Reset the form when the modal is closed.
  useEffect(() => {
    if (!opened) {
      form.reset();
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Pridať súbor"
      centered
      radius={4}
      overlayProps={{
        opacity: 0.4,
        color: '#282934',
      }}
      styles={{
        close: { color: '#585B74', '&:hover': { backgroundColor: '#EBEBF0', color: '#353746' } },
      }}
    >
      <form onSubmit={form.onSubmit(submit)}>
        <Flex direction="column" gap={24}>
          <Divider color="gray.2" />
          <TextInput
            label="Názov"
            placeholder="Názov súboru"
            size="md"
            form="add-device-file-modal"
            name="amperia-fileName"
            {...form.getInputProps('fileName')}
          />
          <Select
            data={[
              { label: 'Projektová dokumentácia', value: 'project-documentation' },
              { label: 'Revízne správy', value: 'revisions' },
              { label: 'Fotografie', value: 'photos' },
              { label: 'Ostatné', value: 'other' },
            ]}
            label="Typ"
            placeholder="Vyberte kategóriu súboru"
            size="md"
            searchable
            form="add-device-file-modal"
            name="amperia-tabName"
            {...form.getInputProps('tabName')}
          />
          <Divider color="gray.2" />
          <Group position="left">
            <LoadingOverlay visible={loading} loaderProps={{ size: 'sm' }} />
            <FileButton
              onChange={handleChange}
              accept="application/pdf,image/jpg,image/jpeg,image/png"
              form="add-device-file-modal"
            >
              {(props) => (
                <Button
                  {...props}
                  leftIcon={<IconUpload stroke="1.5" />}
                  variant={form.getInputProps('file').error ? 'danger-secondary' : 'tertiary'}
                  sx={(theme) => ({
                    border: form.getInputProps('file').error ? `1px solid ${theme.colors.red[8]}` : undefined,
                  })}
                  size="md"
                  onClick={() => {
                    form.setFieldValue('file', null);
                    props.onClick();
                  }}
                >
                  Pridať súbor
                </Button>
              )}
            </FileButton>
            {form.getInputProps('file').error && <P4Medium color="red.8">{form.getInputProps('file').error}</P4Medium>}
            <P1Regular>{form.values.file && form.values.file.originalName}</P1Regular>
          </Group>
          <Divider color="gray.2" />
          <Group position="apart">
            <Button variant="subtle-gray" onClick={onClose}>
              Zrušiť
            </Button>
            <Button variant="primary" type="submit">
              Pridať
            </Button>
          </Group>
        </Flex>
      </form>
    </Modal>
  );
}
