import { UseFormReturnType } from '@mantine/form';
import { useCounter, useInterval } from '@mantine/hooks';
import { RevisionFormData } from 'components/forms/revision/fill-out/types';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * Hook used to compute the value of fields._totalFaultCount periodically.
 * Use indirect state change to avoid unnecessary re-renders.
 */
export default function useTotalFaultsIntervalCheck(form: UseFormReturnType<RevisionFormData>) {
  const [totalFaults, setTotalFaults] = useState(0);
  const [refreshToken, { increment: forceRefresh }] = useCounter();
  const refreshInterval = useInterval(forceRefresh, 1000);

  useEffect(() => {
    const devices = Object.values(form.values.devices);
    const totalFaults = sumBy(devices, 'fields._deviceFaultCount');

    setTotalFaults(totalFaults);
  }, [refreshToken]);

  useEffect(() => {
    form.setFieldValue('fields._totalFaultCount', totalFaults);
  }, [totalFaults]);

  useEffect(() => {
    refreshInterval.start();
    return refreshInterval.stop;
  }, []);
}
