import { ActionIcon, Box, Button, createStyles, Menu, rem, Tooltip } from '@mantine/core';
import P2Regular from 'components/typography/P2Regular';
import P4Medium from 'components/typography/P4Medium';
import { useMemo } from 'react';

export type SimpleControlKind = 'action-icon' | 'button' | 'menu-item';

export interface SimpleControlProps {
  kind: SimpleControlKind;
  icon: React.ReactNode;
  label: string;
  active?: boolean;
  disabled?: boolean;
  shortcut?: string;
  onClick?: () => void;
}

const useStyles = createStyles((theme, withIcon: boolean) => ({
  icon: {
    '& svg': {
      strokeWidth: '2.5 !important',
    },
  },
  itemWrapper: {
    minWidth: rem(300),
  },
  itemInner: {
    height: rem(36),
    display: 'grid',
    gridTemplateColumns: `${withIcon ? 'auto' : ''} 1fr auto`,
    alignItems: 'center',
    gap: rem(8),
    padding: `${rem(8)} ${rem(16)}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
    '& svg': {
      width: rem(16),
      height: rem(16),
    },
  },
}));

/**
 * A toolbar control action button for operations without a dropdown.
 */
export default function SimpleControl({ kind, icon, label, active, disabled, shortcut, onClick }: SimpleControlProps) {
  const { classes } = useStyles(!!icon);

  const tooltipLabel = useMemo(() => {
    if (shortcut) {
      return `${label} (${shortcut})`;
    }

    return label;
  }, [label, shortcut]);

  const iconVariant = useMemo(() => (active ? 'subtle-blue' : 'subtle-gray'), [active]);

  if (kind === 'action-icon') {
    return (
      <Tooltip label={tooltipLabel} withArrow={false} openDelay={500} withinPortal position="bottom">
        <ActionIcon variant={iconVariant} size="xs" onClick={onClick} className={classes.icon} disabled={disabled}>
          {icon}
        </ActionIcon>
      </Tooltip>
    );
  }

  if (kind === 'button') {
    return (
      <Button variant="subtle-gray" size="xs" onClick={onClick} leftIcon={icon} disabled={disabled}>
        {label}
      </Button>
    );
  }

  return (
    <Box className={classes.itemWrapper}>
      <Menu.Item pos="relative" onClick={onClick} p={0} disabled={disabled}>
        <Box className={classes.itemInner} opacity={disabled ? 0.7 : 1}>
          {icon}
          <P2Regular c={disabled ? 'gray.6' : 'gray.8'}>{label}</P2Regular>
          <P4Medium c={disabled ? 'gray.5' : 'gray.6'}>{shortcut}</P4Medium>
        </Box>
      </Menu.Item>
    </Box>
  );
}
