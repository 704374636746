import { Center } from '@mantine/core';
import H3Medium from 'components/typography/H3Medium';

/**
 * Variant of the badge color.
 */
export type NumberBadgeVariant = 'gray' | 'red';

/**
 * Parameters of the NumberBadge component.
 */
export interface NumberBadgeProps {
  value: number | number[];
  radius?: number;
  variant?: NumberBadgeVariant;
}

/**
 * Mapping of the badge colors to the background colors.
 */
const BG_MAP: Record<NumberBadgeVariant, string> = {
  gray: 'gray.1',
  red: 'red.8',
};

/**
 * Mapping of the badge colors to the text colors.
 */
const TEXT_MAP: Record<NumberBadgeVariant, string> = {
  gray: 'gray.8',
  red: 'white',
};

/**
 * Displays a number in a badge.
 */
export default function NumberBadge({ variant = 'gray', radius = 10, value }: NumberBadgeProps) {
  const backgroundColor = BG_MAP[variant];
  const textColor = TEXT_MAP[variant];

  const values = Array.isArray(value) ? value : [value];

  return (
    <Center sx={{ borderRadius: radius }} bg={backgroundColor} py={2} px={8}>
      <H3Medium color={textColor}>{values.join(' / ')}</H3Medium>
    </Center>
  );
}
