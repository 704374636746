import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionDeletePathParameters } from 'api/actions/revision-delete/revision-delete-path-parameters';
import { RevisionDeleteResponse } from 'api/actions/revision-delete/revision-delete-response';

type RevisionDeleteQueryParameters = Record<string, any>;

/**
 * The path of the RevisionDelete action.
 */
export const revisionDeleteActionPath = new SweetPath<keyof RevisionDeletePathParameters>(
  '/api/revision/delete/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionDelete actions.
 */
export default function createRevisionDeleteAction(axios: AxiosInstance) {
  return function revisionDeleteAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionDeletePathParameters;
    query?: RevisionDeleteQueryParameters;
  }) {
    const path = revisionDeleteActionPath.insert(parameters);

    return axios.post<RevisionDeleteResponse, RevisionDeleteResponse>(path, { params: query, headers });
  };
}
