import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconUnderline } from '@tabler/icons-react';
import { useRichTextEditorContext } from '@mantine/tiptap';

/**
 * A button that allows the user to make the selected text underline.
 */
export default function Underline({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconUnderline />}
      label="Podčiarknuté"
      shortcut="Ctrl + U"
      active={editor?.isActive('underline')}
      onClick={() => editor?.chain().focus().toggleUnderline().run()}
    />
  );
}
