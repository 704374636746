import { useApi } from 'api/api-context';
import RevisionTemplatesTable from 'components/tables/revision-template/RevisionTemplatesTable';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';

/**
 * The page for listing all revision templates.
 */
export default function RevisionTemplatesPage() {
  const { roleId } = useApi();
  const [search, setSearch] = useState('');
  const [showDiscarded, setShowDiscarded] = useState(false);

  if (roleId !== ROLE_ADMIN_ID) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout
      height="screen"
      title="Šablóny revíznych správ"
      breadcrumbs={[{ title: 'Šablóny revíznych správ' }]}
      withSearch
      onSearch={setSearch}
      withToggle
      onToggleChange={setShowDiscarded}
      toggleLabel="Zobraziť vyradené"
    >
      <RevisionTemplatesTable search={search} showDiscarded={showDiscarded} />
    </DashboardLayout>
  );
}
