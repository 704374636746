import { notifications } from '@mantine/notifications';
import { useApi } from 'api/api-context';
import useImmediateAction from 'api/use-immediate-action';
import MeasuringDeviceForm, { MeasuringDeviceFormData } from 'components/forms/measuring-device/MeasuringDeviceForm';
import { MEASURING_DEVICE_REDIRECT_AFTER_SAVE } from 'env';
import panic from 'errors/panic';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { MEASURING_DEVICES_PAGE_PATH, SETTINGS_PAGE_PATH } from 'routes/paths';
import { SUCCESS_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Page used to edit measuring device.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=251%3A8831 Figma Design}
 * - {@linkhttps://www.notion.so/Pre-defined-Data-Edit-Measuring-Device-b308c6dbb1a249aeb8558efecc87fbe7?pvs=4 Notion Page}
 */
export default function EditMeasuringDevicePage() {
  const navigate = useNavigate();
  const { measuringDeviceId } = useParams();
  const { getAction } = useApi();

  const { data, loading, error } = useImmediateAction(() => {
    const measuringDeviceGetAction = getAction('MeasuringDeviceGet');
    return measuringDeviceGetAction({ parameters: { measuringDeviceId: measuringDeviceId! } });
  });

  if (error) {
    panic(error);
    return <></>;
  }

  if (!measuringDeviceId) {
    return <Navigate to={MEASURING_DEVICES_PAGE_PATH.original} />;
  }

  /**
   * Handles the form submission.
   */
  function onSubmit({ deviceName, manufacturer, serialNumber, type, deviceInfo }: MeasuringDeviceFormData) {
    const measuringDeviceUpdateAction = getAction('MeasuringDeviceUpdate');

    return measuringDeviceUpdateAction({
      parameters: {
        measuringDeviceId: measuringDeviceId!,
      },
      payload: {
        measuringDeviceName: deviceName,
        manufacturer,
        type,
        serialNumber,
        description: deviceInfo,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Merací prístroj bol úspešne upravený',
          message: `Merací prístroj ${deviceName} bol úspešne upravený`,
          color: SUCCESS_NOTIFICATION_COLOR,
        });

        if (MEASURING_DEVICE_REDIRECT_AFTER_SAVE === 'list') {
          navigate(MEASURING_DEVICES_PAGE_PATH.original);
        }
      })
      .catch(panic);
  }

  return (
    <DashboardLayout
      title={`Úprava meracieho prístroja ${data?.measuringDeviceName ?? ''}`}
      breadcrumbs={[
        { title: 'Nastavenia', link: SETTINGS_PAGE_PATH.original },
        { title: 'Meracie prístroje', link: MEASURING_DEVICES_PAGE_PATH.original },
        {
          title: `Úprava meracieho prístroja ${data?.measuringDeviceName ?? 'Načítavanie ....'}`,
        },
      ]}
    >
      {!loading && (
        <MeasuringDeviceForm
          onSubmit={onSubmit}
          initialValues={{
            deviceName: data?.measuringDeviceName ?? '',
            manufacturer: data?.manufacturer ?? '',
            type: data?.type ?? '',
            serialNumber: data?.serialNumber ?? '',
            deviceInfo: data?.description ?? '',
          }}
        />
      )}
    </DashboardLayout>
  );
}
