import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserUpdatePayload } from 'api/actions/user-update/user-update-payload';
import { UserUpdatePathParameters } from 'api/actions/user-update/user-update-path-parameters';
import { UserUpdateResponse } from 'api/actions/user-update/user-update-response';

type UserUpdateQueryParameters = Record<string, any>;

/**
 * The path of the UserUpdate action.
 */
export const userUpdateActionPath = new SweetPath<keyof UserUpdatePathParameters>('/api/user/update/{userId}', {
  path: '{param}',
});

/**
 * Creates a contextualized UserUpdate actions.
 */
export default function createUserUpdateAction(axios: AxiosInstance) {
  return function userUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserUpdatePathParameters;
    query?: UserUpdateQueryParameters;
    payload: UserUpdatePayload;
  }) {
    const path = userUpdateActionPath.insert(parameters);

    return axios.put<UserUpdateResponse, UserUpdateResponse, UserUpdatePayload>(path, payload);
  };
}
