import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionCustomerConfirmPayload } from 'api/actions/revision-customer-confirm/revision-customer-confirm-payload';
import { RevisionCustomerConfirmPathParameters } from 'api/actions/revision-customer-confirm/revision-customer-confirm-path-parameters';
import { RevisionCustomerConfirmResponse } from 'api/actions/revision-customer-confirm/revision-customer-confirm-response';

type RevisionCustomerConfirmQueryParameters = Record<string, any>;

/**
 * The path of the RevisionCustomerConfirm action.
 */
export const revisionCustomerConfirmActionPath = new SweetPath<keyof RevisionCustomerConfirmPathParameters>(
  '/api/revision/confirm/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionCustomerConfirm actions.
 */
export default function createRevisionCustomerConfirmAction(axios: AxiosInstance) {
  return function revisionCustomerConfirmAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionCustomerConfirmPathParameters;
    query?: RevisionCustomerConfirmQueryParameters;
    payload: RevisionCustomerConfirmPayload;
  }) {
    const path = revisionCustomerConfirmActionPath.insert(parameters);

    return axios.post<RevisionCustomerConfirmResponse, RevisionCustomerConfirmResponse, RevisionCustomerConfirmPayload>(
      path,
      payload
    );
  };
}
