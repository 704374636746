import { Group, Text } from '@mantine/core';
import DeviceTypeIcon from 'components/device/DeviceTypeIcon';
import { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
}

const CertificateItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        {/* NOTE: image is a workaround to get the ID of the device type */}
        <DeviceTypeIcon typeId={Number(image)} />
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  );
});

export default CertificateItem;
