import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionCustomerReturnPayload } from 'api/actions/revision-customer-return/revision-customer-return-payload';
import { RevisionCustomerReturnPathParameters } from 'api/actions/revision-customer-return/revision-customer-return-path-parameters';
import { RevisionCustomerReturnResponse } from 'api/actions/revision-customer-return/revision-customer-return-response';

type RevisionCustomerReturnQueryParameters = Record<string, any>;

/**
 * The path of the RevisionCustomerReturn action.
 */
export const revisionCustomerReturnActionPath = new SweetPath<keyof RevisionCustomerReturnPathParameters>(
  '/api/revision/return/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionCustomerReturn actions.
 */
export default function createRevisionCustomerReturnAction(axios: AxiosInstance) {
  return function revisionCustomerReturnAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionCustomerReturnPathParameters;
    query?: RevisionCustomerReturnQueryParameters;
    payload: RevisionCustomerReturnPayload;
  }) {
    const path = revisionCustomerReturnActionPath.insert(parameters);

    return axios.post<RevisionCustomerReturnResponse, RevisionCustomerReturnResponse, RevisionCustomerReturnPayload>(
      path,
      payload
    );
  };
}
