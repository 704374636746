import { LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { RevisionGetResponse } from 'api/actions/revision-get/revision-get-response';
import useDocumentGeneratorV2 from 'api/document-generator-v2/use-document-generator-v2';
import panic from 'errors/panic';
import { useEffect, useState } from 'react';

/**
 * Parameters of the PreviewTabContent component.
 */
export interface RevisionPreviewProps {
  revisionData: any;
  revision: RevisionGetResponse;
  height?: string;
}

/**
 * Content of the preview tab in the Electric revision form.
 */
export default function RevisionPreview({
  revisionData,
  revision,
  height = 'calc(100vh - 200px)',
}: RevisionPreviewProps) {
  const [preview, setPreview] = useState('');
  const [loading, { close: stopLoading }] = useDisclosure(true);
  const { generatePreview } = useDocumentGeneratorV2();

  useEffect(() => {
    if (revision.revisionTemplate?.templateScheme && revision.revisionTemplate?.visualData) {
      generatePreview(revision, revisionData).then(setPreview).catch(panic).finally(stopLoading);
    } else {
      fetch('/api/documents/preview/', {
        method: 'POST',
        body: JSON.stringify({
          data: revisionData,
          template: revision.revisionTemplate?.slug,
          theme: revision.revisionVisual?.slug,
        }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.text())
        .then(setPreview)
        .catch(panic)
        .finally(stopLoading);
    }
  }, [revisionData, revision]);

  return (
    <>
      <LoadingOverlay visible={loading} />
      <iframe title="Náhľad" srcDoc={preview} style={{ width: '100%', height, border: 'none' }} />
    </>
  );
}
