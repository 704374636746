import { P1Medium } from 'theme/typography';
import { MantineThemeOverride, rem } from '@mantine/core';

export type SwitchTheme = NonNullable<MantineThemeOverride['components']>['Switch'];

export const Switch: SwitchTheme = {
  styles: (theme) => ({
    label: {
      paddingLeft: rem(8),
      transform: 'translateY(-1px)',
      ...P1Medium,
      color: theme.colors.gray[7],
    },
  }),
};
