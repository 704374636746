import { Box, Flex } from '@mantine/core';
import { RevisionRow } from 'components/tables/revision/types';
import { IconCircleLetterZ } from '@tabler/icons-react';
import P2Regular from 'components/typography/P2Regular';

/**
 * Column for the assigned by organization name.
 */
export default function AssignedByOrganizationNameColumn({ data: { assignedBy } }: { data: RevisionRow }) {
  return (
    <Flex gap={8}>
      <Box w={24} h={24}>
        <IconCircleLetterZ stroke={1.5} height={24} width={24} />
      </Box>
      <P2Regular color="gray.8">{assignedBy.organizationName}</P2Regular>
    </Flex>
  );
}
