import { useApi } from 'api/api-context';
import {
  DataMultiSelect,
  DataMultiSelectInheritedProps,
  DataSelect,
  DataSelectInheritedProps,
} from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

/**
 * Fetches organizations form API.
 */
function useOrganizationSelect({
  showDiscarded,
  permissionSlug,
}: {
  showDiscarded?: boolean;
  permissionSlug?: string;
}) {
  const { getAction } = useApi();

  const fetchOrganizations = useCallback(async () => {
    const action = getAction('OrganizationList');
    const organizations = await action({
      query: {
        filters: {
          'showDiscarded.eq': showDiscarded ? 1 : 0,
          'permissionSlug.eq': permissionSlug,
        },
      },
    });

    return organizations.map((organization) => ({
      label: (organization.status ? '' : '[VYRADENÁ] ') + organization.organizationName,
      value: organization.organizationId,
    }));
  }, [getAction, showDiscarded, permissionSlug]);

  return { fetchOrganizations };
}

/**
 * The shared props of the select components.
 */
interface OrganizationSelectProp {
  showDiscarded?: boolean;
  permissionSlug?: string;
}

/**
 * The type of the Organization Select component.
 */
type OrganizationSelectProps = OrganizationSelectProp & DataSelectInheritedProps;

/**
 * The type of the Organization Multiselect component.
 */
type OrganizationMultiSelectProps = OrganizationSelectProp & DataMultiSelectInheritedProps;

/**
 * Select input for organizations.
 */
export function OrganizationSelect({
  showDiscarded = true,
  permissionSlug = undefined,
  ...props
}: OrganizationSelectProps) {
  const key = useMemo(() => nanoid(), [showDiscarded, permissionSlug]);
  const { fetchOrganizations } = useOrganizationSelect({ showDiscarded, permissionSlug });
  return <DataSelect key={key} action={fetchOrganizations} labelProp="label" valueProp="value" {...props} />;
}

/**
 * Select input for organizations (multiple).
 */
export function OrganizationMultiSelect({
  showDiscarded = true,
  permissionSlug = undefined,
  ...props
}: OrganizationMultiSelectProps) {
  const key = useMemo(() => nanoid(), [showDiscarded, permissionSlug]);

  const { fetchOrganizations } = useOrganizationSelect({ showDiscarded, permissionSlug });
  return <DataMultiSelect key={key} action={fetchOrganizations} labelProp="label" valueProp="value" {...props} />;
}
