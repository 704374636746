import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { OrganizationUpdatePayload } from 'api/actions/organization-update/organization-update-payload';
import { OrganizationUpdatePathParameters } from 'api/actions/organization-update/organization-update-path-parameters';
import { OrganizationUpdateResponse } from 'api/actions/organization-update/organization-update-response';

type OrganizationUpdateQueryParameters = Record<string, any>;

/**
 * The path of the OrganizationUpdate action.
 */
export const organizationUpdateActionPath = new SweetPath<keyof OrganizationUpdatePathParameters>(
  '/api/organization/update/{organizationId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized OrganizationUpdate actions.
 */
export default function createOrganizationUpdateAction(axios: AxiosInstance) {
  return function organizationUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: OrganizationUpdatePathParameters;
    query?: OrganizationUpdateQueryParameters;
    payload: OrganizationUpdatePayload;
  }) {
    const path = organizationUpdateActionPath.insert(parameters);

    return axios.put<OrganizationUpdateResponse, OrganizationUpdateResponse, OrganizationUpdatePayload>(path, payload);
  };
}
