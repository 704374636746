import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { DeviceListSubtypesPathParameters } from 'api/actions/device-list-subtypes/device-list-subtypes-path-parameters';
import { DeviceListSubtypesResponse } from 'api/actions/device-list-subtypes/device-list-subtypes-response';

type DeviceListSubtypesQueryParameters = Record<string, any>;

/**
 * The path of the DeviceListSubtypes action.
 */
export const deviceListSubtypesActionPath = new SweetPath<keyof DeviceListSubtypesPathParameters>(
  '/api/device/subtypes/{departmentId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized DeviceListSubtypes actions.
 */
export default function createDeviceListSubtypesAction(axios: AxiosInstance) {
  return function deviceListSubtypesAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: DeviceListSubtypesPathParameters;
    query?: DeviceListSubtypesQueryParameters;
  }) {
    const path = deviceListSubtypesActionPath.insert(parameters);

    return axios.get<DeviceListSubtypesResponse, DeviceListSubtypesResponse>(path, { params: query, headers });
  };
}
