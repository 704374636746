import { CheckboxProps, MantineThemeOverride, rem } from '@mantine/core';
import { P1Regular, P2Regular } from 'theme/typography';

export type CheckboxTheme = NonNullable<MantineThemeOverride['components']>['Checkbox'];

export const CheckboxDefaultProps: Partial<CheckboxProps> = {
  size: 'md',
};

export const Checkbox: CheckboxTheme = {
  // todo: define
  sizes: {
    lg: (theme) => ({
      input: {
        width: rem(24),
        height: rem(24),
      },
      inner: {
        width: rem(24),
        height: rem(24),
      },
      icon: {
        width: rem(20),
        height: rem(20),
        padding: rem(2),
      },
      label: {
        ...P1Regular,
      },
    }),
    md: (theme) => ({
      input: {
        width: rem(20),
        height: rem(20),
      },
      inner: {
        marginTop: rem(2),
        width: rem(20),
        height: rem(20),
      },
      icon: {
        width: rem(16),
        height: rem(16),
        padding: rem(2),
      },
      label: {
        ...P2Regular,
      },
    }),
    sm: (theme) => ({
      input: {
        width: rem(16),
        height: rem(16),
      },
      inner: {
        marginTop: rem(4),
        width: rem(16),
        height: rem(16),
      },
      icon: {
        width: rem(12),
        height: rem(12),
        padding: rem(2),
      },
      label: {
        ...P2Regular,
      },
    }),
  },
  styles: (theme) => ({
    input: {
      backgroundColor: theme.white,
      borderColor: theme.colors.gray[4],
      '&:hover': {
        backgroundColor: theme.colors.gray[0],
        borderColor: theme.colors.gray[5],
      },
      '&:focus': {
        backgroundColor: theme.white,
        borderColor: theme.colors.blue[8],
        boxShadow: '0px 0px 0px 4px #DBE4FF',
      },
      '&:disabled': {
        backgroundColor: theme.white,
        borderColor: theme.colors.gray[1],
      },
      '&:checked': {
        backgroundColor: theme.colors.blue[8],
      },
      '&:hover:checked': {
        backgroundColor: theme.colors.blue[9],
        borderColor: theme.colors.blue[9],
      },
      '&:disabled:checked': {
        backgroundColor: theme.colors.blue[2],
        borderColor: theme.colors.blue[2],
      },
    },
    label: {
      paddingLeft: rem(8),
      color: theme.colors.gray[8],
      '&:disabled': {
        color: theme.colors.gray[4],
      },
      '&:checked': {
        color: theme.colors.gray[7],
      },
    },
  }),
};
