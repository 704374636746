import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataListTypesPathParameters } from 'api/actions/predefined-data-list-types/predefined-data-list-types-path-parameters';
import { PredefinedDataListTypesResponse } from 'api/actions/predefined-data-list-types/predefined-data-list-types-response';

type PredefinedDataListTypesQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataListTypes action.
 */
export const predefinedDataListTypesActionPath = new SweetPath<keyof PredefinedDataListTypesPathParameters>(
  '/api/predefined-data/types',
  { path: '{param}' }
);

/**
 * Creates a contextualized PredefinedDataListTypes actions.
 */
export default function createPredefinedDataListTypesAction(axios: AxiosInstance) {
  return function predefinedDataListTypesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: PredefinedDataListTypesQueryParameters } = {}) {
    const path = predefinedDataListTypesActionPath.original;

    return axios.get<PredefinedDataListTypesResponse, PredefinedDataListTypesResponse>(path, {
      params: query,
      headers,
    });
  };
}
