import { useApi } from 'api/api-context';
import {
  DataMultiSelect,
  DataMultiSelectInheritedProps,
  DataSelect,
  DataSelectInheritedProps,
} from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

/**
 * The shared props of the select components.
 */
interface DepartmentSelectProp {
  userId: number;
  groupByOrganization?: boolean;
}

/**
 * Fetches followed departments form API.
 */
function useFollowedDepartmentSelect({
  userId,
  groupByOrganization,
}: {
  userId: number;
  groupByOrganization?: boolean;
}) {
  const { getAction } = useApi();

  const fetchFollowedDepartments = useCallback(async () => {
    const action = getAction('UserGetFollowedDepartmentsList');
    const departments = await action({
      parameters: {
        userId: String(userId),
      },
    });

    return departments.map((department) => ({
      label: department.departmentName,
      value: String(department.departmentId),
      group: groupByOrganization ? department.organizationName : '',
    }));
  }, [getAction, groupByOrganization]);

  return { fetchFollowedDepartments };
}

/**
 * The type of the User Followed Department Select component.
 */
type UserFollowedDepartmentSelectProps = DepartmentSelectProp & DataSelectInheritedProps;

/**
 * The type of the User Followed Department Multiselect component.
 */
type UserFollowedDepartmentMultiSelectProps = DepartmentSelectProp & DataMultiSelectInheritedProps;

/**
 * Select input for followed departments.
 */
export function UserFollowedDepartmentSelect({
  userId,
  groupByOrganization = false,
  ...props
}: UserFollowedDepartmentSelectProps) {
  const key = useMemo(() => nanoid(), [groupByOrganization]);

  const { fetchFollowedDepartments } = useFollowedDepartmentSelect({
    groupByOrganization,
    userId,
  });

  return (
    <DataSelect
      key={key}
      action={fetchFollowedDepartments}
      labelProp="label"
      valueProp="value"
      groupProp="group"
      {...props}
    />
  );
}

/**
 * Select input for followed departments (multiple).
 */
export function UserFollowedDepartmentMultiSelect({
  userId,
  groupByOrganization = false,
  ...props
}: UserFollowedDepartmentMultiSelectProps) {
  const key = useMemo(() => nanoid(), [groupByOrganization]);

  const { fetchFollowedDepartments } = useFollowedDepartmentSelect({
    groupByOrganization,
    userId,
  });

  return (
    <DataMultiSelect
      key={key}
      action={fetchFollowedDepartments}
      labelProp="label"
      valueProp="value"
      groupProp="group"
      {...props}
    />
  );
}
