import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionTechnicianDeclinePayload } from 'api/actions/revision-technician-decline/revision-technician-decline-payload';
import { RevisionTechnicianDeclinePathParameters } from 'api/actions/revision-technician-decline/revision-technician-decline-path-parameters';
import { RevisionTechnicianDeclineResponse } from 'api/actions/revision-technician-decline/revision-technician-decline-response';

type RevisionTechnicianDeclineQueryParameters = Record<string, any>;

/**
 * The path of the RevisionTechnicianDecline action.
 */
export const revisionTechnicianDeclineActionPath = new SweetPath<keyof RevisionTechnicianDeclinePathParameters>(
  '/api/revision/technician-decline/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionTechnicianDecline actions.
 */
export default function createRevisionTechnicianDeclineAction(axios: AxiosInstance) {
  return function revisionTechnicianDeclineAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionTechnicianDeclinePathParameters;
    query?: RevisionTechnicianDeclineQueryParameters;
    payload: RevisionTechnicianDeclinePayload;
  }) {
    const path = revisionTechnicianDeclineActionPath.insert(parameters);

    return axios.post<
      RevisionTechnicianDeclineResponse,
      RevisionTechnicianDeclineResponse,
      RevisionTechnicianDeclinePayload
    >(path, payload);
  };
}
