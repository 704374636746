import { useDisclosure } from '@mantine/hooks';
import PickDeviceTypeModal from 'components/modals/pick-device-type/PickDeviceTypeModal';
import { DeviceType } from 'model/DeviceType';
import { createContext, useContext, useState } from 'react';

/**
 * Callback for when user selects the device type.
 */
type OnPickCallback = (value: DeviceType) => void;

/**
 * Parameters of the pickDeviceType function.
 */
interface PickDeviceTypeParams {
  title: string;
  addButtonLabel: string;
  onPick: OnPickCallback;
}

/**
 * Function for selecting the device type.
 */
type PickDeviceType = (params: PickDeviceTypeParams) => void;

/**
 * Interface of the PickDeviceTypeContext.
 */
interface IPickDeviceTypeContext {
  pickDeviceType: PickDeviceType;
}

/**
 * Context for selecting the device type.
 */
const PickDeviceTypeContext = createContext<IPickDeviceTypeContext>(undefined!);

/**
 * Provider for selecting the device type.
 */
export function PickDeviceTypeProvider({ children }: { children: React.ReactNode }) {
  const [opened, { close, open }] = useDisclosure(false);
  const [params, setParams] = useState<PickDeviceTypeParams | undefined>(undefined);

  /**
   * Opens the modal for selecting the device type.
   */
  const pickDeviceType: PickDeviceType = (params) => {
    setParams(params);
    open();
  };

  return (
    <PickDeviceTypeContext.Provider value={{ pickDeviceType }}>
      {children}
      <PickDeviceTypeModal opened={opened} onClose={close} {...params!} />
    </PickDeviceTypeContext.Provider>
  );
}

/**
 * Hook for selecting the type of technician's certificate.
 */
export function usePickDeviceType() {
  return useContext(PickDeviceTypeContext);
}
