import P2Regular from 'components/typography/P2Regular';
import { UserRow } from 'components/tables/user/types';
import { isEmpty } from 'lodash';
import { Box, Group, Stack, Tooltip } from '@mantine/core';
import Excerpt from 'components/Excerpt';
import { useMemo } from 'react';
import { ROLE_ADMIN_ID } from 'model/Role';

/**
 * Column for the permissions.
 */
export default function PermissionsColumn({ data: { permissions, role } }: { data: UserRow }) {
  const { mappedPermissions, firstPermission, rest } = useMemo(() => {
    const organizationPermissions = permissions['organization'] ?? [];
    const departmentPermissions = permissions['department'] ?? [];
    const mappedPermissions = [];

    if (!isEmpty(organizationPermissions)) {
      const textPermissions = organizationPermissions.map(
        ({ organizationName, templateName }) => `${templateName} v ${organizationName}`
      );

      mappedPermissions.push(...textPermissions);
    }

    if (!isEmpty(departmentPermissions)) {
      const textPermissions = departmentPermissions.map(
        ({ organizationName, templateName, departmentName }) =>
          `${templateName} na stredisku ${departmentName} (${organizationName})`
      );

      mappedPermissions.push(...textPermissions);
    }

    const firstPermission = mappedPermissions[0];
    const rest = mappedPermissions.slice(1);

    return { mappedPermissions, firstPermission, rest };
  }, [permissions]);

  if (role.roleId === ROLE_ADMIN_ID) {
    return <P2Regular>Systémový administrátor</P2Regular>;
  }

  if (isEmpty(mappedPermissions)) {
    return <P2Regular>Používateľ nemá žiadne oprávnenia</P2Regular>;
  }

  const hasMore = rest.length > 0;

  return (
    <Group spacing={4} noWrap>
      <P2Regular color="gray.8">
        <Excerpt text={firstPermission} length={50} />
      </P2Regular>
      {hasMore && (
        <Tooltip
          bg="gray.2"
          withArrow
          label={
            <Stack spacing={4}>
              {rest.map((p) => (
                <P2Regular key={p} color="gray.8">
                  {p}
                </P2Regular>
              ))}
            </Stack>
          }
        >
          <Box>
            <P2Regular color="gray.6">(+{rest.length})</P2Regular>
          </Box>
        </Tooltip>
      )}
    </Group>
  );
}
