import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionListExecutingCompaniesOptionsPathParameters } from 'api/actions/revision-list-executing-companies-options/revision-list-executing-companies-options-path-parameters';
import { RevisionListExecutingCompaniesOptionsResponse } from 'api/actions/revision-list-executing-companies-options/revision-list-executing-companies-options-response';

type RevisionListExecutingCompaniesOptionsQueryParameters = Record<string, any>;

/**
 * The path of the RevisionListExecutingCompaniesOptions action.
 */
export const revisionListExecutingCompaniesOptionsActionPath = new SweetPath<
  keyof RevisionListExecutingCompaniesOptionsPathParameters
>('/api/revision/list-executing-companies-options/{revisionId}/{deviceTypeId}', { path: '{param}' });

/**
 * Creates a contextualized RevisionListExecutingCompaniesOptions actions.
 */
export default function createRevisionListExecutingCompaniesOptionsAction(axios: AxiosInstance) {
  return function revisionListExecutingCompaniesOptionsAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionListExecutingCompaniesOptionsPathParameters;
    query?: RevisionListExecutingCompaniesOptionsQueryParameters;
  }) {
    const path = revisionListExecutingCompaniesOptionsActionPath.insert(parameters);

    return axios.get<RevisionListExecutingCompaniesOptionsResponse, RevisionListExecutingCompaniesOptionsResponse>(
      path,
      { params: query, headers }
    );
  };
}
