import { useApi } from 'api/api-context';
import { useCallback, useMemo } from 'react';
import { DataSelect, DataSelectInheritedProps } from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { getDeviceType } from 'model/DeviceType';

/**
 * Fetches predefined data types from API.
 */
function usePredefinedDataTypeSelect({
  deviceTypeId,
  isGlobal,
  isPersonal,
}: {
  deviceTypeId?: number;
  isGlobal?: boolean;
  isPersonal?: boolean;
} = {}) {
  const { getAction } = useApi();

  const fetchPredefineDataTypes = useCallback(async () => {
    const action = getAction('PredefinedDataListTypes');

    const predefinedDataTypes = await action({
      query: {
        filters: {
          'deviceTypeId.eq': deviceTypeId,
          'isGlobal.eq': isGlobal,
          'isPersonal.eq': isPersonal,
        },
      },
    });

    return predefinedDataTypes.map((type) => ({
      label: type.name,
      value: String(type.predefinedDataTypeId),
      group: !deviceTypeId ? getDeviceType(type.deviceTypeId)?.name : '',
    }));
  }, [getAction, deviceTypeId]);

  return { fetchPredefineDataTypes };
}

interface PredefinedDataTypeProp {
  deviceTypeId?: number;
  isGlobal?: boolean;
  isPersonal?: boolean;
}

type PredefinedDataTypeProps = DataSelectInheritedProps & PredefinedDataTypeProp;

/**
 * Select for device types.
 */
export function PredefinedDataTypeSelect({ isGlobal, isPersonal, deviceTypeId, ...props }: PredefinedDataTypeProps) {
  const key = useMemo(() => nanoid(), [deviceTypeId, isGlobal, isPersonal]);
  const { fetchPredefineDataTypes } = usePredefinedDataTypeSelect({ isGlobal, isPersonal, deviceTypeId });

  return (
    <DataSelect
      key={key}
      valueProp="value"
      labelProp="label"
      groupProp="group"
      action={fetchPredefineDataTypes}
      {...props}
    />
  );
}
