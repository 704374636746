import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconAlignCenter } from '@tabler/icons-react';

/**
 * A button that allows the user to align the selected text to the center.
 */
export default function AlignCenter({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconAlignCenter />}
      label="Zarovnať na stred"
      shortcut="Ctrl + Shift + E"
      active={editor?.isActive({ textAlign: 'center' })}
      onClick={() => editor?.chain().focus().setTextAlign('center').run()}
    />
  );
}
