import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserGetFollowedDepartmentsListPathParameters } from 'api/actions/user-get-followed-departments-list/user-get-followed-departments-list-path-parameters';
import { UserGetFollowedDepartmentsListResponse } from 'api/actions/user-get-followed-departments-list/user-get-followed-departments-list-response';

type UserGetFollowedDepartmentsListQueryParameters = Record<string, any>;

/**
 * The path of the UserGetFollowedDepartmentsList action.
 */
export const userGetFollowedDepartmentsListActionPath = new SweetPath<
  keyof UserGetFollowedDepartmentsListPathParameters
>('/api/user/followed-departments/{userId}', { path: '{param}' });

/**
 * Creates a contextualized UserGetFollowedDepartmentsList actions.
 */
export default function createUserGetFollowedDepartmentsListAction(axios: AxiosInstance) {
  return function userGetFollowedDepartmentsListAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserGetFollowedDepartmentsListPathParameters;
    query?: UserGetFollowedDepartmentsListQueryParameters;
  }) {
    const path = userGetFollowedDepartmentsListActionPath.insert(parameters);

    return axios.get<UserGetFollowedDepartmentsListResponse, UserGetFollowedDepartmentsListResponse>(path, {
      params: query,
      headers,
    });
  };
}
