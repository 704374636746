import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { IForm, IFormSection, IFormTabSlug } from 'pages/revisions-module/template-editor/editors/form/types';
import { useTemplateEditorData } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { nanoid } from 'nanoid';

interface IFormSectionsDataContext {
  sections: IFormSection[];
  newestSectionId: string;
  getSectionById: (sectionId: string) => IFormSection | undefined;
  mutateSections: (mutate: (sections: IFormSection[]) => IFormSection[]) => void;
  addSection: (tab: IFormTabSlug) => string;
  removeSection: (sectionId: string) => void;
  updateSectionName: (sectionId: string, name: string) => void;
  moveSectionUp: (sectionId: string) => void;
  moveSectionDown: (sectionId: string) => void;
  getTabName: (tab: IFormTabSlug) => string;
  moveRowToSection: (sectionId: string, rowId: string, newSectionId: string) => void;
}

const FormSectionsDataContext = createContext<IFormSectionsDataContext>(undefined!);

/**
 * Provides the form sections data to the form editor.
 */
export function FormSectionsDataProvider({ children }: { children: React.ReactNode }) {
  const {
    form: { sections },
    setForm,
  } = useTemplateEditorData();

  const [newestSectionId, setNewSectionId] = useState<string>('');

  const getSectionById = useCallback((sectionId: string) => sections.find(({ id }) => id === sectionId), [sections]);

  const mutateSections = useCallback((mutate: (sections: IFormSection[]) => IFormSection[]) => {
    setForm((prev: IForm) => ({ ...prev, sections: mutate(prev.sections) }));
  }, []);

  const addSection = useCallback((tab: IFormTabSlug) => {
    const section = { id: nanoid(), tab, name: '', rows: [] };
    mutateSections((prev) => [...prev, section]);
    setNewSectionId(section.id);
    return section.id;
  }, []);

  const removeSection = useCallback((sectionId: string) => {
    mutateSections((prev) => prev.filter((section) => section.id !== sectionId));
  }, []);

  const updateSectionName = useCallback((sectionId: string, name: string) => {
    mutateSections((prev) => prev.map((section) => (section.id === sectionId ? { ...section, name } : section)));
  }, []);

  const moveSectionUp = useCallback((sectionId: string) => {
    mutateSections((sections) => {
      const index = sections.findIndex((section) => section.id === sectionId);

      if (index === 0 || index === -1) {
        return sections;
      }

      const newSections = [...sections];
      [newSections[index - 1], newSections[index]] = [newSections[index], newSections[index - 1]];

      return newSections;
    });
  }, []);

  const moveSectionDown = useCallback((sectionId: string) => {
    mutateSections((sections) => {
      const index = sections.findIndex((section) => section.id === sectionId);

      if (index === sections.length - 1 || index === -1) {
        return sections;
      }

      const newSections = [...sections];
      [newSections[index], newSections[index + 1]] = [newSections[index + 1], newSections[index]];

      return newSections;
    });
  }, []);

  const getTabName = useCallback((tab: IFormTabSlug) => {
    const tabNames: Record<IFormTabSlug, string> = {
      info: 'Základné informácie',
      description: 'Popis',
      specification: 'Špecifikácia',
      conclusion: 'Záver',
      device: 'Zariadenia',
      measurements: 'Merania',
    };

    return tabNames[tab];
  }, []);

  const moveRowToSection = useCallback((sectionId: string, rowId: string, newSectionId: string) => {
    mutateSections((sections) => {
      const sectionIndex = sections.findIndex((section) => section.id === sectionId);
      const newSectionIndex = sections.findIndex((section) => section.id === newSectionId);

      if (sectionIndex === -1 || newSectionIndex === -1) {
        return sections;
      }

      const row = sections[sectionIndex].rows.find((row) => row.id === rowId);

      if (!row) {
        return sections;
      }

      const newSections = [...sections];
      newSections[sectionIndex].rows = newSections[sectionIndex].rows.filter((row) => row.id !== rowId);
      newSections[newSectionIndex].rows.push(row);

      return newSections;
    });
  }, []);

  const value = useMemo(
    () => ({
      sections,
      newestSectionId,
      getSectionById,
      mutateSections,
      addSection,
      removeSection,
      updateSectionName,
      moveSectionUp,
      moveSectionDown,
      getTabName,
      moveRowToSection,
    }),
    [
      sections,
      newestSectionId,
      getSectionById,
      mutateSections,
      addSection,
      removeSection,
      updateSectionName,
      moveSectionUp,
      moveSectionDown,
      getTabName,
      moveRowToSection,
    ]
  );

  return <FormSectionsDataContext.Provider value={value}>{children}</FormSectionsDataContext.Provider>;
}

/**
 * Uses the form sections data.
 */
export function useFormSections() {
  return useContext(FormSectionsDataContext);
}
