import { GridApi } from 'ag-grid-community';
import { QualificationsSelect } from 'components/selects/QualificationsSelect';
import { useCallback, useState } from 'react';

/**
 * The key of the filter. Must be equal to the column field.
 */
export const FILTER_KEY = 'qualificationSlugs' as const;

/**
 * Parameters of the QualificationsSelectFilter component.
 */
export interface QualificationsSelectFilterProps {
  api: GridApi;
  context: any;
}

/**
 * Filter component for the qualificationSlugs column.
 *
 * This filter assumes:
 *   - column field is `qualificationSlugs`
 *   - context contains `initialFilters` with `qualificationSlugs` filter
 */
export default function QualificationsSelectFilter({ api, context }: QualificationsSelectFilterProps) {
  const [value, setValue] = useState<string | null>(context?.initialFilters?.[FILTER_KEY]?.filter);

  const onChange = useCallback(
    (value: string | null) => {
      setValue(value);

      api.setFilterModel({
        ...api.getFilterModel(),
        [FILTER_KEY]: { filterType: 'text', type: 'contains', filter: value },
      });
    },
    [api, setValue]
  );

  return (
    <QualificationsSelect
      lazyLoad
      clearable
      w="100%"
      size="sm"
      placeholder="Všetky typy oprávnení"
      value={value}
      onChange={onChange}
    />
  );
}
