import { SimpleGrid } from '@mantine/core';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import FormEditor from 'pages/revisions-module/template-editor/editors/form/FormEditor';
import TemplateEditor from 'pages/revisions-module/template-editor/editors/template/TemplateEditor';
import PipelineEditor from 'pages/revisions-module/template-editor/editors/pipeline/PipelineEditor';
import { useState } from 'react';
import TemplateEditorNavigation from 'pages/revisions-module/template-editor/navigation/TemplateEditorNavigation';
import TemplateEditorTabPane from 'pages/revisions-module/template-editor/TemplateEditorTabPane';
import { TemplateEditorDataProvider } from 'pages/revisions-module/template-editor/TemplateEditorDataProvider';
import { ROLE_ADMIN_ID } from 'model/Role';
import { DASHBOARD_PAGE_PATH } from 'routes/paths';
import { useApi } from 'api/api-context';
import { Navigate, useParams } from 'react-router-dom';
import TemplateEditorFormWrapper from 'pages/revisions-module/template-editor/TemplateEditorFormWrapper';
import { FormSectionsDataProvider } from 'pages/revisions-module/template-editor/editors/form/section/FormSectionsDataProvider';
import { FormRowsDataProvider } from 'pages/revisions-module/template-editor/editors/form/row/FormRowsDataProvider';
import { FormInputsDataProvider } from 'pages/revisions-module/template-editor/editors/form/input/FormInputsDataProvider';
import { PipelineDataProvider } from 'pages/revisions-module/template-editor/editors/pipeline/PipelineDataProvider';
import AutomationConfiguratorProvider from 'pages/revisions-module/template-editor/editors/pipeline/configurator/AutomationConfiguratorProvider';
import { PageMarginSettingsProvider } from 'pages/revisions-module/template-editor/editors/visual/margin/PageMarginSettingsProvider';
import { PageBorderSettingsProvider } from 'pages/revisions-module/template-editor/editors/visual/border/PageBorderSettingsProvider';
import { PageTypographySettingsProvider } from 'pages/revisions-module/template-editor/editors/visual/typography/PageTypographySettingsProvider';
import { PageAdvancedSettingsProvider } from 'pages/revisions-module/template-editor/editors/visual/advanced/PageAdvancedSettingsProvider';
import { FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS } from 'env';

/**
 * Used to create and edit revision templates.
 */
export default function TemplateEditorPage() {
  const { roleId } = useApi();
  const [tabs, setTabs] = useState<string[]>(['form']);
  const { revisionTemplateId } = useParams();

  if (roleId !== ROLE_ADMIN_ID || !revisionTemplateId) {
    return <Navigate to={DASHBOARD_PAGE_PATH.original} replace />;
  }

  return (
    <DashboardLayout hideBreadcrumbs>
      <TemplateEditorDataProvider revisionTemplateId={revisionTemplateId}>
        <FormSectionsDataProvider>
          <FormRowsDataProvider>
            <FormInputsDataProvider>
              <PipelineDataProvider>
                <AutomationConfiguratorProvider>
                  <PageMarginSettingsProvider>
                    <PageBorderSettingsProvider>
                      <PageTypographySettingsProvider>
                        <PageAdvancedSettingsProvider>
                          <TemplateEditorFormWrapper>
                            <TemplateEditorNavigation value={tabs} onChange={setTabs} />
                            <SimpleGrid cols={tabs.length} spacing={1}>
                              <TemplateEditorTabPane visible={tabs.includes('form')}>
                                <FormEditor />
                              </TemplateEditorTabPane>
                              <TemplateEditorTabPane visible={tabs.includes('template')} innerPadding={0}>
                                <TemplateEditor />
                              </TemplateEditorTabPane>
                              {FEATURE_TOGGLE_REVISION_TEMPLATE_EDITOR_AUTOMATIONS && (
                                <TemplateEditorTabPane visible={tabs.includes('automation')}>
                                  <PipelineEditor />
                                </TemplateEditorTabPane>
                              )}
                            </SimpleGrid>
                          </TemplateEditorFormWrapper>
                        </PageAdvancedSettingsProvider>
                      </PageTypographySettingsProvider>
                    </PageBorderSettingsProvider>
                  </PageMarginSettingsProvider>
                </AutomationConfiguratorProvider>
              </PipelineDataProvider>
            </FormInputsDataProvider>
          </FormRowsDataProvider>
        </FormSectionsDataProvider>
      </TemplateEditorDataProvider>
    </DashboardLayout>
  );
}
