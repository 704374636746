import { Button, Checkbox, Group, Modal, ScrollArea, Stack } from '@mantine/core';
import { ColDef, CsvExportParams } from 'ag-grid-community';
import { DataTableExport } from 'components/tables/DataTable';
import { useState } from 'react';

/**
 * Parameters of the ExportModal component.
 */
export interface ExportModalProps {
  columns: ColDef[];
  dataExport: DataTableExport;
  title: string;
  opened: boolean;
  onClose: () => void;
  onExport: (csvExportParams: CsvExportParams) => void;
}

/**
 * Modal which allows the user to export the data.
 */
export default function ExportModal({ columns, dataExport, title, opened, onClose, onExport }: ExportModalProps) {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(dataExport.columnKeys);

  /**
   * Toggle the column.
   */
  const toggleColumn = (column: string) => {
    if (selectedColumns.includes(column)) {
      setSelectedColumns(selectedColumns.filter((key) => key !== column));
    } else {
      setSelectedColumns([...selectedColumns, column]);
    }
  };

  return (
    <Modal centered opened={opened} onClose={onClose} title={title}>
      <ScrollArea h={500} py={16}>
        <Stack spacing={8} px={8}>
          {dataExport.columnKeys.map((key) => (
            <Checkbox
              // TODO name
              size="md"
              key={key}
              label={columns.find((column) => column.field === key)?.headerName ?? key}
              checked={selectedColumns.includes(key)}
              onChange={() => toggleColumn(key)}
            />
          ))}
        </Stack>
      </ScrollArea>
      <Group position="right" spacing={16} pt={16}>
        <Button variant="link" onClick={onClose} px={8}>
          Zrušiť
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onExport({
              fileName: dataExport.fileName,
              columnKeys: selectedColumns,
            });
            onClose();
          }}
        >
          Exportovať
        </Button>
      </Group>
    </Modal>
  );
}
