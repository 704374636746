import { Box, ScrollArea, Select, Stack } from '@mantine/core';
import PermissionInput from 'components/inputs/permission-input/PermissionInput';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { useMemo, useState } from 'react';

type PermissionTemplateMap = Record<string, number[]>;

interface PermissionTemplateInputValue {
  templateName: string;
  permissions: number[];
}

interface PermissionTemplateInputProps {
  permissionType: 'organization' | 'department';
  value: PermissionTemplateInputValue;
  onChange: (value: PermissionTemplateInputValue) => void;
}

/**
 * Allows to select permission template and edit permissions.
 */
export default function PermissionTemplateInput({ permissionType, value, onChange }: PermissionTemplateInputProps) {
  const [permissionTemplate, setPermissionTemplate] = useState('no-template');
  const { departmentPermissionTemplates, organizationPermissionTemplates } = usePermissionDataProvider();
  const permissionTemplateMap = useMemo(
    () =>
      permissionType === 'department'
        ? departmentPermissionTemplates.reduce(
            (acc, curr) => ({ ...acc, [curr.slug]: curr.permissions.map((p) => p.permissionId) }),
            {} as PermissionTemplateMap
          )
        : permissionType === 'organization'
        ? organizationPermissionTemplates.reduce(
            (acc, curr) => ({ ...acc, [curr.slug]: curr.permissions.map((p) => p.permissionId) }),
            {} as PermissionTemplateMap
          )
        : {},
    [permissionType]
  );

  const permissionTemplateSelectData: { value: string; label: string }[] = useMemo(
    () =>
      permissionType === 'department'
        ? departmentPermissionTemplates.map((template) => ({
            value: template.slug,
            label: template.permissionTemplateName,
          }))
        : permissionType === 'organization'
        ? organizationPermissionTemplates.map((template) => ({
            value: template.slug,
            label: template.permissionTemplateName,
          }))
        : [],
    [permissionType]
  );

  /**
   * Handles template name change.
   */
  function handleTemplateNameChange(templateName: string) {
    setPermissionTemplate(templateName);

    if (templateName === 'no-template') {
      onChange({ ...value, templateName });
    } else {
      onChange({ templateName, permissions: permissionTemplateMap[templateName] });
    }
  }

  /**
   * Handles the permission change
   */
  function handlePermissionsChange(permissions: number[]) {
    onChange({ templateName: 'no-template', permissions });
  }

  return (
    <Stack spacing={24}>
      <Select
        searchable
        data={permissionTemplateSelectData}
        value={permissionTemplate}
        onChange={handleTemplateNameChange}
        // TODO name
      />
      <ScrollArea h={400}>
        <Box pb={24}>
          <PermissionInput
            permissionType={permissionType}
            value={value.permissions}
            onChange={handlePermissionsChange}
            // TODO name
          />
        </Box>
      </ScrollArea>
    </Stack>
  );
}
