import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthGetOrganizationsWithPermissionPathParameters } from 'api/actions/auth-get-organizations-with-permission/auth-get-organizations-with-permission-path-parameters';
import { AuthGetOrganizationsWithPermissionResponse } from 'api/actions/auth-get-organizations-with-permission/auth-get-organizations-with-permission-response';

type AuthGetOrganizationsWithPermissionQueryParameters = Record<string, any>;

/**
 * The path of the AuthGetOrganizationsWithPermission action.
 */
export const authGetOrganizationsWithPermissionActionPath = new SweetPath<
  keyof AuthGetOrganizationsWithPermissionPathParameters
>('/api/auth/organizations-with-permission/{permissionSlug}', { path: '{param}' });

/**
 * Creates a contextualized AuthGetOrganizationsWithPermission actions.
 */
export default function createAuthGetOrganizationsWithPermissionAction(axios: AxiosInstance) {
  return function authGetOrganizationsWithPermissionAction({
    headers = {},
    parameters,
    query = {},
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: AuthGetOrganizationsWithPermissionPathParameters;
    query?: AuthGetOrganizationsWithPermissionQueryParameters;
  }) {
    const path = authGetOrganizationsWithPermissionActionPath.insert(parameters);

    return axios.get<AuthGetOrganizationsWithPermissionResponse, AuthGetOrganizationsWithPermissionResponse>(path, {
      params: query,
      headers,
    });
  };
}
