import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { IconMaximize } from '@tabler/icons-react';
import { noop } from 'lodash';

/**
 * A button that allows the user to toggle full screen mode.
 */
export default function Fullscreen({ kind }: { kind: SimpleControlKind }) {
  return (
    <SimpleControl
      kind={kind}
      icon={<IconMaximize />}
      label="Na celú obrazovku"
      shortcut="Ctrl + Shift + F"
      onClick={noop} // TODO: Implement
    />
  );
}
