import { useForm } from '@mantine/form';
import useLoadingAction from 'hooks/use-loading-action';
import { noop } from 'lodash';
import FormWrapper from 'components/forms/FormWrapper';
import { IconCheck } from '@tabler/icons-react';
import { Checkbox, Collapse, SimpleGrid } from '@mantine/core';
import { FormProps } from 'components/forms/FormProps';
import FormInputGroup from 'components/forms/FormInputGroup';
import { DeviceTypeMultiSelect } from 'components/selects/DeviceTypeSelect';
import { useParams } from 'react-router-dom';
import { UserFollowedDepartmentMultiSelect } from 'components/selects/UserFollowedDepartmentsSelect';

export interface NotificationSettingsFormData {
  revisions: boolean;
  revisionPlans: boolean;
  departmentIds?: string[];
  deviceTypeIds?: string[];
}

/**
 * Form for editing user's notification settings.
 */
export default function NotificationSettingsForm({
  initialValues = {
    revisions: false,
    revisionPlans: false,
  },
  onSubmit = noop,
}: FormProps<NotificationSettingsFormData>) {
  const [{ loading }, submit] = useLoadingAction(onSubmit);

  const { userId } = useParams();

  const form = useForm({
    initialValues,
  });

  return (
    <FormWrapper
      pt={24}
      onSubmit={form.onSubmit(submit)}
      loading={loading}
      primaryButtonText="Uložiť"
      primaryIcon={<IconCheck stroke="1.5" size={24} />}
      skipSecondaryButtonConfirm={!form.isDirty()}
    >
      <FormInputGroup
        groupTitle="Revízne správy"
        groupTooltip="Upozornenia o zmene stavu revíznej správy, ak sa vás táto zmena týka."
      >
        <SimpleGrid cols={2} spacing={40}>
          <Checkbox
            label="Povoliť odosielať upozorenia na revízne správy"
            size="lg"
            name="amperia-revisions"
            {...form.getInputProps('revisions', { type: 'checkbox' })}
          />
        </SimpleGrid>
      </FormInputGroup>

      <FormInputGroup
        groupTitle="Sledovanie termínov"
        groupTooltip="Upozornenia o blížiacom sa termíne zariadenia. Toto nastavenia môžete obmedziť na niektoré strediská a typy zariadení."
      >
        <SimpleGrid cols={2} spacing={40}>
          <Checkbox
            label="Povoliť odosielať upozorenia na termíny"
            size="lg"
            name="amperia-revisionPlans"
            {...form.getInputProps('revisionPlans', { type: 'checkbox' })}
          />
        </SimpleGrid>

        <Collapse in={form.values.revisionPlans}>
          <SimpleGrid cols={2} spacing={40}>
            <UserFollowedDepartmentMultiSelect
              label="Strediská"
              size="lg"
              placeholder="Všetky strediská"
              groupByOrganization
              userId={userId ? Number(userId) : -1}
              name="amperia-departmentIds"
              {...form.getInputProps('departmentIds')}
            />
            <DeviceTypeMultiSelect
              label="Typy zariadení"
              size="lg"
              placeholder="Všetky typy zariadení"
              name="amperia-deviceTypeIds"
              {...form.getInputProps('deviceTypeIds')}
            />
          </SimpleGrid>
        </Collapse>
      </FormInputGroup>
    </FormWrapper>
  );
}
