import { MantineThemeOverride, rem } from '@mantine/core';

export type ColorSwatchTheme = NonNullable<MantineThemeOverride['components']>['ColorSwatch'];

export const ColorSwatch: ColorSwatchTheme = {
  styles: (theme) => ({
    root: {
      width: rem(10),
      height: rem(10),
    },
  }),
};
