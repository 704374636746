import { useApi } from 'api/api-context';
import {
  DataMultiSelect,
  DataMultiSelectInheritedProps,
  DataSelect,
  DataSelectInheritedProps,
} from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

/**
 * The shared props of the select components.
 */
interface DepartmentSelectProp {
  showDiscarded?: boolean;
  groupByOrganization?: boolean;
  organizationId?: number;
  permissionSlug?: string;
}

/**
 * Fetches departments form API.
 */
function useDepartmentSelect({
  showDiscarded,
  groupByOrganization,
  organizationId,
  permissionSlug,
}: DepartmentSelectProp) {
  const { getAction } = useApi();

  const fetchDepartments = useCallback(async () => {
    const action = getAction('DepartmentList');
    const departments = await action({
      query: {
        filters: {
          'organizationId.eq': organizationId === -1 ? undefined : organizationId,
          'showDiscarded.eq': showDiscarded ? 1 : 0,
          'permissionSlug.eq': permissionSlug,
        },
      },
    });

    return departments.map((department) => ({
      label: (department.status ? '' : ' [VYRADENÉ] ') + department.departmentName,
      value: String(department.departmentId),
      group: groupByOrganization ? department.organization.organizationName : '',
    }));
  }, [getAction, showDiscarded, groupByOrganization, organizationId]);

  return { fetchDepartments };
}

/**
 * The type of the Department Select component.
 */
type DepartmentSelectProps = DepartmentSelectProp & DataSelectInheritedProps;

/**
 * The type of the Department Multiselect component.
 */
type DepartmentMultiSelectProps = DepartmentSelectProp & DataMultiSelectInheritedProps;

/**
 * Select input for departments.
 */
export function DepartmentSelect({
  showDiscarded = true,
  groupByOrganization = false,
  organizationId,
  permissionSlug,
  ...props
}: DepartmentSelectProps) {
  const key = useMemo(() => nanoid(), [showDiscarded, groupByOrganization, organizationId, permissionSlug]);

  const { fetchDepartments } = useDepartmentSelect({
    showDiscarded,
    groupByOrganization,
    organizationId,
    permissionSlug,
  });

  return (
    <DataSelect key={key} action={fetchDepartments} labelProp="label" valueProp="value" groupProp="group" {...props} />
  );
}

/**
 * Select input for departments (multiple).
 */
export function DepartmentMultiSelect({
  showDiscarded = true,
  groupByOrganization = false,
  organizationId,
  permissionSlug,
  ...props
}: DepartmentMultiSelectProps) {
  const key = useMemo(() => nanoid(), [showDiscarded, groupByOrganization, organizationId, permissionSlug]);

  const { fetchDepartments } = useDepartmentSelect({
    showDiscarded,
    groupByOrganization,
    organizationId,
    permissionSlug,
  });

  return (
    <DataMultiSelect
      key={key}
      action={fetchDepartments}
      labelProp="label"
      valueProp="value"
      groupProp="group"
      {...props}
    />
  );
}
