import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserCreatePayload } from 'api/actions/user-create/user-create-payload';
import { UserCreatePathParameters } from 'api/actions/user-create/user-create-path-parameters';
import { UserCreateResponse } from 'api/actions/user-create/user-create-response';

type UserCreateQueryParameters = Record<string, any>;

/**
 * The path of the UserCreate action.
 */
export const userCreateActionPath = new SweetPath<keyof UserCreatePathParameters>('/api/user/create', {
  path: '{param}',
});

/**
 * Creates a contextualized UserCreate actions.
 */
export default function createUserCreateAction(axios: AxiosInstance) {
  return function userCreateAction({
    headers = {},
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    query?: UserCreateQueryParameters;
    payload: UserCreatePayload;
  }) {
    const path = userCreateActionPath.original;

    return axios.post<UserCreateResponse, UserCreateResponse, UserCreatePayload>(path, payload);
  };
}
