import { Skeleton, Text } from '@mantine/core';
import { ActionIcon, Group, Menu } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { noop } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { RevisionActionProps, RevisionSingleActionProps } from 'components/tables/revision/types';
import AcceptAction from 'components/tables/revision/actions/AcceptAction';
import EditAction from 'components/tables/revision/actions/EditAction';
import ViewAction from 'components/tables/revision/actions/ViewAction';
import FillOutAction from 'components/tables/revision/actions/FillOutAction';
import ApproveAction from 'components/tables/revision/actions/ApproveAction';
import ConfirmAction from 'components/tables/revision/actions/ConfirmAction';
import ReopenAction from 'components/tables/revision/actions/ReopenAction';
import DeclineAction from 'components/tables/revision/actions/DeclineAction';
import RejectAction from 'components/tables/revision/actions/RejectAction';
import ReturnAction from 'components/tables/revision/actions/ReturnAction';
import ReassignAction from 'components/tables/revision/actions/ReassignAction';
import CopyAction from 'components/tables/revision/actions/CopyAction';
import DeleteAction from 'components/tables/revision/actions/DeleteAction';
import ApproveAndConfirmAction from 'components/tables/revision/actions/ApproveAndConfirmAction';
import panic from 'errors/panic';
import { ROLE_ADMIN_ID } from 'model/Role';
import { FEATURE_TOGGLE_KEP } from 'env';
import SignAction from 'components/tables/revision/actions/SignAction';
import FinishSigningAction from 'components/tables/revision/actions/FinishSigningAction';
import SignAgainAction from 'components/tables/revision/actions/SignAgainAction';

/**
 * Enum of all possible action names.
 */
type ActionName =
  | 'accept'
  | 'approve'
  | 'confirm'
  | 'copy'
  | 'decline'
  | 'delete'
  | 'edit'
  | 'fillOut'
  | 'reassign'
  | 'reject'
  | 'reopen'
  | 'return'
  | 'approve-confirm'
  | 'view'
  | 'sign-with-kep'
  | 'sign-again-with-kep'
  | 'finish-signing-with-kep';

/**
 * Discriminates between different actions.
 */
function ActionDiscriminator({ actionName, ...props }: { actionName: ActionName } & RevisionSingleActionProps) {
  if (actionName === 'accept') {
    return <AcceptAction {...props} />;
  }

  if (actionName === 'edit') {
    return <EditAction {...props} />;
  }

  if (actionName === 'view') {
    return <ViewAction {...props} />;
  }

  if (actionName === 'fillOut') {
    return <FillOutAction {...props} />;
  }

  if (actionName === 'approve') {
    return <ApproveAction {...props} />;
  }

  if (actionName === 'confirm') {
    return <ConfirmAction {...props} />;
  }

  if (actionName === 'reopen') {
    return <ReopenAction {...props} />;
  }

  if (actionName === 'decline') {
    return <DeclineAction {...props} />;
  }

  if (actionName === 'reject') {
    return <RejectAction {...props} />;
  }

  if (actionName === 'return') {
    return <ReturnAction {...props} />;
  }

  if (actionName === 'reassign') {
    return <ReassignAction {...props} />;
  }

  if (actionName === 'copy') {
    return <CopyAction {...props} />;
  }

  if (actionName === 'delete') {
    return <DeleteAction {...props} />;
  }

  if (actionName === 'approve-confirm') {
    return <ApproveAndConfirmAction {...props} />;
  }

  if (FEATURE_TOGGLE_KEP && actionName === 'sign-with-kep') {
    return <SignAction {...props} />;
  }

  if (FEATURE_TOGGLE_KEP && actionName === 'sign-again-with-kep') {
    return <SignAgainAction {...props} />;
  }

  if (FEATURE_TOGGLE_KEP && actionName === 'finish-signing-with-kep') {
    return <FinishSigningAction {...props} />;
  }

  return <></>;
}

/**
 * Parameters of the RevisionActions component.
 */
export interface RevisionActionsPropsExtended extends RevisionActionProps {
  hideCopy?: boolean;
  hideDelete?: boolean;
  hideEdit?: boolean;
  hideView?: boolean;
}

/**
 * Displays actions for a revision in a table
 */
export default function RevisionActions({
  revision,
  hideCopy = false,
  hideDelete = false,
  hideEdit = false,
  hideView = false,
  onStatusChange = noop,
}: RevisionActionsPropsExtended) {
  const { revisionName, revisionStatus, technician, department, kepSignable } = revision;

  const { userId, getAction, roleId } = useApi();
  const [loading, setLoading] = useState(roleId !== ROLE_ADMIN_ID);
  const [canAssignRevisions, setCanAssignRevisions] = useState(roleId === ROLE_ADMIN_ID);
  const [canApproveRevisions, setCanApproveRevisions] = useState(roleId === ROLE_ADMIN_ID);
  const [canAcceptRevisions, setCanAcceptRevisions] = useState(roleId === ROLE_ADMIN_ID);

  const actions = useMemo(() => {
    const { slug } = revisionStatus;
    const { userId: technicianId } = technician;
    const actions: ActionName[] = [];
    const hasRevisionData = revision.hasRevisionData;
    const hasActiveRevisionTemplate = !!revision.revisionTemplate?.status;

    if (slug === 'created' && userId === technicianId) {
      // 1 -> 2, 1 -> 3
      actions.push('accept', 'decline');
    }

    if ((slug === 'accepted-by-technician' || slug === 'in-progress') && userId === technicianId) {
      // 2 -> 4, 4 -> 4
      actions.push('fillOut');
    }

    // finish revision is available only from the form

    if (slug === 'finished-waiting-for-approval' && canApproveRevisions) {
      if (canAcceptRevisions) {
        // 5 -> 8
        actions.push('approve-confirm');
      }

      // 5 -> 6
      actions.push('approve', 'reject');
    }

    if (slug === 'rejected' && canAssignRevisions) {
      // 6 -> 4
      actions.push('reopen');
    }

    if (slug === 'approved-waiting-for-confirmation' && canAcceptRevisions) {
      // 7 -> 8, 7 -> 6
      actions.push('confirm', 'return');
    }

    if (FEATURE_TOGGLE_KEP && kepSignable && slug === 'confirmed' && userId === technicianId) {
      // 8 -> 9
      actions.push('sign-with-kep');
    }

    if (FEATURE_TOGGLE_KEP && kepSignable && slug === 'being-signed' && userId === technicianId) {
      // 9 -> 10
      actions.push('finish-signing-with-kep');

      // re-send the revision to the signing portal
      actions.push('sign-again-with-kep');
    }

    if (
      slug !== 'confirmed' &&
      !hideEdit &&
      canAssignRevisions &&
      slug !== 'being-signed' &&
      slug !== 'signed-with-kep'
    ) {
      actions.push('edit');
    }

    if (slug === 'declined-by-technician' && canAssignRevisions) {
      // 3 -> 1
      actions.push('reassign');
    }

    if (!hideView) {
      actions.push('view');
    }

    if (!hideCopy && canAssignRevisions && (!hasRevisionData || hasActiveRevisionTemplate)) {
      actions.push('copy');
    }

    if (
      (slug === 'created' ||
        slug === 'declined-by-technician' ||
        slug === 'accepted-by-technician' ||
        slug === 'in-progress') &&
      !hideDelete &&
      canAssignRevisions
    ) {
      actions.push('delete');
    }

    return actions;
  }, [userId, revisionStatus.slug, technician.userId, canAssignRevisions, canApproveRevisions, canAcceptRevisions]);

  const primaryAction = useMemo(() => actions[0], [actions]);
  const menuActions = useMemo(() => actions.slice(1), [actions]);

  useEffect(() => {
    const getPermissions = getAction('AuthUserGetPermissionsInDepartment');

    if (roleId === ROLE_ADMIN_ID) {
      setCanAssignRevisions(true);
      setCanApproveRevisions(true);
      setCanAcceptRevisions(true);
    } else {
      setLoading(true);

      getPermissions({ parameters: { departmentId: String(department.departmentId) } })
        .then((permissions) => {
          const permissionMap = permissions.reduce(
            (map, { slug, hasPermission }) => ({ ...map, [slug]: hasPermission }),
            {} as Record<string, boolean>
          );

          setCanAssignRevisions(permissionMap['assign-revisions'] ?? false);
          setCanApproveRevisions(permissionMap['approve-revisions'] ?? false);
          setCanAcceptRevisions(permissionMap['accept-revisions'] ?? false);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    }
  }, [revision, roleId]);

  if (loading) {
    return (
      <Group noWrap spacing={12}>
        <Skeleton width={180} height={40} />
        <Skeleton width={40} height={40} />
      </Group>
    );
  }

  return (
    <Group noWrap spacing={12}>
      <ActionDiscriminator
        actionName={primaryAction}
        revision={revision}
        onStatusChange={onStatusChange}
        kind="button"
      />
      {menuActions.length > 0 && (
        <Menu position="bottom-end" withinPortal>
          <Menu.Target>
            <ActionIcon variant="tertiary" size="md">
              <IconDotsVertical stroke="1.5" height={24} width={24} />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {revisionName}
              </Text>
            </Menu.Label>

            {menuActions.map((action) => (
              <ActionDiscriminator
                key={action}
                actionName={action}
                revision={revision}
                onStatusChange={onStatusChange}
                kind="menu"
              />
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </Group>
  );
}
