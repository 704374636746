import { Flex } from '@mantine/core';
import BadgeDeleted from 'components/tables/Badge';
import { DeviceSubtypeRow } from 'components/tables/device-subtypes/types';
import P2Medium from 'components/typography/P2Medium';

/**
 * Column for the device subtype name.
 */
export default function DeviceSubtypeNameColumn({ data: { deviceTypeName, status } }: { data: DeviceSubtypeRow }) {
  const opacity = status ? 1 : 0.6;

  return (
    <Flex gap={10} align="center">
      {!status && <BadgeDeleted text="Vyradené" />}

      <P2Medium color="gray.8" style={{ opacity }}>
        {deviceTypeName}
      </P2Medium>
    </Flex>
  );
}
