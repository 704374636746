import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthLogoutPathParameters } from 'api/actions/auth-logout/auth-logout-path-parameters';
import { AuthLogoutResponse } from 'api/actions/auth-logout/auth-logout-response';

type AuthLogoutQueryParameters = Record<string, any>;

/**
 * The path of the AuthLogout action.
 */
export const authLogoutActionPath = new SweetPath<keyof AuthLogoutPathParameters>('/api/auth/logout', {
  path: '{param}',
});

/**
 * Creates a contextualized AuthLogout actions.
 */
export default function createAuthLogoutAction(axios: AxiosInstance) {
  return function authLogoutAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthLogoutQueryParameters } = {}) {
    const path = authLogoutActionPath.original;

    return axios.post<AuthLogoutResponse, AuthLogoutResponse>(path, { params: query, headers });
  };
}
