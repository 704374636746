import { ActionIcon, Button, Group, Menu, Portal, Skeleton, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBackspace, IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import { useApi } from 'api/api-context';
import { useAcknowledge, useConfirm } from 'components/modals/message/MessageProvider';
import panic from 'errors/panic';
import { intersection, noop } from 'lodash';
import { ROLE_ADMIN_ID } from 'model/Role';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { EDIT_DEVICE_SUBTYPE_PAGE_PATH } from 'routes/paths';
import { CANNOT_SET_ENTITY_STATUS, ERROR_NOTIFICATION_COLOR } from 'utils/constants';

/**
 * Parameters for the DeviceSubtypesActions component.
 */
interface DeviceSubtypesActionsProps {
  deviceTypeId: number;
  deviceSubtypeId: number;
  deviceSubtypeName: string;
  status: boolean;
  canDiscard: boolean;
  isOrganization: boolean;
  isDepartment: boolean;
  organizations: { organizationId: number; organizationName: string }[];
  departments: { departmentId: number; departmentName: string }[];
  onStatusChange: (status: boolean) => void;
}

/**
 * The actions which can be performed on a single row.
 */
export default function DeviceSubtypesActions({
  deviceTypeId,
  deviceSubtypeId,
  deviceSubtypeName,
  status,
  isOrganization,
  isDepartment,
  organizations,
  departments,
  canDiscard,
  onStatusChange = noop,
}: DeviceSubtypesActionsProps) {
  const { getAction, roleId } = useApi();
  const { confirm } = useConfirm();
  const { acknowledge } = useAcknowledge();

  const [canEdit, setCanEdit] = useState(roleId === ROLE_ADMIN_ID);
  const [loading, setLoading] = useState(roleId !== ROLE_ADMIN_ID);

  useEffect(() => {
    if (roleId === ROLE_ADMIN_ID) {
      setCanEdit(true);
      setLoading(false);
    } else if (isDepartment) {
      const getDepartments = getAction('AuthGetDepartmentsWithPermission');

      getDepartments({ parameters: { permissionSlug: 'define-device-subtypes' } })
        .then((departmentsWithPermission) => {
          const deptsIntersection = intersection(
            departments.map((dpt) => dpt.departmentId),
            departmentsWithPermission.map((dpt) => dpt.departmentId)
          );

          setCanEdit(departmentsWithPermission.length > 0 && deptsIntersection.length === departments.length);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    } else if (isOrganization) {
      const getOrganizations = getAction('AuthGetOrganizationsWithPermission');

      getOrganizations({ parameters: { permissionSlug: 'define-device-subtypes' } })
        .then((organizationsWithPermission) => {
          const orgIntersection = intersection(
            organizations.map((org) => org.organizationId),
            organizationsWithPermission.map((org) => org.organizationId)
          );

          setCanEdit(organizationsWithPermission.length > 0 && orgIntersection.length === organizations.length);
        })
        .catch(panic)
        .finally(() => setLoading(false));
    } else {
      setCanEdit(false);
      setLoading(false);
    }
  }, [roleId]);

  /**
   * Makes a call to the API to set the status of the device subtype.
   */
  const setStatusRemote = useCallback(
    (status: boolean) => {
      const deviceSubtypeSetStatus = getAction('DeviceSubtypeSetStatus');

      deviceSubtypeSetStatus({ parameters: { deviceSubtypeId: String(deviceSubtypeId) }, payload: { status } })
        .then(() => onStatusChange(status))
        .catch((error) => {
          if (error.response.data.error.code === CANNOT_SET_ENTITY_STATUS) {
            notifications.show({
              title: 'Chyba!',
              message: 'Tento typ zariadenia nemôžete deaktivovať.',
              color: ERROR_NOTIFICATION_COLOR,
            });
          } else {
            panic(error);
          }
        });
    },
    [getAction, deviceSubtypeId]
  );

  /**
   * Confirms the status change.
   */
  const confirmSetStatus = useCallback(
    (status: boolean) => {
      if (!status && !canDiscard) {
        acknowledge({
          title: 'Nemožno vyradiť typ zariadenia',
          content: 'Existujú zariadenia, ktoré majú priradený tento typ.',
        });
      } else {
        const title = status ? 'Zaradiť typ zariadenia' : 'Vyradiť typ zariadenia';
        const message = status ? 'Naozaj chcete zaradiť typ zariadenia?' : 'Naozaj chcete vyradiť typ zariadenia?';

        confirm({
          title,
          content: message,
          onConfirm: () => setStatusRemote(status),
        });
      }
    },
    [confirm, setStatusRemote, canDiscard]
  );

  const mainAction = useMemo(() => {
    if (loading) {
      return <Skeleton width={120} height={40} radius={4} />;
    }

    return (
      <Button
        size="md"
        variant="secondary"
        component={Link}
        to={EDIT_DEVICE_SUBTYPE_PAGE_PATH.insert({ deviceTypeId, deviceSubtypeId })}
        leftIcon={<IconPencil />}
        disabled={!status || !canEdit}
      >
        Editovať
      </Button>
    );
  }, [loading, canEdit, deviceTypeId, deviceSubtypeId, status]);

  return (
    <Group spacing={12} noWrap>
      {mainAction}
      <Menu position="bottom-end">
        <Menu.Target>
          <ActionIcon variant="tertiary" size="md">
            <IconDotsVertical stroke="1.5" height={24} width={24} />
          </ActionIcon>
        </Menu.Target>
        <Portal>
          <Menu.Dropdown>
            <Menu.Label>
              <Text maw={160} truncate>
                {deviceSubtypeName}
              </Text>
            </Menu.Label>
            {canEdit && (
              <Menu.Item
                color={status ? 'red.8' : 'blue.8'}
                onClick={() => confirmSetStatus(!status)}
                icon={status ? <IconTrashX stroke={1.5} size={20} /> : <IconBackspace stroke={1.5} size={20} />}
              >
                {status ? 'Vyradiť' : 'Zaradiť'}
              </Menu.Item>
            )}
          </Menu.Dropdown>
        </Portal>
      </Menu>
    </Group>
  );
}
