import { ActionIcon, Box, Button, Group, Menu, useMantineTheme } from '@mantine/core';
import Avatar from 'components/Avatar';
import P1Medium from 'components/typography/P1Medium';
import P2Regular from 'components/typography/P2Regular';
import { usePermissionDataProvider } from 'model/PermissionDataProvider';
import { UserFormData } from 'components/forms/user/UserForm';
import { IconDotsVertical, IconPencil, IconTrashX } from '@tabler/icons-react';
import { usePickOrganizations } from 'components/modals/pick-organizations-modal/PickOrganizationsProvider';
import { usePickDepartments } from 'components/modals/pick-departments-modal/PickDepartmentsProvider';
import { useConfirm } from 'components/modals/message/MessageProvider';
import { useCallback } from 'react';

type EntityType = 'organization' | 'department';

interface PermissionsForEntityProps {
  entityId: number;
  entityName: string;
  entityType: EntityType;
  permissions: number[];
  templateSlug: string;
  value: UserFormData['inOrganizations'] | UserFormData['inDepartments'];
  updatePermissionsForEntity: ({ permissions, templateName }: { permissions: number[]; templateName: string }) => void;
  onEntityDelete(entityId: number): void;
}

/**
 * Renders the entity's, user's permissions for the entity.
 */
export default function PermissionsForEntity({
  entityId,
  entityName,
  entityType,
  permissions,
  templateSlug,
  value: formValue,
  updatePermissionsForEntity,
  onEntityDelete,
}: PermissionsForEntityProps) {
  const theme = useMantineTheme();
  const { getPermission, getPermissionTemplate } = usePermissionDataProvider();
  const { confirm } = useConfirm();
  const { pickOrganizations } = usePickOrganizations();
  const { pickDepartments } = usePickDepartments();

  /**
   * Confirms the removal of organization/department from the user.
   */
  const confirmEntityDelete = useCallback(() => {
    confirm({
      title: `Odstránenie ${entityType === 'organization' ? 'organizácie' : 'strediska'}`,
      content: `Naozaj chcete odstrániť ${entityType === 'organization' ? 'organizáciu' : 'stredisko'} ${entityName}?`,
      onConfirm: () => onEntityDelete(entityId),
    });
  }, [entityType, entityName, entityId, confirm, onEntityDelete]);

  /**
   * Updates the permissions of the user for the organization/department.
   */
  const updatePermissions = () => {
    if (entityType === 'organization') {
      pickOrganizations({
        title: '',
        permissionsTitle: `Oprávnenia v ${entityName}`,
        hiddenOrganizations: [],
        initialPermissions: permissions,
        initialTemplateName: formValue[entityId].templateName,
        pickPermissions: true,
        pickOrganizations: false,
        onPick: ({ permissions, templateName }) => {
          updatePermissionsForEntity({ permissions, templateName });
        },
      });
    } else {
      pickDepartments({
        title: '',
        permissionsTitle: `Oprávnenia v ${entityName}`,
        hiddenDepartments: [],
        initialPermissions: permissions,
        initialTemplateName: formValue[entityId].templateName,
        pickPermissions: true,
        pickDepartments: false,
        onPick: ({ permissions, templateName }) => {
          updatePermissionsForEntity({ permissions, templateName });
        },
      });
    }
  };

  return (
    <Box>
      <Group
        style={{
          border: `1px solid ${theme.colors.gray[2]}`,
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          alignContent: 'center',
        }}
        bg="gray.0"
        py={12}
        px={16}
        position="apart"
        mih={56}
        spacing={16}
      >
        <Group spacing={12}>
          <Avatar fullName={entityName} size="m" bgColor={theme.colors.gray[7]} />
          <Group spacing={8} maw={380}>
            <P1Medium color="gray.8">{entityName}</P1Medium>
            <P2Regular>({getPermissionTemplate(templateSlug)?.permissionTemplateName})</P2Regular>
          </Group>
        </Group>
        <Group spacing={12}>
          <Button
            variant="subtle-gray"
            size="sm"
            leftIcon={<IconPencil stroke="1.5" color={theme.colors.gray[7]} />}
            onClick={updatePermissions}
          >
            Upraviť oprávnenia
          </Button>
          <Box style={{ borderLeft: `1px solid ${theme.colors.gray[2]}` }} h={24}></Box>
          <Menu shadow="md" width={300} position="bottom-end">
            <Menu.Target>
              <ActionIcon variant="subtle-gray" size="sm">
                <IconDotsVertical stroke="1.5" height={24} width={24} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item color="red.8" icon={<IconTrashX stroke={1.5} size={20} />} onClick={confirmEntityDelete}>
                Zmazať oprávnenia v {entityName}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <Group
        style={{
          borderBottom: `1px solid ${theme.colors.gray[2]}`,
          borderLeft: `1px solid ${theme.colors.gray[2]}`,
          borderRight: `1px solid ${theme.colors.gray[2]}`,
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
        p={16}
        spacing={8}
      >
        {permissions.map(
          (permission) =>
            getPermission(permission) && (
              <Box
                style={{ border: `1px solid ${theme.colors.gray[2]}`, borderRadius: '4px' }}
                bg="white"
                px={8}
                key={permission}
              >
                <P2Regular>{getPermission(permission)?.permissionName}</P2Regular>
              </Box>
            )
        )}
      </Group>
    </Box>
  );
}
