import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import DepartmentTable from 'components/tables/department/DepartmentTable';
import { useState } from 'react';

/**
 * Page used to list departments.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=250%3A6994 Figma Design}
 * - {@link https://www.notion.so/Administrative-List-Departments-6f17d8fa9c2b4bbd929ea1b4e94b297d?pvs=4 Notion Page}
 */
export default function DepartmentsPage() {
  const [search, setSearch] = useState('');
  const [showDiscarded, setShowDiscarded] = useState(false);

  return (
    <DashboardLayout
      title="Strediská"
      breadcrumbs={[{ title: 'Strediská' }]}
      height="screen"
      withSearch
      onSearch={setSearch}
      withToggle
      onToggleChange={setShowDiscarded}
      toggleLabel="Zobraziť vyradené"
    >
      <DepartmentTable search={search} showDiscarded={showDiscarded} />
    </DashboardLayout>
  );
}
