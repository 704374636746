import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { UserSetStatusPayload } from 'api/actions/user-set-status/user-set-status-payload';
import { UserSetStatusPathParameters } from 'api/actions/user-set-status/user-set-status-path-parameters';
import { UserSetStatusResponse } from 'api/actions/user-set-status/user-set-status-response';

type UserSetStatusQueryParameters = Record<string, any>;

/**
 * The path of the UserSetStatus action.
 */
export const userSetStatusActionPath = new SweetPath<keyof UserSetStatusPathParameters>('/api/user/status/{userId}', {
  path: '{param}',
});

/**
 * Creates a contextualized UserSetStatus actions.
 */
export default function createUserSetStatusAction(axios: AxiosInstance) {
  return function userSetStatusAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: UserSetStatusPathParameters;
    query?: UserSetStatusQueryParameters;
    payload: UserSetStatusPayload;
  }) {
    const path = userSetStatusActionPath.insert(parameters);

    return axios.put<UserSetStatusResponse, UserSetStatusResponse, UserSetStatusPayload>(path, payload);
  };
}
