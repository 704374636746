import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { RevisionUpdatePayload } from 'api/actions/revision-update/revision-update-payload';
import { RevisionUpdatePathParameters } from 'api/actions/revision-update/revision-update-path-parameters';
import { RevisionUpdateResponse } from 'api/actions/revision-update/revision-update-response';

type RevisionUpdateQueryParameters = Record<string, any>;

/**
 * The path of the RevisionUpdate action.
 */
export const revisionUpdateActionPath = new SweetPath<keyof RevisionUpdatePathParameters>(
  '/api/revision/update/{revisionId}',
  { path: '{param}' }
);

/**
 * Creates a contextualized RevisionUpdate actions.
 */
export default function createRevisionUpdateAction(axios: AxiosInstance) {
  return function revisionUpdateAction({
    headers = {},
    parameters,
    query = {},
    payload,
  }: {
    headers?: AxiosRequestConfig<any>['headers'];
    parameters: RevisionUpdatePathParameters;
    query?: RevisionUpdateQueryParameters;
    payload: RevisionUpdatePayload;
  }) {
    const path = revisionUpdateActionPath.insert(parameters);

    return axios.put<RevisionUpdateResponse, RevisionUpdateResponse, RevisionUpdatePayload>(path, payload);
  };
}
