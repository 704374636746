import { Button, Menu } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { RevisionSingleActionProps } from 'components/tables/revision/types';
import { Link } from 'react-router-dom';
import { COPY_REVISION_PAGE_PATH } from 'routes/paths';

/**
 * Action performed by the user who has the permission to copy the revision.
 */
export default function CopyAction({ revision, kind }: RevisionSingleActionProps) {
  if (kind === 'button') {
    return (
      <Button
        size="md"
        component={Link}
        to={COPY_REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
        leftIcon={<IconCopy />}
        variant="secondary"
        w={180}
      >
        Kopírovať
      </Button>
    );
  }

  return (
    <Menu.Item
      color="gray.8"
      component={Link}
      to={COPY_REVISION_PAGE_PATH.insert({ revisionId: revision.revisionId })}
      icon={<IconCopy stroke={1.5} size={24} />}
    >
      Kopírovať
    </Menu.Item>
  );
}
