import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { PredefinedDataListRevisionDataPathParameters } from 'api/actions/predefined-data-list-revision-data/predefined-data-list-revision-data-path-parameters';
import { PredefinedDataListRevisionDataResponse } from 'api/actions/predefined-data-list-revision-data/predefined-data-list-revision-data-response';

type PredefinedDataListRevisionDataQueryParameters = Record<string, any>;

/**
 * The path of the PredefinedDataListRevisionData action.
 */
export const predefinedDataListRevisionDataActionPath = new SweetPath<
  keyof PredefinedDataListRevisionDataPathParameters
>('/api/predefined-data/revision', { path: '{param}' });

/**
 * Creates a contextualized PredefinedDataListRevisionData actions.
 */
export default function createPredefinedDataListRevisionDataAction(axios: AxiosInstance) {
  return function predefinedDataListRevisionDataAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: PredefinedDataListRevisionDataQueryParameters } = {}) {
    const path = predefinedDataListRevisionDataActionPath.original;

    return axios.get<PredefinedDataListRevisionDataResponse, PredefinedDataListRevisionDataResponse>(path, {
      params: query,
      headers,
    });
  };
}
