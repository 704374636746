import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { AuthLoginPayload } from 'api/actions/auth-login/auth-login-payload';
import { useApi } from 'api/api-context';
import Toast from 'components/Toast';
import { FormMessage } from 'components/forms/FormProps';
import LoginForm from 'components/forms/login/LoginForm';
import panic from 'errors/panic';
import SimpleLayout from 'layouts/simple/SimpleLayout';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WELCOME_PAGE_PATH } from 'routes/paths';
import { INACTIVE_USER_ERROR_CODE, INVALID_EMAIL_OR_PASSWORD_ERROR_CODE } from 'utils/constants';

/**
 * The login page.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=189%3A9460 Figma Design}
 * - {@link https://www.notion.so/Profile-Login-2f78ac82e8de4e4cbf313957f9c073db?pvs=4 Notion Page}
 */
export default function LoginPage() {
  const navigate = useNavigate();
  const { login } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordReset, { close: hidePasswordReset }] = useDisclosure(searchParams.get('resetPassword') === 'success');
  const [logout, { close: hideLogout }] = useDisclosure(searchParams.get('logout') === 'true');
  const [message, setMessage] = useState<FormMessage | undefined>(undefined);

  /**
   * Handles the login form submission.
   */
  function onSubmit({ email, password, rememberMe }: AuthLoginPayload) {
    return login({ payload: { email, password, rememberMe } })
      .then(() => navigate(WELCOME_PAGE_PATH.original))
      .catch((error) => {
        if (error.response.data.error.code === INVALID_EMAIL_OR_PASSWORD_ERROR_CODE) {
          setMessage({ type: 'fail', message: 'Emailová adresa alebo heslo je nesprávne.' });
        } else if (error.response.data.error.code === INACTIVE_USER_ERROR_CODE) {
          setMessage({ type: 'fail', message: 'Váš účet bol deaktivovaný.' });
        } else {
          panic(error);
        }
      });
  }

  // Remove the `resetPassword` query parameter from the URL when the password
  // reset is successful.
  useEffect(() => {
    if (passwordReset) {
      setSearchParams(
        (curr) => {
          curr.delete('resetPassword');
          return curr;
        },
        { replace: true }
      );
    }
  }, [passwordReset]);

  // Remove the `logout` query parameter from the URL when the logout is successful.
  useEffect(() => {
    if (logout) {
      setSearchParams(
        (curr) => {
          curr.delete('logout');
          return curr;
        },
        { replace: true }
      );
    }
  }, [logout]);

  return (
    <SimpleLayout contentStyle={{ position: 'relative', marginTop: passwordReset ? '60px' : 0 }}>
      <Stack pos="absolute" top={-90}>
        <Toast
          type="success"
          message="Heslo bolo úspešne zmenené. Teraz sa môžete prihlásiť."
          opened={passwordReset}
          onClose={hidePasswordReset}
        />
        <Toast
          type="warning"
          message="Boli ste odhlásený. Pokračujte opätovným prihlásením."
          opened={logout}
          onClose={hideLogout}
        />
      </Stack>
      <LoginForm onSubmit={onSubmit} message={message} />
    </SimpleLayout>
  );
}
