import { Anchor, Box, Group, Stack, Tooltip } from '@mantine/core';
import QuestionMark from 'components/QuestionMark';
import P2Medium from 'components/typography/P2Medium';
import P2RegularLink from 'components/typography/P2RegularLink';

/**
 * Parameters of the UserContact component.
 */
export interface UserContactProps {
  firstName: string;
  lastName: string;
  email: string;
  note?: string;
}

/**
 * Displays contact information for a user in a table.
 */
export default function UserContact({ firstName, lastName, email, note }: UserContactProps) {
  return (
    <Stack spacing={0}>
      <Group spacing={4} noWrap>
        <P2Medium color="gray.8">
          {firstName} {lastName}
        </P2Medium>
        {note && (
          <Tooltip label={note} arrowOffset={0} offset={-4}>
            <Box>
              <QuestionMark size={16} />
            </Box>
          </Tooltip>
        )}
      </Group>
      <Anchor href={`mailto:${email}`}>
        <P2RegularLink color="blue.8">{email}</P2RegularLink>
      </Anchor>
    </Stack>
  );
}
