import { Button } from '@mantine/core';
import { IconColumns1, IconColumns2 } from '@tabler/icons-react';
import { useMemo } from 'react';

interface SplitScreenButtonProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

/**
 * Button used to toggle split screen mode in template editor.
 */
export default function SplitScreenButton({ value, onChange }: SplitScreenButtonProps) {
  const text = useMemo(() => (value ? '1-stĺpcové rozloženie' : '2-stĺpcové rozloženie'), [value]);
  const icon = useMemo(() => (value ? <IconColumns1 /> : <IconColumns2 />), [value]);

  return (
    <Button variant="tertiary" size="sm" leftIcon={icon} onClick={() => onChange(!value)}>
      {text}
    </Button>
  );
}
