import { useApi } from 'api/api-context';
import { DataSelectInheritedProps, DataSelect } from 'components/selects/DataSelect';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';

interface DeviceResponsiblePersonSelectProp {
  departmentId: number;
}

/**
 * Fetches users from API.
 */
function useDeviceResponsiblePersonSelect({ departmentId }: DeviceResponsiblePersonSelectProp) {
  const { getAction } = useApi();

  const fetchUsers = useCallback(async () => {
    const action = getAction('DeviceResponsiblePersonGetOptions');

    const users = await action({ parameters: { departmentId: String(departmentId) } });

    return users.map(({ fullName, userId, isContactPerson }) => ({
      label: fullName + (isContactPerson ? ' — Kontaktná osoba na stredisku' : ''),
      value: userId,
    }));
  }, [getAction, departmentId]);

  return { fetchUsers };
}

type DeviceResponsiblePersonSelectProps = DataSelectInheritedProps & DeviceResponsiblePersonSelectProp;

/**
 * Select input for users.
 */
export function DeviceResponsiblePersonSelect({ departmentId, ...props }: DeviceResponsiblePersonSelectProps) {
  const key = useMemo(() => nanoid(), [departmentId]);
  const { fetchUsers } = useDeviceResponsiblePersonSelect({ departmentId });

  return <DataSelect key={key} action={fetchUsers} labelProp="label" valueProp="value" {...props} />;
}
