import { useCallback, useState } from 'react';
import { useFileManager } from 'api/file-manager/file-manager-context';
import panic from 'errors/panic';

/**
 * Triggers file download in the browser.
 */
function downloadFile(content: Blob | MediaSource, filename: string) {
  const url = window.URL.createObjectURL(content);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
}

/**
 * Hook for downloading a file.
 */
export default function useFileDownload() {
  const { readFile, getFileMetadata } = useFileManager();

  const [loading, setLoading] = useState(false);

  /**
   * Downloads a single file.
   */
  const downloadSingle = useCallback(
    async (fileId: string) => {
      const [contents, metadata] = await Promise.all([readFile({ fileId }), getFileMetadata({ fileId })]);
      downloadFile(contents, metadata.basename);
    },
    [readFile, getFileMetadata]
  );

  /**
   * Downloads all files.
   */
  const download = useCallback(
    (fileId: string | string[]) => {
      setLoading((loading) => {
        if (loading) {
          return loading;
        }

        const files = Array.isArray(fileId) ? fileId : [fileId];

        Promise.all(files.map((fileId) => downloadSingle(fileId)))
          .catch(panic)
          .finally(() => setLoading(false));

        return true;
      });
    },
    [setLoading, downloadSingle]
  );

  return { download, loading };
}
