import { Box, Center, Flex, Tooltip, useMantineTheme } from '@mantine/core';
import P3Regular from 'components/typography/P3Regular';
import { useMemo } from 'react';

/**
 * Parameters of the BarChart component.
 */
export interface BarChartProps {
  data: number[];
  labels: string[];
  tooltips?: string[];
}

/**
 * Displays a bar chart.
 */
export default function BarChart({ data, labels, tooltips = [] }: BarChartProps) {
  const theme = useMantineTheme();
  const max = useMemo(() => Math.ceil(Math.max(...data) * 1.1), [data]);
  const heights = useMemo(() => data.map((value) => (value / max) * 100), [data, max]);

  return (
    <Flex h="100%" w="100%" justify="space-between">
      {heights.map((height, index) => (
        <Box key={index} w={25} h="100%" sx={{ display: 'grid', gridTemplateRows: '1fr 24px', gap: '4px' }}>
          <Flex bg="gray.1" h="100%" align="flex-end" sx={{ borderRadius: '4px' }}>
            <Tooltip label={tooltips[index] || labels[index]} transitionProps={{ duration: 200 }}>
              <Box
                bg="blue.8"
                w="100%"
                h={`${height}%`}
                sx={{
                  transition: 'all 200ms ease',
                  borderRadius: '4px',
                  '&:hover': { background: theme.colors.gray[7] },
                }}
              />
            </Tooltip>
          </Flex>
          <Center>
            <P3Regular>{labels[index]}</P3Regular>
          </Center>
        </Box>
      ))}
    </Flex>
  );
}
