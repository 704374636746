import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SweetPath } from 'sweet-path';

import { AuthListRolesPathParameters } from 'api/actions/auth-list-roles/auth-list-roles-path-parameters';
import { AuthListRolesResponse } from 'api/actions/auth-list-roles/auth-list-roles-response';

type AuthListRolesQueryParameters = Record<string, any>;

/**
 * The path of the AuthListRoles action.
 */
export const authListRolesActionPath = new SweetPath<keyof AuthListRolesPathParameters>('/api/auth/role/list', {
  path: '{param}',
});

/**
 * Creates a contextualized AuthListRoles actions.
 */
export default function createAuthListRolesAction(axios: AxiosInstance) {
  return function authListRolesAction({
    headers = {},
    query = {},
  }: { headers?: AxiosRequestConfig<any>['headers']; query?: AuthListRolesQueryParameters } = {}) {
    const path = authListRolesActionPath.original;

    return axios.get<AuthListRolesResponse, AuthListRolesResponse>(path, { params: query, headers });
  };
}
