import { useState } from 'react';
import ResetPasswordForm, { ResetPasswordFormData } from 'components/forms/reset-password/ResetPasswordForm';
import SimpleLayout from 'layouts/simple/SimpleLayout';
import { Button, Center, Divider, Stack } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import H2Medium from 'components/typography/H2Medium';
import P2Regular from 'components/typography/P2Regular';
import { Link } from 'react-router-dom';
import { LOGIN_PAGE_PATH } from 'routes/paths';
import { useApi } from 'api/api-context';
import panic from 'errors/panic';

/**
 * The reset password page.
 *
 * - {@link https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?node-id=189%3A9527 Figma Design}
 * - {@link https://www.notion.so/Profile-Reset-Password-62d0799a51344dc49565e035c53924de?pvs=4 Notion Page}
 */
export default function ResetPasswordPage() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { getAction } = useApi();

  /**
   * Handles the form submission.
   */
  const handleSubmit = ({ email }: ResetPasswordFormData) => {
    const resetPasswordAction = getAction('AuthForgotPassword');

    return resetPasswordAction({ payload: { email } })
      .then(() => setShowConfirmation(true))
      .catch(panic);
  };

  return (
    <SimpleLayout>
      {showConfirmation ? (
        <div>
          {/* Confirmation UI */}
          <Stack p={24} spacing={16} h={310}>
            <Center>
              <Center bg="green.7" w={44} h={44} style={{ borderRadius: '100%' }}>
                <IconCheck stroke="2" width={24} height={24} color="white" />
              </Center>
            </Center>
            <Stack spacing={4}>
              <H2Medium align="center" color="gray.8">
                Na Váš e-mail sme poslali link.
              </H2Medium>
              <P2Regular align="center" color="gray.7">
                Otvorte si link, ktorý sme vám poslali do mailu a nastavte si svoje nové heslo.
              </P2Regular>
            </Stack>
          </Stack>

          <Divider color="gray.1" />

          <Stack p={24}>
            <Button component={Link} to={LOGIN_PAGE_PATH.original} fullWidth size="lg" variant="subtle-gray">
              Hotovo
            </Button>
          </Stack>
        </div>
      ) : (
        <ResetPasswordForm onSubmit={handleSubmit} />
      )}
    </SimpleLayout>
  );
}
