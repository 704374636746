import SimpleControl, {
  SimpleControlKind,
} from 'pages/revisions-module/template-editor/editors/template/toolbar/controls/SimpleControl';
import { useRichTextEditorContext } from '@mantine/tiptap';
import { IconPageBreak } from '@tabler/icons-react';

/**
 * Used to insert a page break into the editor.
 */
export default function InsertPageBreak({ kind }: { kind: SimpleControlKind }) {
  const { editor } = useRichTextEditorContext();

  return (
    <SimpleControl
      kind={kind}
      icon={<IconPageBreak />}
      label="Zalomenie strany"
      onClick={() => editor?.chain().focus().insertPageBreak({ force: true }).run()}
    />
  );
}
